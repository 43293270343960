import moment from 'moment';

export const initialSearchVariables = {
  data: null,
};

export const applyFilters = (rows, searchVariables) => {
  let newRows = [...rows];
  Object.keys(searchVariables).forEach((searchField) => {
    const { type } = searchVariables[searchField];
    if (type === 'date') {
      newRows = newRows.filter((row) => {
        const rowValue = moment(row[searchField]);
        return rowValue.isBetween(searchVariables[searchField].value[0], searchVariables[searchField].value[1], 'minutes', '[]');
      });
    } else if (type === 'rate') {
      newRows = newRows.filter((row) => {
        const rowValue = row[searchField] * 10000;
        return rowValue >= searchVariables[searchField].value[0]
          && rowValue <= searchVariables[searchField].value[1];
      });
    } else if (type === 'number') {
      newRows = newRows.filter((row) => (
        row[searchField] >= searchVariables[searchField].value[0]
        && row[searchField] <= searchVariables[searchField].value[1]
      ));
    } else if (type === 'text') {
      newRows = newRows.filter((row) => (
        row[searchField]
          .toString() // there are number values such as rut that have type text for filtering
          .toLowerCase()
          .indexOf(searchVariables[searchField].value.toLowerCase()) > -1
      ));
    } else if (type === 'enum' || type === 'boolean') {
      newRows = newRows.filter((row) => (
        searchVariables[searchField].value.includes(row[searchField])
      ));
    }
  });
  return newRows;
};

export const applySimpleSearch = (rows, search, fields) => {
  const newRows = [];
  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];
    if (search === '') {
      newRows.push(row);
    } else {
      for (let j = 0; j < fields.length; j += 1) {
        const field = fields[j].split('.');
        let value = row;
        for (let fieldIndex = 0; fieldIndex < field.length; fieldIndex += 1) {
          value = value[field[fieldIndex]];
        }
        if (value && value.toString().toLowerCase().indexOf(search.toLowerCase()) > -1) {
          newRows.push(row);
          break;
        }
      }
    }
  }
  return newRows;
};

export const createSearchVariables = (filters, rows) => {
  const newSearchVariables = {};
  if (rows.length <= 0) return null;
  filters
    .forEach((header) => {
      const type = header.filterType;
      let min;
      let max;
      let step;
      let value = '';
      let options = [];
      if (type === 'number' || type === 'date' || type === 'rate') {
        const tempArray = rows.map((row) => {
          if (type === 'date') return moment(row[header.id]);
          if (type === 'rate') return row[header.id] * 10000;
          return row[header.id];
        });
        max = Math.max(...tempArray);
        min = Math.min(...tempArray);
        value = [min, max];
        step = parseInt((max - min) / 20, 10);
      }
      if (type === 'enum') {
        const statusSet = new Set();
        rows.forEach((row) => statusSet.add(row[header.id]));
        options = Array.from(statusSet);
        value = [...options];
      }
      if (type === 'boolean') {
        options = [true, false];
        value = [true, false];
      }
      newSearchVariables[header.id] = {
        label: header.label,
        type,
        value,
        min,
        max,
        step,
        options,
      };
    });
  // This has to change every time we call createSearchVariables
  // because we can create search for different tables or sets of invoices.
  initialSearchVariables.data = newSearchVariables;
  return newSearchVariables;
};
