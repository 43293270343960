import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  TextField,

} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
});

const AddObservationCheck = ({ open, handleClose, addObservationMutation, whatToCheck }) => {
  const classes = useStyles();
  const location = useLocation();
  const rut = location.pathname.split('/').pop();
  const [observation, setObservation] = useState('');

  const handleObservationChange = (event) => {
    setObservation(event.target.value);
  };

  const onClosing = () => {
    setObservation('');
    handleClose();
  };

  const runAddObservation = async () => {
    await addObservationMutation({ variables: {
      companyRut: rut,
      observation,
    } });
    onClosing();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>Agrega un comentario de chequeos de {whatToCheck}</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <TextField
          value={observation}
          onChange={handleObservationChange}
          variant="standard"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosing} color="primary">
          Cancelar
        </Button>
        <Button color="primary" autoFocus onClick={runAddObservation}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddObservationCheck.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addObservationMutation: PropTypes.func.isRequired,
  whatToCheck: PropTypes.string.isRequired,
};

export default AddObservationCheck;
