/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const GET_PSEUDO_ORDERS = gql`
    query GetPseudoOrders {
        getPseudoOrders {
        id
        company {
            id
            rut
            name
        }
        debtorRut
        amount
        status
        checkInvoiceDate
        }
    }
`;

const CREATE_PSEUDO_ORDER = gql`
    mutation CreatePseudoOrder(
        $orderingClientRut: String!,
        $orderingDebtorRut: String!,
        $orderingAmount: Int!
    ) {
        createPseudoOrder(
            orderingClientRut: $orderingClientRut,
            orderingDebtorRut: $orderingDebtorRut,
            orderingAmount: $orderingAmount
        ) {
        success
        message
        }
  }
`;

const GET_EVALUATION_SIMULATIONS = gql`
  query getOrderingSimulationsInEvaluation {
  getOrderingSimulationsInEvaluation {
    id
    purchaseOrder {
      id
      company {
        id
        name
        rut
      }
      purchaser {
        name
        rut
      }
      riskEvaluationModel {
        treeClientResult
        treeDebtorResult
        treeClientDebtorResult
        rasClientResult
        rasDebtorResult
        rasClientDebtorResult
        ratification
        reason
        ruleResults {
          modelType
          rejects
          threshold
          name
          value
          prize
          logicalType
        }
      }
      orderNumber
      totalAmount
      pdfFileUrl
      status
    }
    requestDate
    orderingAmount
    orderingMonthlyRate
    orderingDefaultRate
    orderingRetentionRate
    factoringMonthlyRate
    factoringDefaultRate
    factoringRetentionRate
    invoiceIssuedDate
    invoiceDateToPay
  }
}
`;

const APPROVE_ORDERING_SIMULATION = gql`
  mutation approveOrderingSimulation($requestResolutions: [SimulationApprovedType]!, $informClients: Boolean!, $sendEmail: Boolean!, $analystDescription: String ) {
    approveOrderingSimulation(
      requestResolutions: $requestResolutions
      informClients: $informClients
      sendEmail: $sendEmail
      analystDescription: $analystDescription
      ) {
      success
    }
  }
`;

const REJECT_ORDERING_SIMULATION = gql`
mutation rejectOrderingSimulation(
  $requestResolutions: [PurchaseOrderEvaluationResolutionRejectType], $informClients: Boolean!, $sendEmail: Boolean!, $analystDescription: String
) {
  rejectOrderingSimulation (
    requestResolutions: $requestResolutions,
    informClients: $informClients,
    sendEmail: $sendEmail,
    analystDescription: $analystDescription,
  ) {
    success
  }
}
`;

const GET_PENDING_DOCUMENT_ORDERS = gql`
    query GetPendingDocumentOrders {
      getPendingDocumentOrders {
        id
        orderNumber
        totalAmount
        company {
          id
          rut
          name
        }
        purchaser {
          rut
          name
        }
        orderingoffer {
          orderingAmount
          orderingFinancedAmount
          orderingCommission
          orderingMonthlyRate
          orderingDefaultRate
          orderingRetentionRate
          factoringMonthlyRate
          factoringDefaultRate
          factoringCommission
          factoringRetentionRate
          invoiceIssuedDate
          invoiceDateToPay
        }
        assignmentDocument {
          user {
            email
            mailProvided
          }
        }
        operationDate
      }
  }
`;

const UPLOAD_ASSIGNMENT_DOCUMENT = gql`
    mutation UploadAssigmentDocument($orderId:Int!, $file:Upload!) {
        uploadAssignmentDocument(orderId:$orderId, file:$file) {
          success
          message
        }
  }
`;

const SEND_ASSIGNMENT_DOCUMENT = gql`
    mutation SendAssignmentDocument($orderId:Int!, $representativeName:String!, $representativeRut:Int!, $representativeEmail:String!) {
      sendAssignmentDocument(orderId: $orderId, representativeName: $representativeName, representativeRut: $representativeRut, representativeEmail: $representativeEmail) {
        success
        message
      }
    }
`;

const GET_PENDING_INVOICES_ORDERS = gql`
  query GetPendingInvoicesOrders {
    getPendingInvoicesOrders {
      company {
        id
        name
        rut
      }
      purchaser {
        name
        rut
      }
      orderingoffer {
        orderingFinancedAmount
        invoiceIssuedDate
        invoiceDateToPay
      }
      id
      orderNumber
      totalAmount
      publicationDate
      status
    }
  }
`;

const ASSIGN_INVOICES_TO_ORDER = gql`
  mutation AssignInvoicesToPurchaseOrder (
    $orderId: Int!
    $bankAccountId: Int
    $commission: Int!
    $orderingAssignmentRequest: [OrderingAssginmentRequest]!
    $isExternal: Boolean!
  ) {
    assignInvoicesToPurchaseOrder (
      orderId: $orderId
      bankAccountId: $bankAccountId
      commission: $commission
      orderingAssignmentRequest: $orderingAssignmentRequest
      isExternal: $isExternal
    ) {
      success
      message
    }
  }
`;

const GET_AVAILABLE_FOR_TRANSFER_ORDERING = gql`
  query GetAvailableForTransferOrdering {
    getAvailableForTransferOrdering{
      id
      status
      purchaseOrder {
        id
        orderNumber
        totalAmount {
          amount
          currency {
            code
          }
        }
        company {
          id
          rut
          name
          companycompliance {
            id
            readyForTransfer
            status
            requirementsClean
            information
          }
        }
        purchaser {
          rut
          name
        }
        offer {
          id
          orderingPaymentAmount {
            amount
            currency {
              code
            }
          }
        }
      }
      createdAt
    }
  }
`;

const CREATE_EXTERNAL_PURCHASE_ORDER = gql`
mutation CreateExternalPurchaseOrder (
  $externalPurchaseOrders: [ExternalPurchaseOrderType]!
) {
  createExternalPurchaseOrder (
    externalPurchaseOrders: $externalPurchaseOrders
  ) {
    success
    message
  }
}
`;

export {
  GET_PSEUDO_ORDERS,
  CREATE_PSEUDO_ORDER,
  GET_EVALUATION_SIMULATIONS,
  APPROVE_ORDERING_SIMULATION,
  REJECT_ORDERING_SIMULATION,
  GET_PENDING_DOCUMENT_ORDERS,
  UPLOAD_ASSIGNMENT_DOCUMENT,
  GET_PENDING_INVOICES_ORDERS,
  ASSIGN_INVOICES_TO_ORDER,
  GET_AVAILABLE_FOR_TRANSFER_ORDERING,
  SEND_ASSIGNMENT_DOCUMENT,
  CREATE_EXTERNAL_PURCHASE_ORDER,
};
