import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';
import { SmallUserAvatarWithName } from '../utils';
import { ASSIGN_CONTACT_RESPONSE } from '../../gql/ratification';
import SendgridInfoTooltip from './SendgridInfoTooltip';

const channelToText = {
  AUTOMATIC_MAIL: 'Email automático enviado a',
  MANUAL_MAIL: 'Email manual enviado a',
  PHONE_CALL: 'Llamada telefónica a',
  OTHER: 'Otro',
};
const actionToText = {
  INSIST_RATIFICATION: 'Insistencia en ratificación',
  INITIATE_RATIFICATION_PROCESS: 'Inicio de proceso de ratificación',
  OTHER: 'Otro',
};

const useStyles = makeStyles({
  actionContainer: {
    padding: '1rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#ABABAB',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
    overflowWrap: 'break-word',
  },
  actionInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionType: {
    fontWeight: 600,
  },
  channelAutomailType: {
    color: '#283593',
    fontWeight: 600,
  },
  channelManualType: {
    color: '#FF5E91',
    fontWeight: 600,
  },
});

const RatificationAction = ({ action }) => {
  const classes = useStyles();
  const [assignContactResponse] = useMutation(ASSIGN_CONTACT_RESPONSE);
  const {
    id: actionId,
    actionType,
    sendgridMessages,
    createdAt,
    ratificationContacts,
    contactsResponse,
    comment,
    author,
    responded,
    channel,
  } = action;
  const authorName = `${author.firstName} ${author.lastName}`;
  const creationDate = moment(createdAt).format('DD/MM/YYYY HH:mm');
  const [contactsResponseId, setContactsResponseId] = useState(
    contactsResponse.map((contact) => contact.id),
  );

  const handleMarkResponse = async (contact) => {
    await assignContactResponse({
      variables: {
        ratificationContactId: contact.id,
        ratificationActionId: actionId,
      },
    });
    const newContactsResponseId = [...contactsResponseId];
    newContactsResponseId.push(contact.id);
    setContactsResponseId(newContactsResponseId);
  };

  const getActionContent = () => {
    if (actionType === 'OTHER') {
      return (
        <>
          <Typography className={classes.actionType}>{actionToText[actionType]}</Typography>
          <Typography
            className={clsx(!(channel === 'AUTOMATIC_MAIL') && classes.channelManualType, (channel === 'AUTOMATIC_MAIL' && classes.channelAutomailType))}
          >
            {channelToText[channel] || 'Sin información del canal'}:
          </Typography>
          <Typography>{comment}</Typography>
        </>
      );
    }

    return (
      <div>
        <Typography className={classes.actionType}>{actionToText[actionType]}</Typography>
        <Typography
          className={clsx(!(channel === 'AUTOMATIC_MAIL') && classes.channelManualType, (channel === 'AUTOMATIC_MAIL' && classes.channelAutomailType))}
        >
          {channelToText[channel] || 'Sin información del canal'}:
        </Typography>
        <ul>
          { ratificationContacts.map((contact) => (
            <li
              key={contact.email}
            >
              <Typography>
                {`${contact.firstName} ${contact.lastName} - ${contact.email || 'sin correo'}, ${contact.phoneNumber || 'sin teléfono'}`}
                { (channel === 'PHONE_CALL') ? (
                  <Button size="small" disabled>
                    { responded ? ('Llamada contestada') : ('Contacto no contestó') }
                  </Button>
                ) : (
                  <Button size="small" disabled={contactsResponseId.includes(contact.id)} onClick={() => handleMarkResponse(contact)}>
                    { contactsResponseId.includes(contact.id) ? ('Email contestado') : ('¿Marcar como respondido?')}
                  </Button>
                )}
              </Typography>
            </li>
          ))}
        </ul>
        <Typography variant="body2">{comment}</Typography>
      </div>
    );
  };

  const container = (
    <div className={classes.actionContainer}>
      <div className={classes.actionInfoContainer}>
        <SmallUserAvatarWithName name={authorName} />
        <Typography variant="caption">{creationDate}</Typography>
      </div>
      {getActionContent()}
    </div>
  );

  return (
    <>
      {sendgridMessages?.length && channel === 'AUTOMATIC_MAIL' ? (
        <SendgridInfoTooltip actionEvents={sendgridMessages?.[0]?.events}>
          {container}
        </SendgridInfoTooltip>
      ) : (
        <>
          {container}
        </>
      )}
    </>
  );
};

RatificationAction.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    sendgridMessages: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    comment: PropTypes.string,
    channel: PropTypes.string.isRequired,
    responded: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    ratificationContacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    contactsResponse: PropTypes.arrayOf(PropTypes.object).isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default RatificationAction;
