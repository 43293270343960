import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ArrowBackIos } from '@material-ui/icons';
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { SUBMIT_NEGOTIATION_RESPONSE } from '../gql/negotiation';
import { useStyles } from './styles';
import { Table } from '../components/tables';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { TwoButtonsAlert } from '../components/dialogs';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', hideSortIcon: true },
  { id: 'rutReceiver', label: 'Deudor', type: 'rut', hideSortIcon: true },
  { id: 'dateIssued', label: 'Fecha de emisión', type: 'date', hideSortIcon: true },
  { id: 'dateExpiration', label: 'Fecha de vencimiento', type: 'date', hideSortIcon: true },
  { id: 'amountWithIva', label: 'Monto factura', type: 'money', hideSortIcon: true },
  { id: 'monthlyRate', label: 'Tasa mensual', type: 'rate', hideSortIcon: true },
  { id: 'defaultRate', label: 'Mora mensual', type: 'rate', hideSortIcon: true },
  { id: 'retentionRate', label: 'Retención', type: 'rate', hideSortIcon: true },
  { id: 'competitorRate', label: 'Tasa competencia', type: 'rate', hideSortIcon: true },
  { id: 'competitorName', label: 'Nombre competencia', type: 'text', hideSortIcon: true },
  { id: 'simulationFile', label: 'Simulación adjunta', type: 'file', hideSortIcon: true },
];

const RatesNegotiationRespond = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const {
    negotiationId,
    negotiationType,
    negotiationRequests,
    comment,
    clientName,
    clientRut,
  } = location.state;
  const [negotiationData, setNegotiationData] = useState([]);
  const [negotiationRates, setNegotiationRates] = useState([]);
  const [validationMessage, setValidationMessage] = useState('');
  const [openOnEmptyRates, setOpenOnEmptyRates] = useState(false);
  const onCompleted = () => {
    history.goBack();
  };
  const onError = (e) => {
    setValidationMessage(e.message);
  };
  const [submitNegotiationResponse, { loading }] = useMutation(SUBMIT_NEGOTIATION_RESPONSE,
    { onError,
      onCompleted });

  useEffect(() => {
    const flatRequests = negotiationRequests.map((request) => {
      const {
        id,
        monthlyRate: requestMonthlyRate,
        defaultRate: requestDefaultRate,
        retentionRate: requestRetentionRate,
        offer,
        preoffer,
        competitorRate,
        competitorName,
        simulationFileUrl,
      } = request;
      let monthlyRate;
      let defaultRate;
      let retentionRate;
      let invoice;
      if (offer) {
        monthlyRate = offer.monthlyRate;
        defaultRate = offer.defaultRate;
        retentionRate = offer.retentionRate;
        invoice = offer.invoice;
      } else if (preoffer) {
        monthlyRate = preoffer.monthlyRate;
        defaultRate = preoffer.defaultRate;
        retentionRate = preoffer.retentionRate;
        invoice = preoffer.invoice;
      }
      const {
        folio,
        amountWithIva,
        receiver: { rut: rutReceiver, name: nameReceiver },
        dateIssued,
        dateExpiration,
      } = invoice;
      const flatRequest = ({
        id,
        folio,
        rutReceiver,
        nameReceiver,
        dateIssued,
        dateExpiration,
        amountWithIva,
        monthlyRate,
        defaultRate,
        retentionRate,
        requestMonthlyRate,
        requestDefaultRate,
        requestRetentionRate,
        competitorRate,
        competitorName,
        simulationFileUrl,
      });
      return flatRequest;
    });
    setNegotiationData(flatRequests);
    setNegotiationRates(flatRequests.reduce((acc, val) => ({ ...acc,
      [val.id]: { monthlyRate: '', defaultRate: '', retentionRate: '' } }),
    {}));
  }, [negotiationRequests]);

  const abstractHandleChange = (id, rate) => {
    const handleChange = (event) => {
      const targetValue = event.target.value;
      const rates = { ...negotiationRates };
      rates[id][rate] = targetValue;
      setNegotiationRates(rates);
    };
    return handleChange;
  };

  const submitResponse = () => {
    const negotiationResponses = Object.entries(negotiationRates).map(([id, rates]) => ({
      requestId: id,
      monthlyRate: rates.monthlyRate === '' ? null : parseFloat(rates.monthlyRate),
      defaultRate: rates.defaultRate === '' ? null : parseFloat(rates.defaultRate),
      retentionRate: rates.retentionRate === '' ? null : parseFloat(rates.retentionRate) }));
    submitNegotiationResponse({
      variables: {
        negotiationType,
        negotiationId,
        negotiationResponses,
      },
    });
  };

  const validateResponse = () => {
    setValidationMessage('');
    const validationRegex = /^\d{0,1}?\.{0,1}?\d{0,10}?$/;
    const validForm = Object.keys(negotiationRates).every((key) => (
      validationRegex.test(negotiationRates[key].monthlyRate)
      && validationRegex.test(negotiationRates[key].defaultRate)
      && validationRegex.test(negotiationRates[key].retentionRate)));
    if (!validForm) {
      setValidationMessage('Hay errores de formato en las tasas ingresadas');
      return;
    }
    const requestedRates = negotiationData.reduce((acc, val) => ({ ...acc,
      [val.id]:
      { monthlyRate: val.requestMonthlyRate,
        defaultRate: val.requestDefaultRate,
        retentionRate: val.requestRetentionRate } }),
    {});
    const hasEmptyResponse = Object.keys(negotiationRates).some((key) => (
      (requestedRates[key].monthlyRate && negotiationRates[key].monthlyRate === '')
        || (requestedRates[key].defaultRate && negotiationRates[key].defaultRate === '')
        || (requestedRates[key].retentionRate && negotiationRates[key].retentionRate === '')));
    if (hasEmptyResponse) {
      setOpenOnEmptyRates(true);
      return;
    }
    submitResponse();
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      rutReceiver,
      nameReceiver,
      dateIssued,
      dateExpiration,
      amountWithIva,
      monthlyRate,
      defaultRate,
      retentionRate,
      requestMonthlyRate,
      requestDefaultRate,
      requestRetentionRate,
      competitorRate,
      competitorName,
      simulationFileUrl,
    } = row;
    return ({
      id,
      folio: {
        value: folio,
        component: (
          <Typography>{folio}</Typography>
        ),
        size: 'small',
      },
      rutReceiver: {
        value: rutReceiver,
        component: <DebtorCell name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <div>
            <Typography>{moment(dateIssued).format('DD-MM-YYYY')}</Typography>
          </div>
        ),
        size: 'small',
      },
      dateExpiration: {
        value: dateExpiration,
        component: (
          <div>
            <Typography>{moment(dateExpiration).format('DD-MM-YYYY')}</Typography>
          </div>
        ),
        size: 'small',
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva.amount)}</Typography>
        ),
        size: 'small',
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{(monthlyRate).toFixed(2)}%</Typography>
            <TextField
              value={negotiationRates[id].monthlyRate || ''}
              onChange={abstractHandleChange(id, 'monthlyRate')}
              className={classes.alignElement}
              style={{ maxWidth: 100 }}
              step=".01"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText={requestMonthlyRate ? 'Solicitada' : 'No solicitada'}
            />
          </div>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{(defaultRate).toFixed(2)}%</Typography>
            <TextField
              value={negotiationRates[id].defaultRate || ''}
              onChange={abstractHandleChange(id, 'defaultRate')}
              className={classes.alignElement}
              style={{ maxWidth: 100 }}
              step=".01"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText={requestDefaultRate ? 'Solicitada' : 'No solicitada'}
            />
          </div>
        ),
        size: 'small',
      },
      retentionRate: {
        value: retentionRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{(retentionRate).toFixed(2)}%</Typography>
            <TextField
              value={negotiationRates[id].retentionRate || ''}
              onChange={abstractHandleChange(id, 'retentionRate')}
              className={classes.alignElement}
              style={{ maxWidth: 100 }}
              step=".01"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText={requestRetentionRate ? 'Solicitada' : 'No solicitada'}
            />
          </div>
        ),
        size: 'small',
      },
      competitorRate: {
        value: competitorRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{competitorRate}%</Typography>
          </div>
        ),
        size: 'small',
      },
      competitorName: {
        value: competitorName,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{competitorName}</Typography>
          </div>
        ),
        size: 'small',
      },
      simulationFile: {
        value: simulationFileUrl,
        component: (
          <div className={classes.centerDiv}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(simulationFileUrl)}
              disabled={!simulationFileUrl}
              size="small"
            >
              Ver simulación
            </Button>
          </div>
        ),
        size: 'small',
      },
    });
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.titleAndUpdate}>
          <IconButton onClick={history.goBack}>
            <ArrowBackIos />
          </IconButton>
          <>
            <Grid item xs={12} className={classes.container}>
              <Typography variant="h4"><b>{ `Negociación de ${negotiationType === 'OFFER' ? 'ofertas' : 'preofertas'}` }</b></Typography>
              <Typography variant="h3">{ `Cliente: ${clientName} ${formatRut(clientRut)}` }</Typography>
            </Grid>
          </>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          <>
            <Typography variant="h5" color="textSecondary">Comentario negociación:</Typography>
            <Typography variant="h6" className={classes.indentedText} color="textPrimary"> {`"${comment}"`} </Typography>
          </>
        </Grid>
        <Grid item xs={12} className={classes.tableContainer}>
          <Table
            headers={headers}
            initialOrderId="folio"
            setNewDataOrder={setNegotiationData}
            data={negotiationData}
            dataToRows={transformDataToComponents}
            pagination={false}
          />
          <Grid container alignItems="flex-end" direction="column">
            <Typography color="error" gutterBottom className={classes.alignElement}>
              {validationMessage}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={validateResponse}
              className={classes.alignElement}
              endIcon={loading ? <CircularProgress size={14} /> : null}
            >
              Enviar respuesta
            </Button>
          </Grid>
        </Grid>
        <TwoButtonsAlert
          open={openOnEmptyRates}
          onClose={() => setOpenOnEmptyRates(false)}
          onAccept={() => {
            setOpenOnEmptyRates(false);
            submitResponse();
          }}
          title="Casillas en blanco"
          message="Hay filas con casillas en blanco para tasas que fueron requeridas a ser negociadas, lo cual significa que se está rechazando la negociación de éstas. ¿Continuar de todas formas?"
        />
      </Grid>
    </>
  );
};

export default RatesNegotiationRespond;
