import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    minWidth: 100,
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #a1a1a1',
    borderRadius: '1px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexFlow: 'column wrap',
  },
  arrow: {
    '&:before': {
      border: '1px solid #a1a1a1',
    },
    '&:after': {
      border: '1px solid #a1a1a1',
    },
    color: theme.palette.common.white,
    fontSize: '16px',
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 600,
    fontSize: '16px',
  },
  inactive: {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

const events = [
  {
    id: 'PROCESSED',
    cases: ['PROCESSED'],
    label: 'Procesando',
    color: '#3f9733',
  },
  {
    id: 'SENT',
    cases: ['DELIVERED'],
    label: 'Enviado',
    color: '#3f9733',
  },
  {
    id: 'OPEN',
    cases: ['OPEN'],
    label: 'Abierto',
    color: '#3f9733',
  },
  {
    id: 'CLICK',
    cases: ['CLICK'],
    label: 'Click',
    color: '#3f9733',
  },
  {
    id: 'NOT_ARRIVE',
    cases: ['GROUP_UNSUSCRIBE', 'UNSUSCRIBE', 'SPAMREPORT', 'DEFERRED', 'DROPPED', 'DROP', 'BOUNCE'],
    label: 'No llegó',
    color: 'red',
  },
];

const SendgridInfoTooltip = ({ actionEvents, children }) => {
  const classes = useStyles();
  const actionStatuses = actionEvents.map((event) => event?.status);

  const title = () => (
    <>
      {events.map((event) => {
        const statusInCases = actionStatuses.filter((status) => event.cases.includes(status));
        return (
          <Typography
            key={event.id}
            className={statusInCases.length ? classes.active : classes.inactive}
            style={{ color: statusInCases.length ? event.color : '#a9a9a9' }}
          >
            {event.label}
          </Typography>
        );
      })}
    </>
  );

  return (
    <StyledTooltip
      className={classes.tooltip}
      title={title()}
      placement="left"
      arrow
    >
      {children}
    </StyledTooltip>
  );
};

SendgridInfoTooltip.propTypes = {
  actionEvents: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

SendgridInfoTooltip.defaultProps = {
  actionEvents: [],
};

export default SendgridInfoTooltip;
