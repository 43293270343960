import { gql } from '@apollo/client';

export const GET_PENDING_COMPANY_ENROLLMENT_REQUESTS = gql`
  query GetPendingCompanyEnrollmentRequests {
    getPendingCompanyEnrollmentRequests {
      id
      company {
        id
        name
        rut
        rexInfo {
          rexStartActivities
          monthlyPayroll
          representativeName
          representativePhoneNumber
          representativeEmail
        }
      }
    }
  }
`;

export const GET_ACTIVE_ADVANCE_PAYMENTS = gql`
  query GetActiveAdvancePaymentsRequests {
    getActiveAdvancePaymentsRequests {
      id
      company {
        id
        name
        rut
      }
      naturalPersonName
      amount
      requestStatus
    }
  }
`;

export const RESOLVE_COMPANY_ENROLLMENT_REQUEST = gql`
  mutation ResolveCompanyEnrollmentRequest($requestIds: [Int]!, $requestApproved: Boolean!, $files: [Upload]) {
    resolveCompanyEnrollmentRequest(requestIds: $requestIds, requestApproved: $requestApproved, files: $files){
      success
      message
    }
  }
`;
