import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { MeetingRoomOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../context/user';
import textToRoute from '../../helpers/textToRoute';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    minWidth: '13%',
    top: 64,
  },
  list: {
    paddingTop: 20,
    paddingBottom: '5rem',
  },
}));

const fullStructure = [
  {
    title: 'Riesgo',
    subtitles: [
      'Evaluaciones de Riesgo',
      'Riesgo Cliente',
      'Algoritmo',
      'Blacklist',
    ],
  },
  {
    title: 'Fondos',
    subtitles: [
      'Descargas en fondos',
    ],
  },
  {
    title: 'Confirming',
    subtitles: ['Ingresar facturas', 'Confirming en proceso', 'Confirming disponible para giro', 'Confirming Deal'],
  },
  {
    title: 'Ratificación',
    subtitles: ['Ratificación con prioridad', 'Ratificación sin prioridad', 'Revisión de OC y HES'],
  },
  {
    title: 'Giro',
    subtitles: ['Disponible para giro'],
  },
  {
    title: 'Cobranza',
    subtitles: ['Cobranza simple', 'Cobranza judicial', 'Planes de Pago'],
  },
  {
    title: 'Conciliación',
    subtitles: ['Egresos', 'Ingresos'],
  },
  {
    title: 'Conciliación Anticipos',
    subtitles: ['Egresos Anticipos'],
  },
  {
    title: 'Comercial',
    subtitles: [
      'Contactabilidad',
      'Whitelist',
      'Mails Masivos',
      'Estado de clientes',
      'Gestor de clientes',
      'Gestor de ejecutivos',
      'Asignación de ejecutivos',
      'Administración de deudores',
      'Negociación de tasas',
    ],
  },
  {
    title: 'Deudores',
    subtitles: [
      'Maestro de deudores',
    ],
  },
  {
    title: 'Pronto Pago',
    subtitles: [
      'Empresas de Pronto Pago',
    ],
  },
  {
    title: 'Ordering',
    subtitles: [
      'Ingreso de facturas',
      'Ordenes externas',
      'Documentos de cesión pendientes',
      'Órdenes disponibles para giro',
    ],
  },
  {
    title: 'Antitcipos',
    subtitles: ['Solicitudes de activación', 'Solicitudes de anticipo'],
  },
  {
    title: 'Utils',
    subtitles: ['Crear Empresas', 'Crear Usuarios'],
  },
];

const LeftNavigation = ({ open, onClose, logout }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [newAllowedStructure, setNewAllowedStructure] = useState([]);

  useEffect(() => {
    if (user === null) {
      return;
    }
    const backofficeSubmenuPermissions = [...user.user.backofficeSubmenuPermissions];
    const createNewAllowedStructure = [];
    fullStructure.forEach((menu) => {
      const { title } = menu;
      const subtitles = [...menu.subtitles]
        .filter((subtitle) => backofficeSubmenuPermissions.includes(subtitle));
      if (subtitles.length) {
        createNewAllowedStructure.push({
          title,
          subtitles,
        });
      }
    });
    setNewAllowedStructure(createNewAllowedStructure);
  }, [user]);

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={onClose}
        variant="persistent"
      >
        <List className={classes.list}>
          {newAllowedStructure.map((item) => (
            <div key={item.title}>
              <ListItem>
                <Typography variant="subtitle1" gutterBottom>
                  <b>{item.title}</b>
                </Typography>
              </ListItem>
              {item.subtitles.map((subtitle) => (
                <Link
                  to={textToRoute[subtitle]}
                  key={subtitle}
                  onClick={onClose}
                >
                  <ListItem
                    selected={location.pathname === textToRoute[subtitle]}
                    button
                  >
                    <ListItemText primary={subtitle} inset />
                  </ListItem>
                </Link>
              ))}
            </div>
          ))}
          <>
            <Divider style={{ margin: '1rem' }} />
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <MeetingRoomOutlined />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </>
        </List>
      </Drawer>
    </>
  );
};

LeftNavigation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

LeftNavigation.defaultProps = {
  open: true,
};

export default LeftNavigation;
