import { Typography, Dialog, DialogTitle, DialogContent, Button, Select, MenuItem, Grid, CircularProgress, makeStyles } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '../../views/styles';
import { GET_FUNDS } from '../../gql/funding';

const internalUseStyles = makeStyles({
  input: {
    minWidth: '100%',
  },
});

const UploadRosterDialog = ({
  open,
  onClose,
  UploadRosterFileMutation,
  loading,
}) => {
  const classes = useStyles();
  const internalClasses = internalUseStyles();
  // UseStates
  const [uploadDocument, setUploadDocument] = useState();
  const [selectedFund, setSelectedFund] = useState();
  const [selectedCessionare, setSelectedCessionare] = useState();

  // Queries
  const { data, loading: loadingFunds } = useQuery(GET_FUNDS);

  const funds = data?.getFunds || [];

  // Mutations
  const handleUpload = useCallback(() => {
    UploadRosterFileMutation({
      variables: {
        file: uploadDocument,
        fundId: selectedFund.id,
        cessionareId: selectedCessionare.id,
      },
    });
  }, [uploadDocument, selectedFund, selectedCessionare, UploadRosterFileMutation]);

  const internalOnClose = useCallback(() => {
    setUploadDocument(null);
    setSelectedFund(null);
    onClose();
  }, [onClose]);

  const selectFund = useCallback((event) => {
    setSelectedFund(event.target.value);
  }, []);
  const selectCessionare = useCallback((event) => {
    setSelectedCessionare(event.target.value);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={internalOnClose}
    >
      <DialogTitle>
        <Typography variant="h6" align="justify">
          Subir nómina
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <Typography align="center">Seleccione un fondo</Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={selectedFund}
              onChange={selectFund}
              variant="outlined"
              className={internalClasses.input}
            >
              <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un fondo</MenuItem>
              {!loadingFunds && (
                funds.filter((fund) => fund.isFund).map((option) => (
                  <MenuItem key={option.id} value={option}>{option.socialReason}</MenuItem>
                )))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Typography align="center">Seleccione un cesionario</Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={selectedFund}
              onChange={selectCessionare}
              variant="outlined"
              className={internalClasses.input}
            >
              <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un cesionario</MenuItem>
              {!loadingFunds && (
                funds.filter((fund) => fund.isAsignee).map((option) => (
                  <MenuItem key={option.id} value={option}>{option.socialReason}</MenuItem>
                )))}
            </Select>
          </Grid>
        </Grid>
        {!loading ? (
          <Grid container spacing={2} direction="row">
            <Grid item xs={6}>
              <label htmlFor="contained-button-roster-file">
                <input
                  id="contained-button-roster-file"
                  type="file"
                  className={classes.hiddenInput}
                  onChange={(e) => { setUploadDocument(e.target.files[0]); }}
                />
                <Button
                  name="DOCUMENT"
                  size="small"
                  style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                  component="span"
                  color="primary"
                  variant="contained"
                  startIcon={<Publish />}
                >
                  <Typography noWrap>{(uploadDocument && uploadDocument.name) || 'Subir documento'}</Typography>
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="small"
                style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                component="span"
                color="primary"
                variant="contained"
                disabled={!uploadDocument || !selectedFund}
                onClick={handleUpload}
              >
                Subir documento
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="row">
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography color="primary">Subiendo nómina...</Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

UploadRosterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  UploadRosterFileMutation: PropTypes.func.isRequired,
};

export default UploadRosterDialog;
