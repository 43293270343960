import { gql } from '@apollo/client';

export const CREATE_MARKETING_CAMPAIGN = gql`
mutation createMarketingCampaign($mailList: [String]!, $sendingDate: DateTime!, $sendgridTemplateId: String!, $fromMail: String!){
    createMarketingCampaign(inputMarketingCampaign: {mailsList:$mailList, sendingDate:$sendingDate, sendgridTemplateId:$sendgridTemplateId, fromMail: $fromMail}){
      success
    }
  }
`;

export const GET_MARKETING_CAMPAIGNS = gql`
query getMarketingCampaigns{
  getMarketingCampaigns{
    id
    fromMail
    mailsList
    campaignSent
    campaignCanceled
    createdAt
    scheduledMailingDate
    sendgridTemplateId
  }
}`;

export const CANCEL_MARKETING_CAMPAIGN = gql`
  mutation cancelMarketingCampaigns($idMarketingCampaign:Int!){
    cancelMarketingCampaign(idMarketingCampaign:$idMarketingCampaign){
      success
    }
  }`;
