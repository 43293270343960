import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  IconButton,
  Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ArrowBackIos } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { Table } from '../components/tables';
import { ASSIGN_INVOICES_TO_ORDER } from '../gql/ordering';
import { calculateCommission } from '../helpers/factoringSimulation';
import { DOCUMENTS } from '../gql/invoices';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'company', label: 'Emisor factura', align: 'left' },
  { id: 'receiver', label: 'Deudor factura', align: 'left' },
  { id: 'amountWithIva', label: 'Monto factura', align: 'left' },
  { id: 'dateIssued', label: 'Fecha de emisión', align: 'left' },
  { id: 'dateToPay', label: 'Ingrese fecha de pago', align: 'left' },
  { id: 'button', label: '', align: 'left' },
];

const PurchaseOrderInvoices = () => {
  const classes = useStyles();
  const location = useLocation();
  const [invoices, setInvoices] = useState([]);
  const [invoicesAmount, setInvoicesAmount] = useState();
  const [selectedDteType, setSelectedDteType] = useState();
  const [openAddFolioDialog, setOpenAddFolioDialog] = useState(false);
  const [invoiceFolio, setInvoiceFolio] = useState();
  const { purchaseOrder } = location.state;

  const history = useHistory();
  const [getInvoiceByFolio] = useLazyQuery(DOCUMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.invoices.edges.length) {
        return;
      }
      const newInvoice = data.invoices.edges[0].node;
      const newInvoicesData = [...invoices];
      const sameFolioInvoice = newInvoicesData.filter((inv) => (
        inv.folio === newInvoice.folio
      ));
      if (sameFolioInvoice.length === 0) {
        newInvoicesData.push({
          ...newInvoice,
          dateToPay: moment(purchaseOrder.orderingoffer.invoiceDateToPay),
        });
        setInvoices(newInvoicesData);
        setInvoicesAmount(newInvoicesData.reduce((prev, curr) => prev + curr.amountWithIva, 0));
      }
    },
  });
  const [permormOrderingAssignment] = useMutation(ASSIGN_INVOICES_TO_ORDER, {
    onError: (error) => {
      history.push({
        pathname: '/app/ordering/add-invoices',
        state: {
          errorMessage: error.message,
        },
      });
    },
  });
  const handleAddFolio = () => {
    setOpenAddFolioDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenAddFolioDialog(false);
  };
  const handleOnChangeFolio = (event) => {
    setInvoiceFolio(event.target.value);
  };
  const handleDeleteInvoice = (fol) => {
    const newInvoicesData = [...invoices];
    const index = newInvoicesData.findIndex((inv) => inv.folio === fol);
    newInvoicesData.splice(index, 1);
    setInvoicesAmount(newInvoicesData.reduce((prev, curr) => prev + curr.amountWithIva, 0));
    setInvoices(newInvoicesData);
  };
  const handleSubmitInvoices = () => {
    const commission = calculateCommission(
      invoices[0].company.currentCommissionRules,
      invoicesAmount,
    );
    const dataToMutation = {
      variables: {
        orderId: purchaseOrder.id,
        orderingAssignmentRequest: invoices.map((invoice) => ({
          invoiceId: invoice.id,
          dateToPay: moment(invoice.dateToPay).format('YYYY-MM-DD'),
        })),
        isExternal: false,
        commission,
      },
    };
    permormOrderingAssignment(dataToMutation);
    history.push('/app/ordering/add-invoices');
  };
  const handleAddNewFolio = () => {
    getInvoiceByFolio({
      variables: {
        company_MasterEntity_Rut_In: purchaseOrder.company.rut,
        dteType_Code: selectedDteType,
        folio: invoiceFolio,
        availableForFinancing: 'AVAILABLE',
      },
    });
    setOpenAddFolioDialog(false);
  };

  const handleChangeDate = (date, id) => {
    const newInvoicesData = [...invoices];
    const invoiceChanged = newInvoicesData.find((inv) => inv.id === id);
    invoiceChanged.dateToPay = date;
    setInvoices(newInvoicesData);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      company: { name, rut },
      receiver: { name: nameReceiver, rut: rutReceiver },
      amountWithIva,
      dateIssued,
      dateToPay,
      dateExpiration,
    } = row;
    const momentDateExpiration = moment(dateExpiration);
    const daysUntilExpiration = momentDateExpiration.diff(moment().startOf('day'), 'days');
    const minDate = moment().add(15, 'days');
    const maxDate = moment().add(daysUntilExpiration + 30, 'days');
    return ({
      id,
      folio: {
        value: folio,
        component: (
          <Typography>{folio}</Typography>
        ),
      },
      company: {
        value: name,
        component: (
          <Box>
            <Typography>{name}</Typography>
            <Typography>{formatRut(rut)}</Typography>
          </Box>
        ),
      },
      receiver: {
        value: nameReceiver,
        component: (
          <Box>
            <Typography>{nameReceiver}</Typography>
            <Typography>{formatRut(rutReceiver)}</Typography>
          </Box>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <Typography>{moment(dateIssued).format('DD-MM-YYYY')}</Typography>
        ),
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <div className={classes.datePickerContainer}>
            <DatePicker
              autoOk
              allowKeyboardControl={false}
              style={{ width: '90%' }}
              disableToolbar
              variant="inline"
              format="DD-MM-YYYY"
              margin="none"
              inputVariant="outlined"
              minDate={minDate}
              maxDate={maxDate}
              value={dateToPay || null}
              onChange={(date) => handleChangeDate(date, id)}
              inputProps={{
                style: {
                  padding: 5,
                  fontSize: 12,
                  cursor: 'pointer',
                },
              }}
            />
            <br />
            {dateToPay && (
              <Typography variant="caption" align="left" style={{ color: '#417DFF' }}>
                En {dateToPay.diff(moment().startOf('day'), 'days')} días
              </Typography>
            )}
          </div>
        ),
      },
      button: {
        value: id,
        component: (
          <Button variant="contained" color="primary" onClick={() => handleDeleteInvoice(folio)}>Eliminar</Button>
        ),
      },
    });
  });
  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4" className={classes.header}><b>{purchaseOrder.company.name}</b></Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddFolio}
        >
          Agregar factura
        </Button>
      </Box>
      <Typography variant="h6"><b>Número orden de compra: {purchaseOrder.orderNumber}</b></Typography>
      <Typography variant="h6"><b>Monto financiado orden de compra: ${formatMoney(purchaseOrder.orderingoffer.orderingFinancedAmount)}</b></Typography>
      <Typography variant="h6"><b>Monto facturas ingresadas: ${formatMoney(invoicesAmount)}</b></Typography>
      {(invoices && invoices.length > 0) ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              headers={headers}
              setNewDataOrder={setInvoices}
              data={invoices}
              dataToRows={transformDataToComponents}
              initialOrderId="amountWithIva"
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">No hay ordenes de compra pendientes de factura</Typography>
      )}
      <Dialog
        open={openAddFolioDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ingresa el folio de la factura
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folio"
            type="text"
            onChange={(event) => handleOnChangeFolio(event)}
            fullWidth
            variant="standard"
          />
          <Select
            value={selectedDteType}
            label="Tipo DTE"
            onChange={(e) => setSelectedDteType(e.target.value)}
            fullWidth
          >
            <MenuItem value={null} disabled>Tipo de DTE</MenuItem>
            <MenuItem value={33}>Tipo 33 (Afecta IVA)</MenuItem>
            <MenuItem value={34}>Tipo 34 (Exenta IVA)</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNewFolio}
            disabled={!(!!invoiceFolio && !!selectedDteType)}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        disabled={
          !(
            invoices.length > 0
            && invoicesAmount >= purchaseOrder.orderingoffer.orderingFinancedAmount
          )
        }
        onClick={handleSubmitInvoices}
      >
        Asignar y ceder facturas
      </Button>
    </Box>
  );
};

export default PurchaseOrderInvoices;
