export const filterRuleResults = (riskEvaluationModel, type) => {
  if (riskEvaluationModel === null) {
    return null;
  }
  return riskEvaluationModel.ruleResults.filter((result) => result.modelType === type);
};

export const calculateRasFinalResult = (riskEvaluationModel) => {
  if (riskEvaluationModel === null) {
    return null;
  }
  return (
    (0.4 * riskEvaluationModel.rasClientResult
    + 0.4 * riskEvaluationModel.rasDebtorResult + 0.2
    * riskEvaluationModel.rasClientDebtorResult) || null
  );
};

export const calculateTreeFinalResult = (riskEvaluationModel) => {
  if (riskEvaluationModel === null) {
    return null;
  }
  return (

    riskEvaluationModel.treeClientResult
    && riskEvaluationModel.treeDebtorResult
    && riskEvaluationModel.treeClientDebtorResult
  );
};
