import React, { useState } from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_CONCILIABLE_ORDERS } from '../../gql/conciliations';
import { formatMoney } from '../../helpers/money';
import { getCollectionManagers, filterOrders } from '../../helpers/conciliation';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import IncomesTable from './incomesTable';
import { formatRut } from '../../helpers/generic';

const IncomesOrdering = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const {
    loading: purchaseOrdersLoading,
    refetch: refetchPurchaseOrders,
  } = useQuery(GET_CONCILIABLE_ORDERS, {
    onCompleted: (data) => {
      setPurchaseOrders(data.getConciliableOrders.map((order) => ({
        ...order, debtor: order.purchaser,
      })));
    },
  });

  const headersPurchaseOrders = [
    { id: 'orderNumber', label: 'Número de Orden', align: 'left' },
    { id: 'companyName', label: 'Razón Social Cliente', align: 'center' },
    { id: 'orderingFinancedAmount', label: 'Monto Financiado', align: 'center' },
  ];

  const confirmationHeadersPurchaseOrders = [
    { id: 'orderNumber', label: 'Número de Orden', align: 'left' },
    { id: 'companyName', label: 'Razón Social Cliente', align: 'center' },
    { id: 'orderingFinancedAmount', label: 'Monto Financiado', align: 'center' },
  ];

  const transformPurchaseOrderToComponents = (_data) => _data.map((row) => {
    const {
      id,
      orderNumber,
      company: {
        name: companyName,
        rut: companyRut,
      },
      orderingoffer: {
        orderingFinancedAmount,
      },
      orderingDebt: {
        debt,
      },
      collectionManager: {
        id: collectionManagerId,
      },
    } = row;
    return ({
      id,
      collectionManagerId,
      amountWithIva: {
        value: orderingFinancedAmount + debt,
      },
      orderNumber: {
        value: orderNumber,
        component: (
          <Typography>{orderNumber}</Typography>
        ),
        align: 'left',
      },
      companyName: {
        value: companyName,
        component: (
          <>
            <Typography>{companyName}</Typography>
            <Typography>{formatRut(companyRut)}</Typography>
          </>
        ),
        align_: 'center',
      },
      orderingFinancedAmount: {
        value: orderingFinancedAmount,
        component: (
          <Typography>${formatMoney(orderingFinancedAmount + debt)}</Typography>
        ),
        align: 'center',
      },
    });
  });

  if (purchaseOrdersLoading) {
    return (
      <LinearProgress />
    );
  }

  return (
    <IncomesTable
      objectsToCollectHeaders={headersPurchaseOrders}
      objectsToCollectConfirmationHeaders={confirmationHeadersPurchaseOrders}
      objectsToCollectData={purchaseOrders}
      transformObjectsToComponents={transformPurchaseOrderToComponents}
      objectsToCollectType="PURCHASEORDERS"
      getObjectsCollectionManagers={getCollectionManagers}
      refetchObjects={refetchPurchaseOrders}
      objectsFilterFunction={filterOrders}
    />
  );
};

export default IncomesOrdering;
