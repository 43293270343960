/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!){
    tokenAuth(email: $email, password: $password) {
      payload
      refreshExpiresIn
      token
      user {
        isSuperuser
        isStaff
        firstName
        lastName
        backofficeSubmenuPermissions
        userGroups
      }
      refreshToken
    }
  }
`;
