import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { Typography, Tooltip, Grid, LinearProgress, Button, IconButton } from '@material-ui/core';
import { Cached, CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { TwoButtonsAlert, AcceptCreditLineRequest } from '../components/dialogs';
import { formatRut } from '../helpers/generic';
import { useStyles } from './styles';
import { GET_CREDIT_LINE_REQUESTS } from '../gql/customer';
import { RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS } from '../gql/resolveRequests';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const headers = [
  { id: 'rut', label: 'Rut', align: 'left' },
  { id: 'companyName', label: 'Razón social', align: 'left' },
  { id: 'requestDate', label: 'Fecha última solicitud', align: 'right' },
  { id: 'actions', label: '', align: 'center' },
];

const Clients = () => {
  const classes = useStyles();
  const { data, refetch, error } = useQuery(GET_CREDIT_LINE_REQUESTS);
  const [resolveCreditLineEvaluationRequests] = useMutation(
    RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS,
  );
  const [stateData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    if (data) {
      const newData = data.getCreditLineEvaluationRequests
        .filter((item) => item.status === 'EVALUATING')
        .map((item) => ({
          id: item.id,
          rut: item.company.rut,
          companyName: item.company.name,
          requestDate: item.requestDate,
        }));
      setData(newData);
      setLoading(false);
    }
  }, [data]);

  const handleSelectRequest = (id, type) => {
    setSelectedRequest(stateData.find((request) => request.id === id));
    if (type === 'accept') setAcceptOpen(true);
    else setRejectOpen(true);
  };

  const handleAcceptCreditLine = async () => {
    setAcceptOpen(false);
    setLoading(true);
    try {
      await resolveCreditLineEvaluationRequests({
        variables: {
          creditLineEvaluationResolutions: [{ id: selectedRequest.id, status: 'Increased' }],
        },
      });
      refetch();
    } catch (acceptError) {
      // console.error(error);
    }
  };

  const handleRejectCreditLine = async () => {
    setRejectOpen(false);
    setLoading(true);
    try {
      await resolveCreditLineEvaluationRequests({
        variables: {
          id: selectedRequest.id,
          status: 'Rejected',
        },
      });
      refetch();
    } catch (rejectError) {
      // console.error(error);
    }
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const { id, rut, companyName, requestDate } = row;
    return {
      id,
      rut: {
        value: rut,
        component: <Typography>{formatRut(rut)}</Typography>,
      },
      companyName: {
        value: companyName,
        component: (
          <Tooltip title={companyName}>
            <Typography>{companyName}</Typography>
          </Tooltip>
        ),
      },
      requestDate: {
        value: requestDate,
        component: (
          <Typography align="right">{moment(requestDate).format('DD-MM-YYYY HH:mm')}</Typography>
        ),
      },
      actions: {
        value: null,
        component: (
          <div className={classes.center}>
            <Tooltip title="Aceptar solicitud">
              <IconButton onClick={() => handleSelectRequest(id, 'accept')}>
                <CheckCircleOutline style={{ color: '#4BB543' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rechazar solicitud">
              <IconButton onClick={() => handleSelectRequest(id, 'reject')}>
                <HighlightOff style={{ color: '#ff0033' }} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    };
  });

  const handleRefetch = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}>
          <b>Clientes que solicitaron evaluación de pre ofertas</b>
        </Typography>
        <Button color="primary" variant="contained" onClick={handleRefetch} endIcon={<Cached />}>
          Actualizar
        </Button>
      </div>
      {stateData.length > 0 ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              headers={headers}
              setNewDataOrder={setData}
              data={stateData}
              dataToRows={transformDataToComponents}
              initialOrderId="invoicesAmount"
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">
          No hay solicitudes pendientes
        </Typography>
      )}
      <TwoButtonsAlert
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onAccept={handleRejectCreditLine}
        message="Recuerda que debes enviar un mail a jmg@fingo.cl, esteban@fingo.cl y antonio@fingo.cl con la decisión. Además es muy importante que notifiques al cliente de la decisión"
        title="¿Rechazar la solicitud de línea?"
      />
      <AcceptCreditLineRequest
        open={acceptOpen}
        onClose={() => setAcceptOpen(false)}
        onAccept={handleAcceptCreditLine}
      />
    </div>
  );
};

export default Clients;
