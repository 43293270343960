import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  LinearProgress,
  IconButton,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { ArrowBackIos, Print as PrintIcon } from '@material-ui/icons';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
// import { getTimeDiffText } from '../helpers/date';
import { ACCEPT_PRE_OFFER_EVALUATION_REQUESTS } from '../gql/resolveRequests';
import {
  RISE_CREDIT_LINE,
  RISE_CREDIT_LINE_OVERDRAFT,
} from '../gql/customer';
import { OneButtonAlert, ConfirmPreOffers } from '../components/dialogs';
import SelectForm from '../components/utils/SelectForm';
import TextEditor from '../components/textEditor';
import DebtorCell from '../components/shared/DebtorCell';
import { GET_EVALUATION_SIMULATIONS } from '../gql/ordering';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'emittedDate', label: 'Emisión', align: 'right' },
  { id: 'expirationDate', label: 'Fecha vencimiento', align: 'right' },
  { id: 'amountWithIva', label: 'Monto factura', align: 'right' },
  { id: 'monthlyRate', label: 'Tasa 30 días', align: 'right' },
  { id: 'defaultRate', label: 'Mora 30 días', align: 'right' },
  { id: 'retentionRate', label: 'Ptje. retención', align: 'right' },
  { id: 'fundingDays', label: 'Días Financiamiento', align: 'right' },
  { id: 'ratification', label: 'Ratificación', align: 'right' },
  { id: 'checkDocuments', label: 'Revisar documentos', align: 'right' },
];
const ratificationReasons = [
  { label: 'Sin ratificación línea deudor', value: 'WHITHOUT_RATIFICATION_DEBTOR' },
  { label: 'Sin ratificación línea cliente', value: 'WHITHOUT_RATIFICATION_CLIENT' },
  { label: 'Ratificación obligatoria', value: 'WITH_RATIFICATION' }];

const CompanyPreOffersToAccept = () => {
  const location = useLocation();
  const { state: { selectedInvoices: incomingPreOffers } } = location;
  const clientCompany = incomingPreOffers[0].company;
  const incomingCompanies = incomingPreOffers.reduce((previousValue, value) => {
    previousValue.push({ name: value.receiver.name, rut: value.receiver.rut });
    return previousValue;
  }, [clientCompany]);
  const history = useHistory();
  const [acceptPreOfferEvaluationRequests] = useMutation(ACCEPT_PRE_OFFER_EVALUATION_REQUESTS, {
    refetchQueries: [GET_EVALUATION_SIMULATIONS],
  });
  const [riseCreditLine] = useMutation(RISE_CREDIT_LINE);
  const [riseCreditLineOverdraft] = useMutation(RISE_CREDIT_LINE_OVERDRAFT);
  const classes = useStyles();
  const [preOffers, setPreOffers] = useState(incomingPreOffers);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [operation, setOperation] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [error, setError] = useState('');
  const [processDataLoading, setProcessDataLoading] = useState(true);
  const [allRatificationsAssigned, setAllRatificationsAssigned] = useState(false);
  const [allRatesAssigned, setAllRatesAssigned] = useState(true);
  const [allCompanies, setAllCompanies] = useState(incomingCompanies);
  const [informClients, setInformClients] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const newPreOffers = incomingPreOffers.map((preOffer) => ({
      ...preOffer,
      ratification: '',
      reviewOc: false,
      reviewHes: false,
      reviewPortalScreenshot: false,
      fundingDays: 60,
      monthlyRate: preOffer.preoffer.monthlyRate,
      defaultRate: preOffer.preoffer.defaultRate,
      retentionRate: preOffer.preoffer.retentionRate,
      emittedDate: moment(preOffer.emittedDate),
      expirationDate: moment(preOffer.expirationDate),
    }));
    setPreOffers(newPreOffers);
    setProcessDataLoading(false);
  }, [incomingPreOffers]);

  useEffect(() => {
    /* All rows must have ratification */
    const newAllCompanies = allCompanies.map((company) => {
      const amountToFund = preOffers
        .filter((preOffer) => (
          preOffer.company.rut === company.rut
          || preOffer.rutReceiver === company.rut
        ))
        .reduce((acc, row) => {
          // Account for cases when funding goes to the credit line of the client
          if (company.rut === clientCompany.rut) {
            if (row.ratification === 'WHITHOUT_RATIFICATION_CLIENT') return acc + row.amountWithIva;
            return acc;
          }
          // Account for cases when funding goes to the credit line of the debtor
          if (row.ratification === 'WHITHOUT_RATIFICATION_DEBTOR'
            || row.ratification === 'WITH_RATIFICATION') return acc + row.amountWithIva;
          return acc;
        }, 0);
      const surpasedAmount = company.availableCreditLine - amountToFund < 0
        ? Math.abs(company.availableCreditLine - amountToFund)
        : 0;
      return {
        ...company,
        amountToFund,
        surpasedAmount,
        decided: surpasedAmount <= 0,
        overdraftChoice: 'OVERDRAFT',
        overdraftAmount: 0,
      };
    });
    setAllCompanies(newAllCompanies);
    const dataMissingRatification = preOffers.filter((row) => row.ratification === '');
    if (dataMissingRatification.length === 0) {
      setAllRatificationsAssigned(true);
    } else {
      setAllRatificationsAssigned(false);
    }
    /* All rows must have all rates */
    const dataMissingRates = preOffers.filter(
      (row) => (parseFloat(row.monthlyRate, 10) === 0)
        || row.defaultRate === ''
        || row.monthlyRate === ''
        || row.retentionRate === '',
    );

    if (dataMissingRates.length === 0) {
      setAllRatesAssigned(true);
    } else {
      setAllRatesAssigned(false);
    }
  }, [preOffers]);

  const transformDataToComponents = (_data) => {
    const handleChange = (event, id) => {
      const { name, value } = event.target;
      const newData = [..._data];
      newData.find((row) => row.id === id)[name] = value;
      setPreOffers(newData);
    };
    const handleCheck = (event, id) => {
      const { name, checked } = event.target;
      const newData = [..._data];
      newData.find((row) => row.id === id)[name] = checked;
      setPreOffers(newData);
    };

    return _data.map((row) => {
      const {
        id,
        folio,
        receiver: {
          id: receiverId,
          name: nameReceiver,
          rut: rutReceiver,
        },
        dateIssued,
        dateExpiration,
        amountWithIva,
        defaultRate,
        monthlyRate,
        retentionRate,
        pdf,
        ratification,
        fundingDays,
      } = row;
      return ({
        id,
        folio: {
          value: folio, // value is for table sorting
          component: (
            <div className={classes.alignRight}>
              <Typography>  {folio}</Typography>
              <Tooltip title="Descargar Factura">
                <a href={pdf} target="_blank" rel="noopener noreferrer">
                  <IconButton>
                    <PrintIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </div>
          ),
        },
        debtor: {
          value: rutReceiver,
          component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
        },
        emittedDate: {
          value: dateIssued,
          component: (
            <>
              <Typography align="right" variant="body2">{dateIssued}</Typography>
              {/* <Typography align="right" variant="body2"
              >{getTimeDiffText(emittedDate)}</Typography> */}
            </>
          ),
        },
        expirationDate: {
          value: dateExpiration,
          component: (
            <>
              <Typography align="right" variant="body2">{dateExpiration}</Typography>
              {/* <Typography align="right" variant="body2"
              >{getTimeDiffText(expirationDate)}</Typography> */}
            </>
          ),
        },
        amountWithIva: {
          value: amountWithIva,
          component: (
            <Typography align="right">${formatMoney(amountWithIva)}</Typography>
          ),
        },
        monthlyRate: {
          value: monthlyRate,
          component: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TextField
                style={{ maxWidth: 80 }}
                type="number"
                step=".01"
                value={monthlyRate}
                name="monthlyRate"
                variant="outlined"
                onChange={(event) => handleChange(event, id)}
                InputProps={{
                  classes: { adornedEnd: classes.adornedEnd },
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </div>
          ),
        },
        defaultRate: {
          value: defaultRate,
          component: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TextField
                style={{ maxWidth: 80 }}
                type="number"
                step=".01"
                value={defaultRate}
                name="defaultRate"
                variant="outlined"
                onChange={(event) => handleChange(event, id)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </div>
          ),
        },
        retentionRate: {
          value: retentionRate,
          component: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TextField
                style={{ maxWidth: 80 }}
                type="number"
                step=".01"
                value={retentionRate}
                name="retentionRate"
                variant="outlined"
                onChange={(event) => handleChange(event, id)}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </div>
          ),
        },
        fundingDays: {
          value: fundingDays,
          component: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TextField
                style={{ maxWidth: 80 }}
                type="number"
                step=".01"
                value={fundingDays}
                name="fundingDays"
                variant="outlined"
                onChange={(event) => handleChange(event, id)}
              />
            </div>
          ),
        },
        ratification: {
          value: ratification,
          component: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SelectForm
                reasons={ratificationReasons}
                inputText="Selecciona la ratificación"
                onChange={(event) => handleChange(event, id)}
                name="ratification"
              />
            </div>
          ),
        },
        checkDocuments: {
          component: (
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(event) => handleCheck(event, id)}
                    name="reviewOc"
                    disabled={row.ratification === '' || row.ratification === 'WITH_RATIFICATION'}
                  />
                )}
                label="Requiere OC"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(event) => handleCheck(event, id)}
                    name="reviewHes"
                    disabled={row.ratification === '' || row.ratification === 'WITH_RATIFICATION'}
                  />
                )}
                label="Requiere HES"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(event) => handleCheck(event, id)}
                    name="reviewPortalScreenshot"
                    disabled={row.ratification === '' || row.ratification === 'WITH_RATIFICATION'}
                  />
                )}
                label="Requiere pantallazo de portal"
              />
            </FormGroup>
          ),
        },
      });
    });
  };

  const sendOffer = async ({ allCompanies: propsAllCompanies }) => {
    setOfferLoading(true);
    const resolvedPreOffers = [];
    preOffers.forEach((preOffer) => {
      const {
        preoffer,
        monthlyRate,
        defaultRate,
        retentionRate,
        ratification,
        fundingDays,
        reviewHes,
        reviewOc,
        reviewPortalScreenshot,
      } = preOffer;
      resolvedPreOffers.push({
        id: preoffer.preofferevaluationrequest.id,
        ratification,
        monthlyRate,
        defaultRate,
        retentionRate,
        fundingDays,
        reviewOc,
        reviewHes,
        reviewPortalScreenshot,
        automaticDecision: sendEmail,
        hasReceptionAcknowledgment: false,
      });
    });
    try {
      // Rise credit or overdraft line for each company
      const overdraftPromises = [];
      propsAllCompanies.forEach((company) => {
        const { overdraftChoice, overdraftAmount, rut } = company;
        if (overdraftChoice === 'OVERDRAFT') {
          overdraftPromises.push(
            riseCreditLineOverdraft({
              variables: {
                rut,
                amountToRise: overdraftAmount,
              },
            }),
          );
        }

        if (overdraftChoice === 'RISE_LINE') {
          overdraftPromises.push(
            riseCreditLine({
              variables: {
                rut,
                amountToRise: overdraftAmount,
              },
            }),
          );
        }
      });
      await Promise.all(overdraftPromises);
      // Resolve pre offer requests
      await acceptPreOfferEvaluationRequests({
        variables: {
          resolution: {
            requests: resolvedPreOffers,
            analystDescription: comment,
            informClients,
            sendEmail,
          },
        },
      });
      setOfferLoading(false);
      setOpenConfirmation(false);
      setOpenAlert(true);
      setInformClients(true);
      setSendEmail(true);
      history.push({
        pathname: '/app/risk/evaluations',
        state: {
          tab: 0,
        },
      });
    } catch {
      setOfferLoading(false);
      setError('Ocurrió un error al aceptar las pre ofertas');
    }
  };

  if (processDataLoading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <div className={classes.titleAndUpdate}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4"><b>Aceptar preofertas de {clientCompany.name}</b></Typography>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} className={classes.tableContainer}>
          <Table
            data={preOffers}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setPreOffers}
            headers={headers}
            initialOrderId="amountWithIva"

          />
          <Grid container alignItems="flex-end" direction="column">
            {!allRatificationsAssigned && (
              <Typography color="error" gutterBottom>
                Debes seleccionar la ratificación para cada solicitud
              </Typography>
            )}
            {!allRatesAssigned && (
              <Typography color="error" gutterBottom>
                Debes asignar tasas a todas las solicitudes aceptadas
              </Typography>
            )}
            <Typography variant="h4">Comentarios</Typography>
            <TextEditor
              setDescription={setComment}
            />
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Typography>Informar al cliente de la decisión tomada</Typography>
              <Checkbox color="primary" checked={informClients} onChange={() => setInformClients((last) => !last)} />
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Typography>
                Enviar mail informando de aprobacion automática sin autorizacion de Gerente
              </Typography>
              <Checkbox color="primary" checked={sendEmail} onChange={() => setSendEmail((last) => !last)} />
            </Grid>
            <Button variant="contained" color="primary" onClick={() => { setOperation(true); setOpenConfirmation(true); }} disabled={!(allRatificationsAssigned && allRatesAssigned)}>
              Confirmar aprobación
            </Button>

          </Grid>

        </Grid>
      </Grid>
      {operation && (
        <ConfirmPreOffers
          loading={offerLoading}
          error={error}
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          handleConfirm={sendOffer}
          setAllCompanies={setAllCompanies}
          allCompanies={allCompanies}
          clients={[{
            rut: clientCompany.rut,
            socialReason: clientCompany.name,
          }]}
          informClient={informClients}
          handleCheckInformClient={() => setInformClients((last) => !last)}
        />
      )}
      <OneButtonAlert
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        title="Oferta guardada con éxito"
      />
    </div>
  );
};

export default CompanyPreOffersToAccept;
