const validEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validNumber = (number) => {
  const numberString = number.toString();
  if (['300', '600', '700', '800'].includes(numberString.slice(0, 3))
    && numberString.length === 10) {
    return true;
  }
  return numberString.length === 9;
};

const validRut = (rut) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(\d{6,9}-[\dkK])$/; // 6 to 9 digits, followed by an hyphen, and a digit, K or k.
  return re.test(rut);
};

export const validName = (name) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[A-Z]((\s[A-Z])?((\'|\-|\.)?([a-z]+)))*$/;
  return re.test(name);
};

// eslint-disable-next-line import/prefer-default-export
export const getFormFieldError = (value, type) => {
  switch (type) {
    case 'email':
      if (!validEmail(value)) {
        return { isError: true, message: 'Ingresa un email válido' };
      }
      return { isError: false, message: '' };
    case 'password':
      if (value.length < 4) {
        return { isError: true, message: 'Debe tener más de 4 caracteres' };
      }
      return { isError: false, message: '' };
    case 'phoneNumber':
      if (!validNumber(value)) {
        return {
          isError: true,
          message: 'Debe tener 9 dígitos, o 10 para números iniciados con 300, 600, 700, 800',
        };
      }
      return { isError: false, message: '' };
    case 'rut':
      if (!validRut(value)) {
        return { isError: true, message: 'Formato de rut inválido' };
      }
      return { isError: false, message: '' };
    default:
      return { isError: false, message: '' };
  }
};
