import { useMutation } from '@apollo/client';
import { Button, CircularProgress, Grid, MenuItem, Select, Snackbar, TextField, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import React, { useState } from 'react';
import { CREATE_EXTERNAL_PURCHASE_ORDER } from '../gql/ordering';
import { useStyles } from './styles';

const ExternalPurchaseOrder = () => {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState();
  const [formData, setFormData] = useState({
    companyRut: null,
    purchaserRut: null,
    orderNumber: null,
    origin: null,
    otherOrigin: null,
    publicationDate: null,
    amountWithoutIva: null,
    charges: null,
    discounts: null,
    taxAmount: null,
    totalAmount: null,
    pdfFile: null,
  });
  const [createExternalPurchaseOrder, {
    loading: mutationLoading,
  }] = useMutation(CREATE_EXTERNAL_PURCHASE_ORDER, {
    onCompleted: (data) => {
      if (data.createExternalPurchaseOrder.success) {
        setSnackbar('Orden creada correctamente');
      } else {
        setSnackbar(data.createExternalPurchaseOrder.message);
      }
    },
    onError: (error) => {
      setSnackbar(error.message);
    },
  });
  const isButtonDisabled = () => (
    !formData.companyRut
      || !formData.purchaserRut
      || !formData.orderNumber
      || !formData.publicationDate
      || !formData.amountWithoutIva
      || !formData.origin
      || !formData.charges
      || !formData.discounts
      || !formData.taxAmount
      || !formData.totalAmount
      || !formData.pdfFile
  );
  const onChange = (event) => {
    event.persist();
    setFormData((oldState) => ({
      ...oldState,
      [event.target.name]: event.target.name === 'pdfFile' ? event.target.files[0] : event.target.value,
    }));
  };
  const onSubmit = () => {
    const variables = {
      externalPurchaseOrders: [{
        ...formData,
        origin: formData.origin === 'Otro' ? formData.otherOrigin : formData.origin,
      }],
    };
    delete variables.externalPurchaseOrders[0].otherOrigin;
    createExternalPurchaseOrder({
      variables,
    });
  };

  return (
    <Grid container className={classes.container} direction="column" alignItems="center" justifyContent="center">
      <Grid item className={classes.container}>
        <Typography variant="h3" style={{ textAlign: 'center' }} className={classes.title}>
          Formulario para órdenes de compra externas
        </Typography>
        <br />
        <Typography variant="body1">
          • Las ordenes de compra no pueden ser menores a $60.000 o
          mayores a $350.000.000 IVA incluidos.
        </Typography>
        <Typography variant="body1">
          • La fecha de publicación no debe tener más de 60 días de antiguedad a partir de hoy.
        </Typography>
        <Typography variant="body1">
          • Debe estar en pesos chilenos.
        </Typography>
        <Typography variant="body1">
          • El formulario debe estar completo para poder enviarlo.
        </Typography>
      </Grid>
      <Grid item className={classes.container}>
        <Grid container direction="row">
          <Snackbar
            open={Boolean(snackbar)}
            autoHideDuration={null}
            onClose={() => setSnackbar(null)}
            message={snackbar}
          />
          <Typography>
            Ingresa el rut cliente sin dígito verificador:
          </Typography>
          <TextField
            autoFocus
            name="companyRut"
            value={formData.companyRut}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa el rut comprador sin dígito verificador:
          </Typography>
          <TextField
            name="purchaserRut"
            value={formData.purchaserRut}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa el origen de la orden de compra:
          </Typography>
          <Select
            name="origin"
            value={formData.origin}
            label="Origen OC"
            onChange={onChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="ChileCompra">ChileCompra</MenuItem>
            <MenuItem value="Otro">Otro</MenuItem>
          </Select>
        </Grid>
        { formData.origin === 'Otro' && (
        <Grid container direction="row" xs={6}>
          <Typography color="primary">
            Escribe el origen de la OC:
          </Typography>
          <TextField
            name="otherOrigin"
            value={formData.otherOrigin}
            margin="dense"
            type="text"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        )}
        <Grid container direction="row">
          <Typography>
            Ingresa número de orden de compra:
          </Typography>
          <TextField
            name="orderNumber"
            value={formData.orderNumber}
            margin="dense"
            type="text"
            fullWidth
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa fecha de publicación:
          </Typography>
          <TextField
            name="publicationDate"
            value={formData.publicationDate}
            margin="dense"
            type="date"
            fullWidth
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa el monto SIN IVA:
          </Typography>
          <TextField
            name="amountWithoutIva"
            value={formData.amountWithoutIva}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa cargos:
          </Typography>
          <TextField
            name="charges"
            value={formData.charges}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa descuentos:
          </Typography>
          <TextField
            name="discounts"
            value={formData.discounts}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa IVA:
          </Typography>
          <TextField
            name="taxAmount"
            value={formData.taxAmount}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid container direction="row">
          <Typography>
            Ingresa MONTO TOTAL:
          </Typography>
          <TextField
            name="totalAmount"
            value={formData.totalAmount}
            margin="dense"
            type="number"
            fullWidth
            onWheel={(e) => e.target.blur()}
            onChange={onChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'left', marginTop: '2rem' }}>
          <label htmlFor="contained-button-pdf-file">
            <input
              id="contained-button-pdf-file"
              type="file"
              name="pdfFile"
              className={classes.hiddenInput}
              onChange={onChange}
            />
            <Button
              name="DOCUMENT"
              size="small"
              style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
              component="span"
              color="primary"
              variant="contained"
              startIcon={<Publish />}
            >
              <Typography noWrap>{(formData.pdfFile && formData.pdfFile.name) || 'Adjuntar OC'}</Typography>
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid item className={classes.container} style={{ textAlign: 'center' }}>
        <Button color="primary" variant="contained" disabled={isButtonDisabled()} onClick={onSubmit}>
          Subir formulario
        </Button>
        {mutationLoading && <CircularProgress size={24} />}
      </Grid>
    </Grid>
  );
};

export default ExternalPurchaseOrder;
