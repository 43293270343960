import { useQuery } from '@apollo/client';

const useFilteredQuery = (...queryOptions) => {
  const { data, previousData, ...result } = useQuery(...queryOptions);
  return {
    data,
    previousData,
    usableData: data || previousData,
    ...result,
  };
};

export default useFilteredQuery;
