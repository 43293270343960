/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_NEGOTIATION = gql`
  mutation CreateNegotiation(
    $companyId: Int!,
    $negotiationRequests: [NegotiationRequestInputType]!,
    $negotiationType: NegotiationTypeEnum!,
    $negotiationComment: String!
    ) 
    {
        createNegotiation(
            companyId: $companyId,
            negotiationRequests: $negotiationRequests,
            negotiationType: $negotiationType,
            comment: $negotiationComment
        )
        {
            success
            message
        }
    }
`;

export const GET_PENDING_NEGOTIATIONS = gql`
query GetPendingNegotiations(
    $negotiationType: NegotiationTypeEnum!
)
{
    getPendingNegotiations(
        negotiationType:$negotiationType
    )
    {
        id
        company {
            id
            rut
            name
        }
        applicant {
            firstName
            lastName
        }
        negotiationType
        status
        comment
        createdAt
        requestsNumber
        negotiationRequests {
            id
            monthlyRate
            defaultRate
            retentionRate
            competitorRate
            competitorName
            simulationFileUrl
            offer {
              id
              monthlyRate
              defaultRate
              retentionRate
              invoice {
                folio
                amountWithIva {
                  amount
                  currency {
                    code
                  }
                } 
                receiver {
                  id
                  rut
                  name
                }
                dateIssued
                dateExpiration
              }
            }
            preoffer {
              id
              monthlyRate
              defaultRate
              retentionRate
              invoice {
                folio
                amountWithIva {
                  amount
                  currency {
                    code
                  }
                }
                receiver {
                  id
                  rut
                  name
                }
                dateIssued
                dateExpiration
              }
            }
        }
    }
}
`;

export const SUBMIT_NEGOTIATION_RESPONSE = gql`
mutation SubmitNegotiationResponse(
  $negotiationId: Int!,
  $negotiationResponses: [NegotiationResponseInputType]!
  $negotiationType: NegotiationTypeEnum!
) {
  submitNegotiationResponse(
    negotiationId: $negotiationId,
    negotiationResponses: $negotiationResponses,
    negotiationType: $negotiationType
    ) {
      success
      message
  }
}
`;
