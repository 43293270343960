import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Grid, Typography, Button, LinearProgress, InputAdornment, OutlinedInput, CircularProgress } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { SearchOutlined } from '@material-ui/icons';
import { useStyles } from './styles';
import MovementsTable from './MovementsTable';
import { GET_MOVEMENTS, GET_PAYSHEETS, CONCILIATE_PAYSHEET } from '../gql/conciliations';
import { Table } from '../components/tables';
import { getIds } from '../helpers/conciliation';
import ConciliationConfirmation from '../components/dialogs/ConciliationConfirmation';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import { formatMoney } from '../helpers/money';
import ConciliaitonSuccessful from '../components/dialogs/ConciliationSuccessful';

const PaysheetExpenses = () => {
  const classes = {
    ...useStyles(),
  };

  const [paysheets, setPaysheets] = useState([]);
  const [selectedPaysheets, setSelectedPaysheets] = useState([]);
  const [totalPaysheetsSelected, setTotalPaysheetsSelected] = useState(0);
  const [selectedMoves, setSelectedMoves] = useState([]);
  const [totalMoveSelected, setTotalMoveSelected] = useState(0);
  const [reset, setReset] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [filter, setFilter] = useState('');
  const [loadingConciliation, setLoadingConciliation] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const {
    data: paysheetsData,
    loading: paysheetsLoading,
    refetch: refetchPaysheets,
  } = useQuery(GET_PAYSHEETS);

  const {
    data: movementsData,
    loading: movementsLoading,
    refetch: refetchMovements,
    error: movementsError,
  } = useQuery(GET_MOVEMENTS, { variables: { movementType: 'expenses' } });

  const [conciliate] = useMutation(CONCILIATE_PAYSHEET);

  useEffect(() => {
    if (paysheetsData) {
      setPaysheets(paysheetsData.getPaysheets);
    }
  }, [paysheetsData]);

  useEffect(() => {
    setTotalPaysheetsSelected(selectedPaysheets.reduce(
      (total, row) => total + row.amount.value, 0,
    ));
  }, [selectedPaysheets]);

  useEffect(() => {
    setTotalMoveSelected(selectedMoves.reduce((total, row) => total + row.amount.value, 0));
  }, [selectedMoves]);

  useEffect(() => {
    if (paysheetsData) {
      setPaysheets(paysheetsData.getPaysheets.filter((paysheet) => {
        const idCondition = paysheet.id.toString().includes(filter);
        const amountCondition = paysheet.amount.toString().includes(filter);
        return idCondition || amountCondition;
      }));
    }
  }, [filter]);

  const headersRequests = [
    { id: 'paysheetId', label: 'ID', align: 'left' },
    { id: 'amount', label: 'Monto', align: 'left' },
    { id: 'amountOfPayments', label: 'Cantidad de anticipos', align: 'left' },
  ];

  const transformRequestToComponents = (_data) => _data.map((row) => {
    const { id, amount, amountOfPayments } = row;
    return ({
      id,
      paysheetId: {
        value: id,
        component: (
          <Typography>{id}</Typography>
        ),
      },
      amount: {
        value: amount,
        component: (
          <Typography>${formatMoney(amount)}</Typography>
        ),
      },
      amountOfPayments: {
        value: amountOfPayments,
        component: (
          <Typography>{amountOfPayments}</Typography>
        ),
      },
    });
  });

  const conciliationAction = async () => {
    setLoadingConciliation(true);
    await conciliate({ variables: {
      movementsIds: getIds(selectedMoves),
      paysheetsIds: getIds(selectedPaysheets),
    } });
    setReset(true);
    refetchMovements();
    refetchPaysheets();
    setSelectedPaysheets([]);
    setSelectedMoves([]);
    setReset(false);
    setOpenDialog(false);
    setWarnings([]);
    setLoadingConciliation(false);
    setSuccessDialog(true);
  };

  const match = () => {
    const diffs = [];
    if (Math.abs(totalPaysheetsSelected) !== Math.abs(totalMoveSelected)) {
      diffs.push('Los montos totales no calzan');
    }
    setWarnings([...diffs]);
    return diffs;
  };

  const handleSelectedMoves = (moves) => {
    setSelectedMoves(moves);
  };

  if (permissionDeniedErrorCatcher(movementsError)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Box>
        <Grid container spacing={1} className={classes.conciliations}>
          <Grid item xs={6}>
            <MovementsTable
              movementType="expenses"
              setSelectedMoves={handleSelectedMoves}
              movementsData={movementsData ? movementsData.getMovements : []}
              movementsLoading={movementsLoading}
              reset={reset}
              totalSelected={totalMoveSelected}
              refetchMovements={refetchMovements}
              resetSelectedMoves={() => setSelectedMoves([])}
            />
          </Grid>
          <Grid item xs={6}>
            <Box component="div" className={classes.movements}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h4" className={classes.tableHeader}>
                    <b>Solicitudes de giro de anticipos</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    id="input-with-icon-adornment"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                      )}
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </Grid>
              </Grid>
              {paysheetsLoading ? <LinearProgress /> : (
                <>
                  {paysheets.length > 0 ? (
                    <>
                      <Box border={0.5} borderColor="grey.500" className={classes.tableBox}>
                        <Table
                          data={paysheets}
                          dataToRows={transformRequestToComponents}
                          setNewDataOrder={setPaysheets}
                          headers={headersRequests}
                          initialOrderId="receiverName"
                          handleSelected={setSelectedPaysheets}
                          withSelect
                          withoutSelectHeader
                          rowsPerPageInit={100}
                          clearSelection={reset}
                          pagination={false}
                        />
                      </Box>
                      <Grid container justify="space-between" className={classes.totalSelected}>
                        <Grid item>
                          <Typography variant="h5">Total seleccionado:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">${formatMoney(totalPaysheetsSelected)}</Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="h5" align="center" className={classes.listEmpty}>
                      {filter ? 'No hay coincidencias' : 'No hay solicitudes sin conciliar'}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" className={classes.buttonLine}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={
                selectedMoves.length === 0 || selectedPaysheets.length === 0 || loadingConciliation
              }
              onClick={() => {
                if (match().length === 0) {
                  conciliationAction();
                } else {
                  setOpenDialog(!openDialog);
                }
              }}
            >
              Conciliar
              {loadingConciliation && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConciliationConfirmation
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        warnings={warnings}
        conciliationAction={conciliationAction}
        loadingConciliation={loadingConciliation}
        setLoadingConciliation={setLoadingConciliation}
      />
      <ConciliaitonSuccessful
        open={successDialog}
        setOpen={setSuccessDialog}
      />
    </>
  );
};

export default PaysheetExpenses;
