import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from '@material-ui/core';

import { ASSIGN_EXECUTIVE_TO_CLIENT, GET_COMMERCIAL_EXECUTIVES } from '../../gql/customer';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
  select: {
    width: '20vw',
  },
});

const AssignmentExecutiveClient = ({
  open,
  handleClose,
  handleConfirm,
  selectedRows,
}) => {
  const classes = useStyles();
  const [executive, setExecutive] = useState('choose');
  const [successOperation, setSuccessOperation] = useState(false);
  const [errorOperation, setErrorOperation] = useState(false);
  const { data: executivesData,
  } = useQuery(GET_COMMERCIAL_EXECUTIVES,
    { fetchPolicy: 'network-only' });
  const [executivesList, setExecutivesList] = useState([]);
  const [confirmButton, setConfirmButton] = useState(true);
  const [assignExecutiveMutation,
    {
      loading: loadingAssignExecutive,
    }] = useMutation(ASSIGN_EXECUTIVE_TO_CLIENT);

  useEffect(() => {
    if (executivesData && executivesData.getCommercialExecutives) {
      const executives = executivesData.getCommercialExecutives.map((ex) => (
        { label: ex.firstName, value: ex.email }));
      setExecutivesList(executives);
    }
  }, [executivesData]);

  const handleChange = (event) => {
    const { target: { value } } = event;
    setExecutive(value);
    setConfirmButton(false);
  };
  const handleConfirmModal = async () => {
    try {
      await assignExecutiveMutation(
        {
          variables: {
            companyRutList: selectedRows.map((company) => company.companyRut.value),
            executiveMail: executive,
          },
        },
      );
      handleConfirm();
      setSuccessOperation(true);
    } catch (_error) {
      setErrorOperation(true);
    }
  };
  const handleModalClose = () => {
    // set init state
    setExecutive('choose');
    setSuccessOperation(false);
    setErrorOperation(false);
    handleClose();
  };
  return (
    <>
      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle id="alert-dialog-title"><b>¿Qué ejecutivo quieres agregar a estos clientes?</b></DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <div className={classes.section}>
            <FormControl className={classes.margin}>
              <InputLabel id="executive"> </InputLabel>
              <Select
                labelId="executive"
                id="id-select"
                value={executive}
                name="executive"
                onChange={handleChange}
                className={classes.select}
                variant="outlined"
              >
                <MenuItem value="choose" disabled>Elige a un ejecutivo</MenuItem>
                {executivesList.map((_executive) => (
                  <MenuItem value={_executive.value} key={_executive.value}>
                    {_executive.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {errorOperation && <Typography color="error">Hubo un error para asignar el ejecutivo. Intenta de nuevo</Typography>}
          {successOperation && <Typography color="primary">Ejecutivo asignado con éxito</Typography>}
        </DialogContent>
        <DialogActions>
          <Button disabled={loadingAssignExecutive} onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button disabled={loadingAssignExecutive || confirmButton || successOperation} onClick={handleConfirmModal} color="primary" autoFocus>
            Confirmar
            {loadingAssignExecutive && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
AssignmentExecutiveClient.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  // eslint-disable-next-line
  selectedRows: PropTypes.arrayOf(PropTypes.obj),
};

AssignmentExecutiveClient.defaultProps = {
  selectedRows: [],
};

export default AssignmentExecutiveClient;
