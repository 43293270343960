import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { Check, Close } from '@material-ui/icons';
import { useStyles } from '../../views/styles';
import { GET_PENDING_COLLECTION_PAYMENT_PLANS } from '../../gql/collection';
import { DateWithDaysLeft } from '../utils';
import { formatMoney } from '../../helpers/money';
import { getCollectionManagers, filterInvoices } from '../../helpers/conciliation';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import IncomesTable from './incomesTable';

const useOwnStyles = makeStyles((theme) => ({
  greenColorIcon: {
    color: theme.palette.success.main,
    marginRight: '0.5rem',
  },
  redColorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem',
  },
  installmentAmount: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const IncomesPaymentPlan = () => {
  const classes = {
    ...useStyles(),
    ...useOwnStyles(),
  };

  const [conciliableInvoices, setConciliableInvoices] = useState([]);

  const {
    data: invoicesData,
    loading: invoicesLoading,
    refetch: refetchInvoices,
  } = useQuery(GET_PENDING_COLLECTION_PAYMENT_PLANS);

  useEffect(() => {
    if (invoicesData) {
      const { getPendingCollectionObjects: { confirmingInvoices, invoices } } = invoicesData;
      const allInvoices = confirmingInvoices.concat(invoices);
      setConciliableInvoices(allInvoices.map((invoice) => (
        { ...invoice, debtor: invoice.receiver }
      )));
    }
  }, [invoicesData]);

  const headersInvoices = [
    { id: 'folio', label: 'Folio', align: 'left' },
    { id: 'receiverName', label: 'Razón Social Receptor', align: 'left' },
    { id: 'amountWithIva', label: 'Monto', align: 'left' },
  ];

  const confirmationHeadersInstallments = [
    { id: 'amountWithIva', label: 'Monto cuota' },
    // { id: 'debtAmount', label: 'Monto mora' },
    { id: 'expirationDate', label: 'Fecha de vencimiento' },
    { id: 'paid', label: 'Pagado' },
    { id: 'actualDate', label: 'Fecha de Pago', align: 'left' },
  ];

  const transformInvoiceToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      amountWithIva,
      actualAmortizationSchedule: { installments: collapseData },
    } = row;
    const {
      name: receiverName,
      rut,
      dv,
    } = row.receiver.masterEntity || row.receiver;
    // REVISAR
    const fullRut = rut.toString() + dv;
    return ({
      id,
      collapseData: {
        collapseTitle: 'Cuotas',
        headers: [
          { id: 'amountWithIva', label: 'Monto cuota a pagar' },
          { id: 'expirationDate', label: 'Fecha de vencimiento' },
          { id: 'paid', label: 'Pagado' },
        ],
        components: collapseData.map((rowCollapse) => {
          const {
            installmentAmountToPay,
            expirationDate,
            installmentManager: {
              collectionManager: { id: idCollapse },
              status: statusInstallmentManager, totalAmountToPay },
            debtAmount,
            paymentDate,
          } = rowCollapse;
          const debt = Boolean(debtAmount);
          const paid = statusInstallmentManager === 'FINISHED';
          return ({
            dateToPay: { value: expirationDate },
            id: idCollapse,
            collectionManagerId: idCollapse,
            fullRut,
            selectedRow: debt,
            disabled: paid,
            checked: paid,
            amountWithIva: {
              component: (
                <div className={classes.installmentAmount}>
                  <Typography>${formatMoney(totalAmountToPay)}</Typography>
                  <Typography variant="caption">Monto cuota original: ${formatMoney(installmentAmountToPay)}</Typography>
                  <Typography variant="caption">Monto mora: ${formatMoney(debtAmount)}</Typography>
                </div>
              ),
              value: installmentAmountToPay,
            },
            expirationDate: {
              component: (<DateWithDaysLeft date={moment(expirationDate)} />),
              value: expirationDate,
            },
            paid: {
              value: paid,
              component: (
                paid ? <Tooltip title={`Fecha de pago: ${moment(paymentDate)}`}><Check className={classes.greenColorIcon} /></Tooltip>
                  : <Close className={classes.redColorIcon} />),
            },
          });
        }),
      },
      folio: {
        value: folio,
        component: (
          <Typography>{folio}</Typography>
        ),
      },
      receiverName: {
        value: receiverName,
        component: (
          <Typography className={classes.twoLineClamp}>{receiverName}</Typography>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      fullRut,
    });
  });

  if (invoicesLoading) {
    return (<LinearProgress />);
  }

  return (
    <IncomesTable
      objectsToCollectHeaders={headersInvoices}
      objectsToCollectConfirmationHeaders={confirmationHeadersInstallments}
      objectsToCollectData={conciliableInvoices}
      transformObjectsToComponents={transformInvoiceToComponents}
      objectsToCollectType="INSTALLMENTS"
      getObjectsCollectionManagers={getCollectionManagers}
      refetchObjects={refetchInvoices}
      objectsFilterFunction={filterInvoices}
    />
  );
};

export default IncomesPaymentPlan;
