/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/prefer-default-export */
export class DefaultDict {
  constructor(DefaultInit) {
    return new Proxy({}, {
      get: (target, name) => (
        name in target
          ? target[name]
          : (
            target[name] = typeof DefaultInit === 'function'
              ? new DefaultInit().valueOf()
              : DefaultInit
          )),
    });
  }
}
