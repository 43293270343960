export const turnoverKeyToSpanish = {
  avgInvoiceAmount: 'Monto Fx promedio',
  monthlyAmount: 'Monto emitido',
  bankingAssignmentAmount: 'Monto cedido B',
  nonBankingAssignmentAmount: 'Monto cedido no B',
  creditNotesAmount: 'Monto notas de crédito',
  bankingAssignedOverTotal: 'Porcentaje cedido B',
  nonBankingAssignedOverTotal: 'Porcentaje cedido no B',
  creditOverTotal: 'Porcentaje notas de crédito',
};

export const addressTypeToSpanish = {
  virtualOffice: 'Oficina virtual',
  physicalOffice: 'Oficina física',
};

export const contactTypeToSpanish = {
  RATIFICATION: 'Ratificación',
  COLLECTION: 'Cobranza',
  COMMERCIAL: 'Comercial',
};

export const contactSourceToSpanish = {
  PROVIDED_BY_CLIENT: 'Cliente',
  OBTAINED_BY_FINGO: 'Fingo',
};

export const riskErrorToSpanish = (riskError) => {
  switch (riskError) {
    case 'Rut does not belong to a company with accepted terms':
      return 'Esa empresa no ha firmado términos y condiciones';
    default:
      return 'Error de conexión';
  }
};

export const ruleVariantToText = {
  MIN: 'mínima',
  MAX: 'máxima',
  PERCENTAGE: 'variable',
  FIXED: 'fija',
};
