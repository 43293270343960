/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { ruleVariantToText } from '../../helpers/translators';
import { capitalize } from '../../helpers/generic';

const ruleVariants = [
  'MIN',
  'MAX',
  'PERCENTAGE',
  'FIXED',
];

const EMPTY_FORM_DATA = {
  variant: '',
  value: 0,
  threshold: 0,
  expirationDate: null,
};

const AddOrEditCommissionRule = ({ open, onClose, onAccept, ruleToEdit }) => {
  const [formData, setFormData] = useState(ruleToEdit || EMPTY_FORM_DATA);

  useEffect(() => {
    setFormData(ruleToEdit || EMPTY_FORM_DATA);
  }, [ruleToEdit]);

  const handleChange = (e) => {
    const { target: { name, value } } = e;
    if (name === 'variant' && value === 'FIXED') {
      setFormData((oldFormData) => ({
        ...oldFormData,
        [name]: value,
        expirationDate: new Date(),
      }));
      return;
    }
    if (name === 'variant' && value === 'MAX') {
      setFormData((oldFormData) => ({
        ...oldFormData,
        [name]: value,
        threshold: 1000000000,
      }));
      return;
    }
    setFormData((oldFormData) => ({ ...oldFormData, [name]: value }));
  };

  const handleDateChange = (date) => {
    setFormData((oldFormData) => ({ ...oldFormData, expirationDate: date }));
  };

  const handleAccept = () => {
    onAccept(formData);
  };

  const buttonDisabled = () => (
    !formData.variant
    || !formData.value
    || parseFloat(formData.value, 10) < 0
  );

  const getFields = () => {
    const {
      variant,
      value,
      threshold,
      expirationDate,
    } = formData;

    // eslint-disable-next-line react/prop-types
    const AmountField = (
      <Grid item xs={6}>
        <Typography>Monto (Sin IVA)</Typography>
        <TextField
          type="number"
          name="value"
          value={value}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    );

    const ThresholdField = (
      <Grid item xs={6}>
        <Typography>Umbral</Typography>
        <TextField
          type="number"
          name="threshold"
          value={threshold}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    );

    const DateField = (
      <Grid item xs={6}>
        <Typography>Fecha de caducidad</Typography>
        <KeyboardDateTimePicker
          variant="dialog"
          format="DD/MM/YYYY HH:mm"
          margin="normal"
          inputVariant="outlined"
          disablePast
          ampm={false}
          value={expirationDate}
          onChange={handleDateChange}
        />
      </Grid>
    );

    const PercentageField = (
      <Grid item xs={12}>
        <Typography>Porcentaje sobre el monto financiado</Typography>
        <TextField
          type="number"
          name="value"
          value={value}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    );

    if (variant === 'MIN') {
      return (
        <>
          {AmountField}
          {ThresholdField}
        </>
      );
    }
    if (variant === 'MAX') {
      return (
        <>
          {AmountField}
        </>
      );
    }
    if (variant === 'FIXED') {
      return (
        <>
          {AmountField}
          {DateField}
        </>
      );
    }
    if (variant === 'PERCENTAGE') {
      return (
        <>
          {PercentageField}
        </>
      );
    }
    return null;
  };

  const {
    variant,
  } = formData;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {ruleToEdit
          ? '¿Qué quieres modificar?'
          : '¿Qué regla quieres agregar?'}
      </DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Tipo de regla</Typography>
            <FormControl variant="outlined" style={{ minWidth: '100%', marginBottom: '1rem' }}>
              <Select
                value={variant}
                onChange={handleChange}
                name="variant"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  Elige el tipo de regla
                </MenuItem>
                {ruleVariants.map((ruleVariant) => (
                  <MenuItem value={ruleVariant} key={ruleVariant}>
                    {capitalize(ruleVariantToText[ruleVariant])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid container spacing={2}>
              {getFields()}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleAccept}
          color="primary"
          disabled={buttonDisabled()}
        >
          {ruleToEdit
            ? 'Editar regla'
            : 'Agregar regla'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddOrEditCommissionRule.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  ruleToEdit: PropTypes.shape({
    variant: PropTypes.string,
    value: PropTypes.number,
    threshold: PropTypes.number,
    expirationDate: PropTypes.instanceOf(Date),
  }),
};

AddOrEditCommissionRule.defaultProps = {
  ruleToEdit: null,
};

export default AddOrEditCommissionRule;
