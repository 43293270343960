import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  Badge,

} from '@material-ui/core';
import { InsertDriveFile, Message, Block } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_CESIONS } from '../../gql/invoicesStatus';
import calculateInvoiceAmounts from '../../helpers/invoiceAmountsCalculation';
import { formatMoney } from '../../helpers/money';
import { getTimeDiffText, getTimePassedFromDate, getTimePassedTextFromTime } from '../../helpers/date';
import { UploadDocumentsManager } from '../dialogs';
import { Table } from '../tables';
import Status from './Status';
import { CollectionActionsDrawer } from '../collection';
import { Filters } from '../utils';
import DebtorCell from '../shared/DebtorCell';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  emptyInvoices: {
    marginTop: '2rem',
  },
});

const headers = [
  { id: 'dateOperation', label: 'Fecha de operación', className: 'md', type: 'date' },
  { id: 'folio', label: 'Folio', className: 'sm', type: 'text' },
  { id: 'nameReceiver', label: 'Deudor', className: 'md', type: 'longText' },
  { id: 'collectorName', label: 'Cobrador', className: 'md', type: 'longText' },
  { id: 'dateToPay', label: 'Fecha de pago acordada', className: 'md', type: 'date', tooltip: 'Tiempo de financiamiento' },
  { id: 'amountWithIva', label: 'Monto factura', className: 'md', type: 'money' },
  { id: 'surplus', label: 'Condiciones', className: 'md', type: 'surplus', extraDataKey: 'retentionAmount', extraDataKey2: 'debt' },
  { id: 'timeInStage', label: 'Tiempo en la etapa', className: 'md', type: 'date' },
  { id: 'status', label: 'Estado', className: 'lg', type: 'status', extraDataKey: 'paymentDate', align: 'center' },
  { id: 'actions', label: 'Gestiones', align: 'center' },
  { id: 'documents', label: 'Carga Documentos', align: 'center', hideSortIcon: true },
];

const InvoicesInOperation = ({ setLoading }) => {
  const classes = useStyles();
  const { clientRut } = useParams();
  const { state } = useLocation() || {};
  const { name } = state || {};
  const [cessions, setCessions] = useState([]);
  const [filteredCessions, setFilteredCessions] = useState([]);
  const { data, loading: loadingCesions } = useQuery(GET_CESIONS, {
    fetchPolicy: 'network-only',
    variables: { rut: clientRut },
  });
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [actionsDrawerInvoice, setActionsDrawerInvoice] = useState({
    id: '',
    name: '',
    folio: 0,
    nameReceiver: '',
    actions: [],
    rutReceiver: 0,
    companyRut: 0,
    companyName: '',
    managerId: null,
    dataForCollection: {
      dateToPay: null,
      paymentMethod: null,
      collectionContacts: [],
    },
    reminder: {
      reminderDate: null,
      reminderNote: null,
    },
  });

  const onCloseActionsDrawer = () => {
    setActionsDrawerOpen(false);
    setActionsDrawerInvoice({
      name: '',
      folio: '',
      amount: 0,
      nameReceiver: '',
      actions: [],
      rutReceiver: 0,
      companyRut: 0,
      companyName: '',
      managerId: null,
      dataForCollection: {
        dateToPay: null,
        paymentMethod: null,
        collectionContacts: [],
      },
      reminder: {
        reminderDate: null,
        reminderNote: null,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const mappedCessions = data.getCessions.map((invoice) => {
        const {
          dateToPay,
          dateOperation,
          folio,
          pdf,
          dateIssued,
          dateExpiration,
          company,
          amountWithIva,
          offer,
          surplusRetention,
          surplusDebt,
          surplusSurplus,
          lastHistoryStatus,
          collectionManager,
          ratificationmanager,
        } = invoice;
        const { monthlyRate, defaultRate, retentionRate } = offer || {};
        const realRetentionRate = retentionRate || 0;
        const momentDateToPay = moment(dateToPay, 'YYYY-MM-DD');
        const momentDateOperation = dateOperation ? moment(dateOperation, 'YYYY-MM-DD') : moment().subtract(10, 'days').startOf('day');
        const daysBetweenPayAndOperation = momentDateToPay.diff(momentDateOperation, 'days');
        const {
          id,
          actions,
          currentDataForCollection: dataforcollection,
          reminder,
        } = collectionManager || {};
        const { actions: ratificationManagerActions } = ratificationmanager || {};
        const invoiceData = {
          days: daysBetweenPayAndOperation,
          retentionRate: realRetentionRate,
          monthlyRate,
          amountWithIva,
        };
        const {
          effectiveRate,
          effectiveDefaultRate,
          advanceAmount,
          amountMinusInterests,
          priceDifference,
        } = calculateInvoiceAmounts(invoiceData);
        return ({
          ...invoice,
          effectiveRate,
          effectiveDefaultRate,
          amountMinusInterests,
          advanceAmount,
          pdf,
          monthlyRate,
          defaultRate,
          priceDifference,
          retentionAmount: surplusRetention,
          debt: surplusDebt,
          dateToPay: momentDateToPay,
          dateOperation: momentDateOperation,
          folio: folio.toString(),
          dateIssued: moment(dateIssued, 'YYYY-MM-DD'),
          dateExpiration: moment(dateExpiration, 'YYYY-MM-DD'),
          companyRut: company.rut,
          surplus: surplusSurplus,
          advancePercentage: 100 - realRetentionRate,
          timeInStage: lastHistoryStatus ? getTimePassedFromDate(lastHistoryStatus.createdAt) : 0,
          actions,
          ratificationManagerActions,
          dataForCollection: dataforcollection,
          reminder,
          managerId: id,
        });
      });
      setCessions(mappedCessions);
      setFilteredCessions(mappedCessions);
      if (actionsDrawerInvoice.folio) {
        const openedInvoice = data.getCessions.find((element) => (
          element.id === actionsDrawerInvoice.managerId
        ));
        setActionsDrawerInvoice((oldInvoice) => ({
          ...oldInvoice,
          actions: openedInvoice.actions ? openedInvoice.actions : [],
          dataForCollection: openedInvoice.currentDataForCollection,
          reminder: openedInvoice.reminder,
        }));
      }
    }
  }, [data]);

  useEffect(() => {
    setLoading(loadingCesions);
  }, [loadingCesions]);

  const openPdf = (pdf) => {
    window.open(pdf);
  };

  const onClickActions = ({
    id,
    folio,
    nameReceiver,
    actions,
    rutReceiver,
    companyRut,
    dataForCollection,
    reminder,
    collectionManager,
    amount,
  }) => {
    setActionsDrawerInvoice({
      id,
      folio,
      amount,
      nameReceiver,
      actions: actions || [],
      rutReceiver,
      companyRut,
      companyName: name,
      dataForCollection,
      reminder,
      managerId: collectionManager ? collectionManager.id : null,
    });
    setActionsDrawerOpen(true);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      dateOperation,
      folio,
      pdf,
      nameReceiver,
      rutReceiver,
      collector,
      dateToPay,
      receiver,
      amountWithIva,
      advancePercentage,
      surplusSurplus,
      monthlyRate,
      defaultRate,
      status,
      paymentDate,
      timeInStage,
      lastHistoryStatus,
      actions,
      ratificationManagerActions,
    } = row;
    let actionsNumber = 0;
    if (actions && ratificationManagerActions) {
      actionsNumber = actions.length + ratificationManagerActions.length;
    } else if (actions) {
      actionsNumber = actions.length;
    } else if (ratificationManagerActions) {
      actionsNumber = ratificationManagerActions.length;
    } else {
      actionsNumber = 0;
    }
    return ({
      id,
      dateOperation: {
        value: dateOperation,
        component: (
          <div>
            <Typography className={classes.date}>{dateOperation.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateOperation)}</Typography>
          </div>
        ),
        size: 'small',
      },
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography className={classes.textCell}>{folio}</Typography>
            <IconButton onClick={() => openPdf(pdf)}>
              <InsertDriveFile />
            </IconButton>
          </div>),
        size: 'small',
      },
      nameReceiver: {
        value: nameReceiver,
        component: <DebtorCell debtorId={receiver.id} name={nameReceiver} rut={rutReceiver} />,
        size: 'medium',
      },
      collectorName: {
        value: null,
        component: <Typography className={classes.textCell}>{collector ? `${collector.firstName} ${collector.lastName}` : 'Sin asignar'}</Typography>,
        size: 'small',
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <div>
            <Typography className={classes.date}>{dateToPay.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateToPay)}</Typography>
          </div>
        ),
        size: 'small',
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography className={classes.textCell}>${formatMoney(amountWithIva)}</Typography>
        ),
        size: 'small',
      },
      surplus: {
        value: surplusSurplus,
        component: (
          <Grid container direction="column">
            <Typography className={classes.textCell} align="right">Tasa {(monthlyRate).toFixed(2)}%</Typography>
            <Typography className={classes.textCell} align="right">Mora {(defaultRate).toFixed(2)}%</Typography>
            <Typography className={classes.textCell} align="right">Anticipo {(advancePercentage).toFixed(2)}%</Typography>
          </Grid>
        ),
        size: 'small',
      },
      status: {
        value: status,
        component: <Status status={status} paymentDate={paymentDate} />,
        size: 'medium',
      },
      timeInStage: {
        // eslint-disable-next-line no-underscore-dangle
        value: lastHistoryStatus ? timeInStage._milliseconds : 0,
        component: (
          <Typography align="left">
            {lastHistoryStatus ? getTimePassedTextFromTime(timeInStage) : 0}
          </Typography>
        ),
      },
      actions: {
        value: actions ? actions.length : 0,
        component: (
          <IconButton
            disabled={!actionsNumber}
            onClick={() => onClickActions(row)}
          >
            <Badge badgeContent={actionsNumber} color="primary">
              {actionsNumber ? <Message /> : <Block />}
            </Badge>
          </IconButton>
        ),
      },
      documents: {
        value: null,
        component: (
          <>
            <IconButton
              onClick={() => {
                setOpenDocuments(true);
                setSelectedInvoice(id);
              }}
            >
              <CloudUploadIcon />
            </IconButton>
          </>
        ),
      },
    });
  });

  return (
    <Grid container style={{ marginTop: '2rem' }}>
      <CollectionActionsDrawer
        open={actionsDrawerOpen}
        onClose={onCloseActionsDrawer}
        invoiceData={actionsDrawerInvoice}
        disableActions
      />
      <Grid item xs={6} sm={6} className={classes.searchBar}>
        <Filters
          allData={cessions}
          setFilteredData={setFilteredCessions}
          searchFields={['folio', 'nameReceiver', 'rutReceiver']}
          searchPlaceholder="Búsqueda por folio o rut o nombre de deudor"
        />
      </Grid>
      { (filteredCessions && filteredCessions.length > 0)
        ? (
          <Table
            headers={headers}
            initialOrderId="dateOperation"
            setNewDataOrder={setFilteredCessions}
            data={filteredCessions}
            dataToRows={transformDataToComponents}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            { !loadingCesions && <Typography variant="h4" align="center" className={classes.emptyInvoices}>No se encontraron facturas </Typography>}
          </Grid>
        )}
      <UploadDocumentsManager
        open={openDocuments}
        handleClose={() => { setOpenDocuments(false); }}
        invoiceId={selectedInvoice}
      />
    </Grid>

  );
};

InvoicesInOperation.propTypes = {
  setLoading: PropTypes.func,
};

InvoicesInOperation.defaultProps = {
  setLoading: () => {},
};
export default InvoicesInOperation;
