import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  Typography,
  TextField,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { getFormFieldError } from '../../helpers/formValidators';
import { UPDATE_RATIFICATION_CONTACT } from '../../gql/ratification';
import { UPDATE_COLLECTION_CONTACT } from '../../gql/collection';

const EditContact = ({
  companyName,
  contactsRefetch,
  contact,
  openForm,
  setOpenForm,
  type,
}) => {
  const INITIAL_FORM_DATA = (contact) ? ({
    names: {
      value: contact.firstName,
      error: '',
    },
    lastNames: {
      value: contact.lastName,
      error: '',
    },
    position: {
      value: contact.position,
      error: '',
    },
    phoneNumber: {
      value: contact.phoneNumber,
      error: '',
    },
    email: {
      value: contact.email,
      error: '',
    },
    verified: {
      value: contact.verified,
      error: '',
    },
    ratifies: {
      value: contact.ratifies,
      error: '',
    },
  }) : ({
    names: {
      value: '',
      error: '',
    },
    lastNames: {
      value: '',
      error: '',
    },
    position: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    verified: {
      value: false,
      error: '',
    },
    ratifies: {
      values: false,
      error: '',
    },
  });
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [error, setError] = useState('');
  const [updateRatificationContact] = useMutation(UPDATE_RATIFICATION_CONTACT);
  const [updateCollectionContact] = useMutation(UPDATE_COLLECTION_CONTACT);

  useEffect(() => {
    setFormData(INITIAL_FORM_DATA);
  }, [contact]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: {
        ...oldFormData[name],
        value,
      },
    }));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: {
        ...oldFormData[name],
        value: checked,
      },
    }));
  };

  const handleSetError = (name, _error) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: {
        ...oldFormData[name],
        error: _error,
      },
    }));
  };

  const handleUpdateContact = async () => {
    const {
      names,
      lastNames,
      position,
      phoneNumber,
      email,
      ratifies,
      verified,
    } = formData;
    if (!names.value || !lastNames.value) {
      setError('Nombre y appelido no pueden estar vacíos');
      return;
    }
    if (!phoneNumber.value && !email.value) {
      setError('Debes indicar al menos uno entre email y número de teléfono');
      return;
    }
    const emailError = getFormFieldError(email.value, 'email');
    const errorOnEmail = emailError.isError && email.value.length > 0;
    if (errorOnEmail) handleSetError('email', emailError.message);
    else handleSetError('email', '');
    const phoneNumberError = getFormFieldError(phoneNumber.value, 'phoneNumber');
    const errorOnPhoneNumber = phoneNumberError.isError && phoneNumber.value.length > 0;
    if (errorOnPhoneNumber) handleSetError('phoneNumber', phoneNumberError.message);
    else handleSetError('phoneNumber', '');
    if (errorOnEmail || errorOnPhoneNumber) return;
    try {
      if ((type === 'RATIFICATION') || (type.includes('ratificación'))) {
        await updateRatificationContact({
          variables: {
            // eslint-disable-next-line react/prop-types
            contactId: contact.ratificationId || contact.id,
            firstName: names.value,
            lastName: lastNames.value,
            position: position.value,
            phoneNumber: phoneNumber.value,
            email: email.value,
            ratifies: ratifies.value,
            verified: verified.value,
          },
        });
      }
      if ((type === 'COLLECTION') || (type.includes('cobranza'))) {
        await updateCollectionContact({
          variables: {
            // eslint-disable-next-line react/prop-types
            contactId: contact.collectionId || contact.id,
            firstName: names.value,
            lastName: lastNames.value,
            position: position.value,
            phoneNumber: phoneNumber.value,
            email: email.value,
          },
        });
      }
      if (contactsRefetch) {
        contactsRefetch();
      }
    } catch (err) {
      if (err.message === 'Contact with this email already exists for this company') {
        setError('Ya existe un contacto de esta empresa con este mail');
        return;
      }
      setError('No pudimos editar el contacto, por favor comunícate con el equipo de desarrollo');
      return;
    }
    setSuccess(true);
  };

  const {
    names,
    lastNames,
    position,
    phoneNumber,
    email,
    verified,
    ratifies,
  } = formData;

  return (
    <>
      <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
      >
        {success
          ? (
            <>
              <DialogTitle>Contacto actualizado con éxito</DialogTitle>
              <DialogContent>
                <Typography>Hemos actualizado el contacto</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenForm(false)}
                  color="primary"
                >
                  Cerrar
                </Button>
              </DialogActions>
            </>
          )
          : (
            <>
              <DialogTitle>Editar contacto para {companyName}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Nombres</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="names"
                      onChange={handleChange}
                      value={names.value}
                      error={Boolean(names.error)}
                      helperText={names.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Apellidos</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="lastNames"
                      onChange={handleChange}
                      value={lastNames.value}
                      error={Boolean(lastNames.error)}
                      helperText={lastNames.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Cargo</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="position"
                      onChange={handleChange}
                      value={position.value}
                      error={Boolean(position.error)}
                      helperText={position.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Teléfono</Typography>
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+56</InputAdornment>
                        ),
                      }}
                      name="phoneNumber"
                      onChange={handleChange}
                      value={phoneNumber.value}
                      error={Boolean(phoneNumber.error)}
                      helperText={phoneNumber.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Email</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      onChange={handleChange}
                      value={email.value}
                      error={Boolean(email.error)}
                      helperText={email.error}
                    />
                  </Grid>
                  {((type === 'RATIFICATION') || (type.includes('ratificación'))) && (
                  <Grid item xs={6}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={verified.value} onChange={handleCheckboxChange} name="verified" />} label="Contacto verificado" />
                      <FormControlLabel control={<Checkbox checked={ratifies.value} onChange={handleCheckboxChange} name="ratifies" />} label="Contacto ratifica" />
                    </FormGroup>
                  </Grid>
                  )}
                  <Typography style={{ width: '100%' }} variant="caption" color="error" align="right">
                    {error}
                  </Typography>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenForm(false)}
                  color="primary"
                  disabled={false}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={(e) => { e.preventDefault(); handleUpdateContact(); }}
                  color="primary"
                >
                  Aceptar
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

EditContact.propTypes = {
  companyName: PropTypes.string.isRequired,
  contactsRefetch: PropTypes.func,
  contact: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    verified: PropTypes.bool,
    ratifies: PropTypes.bool,
  }).isRequired,
  openForm: PropTypes.bool.isRequired,
  setOpenForm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

EditContact.defaultProps = {
  contactsRefetch: () => null,
};

export default EditContact;
