import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button, LinearProgress, Snackbar } from '@material-ui/core';
import GET_CONTACTABILITY from '../gql/contactability';
import { useStyles } from './styles';

const ownStyles = makeStyles(() => ({
  inputContainer: {
    marginInline: '25%',
  },
  buttonContainer: {
    marginTop: '5%',
  },
  button: {
    marginInline: '1%',
  },
}));

const Contactability = () => {
  const classes = {
    ...useStyles(),
    ...ownStyles(),
  };
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [getContactability, { loading, error }] = useLazyQuery(
    GET_CONTACTABILITY, { onCompleted: () => {
      setOpenSnackBar(true);
    } },
  );
  const [ruts, setRuts] = useState('');
  const onRutChange = (e) => {
    const { value } = e.target;
    setRuts(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getContactability({ variables: { ruts: ruts.split(' ') } });
    setRuts('');
  };

  const handleErase = (e) => {
    e.preventDefault();
    setRuts('');
  };

  if (loading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Contactabilidad de las Empresas</b></Typography>
      </div>
      <div className={[classes.center, classes.inputContainer].join(' ')}>
        <TextField
          error={error}
          multiline
          variant="outlined"
          label="RUTS"
          fullWidth
          helperText="Ingrese los rut sin guión ni dv, separados por espacio entre ellos"
          value={ruts}
          onChange={onRutChange}
        />
      </div>
      <div className={[classes.center, classes.buttonContainer].join(' ')}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className={classes.button}
        >
          Consultar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleErase}
          className={classes.button}
        >
          Borrar
        </Button>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={() => { setOpenSnackBar(false); }}
          message="Documento enviado a su mail"
          disableWindowBlurListener
        />
      </div>
    </div>
  );
};

export default Contactability;
