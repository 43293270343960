import React from 'react';
import PropTypes from 'prop-types';
import { List, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ActionsDrawerInvoiceInfo,
  ActionsDrawerCollectionDataInfo,
  ActionsDrawerActionInfo,
} from './actionsDrawerInfo/index';

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 20,
    height: '100%',
  },
  actionsContainer: {
    height: '44%',
  },
  allActionsContainer: {
    height: '95%',
  },
}));

const ActionsDrawerInfo = ({
  disableActions,
  disableShowData,
  invoiceData: {
    actions,
    folio,
    nameReceiver,
    dataForCollection,
    amount,
  },
  generalActions,
  allActions,
  openAddDataForCollection,
}) => {
  const classes = useStyles();
  const actionsClass = generalActions ? classes.allActionsContainer : classes.actionsContainer;
  const actionsList = generalActions ? allActions.collectionActions : actions;
  const typographyVariant = generalActions ? 'h5' : 'h6';
  const typographyGutterBottom = generalActions;
  const typographyStyle = generalActions ? { marginTop: '1rem', marginLeft: '1rem' } : {};

  return (
    <List className={classes.list}>
      {!generalActions && (
        <ActionsDrawerInvoiceInfo
          nameReceiver={nameReceiver}
          folio={folio}
          amount={amount}
        />
      )}
      <Divider />
      {!disableShowData && !generalActions && (
        <ActionsDrawerCollectionDataInfo
          dataForCollection={dataForCollection}
          disableActions={disableActions}
          openAddDataForCollection={openAddDataForCollection}
        />
      )}
      <ActionsDrawerActionInfo
        actionsClass={actionsClass}
        typographyVariant={typographyVariant}
        typographyGutterBottom={typographyGutterBottom}
        typographyStyle={typographyStyle}
        actions={actionsList}
      />
      <Divider />
    </List>
  );
};

ActionsDrawerInfo.propTypes = {
  openAddDataForCollection: PropTypes.func.isRequired,
  generalActions: PropTypes.bool,
  invoiceData: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })).isRequired,
      author: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string,
      }).isRequired,
    })).isRequired,
    folio: PropTypes.number,
    amount: PropTypes.number,
    nameReceiver: PropTypes.string,
    rutReceiver: PropTypes.number,
    dataForCollection: PropTypes.shape({
      dateToPay: PropTypes.instanceOf(Date),
      paymentMethod: PropTypes.string,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })),
    }).isRequired,
    reminder: PropTypes.shape({
      reminderDate: PropTypes.instanceOf(Date),
      reminderNote: PropTypes.string,
    }).isRequired,
  }).isRequired,
  disableActions: PropTypes.bool,
  disableShowData: PropTypes.bool,
  allActions: PropTypes.arrayOf(PropTypes.shape({
    ratificationActions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      ratificationContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })),
    })),
    collectionActions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })).isRequired,
      author: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string,
      }).isRequired,
    })).isRequired,
  })),
};

ActionsDrawerInfo.defaultProps = {
  disableActions: false,
  disableShowData: false,
  generalActions: false,
  allActions: { collectionActions: [], ratificationActions: [] },
};

export default ActionsDrawerInfo;
