import React, { useState } from 'react';
import { Chip, makeStyles, Snackbar, Button, Grid, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table } from '../components/tables';
import { UPLOAD_ROSTER_FILE, GET_ROSTERS, ROSTER_RECESION } from '../gql/funding';
import rosterStatusToSpanish from '../helpers/funding';
import { useStyles } from './styles';
import { UploadRosterDialog, ConfirmRecesionDialog } from '../components/funding';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';

const headers = [
  { id: 'fund', label: 'Fondo', align: 'center' },
  { id: 'cessionFund', label: 'Cesionario', align: 'center' },
  { id: 'invoicesCount', label: 'Cantidad de facturas', align: 'center' },
  { id: 'invoicesAmount', label: 'Monto total', align: 'center' },
  { id: 'status', label: 'Estado', align: 'center' },
  { id: 'detail', label: 'Detalle', align: 'center' },
  { id: 'recesion', label: 'Receder', align: 'center' },
];
const internalUseStyles = makeStyles({
  input: {
    maxWidth: '100%',
  },
  blueChip: {
    color: '#344A96',
  },
  yellowChip: {
    color: '#BA8D00',
  },
  greenChip: {
    color: '#196400',
  },
});

const FundsDownload = () => {
  const classes = useStyles();
  const history = useHistory();
  const internalClasses = internalUseStyles();
  // UseStates
  const [openUploadRosterDialog, setOpenUploadRosterDialog] = useState(false);
  const [openConfirmRecesionDialog, setOpenConfirmRecesionDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [rosters, setRosters] = useState([]);
  const [selectedRoster, setSelectedRoster] = useState(null);
  // Queries
  useQuery(GET_ROSTERS, {
    onCompleted: (data) => {
      setRosters(data.getRosters);
    },
  });
  // Mutations
  const [UploadRosterFileMutation, { loadingUploadRoster }] = useMutation(UPLOAD_ROSTER_FILE, {
    refetchQueries: [{ query: GET_ROSTERS }],
    onError: (error) => {
      setOpenUploadRosterDialog(false);
      setLocalErrorMessage(error.message);
      setOpenErrorDialog(true);
    },
    onCompleted: () => {
      setOpenUploadRosterDialog(false);
    },
  });
  const [RosterRecesionMutation, { loadingRecesion }] = useMutation(ROSTER_RECESION, {
    refetchQueries: [{ query: GET_ROSTERS }],
    onCompleted: () => {
      setOpenConfirmRecesionDialog(false);
    },
  });
  // Methods
  const handleRosterStatusColor = (rosterStatus) => {
    if (rosterStatus === 'CREATED') {
      return internalClasses.blueChip;
    } if (rosterStatus === 'PROCESSING') {
      return internalClasses.yellowChip;
    } if (rosterStatus === 'RECEDED') {
      return internalClasses.greenChip;
    } if (rosterStatus === 'PAID') {
      return internalClasses.greyChip;
    }
    return '';
  };

  const confirmRecesion = () => {
    RosterRecesionMutation({
      variables: {
        rosterId: selectedRoster.id,
      },
    });
  };
  const handleAskRecesion = (roster) => {
    setSelectedRoster(roster);
    setOpenConfirmRecesionDialog(true);
  };
  const handleSeeRoster = (roster) => {
    history.push({
      pathname: `/app/funding/roster/${roster.id}`,
      state: {
        roster,
      },
    });
  };
  // useEffects

  const transformDataToComponent = (_data) => _data.map((row) => {
    const {
      id,
      status,
      fund,
      recesionDate,
      invoicesCount,
      invoicesAmount,
      loanedInvoicesCount,
      cessionFund,
    } = row;
    return ({
      id,
      fund: {
        value: fund.name,
        component: (
          <div>
            <Typography>{fund.socialReason}</Typography>
            <Typography>{formatRut(fund.rut)}</Typography>
          </div>
        ),
      },
      cessionFund: {
        value: cessionFund.socialReason,
        component: (
          <div>
            <Typography>{cessionFund.socialReason}</Typography>
            <Typography>{formatRut(cessionFund.rut)}</Typography>
          </div>
        ),
      },
      invoicesCount: {
        component: (
          <div>
            <Typography>{invoicesCount} facturas</Typography>
            <Typography variant="caption">{loanedInvoicesCount} recedidas</Typography>
          </div>
        ),
      },
      invoicesAmount: {
        component: (
          <Typography>${formatMoney(invoicesAmount)}</Typography>
        ),
      },
      status: {
        value: status,
        component: (
          <div>
            <Chip label={rosterStatusToSpanish[status]} className={handleRosterStatusColor(status)} variant="outlined" />
            {recesionDate && (
              <div>
                <Typography variant="caption" style={{ marginLeft: 10 }}>Recedida el {moment(recesionDate).format('DD-MM-YYYY')}</Typography>
              </div>
            )}
          </div>
        ),
      },
      recesion: {
        value: 'Receder',
        component: (
          <Button
            variant="contained"
            color="primary"
            disabled={recesionDate}
            onClick={() => { handleAskRecesion(row); }}
          >
            Receder
          </Button>
        ),
      },
      detail: {
        component: (
          <Button
            onClick={() => { handleSeeRoster(row); }}
          >
            Ver nómina
          </Button>
        ),
      },
    });
  });

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}>
          <b>Nóminas de descarga en fondos</b>
        </Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            className={classes.mainColorButton}
            onClick={() => setOpenUploadRosterDialog(true)}
            color="inherit"
            variant="contained"
          >
            Subir nómina
          </Button>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.tableContainer}>
          { rosters.length > 0 ? (
            <Table
              headers={headers}
              initialOrderId="status"
              data={rosters}
              withSelect={false}
              setNewDataOrder={setRosters}
              dataToRows={transformDataToComponent}
            />
          ) : (
            <Typography variant="h3" align="center">No existen nóminas por el momento</Typography>
          )}
        </Grid>
      </Grid>
      <UploadRosterDialog
        open={openUploadRosterDialog}
        onClose={() => setOpenUploadRosterDialog(false)}
        setOpen={setOpenUploadRosterDialog}
        UploadRosterFileMutation={UploadRosterFileMutation}
        loading={loadingUploadRoster}
      />
      {selectedRoster && (
        <ConfirmRecesionDialog
          open={openConfirmRecesionDialog}
          onClose={() => setOpenConfirmRecesionDialog(false)}
          onConfim={confirmRecesion}
          handleSeeRoster={handleSeeRoster}
          loading={loadingRecesion}
          roster={selectedRoster}
        />
      )}
      <Snackbar
        open={openErrorDialog}
        autoHideDuration={null}
        onClose={() => setOpenErrorDialog(false)}
        message={localErrorMessage}
      />
    </div>
  );
};

export default FundsDownload;
