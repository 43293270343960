import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { GenericRiskForm, ClientRiskForm } from '../components/risk';

const ClientRisk = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <div className={classes.section}>
        <div className={classes.header}>
          <Typography variant="h4"><b>Dicom Cliente</b></Typography>
          <Typography>
            Solicita y retorna el dicom de un cliente, con un desfase máximo de 30 días
          </Typography>
        </div>
        <GenericRiskForm />
      </div> */}
      <div className={classes.section}>
        <div className={classes.header}>
          <Typography variant="h4"><b>Excel de Riesgo Cliente</b></Typography>
          <Typography>
            Para hacer esta consulta, el rut tiene que corresponder a un
            cliente que haya aceptado los términos y condiciones
          </Typography>
        </div>
        <ClientRiskForm />
      </div>
      <div className={classes.section}>
        <div className={classes.header}>
          <Typography variant="h4"><b>Excel de Riesgo genérico</b></Typography>
          <Typography>
            Utiliza esta consulta si es que el rut corresponde a una empresa deudora o a
            cualquiera que no haya aceptado nuestros términos y condiciones
          </Typography>
        </div>
        <GenericRiskForm />
      </div>
    </div>
  );
};

export default ClientRisk;
