import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_DOCUMENTS } from '../../gql/customer';
import VisualizeDocument from './VisualizeDocument';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',

  },

}));
const VisualizeAllDocument = ({
  rut,
  documentTypes,
  controlRefetchDocuments,
  openSnackBar,
}) => {
  const classes = useStyles();
  const [getDocuments,
    { data: getCompanyDocuments, loading, error }] = useLazyQuery(GET_DOCUMENTS, {
    variables: {
      rut,
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => { getDocuments(); }, [controlRefetchDocuments]);

  if (error) return <Typography align="center">Error al cargar los documentos </Typography>;
  if (loading) return <Typography align="center">Cargando documentos </Typography>;
  let documents = [];
  if (getCompanyDocuments) { documents = getCompanyDocuments.getCompanyDocuments; }

  return (
    <div className={classes.container}>
      {documents.map((document) => (
        documentTypes.includes(document.documentType) && (
        <VisualizeDocument
          companyRut={rut}
          key={document.documentType}
          documentName={document.documentType}
          exists={document.exists}
          lastDate={document.lastDate}
          file={document.lastFile}
          openSnackBar={openSnackBar}
        />
        )
      ))}
    </div>
  );
};
VisualizeAllDocument.propTypes = {
  rut: PropTypes.string.isRequired,
  documentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  controlRefetchDocuments: PropTypes.bool.isRequired,
  openSnackBar: PropTypes.func,
};

VisualizeAllDocument.defaultProps = {
  openSnackBar: () => { },
};

export default VisualizeAllDocument;
