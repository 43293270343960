import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  CircularProgress,

} from '@material-ui/core';
import { OverdraftHandler } from '../creditLine';
import AllRestrictionsWarning from '../companyRestrictions/AllRestrictionsWarning';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
  subtitle: {
    marginBottom: '1rem',
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    color: '#ABABAB',
    backgroundColor: 'ABABAB',
    background: 'ABABAB',
  },
  warning: {
    fontSize: 80,
  },
});
const ConfirmPreOffers = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  error,
  allCompanies,
  setAllCompanies,
  clients,
}) => {
  const classes = useStyles();
  const [disableConfirm, setDisableConfirm] = useState(true);
  // only the restrictions uncreated or pending can be created

  useEffect(() => {
    /* company.decided is always true if it does not need a decision on overdraft
    (created in CompanyPreOffersToAccept) */
    const hasOverdraftWithoutDecisions = allCompanies.filter((company) => (
      !company.decided
    )).length > 0;
    if (hasOverdraftWithoutDecisions) {
      setDisableConfirm(true);
    } else {
      setDisableConfirm(false);
    }
  }, [allCompanies]);

  const handleOverdraftChoice = (choice, rut) => {
    const newAllCompanies = [...allCompanies];
    const i = newAllCompanies.findIndex((company) => company.rut === rut);
    /* If the choice is nothing, the decision has been made,
    if not, the user has to select an amount to make de decision */
    const decided = choice === 'NOTHING';
    newAllCompanies[i] = {
      ...newAllCompanies[i],
      overdraftChoice: choice,
      overdraftAmount: 0,
      decided,
    };
    setAllCompanies(newAllCompanies);
  };

  const handleOverdraftAmount = (amount, rut) => {
    const newAllCompanies = [...allCompanies];
    const i = newAllCompanies.findIndex((company) => company.rut === rut);
    // If an amount is selected, it means the decision has been made
    newAllCompanies[i] = { ...newAllCompanies[i], overdraftAmount: amount, decided: true };
    setAllCompanies(newAllCompanies);
  };

  const handleConfirmModal = async () => {
    if (disableConfirm) return;
    await handleConfirm({
      allCompanies,
    });
  };

  const overdraftCompanies = allCompanies.filter((company) => company.surpasedAmount > 0);
  return (
    <Dialog open={open} onClose={handleClose} align="center">
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <AllRestrictionsWarning
            clients={clients}
          />
        </div>
        {overdraftCompanies.length > 0 && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            <b>
              Algunas empresas involucradas en la transacción no tienen suficiente línea de crédito
            </b>
          </Typography>
        )}
        {overdraftCompanies.map((company) => (
          <div key={company.rut} className={classes.section}>
            <OverdraftHandler
              overdraft={company.surpasedAmount}
              companyName={company.name}
              companyRut={company.rut}
              onChoiceChange={(choice) => handleOverdraftChoice(choice, company.rut)}
              onAmountChange={(amount) => handleOverdraftAmount(amount, company.rut)}
            />
          </div>
        ))}
      </DialogContent>
      <DialogTitle id="alert-dialog-title"><b>¿Quieres aprobar estas pre ofertas?</b></DialogTitle>
      <Typography align="center" color="error" variant="caption">{error}</Typography>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={(loading || disableConfirm)} onClick={handleConfirmModal} color="primary">
          Confirmar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmPreOffers.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
  setAllCompanies: PropTypes.func.isRequired,
  allCompanies: PropTypes.arrayOf(PropTypes.shape({
    approvedOverdraft: PropTypes.number,
    rut: PropTypes.number,
    name: PropTypes.string,
    totalCreditLine: PropTypes.number,
    availableCreditLine: PropTypes.number,
    surpasedAmount: PropTypes.number,
    amountToFund: PropTypes.number,
    decided: PropTypes.bool,
  })).isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({
    rut: PropTypes.number,
    socialReason: PropTypes.string,
  })).isRequired,
};

ConfirmPreOffers.defaultProps = {
  loading: false,
};

export default ConfirmPreOffers;
