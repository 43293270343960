import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ArrowBackIos } from '@material-ui/icons';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { formatRut } from '../helpers/generic';
import { APPROVE_ORDERING_SIMULATION, GET_EVALUATION_SIMULATIONS } from '../gql/ordering';
import { Table } from '../components/tables';
import { daysBetween } from '../helpers/date';

const headers = [
  { id: 'orderNumber', label: 'Númer de orden', align: 'left' },
  { id: 'name', label: 'Comprador', align: 'left' },
  { id: 'totalAmount', label: 'Monto orden', align: 'left' },
  { id: 'orderingMonthlyRate', label: 'Tasa ordering', align: 'left' },
  { id: 'orderingDefaultRate', label: 'Tasa mora ordering', align: 'left' },
  { id: 'orderingRetentionRate', label: 'Tasa retención ordering', align: 'left' },
  { id: 'factoringMonthlyRate', label: 'Tasa factoring', align: 'left' },
  { id: 'factoringDefaultRate', label: 'Tasa mora factoring', align: 'left' },
  { id: 'factoringRetentionRate', label: 'Tasa retención factoring', align: 'left' },
  { id: 'factoringFundingDays', label: 'Días financiados factoring', align: 'left' },
  { id: 'orderingFundingDays', label: 'Días financiados ordering', align: 'left' },
  { id: 'invoiceIssuedDate', label: 'Emisión de Factura', align: 'left' },
  { id: 'invoiceDateToPay', label: 'Fecha de pago', align: 'left' },
];

const SimulationToAccept = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [
    simulations,
    setSimulations,
  ] = useState(state?.selectedSimulations.map((s) => (
    { ...s,
      factoringFundingDays: 60,
      orderingMonthlyRate: s.orderingsimulation.orderingMonthlyRate,
      orderingRetentionRate: s.orderingsimulation.orderingRetentionRate,
      orderingDefaultRate: s.orderingsimulation.orderingDefaultRate,
      factoringMonthlyRate: s.orderingsimulation.factoringMonthlyRate,
      factoringRetentionRate: s.orderingsimulation.factoringRetentionRate,
      factoringDefaultRate: s.orderingsimulation.factoringDefaultRate,
    }
  )));
  const [ApproveOrderingSimulation] = useMutation(
    APPROVE_ORDERING_SIMULATION,
    {
      refetchQueries: [
        { query: GET_EVALUATION_SIMULATIONS },
      ],
    },
  );

  const handleApprove = async () => {
    const resolvedPreOffers = simulations.map((simulation) => ({
      requestId: simulation.orderingsimulation.id,
      orderingMonthlyRate: simulation.orderingMonthlyRate,
      orderingRetentionRate: simulation.orderingRetentionRate,
      orderingDefaultRate: simulation.orderingDefaultRate,
      factoringMonthlyRate: simulation.factoringMonthlyRate,
      factoringRetentionRate: simulation.factoringRetentionRate,
      factoringDefaultRate: simulation.factoringDefaultRate,
      fundingDays: simulation.factoringFundingDays,
    }));
    await ApproveOrderingSimulation({
      variables:
        {
          requestResolutions: resolvedPreOffers,
          analystDescription: '',
          informClients: false,
          sendEmail: true,
        },
    });
    history.push({
      pathname: '/app/risk/evaluations',
    });
  };

  const handleChangeRate = (event, simulationId) => {
    const { name, value } = event.target;
    setSimulations((prevState) => prevState?.map((simulation) => {
      if (simulation.id === simulationId) return { ...simulation, [name]: value };

      return simulation;
    }));
  };

  const transformDataToComponents = (data) => data?.map((row) => {
    const {
      id,
      orderNumber,
      purchaser: { rut, name },
      totalAmount,
      orderingMonthlyRate,
      orderingDefaultRate,
      orderingRetentionRate,
      factoringMonthlyRate,
      factoringDefaultRate,
      factoringRetentionRate,
      orderingsimulation: {
        invoiceIssuedDate,
        invoiceDateToPay,
      },
      factoringFundingDays,
    } = row;
    return ({
      id,
      orderNumber: {
        value: orderNumber,
        component: <Typography align="left">{orderNumber}</Typography>,
      },
      name: {
        value: name,
        component: (
          <Grid>
            <Typography align="left">{name}</Typography>
            <Typography align="left">{formatRut(rut)}</Typography>
          </Grid>
        ),
      },
      totalAmount: {
        value: totalAmount,
        component: <Typography align="left">${formatMoney(totalAmount)}</Typography>,
      },
      orderingMonthlyRate: {
        value: orderingMonthlyRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ minWidth: 70 }}
              type="number"
              step=".01"
              value={orderingMonthlyRate}
              name="orderingMonthlyRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
      },
      orderingDefaultRate: {
        value: orderingDefaultRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ maxWidth: 90 }}
              type="number"
              step=".01"
              value={orderingDefaultRate}
              name="orderingDefaultRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
        size: 'small',
      },
      orderingRetentionRate: {
        value: orderingRetentionRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ maxWidth: 90 }}
              type="number"
              step=".01"
              size="small"
              value={orderingRetentionRate}
              name="orderingRetentionRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
      },
      factoringMonthlyRate: {
        value: factoringMonthlyRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ minWidth: 70 }}
              type="number"
              step=".01"
              size="small"
              value={factoringMonthlyRate}
              name="factoringMonthlyRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
      },
      factoringDefaultRate: {
        value: factoringDefaultRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ maxWidth: 90 }}
              type="number"
              step=".01"
              size="small"
              value={factoringDefaultRate}
              name="factoringDefaultRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
      },
      factoringRetentionRate: {
        value: factoringRetentionRate,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ maxWidth: 90 }}
              type="number"
              step=".01"
              value={factoringRetentionRate}
              name="factoringRetentionRate"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            />
          </div>
        ),
      },
      factoringFundingDays: {
        value: factoringFundingDays,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <TextField
              style={{ maxWidth: 90 }}
              type="number"
              step=".01"
              value={factoringFundingDays}
              name="factoringFundingDays"
              variant="outlined"
              onChange={(event) => handleChangeRate(event, id)}
              InputProps={{ endAdornment: <InputAdornment position="end">días</InputAdornment> }}
            />
          </div>
        ),
      },
      orderingFundingDays: {
        value: orderNumber,
        component: (
          <Typography align="left">{daysBetween(new Date(), new Date(invoiceIssuedDate))} días</Typography>
        ),
      },
      invoiceIssuedDate: {
        value: invoiceIssuedDate,
        component: (
          <Grid style={{ minWidth: 90 }}>
            <Typography align="left">{moment(invoiceIssuedDate).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceIssuedDate))} días</Typography>
          </Grid>
        ),
      },
      invoiceDateToPay: {
        value: invoiceDateToPay,
        component: (
          <Grid style={{ minWidth: 90 }}>
            <Typography align="left">{moment(invoiceDateToPay).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceDateToPay))} días</Typography>
          </Grid>
        ),
      },
    });
  });
  return (
    <Grid className={classes.container}>
      <Grid className={classes.titleAndUpdate}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4"><b>Aceptar simulación de {simulations[0].company.name}</b></Typography>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} className={classes.tableContainer}>
          <Table
            data={simulations}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setSimulations}
            headers={headers}
            initialOrderId="id"
          />
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Estás seguro que deseas aprobar esta orden de compra?
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleApprove}>Aprobar</Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="primary"
        className={classes.redButton}
        onClick={() => setOpenDialog(true)}
      >
        Aprobar
      </Button>
    </Grid>
  );
};

export default SimulationToAccept;
