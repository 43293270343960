import { gql } from '@apollo/client';

export const RESOLVE_CREDIT_LINE_EVALUATION_REQUESTS = gql`
  mutation resolveCreditLineEvaluationRequests(
    $creditLineEvaluationResolutions: [CreditLineEvaluationResolutionInputType]!
  ) {
    resolveCreditLineEvaluationRequests(
      creditLineEvaluationResolutions: $creditLineEvaluationResolutions
    ) {
      creditLineEvaluationRequests {
        id
      }
    }
  }
`;

export const REJECT_PRE_OFFER_EVALUATION_REQUESTS = gql`
  mutation RejectPreOfferEvaluationRequests(
    $resolution: RejectPreOfferEvaluationRequestResolutionInputType
  ) {
    rejectPreOfferEvaluationRequests(resolution: $resolution) {
      preOfferEvaluationRequests {
        id
      }
    }
  }
`;

export const ACCEPT_PRE_OFFER_EVALUATION_REQUESTS = gql`
  mutation AcceptPreOfferEvaluationRequests(
    $resolution: AcceptPreOfferEvaluationRequestResolutionInputType
  ) {
    acceptPreOfferEvaluationRequests(resolution: $resolution) {
      preOfferEvaluationRequests {
        id
      }
    }
  }
`;
