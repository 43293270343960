import React, { useState } from 'react';
import { Typography, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Publish } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { useStyles } from '../../views/styles';
import { UPLOAD_CONFIRMING } from '../../gql/confirming';
import { Loading } from '../loading';

const ownStyles = makeStyles((theme) => ({
  formContainer: {
    paddingLeft: '4%',
    paddingRight: '4%',
    margin: '2rem 2rem 2rem 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
  input: {
    display: 'none',
  },
  buttonCreateCampaign: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  success: {
    backgroundColor: theme.palette.success.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const UploadInvoicesForm = () => {
  const classes = {
    ...useStyles(),
    ...ownStyles(),
  };
  const [sendingFile, setSendingFile] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('success');
  const [uploadFile, setUploadFile] = useState({ file: '', name: '' });
  const resetAfterMutation = () => {
    setSendingFile(false);
    setUploadFile({ file: '', name: '' });
    setInputValue('');
  };
  const handleError = (error) => {
    resetAfterMutation();
    setSnackBarMessage(error.message);
    setSnackBarOpen(true);
    setSnackBarSeverity('error');
  };
  const handleSuccess = () => {
    resetAfterMutation();
    setSnackBarMessage('Facturas de confirming correctamente cargadas');
    setSnackBarOpen(true);
    setSnackBarSeverity('success');
  };
  const [uploadConfirming] = useMutation(
    UPLOAD_CONFIRMING,
    { onError: handleError, onCompleted: handleSuccess },
  );
  const handleUploadFile = async (_event) => {
    setUploadFile({ file: _event.target.files[0], name: _event.target.name });
    setInputValue(_event.target.value);
  };

  const sendConfirming = async () => {
    setSendingFile(true);
    await uploadConfirming({
      variables: {
        confirmingFile: uploadFile.file,
      },
    });
  };

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
      >
        <SnackbarContent
          classes={{
            root: classes[snackBarSeverity],
          }}
          message={snackBarMessage}
        />
      </Snackbar>
      <div className={classes.container}>
        <div>
          <div className={classes.header}>
            <Typography variant="h4">
              <b>Cargar nuevas facturas de confirming</b>
            </Typography>
          </div>

          <div className={classes.formContainer}>
            <div>

              <label htmlFor="contained-button-file-csv">
                <input
                  name="contained-button-file-csv"
                  accept=".csv, .xlsx, .xls"
                  className={classes.input}
                  id="contained-button-file-csv"
                  type="file"
                  onChange={handleUploadFile}
                  value={inputValue}
                />
                <Button
                  component="span"
                  variant="contained"
                  color="primary"
                  startIcon={<Publish />}
                >
                  Subir XLSX o CSV
                </Button>
                <Typography variant="caption" style={{ marginLeft: '0.5rem' }}>
                  {uploadFile.file && uploadFile.file.name}
                </Typography>
              </label>
            </div>
            {
            uploadFile.file
          && (
          <div>
            <Button
              component="span"
              variant="contained"
              color="primary"
              onClick={sendConfirming}
            >
              Enviar
            </Button>
          </div>
          )
          }
          </div>
          {sendingFile && <Loading />}
        </div>
      </div>
    </>
  );
};

export default UploadInvoicesForm;
