import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Grid, Button } from '@material-ui/core';
import moment from 'moment';
import { Cached } from '@material-ui/icons';
import { CustomerTableComponent } from '../components/customers';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import ClientSellsExcel from '../components/risk/ClientSellsExcel';

const headers = [
  { id: 'company', label: 'Empresa', align: 'left' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'simulationsQuantity', label: 'Cantidad de ordenes', align: 'right' },
  { id: 'purchasersQuantity', label: 'Cantidad de compradores', align: 'right' },
  { id: 'simulationsAmount', label: 'Monto total', align: 'right' },
  { id: 'firstRequestDate', label: 'Fecha primera solicitud', align: 'right' },
  { id: 'requestDate', label: 'Fecha última solicitud', align: 'right' },
  { id: 'creditLineStatus', label: 'Línea disponible', align: 'right' },
  { id: 'simulationButton', label: '', align: 'center' },
];

const OrderingSimulationEvaluationRequests = ({
  sortedSimulations,
  setSortedSimulations,
  refetch,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const transformDataToComponents = (_data) => _data.map((row) => {
    const goToCompanySimulations = (rut, companyName) => history.push({
      pathname: `${location.pathname}/simulation/${rut}`,
      state: {
        rut,
        company: companyName,
      },
    });
    const {
      rut,
      name,
      executiveAssigned,
      riskBlacklist,
      simulationsQuantity,
      simulationsAmount,
      purchasersQuantity,
      requestDate,
      firstRequestDate,
      amountOfPendingReintegrations,
      amountDebtInvoices,
      creditLineLeft,
    } = row;
    return ({
      company: {
        value: rut,
        component: (
          <CustomerTableComponent
            rut={rut}
            name={name}
            amountOfPendingReintegrations={amountOfPendingReintegrations}
            amountDebtInvoices={amountDebtInvoices}
            riskBlacklist={Boolean(riskBlacklist)}
          />
        ),
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      simulationsAmount: {
        value: simulationsAmount,
        component: (
          <>
            <Typography align="left">${formatMoney(simulationsAmount)}</Typography>
          </>
        ),
      },
      simulationsQuantity: {
        value: simulationsQuantity,
        component: (
          <>
            <Typography align="left">{simulationsQuantity} ordenes de compra</Typography>
          </>
        ),
      },
      purchasersQuantity: {
        value: purchasersQuantity,
        component: (
          <>
            <Typography align="left">{purchasersQuantity} compradores</Typography>
          </>
        ),
      },
      firstRequestDate: {
        value: firstRequestDate,
        component: (
          <>
            <Typography align="left">{moment(firstRequestDate).format('DD-MM-YYYY HH:mm')}</Typography>
          </>
        ),
      },
      requestDate: {
        value: requestDate,
        component: (
          <>
            <Typography align="left">{moment(requestDate).format('DD-MM-YYYY HH:mm')}</Typography>
          </>
        ),
      },
      creditLineStatus: {
        value: creditLineLeft,
        component: (
          <>
            <Typography align="left">{creditLineLeft}</Typography>
          </>
        ),
      },
      simulationButton: {
        value: 'null',
        component: (
          <>
            <Button
              variant="contained"
              onClick={() => goToCompanySimulations(rut, name)}
              color="primary"
            >
              Ver solicitudes
            </Button>
          </>
        ),
      },
    });
  });

  return (
    <>
      <Grid className={classes.container}>
        <Grid className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}><b>Clientes que solicitaron evaluar simulaciones de ordering</b></Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => refetch()}
            endIcon={<Cached />}
          >
            Actualizar
          </Button>
        </Grid>
        <ClientSellsExcel />
        {sortedSimulations.length > 0 ? (
          <Grid container>
            <Grid item xs={12} className={classes.tableContainer}>
              <Table
                headers={headers}
                setNewDataOrder={setSortedSimulations}
                data={sortedSimulations}
                dataToRows={transformDataToComponents}
                initialOrderId="firstRequestDate"
                initialOrder="asc"
              />
            </Grid>
          </Grid>
        ) : (
          <Typography align="center" variant="h5">No hay simulaciones pendientes de evaluar</Typography>
        )}
      </Grid>
    </>
  );
};

OrderingSimulationEvaluationRequests.propTypes = {
  sortedSimulations: PropTypes.objectOf(PropTypes.objects).isRequired,
  setSortedSimulations: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default OrderingSimulationEvaluationRequests;
