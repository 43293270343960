import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { formatMoney } from '../../helpers/money';
import arrayJoin from '../../helpers/arrayJoin';

const ApprovalDialog = ({ open, onAccept, onCancel, selected, title, loading }) => (
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    onClose={onCancel}
  >
    {loading && <LinearProgress />}
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {selected.map((item) => (
        <Typography key={item.id}>
          {item.type.value === 'INVOICE'
            ? `Facturas folio${item.folios.length > 1 && 's'} ${arrayJoin(item.folios)}, 
            total $${formatMoney(item.total.value)}, 
            del cliente ${item.client.value.name} al banco ${item.bankName.value}`
            : `Excedentes,
            total $${formatMoney(item.total.value)}, 
            del cliente ${item.client.value.name} al banco ${item.bankName.value}`}
        </Typography>
      ))}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        color="primary"
      >
        Cancelar
      </Button>
      <Button
        onClick={onAccept}
        color="primary"
      >
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

ApprovalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ApprovalDialog;
