import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Publish, CheckCircle } from '@material-ui/icons';
import { useStyles } from './styles';
import { GET_COMPANY_RATIFICATION_CONTACTS } from '../../gql/ratification';
import { AddNewContact } from '../contacts';
import AllRestrictionsWarning from '../companyRestrictions/AllRestrictionsWarning';

const selectOptions = [
  { value: 'mail', label: 'Factura ratificada por mail' },
  { value: 'phone', label: 'Factura ratificada por teléfono' },
  { value: 'receipt', label: 'Factura con acuse de recibo' },
  { value: 'merit', label: 'Factura con mérito ejecutivo' },
  { value: 'exception', label: 'Excepción: factura aprobada por el comité' },
];

const AcceptRatification = ({
  open,
  debtorProfile,
  onClose,
  onAccept,
  companiesAndFolios,
  loading,
  rutReceiver,
  nameReceiver,
  clients,
}) => {
  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState('');
  const [evidenceFile, setEvidenceFile] = useState(null);
  const [emailMessage, setEmailMessage] = useState('');
  const [contactId, setContactId] = useState(null);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { data: contactsData,
    loading: contactsLoading,
    refetch: contactsRefetch } = useQuery(GET_COMPANY_RATIFICATION_CONTACTS,
    { variables: { companyRut: rutReceiver }, fetchPolicy: 'network-only' });

  const handleReasonSelectChange = (e) => {
    setSelectedReason(e.target.value);
    if (e.target.value === 'mail') {
      setEmailMessage('Sube un pantallazo del email con la ratificación del deudor');
    } else if (e.target.value === 'exception') {
      setEmailMessage('Sube un pantallazo del email con la autorización del comité');
    } else {
      setEmailMessage('');
    }
  };

  const handleContactSelectChange = (e) => {
    setContactId(e.target.value);
  };

  const handleEvidenceFile = (e) => {
    const { files } = e.target;
    setEvidenceFile(files[0]);
  };

  const getInvoicesMessage = () => Object.keys(companiesAndFolios).reduce((acc, company) => {
    const folios = companiesAndFolios[company];
    const foliosString = folios.join(', ');
    const companyString = `Folio ${foliosString} del cliente ${company}\n`;
    return `${acc}${companyString}`;
  }, '');

  const getFullMessage = () => {
    const invoicesMessage = getInvoicesMessage();
    return `${invoicesMessage}Por favor indica el motivo por el cual esta factura está lista para ser girada:`;
  };

  const onAcceptRatification = async () => {
    if (['exception', 'mail'].includes(selectedReason) && !evidenceFile) {
      setErrorMessage('Debes incluir un pantallazo para corroborar la ratificación');
      return;
    }
    if (selectedReason === 'phone' && !contactId) {
      setErrorMessage('Debes incluir el contacto que ratificó la factura');
      return;
    }
    setErrorMessage('');
    await onAccept(selectedReason, evidenceFile, contactId);
  };

  useEffect(() => {
    if (contactsData) {
      const listOfContacts = contactsData.getCompanyRatificationContacts.map((contact) => {
        const name = `${contact.firstName} ${contact.lastName}`;
        return { name, id: contact.id };
      });
      setCompanyContacts(listOfContacts);
    }
  }, [contactsData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {debtorProfile && (
      <DialogContent>
        <AllRestrictionsWarning
          clients={clients}
        />
      </DialogContent>
      )}
      <DialogTitle id="alert-dialog-title"><b>¿Aceptar facturas?</b></DialogTitle>
      <DialogContent>
        <Typography gutterBottom style={{ whiteSpace: 'pre-line' }}>{getFullMessage()}</Typography>
        <RadioGroup
          aria-label="rejection-reason"
          value={selectedReason}
          onChange={handleReasonSelectChange}
        >
          {selectOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        {(['exception', 'mail']).includes(selectedReason) && (
          <div>
            <Typography gutterBottom><b>{emailMessage}</b></Typography>
            <label htmlFor="contained-button-file">
              <input
                accept=".jpg, .jpeg, .png"
                className={classes.fileInput}
                id="contained-button-file"
                type="file"
                onChange={handleEvidenceFile}
              />
              <Button
                fullWidth
                className={classes.uploadButton}
                variant="outlined"
                component="span"
                endIcon={
                  <Publish color="primary" />
                }
              >
                Formato .jpg, .jpeg o .png
              </Button>
            </label>
            {evidenceFile && (
              <div className={classes.uploadStatus}>
                <CheckCircle className={classes.checkIcon} />
                <Typography variant="caption">{evidenceFile.name}</Typography>
              </div>
            )}
          </div>
        )}
        {(selectedReason === 'phone') && (
          <>
            <Typography gutterBottom>
              <b>Selecciona al contacto que realizó la ratificación</b>
            </Typography>
            {contactsLoading ? (<CircularProgress size={16} />) : (
              <>
                <Select
                  id="ratification-contact-select"
                  value={contactId}
                  onChange={handleContactSelectChange}
                  style={{ minWidth: '10rem' }}
                >
                  {companyContacts.map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                  ))}
                </Select>
                <AddNewContact
                  companyName={nameReceiver}
                  companyRut={rutReceiver}
                  type="RATIFICATION"
                  contactsRefetch={contactsRefetch}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Typography color="error" variant="caption">{errorMessage}</Typography>
          <Button
            onClick={onClose}
            color="primary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={onAcceptRatification}
            color="primary"
            disabled={loading}
            startIcon={loading ? (<CircularProgress size={16} />) : null}
          >
            Confirmar
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

AcceptRatification.propTypes = {
  open: PropTypes.bool.isRequired,
  debtorProfile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  companiesAndFolios: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  loading: PropTypes.bool.isRequired,
  rutReceiver: PropTypes.number.isRequired,
  nameReceiver: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({
    rut: PropTypes.number,
    socialReason: PropTypes.string,
  })).isRequired,
};

export default AcceptRatification;
