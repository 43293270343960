import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';

const TwoButtonsAlert = ({ open, onClose, onAccept, message, title, loading, error }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <Typography align="right" className={classes.error} variant="caption">
        {error}
      </Typography>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.cancelButton}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          onClick={onAccept}
          className={classes.acceptButton}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={14} /> : null}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TwoButtonsAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

TwoButtonsAlert.defaultProps = {
  loading: false,
  error: '',
};

export default TwoButtonsAlert;
