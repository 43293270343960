import moment from 'moment';
import { getTimePassedFromDate } from './date';

const debtorBehaviourPriority = (debtorPaymentBehaviour) => {
  if (debtorPaymentBehaviour === 'VERY_GOOD') {
    return 1;
  } if (debtorPaymentBehaviour === 'GOOD') {
    return 2;
  } if (debtorPaymentBehaviour === 'NEW') {
    return 3;
  } if (debtorPaymentBehaviour === 'BAD_LATE_PAYER') {
    return 4;
  } if (debtorPaymentBehaviour === 'IN_PROBLEMS') {
    return 5;
  } if (debtorPaymentBehaviour === 'IN_SEVERE_PROBLEMS') {
    return 6;
  }
  return 0;
};

export const transformInvoiceToRowLikeObjects = (_data) => _data.map((row) => {
  const {
    id: invoiceId,
    folio,
    rutReceiver,
    nameReceiver,
    dateToPay,
    amountWithIva,
    surplusDebt,
    pdf,
    isFunding,
    isRatificated,
    documents,
    integrationStatus,
    siiStatus,
    creditNoteStatus,
    dateIssued,
    forReintegration,
    debtorPaymentBehaviour,
    classification,
    company: {
      rut,
      name,
      executiveAssigned,
    },
    receiver: {
      id: receiverId,
    },
    dateOperation,
    lastHistoryStatus,
    certificateUrl,
    collectionManager: {
      id,
      collector,
      actions,
      currentDataForCollection: dataforcollection,
      reminder,
    },
  } = row;
  const realIntegrationStatus = (integrationStatus === 'LOANEDOTHER') ? 'VALID' : integrationStatus;
  return {
    id,
    managerId: id,
    invoiceId,
    collector: collector ? collector.firstName : null,
    folio,
    rutReceiver,
    nameReceiver,
    receiverId,
    rut,
    name,
    isFunding,
    paymentDate: dateToPay,
    amount: amountWithIva,
    debt: surplusDebt,
    dateOperation,
    pdf,
    isRatificated,
    documents,
    executiveAssigned,
    timeInStage: lastHistoryStatus ? getTimePassedFromDate(lastHistoryStatus.createdAt) : null,
    actions,
    dataForCollection: dataforcollection,
    reminder,
    certificateUrl,
    actionsData: actions.length > 0 ? moment(actions[0].createdAt).valueOf() : 0,
    integrationStatus: realIntegrationStatus,
    forReintegration,
    debtorPaymentBehaviour,
    debtorBehaviourFilter: debtorBehaviourPriority(debtorPaymentBehaviour),
    classification,
    siiStatus,
    creditNoteStatus,
    dateIssued,
  };
});

export const transformOrdersToRowLikeObjects = (_data) => _data.map((row) => {
  const {
    id: orderId,
    orderNumber,
    totalAmount,
    orderingDebt,
    status,
    company: {
      rut: companyRut,
      name: companyName,
      executiveAssigned,
    },
    purchaser: {
      rut: purchaserRut,
      name: purchaserName,
    },
    orderingoffer: {
      orderingFinancedAmount,
      invoiceIssuedDate,
      operationDate,
    },
    collectionManager: {
      id: managerId,
      createdAt,
    },
  } = row;
  return {
    managerId,
    orderId,
    status,
    orderNumber,
    purchaserName,
    orderingDebt,
    purchaserRut,
    executiveAssigned,
    companyName,
    companyRut,
    invoiceIssuedDate,
    amount: totalAmount,
    financedAmount: orderingFinancedAmount,
    dateOperation: operationDate,
    timeInStage: getTimePassedFromDate(createdAt),
  };
});
