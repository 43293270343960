import React from 'react';

import RutColumn from '../../../components/tables/cells/RutColumn';
import TooltipColumn from '../../../components/tables/cells/TooltipColumn';
import NumberColumn from '../../../components/tables/cells/NumberColumn';
import DebtorProfileButtonColumn from '../components/DebtorProfileButtonColumn';

const debtorsToRows = (debtors) => debtors?.map((debtor) => {
  const {
    masterEntity,
    ratificator,
    collector,
  } = debtor;
  const {
    id,
    rut,
    name,
    invoicesInEvaluationCount,
    invoicesInCollectionCount,
    invoicesInRatificationCount,
  } = masterEntity;

  return {
    id,
    debtorRut: {
      value: rut,
      component: <RutColumn rut={rut} />,
    },
    debtorName: {
      value: name,
      component: <TooltipColumn tooltip={name} value={name} />,
    },
    invoicesInEvaluationCount: {
      value: invoicesInEvaluationCount,
      component: <NumberColumn number={invoicesInEvaluationCount} />,
    },
    invoicesInRatificationCount: {
      value: invoicesInRatificationCount,
      component: <NumberColumn number={invoicesInRatificationCount} />,
    },
    invoicesInCollectionCount: {
      value: invoicesInCollectionCount,
      component: <NumberColumn number={invoicesInCollectionCount} />,
    },
    debtorsProfileButton: {
      component: (
        <DebtorProfileButtonColumn
          masterEntity={masterEntity}
          ratificator={ratificator}
          collector={collector}
        />
      ),
    },
  };
});

export default debtorsToRows;
