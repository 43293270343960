import React, { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapperClass: {
    padding: '1rem',
    border: '1px solid #ccc',
  },
  editorClass: {
    'background-color': 'white',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  toolbarClass: {
    border: '1px solid #ccc',
  },
});

const TextEditor = (props) => {
  const {
    setDescription,
  } = props;
  const classes = useStyles();
  const html = '';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  const [editorDescription, setEditorDescription] = useState(editorState);

  useEffect(() => {
    let finalHtml = draftToHtml(convertToRaw(editorDescription.getCurrentContent()));
    finalHtml = `<div>${finalHtml}</div>`;
    setDescription(finalHtml);
  }, [editorDescription]);
  return (
    <Editor
      editorState={editorDescription}
      toolbarClassName={classes.toolbarClass}
      wrapperClassName={classes.wrapperClass}
      editorClassName={classes.editorClass}
      onEditorStateChange={setEditorDescription}
    />
  );
};

TextEditor.propTypes = {
  setDescription: PropTypes.func.isRequired,
};

export default TextEditor;
