import moment from 'moment';

export const getTimeDiffText = (date) => {
  const today = moment().startOf('day');
  const diff = date.diff(today, 'days');
  if (diff < -1) return `Hace ${Math.abs(diff)} días`;
  if (diff === -1) return 'Ayer';
  if (diff === 0) return 'Hoy';
  if (diff === 1) return 'Mañana';
  return `En ${diff} días`;
};

export const getTermTextBetweenDates = (date1, date2) => {
  const diff = date2.diff(date1, 'days');
  if (diff === 1) return '1 día';
  return `Financiamiento ${diff} días`;
};

export const getTimePassedFromDate = (date) => {
  const now = moment();
  return moment.duration(now.diff(moment(date)));
};

export const getTimePassedTextFromTime = (time) => {
  let daysPassedString = '';
  const daysPassed = time.days();
  if (daysPassed > 1) {
    daysPassedString = `${daysPassed} días y `;
  } else if (daysPassed === 1) {
    daysPassedString = `${daysPassed} día y `;
  }
  const hoursPassed = time.hours().toString().padStart(2, '0');
  const minutesPassed = time.minutes().toString().padStart(2, '0');
  const secondsPassed = time.seconds().toString().padStart(2, '0');
  return `${daysPassedString}${hoursPassed}:${minutesPassed}:${secondsPassed}`;
};

export const daysBetween = (date1, date2) => {
  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.ceil(differenceInDays);
};

export const parseDate = (date) => `${date.slice(8, 10)}-${date.slice(5, 7)}-${date.slice(0, 4)}`;
