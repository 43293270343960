import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Typography, Grid, IconButton, Button } from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../tables';
import { GET_OFFERS } from '../../gql/invoicesStatus';
import { formatMoney } from '../../helpers/money';
import { getTimeDiffText } from '../../helpers/date';
import transformInvoice from '../../helpers/invoiceTransform';
import CustomerDocumentsMenu from '../customers/DocumentsMenu';
import { Filters } from '../utils';
import { CreateNegotiation, CreateFactoringSimulation, UploadDocumentsManager } from '../dialogs';
import DebtorCell from '../shared/DebtorCell';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  emptyInvoices: {
    marginTop: '2rem',
  },
  negotiationButton: {
    margin: 1,
    borderRadius: '5em',
  },
});

const offerConditionToText = {
  WHITHOUT_RATIFICATION_CLIENT: 'Sin ratificación',
  WHITHOUT_RATIFICATION_DEBTOR: 'Sin ratificación',
  WITH_RATIFICATION: 'Con ratificación',
};

const headers = [
  { id: 'folio', label: 'Folio', type: 'text', isSearchable: true },
  { id: 'rutReceiver', label: 'Deudor', type: 'rut', isSearchable: true },
  { id: 'dateIssued', label: 'Fecha de emisión', type: 'date', isSearchable: true },
  { id: 'dateExpiration', label: 'Fecha de vencimiento', type: 'date', isSearchable: true },
  { id: 'type', label: 'Tipo', type: 'text', isSearchable: false },
  { id: 'collectorName', label: 'Cobrador', isSearchable: true },
  { id: 'amountWithIva', label: 'Monto facturado', type: 'money', isSearchable: true },
  { id: 'monthlyRate', label: 'Tasa mensual', type: 'rate', isSearchable: true },
  { id: 'monthlyWLRate', label: 'Tasa Whitelist mensual', type: 'rate', isSearchable: true },
  { id: 'defaultRate', label: 'Mora mensual', className: 'xs', type: 'rate', isSearchable: false },
  {
    id: 'advancePercentage',
    label: 'Porcentaje anticipado',
    className: 'xs',
    type: 'rate',
    isSearchable: true,
  },
  { id: 'transferCondition', label: 'Condición de giro', type: 'condition', isSearchable: false },
  {
    id: 'negotiation',
    label: 'Negociar tasas',
    className: 'xs',
    type: 'negotiateRates',
    isSearchable: false,
    hideSortIcon: true,
  },
  { id: 'simulation', label: 'Crear simulación', className: 'xs' },
  { id: 'documents', label: 'Gestor de Documentos', align: 'center', hideSortIcon: true },
];

const Offers = ({ setLoading }) => {
  const { clientRut } = useParams();
  const classes = useStyles();
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const {
    data: offersData,
    loading: loadingOffers,
    refetch: offersRefetch,
  } = useQuery(GET_OFFERS, { variables: { rut: clientRut }, fetchPolicy: 'network-only' });
  const [selectedForButtons, setSelectedForButtons] = useState([]);
  const [buttonStates, setButtonStates] = useState({});
  const [openNegotiation, setOpenNegotiation] = useState(false);
  const [negotiationData, setNegotiationData] = useState([]);
  const [openSimulation, setOpenSimulation] = useState(false);
  const [simulationData, setSimulationData] = useState([]);
  const [clearSelection, setClearSelection] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (offersData) {
      setOffers(offersData.getOffers.offers.map(transformInvoice));
      setFilteredOffers(offersData.getOffers.offers.map(transformInvoice));
    }
  }, [offersData]);

  useEffect(() => {
    setLoading(loadingOffers);
  }, [loadingOffers]);

  const openPdf = (pdf) => {
    window.open(pdf);
  };
  // Negotiation logic
  useEffect(() => {
    if (offers.length > 0) {
      setButtonStates(offers.reduce((acc, row) => ({ ...acc, [row.id]: true }), {}));
    }
  }, [offers]);

  useEffect(() => {
    if (selectedForButtons) {
      const baseButtonStates = offers.reduce((acc, row) => ({ ...acc, [row.id]: true }), {});
      setButtonStates(
        selectedForButtons.reduce((acc, row) => ({ ...acc, [row.id]: false }), baseButtonStates),
      );
    }
  }, [selectedForButtons]);

  const handleNegotiationClick = () => {
    if (selectedForButtons.length > 0) {
      setNegotiationData(
        selectedForButtons.map((row) => ({
          id: row.id,
          folio: row.folio.value,
          rutReceiver: row.rutReceiver.value,
          nameReceiver: row.nameReceiver,
          monthlyRate: row.monthlyRate.value,
          receiver: row.receiver,
          defaultRate: row.defaultRate.value,
          retentionRate: 100 - row.advancePercentage.value,
        })),
      );
      setOpenNegotiation(true);
    }
  };

  const handleSimulationClick = () => {
    if (selectedForButtons.length > 0) {
      setSimulationData(
        selectedForButtons.map((row) => ({
          id: row.invoiceId,
          folio: row.folio.value,
          nameReceiver: row.nameReceiver,
          rutReceiver: row.rutReceiver.value,
          companyCommissionRules: row.currentCommissionRules,
          monthlyRate: row.monthlyRate.value,
          defaultRate: row.defaultRate.value,
          receiver: row.receiver,
          retentionRate: 100 - row.advancePercentage.value,
          amountWithIva: row.amountWithIva.value,
        })),
      );
      setOpenSimulation(true);
    }
  };

  const onSubmitNegotiation = () => {
    setSelectedForButtons([]);
    setNegotiationData([]);
    setClearSelection(true);
    offersRefetch();
    setClearSelection(false);
    setOpenNegotiation(false);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      invoiceId,
      nonSelectable,
      folio,
      nameReceiver,
      rutReceiver,
      receiver,
      collector,
      dateIssued,
      dateExpiration,
      amountWithIva,
      objectPurchaseOrder,
      documents,
      certificateUrl,
      offer,
      monthlyRate,
      defaultRate,
      advancePercentage,
      transferCondition,
      confirming,
      pdf,
      company,
    } = row;
    const monthlyWLRates = company.masterEntity.rateWhitelistEmitter;
    let monthlyWLRate = null;
    if (monthlyWLRates) {
      monthlyWLRate = monthlyWLRates.find((x) => !x.receiver || x.receiver.rut === rutReceiver);
    }
    let negotiationDisabled = false;
    let negotiationMessage = 'Negociar';
    const negotiationRequestStatus = offer ? offer.negotiationRequestStatus : null;
    if (negotiationRequestStatus) {
      switch (negotiationRequestStatus) {
        case 'PendingResponse':
          negotiationMessage = 'Negociando';
          negotiationDisabled = true;
          break;
        case 'Rejected':
          negotiationMessage = 'Renegociar';
          break;
        case 'Responded':
          negotiationMessage = 'Renegociar';
          break;
        default:
          break;
      }
    }
    return {
      id,
      invoiceId,
      nonSelectable,
      disabled: negotiationDisabled,
      nameReceiver,
      receiver,
      currentCommissionRules: company.currentCommissionRules,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography className={classes.textCell}>{folio}</Typography>
            <IconButton onClick={() => openPdf(pdf)}>
              <InsertDriveFile />
            </IconButton>
          </div>
        ),
        size: 'small',
      },
      rutReceiver: {
        value: rutReceiver,
        component: <DebtorCell debtorId={receiver.id} name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <div>
            <Typography className={classes.date}>{dateIssued.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateIssued)}</Typography>
          </div>
        ),
        size: 'small',
      },
      dateExpiration: {
        value: dateExpiration,
        component: (
          <div>
            <Typography className={classes.date}>{dateIssued.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateIssued)}</Typography>
          </div>
        ),
        size: 'small',
      },
      type: {
        value: confirming,
        component: (
          <Typography className={classes.textCell}>
            {confirming ? 'CONFIRMING' : 'FACTORING'}
          </Typography>
        ),
        size: 'small',
      },
      collectorName: {
        value: null,
        component: (
          <Typography className={classes.textCell}>
            {collector ? `${collector.firstName} ${collector.lastName}` : 'Sin asignar'}
          </Typography>
        ),
        size: 'small',
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography className={classes.textCell}>${formatMoney(amountWithIva)}</Typography>
        ),
        size: 'small',
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <Typography className={classes.textCell}>{monthlyRate.toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      monthlyWLRate: {
        value: monthlyWLRate,
        component: (
          <Typography className={classes.textCell}>
            {monthlyWLRate ? `${monthlyWLRate.monthlyRate.toFixed(2)}%` : '-'}
          </Typography>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <Typography className={classes.textCell}>{defaultRate.toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      advancePercentage: {
        value: advancePercentage,
        component: (
          <Typography className={classes.textCell}>{advancePercentage.toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      transferCondition: {
        value: transferCondition,
        component: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography align="left" variant="caption">
              {offerConditionToText[transferCondition]}
            </Typography>
          </div>
        ),
        size: 'small',
      },
      negotiation: {
        value: negotiationDisabled,
        component: (
          <Button
            className={classes.negotiationButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleNegotiationClick}
            disabled={(buttonStates && buttonStates[id]) || negotiationDisabled}
          >
            {negotiationMessage}
          </Button>
        ),
        size: 'small',
      },
      simulation: {
        component: (
          <Button
            className={classes.negotiationButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSimulationClick}
            disabled={buttonStates && buttonStates[id]}
          >
            Crear simulación
          </Button>
        ),
        size: 'small',
      },
      documents: {
        value: null,
        component: (
          <>
            <CustomerDocumentsMenu
              uploadOption
              onUploadClick={() => {
                setOpenDocuments(true);
                setSelectedInvoice(invoiceId);
              }}
              purchaseOrder={objectPurchaseOrder}
              pdf={pdf}
              certificate={certificateUrl}
              companyRut={clientRut}
              documents={documents}
            />
          </>
        ),
      },
    };
  });

  return (
    <Grid container>
      <Grid item xs={6} sm={6} className={classes.searchBar}>
        <Filters
          allData={offers}
          setFilteredData={setFilteredOffers}
          searchFields={['folio', 'nameReceiver', 'rutReceiver']}
          searchPlaceholder="Búsqueda por folio o rut o nombre de deudor"
        />
      </Grid>
      {filteredOffers.length > 0 ? (
        <Table
          headers={headers}
          initialOrderId="monthlyRate"
          setNewDataOrder={setFilteredOffers}
          data={filteredOffers}
          dataToRows={transformDataToComponents}
          withSelect
          withoutSelectHeader
          handleSelected={setSelectedForButtons}
          clearSelection={clearSelection}
        />
      ) : (
        <Grid item xs={12} sm={12}>
          {!loadingOffers && (
            <Typography variant="h4" align="center" className={classes.emptyInvoices}>
              No se encontraron facturas{' '}
            </Typography>
          )}
        </Grid>
      )}
      <CreateNegotiation
        open={openNegotiation}
        onClose={() => {
          setOpenNegotiation(false);
        }}
        onSubmit={onSubmitNegotiation}
        negotiationData={negotiationData}
        companyId={filteredOffers?.[0]?.companyId}
        negotiationType="OFFER"
      />
      <CreateFactoringSimulation
        openSimulation={openSimulation}
        onClose={() => {
          setOpenSimulation(false);
        }}
        onSubmit={handleSimulationClick}
        selectedRowsData={simulationData}
        type="OFFER"
      />
      <UploadDocumentsManager
        open={openDocuments}
        handleClose={() => {
          setOpenDocuments(false);
        }}
        invoiceId={selectedInvoice}
        refetchPage={() => offersRefetch()}
      />
    </Grid>
  );
};

Offers.propTypes = {
  setLoading: PropTypes.func,
};

Offers.defaultProps = {
  setLoading: () => {},
};

export default Offers;
