import { gql } from '@apollo/client';

export const GET_CLIENT_RISK = gql`
  query getCompanyRiskVariables(
    $rut: String!
    $access: String
  ) {
    getCompanyRiskVariables(rut: $rut, access: $access){
      url
    }
  }
`;

export const GET_CLIENT_AND_DEBTORS_RISK = gql`
  query getCompanyAndDebtorsRiskVariables(
    $clientRut: String!
    $debtorRuts: [String]!
  ) {
    getCompanyAndDebtorsRiskVariables(clientRut: $clientRut, debtorRuts: $debtorRuts){
      url
    }
  }
`;
