/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const UPLOAD_CONFIRMING = gql`
  mutation UploadConfirming($confirmingFile: Upload!) {
    uploadConfirming(confirmingFile: $confirmingFile){
      success
    }
  }
`;
const PROCESSING_CONFIRMING = gql`
  query ProcessingConfirming {
    processingConfirming {
      id
      dteType {
        id
        name
        code
      }
      folio
      company {
        masterEntity {
          name
          rut
        }
      }
      receiver {
        id
        rut
        name
      }
      amountWithIva {
        amount 
          currency {
            code
          }
        
      }
      createdAt
      updatedAt
    }
  }
`;
const AVAILABLE_FOR_TRANSFER_CONFIRMING = gql`
  query GetAvailableForTransferConfirming {
    getAvailableForTransferConfirming {
      id
      status
      confirming {
        id
        folio
        amountWithIva {
          amount
          currency {
            code
          }
        }
        company {
          id
          masterEntity {
            id
            name
            rut
          }
          companycompliance {
            id
            readyForTransfer
            status
            requirementsClean
            information
          }
        }
        receiver {
          id
          masterEntity {
            id 
            rut
            name
          }
        }
        dateToPay
        operation {
          monthlyRate
        }
      }
      createdAt
      updatedAt
    }
  }
`;
const GET_WAITING_APPROVAL_CONFIRMING = gql`
  query getWaitingForApprovalConfirming {
    getWaitingForApprovalConfirming {
      id
      treasureMessageId
      folios
      bank {
        id
        bankName
        accountRut
        accountType
        accountName
        accountEmail
        accountNumber
      }
      moneyTransfersId
      moneyTransferType
      total
      client {
        rut
        name
      }
    }
  }
`;

const CHECK_PROCESSING_CONFIRMING = gql`
  mutation checkConfirmingCession {
    checkConfirmingCession{
      success
    }
  }
`;

const GET_PROMPT_PAYMENT_DEAL = gql`
query GetPromptPaymentDeal {
  getPromptPaymentDeal {
      id
      promptPaymentCompany {
          id
          masterEntity {
              id
              rut
              name
          }
      }
      providerCompany {
          id
          masterEntity {
              id
              rut
              name
          }
      }
      whitelist {
        id
        monthlyRate
        defaultRate
        retentionRate
      }
  }
}
`;

export {
  UPLOAD_CONFIRMING,
  PROCESSING_CONFIRMING,
  AVAILABLE_FOR_TRANSFER_CONFIRMING,
  GET_WAITING_APPROVAL_CONFIRMING,
  CHECK_PROCESSING_CONFIRMING,
  GET_PROMPT_PAYMENT_DEAL,
};
