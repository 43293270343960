import { Typography, LinearProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_CONCILIABLE_ROSTERS } from '../../gql/conciliations';
import { getCollectionManagers, filterRosters } from '../../helpers/conciliation';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import IncomesTable from './incomesTable';
import { formatMoney } from '../../helpers/money';

const IncomesFunding = () => {
  const [rosters, setRosters] = useState([]);
  const {
    loading: rostersLoading,
    refetch: refetchRosters,
  } = useQuery(GET_CONCILIABLE_ROSTERS, {
    onCompleted: (data) => {
      setRosters(data.getConciliableRosters.map((roster) => ({
        ...roster,
        debtor: roster.fund,
      })));
    },
  });
  const headersRosters = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'fundName', label: 'Fondo', align: 'center' },
    { id: 'recesionDate', label: 'Fecha recesión', align: 'center' },
    { id: 'transferAmount', label: 'Monto a recibir', align: 'center' },
  ];
  const confirmationHeadersRosters = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'fundName', label: 'Fondo', align: 'center' },
    { id: 'recesionDate', label: 'Fecha recesión', align: 'center' },
    { id: 'transferAmount', label: 'Monto a girar', align: 'center' },
  ];
  const transformRosterToComponents = (_data) => _data.map((row) => {
    const {
      id,
      fund: {
        socialReason,
      },
      cessionFund,
      recesionDate,
      transferAmount,
      collectionManager: {
        id: collectionManagerId,
      },
    } = row;
    return ({
      collectionManagerId,
      cessionFund,
      id: {
        value: id,
        component: (
          <Typography variant="body2" component="span">
            {id}
          </Typography>
        ),
      },
      amountWithIva: {
        value: transferAmount,
      },
      fundName: {
        value: socialReason,
        component: (
          <Typography variant="body2">
            {socialReason}
          </Typography>
        ),
      },
      recesionDate: {
        value: recesionDate,
        component: (
          <Typography variant="body2">
            {moment(recesionDate).format('DD-MM-YYYY')}
          </Typography>
        ),
      },
      transferAmount: {
        value: transferAmount,
        component: (
          <Typography variant="body2">
            ${formatMoney(transferAmount)}
          </Typography>
        ),
      },
    });
  });
  if (rostersLoading) {
    return (
      <LinearProgress />
    );
  }

  return (
    <IncomesTable
      objectsToCollectHeaders={headersRosters}
      objectsToCollectConfirmationHeaders={confirmationHeadersRosters}
      objectsToCollectData={rosters}
      transformObjectsToComponents={transformRosterToComponents}
      objectsToCollectType="ROSTERS"
      getObjectsCollectionManagers={getCollectionManagers}
      refetchObjects={refetchRosters}
      objectsFilterFunction={filterRosters}
    />
  );
};

export default IncomesFunding;
