import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

import debtorsToRows from '../helpers/debtors-panel';
import { HEADERS, ROWS_PER_PAGE } from '../constants/debtors-panel';
import { PaginationTable } from '../../../components/tables';

const DebtorsTable = (props) => {
  const {
    debtors,
    totalCount,
    loading,
    error,
    page,
    setPage,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = props;

  if (loading) return <LinearProgress />;

  if (error) return `Error!: ${error}`;

  return (
    <PaginationTable
      headers={HEADERS}
      data={debtors}
      rowsCount={totalCount}
      dataToRows={debtorsToRows}
      page={page}
      setPage={setPage}
      rowsPerPage={ROWS_PER_PAGE}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      orderDirection={orderDirection}
      setOrderDirection={setOrderDirection}
    />
  );
};

DebtorsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  debtors: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  orderDirection: PropTypes.string.isRequired,
  setOrderDirection: PropTypes.func.isRequired,
};

DebtorsTable.defaultProps = {
  debtors: [],
  totalCount: 0,
  loading: true,
  error: '',
};

export default DebtorsTable;
