/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const clients = [
  'Maggie Bullen',
  'Adena Autrey',
  'Ivelisse Lingle',
  'Weldon Dampier',
  'Eloy Chatmon',
  'Araceli Braswell',
  'Dewitt Saffer',
  'Graciela Struck',
  'Vesta Danzy',
  'Sebrina Lawing',
  'Latashia Hanby',
  'Josette Oliverio',
  'Holly Junkins',
  'Marcel Abila',
  'Kenna Krug',
  'Carleen Hellard',
  'Marianela Rolfe',
  'Junko Felix',
  'Lashaunda Palermo',
  'Karina Blackshear',
  'Charla Swafford',
  'Ola Moll',
  'Nichole Bakke',
  'Tiffany Hise',
  'Josephine Strecker',
  'Charlene Pixley',
  'Maribeth Rollo',
  'Lonny Mealey',
  'Archie Mollett',
  'Bronwyn Mynatt',
  'Doug Hegg',
  'Cythia Pou',
  'Juli Kuehne',
  'Muoi Manigault',
  'Marcia Soderberg',
  'Janis Honda',
  'Christia Kasprzak',
  'Chiquita Findlay',
  'Pierre Grandy',
  'Emmanuel Hewes',
  'Darcie Blasi',
  'Erma Gushiken',
  'Garnett Philippe',
  'Misty Evenson',
  'Hipolito Lepine',
  'Sierra Prevost',
  'Cecila Aust',
  'Alyssa Schober',
  'Lezlie Lund',
  'Sigrid Jacobus',
];

const CopyCommission = ({ open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">¿A qué cliente le quieres copiar las reglas?</DialogTitle>
    <DialogContent>
      <Autocomplete
        id="combo-box-demo"
        options={clients}
        getOptionLabel={(option) => option}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="error"
      >
        Cancelar
      </Button>
      <Button
        onClick={onClose}
        color="primary"
      >
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

CopyCommission.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CopyCommission;
