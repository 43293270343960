import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Grid, Typography, Button, LinearProgress, InputAdornment, OutlinedInput, CircularProgress } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { SearchOutlined } from '@material-ui/icons';
import { useStyles } from './styles';
import MovementsTable from './MovementsTable';
import { GET_MOVEMENTS, GET_REQUESTS, CONCILIATE } from '../gql/conciliations';
import { Table } from '../components/tables';
import { parseRut } from '../helpers/generic';
import { getIds } from '../helpers/conciliation';
import ConciliationConfirmation from '../components/dialogs/ConciliationConfirmation';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import { formatMoney } from '../helpers/money';
import ConciliaitonSuccessful from '../components/dialogs/ConciliationSuccessful';

const Expenses = () => {
  const classes = {
    ...useStyles(),
  };

  const [requests, setRequests] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [distinctSelectedRequests, setDistinctSelectedRequests] = useState(true);
  const [totalRequestsSelected, setTotalRequestsSelected] = useState(0);
  const [selectedMoves, setSelectedMoves] = useState([]);
  const [totalMoveSelected, setTotalMoveSelected] = useState(0);
  const [reset, setReset] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [filter, setFilter] = useState('');
  const [loadingConciliation, setLoadingConciliation] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);

  const {
    data: requestsData,
    loading: requestsLoading,
    refetch: refetchRequests,
  } = useQuery(GET_REQUESTS);

  const {
    data: movementsData,
    loading: movementsLoading,
    refetch: refetchMovements,
    error: movementsError,
  } = useQuery(GET_MOVEMENTS, { variables: { movementType: 'expenses' } });

  const [conciliate] = useMutation(CONCILIATE);

  useEffect(() => {
    if (requestsData) {
      setRequests(requestsData.getRequests);
    }
  }, [requestsData]);

  useEffect(() => {
    setTotalRequestsSelected(selectedRequests.reduce(
      (total, row) => total + row.amount.value, 0,
    ));
    const uniqueTransferTypes = [...new Set(selectedRequests.map((data) => (data.transferType)))];
    setDistinctSelectedRequests(uniqueTransferTypes.length > 1);
  }, [selectedRequests]);

  useEffect(() => {
    setTotalMoveSelected(selectedMoves.reduce((total, row) => total + row.amount.value, 0));
  }, [selectedMoves]);

  useEffect(() => {
    if (requestsData) {
      setRequests(requestsData.getRequests.filter((request) => {
        const idCondition = request.id.toString().includes(filter);
        const nameCondition = (
          request.bankAccount.company.name.toLowerCase().includes(filter.toLowerCase())
        );
        const amountCondition = request.amount.toString().includes(filter);
        const rutCondition = request.bankAccount.accountRut.includes(filter);
        return idCondition || nameCondition || amountCondition || rutCondition;
      }));
    }
  }, [filter]);

  const headersRequests = [
    { id: 'requestId', label: 'ID', align: 'left' },
    { id: 'companyName', label: 'Razón Social Receptor', align: 'left' },
    { id: 'accountRut', label: 'Rut Receptor', align: 'left' },
    { id: 'amount', label: 'Monto', align: 'left' },
  ];

  const transformRequestToComponents = (_data) => _data.map((row) => {
    const { id, amount } = row;
    const { name: companyName, masterEntity } = row.bankAccount.company;
    const { rut: companyRut, dv: companyDv } = masterEntity;
    const fullRut = companyRut.toString() + companyDv;
    return ({
      id,
      requestId: {
        value: id,
        component: (
          <Typography>{id}</Typography>
        ),
      },
      companyName: {
        value: companyName,
        component: (
          <Typography className={classes.twoLineClamp}>{companyName}</Typography>
        ),
      },
      accountRut: {
        value: fullRut,
        component: (
          <Typography>{parseRut(fullRut)}</Typography>
        ),
      },
      amount: {
        value: amount,
        component: (
          <Typography>${formatMoney(amount)}</Typography>
        ),
      },
    });
  });

  const conciliationAction = async () => {
    setLoadingConciliation(true);
    await conciliate({ variables: {
      movements: getIds(selectedMoves),
      requests: getIds(selectedRequests),
      conciliationType: 'Manual',
      conciliationDate: new Date(),
    } });
    setReset(true);
    refetchMovements();
    refetchRequests();
    setSelectedRequests([]);
    setSelectedMoves([]);
    setReset(false);
    setOpenDialog(false);
    setWarnings([]);
    setLoadingConciliation(false);
    setSuccessDialog(true);
  };

  const match = (movements, requests_) => {
    const diffs = [];
    const moveRut = movements[0].recipientHolderId;
    const requestRut = requests_[0].accountRut.value;
    if (moveRut !== requestRut) {
      diffs.push('El RUT del pagador y receptor no calzan');
    }
    if (movements.filter((move) => move.recipientHolderId !== moveRut).length > 0) {
      diffs.push('Los movimientos pertenecen a más de un cobrador');
    }
    if (requests_.filter((request) => request.accountRut.value !== requestRut).length > 0) {
      diffs.push('Las solicitudes pertenecen a más de un receptor');
    }
    if (Math.abs(totalRequestsSelected) !== Math.abs(totalMoveSelected)) {
      diffs.push('Los montos totales no calzan');
    }
    setWarnings([...diffs]);
    return diffs;
  };

  const handleSelectedMoves = (moves) => {
    setSelectedMoves(moves);
    if (moves.length > 0) {
      const newInvoices = [...requests].sort((a) => {
        const rut = a.bankAccount.accountRut + a.bankAccount.company.masterEntity.dv;
        if (moves[0].recipientHolderId === rut) {
          return -1;
        }
        return 1;
      });
      setRequests(newInvoices);
    }
  };

  if (permissionDeniedErrorCatcher(movementsError)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Box>
        <Grid container spacing={1} className={classes.conciliations}>
          <Grid item xs={6}>
            <MovementsTable
              movementType="expenses"
              setSelectedMoves={handleSelectedMoves}
              selectedMoves={selectedMoves}
              movementsData={movementsData ? movementsData.getMovements : []}
              movementsLoading={movementsLoading}
              reset={reset}
              totalSelected={totalMoveSelected}
              refetchMovements={refetchMovements}
              resetSelectedMoves={() => setSelectedMoves([])}
            />
          </Grid>
          <Grid item xs={6}>
            <Box component="div" className={classes.movements}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h4" className={classes.tableHeader}>
                    <b>Solicitudes de Giro y Excedentes</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    id="input-with-icon-adornment"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                      )}
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </Grid>
              </Grid>
              {requestsLoading ? <LinearProgress /> : (
                <>
                  {requests.length > 0 ? (
                    <>
                      <Box border={0.5} borderColor="grey.500" className={classes.tableBox}>
                        <Table
                          data={requests}
                          dataToRows={transformRequestToComponents}
                          setNewDataOrder={setRequests}
                          headers={headersRequests}
                          initialOrderId="receiverName"
                          handleSelected={setSelectedRequests}
                          selectedObjects={selectedRequests}
                          withSelect
                          withoutSelectHeader
                          clearSelection={reset}
                          pagination={false}
                        />
                      </Box>
                      <Grid container justify="space-between" className={classes.totalSelected}>
                        <Grid item>
                          <Typography variant="h5">Total seleccionado:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">${formatMoney(totalRequestsSelected)}</Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Typography variant="h5" align="center" className={classes.listEmpty}>
                      {filter ? 'No hay coincidencias' : 'No hay solicitudes sin conciliar'}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" className={classes.buttonLine}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={
                selectedMoves.length === 0 || selectedRequests.length === 0 || loadingConciliation
                  || distinctSelectedRequests
              }
              onClick={() => {
                if (match(selectedMoves, selectedRequests).length === 0) {
                  conciliationAction();
                } else {
                  setOpenDialog(!openDialog);
                }
              }}
            >
              Conciliar
              {loadingConciliation && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConciliationConfirmation
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        warnings={warnings}
        conciliationAction={conciliationAction}
        loadingConciliation={loadingConciliation}
        setLoadingConciliation={setLoadingConciliation}
      />
      <ConciliaitonSuccessful
        open={successDialog}
        setOpen={setSuccessDialog}
      />
    </>
  );
};

export default Expenses;
