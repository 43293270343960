import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import { AddNewContact, EditContact } from '../contacts';
import { GET_COMPANY_COLLECTION_CONTACTS } from '../../gql/collection';

const headers = [
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'contact', label: 'Contacto', align: 'left' },
  { id: 'actions', label: 'Acciones', align: 'left' },
];
const useStyles = makeStyles({
  addContactContainer: {
    textAlign: 'center',
  },
  container: {
    margin: '2%',
  },
});

const CollectionContactsTable = ({ companyRut, companyName, onSelect }) => {
  const { data: contactsData, refetch: contactsRefetch } = useQuery(
    GET_COMPANY_COLLECTION_CONTACTS,
    { variables: { companyRut }, fetchPolicy: 'network-only' },
  );
  const [contacts, setContacts] = useState();
  const [openEditContact, setOpenEditContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState();
  const classes = useStyles();
  const handleEditContact = (row) => {
    setContactToEdit(row);
    setOpenEditContact(true);
  };
  useEffect(() => {
    if (contactsData && contactsData.getCompanyCollectionContacts) {
      setContacts(contactsData.getCompanyCollectionContacts);
    }
  }, [contactsData]);
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
    } = row;
    return ({
      id,
      name: {
        component: (
          <>
            <Typography variant="body2">{firstName}</Typography>
            <Typography variant="body2">{lastName}</Typography>
            <Typography variant="caption"><b>{position || 'Sin cargo'}</b></Typography>
          </>
        ),
        value: firstName,
      },
      contact: {
        component: (
          <>
            <Typography variant="body2">{email || 'Sin email'}</Typography>
            <Typography variant="body2">{phoneNumber || 'Sin telefono'}</Typography>
          </>
        ),
        value: position,
      },
      actions: {
        component: (
          <>
            <Button color="primary" onClick={() => handleEditContact(row)}>Editar</Button>
          </>
        ),
      },
    });
  });
  return (
    <Box className={classes.container}>
      { contacts ? (
        <Table
          data={contacts}
          dataToRows={transformDataToComponents}
          headers={headers}
          withSelect={Boolean(onSelect)}
          pagination={false}
          setNewDataOrder={setContacts}
          handleSelected={onSelect}
        />
      ) : (
        <CircularProgress />
      )}
      <Grid className={classes.addContactContainer}>
        <AddNewContact
          companyName={companyName}
          companyRut={companyRut}
          type="COLLECTION"
          contactsRefetch={contactsRefetch}
        />
      </Grid>
      <EditContact
        companyName={companyName}
        companyRut={companyRut}
        openForm={openEditContact}
        setOpenForm={setOpenEditContact}
        contact={contactToEdit}
        type="COLLECTION"
        contactsRefetch={contactsRefetch}
      />
    </Box>
  );
};

CollectionContactsTable.propTypes = {
  companyRut: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CollectionContactsTable;
