import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Table } from '../tables';
import { changeNumberColor, operatorMapper, setResultIcon, titleMapper } from '../../helpers/riskEngineMapper';
import { parseMoney } from '../../helpers/money';
import { toPercentage } from '../../helpers/generic';

const useStyles = makeStyles({
  success: {
    color: 'green',
  },
  failure: {
    color: 'red',
  },
});

const ResultDetail = ({
  open,
  onClose,
  resultType,
  riskResults,
  resultHeader,
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataContent, setDataContent] = useState([]);
  const [tabIcons, setTabIcons] = useState({
    client: <Cancel className="failure" />,
    debtor: <Cancel className="failure" />,
    relation: <Cancel className="failure" />,
  });

  const headers = [
    { id: 'rule', label: 'Regla', align: 'center' },
    { id: 'value', label: 'Valor', align: 'center' },
    { id: 'operator', label: `${resultType === 'TREE' || resultType === 'Tree' ? 'No debe' : 'Debe'} ser`, align: 'center' },
    { id: 'threshold', label: 'Threshold', align: 'center' },
    { id: 'result', label: resultType === 'TREE' || resultType === 'Tree' ? 'Resultado' : 'Premio', align: 'center' },
  ];

  useEffect(() => {
    if (riskResults) {
      if (resultType === 'TREE' || resultType === 'Tree') {
        setTabIcons({
          client: setResultIcon(resultHeader[0], classes),
          debtor: setResultIcon(resultHeader[1], classes),
          relation: setResultIcon(resultHeader[2], classes),
        });
      } else {
        setTabIcons({
          client: changeNumberColor(resultHeader[0], classes),
          debtor: changeNumberColor(resultHeader[1], classes),
          relation: changeNumberColor(resultHeader[2], classes),
        });
      }
    }
  }, [riskResults, resultType]);

  useEffect(() => {
    if (riskResults) {
      if (selectedTab === 0) {
        setDataContent(
          riskResults.filter(
            (rule) => rule.name.startsWith('client') && !rule.name.startsWith('client_debtor'),
          ),
        );
      }
      if (selectedTab === 1) {
        setDataContent(
          riskResults.filter(
            (rule) => rule.name.startsWith('debtor'),
          ),
        );
      }
      if (selectedTab === 2) {
        setDataContent(
          riskResults.filter(
            (rule) => rule.name.startsWith('client_debtor'),
          ),
        );
      }
    }
  }, [riskResults, selectedTab, resultType]);

  const valueHandler = (value, valueParser) => {
    if (value === null) {
      return 'n/a';
    }
    if (typeof value === 'boolean') {
      return value ? 'Si' : 'No';
    }
    if (valueParser === '$') {
      const moneySign = value >= 0 ? '' : '-';
      return moneySign.concat(parseMoney(value));
    }
    if (valueParser === '%') {
      return toPercentage(value);
    }
    return value;
  };

  const dataToRows = (_data) => _data.map((row) => {
    const {
      name,
      labelName,
      description,
      valueParser,
      value,
      logicalType,
      threshold,
      rejects,
      prize,
    } = row;
    return ({
      rule: {
        value: name,
        component: (
          <Tooltip title={description} arrow>
            <Typography>{labelName}</Typography>
          </Tooltip>
        ),
      },
      value: {
        value,
        component: (
          <Typography>{valueHandler(value, valueParser)}</Typography>
        ),
      },
      operator: {
        value: logicalType,
        component: (
          <Typography>{operatorMapper[logicalType]}</Typography>
        ),
      },
      threshold: {
        value: threshold,
        component: (
          <Typography>{valueHandler(threshold, valueParser)}</Typography>
        ),
      },
      result: {
        value: resultType === 'TREE' || resultType === 'Tree' ? rejects : prize,
        component: resultType === 'TREE' || resultType === 'Tree' ? (
          <Box>
            {setResultIcon(!rejects, classes)}
          </Box>
        ) : (
          <Typography>{prize}</Typography>
        ),
      },
    });
  });
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h3">
          Resultados {titleMapper[resultType]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
        >
          <Tab
            label="Cliente"
            icon={tabIcons.client}
          />
          <Tab
            label="Deudor"
            icon={tabIcons.debtor}
          />
          <Tab
            label="Relación"
            icon={tabIcons.relation}
          />
        </Tabs>
        <Table
          headers={headers}
          initialOrderId="name"
          data={dataContent}
          dataToRows={dataToRows}
          setNewDataOrder={setDataContent}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResultDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resultType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  riskResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resultHeader: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResultDetail;
