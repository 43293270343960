import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute';
import {
  CompanyPreOffers,
  Login,
  UnderConstruction,
  Ratification,
  Contactability,
  ClientRisk,
  CompanyPreOffersToAccept,
  CompanyPreOffersToReject,
  AvailableForTransfer,
  WaitingApproval,
  Collection,
  Whitelist,
  MassiveMails,
  MassiveMailsForm,
  CustomerMaintainer,
  CustomerDetail,
  Incomes,
  Expenses,
  AssignmentExecutive,
  ExecutiveManager,
  ManageClientCommissions,
  ClientInvoiceStatus,
  CollectionPaymentPlans,
  RiskAlgorithm,
  ConfirmingInvoices,
  ConfirmingProcessing,
  AvailableForTransferConfirming,
  WaitingApprovalConfirming,
  PromptPaymentDeal,
  CreateCompany,
  CreateUser,
  OrderingSimulationEvaluationRequests,
  CompanyOrderingSimulations,
  SimulationToAccept,
  SimulationToReject,
  OrderingPendingDocuments,
  OrderingInvoices,
  PurchaseOrderInvoices,
  AdvancePaymentCompanyEnrollment,
  AdvancePaymentRequest,
  PaysheetExpenses,
  AvailableForTransferOrdering,
  OcAndHesReview,
  RatesNegotiation,
  RatesNegotiationRespond,
  Blacklist,
  FundsDownload,
  RosterDetail,
  DebtorAdmin,
  DebtorsPanel,
  DebtorProfile,
  ExternalPurchaseOrder,
  EvaluationsRequests,
} from './views';

import { Layout } from './components/layout';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/app">
          <Layout>
            <>
              {/* <PrivateRoute path="/clients/:clientRut" component={ClientInvoices} />
          <PrivateRoute path="/clients" component={Clients} /> */}
              <PrivateRoute
                exact
                path="/app/risk/evaluations/preoffer/:clientRut"
                component={CompanyPreOffers}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations/preoffer/:clientRut/to-accept"
                component={CompanyPreOffersToAccept}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations/preoffer/:clientRut/to-reject"
                component={CompanyPreOffersToReject}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations/simulation/:clientRut"
                component={CompanyOrderingSimulations}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations/simulation/:clientRut/to-accept"
                component={SimulationToAccept}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations/simulation/:clientRut/to-reject"
                component={SimulationToReject}
              />
              <PrivateRoute
                exact
                path="/app/risk/evaluations"
                component={EvaluationsRequests}
              />
              <PrivateRoute
                exact
                path="/app/risk/blacklist"
                component={Blacklist}
              />
              <PrivateRoute
                exact
                path="/app/risk/contactability"
                component={Contactability}
              />
              <PrivateRoute
                exact
                path="/app/ratification/priority"
              >
                <Ratification type="priority" />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/app/ratification/non-priority"
              >
                <Ratification type="nonPriority" />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/app/without-ratification/oc-hes-review"
                component={OcAndHesReview}
              />
              <PrivateRoute
                exact
                path="/app/risk/risk"
                component={ClientRisk}
              />
              <PrivateRoute
                exact
                path="/app/risk/customer-maintainer"
                component={CustomerMaintainer}
              />
              <PrivateRoute
                exact
                path="/app/risk/customer-detail"
                component={CustomerDetail}
              />
              <PrivateRoute
                exact
                path="/app/risk/assignment-executive"
                component={AssignmentExecutive}
              />
              <PrivateRoute
                exact
                path="/app/risk/executive-manager"
                component={ExecutiveManager}
              />
              <PrivateRoute
                exact
                path="/app/risk/debtor-administration"
                component={DebtorAdmin}
              />
              <PrivateRoute
                exact
                path="/app/risk/debtors-panel"
                component={DebtorsPanel}
              />
              <PrivateRoute
                exact
                path="/app/risk/debtor-panel/:rut"
                component={DebtorProfile}
              />
              <PrivateRoute
                exact
                path="/app/risk/rates-negotiation"
                component={RatesNegotiation}
              />
              <PrivateRoute
                exact
                path="/app/risk/rates-negotiation/:negotiationId"
                component={RatesNegotiationRespond}
              />
              <PrivateRoute
                exact
                path="/app/risk/mass-email"
                component={MassiveMails}
              />
              <PrivateRoute
                exact
                path="/app/risk/new-mass-email"
                component={MassiveMailsForm}
              />
              <PrivateRoute
                exact
                path="/app/risk/whitelist"
                component={Whitelist}
              />
              <PrivateRoute
                exact
                path="/app/risk/risk-algorithm"
                component={RiskAlgorithm}
              />
              <PrivateRoute
                exact
                path="/app/collect/simple"
                component={Collection}
              />
              <PrivateRoute
                exact
                path="/app/collect/judiciary"
                component={UnderConstruction}
              />
              <PrivateRoute
                exact
                path="/app/collect/payment-plans"
                component={CollectionPaymentPlans}
              />
              <PrivateRoute
                exact
                path="/app/payment/available"
                component={AvailableForTransfer}
              />
              <PrivateRoute
                exact
                path="/app/payment/waiting"
                component={WaitingApproval}
              />
              <PrivateRoute
                exact
                path="/app/conciliate/incomes"
                component={Incomes}
              />
              <PrivateRoute
                exact
                path="/app/conciliate/expenses"
                component={Expenses}
              />
              <PrivateRoute
                exact
                path="/app/conciliate/paysheet-expenses"
                component={PaysheetExpenses}
              />
              <PrivateRoute
                exact
                path="/app/client/:clientRut/manage-commissions"
                component={ManageClientCommissions}
              />
              <PrivateRoute
                exact
                path="/app/client/:clientRut/invoice-status"
                component={ClientInvoiceStatus}
              />
              <PrivateRoute
                exact
                path="/app/confirming/upload-invoices"
                component={ConfirmingInvoices}
              />
              <PrivateRoute
                exact
                path="/app/confirming/processing"
                component={ConfirmingProcessing}
              />
              <PrivateRoute
                exact
                path="/app/confirming/available-for-transfer"
                component={AvailableForTransferConfirming}
              />
              <PrivateRoute
                exact
                path="/app/confirming/waiting-for-approval"
                component={WaitingApprovalConfirming}
              />
              <PrivateRoute
                exact
                path="/app/confirming/prompt-payment-deal"
                component={PromptPaymentDeal}
              />
              <PrivateRoute
                exact
                path="/app/ordering/add-invoices"
                component={OrderingInvoices}
              />
              <PrivateRoute
                exact
                path="/app/ordering/add-invoices/:purchaseOrderId"
                component={PurchaseOrderInvoices}
              />
              {/* deprecated */}
              <PrivateRoute
                exact
                path="/app/ordering/simulations"
                component={OrderingSimulationEvaluationRequests}
              />
              <PrivateRoute
                exact
                path="/app/ordering/pending-documents"
                component={OrderingPendingDocuments}
              />
              <PrivateRoute
                exact
                path="/app/ordering/available-for-transfer"
                component={AvailableForTransferOrdering}
              />
              <PrivateRoute
                exact
                path="/app/ordering/external-orders"
                component={ExternalPurchaseOrder}
              />
              <PrivateRoute
                exact
                path="/app/advance-payment/pending-activation"
                component={AdvancePaymentCompanyEnrollment}
              />
              <PrivateRoute
                exact
                path="/app/advance-payment/active-requests"
                component={AdvancePaymentRequest}
              />
              <PrivateRoute
                exact
                path="/app/utils/create-company"
                component={CreateCompany}
              />
              <PrivateRoute
                exact
                path="/app/utils/create-user"
                component={CreateUser}
              />
              <PrivateRoute
                exact
                path="/app/funding/funds-download"
                component={FundsDownload}
              />
              <PrivateRoute
                exact
                path="/app/funding/roster/:rosterId"
                component={RosterDetail}
              />
            </>
          </Layout>
        </Route>
        <Route exact path="/" component={Login} />
      </Switch>
    </Router>
  );
}

export default App;
