import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
});

const EditExecutive = ({
  open,
  selectedExecutive,
  handleClose,
  handleUpdateExecutive,
}) => {
  const classes = useStyles();
  const [executiveId, setExecutiveId] = useState(null);
  const [active, setActive] = useState(null);
  const [tycActiveAssignation, setTycActiveAssignation] = useState(null);
  const [loginActiveAssignation, setLoginActiveAssignation] = useState(null);
  const [clickActiveAssignation, setClickActiveAssignation] = useState(null);

  useEffect(() => {
    if (selectedExecutive.active !== null) {
      setActive(selectedExecutive.active);
    }
    if (selectedExecutive.tycActiveAssignation !== null) {
      setTycActiveAssignation(selectedExecutive.tycActiveAssignation);
    }
    if (selectedExecutive.loginActiveAssignation !== null) {
      setLoginActiveAssignation(selectedExecutive.loginActiveAssignation);
    }
    if (selectedExecutive.clickActiveAssignation !== null) {
      setClickActiveAssignation(selectedExecutive.clickActiveAssignation);
    }
    if (selectedExecutive.id !== null) {
      setExecutiveId(selectedExecutive.id);
    }
  }, [selectedExecutive]);

  const handleConfirm = () => {
    handleUpdateExecutive(
      executiveId,
      active,
      tycActiveAssignation,
      loginActiveAssignation,
      clickActiveAssignation,
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>Editar Ejecutivo {selectedExecutive.firstName} {selectedExecutive.lastName}</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Box className={classes.section}>
          <FormControl fullWidth>
            <InputLabel id="active-executive">Ejecutivo Activo</InputLabel>
            <Select
              labelId="active-executive"
              id="demo-simple-select"
              label="Ejecutivo Activo"
              value={active}
              onChange={(e) => setActive(e.target.value === 'true')}
            >
              <MenuItem value="true">Si</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.section}>
          <FormControl fullWidth>
            <InputLabel id="tyc-active-assignation">Asignación de TYC</InputLabel>
            <Select
              id="ratification-contact-select"
              value={tycActiveAssignation}
              onChange={(e) => setTycActiveAssignation(e.target.value === 'true')}
              label="Asignación de TYC"
            >
              <MenuItem value="true">Si</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.section}>
          <FormControl fullWidth>
            <InputLabel id="login-active-assignation">Asignación de Login</InputLabel>
            <Select
              id="ratification-contact-select"
              value={loginActiveAssignation}
              onChange={(e) => setLoginActiveAssignation(e.target.value === 'true')}
              label="Asignación de Login"
            >
              <MenuItem value="true">Si</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.section}>
          <FormControl fullWidth>
            <InputLabel id="click-active-assignation">Asignación de Click</InputLabel>
            <Select
              id="ratification-contact-select"
              value={clickActiveAssignation}
              onChange={(e) => setClickActiveAssignation(e.target.value === 'true')}
              label="Asignación de Click"
            >
              <MenuItem value="true">Si</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          onClick={() => { handleConfirm(); }}
          color="primary"
        >
          Guardar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

EditExecutive.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdateExecutive: PropTypes.func.isRequired,
  selectedExecutive: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    tycActiveAssignation: PropTypes.bool.isRequired,
    loginActiveAssignation: PropTypes.bool.isRequired,
    clickActiveAssignation: PropTypes.bool.isRequired,
  }).isRequired,
};

export default EditExecutive;
