import React, { useState } from 'react';
import { Typography, Grid, Button, IconButton, Snackbar, Box, CircularProgress, LinearProgress, Tooltip } from '@material-ui/core';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { ArrowBackIos, InsertDriveFile, OpenInNew, Cached, Block, Error } from '@material-ui/icons';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { Table } from '../components/tables';
import { AlertsMenu } from '../components/customers';
import { RestrictionsToAcceptPreOffers } from '../components/dialogs';
import { GET_COMPANY_ORDERING_EVALUATIONS, CREATE_COMPANY_RESTRICTION } from '../gql/customer';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import VisualizeAllDocument from '../components/companyDocumentsPreoffers/VisualizeAllDocuments';
import { daysBetween } from '../helpers/date';
import { changeNumberColor, setResultIcon } from '../helpers/riskEngineMapper';
import { calculateRasFinalResult, calculateTreeFinalResult } from '../components/risk/Helpers';
import VariablesDetail from '../components/riskEngine/VariablesDetail';
import ResultDetail from '../components/riskEngine/ResultDetail';
import { getDialogLikeRiskResult } from '../helpers/evaluationsRequests';
import tooltipHandler from '../helpers/riskTooltip';
import DebtorCell from '../components/shared/DebtorCell';
import { GET_CLIENT_DEBTORS_LIST_EXCEL } from '../gql/clientDebtorsListExcel';

const headers = [
  { id: 'orderNumber', label: 'Número de orden', align: 'left' },
  { id: 'name', label: 'Comprador', align: 'left' },
  { id: 'invoiceIssuedDate', label: 'Emisión de factura', align: 'right' },
  { id: 'invoiceDateToPay', label: 'Pago de factura', align: 'right' },
  { id: 'orderingAmount', label: 'Monto orden de compra', align: 'right' },
  { id: 'pdfFile', label: 'PDF', align: 'right' },
  { id: 'riskResults', label: 'V. Riesgo', align: 'center' },
  { id: 'treeResults', label: 'Árbol', align: 'center' },
  { id: 'rasResults', label: 'RAS', align: 'center' },
  { id: 'bureausAlert', label: 'Alertas', align: 'center' },
];
const CompanyOrderingSimulations = () => {
  const location = useLocation();
  const companyRut = location.pathname.split('/').pop();
  // TODO: this should be a CustomHook
  const [getExcelUrl, {
    loading: loadingExcel,
  }] = useLazyQuery(GET_CLIENT_DEBTORS_LIST_EXCEL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getClientDebtorsListExcel) {
        window.open(data.getClientDebtorsListExcel.url);
      }
    },
  });
  const [simulations, setSimulations] = useState([]);
  const [company, setCompany] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const [restrictionsMutation, setRestrictionsMutation] = useState([]);
  const [modalRestrictions, setModalRestrictions] = useState(false);
  const [createCompanyRestriction,
    { loading: createRestrictionLoading }] = useMutation(CREATE_COMPANY_RESTRICTION);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { refetch, loading, error } = useQuery(GET_COMPANY_ORDERING_EVALUATIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      company_MasterEntity_Rut_In: [companyRut],
      inEvaluation: true,
    },
    onCompleted: (data) => {
      setCompany(data.purchaseOrders.edges[0].node.company);
      setSimulations(data.purchaseOrders.edges.reduce((previousValue, value) => {
        previousValue.push(value.node);
        return previousValue;
      }, []));
    } });
  const [riskResults, setRiskResults] = useState({
    type: '',
    open: false,
    data: [],
    header: [],
  });
  const [riskVariables, setRiskVariables] = useState({
    ratification: {},
    open: false,
    orderId: null,
    purchaser: {},
  });

  const handleSelectedSimulations = (_selectedSimulations) => {
    const completeSimulations = _selectedSimulations.map(
      (si) => simulations.filter((selSim) => selSim.id === si.id)[0],
    ).filter((filSim) => filSim);
    setSelectedSimulations(completeSimulations);
  };

  const handleRestrictions = (_restrictions) => {
    const { restrictions } = _restrictions;
    const restrictionsChecked = restrictions.filter((res) => res.checked);
    const newRestrictionsMutation = [];
    const other = restrictions.find((obj) => obj.name === 'OTHER');
    const otherRestriction = other ? other.value : '';
    restrictionsChecked.map((res) => (res.name === 'OTHER' ? newRestrictionsMutation.push({
      companyRut,
      restriction: res.name,
      otherRestriction,
    })
      : newRestrictionsMutation.push({
        companyId: companyRut,
        restriction: res.name,
        otherRestriction: '',
      })));
    setRestrictionsMutation(newRestrictionsMutation);
  };

  const handleCreateRestrictions = async () => {
    try {
      await createCompanyRestriction({
        variables: {
          requestRestriction: restrictionsMutation,
        },
      });
    } catch (err) {
      // console.log('EL ERROR ES', err);
    }
  };

  const handleAccept = () => {
    history.push({
      pathname: `/app/risk/evaluations/simulation/${companyRut}/to-accept`,
      state: { selectedSimulations },
    });
  };

  const handleReject = () => {
    history.push({
      pathname: `/app/risk/evaluations/simulation/${companyRut}/to-reject`,
      state: { selectedSimulations },
    });
  };

  // function passed to the risk result dialog
  const handleCloseRiskResult = () => {
    setRiskResults({
      type: '',
      open: false,
      data: [],
      header: [],
    });
  };
  // function passed to the risk variable dialog
  const handleCloseRiskVariables = () => {
    setRiskVariables({
      ratification: {},
      open: false,
      orderId: null,
      purchaser: {},
    });
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/app/risk/evaluations',
      state: {
        tab: 1,
      },
    });
  };

  const handleDownloadExcel = (rut) => {
    getExcelUrl({
      variables: {
        clientRut: rut,
      },
    });
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      company: {
        companyRiskHistory,
      },
      orderNumber,
      purchaser,
      pdfFileUrl,
      riskEvaluationModel,
      companyReceiverRiskHistory,
      orderingsimulation: {
        invoiceIssuedDate,
        invoiceDateToPay,
      },
      totalAmount,
    } = row;
    const ratificationBlacklistActive = (
      purchaser.ratificationBlacklist ? purchaser.ratificationBlacklist.active : false
    );
    const treeFinalResult = calculateTreeFinalResult(riskEvaluationModel);
    const rasFinalResult = calculateRasFinalResult(riskEvaluationModel);

    // function for setting the clicked row RiskResultData
    const handleRiskResultButton = (type) => {
      const dialogLikeRiskResult = getDialogLikeRiskResult(type, riskEvaluationModel);
      setRiskResults(dialogLikeRiskResult);
    };

    // function for setting the clicked row RiskVariablesData
    const handleRiskVariablesButton = () => {
      setRiskVariables({
        orderId: id,
        purchaser,
        ratification: {
          ratification: riskEvaluationModel.ratification,
          reason: riskEvaluationModel.reason,
        },
        open: true,
      });
    };

    return ({
      id,
      orderNumber: {
        value: orderNumber,
        component: (
          <Typography align="left">{orderNumber}</Typography>
        ),
      },
      name: {
        value: purchaser.rut,
        component: (
          <DebtorCell
            debtorId={purchaser.id}
            name={purchaser.name}
            rut={purchaser.rut}
            hasRatificationBlacklist={ratificationBlacklistActive}
            hasRiskBlacklist={purchaser.riskBlacklist}
            creditLine={purchaser.creditLine.amount}
          />
        ),
      },
      invoiceIssuedDate: {
        value: invoiceIssuedDate,
        component: (
          <Grid>
            <Typography align="left">{moment(invoiceIssuedDate).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceIssuedDate))} días</Typography>
          </Grid>
        ),
      },
      invoiceDateToPay: {
        value: invoiceDateToPay,
        component: (
          <Grid>
            <Typography align="left">{moment(invoiceDateToPay).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceDateToPay))} días</Typography>
          </Grid>
        ),
      },
      orderingAmount: {
        value: totalAmount,
        component: (
          <Typography align="left">${formatMoney(totalAmount.amount)}</Typography>
        ),
      },
      pdfFile: {
        value: pdfFileUrl,
        component: (
          <IconButton
            style={{ paddingLeft: 0 }}
            color="primary"
            onClick={() => window.open(pdfFileUrl)}
            disabled={!pdfFileUrl}
          >
            <InsertDriveFile />
          </IconButton>
        ),
        align: 'left',
      },
      riskResults: {
        value: rasFinalResult,
        component: (
          <Button
            onClick={handleRiskVariablesButton}
            disabled={treeFinalResult == null}
          >
            <OpenInNew />
          </Button>
        ),
      },
      treeResults: {
        value: treeFinalResult,
        component: (
          <Button
            onClick={() => handleRiskResultButton('Tree')}
            disabled={treeFinalResult == null}
          >
            <Box>
              {setResultIcon(treeFinalResult, classes)}
            </Box>
          </Button>
        ),
      },
      rasResults: {
        value: rasFinalResult,
        component: (
          <Button
            onClick={() => handleRiskResultButton('RAS')}
            disabled={rasFinalResult == null}
          >
            <Typography>{changeNumberColor(rasFinalResult === null ? 'n/a' : rasFinalResult.toFixed(2), classes)}</Typography>
          </Button>
        ),
      },
      bureausAlert: {
        value: null,
        component: (
          <AlertsMenu
            companyRiskHistory={companyRiskHistory}
            receiverRiskHistory={purchaser.receiverRiskHistory}
            companyReceiverRiskHistory={companyReceiverRiskHistory}
          />
        ),
      },
    });
  });
  if (!company || loading) return <LinearProgress />;
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) return <Typography variant="h2" align="center">Error al cargar las simulaciones</Typography>;
  return (
    <Grid className={classes.container}>
      <Grid className={classes.headerContainer}>
        <Grid container direction="column" alignItems="left">
          <Grid item style={{ display: 'flex', alignItems: 'left' }}>
            <IconButton onClick={handleGoBack}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4"><b>Solicitudes vigentes de {company.name}</b>
              {company.masterEntity.riskBlacklist && (
              <Tooltip title="Blacklist">
                <Block fontSize="large" className={classes.blacklistIcon} />
              </Tooltip>
              )}
              {company.amountDebtInvoices > 0 && (
                <Tooltip title={tooltipHandler(company.amountDebtInvoices, 'debt')}>
                  <Error fontSize="large" className={classes.alertIcon} />
                </Tooltip>
              )}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" wrap="nowrap">
            <Button
              variant="contained"
              style={{ backgroundColor: 'green', fontSize: '0.9rem', lineHeight: '0.9rem' }}
              className={classes.yellowButton}
              onClick={() => handleDownloadExcel(company.rut)}
            >
              {loadingExcel ? (
                <Typography variant="body1">Descargando Excel <CircularProgress size={20} /></Typography>
              ) : (
                <>Excel de ventas</>
              )}
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.yellowButton}
              onClick={() => {
                setModalRestrictions(true);
              }}
            >
              Añadir Restricciones
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedSimulations.length === 0}
              onClick={handleAccept}
              className={classes.riskButton}
            >
              Aprobar Seleccionada
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.redButton}
              disabled={selectedSimulations.length === 0}
              onClick={handleReject}
            >
              Rechazar Seleccionada
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => refetch()}
              endIcon={<Cached />}
              className={classes.riskButton}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ paddingInline: '2rem', marginTop: '1rem' }}>
        <Typography variant="h6">Línea de crédito:</Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h6"
          >
            {company?.masterEntity?.creditLine ? (
              `Disponible actual: $${formatMoney((company?.masterEntity?.creditLine?.limitAmount.amount || 0)
                - (company?.masterEntity?.creditLine?.creditUsed || 0))} | 
              Total: $${formatMoney(company?.masterEntity?.creditLine?.limitAmount.amount || 0)}`
            ) : (
              'Sín línea'
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ paddingInline: '2rem' }}>
        <VisualizeAllDocument rut={company.rut} documentTypes={['CTE', 'TGR', 'EERR', 'BALANCE', 'CONTRACT']} openSnackBar={setOpenSnackBar} />
      </Grid>
      <Grid item style={{ marginTop: '2rem', marginLeft: '2rem' }}>
        <Typography variant="h6">Monto total en evaluación ${formatMoney(simulations.reduce((acc, sim) => acc + sim.totalAmount.amount, 0))}</Typography>
      </Grid>
      <Table
        withSelect
        data={simulations}
        dataToRows={transformDataToComponents}
        setNewDataOrder={setSimulations}
        headers={headers}
        initialOrderId="id"
        handleSelected={handleSelectedSimulations}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => { setOpenSnackBar(false); }}
        message="Documento enviado por Slack al canal #credit_line_requests, brevemente llegará una respuesta"
        disableWindowBlurListener
      />
      {riskVariables.orderId && (
      <VariablesDetail
        open={riskVariables.open}
        onClose={handleCloseRiskVariables}
        objectId={riskVariables.orderId}
        modelName="purchaseorder"
        company={company}
        debtor={riskVariables.purchaser}
        ratification={riskVariables.ratification}
      />
      )}
      <ResultDetail
        open={riskResults.open}
        onClose={handleCloseRiskResult}
        resultType={riskResults.type}
        riskResults={riskResults.data}
        resultHeader={riskResults.header}
      />
      <RestrictionsToAcceptPreOffers
        loading={createRestrictionLoading}
        open={modalRestrictions}
        handleClose={() => { setModalRestrictions(false); }}
        handleConfirm={handleCreateRestrictions}
        handleRestrictions={handleRestrictions}
      />
    </Grid>
  );
};

export default CompanyOrderingSimulations;
