import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
  Menu,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';

const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleMenu: {
    marginBottom: '1rem',
  },
  filterActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: 45,
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
  },
  filterButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper: {
    border: '1px solid #d3d4d5',
    padding: '1rem',
  },
});

const FilterHeader = (props) => {
  const {
    property,
    titleTooltip,
    label,
    dataList, // [{label: '', value: ''}]
    sendFilterData,
    initState,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedDataList, setCheckedDataList] = useState(false);

  useEffect(() => {
    const newCheckedDataList = checkedDataList || dataList.reduce((acc, item) => (
      { ...acc, [item.value]: { ...item, checked: false } }
    ), {});
    setCheckedDataList(newCheckedDataList);
    if (initState.propertyFilter) {
      let objectCheckedInit = newCheckedDataList;
      initState.arrayFilter.forEach((email) => {
        objectCheckedInit = {
          ...objectCheckedInit, [email]: { ...objectCheckedInit[email], checked: true } };
      });
      setCheckedDataList(objectCheckedInit);
    }
  }, [initState]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    const objectCheckedDataList = dataList.reduce((acc, item) => (
      { ...acc, [item.value]: { ...item, checked: true } }
    ), {});
    setCheckedDataList(objectCheckedDataList);
  };

  const handleDeleteAll = () => {
    const objectCheckedDataList = dataList.reduce((acc, item) => (
      { ...acc, [item.value]: { ...item, checked: false } }
    ), {});
    setCheckedDataList(objectCheckedDataList);
  };

  const handleChange = (event) => {
    event.persist();
    setCheckedDataList((prev) => (
      { ...prev,
        [event.target.name]:
          {
            ...prev[event.target.name],
            checked: !prev[event.target.name]?.checked,
          },
      }
    ));
  };

  const handleSendFilterData = () => {
    const values = Object.values(checkedDataList);
    sendFilterData({

      propertyFilter: property,
      arrayFilter: values.filter((item) => item.checked).map((_item) => _item.value),

    });
  };

  return (
    <>
      <Tooltip title={titleTooltip}>
        <IconButton style={{ padding: 0, margin: 0 }} onClick={handleClick}>
          <FilterList />
        </IconButton>
      </Tooltip>
      <Paper>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
        >
          <Typography className={classes.titleMenu}>Filtro {label}</Typography>
          <div className={classes.checkboxContainer}>
            {dataList.map((item) => (
              <FormControlLabel
                key={item.value}
                label={item.label}
                control={(
                  <Checkbox
                    name={item.value}
                    onChange={handleChange}
                    checked={checkedDataList && checkedDataList[item.value]
                      ? checkedDataList[item.value].checked : false}
                  />
                )}
              />
            ))}
          </div>
          <div className={classes.filterActions}>
            <Button size="small" color="primary" className={classes.button} onClick={handleSelectAll}>Seleccionar todo</Button>
            <Button size="small" color="primary" className={classes.button} onClick={handleDeleteAll}>Limpiar</Button>
          </div>
          <div className={classes.filterButtonContainer}>
            <Button style={{ height: 45 }} size="small" color="primary" variant="contained" onClick={handleSendFilterData}>Filtrar</Button>
          </div>
        </Menu>
      </Paper>
    </>
  );
};

FilterHeader.propTypes = {
  property: PropTypes.string.isRequired,
  titleTooltip: PropTypes.string.isRequired,
  dataList: PropTypes.arrayOf(PropTypes.shape(
    {
      value: PropTypes.string,
      label: PropTypes.string,
    },
  )).isRequired,
  label: PropTypes.string.isRequired,
  sendFilterData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  initState: PropTypes.obj,
};

FilterHeader.defaultProps = {
  initState: {},
};
export default FilterHeader;
