import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { useStyles } from './styles';
import PendingNegotiations from '../components/negotiation/PendingNegotiations';

const RatesNegotiation = () => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <>
      { loading && <LinearProgress />}
      <Grid container>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.titleAndUpdate}>
            <Typography variant="h4"><b> Negociaciones pendientes </b></Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '2rem', backgroundColor: 'white' }}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(e, value) => setTab(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Pre ofertas</Typography>} />
              <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Ofertas</Typography>} />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: '0 2rem' }}>
          {tab === 0 && <PendingNegotiations negotiationType="PREOFFER" setLoading={setLoading} />}
          {tab === 1 && <PendingNegotiations negotiationType="OFFER" setLoading={setLoading} />}
        </Grid>
      </Grid>
    </>
  );
};

export default RatesNegotiation;
