import { gql } from '@apollo/client';

export const UPLOAD_ROSTER_FILE = gql`
  mutation UploadRosterFile($file: Upload!, $fundId: Int!, $cessionareId: Int!) {
    uploadRosterFile(file:$file, fundId: $fundId, cessionareId: $cessionareId) {
      roster {
        id
      }
    }
  }
`;

export const DELETE_INVOICE_FROM_ROSTER = gql`
  mutation DeleteInvoiceFromRoster($loanManagerId: [Int]!, $rosterId: Int!) {
    deleteInvoiceFromRoster(loanManagerId: $loanManagerId, rosterId: $rosterId) {
      success
      message
    }
  }
`;

export const ADD_INVOICE_TO_ROSTER = gql`
  mutation AddInvoiceToRoster(
    $documentId: Int!
    $rate: Float!
    $retentionRate: Float!
    $type: String!
    $expirationDate: Date!
    $rosterId: Int!
  ) {
    addInvoiceToRoster(
      documentId: $documentId
      rate: $rate
      retentionRate: $retentionRate
      type: $type
      expirationDate: $expirationDate
      rosterId: $rosterId
    ) {
      success
      message
    }
  }
`;

export const ROSTER_RECESION = gql`
  mutation RosterRecesion($rosterId: Int!) {
    rosterRecesion(rosterId: $rosterId) {
      success
      message
    }
  }
`;

export const GET_FUNDS = gql`
  query GetFunds{
    getFunds{
      id
      rut @client
      socialReason @client
      masterEntity {
        id
        rut
        name
      }
      email
      isAsignee
      isFund
    }
  }
`;

export const GET_ROSTERS = gql`
  query GetRosters {
    getRosters {
      id
      status
      recesionDate
      invoicesCount
      invoicesAmount
      loanedInvoicesCount
      fund {
        id
        masterEntity {
          id
          name
          rut
        }
        rut @client
        socialReason @client
      }
      cessionFund {
        id
        masterEntity {
          id
          name
          rut
        }
        rut @client
        socialReason @client
      }
    }
  }
`;

export const GET_LOAN_MANAGERS = gql`
  query GetLoanManagers($rosterId: Int!) {
    getLoanManagers(rosterId: $rosterId) {
      id
      monthlyRate
      retentionRate
      transferAmount
      financedAmount
      priceDifference
      expirationDate
      document {
        folio
        amountWithIva
        dateIssued
        dateToPay
        type
        company {
          id
          rut
          name
        }
        receiver {
          id
          rut
          name
        }
      }
      assignment {
        id
        status
      }
    }
  }
`;
