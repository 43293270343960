import React, { useEffect, useState } from 'react';
import { Tooltip, Typography, Box, Grid, Button, LinearProgress } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { Cached, FlashOn } from '@material-ui/icons';
import { useStyles } from './styles';
import { Table } from '../components/tables';
import SelectForm from '../components/utils/SelectForm';
import { formatMoney } from '../helpers/money';
import { formatRut } from '../helpers/generic';
import { GET_PENDING_DOCUMENTS_REVIEW_INVOICES, UPDATE_REVIEW_DOCUMENTS_MANAGER } from '../gql/ratification';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import CustomerDocumentsMenu from '../components/customers/DocumentsMenu';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'company', label: 'Cliente', align: 'left' },
  { id: 'receiver', label: 'Deudor', align: 'left' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'amount', label: 'Monto', align: 'left' },
  { id: 'documents', label: 'Documentos', align: 'left' },
  { id: 'ocReviewState', label: 'Revisión OC', align: 'left' },
  { id: 'hesReviewState', label: 'Revisión HES', align: 'left' },
  { id: 'portalScreenshotReviewState', label: 'Revisión Pantallazo Portal', align: 'left' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
  { id: 'action', label: '', align: 'left' },
];
const reviewAnswer = [
  { label: 'Aceptar', value: 'ACCEPTED' },
  { label: 'Rechazar', value: 'REJECTED' }];

const OcAndHesReview = () => {
  const { data, refetch } = useQuery(GET_PENDING_DOCUMENTS_REVIEW_INVOICES);
  const [updateReviewDocumentsManager] = useMutation(UPDATE_REVIEW_DOCUMENTS_MANAGER, {
    onCompleted() {
      window.location.reload();
    },
  });
  const classes = useStyles();
  const [invoices, setInvoices] = useState();
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleRefetch = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };
  useEffect(() => {
    if (data && data.getPendingDocumentsReviewInvoices) {
      setInvoices(data.getPendingDocumentsReviewInvoices);
      const dataToResponse = [];
      data.getPendingDocumentsReviewInvoices.forEach((invoice) => {
        const {
          offer: {
            id: offerId,
            reviewDocumentsManager: {
              ocReviewState,
              hesReviewState,
              portalScreenshotReviewState,
            },
          },
        } = invoice;
        dataToResponse.push({
          offerId,
          ocReviewState,
          hesReviewState,
          portalScreenshotReviewState,
          disabled: true,
        });
      });
      setResponseData(dataToResponse);
    }
  }, [data]);
  const transformDataToComponents = (_data) => _data.map((row) => {
    const handleChange = (event, id) => {
      const { name, value } = event.target;
      const newData = [...responseData];
      const newRow = newData.find((responseRow) => responseRow.offerId === id);
      newRow[name] = value;
      if (newRow.hesReviewState === 'PENDINGREVIEW' || newRow.ocReviewState === 'PENDINGREVIEW' || newRow.portalScreenshotReviewState === 'PENDINGREVIEW') {
        newRow.disabled = true;
      } else {
        newRow.disabled = false;
      }
      setResponseData(newData);
    };
    const handleClick = (event, id) => {
      const updateRow = responseData.find((responseRow) => responseRow.offerId === id);
      if (updateRow) {
        const { offerId, hesReviewState, ocReviewState, portalScreenshotReviewState } = updateRow;
        updateReviewDocumentsManager({
          variables: {
            newStates: [{ offerId, hesReviewState, ocReviewState, portalScreenshotReviewState }],
          },
        });
      }
    };
    const {
      id,
      folio,
      amountWithIva,
      pdf,
      objectPurchaseOrder,
      dateIssued,
      siiStatus,
      creditNoteStatus,
      certificateUrl,
      documents,
      documentfinancestateSet,
      lightningPaymentAuthorized,
      company: {
        rut: companyRut,
        name: companyName,
        executiveAssigned,
      },
      receiver: {
        id: receiverId,
        rut: receiverRut,
        name: receiverName,
      },
      offer: {
        id: offerId,
        reviewDocumentsManager: {
          requiresOc,
          requiresHes,
          requiresPortalScreenshot,
          ocReviewState,
          hesReviewState,
          portalScreenshotReviewState,
        },
      },
    } = row;
    const {
      label: siiLabel,
      color: siiColor,
    } = siiStatusToProps(dateIssued, creditNoteStatus)[siiStatus];
    return ({
      id,
      offerId,
      folio: {
        value: folio,
        component: (
          <div className={classes.alignRight}>
            <Typography>{folio}</Typography>
            {lightningPaymentAuthorized && (
              <Tooltip title="Contabilizadas en Inacap">
                <FlashOn color="primary" />
              </Tooltip>
            )}
          </div>
        ),
      },
      company: {
        value: companyName,
        component: (
          <>
            <Typography>{companyName}</Typography>
            <Typography variant="body2">{formatRut(companyRut)}</Typography>
          </>
        ),
      },
      receiver: {
        value: receiverName,
        component: <DebtorCell debtorId={receiverId} name={receiverName} rut={receiverRut} />,
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      amount: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      documents: {
        component: (
          <CustomerDocumentsMenu
            uploadOption={false}
            purchaseOrder={objectPurchaseOrder}
            pdf={pdf}
            certificate={certificateUrl}
            companyRut={companyRut}
            documents={documents}
          />
        ),
      },
      ocReviewState: {
        value: ocReviewState,
        component: (requiresOc ? (
          <SelectForm
            reasons={reviewAnswer}
            inputText="Selecciona la respuesta"
            name="ocReviewState"
            onChange={(event) => handleChange(event, offerId)}
          />
        ) : (
          <Typography>No se requiere OC</Typography>
        )
        ),
      },
      hesReviewState: {
        value: hesReviewState,
        component: (requiresHes ? (
          <SelectForm
            reasons={reviewAnswer}
            inputText="Selecciona la respuesta"
            name="hesReviewState"
            onChange={(event) => handleChange(event, offerId)}
          />
        ) : (
          <Typography>No se requiere HES</Typography>
        )
        ),
      },
      portalScreenshotReviewState: {
        value: portalScreenshotReviewState,
        component: (requiresPortalScreenshot ? (
          <SelectForm
            reasons={reviewAnswer}
            inputText="Selecciona la respuesta"
            name="portalScreenshotReviewState"
            onChange={(event) => handleChange(event, offerId)}
          />
        ) : (
          <Typography>No se requiere Pantallazo de Portal</Typography>
        )
        ),
      },
      integrationStatus: {
        value: siiLabel,
        component: (
          <>
            <Typography style={{ color: siiColor }}>{siiLabel}</Typography>
            <Tooltip title="Fuente: Portal de provedores" arrow>
              <Grid>
                <Typography style={{ color: siiColor }} variant="caption">
                  {documentfinancestateSet[0]?.status.status}
                </Typography>
                {documentfinancestateSet[0]?.paymentDate
                && (
                <Typography style={{ color: siiColor }} variant="caption">
                  - Fecha de pago: {documentfinancestateSet[0].paymentDate}
                </Typography>
                )}
                {documentfinancestateSet[0]?.rejectionCode
                && (
                <Typography style={{ color: siiColor }} variant="caption">
                  - Razón de rechazo: {documentfinancestateSet[0]?.rejectionCode?.reason}
                </Typography>
                )}
              </Grid>
            </Tooltip>
          </>
        ),
      },
      action: {
        component: (
          <Button
            disabled={responseData.find((responseRow) => responseRow.offerId === offerId).disabled}
            color="primary"
            variant="contained"
            onClick={(event) => handleClick(event, offerId)}
          >
            Enviar
          </Button>
        ),
      },
    });
  });
  if (loading) return <LinearProgress />;

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Revisión de OC y HES</b></Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRefetch}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </Box>
      {(invoices && invoices.length > 0) ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              headers={headers}
              setNewDataOrder={setInvoices}
              data={invoices}
              dataToRows={transformDataToComponents}
              initialOrderId="amount"
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">No hay documentos pendientes.</Typography>
      )}
    </Box>
  );
};
export default OcAndHesReview;
