import { gql } from '@apollo/client';

export default gql`
  query getWaitingForApproval{
    getWaitingForApproval {
      id,
      treasureMessageId,
      folios,
      bank {
        id
        bankName,
        accountRut,
        accountType,
        accountName,
        accountEmail,
        accountNumber
      },
      moneyTransfersId,
      moneyTransferType,
      total,
      client{
        rut,
        name
      }
      integrationStatus
      siiStatus
      creditNoteStatus
    }
  }
`;
