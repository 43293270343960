import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { useStyles } from './styles';
import { GENERATE_TREASURY_MESSAGE, REQUEST_MONEY_TRANSFERS, DELETE_TREASURY_MESSAGE } from '../../gql/moneyTransfer';
import ErrorComponent from '../utils/ErrorComponent';
import AllRestrictionsWarning from '../companyRestrictions/AllRestrictionsWarning';

const AcceptMoneyTransfers = ({ open, onClose, moneyTransfers, clients }) => {
  const classes = useStyles();
  const [generateTreasuryMessage, {
    data: messageData,
    loading: messageLoading,
    error: messageError,
  }] = useMutation(GENERATE_TREASURY_MESSAGE);
  const [requestMoneyTransfers, {
    loading: requestLoading,
    error: requestError,
  }] = useMutation(REQUEST_MONEY_TRANSFERS);
  const [deleteTreasuryMessage] = useMutation(DELETE_TREASURY_MESSAGE);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open) {
      generateTreasuryMessage({
        variables: {
          moneyTransfers,
        },
      });
    }
  }, [open]);

  useEffect(() => {
    if (messageData) {
      setMessage(messageData.generateTreasuryMessage.createdObject);
    }
  }, [messageData]);

  const handleAccept = async () => {
    await requestMoneyTransfers({
      variables: {
        requestedMoneyTransfers: moneyTransfers,
        treasuryMessageId: message.id,
      },
    });
    onClose(true);
  };

  const handleClose = () => {
    deleteTreasuryMessage({
      variables: {
        id: message.id,
      },
    });
    onClose(false);
    setMessage(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">¿Quieres enviar estas facturas a giro, en conjunto con el siguiente mensaje?</DialogTitle>
      <DialogContent style={{ whiteSpace: 'pre' }}>
        <AllRestrictionsWarning
          clients={clients}
        />
        {message && message.message}
        {messageLoading && <LinearProgress />}
        {messageError && <ErrorComponent message="No pudimos generar el mensaje, por favor contáctate con el equipo de desarrollo" />}
        {requestError && <ErrorComponent message="No pudimos solicitar los giros, por favor contáctate con el equipo de desarrollo" />}
      </DialogContent>
      {!messageLoading && !messageError && (
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
            disabled={requestLoading}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAccept}
            className={classes.acceptButton}
            disabled={requestLoading}
            startIcon={requestLoading ? <CircularProgress size={14} /> : null}
          >
            Aceptar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

AcceptMoneyTransfers.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  moneyTransfers: PropTypes.arrayOf(PropTypes.shape({
    moneyTransferId: PropTypes.string,
    moneyTransferType: PropTypes.string,
  })).isRequired,
  clients: PropTypes.arrayOf(PropTypes.shape({
    rut: PropTypes.number,
    socialReason: PropTypes.string,
  })).isRequired,
};

export default AcceptMoneyTransfers;
