import { Typography, Tooltip, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useStyles } from './styles';
import GenericAvailableForTransfer from '../components/moneyTransfers';
import { GET_AVAILABLE_FOR_TRANSFER_ORDERING } from '../gql/ordering';
import { getTimePassedTextFromTime, getTimePassedFromDate } from '../helpers/date';
import { formatMoney } from '../helpers/money';
import { formatRut } from '../helpers/generic';
import BFStatus from '../components/status';

const headers = [
  { id: 'orderNumber', label: 'Número de orden', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'purchaser', label: 'Comprador', align: 'left' },
  { id: 'amount', label: 'Monto de la orden', align: 'right' },
  { id: 'orderingPaymentAmount', label: 'Anticipo', align: 'right' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
  { id: 'BFstatus', label: 'Estado de BF', align: 'right' },
];
const filter = ['purchaseOrder.orderNumber', 'purchaseOrder.purchaser.name', 'purchaseOrder.purchaser.rut', 'purchaseOrder.company.name', 'purchaseOrder.company.rut'];

const AvailableForTransferOrdering = () => {
  const { data, refetch } = useQuery(
    GET_AVAILABLE_FOR_TRANSFER_ORDERING, { notifyOnNetworkStatusChange: true },
  );
  const classes = useStyles();
  const [moneyTransfers, setMoneyTransfes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredMoneyTransfers, setFilteredMoneyTransfers] = useState([]);
  const title = 'Órdenes disponibles para giro';

  useEffect(() => {
    if (data) {
      setMoneyTransfes(data.getAvailableForTransferOrdering);
      console.log('data', data.getAvailableForTransferOrdering);
      setFilteredMoneyTransfers(data.getAvailableForTransferOrdering);
      setSelectedRows([]);
    }
  }, [data]);

  const rejectMessage = (
    <Grid container>
      <Typography style={{ marginBottom: '1rem' }}>
        <b>
          Realizando esta acción se dará por rechazado
          el financiamiento de las siguientes facturas
        </b>
      </Typography>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Cliente</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Númer de orden</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Giro</b></Typography>
      </Grid>
      {selectedRows.map((row) => (
        <Grid container key={row.orderNumber.value}>
          <Grid item xs={4}>
            <Typography variant="body2">{row.client.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">{row.orderNumber.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">${formatMoney(row.orderingPaymentAmount.value)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id: moneyTransferId,
      purchaseOrder: {
        totalAmount,
        orderNumber,
        company: {
          rut: companyRut,
          name: companyName,
          companycompliance,
        },
        purchaser: {
          rut: purchaserRut,
          name: purchaserName,
        },
        offer: {
          orderingPaymentAmount,
        },
      },
      createdAt,
    } = row;
    return ({
      id: moneyTransferId,
      clientRut: companyRut,
      orderNumber: {
        value: orderNumber,
        component: (
          <div className={classes.row}>
            <Typography>{orderNumber}</Typography>
          </div>
        ),
      },
      client: {
        value: companyName,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={companyName}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {companyName}
              </Typography>
              <Typography>
                {formatRut(companyRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      purchaser: {
        value: purchaserName,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={purchaserName}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {purchaserName}
              </Typography>
              <Typography>
                {formatRut(purchaserRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      amount: {
        value: totalAmount,
        component: (
          <Typography align="left">${formatMoney(totalAmount.amount)}</Typography>
        ),
      },
      orderingPaymentAmount: {
        value: orderingPaymentAmount,
        component: (
          <Typography align="left">${formatMoney(orderingPaymentAmount.amount)}</Typography>
        ),
      },
      timeInStage: {
        value: getTimePassedFromDate(createdAt),
        component: (
          <Typography align="left">{getTimePassedTextFromTime(getTimePassedFromDate(createdAt))}</Typography>
        ),
      },
      BFstatus: {
        component: (
          <div className={classes.center}>
            <BFStatus compliance={companycompliance} />
          </div>
        ),
      },
    });
  });
  return (
    <GenericAvailableForTransfer
      title={title}
      refetch={refetch}
      headers={headers}
      transformDataToComponents={transformDataToComponents}
      setFilteredMoneyTransfers={setFilteredMoneyTransfers}
      filteredMoneyTransfers={filteredMoneyTransfers}
      moneyTransfers={moneyTransfers}
      rejectMessage={rejectMessage}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      filter={filter}
    />
  );
};
export default AvailableForTransferOrdering;
