import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import RiskDetails from './RiskDetails';

const styles = makeStyles(() => ({
  bigContainer: {
    padding: '2%',
    marginTop: '4rem',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '5rem',
  },
}));
const headers = [
  { id: 'clientName', label: 'Nombre Cliente', align: 'left' },
  { id: 'debtorName', label: 'Nombre Deudor', align: 'left' },
  { id: 'evaluationStatus', label: 'Decisión Rocío', align: 'left' },
  { id: 'clientTreeResult', label: 'Árbol Cliente', align: 'left' },
  { id: 'debtorTreeResult', label: 'Árbol Deudor', align: 'left' },
  { id: 'clientDebtorTreeResult', label: 'Árbol Relación', align: 'left' },
  { id: 'clientRasResult', label: 'RAS Cliente', align: 'left' },
  { id: 'debtorRasResult', label: 'RAS Deudor', align: 'left' },
  { id: 'clientDebtorRasResult', label: 'RAS Relación', align: 'left' },
  { id: 'treeResult', label: 'Resultado Árbol', align: 'left' },
  { id: 'rasResult', label: 'Resultado RAS', align: 'left' },
  { id: 'goToDetails', label: '', align: 'left' },
];

const TableResults = ({ data, loading }) => {
  const classes = styles();
  const [dataLoading, setDataLoading] = useState(true);
  const [dataContent, setDataContent] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  useEffect(() => {
    if (loading) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
    if (data) {
      setDataContent([...data]);
    } else {
      setDataContent(undefined);
    }
  }, [loading, data]);
  const dataToRows = (_data) => _data.map((row) => {
    const {
      id,
      client: {
        name: clientName,
      },
      debtor: {
        name: debtorName,
      },
      invoice: {
        preoffer: {
          preofferevaluationrequest: {
            status: evaluationStatus,
          },
        },
      },
      clientTreeResult,
      debtorTreeResult,
      clientDebtorTreeResult,
      clientRasResult,
      debtorRasResult,
      clientDebtorRasResult,
      treeResult,
      rasResult,
    } = row;
    return ({
      id,
      clientName: {
        value: clientName,
        component: (
          <Typography>{clientName}</Typography>
        ),
      },
      debtorName: {
        value: debtorName,
        component: (
          <Typography>{debtorName}</Typography>
        ),
      },
      evaluationStatus: {
        value: evaluationStatus,
        component: (
          <Typography>{evaluationStatus}</Typography>
        ),
      },
      clientTreeResult: {
        value: clientTreeResult,
        component: (
          <Typography>{clientTreeResult ? 'Aprobado' : 'Rechazado'}</Typography>
        ),
      },
      debtorTreeResult: {
        value: debtorTreeResult,
        component: (
          <Typography>{debtorTreeResult ? 'Aprobado' : 'Rechazado'}</Typography>
        ),
      },
      clientDebtorTreeResult: {
        value: clientDebtorTreeResult,
        component: (
          <Typography>{clientDebtorTreeResult ? 'Aprobado' : 'Rechazado'}</Typography>
        ),
      },
      clientRasResult: {
        value: clientRasResult,
        component: (
          <Typography>{clientRasResult}</Typography>
        ),
      },
      debtorRasResult: {
        value: debtorRasResult,
        component: (
          <Typography>{debtorRasResult}</Typography>
        ),
      },
      clientDebtorRasResult: {
        value: clientDebtorRasResult,
        component: (
          <Typography>{clientDebtorRasResult}</Typography>
        ),
      },
      treeResult: {
        value: treeResult,
        component: (
          <Typography>{treeResult ? 'Aprobado' : 'Rechazado'}</Typography>
        ),
      },
      rasResult: {
        value: rasResult,
        component: (
          <Typography>{rasResult.toFixed(2)}</Typography>
        ),
      },
      goToDetails: {
        value: id,
        component: (
          <Button
            color="primary"
            onClick={() => {
              setSelectedRow(row);
              setDialogOpen(true);
            }}
          >
            Ver detalles
          </Button>
        ),
      },
    });
  });
  if (dataLoading || !dataContent) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.bigContainer}>
      <RiskDetails open={dialogOpen} onClose={() => setDialogOpen(false)} row={selectedRow} />
      <Typography variant="h2">Principales resultados</Typography>
      <Table
        headers={headers}
        initialOrderId="id"
        data={dataContent}
        dataToRows={dataToRows}
        setNewDataOrder={setDataContent}
      />
    </div>
  );
};

export default TableResults;

TableResults.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
};

TableResults.defaultProps = {
  data: undefined,
};
