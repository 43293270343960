import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,

} from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_RESTRICTIONS_UNCREATED } from '../../gql/customer';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
});
const RestrictionsToAcceptPreOffers = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  handleRestrictions,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({ items: [] });
  const [otherField, setOtherField] = useState(false);
  const [otherRestriction, setOtherRestriction] = useState('Otro');
  const [confirmButton, setConfirmButton] = useState(true);
  const location = useLocation();
  // only the restrictions uncreated or pending can be created
  const [getRestrictionsUncreated, { data: uncreatedRestrictions,
    // refetch,
    loading: loadingRestrictions,
    error:
    errorRestrictions }] = useLazyQuery(GET_RESTRICTIONS_UNCREATED,
    { variables: {
      companyId: location.pathname.split('/').pop(),
    },
    fetchPolicy: 'network-only',
    });
  useEffect(() => { getRestrictionsUncreated(); }, [open]);
  useEffect(() => {
    // create the restriction objects with the uncreated restrictions of the query
    // only uncreated restrictions  are displayed in the checkbox

    const newObjectRestrictions = [];
    let restrictionsLabel = false;

    if (uncreatedRestrictions) {
      restrictionsLabel = uncreatedRestrictions.getCompanyRestrictionsUncreated;
    }

    if (restrictionsLabel) {
      restrictionsLabel.forEach((res, index) => {
        if (res.value === 'OTHER') {
          newObjectRestrictions.push({ label: res.label, name: res.value, checked: false, value: '', index, disabled: false }); // the field 'Otro' has its own value
        } else {
          newObjectRestrictions.push({ label: res.label,
            name: res.value,
            checked: false,
            index,
            disabled: false });
        }
      });
      setState({ items: newObjectRestrictions });
    }
  }, [uncreatedRestrictions]);

  useEffect(() => {
    const newState = [...state.items];
    const completeObject = { restrictions: newState };
    handleRestrictions(completeObject); // to the parent component
    // it must be selected at least one check box
    const atLeastOneChecked = newState.reduce((res1, res2) => (res1
      || res2.checked), false);
    if (atLeastOneChecked) {
      const other = newState.find((obj) => obj.name === 'OTHER');
      if (other && other.checked && !otherRestriction) {
        setConfirmButton(true);
      } else {
        setConfirmButton(false);
      }
    } else {
      setConfirmButton(true);
    }
  }, [state]);

  const handleOther = (event) => {
    setOtherRestriction(event.target.value);
    const items = [...state.items];
    const other = items.find((obj) => obj.name === 'OTHER');
    const item = {
      ...other,
      value: event.target.value,
    };
    items[other.index] = item;
    setState({ items });
  };

  const handleChange = (event, index) => {
    if (event.target.name === 'OTHER') {
      if (event.target.checked) {
        setOtherField(true);
      } else {
        setOtherField(false);
      }
    }
    const items = [...state.items];
    const item = {
      ...items[index],
      checked: event.target.checked,
    };
    items[index] = item;
    setState({ items });
  };

  const handleConfirmModal = async () => {
    handleConfirm(); // mutation to create the restrictions
    // refetch(); // async refetch
    // setState({ items: [] }); // delete state checkbox
    handleClose(); // close the modal
  };

  if (uncreatedRestrictions && uncreatedRestrictions.getCompanyRestrictionsUncreated.length === 0) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title"><b>No puedes añadir más restricciones, debes resolver las que tienes actualmente.</b>
        </DialogTitle>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>¿Qué restricciones quieres añadir?</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <Grid item xs={12}>
            {loadingRestrictions && (<Typography>Cargando Restricciones</Typography>)}
            {errorRestrictions && (
            <Typography>
              Hubo un error al cargar las restricciones. Intenta de nuevo
            </Typography>
            )}
            {uncreatedRestrictions && (
            <FormGroup style={{ marginTop: 20 }}>
              { state.items.map(({ name, checked, disabled, label }, index) => (
                <FormControlLabel
                  key={name}
                  control={(
                    <Checkbox
                      checked={checked}
                      onChange={(event) => { handleChange(event, index); }}
                      name={name}
                      disabled={disabled}
                    />
)}
                  label={label}
                />
              ))}
              {otherField && (
              <TextField
                key="otherField"
                value={otherRestriction}
                name="otherField"
                variant="outlined"
                multiline
                rowsMax={2}
                onChange={handleOther}
                label="Otro"
                size="small"
              />
              )}
            </FormGroup>
            )}

          </Grid>

        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={loading || confirmButton} onClick={handleConfirmModal} color="primary" autoFocus>
          Confirmar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RestrictionsToAcceptPreOffers.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  handleRestrictions: PropTypes.func.isRequired,
};

RestrictionsToAcceptPreOffers.defaultProps = {
  loading: false,
};

export default RestrictionsToAcceptPreOffers;
