import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import { Tooltip, Typography } from '@material-ui/core';

const OrderingAssignationTooltip = ({ orderNumbers }) => (
  <>
    {orderNumbers.length ? (
      <Tooltip
        title={(
          <>
            <Typography color="inherit">
              Se pagan las OCs:
              <strong> {orderNumbers.join(', ')}</strong>
            </Typography>
          </>
        )}
        arrow
      >
        <CheckCircleOutlineIcon />
      </Tooltip>
    ) : <RemoveIcon />}
  </>
);

OrderingAssignationTooltip.propTypes = {
  orderNumbers: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default OrderingAssignationTooltip;
