import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Lock,
  AccountBalance,
  ListAlt,
  Assignment,
  TrendingUp,
  InsertDriveFile,
  Description,
  Create,
  CardMembership,
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '1rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
}));
const IconFilters = ({ handleIconFilters }) => {
  const classes = useStyles();
  const [activeFilters, setActiveFilters] = useState({
    contract: { activeFilter: false, propertyFilter: 'contract' },
    balance: { activeFilter: false, propertyFilter: 'balance' },
    eerr: { activeFilter: false, propertyFilter: 'eerr' },
    equifax: { activeFilter: false, propertyFilter: 'equifax' },
    tgr: { activeFilter: false, propertyFilter: 'tgr' },
    cte: { activeFilter: false, propertyFilter: 'cte' },
    siiCredentials: { activeFilter: false, propertyFilter: 'siiCredentials' },
    acceptedTerms: { activeFilter: false, propertyFilter: 'acceptedTerms' },
    certificate: { activeFilter: false, propertyFilter: 'certificate' },
  });

  const {
    contract,
    balance,
    eerr,
    equifax,
    tgr,
    cte,
    siiCredentials,
    acceptedTerms,
    certificate,
  } = activeFilters;
  const handleChange = (name) => {
    setActiveFilters((prev) => (
      { ...prev,
        [name]: { ...prev[name], activeFilter: !prev[name].activeFilter },
      }));
  };

  useEffect(() => {
    handleIconFilters(Object.values(activeFilters));
  }, [activeFilters]);

  return (
    <div className={classes.container}>
      <Tooltip title="Términos y condiciones">
        <IconButton onClick={() => handleChange('acceptedTerms')}>
          <Create color={acceptedTerms.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Contrato">
        <IconButton onClick={() => handleChange('contract')}>
          <Assignment color={contract.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Balance">
        <IconButton onClick={() => handleChange('balance')}>
          <AccountBalance color={balance.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="EERR">
        <IconButton onClick={() => handleChange('eerr')}>
          <TrendingUp color={eerr.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Equifax">
        <IconButton onClick={() => handleChange('equifax')}>
          <ListAlt color={equifax.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="TGR">
        <IconButton onClick={() => handleChange('tgr')}>
          <InsertDriveFile color={tgr.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="CTE">
        <IconButton onClick={() => handleChange('cte')}>
          <Description color={cte.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Certificado digital">
        <IconButton onClick={() => handleChange('certificate')}>
          <CardMembership color={certificate.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Clave de SII">
        <IconButton onClick={() => handleChange('siiCredentials')}>
          <Lock color={siiCredentials.activeFilter ? 'primary' : 'default'} className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

IconFilters.propTypes = {
  handleIconFilters: PropTypes.func.isRequired,
};

export default IconFilters;
