/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

// ! Creo que no se usa
export const GET_COMPANY_DETAILED_SCORING = gql`
  query GetCompanyDetailedScoring($rut: String!) {
    getCompanyDetailScoring(rut: $rut) {
      directDebt {
        current 
        consumption 
        comercial 
        mortgage 
        overdue 
        punished 
        badDebt 
      }
      indirectDebt {
        current 
        overdue 
        punished 
      }
      leasing {
        current 
        badDebt 
      }
      size {
        sales 
        salesLeg 
      }
      billing {
        clientRut 
        month 
        monthlyAmount
        prevYear 
        varPercentagePrevYear 
        prevMonth 
        varPercentagePrevMonth 
        enterprise
      }
      stability {
        startYear 
        properties 
        mortgageDebt 
        netProperties 
      }
      traceability {
        website
        address 
      }
    }
  }
`;
