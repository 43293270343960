import { useLazyQuery } from '@apollo/client';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { InsertDriveFile, Cancel } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GET_COMPANY_FRAMEWORK_CONTRACT } from '../../gql/customer';

const CustomerDocumentsMenu = ({
  uploadOption,
  onUploadClick,
  purchaseOrder,
  pdf,
  certificate,
  companyRut,
  documents,
}) => {
  const [getFrameworkContract, { data }] = useLazyQuery(
    GET_COMPANY_FRAMEWORK_CONTRACT, { variables: { rut: companyRut } },
  );
  const [anchorEl, setAnchorEl] = useState();
  const openMenu = Boolean(anchorEl);
  const openUrl = (url) => {
    window.open(url);
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (openMenu) {
      getFrameworkContract();
    }
  }, [anchorEl]);
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenMenu}
      >
        <Typography variant="body2">Documentos</Typography>
      </Button>
      <Menu
        onClose={() => setAnchorEl(null)}
        open={openMenu}
        anchorEl={anchorEl}
      >
        {uploadOption && (
        <MenuItem
          onClick={() => onUploadClick()}
        >
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Subir Documento
          </ListItemText>
        </MenuItem>
        )}
        <MenuItem
          onClick={() => openUrl(purchaseOrder.url)}
          disabled={!(purchaseOrder && purchaseOrder.exists)}
        >
          <ListItemIcon>
            {purchaseOrder && purchaseOrder.exists
              ? (
                <InsertDriveFile fontSize="small" />
              ) : (
                <Cancel color="error" fontSize="small" />
              )}
          </ListItemIcon>
          <ListItemText>
            Orden de compra
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => openUrl(data.getCompanyFrameworkContract)}
          disabled={!(data && data.getCompanyFrameworkContract)}
        >
          <ListItemIcon>
            {data && data.getCompanyFrameworkContract
              ? (
                <InsertDriveFile fontSize="small" />
              ) : (
                <Cancel color="error" fontSize="small" />
              )}
          </ListItemIcon>
          <ListItemText>
            Contrato
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => openUrl(pdf)}
          disabled={!pdf}
        >
          <ListItemIcon>
            {pdf
              ? (
                <InsertDriveFile fontSize="small" />
              ) : (
                <Cancel color="error" fontSize="small" />
              )}
          </ListItemIcon>
          <ListItemText>
            PDF
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => openUrl(certificate)}
          disabled={!certificate}
        >
          <ListItemIcon>
            {certificate
              ? (
                <InsertDriveFile fontSize="small" />
              ) : (
                <Cancel color="error" fontSize="small" />
              )}
          </ListItemIcon>
          <ListItemText>
            Certificado
          </ListItemText>
        </MenuItem>
        {documents.map((document) => {
          const {
            id,
            url,
          } = document;
          return (
            <div key={id}>
              <Divider />
              <MenuItem onClick={() => openUrl(url)}>
                <ListItemIcon>
                  <InsertDriveFile fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {document?.type?.name}
                </ListItemText>
              </MenuItem>
            </div>
          );
        })}
      </Menu>
    </>
  );
};

CustomerDocumentsMenu.defaultProps = {
  onUploadClick: () => {},
};

CustomerDocumentsMenu.propTypes = {
  purchaseOrder: PropTypes.shape({
    exists: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired,
  pdf: PropTypes.string.isRequired,
  certificate: PropTypes.string.isRequired,
  uploadOption: PropTypes.bool.isRequired,
  onUploadClick: PropTypes.func,
  companyRut: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf({
    documentType: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default CustomerDocumentsMenu;
