import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import CollectionAction from '../CollectionAction';

const useStyles = makeStyles(() => ({
  baseContainer: {
    width: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&&::-webkit-scrollbar': { /* Chrome, Safari, Opera */
      display: 'none',
    },
    scrollbarWidth: 'none', /* Firefox */
    msOverflowStyle: 'none', /* IE */
  },
}));

const ActionsDrawerActionInfo = (props) => {
  const {
    actionsClass,
    typographyVariant,
    typographyGutterBottom,
    typographyStyle,
    actions,
  } = props;
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.baseContainer, actionsClass)}>
      <Typography
        variant={typographyVariant}
        gutterBottom={typographyGutterBottom}
        style={typographyStyle}
      >
        Gestiones
      </Typography>
      {actions.length > 0
        ? actions.map((action) => <CollectionAction key={action.id} action={action} />)
        : <Typography>Aún no se realizan gestiones para esta factura</Typography>}
    </ListItem>
  );
};

ActionsDrawerActionInfo.propTypes = {
  actionsClass: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  typographyVariant: PropTypes.string.isRequired,
  typographyGutterBottom: PropTypes.bool.isRequired,
  typographyStyle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  actions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ActionsDrawerActionInfo;
