import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { formatRut } from '../../../helpers/generic';

const RutColumn = ({ rut }) => (
  <Typography align="right" style={{ minWidth: 100 }}>
    {formatRut(rut)}
  </Typography>
);

RutColumn.propTypes = {
  rut: PropTypes.number.isRequired,
};

export default RutColumn;
