import React from 'react';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConfirmHiding = ({ open, setOpen, hidingId, handleHide }) => (
  <Dialog open={open}>
    <DialogTitle>
      <Typography variant="h5">
        Confirmar Solicitud
      </Typography>
    </DialogTitle>
    <DialogContent dividers>
      ¿Está segura/o que quiere solicitar esconder este movimiento bancario?
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={() => handleHide(hidingId)}
      >
        Enviar Solicitud
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpen(false)}
      >
        Cancelar
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmHiding.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  hidingId: PropTypes.number.isRequired,
  handleHide: PropTypes.func.isRequired,
};

export default ConfirmHiding;
