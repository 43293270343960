import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Chip, InputBase, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { getFormFieldError } from '../../helpers/formValidators';

const useStyles = makeStyles({
  customForm: {
    '&&:focus': {
      outlineColor: '#FF2B68',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '35vh',
    placeItems: 'flex-start',
    width: '100%',
    marginBottom: '2rem',
    overflowY: 'auto',
    '&&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    cursor: 'text',
  },
  hover: {
    '&:hover': {
      border: 'solid 1px #5A5A5A',
    },
  },
}, { name: 'MuiPaper' });

const CustomInputField = ({
  type,
  newData,
  sendStateToParent,
  /* controlRender, */ // to control the re render component from the parent component
}) => {
  /* This components uses getFormFieldError, and receive the same type of that function,
  for example,
  if we want to render chips of 'ruts' the type will be 'rut',
  and the getFormFieldError will validate the correct format of the 'rut'. */

  const inputBase = useRef();
  const refPaper = useRef();
  // const focus = true;
  const theme = useTheme();
  const [stateType, setStateType] = useState('');
  const [focus, setFocus] = useState(false);
  const useStylesMuiPaper = makeStyles({
    customForm: {
      border: focus ? `2px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.12)' },
  }, { name: 'MuiPaper' });
  const classes = { ...useStyles(), ...useStylesMuiPaper() };
  const [arrayStateType, setArrayStateType] = useState([]);
  const [objectError, setObjectError] = useState({ isError: false, message: '' });

  /*  useEffect(() => {
    // the parent component triggers this rerender to set the own state in this component
    setArrayStateType([]);
    setStateType('');
  }, [controlRender]); */

  const handleClick = (_event) => {
    if (refPaper.current && !refPaper.current.contains(_event.target)) {
      setFocus(false);
    } else {
      setFocus(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return (() => {
      document.removeEventListener('click', handleClick);
    });
  });

  useEffect(() => {
    sendStateToParent(arrayStateType);
  }, [arrayStateType]);

  useEffect(() => {
    setArrayStateType((prev) => [...new Set([...new Set(prev), ...newData])]);
  }, [newData]);

  const handleEvent = (_event) => {
    if (_event.target.value === '') {
      setObjectError({ isError: false, message: '' });
    }
    setStateType(_event.target.value);
  };

  const handleKeyPress = (_event) => {
    _event.persist();
    if (_event.key === 'Enter' || _event.key === ' ') {
      const newObjectError = getFormFieldError(stateType, type);
      setObjectError(newObjectError);

      if (!arrayStateType.includes(stateType) && !newObjectError.isError) {
        setArrayStateType((prev) => [...prev, stateType]);
        setStateType('');
      } else if (arrayStateType.includes(stateType) && !newObjectError.isError) {
        setObjectError({ isError: true, message: `El ${type} ${stateType} está repetido` });
      }
      _event.preventDefault();
    }

    // No Error (Strict Mode not enforced globally)
  };
  const handleKeyDown = (_event) => {
    _event.persist();
    if (stateType === '' && _event.key === 'Backspace') {
      const newStateTypes = [...arrayStateType];
      newStateTypes.pop();
      setArrayStateType(newStateTypes);
    }
  };

  const handleDelete = (chipLabel) => {
    const copyArray = [...arrayStateType].filter((mail) => mail !== chipLabel);
    setArrayStateType(copyArray);
  };

  const handleClickPaper = (_event) => {
    // Focus the inputBase inside the Paper Component
    _event.persist();
    inputBase.current.focus();
  };

  return (
    <>
      <Typography variant="caption">Añadidos { arrayStateType.length} {type}s</Typography>
      {objectError.isError && <Typography color="error">{objectError.message}</Typography>}
      <Paper
        id="paper-input"
        component="form"
        variant="outlined"
        className={clsx({ [classes.customForm]: true, [classes.hover]: !focus })}
        onClick={handleClickPaper}
        ref={refPaper}
        // eslint-disable-next-line
        // this maybe helpful
        /* style={{ border: (props) =>
          { console.log('PROP', props); return (focus ? 'solid 2px #FF2B68' : props); } }} */
      >
        {arrayStateType.map((mail) => (
          <Chip
            key={`chip-key-${mail}`}
            onDelete={() => { handleDelete(mail); }}
            label={mail}
            name={mail}
            style={{ marginLeft: '0.5rem', marginRight: '0.5rem', marginTop: '0.5rem' }}
          />
        ))}
        <InputBase
          value={stateType}
          onChange={handleEvent}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          inputProps={{ ref: inputBase }}
          style={{ marginLeft: '0.5rem', marginTop: '0.5rem', width: '15rem', padding: 0 }}
        />
      </Paper>
    </>
  );
};

CustomInputField.propTypes = {
  type: PropTypes.string.isRequired,
  newData: PropTypes.arrayOf(PropTypes.string),
  sendStateToParent: PropTypes.func.isRequired,
  // controlRender: PropTypes.bool.isRequired,
};
CustomInputField.defaultProps = {
  newData: [],
};

export default CustomInputField;
