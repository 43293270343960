import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { GET_RATIFICATION_ACTIONS } from '../../gql/ratification';
import ErrorComponent from '../utils/ErrorComponent';
import RatificationAction from './RatificationAction';

const RatificationHistory = ({ invoiceId, actions, generalActions }) => {
  const [actionsData, setActionsData] = useState(actions);
  const { loading, error } = useQuery(GET_RATIFICATION_ACTIONS, {
    variables: { invoiceId },
    skip: !(actions.length === 0 && !generalActions && invoiceId),
    onCompleted: (data) => {
      setActionsData(data.getRatificationActions);
    },
  });

  if (loading) return <LinearProgress />;

  if (error) return <ErrorComponent message="No pudimos cargar el historial de gestiones de ratificación" />;

  return (
    <div style={{ overflowY: 'scroll', height: '100vh' }}>
      <Typography
        variant="h5"
        gutterBottom
        style={{ marginTop: '1rem', marginLeft: '1rem' }}
      >
        Historial de ratificación
      </Typography>
      <List>
        {actionsData.length > 0
          ? actionsData.map((action) => (
            <ListItem key={action.createdAt} style={{ width: '100%' }}>
              <RatificationAction action={action} />
            </ListItem>
          ))
          : (
            <Typography style={{ marginLeft: '1rem' }}>
              No se realizaron acciones de ratificación para esta factura
            </Typography>
          )}
      </List>
    </div>
  );
};

RatificationHistory.propTypes = {
  invoiceId: PropTypes.string,
  generalActions: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    actionType: PropTypes.string.isRequired,
    comment: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    ratificationContacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.lastName,
    })),
  })),
};

RatificationHistory.defaultProps = {
  actions: [],
  generalActions: false,
  invoiceId: null,
};

export default RatificationHistory;
