import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMoney } from '../../helpers/money';

const SendContractDialog = ({
  order,
  openDialog,
  loading,
  handleClose,
  handleChange,
  handleClick,
  representativeInputs,
}) => {
  const {
    name,
    rut,
    email,
  } = representativeInputs;

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>
        <Typography align="center" variant="h5">Enviar documento de cesión (*)</Typography>
      </DialogTitle>
      <DialogContent>
        { order && order.orderingoffer && (
          <Grid container direction="column">
            <Grid item>
              <Typography style={{ marginBottom: 10 }}>
                <b>Número de orden:</b> {order.orderNumber}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Monto total OC:</b> ${formatMoney(order.orderingoffer.orderingAmount)}
              </Typography>
            </Grid>
            <Divider />
            <Grid item>
              <Typography variant="subtitle1" align="center" style={{ marginBottom: 10, marginTop: 10 }} color="primary">
                Datos Ordering
              </Typography>
              <Typography style={{ marginBottom: 10, marginTop: 10 }}>
                <b>
                  Monto a financiar ({100 - order.orderingoffer.orderingRetentionRate}% del monto):
                </b> ${formatMoney(order.orderingoffer.orderingFinancedAmount.toFixed(2))}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Tasa mensual ordering:</b> {order.orderingoffer.orderingMonthlyRate.toFixed(2)}%
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Comisión ordering:</b> ${formatMoney(order.orderingoffer.orderingCommission)}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Tasa mora:</b> {order.orderingoffer.factoringDefaultRate.toFixed(2)}%
              </Typography>
            </Grid>
            <Divider />
            <Grid item>
              <Typography variant="subtitle1" align="center" style={{ marginBottom: 10, marginTop: 10 }} color="primary">
                Datos Factoring
              </Typography>
              <Typography style={{ marginBottom: 10, marginTop: 10 }}>
                <b>
                  Tasa mensual factoring:
                </b> {
                  order.orderingoffer.factoringMonthlyRate.toFixed(2)
                }%
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Comisión factoring:</b> ${formatMoney(order.orderingoffer.factoringCommission)}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Tasa mora:</b> {order.orderingoffer.factoringDefaultRate.toFixed(2)}%
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Fecha emisión factura:</b> {moment(order.orderingoffer.invoiceIssuedDate).format('DD/MM/YYYY')}
              </Typography>
              <Typography style={{ marginBottom: 10 }}>
                <b>Fecha pago facturra:</b> {moment(order.orderingoffer.invoiceDateToPay).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        )}
        <Grid container direction="column">
          <br />
          <Typography variant="body2" style={{ marginLeft: '10', color: 'darkgray' }}>(**) Rut sin puntos, ni guión, ni dígito verificador.</Typography>
          <Grid container direction="row">
            <Grid item xs={6}>
              <TextField
                id="name"
                variant="outlined"
                type="text"
                name="name"
                label="Nombre representante"
                value={name.value}
                style={{ margin: 10, marginInline: 'auto', minWidth: '100%' }}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 20,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'darkgrey',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="rut"
                variant="outlined"
                type="number"
                name="rut"
                label="Rut representante (**)"
                value={rut.value}
                style={{ margin: 10, marginInline: 'auto', minWidth: '100%' }}
                onChange={handleChange}
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 20,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: 'darkgrey',
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              variant="outlined"
              type="text"
              name="email"
              label="Email destinatario"
              value={email.value}
              style={{ margin: 10, marginInline: 'auto', minWidth: '100%' }}
              onChange={handleChange}
              InputProps={{
                style: {
                  height: 50,
                  borderRadius: 20,
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'darkgrey',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { loading && (
          <>
            <Typography color="primary" variant="caption">Espera un momento...</Typography>
            <CircularProgress />
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={loading || !name.value || !rut.value || !email.value}
        >
          Enviar contrato
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SendContractDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.objectOf(PropTypes.object).isRequired,
  openDialog: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  representativeInputs: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SendContractDialog;
