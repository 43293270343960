import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  container: {
    padding: '3% 5%',
    height: '15rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LoadingComponent = () => {
  const classes = styles();
  return (
    <Grid sm={12} lg={12} md={12} className={classes.container}>
      <CircularProgress size="8rem" />
    </Grid>
  );
};

export default LoadingComponent;
