import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Select, MenuItem, Typography } from '@material-ui/core';
import PropTypes, { string } from 'prop-types';
import { VARIABLE_OPTIONS, UPDATE_VARIABLE, ADD_VARIABLE } from '../../gql/riskAlgorithm';

const AddVariable = ({ variable, open, onClose, variableType, onSuccessSubmit }) => {
  const [addVar] = useMutation(ADD_VARIABLE);
  const [updateVar] = useMutation(UPDATE_VARIABLE);
  const { data, loading } = useQuery(VARIABLE_OPTIONS);
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [input, setInput] = useState(
    {
      name: '',
      functionName: '',
      variableOrder: '',
      threshold: '',
      logicalType: '',
      args: [''],
    },
  );
  const handleSubmit = async () => {
    const newVariable = {
      name: input.name,
      functionName: input.functionName,
      logicalType: input.logicalType,
      args: input.args,
      threshold: input.threshold,
      variableType,
    };
    try {
      if (isUpdate) {
        await updateVar({ variables: { Variable: newVariable, idVariable: variable.id } });
      } else {
        await addVar({ variables: { Variable: newVariable } });
      }
      onSuccessSubmit();
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };
  useEffect(() => {
    if (variable) {
      setInput({
        name: variable.name,
        functionName: variable.functionName,
        variableOrder: variable.variableOrder,
        threshold: variable.threshold,
        logicalType: variable.logicalType,
        args: variable.args,
      });
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [variable]);
  const handleChange = (e, variableName) => {
    const { value } = e.target;
    setInput({ ...input, [variableName]: value });
  };
  const handleArgChange = (e, index) => {
    const { value } = e.target;
    const newArgs = [...input.args];
    newArgs[index] = value;
    setInput({ ...input, args: [...newArgs] });
  };
  const addArg = () => {
    const newArgs = [...input.args, ''];
    setInput({ ...input, args: [...newArgs] });
  };
  const deleteArg = (index) => {
    const newArgs = [...input.args];
    newArgs.splice(index, 1);
    setInput({ ...input, args: [...newArgs] });
  };
  if (loading) return null;
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle id="updatingVariable">{isUpdate ? 'Actualizar' : 'Nueva'} Variable</DialogTitle>
      <DialogContent>
        <Typography>Nombre</Typography>
        <TextField onChange={(e) => handleChange(e, 'name')} value={input.name} />
        <Typography>Función</Typography>
        <Select value={input.functionName} onChange={(e) => handleChange(e, 'functionName')}>
          {data.operations.map(
            (op) => <MenuItem key={op.value} value={op.value}>{op.name}</MenuItem>,
          )}
        </Select>
        <Typography>Orden de la variable</Typography>
        <TextField onChange={(e) => handleChange(e, 'variableOrder')} value={input.variableOrder} />
        <Typography>Límite</Typography>
        <TextField onChange={(e) => handleChange(e, 'threshold')} value={input.threshold} />
        <Typography>Tipo lógico</Typography>
        <Select value={input.logicalType} onChange={(e) => handleChange(e, 'logicalType')}>
          {data.logicalOperations.map(
            (op) => <MenuItem key={op.value} value={op.value}>{op.name}</MenuItem>,
          )}
        </Select>
        <Typography>Arguments</Typography>
        {input.args.map((arg, index) => (
          <div key={arg}>
            <Select value={input.args[index]} onChange={(e) => handleArgChange(e, index)}>
              {data.arguments.map(
                (modelArg) => <MenuItem key={modelArg} value={modelArg}>{modelArg}</MenuItem>,
              )}
            </Select>
            <Button onClick={deleteArg}>Eliminar arg</Button>
          </div>
        ))}
        <Button color="primary" onClick={addArg}>Agregar otro argumento</Button>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="caption">{errorMessage}</Typography>
        <Button color="primary" onClick={handleSubmit}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
};

AddVariable.propTypes = {
  variable: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    functionName: PropTypes.string,
    variableOrder: PropTypes.number,
    threshold: PropTypes.number,
    logicalType: PropTypes.string,
    args: PropTypes.arrayOf(string),
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variableType: PropTypes.string.isRequired,
  onSuccessSubmit: PropTypes.func,
};

AddVariable.defaultProps = {
  variable: undefined,
  onSuccessSubmit: () => {},
};

export default AddVariable;
