import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { RESOLVE_COMPANY_RESTRICTIONS } from '../../gql/customer';
import ResolveRestriction from '../dialogs/ResolveRestriction';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '0rem 2rem 0rem 0rem',
    width: '50vh',

  },
  input: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ABABAB',
  },
  uploadStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },

}));
const VisualizeRestriction = ({
  restrictionName, // restrictionName is the same at label
  restrictionValue,
  otherRestriction,
  fileAssociated,
  handleRefetchRestrictions, // from the parent VisualizeAllRestrictions
  handleOpenSnackbar,
  handleResolveRestriction, // from the parent view CompanyPreOffers
  companyId,
}) => {
  const classes = useStyles();
  /* eslint jsx-a11y/anchor-is-valid: 0 */ // --> OFF
  const [fileUploaded, setFileUploaded] = useState({ name: '', file: '' });
  const [openModal, setOpenModal] = useState(false);
  const [resolutionExplanation, setResolutionExplanation] = useState('');
  // in this component the restrictions are resolved, so we have to inform to the parent component,
  // that a restriction is already resolved to update the state in the parent (with lazyQuery)
  const [resolveCompanyRestriction,
    { loading: loadingResolveCompanyRestriction,
      error: errorResolveCompanyRestriction }] = useMutation(RESOLVE_COMPANY_RESTRICTIONS,
    { variables: {
      restrictionCompanyInput: {
        companyId,
        restriction: restrictionValue,
        otherRestriction,
      },
      associatedDocument: fileUploaded.file,
      resolutionExplanation,

    } });

  useEffect(() => {
    if (loadingResolveCompanyRestriction) {
      handleOpenSnackbar({ controlSnackbar: true, messageSnackbarInput: 'Cargando resolución de restricción' });
    }
  },
  [loadingResolveCompanyRestriction]);

  useEffect(() => {
    if (errorResolveCompanyRestriction) {
      handleOpenSnackbar({ controlSnackbar: true, messageSnackbarInput: 'Cargando resolución de restricción' });
    }
  },
  [errorResolveCompanyRestriction]);

  const handleFileField = (event) => {
    setFileUploaded({ file: event.target.files[0], name: event.target.name });
  };
  const handleResolveButton = () => {
    setOpenModal(true);
  };
  const handleConfirmResolveRestrictionMutation = async () => {
    await resolveCompanyRestriction();
    handleOpenSnackbar({ controlSnackbar: true, messageSnackbarInput: `Resolución de la restricción: ${restrictionName} exitosa` });
    // from the parent component, VisualizeAllRestrictions,
    // and this functions is defined in the preoffers view
    handleResolveRestriction(); // to notify the view CompanyPreOffers
    handleRefetchRestrictions(true); // to refetch the pending restrictions to the parent component
  };
  const handleVariableWayToSolve = (wayToSolve) => {
    setResolutionExplanation(wayToSolve);
  };
  return (
    <div className={classes.container}>

      {restrictionValue === 'OTHER'
        ? (
          <Typography noWrap display="inline" style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '20rem' }}>
            {restrictionName}: {otherRestriction}
          </Typography>
        )
        : (
          <Typography>
            {restrictionName}
          </Typography>
        )}
      {fileUploaded.file && (

      <Typography variant="caption" style={{ marginLeft: '0.5rem' }}>
          {fileUploaded.file.name}
      </Typography>

      )}
      {fileAssociated && (
      <label htmlFor={`contained-button-file-${restrictionName}-${otherRestriction}`}>
        <input
          name={restrictionName}
          // accept=".pdf"
          // multiple
          className={classes.input}
          id={`contained-button-file-${restrictionName}-${otherRestriction}`}
          type="file"
          onChange={handleFileField}
        />
        <Button
          fullWidth
          className={classes.uploadButton}
          component="span"
          endIcon={<Publish color="primary" />}
        />
      </label>
      )}
      <Button style={{ marginLeft: !fileAssociated ? '1.5rem' : '0rem' }} onClick={handleResolveButton} size="small"> Resolver</Button>
      <ResolveRestriction
        open={openModal}
        handleClose={() => setOpenModal(false)}
        restrictionName={restrictionName}
        restrictionValue={restrictionValue}
        otherRestriction={otherRestriction}
        handleConfirm={handleConfirmResolveRestrictionMutation}
        handleVariablesResolveRestriction={handleVariableWayToSolve}
      />
    </div>
  );
};

VisualizeRestriction.propTypes = {
  restrictionName: PropTypes.string.isRequired,
  restrictionValue: PropTypes.string.isRequired,
  otherRestriction: PropTypes.string.isRequired,
  fileAssociated: PropTypes.bool.isRequired,
  handleRefetchRestrictions: PropTypes.func.isRequired,
  handleOpenSnackbar: PropTypes.func.isRequired,
  handleResolveRestriction: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default VisualizeRestriction;
