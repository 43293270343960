import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Tooltip,
  LinearProgress,
  IconButton,
  Button,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
// import { getTimeDiffText } from '../helpers/date';
import { REJECT_PRE_OFFER_EVALUATION_REQUESTS } from '../gql/resolveRequests';
import { OneButtonAlert } from '../components/dialogs';
import SelectForm from '../components/utils/SelectForm';
import { ADD_COMPANY_TO_BLACKLIST } from '../gql/customer';
import TextEditor from '../components/textEditor';
import DebtorCell from '../components/shared/DebtorCell';
import { GET_EVALUATION_SIMULATIONS } from '../gql/ordering';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'emittedDate', label: 'Emisión', align: 'right' },
  { id: 'expirationDate', label: 'Vencimiento', align: 'right' },
  { id: 'amountWithIva', label: 'Monto', align: 'right' },
  { id: 'rejectionReason', label: 'Motivo de rechazo', align: 'right' },
  { id: 'riskBlacklist', label: 'Blacklist', align: 'right' },
];
const rejectionReasons = [
  { label: 'Se supera el nivel de endeudamiento objetivo de Fingo', value: 'OVER_INDEBTEDNESS_FINGO' },
  { label: 'Cliente con nivel de endeudamiento excesivo', value: 'OVER_INDEBTEDNESS_CLIENT' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a ventas', value: 'OVER_INDEBTEDNESS_CLIENT_SALES' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a patrimonio', value: 'OVER_INDEBTEDNESS_CLIENT_EQUITY' },
  { label: 'Existencia de procedimientos judiciales en curso', value: 'JUDICIAL_PROCEDEMENT' },
  { label: 'Existencia de procedimientos administrativos en curso', value: 'ADMINISTRATIVE_PROCEDURES' },
  { label: 'Antecedentes inexactos o insuficientes', value: 'INACCURATE_BACKGROUND' },
  { label: 'Cliente con morosidades o protestos según Ley 19.268', value: 'CLIENT_OVERDUE' },
  { label: 'Deudor con morosidades o protestos según Ley 19.268', value: 'DEBTOR_OVERDUE' },
  { label: 'Incumplimiento previo con Fingo o con una de sus filiales', value: 'PREVIOUS_NON_COMPLIANCE' },
  { label: 'Conflicto de interés en virtud de una causa legal', value: 'INTEREST_CONFLICT' },
  { label: 'No cumple con antigüedad mínima en la actividad ', value: 'SENIORITY' },
  { label: 'Presenta inestabilidad en sus ingresos', value: 'UNSTABLE_INCOME' },
  { label: 'Otro', value: 'OTHER' },
];

const CompanyPreOffersToReject = () => {
  const location = useLocation();
  const { state: { selectedInvoices: incommingPreOffers } } = location;
  const history = useHistory();
  const [rejectPreOfferEvaluationRequests] = useMutation(REJECT_PRE_OFFER_EVALUATION_REQUESTS,
    {
      refetchQueries: [
        { query: GET_EVALUATION_SIMULATIONS },
      ],
      onCompleted: () => {
        history.push({
          pathname: '/app/risk/evaluations',
          state: { tab: 1 },
        });
      },
    });
  const [
    addCompanyToBlacklist,
    { loading: blacklistLoading },
  ] = useMutation(ADD_COMPANY_TO_BLACKLIST);
  const classes = useStyles();
  const { clientCompanyName } = location;
  const [preOffers, setPreOffers] = useState(incommingPreOffers);
  const [openAlert, setOpenAlert] = useState(false);
  const [processDataLoading, setProcessDataLoading] = useState(false);
  const [allRejectionReasons, setAllRejectionReasons] = useState(false);
  const [informClients, setInformClients] = useState(true);
  const [blacklist, setBlacklist] = useState({});
  const [sendEmail, setSendEmail] = useState(false);

  const [comment, setComment] = useState('');

  useEffect(() => {
    const newPreOffers = incommingPreOffers.map((preOffer) => ({
      ...preOffer,
      rejectionReason: '',
      otherRejectionReason: '',
    }));
    const initBlacklist = {};
    incommingPreOffers.forEach((preOffer) => {
      initBlacklist[preOffer.receiver.rut] = preOffer.receiver.riskBlacklist;
    });
    setPreOffers(newPreOffers);
    setBlacklist(initBlacklist);
    setProcessDataLoading(false);
  }, [location.preOffers]);

  useEffect(() => {
    /* All rows must have rejectionReason */
    const filterFunction = (row) => {
      if (row.rejectionReason === '' || (row.rejectionReason === 'OTHER' && row.otherRejectionReason === '')) {
        return true;
      }
      return false;
    };
    const dataMissingRejectionReason = preOffers.filter((row) => filterFunction(row));
    /*  */
    if (dataMissingRejectionReason.length === 0) {
      setAllRejectionReasons(true);
    } else {
      setAllRejectionReasons(false);
    }
  }, [preOffers]);

  const transformDataToComponents = (_data) => {
    const handleChange = (event, id) => {
      const { name, value } = event.target;
      const newData = [..._data];
      newData.find((row) => row.id === id)[name] = value;
      setPreOffers(newData);
    };

    return _data.map((row) => {
      const {
        id,
        folio,
        receiver: {
          id: receiverId,
          rut: rutReceiver,
          name: nameReceiver,
        },
        dateIssued,
        dateExpiration,
        amountWithIva,
        pdf,
        style,
        rejectionReason,
      } = row;
      return ({
        id,
        folio: {
          value: folio, // value is for table sorting
          component: (
            <div className={classes.alignRight}>
              <Typography>  {folio}</Typography>
              <Tooltip title="Descargar Factura">
                <a href={pdf} target="_blank" rel="noopener noreferrer">
                  <IconButton>
                    <PrintIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </div>
          ),
        },
        debtor: {
          value: rutReceiver,
          component: (
            <DebtorCell
              debtorId={receiverId}
              name={nameReceiver}
              rut={rutReceiver}
              hasRiskBlacklist={blacklist[rutReceiver]}
            />
          ),
        },
        emittedDate: {
          value: dateIssued,
          component: (
            <>
              <Typography align="right">{dateIssued}</Typography>
            </>
          ),
        },
        expirationDate: {
          value: dateExpiration,
          component: (
            <>
              <Typography align="right">{dateExpiration}</Typography>
            </>
          ),
        },
        amountWithIva: {
          value: amountWithIva,
          component: (
            <Typography align="right">${formatMoney(amountWithIva)}</Typography>
          ),
        },

        rejectionReason: {
          value: rejectionReason,
          component: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <SelectForm
                reasons={rejectionReasons}
                inputText="Selecciona el motivo de rechazo"
                onChange={(event) => handleChange(event, id)}
                name="rejectionReason"
              />
            </div>),
        },
        riskBlacklist: {
          component: (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addCompanyToBlacklist({
                  variables: { ruts: [rutReceiver] },
                });
                setBlacklist({ ...blacklist, [rutReceiver]: true });
              }}
              disabled={blacklist[rutReceiver]}
            >
              Agregar
              {blacklistLoading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
            </Button>
          ),
        },
        style,
      });
    });
  };

  const sendOffer = async () => {
    // setOfferLoading(true);
    const resolvedPreOffers = [];
    preOffers.forEach((preOffer) => {
      const {
        preoffer,
        rejectionReason,
        otherRejectionReason,
      } = preOffer;
      resolvedPreOffers.push({
        id: preoffer.preofferevaluationrequest.id,
        rejectionReason,
        otherRejectionReason,
      });
    });
    await rejectPreOfferEvaluationRequests({
      variables: {
        resolution: {
          requests: resolvedPreOffers,
          analystDescription: comment,
          informClients,
          sendEmail,
        },
      },
    });
    // setOfferLoading(false);
    // setOpenConfirmation(false);
    setOpenAlert(true);
  };

  if (processDataLoading) return <LinearProgress />;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4"><b>Rechazar preofertas de {clientCompanyName}</b></Typography>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={12} className={classes.tableContainer}>

          <Table
            data={preOffers}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setPreOffers}
            headers={headers}
            initialOrderId="amountWithIva"
          />
          <Grid container alignItems="flex-end" direction="column">
            {!allRejectionReasons && (
              <Typography color="error" gutterBottom>
                Debes seleccionar el motivo de rechazo para cada solicitud
              </Typography>
            )}
            <Typography variant="h4">Comentarios</Typography>
            <TextEditor
              setDescription={setComment}
            />
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Typography>Informar al cliente de la decisión tomada</Typography>
              <Checkbox
                checked={informClients}
                onChange={() => setInformClients((last) => !last)}
              />
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Typography>
                Enviar mail informando de rechazo automático sin autorizacion de Gerente
              </Typography>
              <Checkbox
                checked={sendEmail}
                onChange={() => setSendEmail((last) => !last)}
              />
            </Grid>
            <Button variant="contained" color="primary" onClick={() => { sendOffer(); }} disabled={!allRejectionReasons}>
              Confirmar rechazo
            </Button>
          </Grid>

        </Grid>
      </Grid>
      <OneButtonAlert
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message="Todavía el proceso no está automatizado, por favor asegúrate de que la oferta se le notifique al cliente que su oferta está lista"
        title="Oferta guardada con éxito"
      />
    </div>
  );
};

export default CompanyPreOffersToReject;
