import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { formatMoney } from '../../helpers/money';
import { ruleVariantToText } from '../../helpers/translators';
import { DeleteCommissionAlert } from '../dialogs';

const useStyles = makeStyles(() => ({
  paper: {
    height: 120,
    padding: '1rem 0 0 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const RuleCard = ({ onDelete, onEdit, rule: { variant, value, threshold, expirationDate } }) => {
  const classes = useStyles();
  const [deleteCommissionAlert, setDeleteCommissionAlert] = useState(false);

  return (
    <Paper className={classes.paper}>
      <div>
        <Typography>Comisión {ruleVariantToText[variant]}</Typography>
        <Typography variant="body2">
          {
            variant !== 'PERCENTAGE'
              ? `Monto $${formatMoney(value)} + IVA`
              : `Porcentaje ${value * 100}% + IVA`
          }
        </Typography>
        {variant === 'MIN' && (
          <Typography variant="body2">Umbral ${formatMoney(threshold)}</Typography>
        )}
        {expirationDate && (
          <Typography variant="body2">
            Caducidad {moment(expirationDate).format('DD/MM/YYYY HH:mm')}
          </Typography>
        )}
      </div>
      <Grid container justify="flex-end">
        <IconButton onClick={() => setDeleteCommissionAlert(true)}>
          <Delete color="error" />
        </IconButton>
        <IconButton onClick={onEdit}>
          <Edit />
        </IconButton>
      </Grid>
      <DeleteCommissionAlert
        onAccept={onDelete}
        open={deleteCommissionAlert}
        onClose={() => setDeleteCommissionAlert(false)}
      />
    </Paper>
  );
};

RuleCard.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  rule: PropTypes.shape({
    variant: PropTypes.string,
    value: PropTypes.number,
    threshold: PropTypes.number,
    expirationDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default RuleCard;
