export const statusToSpanish = {
  SIMULATION: 'Simulación',
  CREATED: 'Creada',
  EVALUATION: 'En evaluación',
  REJECTED: 'Rechazada',
  OFFERED: 'Aceptada',
  PENDINGASSIGNMENTDOCUMENT: 'Pendiente de mandato',
  PENDINGSIGNATURE: 'Pendiente de mandato',
  PENDINGTRANSFER: 'Pendiente de giro',
  PENDINGCOLLECTION: 'En recaudación',
  PENDINGINVOICES: 'Pendiente de factura',
  PROCESSINGINVOICES: 'Procesando Cesión',
  FACTORING: 'Factura cedida no girada',
  FINISHED: 'Finalizada',
};

export const orderStatus = {
  Created: 'CREATED',
  Simulation: 'SIMULATION',
  Evaluation: 'EVALUATION',
  Offered: 'OFFERED',
  Rejected: 'REJECTED',
  PendingAssignmentDocument: 'PENDINGASSIGNMENTDOCUMENT',
  PendingSignature: 'PENDINGSIGNATURE',
  PendingTransfer: 'PENDINGTRANSFER',
  PendingInvoices: 'PENDINGINVOICES',
  PendingCollection: 'PENDINGCOLLECTION',
  Factoring: 'FACTORING',
  Finished: 'FINISHED',
};
