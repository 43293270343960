import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { UserContext } from '../../context/user';

const Layout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, logout } = useContext(UserContext);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  if (!user) return <Redirect to="/" />;

  return (
    <div>
      <Header
        toggleDrawer={toggleDrawer}
        user={user}
        logout={logout}
      />
      <LeftNavigation
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        logout={logout}
      />
      {children}
    </div>
  );
};

Layout.propTypes = {
  // children: PropTypes.objectOf(PropTypes.element).isRequired,
  children: PropTypes.element.isRequired,
};

export default Layout;
