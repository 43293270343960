import { gql } from '@apollo/client';

export const GET_DEBTORS = gql`
  query GetDebtors($first: Int, $offset: Int, $globalFilter: String, $orderBy: String) {
    getDebtors(first: $first, offset: $offset, globalFilter: $globalFilter, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          masterEntity {
            id
            rut
            name
            siiSalesSection
            invoicesInRatificationCount
            invoicesInEvaluationCount
            invoicesInCollectionCount
          }
          ratificator {
            id
            firstName
            lastName
          }
          collector {
            id
            firstName
            lastName
          }
        }
      }
    }
  }

`;

export const ASSIGN_EXECUTIVE_TO_DEBTOR = gql`
mutation SetRatificatorCollector($companyRatificatorCollector:[DebtorRatificatorCollector]) {
  assignExecutiveToDebtor(companyRatificatorCollector:$companyRatificatorCollector) {
    success
  }
}`;

export const GET_DEBTOR_INVOICES = gql`
  query GetDebtorInvoices($debtorRut: String!, $first: Int, $offset: Int, $globalFilter: String, $orderBy: String) {
    getDebtorInvoices(debtorRut: $debtorRut, first: $first, offset: $offset, globalFilter: $globalFilter, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          id
          folio
          pdf
          rutReceiver
          receiver {
            id
            ratificationBlacklist {
              active
            }
            riskBlacklist {
              id
            }
          }
          company {
            id
            name
            rut
          }
          dateIssued
          dateExpiration
          dateToPay
          paymentDate
          ratificationmanager{
            id
            actions {
              id
              actionType
              comment
              createdAt
              responded
              channel
               ratificationContacts {
                id
                firstName
                lastName
                email
                phoneNumber
              }
              author {
                id
                firstName
                lastName
              }
              contactsResponse {
                id
              }
            }
          }
          collectionManager{
            id
            status
            actions {
              id
              actionType
              comment
              createdAt
              collectionContacts {
                id
                firstName
                lastName
                email
                phoneNumber
              }
              author {
                id
                firstName
                lastName
              }
            }
            currentDataForCollection {
              id
              paymentMethod
              dateToPay
              collectionContacts {
                id
                firstName
                lastName
              }
            }
            reminder {
              id
              reminderDate
              reminderNote
            }
          }
          lastHistoryStatus {
            status
            createdAt
          }
          stage
          status
          surplusDebt
          amountWithIva
          creditNoteStatus
          siiStatus
          nameReceiver
        }
      }
    }
  }
`;

export const GET_INVOICES_MANAGERS = gql`
query GetActiveInvoicesByReceiver($debtorRut: String!) {
  getActiveInvoicesByReceiver(debtorRut: $debtorRut) {
      id
      collectionManager{
        id
        status
        actions {
          id
          actionType
          comment
          createdAt
          collectionContacts {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          author {
            id
            firstName
            lastName
          }
        }
      }
      ratificationmanager{
        id
        actions {
          id
          actionType
          comment
          createdAt
          responded
          channel
           ratificationContacts {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          author {
            id
            firstName
            lastName
          }
          contactsResponse {
            id
          }
        }
      }
    }
  }
  `;

export const GET_DEBTOR = gql`
  query GetDebtor($debtorRut: String!) {
    getDebtor(debtorRut: $debtorRut) {
      debtorType
      masterEntity {
        id
        invoicesInDebtCount
        payedInvoicesCount
        pendingInvoicesCount
        invoicesAgreedPaymentTermAverage
        invoicesActualPaymentTerm
        pendingInvoicesTotalAmount
      }
    }
  }
`;
