import React, { useState, useEffect } from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useStyles } from '../../views/styles';
import { GET_CONCILIABLE_INVOICES } from '../../gql/conciliations';
import { parseDate } from '../../helpers/date';
import { formatMoney } from '../../helpers/money';
import { getCollectionManagers, filterInvoices } from '../../helpers/conciliation';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import IncomesTable from './incomesTable';

const IncomesFactoring = () => {
  const classes = {
    ...useStyles(),
  };

  const [invoices, setInvoices] = useState([]);

  const {
    data: invoicesData,
    loading: invoicesLoading,
    refetch: refetchInvoices,
  } = useQuery(GET_CONCILIABLE_INVOICES);

  useEffect(() => {
    if (invoicesData) {
      setInvoices(invoicesData.getConciliableInvoices.map((invoice) => (
        { ...invoice, debtor: invoice.receiver }
      )));
    }
  }, [invoicesData]);

  const headersInvoices = [
    { id: 'folio', label: 'Folio', align: 'left' },
    { id: 'receiverName', label: 'Razón Social Receptor', align: 'left' },
    { id: 'dateToPay', label: 'Fecha Acordada', align: 'left' },
    { id: 'amountWithIva', label: 'Monto', align: 'left' },
  ];

  const confirmationHeadersInvoices = [
    { id: 'folio', label: 'Folio', align: 'left' },
    { id: 'amountWithIva', label: 'Monto', align: 'left' },
    { id: 'actualDate', label: 'Fecha de Pago', align: 'left' },
  ];

  const transformInvoiceToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      amountWithIva,
      dateToPay,
    } = row;
    const {
      name: receiverName,
      rut,
      dv,
    } = row.receiver;
    const { id: collectionManagerId } = row.collectionManager;
    const fullRut = rut.toString() + dv;
    return ({
      id,
      collectionManagerId,
      folio: {
        value: folio,
        component: (
          <Typography>{folio}</Typography>
        ),
      },
      receiverName: {
        value: receiverName,
        component: (
          <Typography className={classes.twoLineClamp}>{receiverName}</Typography>
        ),
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <Typography>{parseDate(dateToPay)}</Typography>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      fullRut,
    });
  });

  if (invoicesLoading) {
    return (<LinearProgress />);
  }

  return (
    <IncomesTable
      objectsToCollectHeaders={headersInvoices}
      objectsToCollectConfirmationHeaders={confirmationHeadersInvoices}
      objectsToCollectData={invoices}
      transformObjectsToComponents={transformInvoiceToComponents}
      objectsToCollectType="INVOICES"
      getObjectsCollectionManagers={getCollectionManagers}
      refetchObjects={refetchInvoices}
      objectsFilterFunction={filterInvoices}
    />
  );
};

export default IncomesFactoring;
