import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
  TextField,
  FormGroup,

} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
});
// the action of resolve restrictions happens in the parent component, VisualizeRestriction,
// here we only pass the variables to the parent
const ResolveRestriction = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  handleVariablesResolveRestriction,
  restrictionName,
  restrictionValue,
  otherRestriction,
}) => {
  const classes = useStyles();
  const [wayToSolveField, setWayToSolveField] = useState('');
  // if confirmButton = true, the button is disabled
  // only the restrictions uncreated or pending can be created

  const handleWayToSolve = (event) => {
    setWayToSolveField(event.target.value);
  };

  const handleConfirmModal = () => {
    handleConfirm(); // mutation to create the restrictions
    // uncreatedRestrictionsRefetch(); // async refetch
    // setState({ items: [] }); // delete state checkbox

    handleClose(); // close the modal
  };

  useEffect(() => {
    // we pass the wayToSolve variable to the parent, to the mutation of resolve restriction
    handleVariablesResolveRestriction(wayToSolveField);
  }, [wayToSolveField]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ overflowWrap: 'break-word' }}><b> Resolver Restricción {restrictionValue === 'OTHER' ? `${restrictionName}: ${otherRestriction}` : restrictionName}</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <Grid item xs={12}>
            <FormGroup style={{ marginTop: 20 }}>
              <TextField
                key="otherField"
                value={wayToSolveField}
                name="wayToSolveField"
                variant="outlined"
                multiline
                rows={8}
                style={{ overflowWrap: 'break-word' }}
                onChange={handleWayToSolve}
                label={restrictionValue === 'OTHER' ? `Indica cómo solucionaste la restricción: ${otherRestriction}` : `Indica cómo solucionaste la restricción: ${restrictionName}`}
              />
            </FormGroup>
          </Grid>

        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={loading} onClick={handleConfirmModal} color="primary" autoFocus>
          Confirmar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResolveRestriction.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  handleVariablesResolveRestriction: PropTypes.func.isRequired,
  restrictionName: PropTypes.string.isRequired,
  restrictionValue: PropTypes.string.isRequired,
  otherRestriction: PropTypes.string.isRequired,
};

ResolveRestriction.defaultProps = {
  loading: false,
};

export default ResolveRestriction;
