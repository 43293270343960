import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { getFormFieldError } from '../../helpers/formValidators';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  rowFile: {
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const CompanyInfoManager = ({
  open,
  handleClose,
  handleConfirm,
  loading,

}) => {
  const classes = useStyles();

  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [representativeNameValue, setRepresentativeNameValue] = useState('');
  const [representativeRutValue, setRepresentativeRutValue] = useState('');
  const [objectErrorFromEmpty, setObjectErrorFromEmpty] = useState({
    isError: false,
    message: '',
  });
  const [objectErrorFromMail, setObjectErrorFromMail] = useState({
    isError: false,
    message: '',
  });
  const [objectErrorFromPhone, setObjectErrorFromPhone] = useState({
    isError: false,
    message: '',
  });

  const handleChangeEmailValue = (event) => {
    setEmailValue(event.target.value);
  };

  const handleChangePhoneValue = (event) => {
    setPhoneValue(event.target.value);
  };

  const handleChangeNameValue = (event) => {
    setRepresentativeNameValue(event.target.value);
  };

  const handleChangeRutValue = (event) => {
    setRepresentativeRutValue(event.target.value);
  };

  const getEmptyInputsError = () => {
    if (representativeNameValue === '' || representativeRutValue === '') {
      return {
        isError: true,
        message: 'Los campos no pueden estar vacíos',
      };
    }
    return {
      isError: false,
      message: '',
    };
  };

  const handleCloseButton = () => {
    setEmailValue('');
    setPhoneValue('');
    setObjectErrorFromMail({
      isError: false,
      message: '',
    });
    setObjectErrorFromPhone({
      isError: false,
      message: '',
    });
    handleClose();
  };

  const handleSubmitButtonChecker = () => {
    const emailError = getFormFieldError(emailValue, 'email');
    setObjectErrorFromMail(emailError);
    const phoneNumberError = getFormFieldError(phoneValue, 'phoneNumber');
    setObjectErrorFromPhone(phoneNumberError);
    const emptyError = getEmptyInputsError();
    setObjectErrorFromEmpty(emptyError);
    if (!emailError.isError && !phoneNumberError.isError && !emptyError.isError) {
      handleConfirm(phoneValue, emailValue, representativeNameValue, representativeRutValue);
      setEmailValue('');
      setPhoneValue('');
    }
  };
  return (
    <Dialog open={open} onClose={() => { handleCloseButton(); }}>
      <DialogTitle id="alert-dialog-title"><b>Agregar email y teléfono del RL de la empresa</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div>
          <div className={classes.rowFile}>
            <Typography><b>Nombre</b></Typography>
            <TextField
              value={representativeNameValue}
              variant="outlined"
              type="text"
              onChange={handleChangeNameValue}
              className={classes.searchBox}
              InputProps={{
                style: {
                  height: 32,
                  width: 200,
                },
              }}
            />
          </div>
          <div className={classes.rowFile}>
            <div>
              <Typography><b>Rut</b></Typography>
              <Typography variant="caption">Sin digito verificador, ni puntos</Typography>
            </div>
            <TextField
              value={representativeRutValue}
              variant="outlined"
              type="number"
              onChange={handleChangeRutValue}
              className={classes.searchBox}
              InputProps={{
                style: {
                  height: 32,
                  width: 200,
                },
              }}
            />
          </div>
          <div className={classes.rowFile}>
            <Typography><b>Email</b></Typography>
            <TextField
              value={emailValue}
              variant="outlined"
              onChange={handleChangeEmailValue}
              className={classes.searchBox}
              InputProps={{
                style: {
                  height: 32,
                  width: 200,
                },
              }}
            />
          </div>
          <div className={classes.rowFile}>
            <Typography><b>Teléfono</b></Typography>
            <TextField
              value={phoneValue}
              variant="outlined"
              onChange={handleChangePhoneValue}
              className={classes.searchBox}
              InputProps={{
                startAdornment: <InputAdornment position="start">+56</InputAdornment>,
                style: {
                  height: 32,
                  width: 200,
                },
              }}
            />
          </div>
        </div>
        {objectErrorFromMail.isError && (
        <Typography color="error">{objectErrorFromMail.message}</Typography>
        )}
        {objectErrorFromPhone.isError && (
        <Typography color="error">{objectErrorFromPhone.message}</Typography>
        )}
        {objectErrorFromEmpty.isError && (
        <Typography color="error">{objectErrorFromEmpty.message}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => { handleCloseButton(); }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={() => handleSubmitButtonChecker()} color="primary" autoFocus>
          Aceptar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompanyInfoManager.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
CompanyInfoManager.defaultProps = {
  loading: false,
};

export default CompanyInfoManager;
