import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Typography,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '0.5rem',
  },
}));

const UserAvatar = ({ name }) => {
  const classes = useStyles();

  return (
    <div className={classes.userInfoContainer}>
      {(name == null) ? (
        <Avatar className={classes.smallAvatar}>
          <Person />
        </Avatar>
      ) : (
        <Avatar className={classes.smallAvatar}>{name[0]}</Avatar>
      )}
      <Typography variant="caption">{name}</Typography>
    </div>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string,
};

UserAvatar.defaultProps = {
  name: null,
};

export default UserAvatar;
