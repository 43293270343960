import React, { useState, useEffect } from 'react';
import { Typography, Box, LinearProgress, Grid, InputAdornment, OutlinedInput, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import { SearchOutlined } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { parseDate } from '../helpers/date';
import { parseMoney } from '../helpers/money';
import { HIDE_MOVEMENT } from '../gql/conciliations';
import ConfirmHiding from '../components/dialogs/ConfirmHiding';

const MovementsTable = (props) => {
  const classes = {
    ...useStyles(),
  };
  const {
    movementType,
    totalSelected,
    setSelectedMoves,
    selectedMoves,
    movementsData,
    movementsLoading,
    reset,
    refetchMovements,
    resetSelectedMoves,
  } = props;

  const [movements, setMovements] = useState([]);
  const [filter, setFilter] = useState('');
  const [hidingConfirmation, setHidingConfirmation] = useState(false);
  const [hidingId, setHidingId] = useState();
  const [hideMovement] = useMutation(HIDE_MOVEMENT);

  useEffect(() => {
    if (movementsData) {
      setMovements(movementsData);
    }
  }, [movementsData]);

  useEffect(() => {
    if (movementsData) {
      setMovements(movementsData.filter((move) => {
        const idCondition = move.id.toString().includes(filter);
        const name = move.senderHolderName || move.recipientHolderName || move.description;
        const nameCondition = name.toLowerCase().includes(filter.toLowerCase());
        const dateCondition = move.postDate.includes(filter);
        const amountCondition = move.amount.toString().includes(filter);
        return idCondition || nameCondition || dateCondition || amountCondition;
      }));
    }
  }, [filter]);

  const handleHide = async (id) => {
    await hideMovement({ variables: {
      movement: parseInt(id, 10),
    } });
    refetchMovements();
    setHidingId(-1);
    setHidingConfirmation(false);
    resetSelectedMoves([]);
  };

  const headersMovements = [
    { id: 'move_id', label: 'ID', align: 'left' },
    {
      id: 'holderName',
      label: movementType === 'incomes' ? 'Razón Social Pagador' : 'Razón Social Emisor',
      align: 'left',
    },
    { id: 'postDate', label: 'Fecha Contable', align: 'left' },
    { id: 'amount', label: 'Monto', align: 'left' },
    { id: 'hide', label: 'Ocultar', align: 'left' },
  ];

  const transformMoveToComponents = (_data) => _data.map((row) => {
    const {
      id,
      postDate,
      amount,
      senderHolderName,
      recipientHolderName,
      senderHolderId,
      recipientHolderId,
      description,
    } = row;
    return ({
      id,
      style: { fontSize: '2px' },
      move_id: {
        value: id,
        component: (
          <div>
            <Typography>{id}</Typography>
          </div>
        ),
      },
      holderName: {
        value: senderHolderName || recipientHolderName || description,
        component: (
          <Typography className={classes.twoLineClamp}>
            {senderHolderName || recipientHolderName || description}
          </Typography>
        ),
      },
      postDate: {
        value: postDate,
        component: (
          <Typography>{parseDate(postDate)}</Typography>
        ),
      },
      amount: {
        value: amount,
        component: (
          <Typography>{parseMoney(amount)}</Typography>
        ),
      },
      hide: {
        component: (
          <IconButton onClick={() => {
            setHidingConfirmation(true);
            setHidingId(id);
          }}
          >
            <ClearIcon />
          </IconButton>
        ),
      },
      senderHolderId,
      recipientHolderId,
    });
  });

  const handleSelected = (rows) => {
    setSelectedMoves(rows);
  };

  return (
    <Box component="div" className={classes.movements}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h4" className={classes.tableHeader}>
            <b>{movementType === 'incomes' ? 'Ingresos' : 'Egresos'}</b>
          </Typography>
        </Grid>
        <Grid item>
          <OutlinedInput
            id="input-with-icon-adornment"
            startAdornment={(
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
          )}
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
        </Grid>
      </Grid>
      {movementsLoading ? (
        <LinearProgress />
      )
        : (
          <>
            {movements.length > 0 ? (
              <>
                <Box border={0.5} borderColor="grey.500" className={classes.tableBox}>
                  <Table
                    data={movements}
                    dataToRows={transformMoveToComponents}
                    setNewDataOrder={setMovements}
                    headers={headersMovements}
                    initialOrderId="holderName"
                    handleSelected={handleSelected}
                    selectedObjects={selectedMoves}
                    withSelect
                    withoutSelectHeader
                    clearSelection={reset}
                    pagination={false}
                  />
                </Box>
                <Grid container justify="space-between" className={classes.totalSelected}>
                  <Grid item>
                    <Typography variant="h5">Total seleccionado:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">{parseMoney(totalSelected)}</Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="h5" align="center" className={classes.listEmpty}>
                {filter ? 'No hay coincidencias' : 'No hay movimientos sin conciliar'}
              </Typography>
            )}
          </>
        )}
      <ConfirmHiding
        open={hidingConfirmation}
        setOpen={setHidingConfirmation}
        hidingId={hidingId}
        handleHide={handleHide}
      />
    </Box>
  );
};

MovementsTable.propTypes = {
  movementType: PropTypes.string.isRequired,
  setSelectedMoves: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedMoves: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  movementsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  movementsLoading: PropTypes.bool.isRequired,
  reset: PropTypes.bool.isRequired,
  totalSelected: PropTypes.number.isRequired,
  refetchMovements: PropTypes.func.isRequired,
  resetSelectedMoves: PropTypes.func.isRequired,
};

MovementsTable.defaultProps = {
  selectedMoves: [],
};

export default MovementsTable;
