import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Chart } from 'react-google-charts';
import { Button, Grid } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { RAS_DISTRIBUTION } from '../../gql/riskAlgorithm';
import { Loading } from '../loading';
import { Error } from '../error';

const styles = makeStyles(() => ({
  container: {
    padding: '2%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  rowEnd: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: '2rem',
  },
  chart: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const RasDistribution = () => {
  const { data, loading, refetch, error } = useQuery(
    RAS_DISTRIBUTION, { notifyOnNetworkStatusChange: true },
  );
  const [treeApprovedData, setTreeApprovedData] = useState();
  const [treeNotApproved, setTreeNotApproved] = useState();
  const [histogramTotalData, setHistogramTotalData] = useState();
  const classes = styles();
  useEffect(() => {
    if (loading) return;
    if (!data) return;
    const newHistogramData = [['name', 'result']];
    const newApprovedData = [['name', 'result']];
    const newNotApprovedData = [['name', 'result']];
    data.rasResults.forEach((value) => {
      newHistogramData.push(['ras', value.ras]);
      if (value.tree) {
        newApprovedData.push(['ras', value.ras]);
      } else {
        newNotApprovedData.push(['ras', value.ras]);
      }
    });
    setTreeApprovedData(newApprovedData);
    setTreeNotApproved(newNotApprovedData);
    setHistogramTotalData(newHistogramData);
  }, [loading, data]);
  if (error) return <Error />;
  if (loading || !treeApprovedData || !treeNotApproved || !histogramTotalData) {
    return <Loading />;
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={12} sm={12} className={classes.rowEnd}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => refetch()}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </Grid>
      <Grid item xs={8} md={8} sm={8}>
        <Chart
          width="100%"
          height="40rem"
          chartType="Histogram"
          loader={<div>Loading Chart</div>}
          data={histogramTotalData}
          options={{
            title: 'Distribución puntajes de RAS',
            legend: { position: 'none' },
          }}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <Chart
          width="100%"
          height="40rem"
          chartType="Histogram"
          loader={<div>Loading Chart</div>}
          data={treeApprovedData}
          options={{
            title: 'Distribución puntajes de RAS para los aprobados en el árbol',
            legend: { position: 'none' },
          }}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <Chart
          width="100%"
          height="40rem"
          chartType="Histogram"
          loader={<div>Loading Chart</div>}
          data={treeNotApproved}
          options={{
            title: 'Distribución puntajes de RAS para los no aprobados en el árbol',
            legend: { position: 'none' },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RasDistribution;
