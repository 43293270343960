import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    padding: '1rem',
  },
  sendButtonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '1rem',
    width: '100%',
  },
});

const AddPseudoOrder = ({ open, onClose, onAccept }) => {
  const classes = useStyles();
  const [rutClient, setRutClient] = useState('');
  const [rutDebtor, setRutDebtor] = useState('');
  const [orderAmount, setOrderAmount] = useState('');
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [error, setError] = useState('');
  const handleRutClientChange = (e) => {
    setRutClient(e.target.value);
  };
  const handleRutDebtorChange = (e) => {
    setRutDebtor(e.target.value);
  };
  const handleOrderAmountChange = (e) => {
    setOrderAmount(e.target.value);
  };
  const handleAccept = async () => {
    if (rutClient.length <= 0 || rutDebtor.length <= 0 || orderAmount.length <= 0) {
      setError('Rellene todos los campos');
      return;
    }
    const rutRegex = /^\d{7,8}$/;
    if (!rutRegex.test(rutClient)) {
      setError('Formato incorrecto de rut cliente');
      return;
    }
    if (!rutRegex.test(rutDebtor)) {
      setError('Formato incorrecto de rut deudor');
      return;
    }
    const numRegex = /^\d{1,12}$/;
    if (!numRegex.test(orderAmount)) {
      setError('Formato incorrecto de monto de orden');
      return;
    }
    setError('');
    setLoadingResponse(true);
    const { data } = await onAccept({ variables: {
      orderingClientRut: rutClient,
      orderingDebtorRut: rutDebtor,
      orderingAmount: orderAmount,
    } });
    setLoadingResponse(false);
    if (data.createPseudoOrder.success) {
      setRutClient('');
      setRutDebtor('');
      setOrderAmount('');
      onClose();
    } else {
      setError(data.createPseudoOrder.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title"><b>Agregar pseudo orden</b></DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ingresar los RUT sin puntos, guión ni dígito verificador
        </DialogContentText>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={handleRutClientChange}
              value={rutClient}
              label="Rut Cliente"
              placeholder="Ej: 77201556"
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={handleRutDebtorChange}
              value={rutDebtor}
              label="Rut Deudor"
              placeholder="Ej: 77201556"
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={handleOrderAmountChange}
              value={orderAmount}
              label="Monto orden"
              placeholder="Ej: 1000000"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.sendButtonContainer}>
          <Typography color="error" variant="caption">{error}</Typography>
          <Button
            onClick={() => { handleAccept(); }}
            color="primary"
            variant="outlined"
            endIcon={loadingResponse}
            startIcon={loadingResponse
              ? <CircularProgress size={16} /> : null}
          >
            Añadir
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddPseudoOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default AddPseudoOrder;
