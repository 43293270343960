import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { statusToIcon } from '../clientInvoiceStatus/Status';

const bfStatusMessage = (
  readyForTransfer, requirementsClean, information, compliance, isFirstOperation,
) => {
  if (readyForTransfer || isFirstOperation) {
    return 'Listo para girar';
  }
  if (compliance) {
    if (requirementsClean) {
      return information;
    }
    return `Normativas no cumplidas: ${information}`;
  }
  return 'Su solicitud está siendo procesada';
};
const BFStatus = ({ compliance, isFirstOperation }) => {
  const { readyForTransfer, requirementsClean, status, information } = compliance || {};
  const transfer = compliance || isFirstOperation;
  return (
    <Tooltip
      title={bfStatusMessage(
        readyForTransfer, requirementsClean, information, compliance, isFirstOperation,
      )}
      arrow
      placement="left"
    >
      {transfer ? statusToIcon[isFirstOperation ? 'FINISHED' : status] : statusToIcon.INFORMATION}
    </Tooltip>
  );
};

BFStatus.propTypes = {
  compliance: PropTypes.shape({
    readyForTransfer: PropTypes.bool,
    status: PropTypes.string,
    requirementsClean: PropTypes.bool,
    information: PropTypes.string,
  }),
  isFirstOperation: PropTypes.bool,
};

BFStatus.defaultProps = {
  compliance: null,
  isFirstOperation: false,
};

export default BFStatus;
