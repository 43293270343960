import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon, Print as PrintIcon } from '@material-ui/icons';
import { Typography, IconButton, Tooltip, Button, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import { GET_COMPANY_TGR, SEND_CTE_SLACK } from '../../gql/customer';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',

  },

}));
const VisualizeDocument = ({
  companyRut,
  documentName,
  exists,
  lastDate,
  file,
  openSnackBar }) => {
  const classes = useStyles();
  let labelDocument = documentName;
  switch (documentName) {
    case 'CONTRACT':
      labelDocument = 'Contrato';
      break;
    case 'BALANCE':
      labelDocument = 'Balance';
      break;
    case 'PROVIDER_CREDENTIALS':
      labelDocument = 'Credenciales del facturador';
      break;
    default:
      break;
  }
  const [getCompanyTgr,
    { loading: loadingGetCompanyTgr,
      error: errorGetCompanyTgr,
    },
  ] = useMutation(GET_COMPANY_TGR, { onCompleted: () => openSnackBar(true) });
  const [sendCte, { loading: loadingSendCte,
    error: errorSendCte,
  }] = useLazyQuery(SEND_CTE_SLACK, { fetchPolicy: 'network-only', onCompleted: () => openSnackBar(true) });

  const handleGenerateCTEorTGR = async (documentType) => {
    if (documentType === 'TGR') {
      await getCompanyTgr({ variables: { rut: companyRut } });
    }
    if (documentType === 'CTE') {
      await sendCte({ variables: { rut: companyRut } });
    }
  };
  return (
    <div className={classes.container}>

      {exists ? <CheckCircleIcon style={{ color: green[500], marginRight: '0.5rem' }} /> : <CancelIcon color="error" style={{ marginRight: '0.5rem' }} />}
      <Typography style={{ marginRight: '0.5rem' }}>{ exists ? `Datos ${labelDocument}` : `Sin datos ${labelDocument}`}</Typography>
      {(exists) && (
        <>
          <Typography>{moment(lastDate).format('DD-MM-YYYY')}</Typography>
          {
          (file) && (
          <Tooltip title="Descargar Documento">
            <Link href={file} target="_blank" rel="noopener noreferrer" color="primary">
              <IconButton>
                <PrintIcon />
              </IconButton>
            </Link>
          </Tooltip>
          )
}
        </>
      )}
      {(documentName === 'TGR' || documentName === 'CTE') && (
        <>
          <Button onClick={() => handleGenerateCTEorTGR(documentName)} size="small"> Generar</Button>
          {(loadingGetCompanyTgr || loadingSendCte)
            && <CircularProgress size={16} /> }
          {(errorGetCompanyTgr || errorSendCte) && (
            <Typography color="error" variant="caption">
              Error al generar el documento. Contáctate con el equipo de desarrollo.
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

VisualizeDocument.propTypes = {
  companyRut: PropTypes.number.isRequired,
  openSnackBar: PropTypes.func,
  documentName: PropTypes.string.isRequired,
  exists: PropTypes.bool.isRequired,
  lastDate: PropTypes.string,
  file: PropTypes.string,
};

VisualizeDocument.defaultProps = {
  lastDate: '',
  file: '',
};

VisualizeDocument.defaultProps = {
  openSnackBar: () => { },
};

export default VisualizeDocument;
