import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  rutsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
});

const CreateOrEditWhitelist = ({
  open,
  handleClose,
  onCreateOrEditWhitelist,
  whitelist,
  editing,
}) => {
  const classes = useStyles();
  const [creationLoading, setCreationLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    clientRut: whitelist ? whitelist.clientRut : '',
    debtorRut: whitelist ? whitelist.debtorRut : '',
    monthlyRate: whitelist ? whitelist.monthlyRate : '',
    defaultRate: whitelist ? whitelist.defaultRate : '',
    retentionRate: whitelist ? whitelist.retentionRate : '',
  });

  useEffect(() => {
    setFormData({
      clientRut: whitelist ? whitelist.clientRut : '',
      debtorRut: whitelist ? whitelist.debtorRut : '',
      monthlyRate: whitelist ? whitelist.monthlyRate : '',
      defaultRate: whitelist ? whitelist.defaultRate : '',
      retentionRate: whitelist ? whitelist.retentionRate : '',
    });
  }, [whitelist]);

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setFormData((oldData) => ({ ...oldData, [name]: value }));
  };

  const handleConfirm = async () => {
    const {
      clientRut,
      debtorRut,
      monthlyRate,
      defaultRate,
      retentionRate,
    } = formData;
    if (monthlyRate.length === 0
      || defaultRate.length === 0
      || retentionRate.length === 0) {
      setErrorMessage('Debes llenar todos los datos');
      return;
    }
    setCreationLoading(true);
    if (!editing) {
      const clientRutVariable = clientRut.length === 0 ? null : clientRut;
      const debtorRutVariable = debtorRut.length === 0 ? null : debtorRut;
      await onCreateOrEditWhitelist(
        clientRutVariable, debtorRutVariable, monthlyRate, defaultRate, retentionRate,
      );
    } else {
      await onCreateOrEditWhitelist(whitelist.id, monthlyRate, defaultRate, retentionRate);
    }
    setCreationLoading(false);
    handleClose();
  };

  const {
    clientRut,
    debtorRut,
    monthlyRate,
    defaultRate,
    retentionRate,
  } = formData;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ overflowWrap: 'break-word' }}><b>Crear tasa preferencial</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Grid container className={classes.rutsContainer}>
          <Grid item>
            <Typography gutterBottom>
              <b>Rut del cliente (sin dígito verificador ni puntos)</b>
            </Typography>
            <TextField
              name="clientRut"
              type="number"
              value={clientRut}
              onChange={handleTextChange}
              variant="outlined"
              disabled={editing}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              <b>Rut del deudor (sin dígito verificador ni puntos)</b>
            </Typography>
            <TextField
              name="debtorRut"
              type="number"
              value={debtorRut}
              onChange={handleTextChange}
              variant="outlined"
              disabled={editing}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.ratesContainer}>
          <Grid item>
            <Typography gutterBottom>
              <b>Tasa</b>
            </Typography>
            <TextField
              style={{ maxWidth: 80 }}
              name="monthlyRate"
              type="number"
              value={monthlyRate}
              onChange={handleTextChange}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              <b>Mora</b>
            </Typography>
            <TextField
              style={{ maxWidth: 80 }}
              name="defaultRate"
              type="number"
              value={defaultRate}
              onChange={handleTextChange}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>
              <b>Retención</b>
            </Typography>
            <TextField
              style={{ maxWidth: 80 }}
              name="retentionRate"
              type="number"
              value={retentionRate}
              onChange={handleTextChange}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="caption">{errorMessage}</Typography>
        <Button disabled={creationLoading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={creationLoading} onClick={handleConfirm} color="primary" autoFocus>
          Confirmar
          {creationLoading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateOrEditWhitelist.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreateOrEditWhitelist: PropTypes.func.isRequired,
  whitelist: PropTypes.shape(),
  editing: PropTypes.bool,
};

CreateOrEditWhitelist.defaultProps = {
  whitelist: null,
  editing: false,
};

export default CreateOrEditWhitelist;
