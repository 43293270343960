import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useLocation, Redirect } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  LinearProgress,
  IconButton,
  Badge,
  Tooltip,
  Select,
  MenuItem,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Message, Cancel, CheckCircle, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../components/tables';
import { OneButtonAlert, AddCollectionActions } from '../components/dialogs';
import { UserAvatar, DateWithDaysLeft } from '../components/utils';
import { CollectionActionsDrawer, CollectionSendMail } from '../components/collection';
import { useStyles } from './styles';
import {
  GET_COLLECTION_ORDERS,
  GET_COLLECTION_INVOICES,
  ASSIGN_COLLECTOR,
  ADD_DATA_FOR_COLLECTION,
  ADD_COLLECTION_REMINDER,
  ADD_COLLECTION_ACTION,
  DEBTOR_PAYMENT_BEHAVIOUR_ENUM,
  SET_DEBTOR_PAYMENT_BEHAVIOUR,
} from '../gql/collection';
import { formatRut } from '../helpers/generic';
import CustomerDocumentsMenu from '../components/customers/DocumentsMenu';
import { formatMoney } from '../helpers/money';
import { getTimePassedTextFromTime } from '../helpers/date';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import { transformInvoiceToRowLikeObjects, transformOrdersToRowLikeObjects } from '../helpers/collection';
import { statusToSpanish } from '../helpers/orderingStatus';
import { ReintegrationTooltip, FundingTooltip } from '../components/collection/Tooltips';
import DebtorCell from '../components/shared/DebtorCell';
import useDebounce from '../helpers/useDebounce';

// Ids are used to sort
const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'amount', label: 'Monto', align: 'right' },
  { id: 'paymentDate', label: 'Fecha de Pago', align: 'left' },
  { id: 'dateOperation', label: 'Fecha de operación', align: 'left' },
  { id: 'debt', label: 'Mora acumulada', align: 'right' },
  { id: 'collector', label: 'Cobrador', align: 'center' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'documents', label: 'Documentos', align: 'center' },
  { id: 'debtorPaymentBehaviour', label: 'Tipo de Deudor', align: 'right', filterBy: 'debtorBehaviourFilter' },
  { id: 'classification', label: 'Clasificación', align: 'right' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
  { id: 'actionsData', label: 'Gestiones', align: 'center' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
];

const orderHeaders = [
  { id: 'orderNumber', label: 'Número de orden', align: 'left' },
  { id: 'company', label: 'Cliente', align: 'center' },
  { id: 'purchaser', label: 'Comprador', align: 'center' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'financedAmount', label: 'Monto financiado', align: 'center' },
  { id: 'invoiceIssuedDate', label: 'Emisión de factura', align: 'center' },
  { id: 'dateOperation', label: 'Fecha de operación', align: 'center' },
  { id: 'debt', label: 'Mora acumulada', align: 'center' },
  { id: 'status', label: 'Estado OC', align: 'center' },
  { id: 'timeInStage', label: 'Tiempo en esta etapa', align: 'center' },
];

const useOwnStyles = makeStyles((theme) => ({
  onTimeBadge: {
    backgroundColor: theme.palette.success.main,
  },
  littleLateBadge: {
    backgroundColor: theme.palette.warning.main,
  },
  lateBadge: {
    backgroundColor: theme.palette.error.main,
  },
  lightBlue: {
    color: '#6495ED',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'right',
  },
}));

const Collection = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const debounce = useDebounce(setSearchValue, 500);
  const [collectionOrders, setCollectionOrders] = useState([]);
  const [, setCollectionInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(true);
  const [receiver, setReceiver] = useState({ name: null, rut: null });
  const [company, setCompany] = useState({ name: null, rut: null });
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [sendMailOpen, setSendMailOpen] = useState(false);
  const [actionsDrawerInvoice, setActionsDrawerInvoice] = useState({
    id: '',
    name: '',
    folio: 0,
    amount: 0,
    nameReceiver: '',
    actions: [],
    rutReceiver: 0,
    companyRut: 0,
    companyName: '',
    managerId: null,
    dataForCollection: {
      dateToPay: null,
      paymentMethod: null,
      collectionContacts: [],
    },
    reminder: {
      reminderDate: null,
      reminderNote: null,
    },
  });
  const [getCollectionOrders] = useLazyQuery(GET_COLLECTION_ORDERS, {
    onCompleted: (data) => {
      const transformedOrders = transformOrdersToRowLikeObjects(data.getCollectionOrders);
      setCollectionOrders(transformedOrders);
    },
  });
  const {
    data: collectionInvoicesData,
    loading: collectionInvoicesLoading,
    refetch: collectionInvoicesRefetch,
    error: collectionInvoicesError,
  } = useQuery(GET_COLLECTION_INVOICES, {
    variables: {
      searchValue,
    },
  });
  const {
    data: debtorPaymentBehaviourEnum,
    loading: loadingDebtorPaymentBehaviourEnum,
  } = useQuery(DEBTOR_PAYMENT_BEHAVIOUR_ENUM);
  const [assignCollector] = useMutation(ASSIGN_COLLECTOR);
  const [addDataForCollection] = useMutation(ADD_DATA_FOR_COLLECTION);
  const [addCollectionReminder] = useMutation(ADD_COLLECTION_REMINDER);
  const [addCollectionAction] = useMutation(ADD_COLLECTION_ACTION);
  const [setDebtorPaymentBehaviour] = useMutation(SET_DEBTOR_PAYMENT_BEHAVIOUR);

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const updateAddActionState = (rows) => {
    const notEmpty = rows.length > 0;
    const sameReceiver = notEmpty && rows.every((row) => row.rutReceiver === rows[0].rutReceiver);
    const sameCompany = notEmpty && rows.every((row) => row.rut === rows[0].rut);
    setActionButtonDisabled(!sameReceiver && !sameCompany);
    if (sameReceiver) setReceiver({ rut: rows[0].rutReceiver, name: rows[0].nameReceiver });
    else setReceiver({ rut: null, name: null });
    if (sameCompany) setCompany({ rut: rows[0].rut, name: rows[0].name });
    else setCompany({ rut: null, name: null });
  };

  useEffect(() => {
    if (location.state && location.state.refetch) collectionInvoicesRefetch();
  }, [location.state]);

  useEffect(() => {
    if (collectionInvoicesData) {
      const transformedInvoices = transformInvoiceToRowLikeObjects(
        collectionInvoicesData.getCollectionInvoices,
      );
      setCollectionInvoices(transformedInvoices);
      setFilteredInvoices(transformedInvoices);
      if (actionsDrawerInvoice.folio) {
        const openedInvoice = collectionInvoicesData.getCollectionInvoices.find((element) => (
          element.id === actionsDrawerInvoice.managerId
        ));
        if (openedInvoice) {
          setActionsDrawerInvoice((oldInvoice) => ({
            ...oldInvoice,
            actions: openedInvoice.actions,
            dataForCollection: openedInvoice.currentDataForCollection,
            reminder: openedInvoice.reminder,
          }));
        } else {
          setActionsDrawerOpen(false);
        }
      }
    }
  }, [collectionInvoicesData]);

  useEffect(() => {
    updateAddActionState(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (tab === 1) {
      getCollectionOrders();
    }
  }, [tab]);

  const onAssignCollector = async (collectionManagerId) => {
    try {
      const { data: { assignCollector: {
        collector,
      } } } = await assignCollector({ variables: { collectionManagerId } });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          if (row.id === collectionManagerId) return { ...row, collector: collector.firstName };
          return row;
        }));
      setCollectionInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
    } catch (err) {
      setErrorMessage('Hubo un error al intentar asignarte como ratificador de esta factura. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const onClickActions = ({
    invoiceId,
    folio,
    nameReceiver,
    actions,
    rutReceiver,
    rut,
    name,
    dataForCollection,
    reminder,
    managerId,
    amount,
    forReintegration,
  }) => {
    setActionsDrawerInvoice({
      id: invoiceId,
      folio,
      amount,
      nameReceiver,
      actions,
      rutReceiver,
      companyRut: rut,
      companyName: name,
      dataForCollection,
      reminder,
      managerId,
      forReintegration,
    });
    setActionsDrawerOpen(true);
  };

  const onCloseActionsDrawer = () => {
    setActionsDrawerOpen(false);
    setActionsDrawerInvoice({
      name: '',
      id: null,
      folio: '',
      amount: 0,
      nameReceiver: '',
      receiverId: null,
      actions: [],
      rutReceiver: 0,
      companyRut: 0,
      companyName: '',
      managerId: null,
      dataForCollection: {
        dateToPay: null,
        paymentMethod: null,
        collectionContacts: [],
      },
      reminder: {
        reminderDate: null,
        reminderNote: null,
      },
    });
  };

  const onSendDataForCollection = async (dateToPay, paymentMethod, collectionContactIds) => {
    const collectionManagerId = actionsDrawerInvoice.managerId;
    try {
      const { data: { addDataForCollection: {
        addedDataForCollection,
      } } } = await addDataForCollection({
        variables: {
          collectionManagerId,
          dateToPay,
          paymentMethod,
          collectionContactIds,
        },
      });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          if (row.id === collectionManagerId) {
            return { ...row, dataForCollection: addedDataForCollection };
          }
          return row;
        }));
      setCollectionInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
      setActionsDrawerInvoice((oldData) => ({
        ...oldData, dataForCollection: addedDataForCollection,
      }));
    } catch (error) {
      setErrorMessage('Hubo un error al intentar enviar la información. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const onSendCollectionReminder = async (reminderDatetime, reminderChannel, reminderNote) => {
    const collectionManagerId = actionsDrawerInvoice.managerId;
    try {
      const { data: { addCollectionReminder: {
        addedReminder,
      } } } = await addCollectionReminder({
        variables: {
          collectionManagerId,
          reminderDatetime,
          reminderChannel,
          reminderNote,
        },
      });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          if (row.id === collectionManagerId) return { ...row, reminder: addedReminder };
          return row;
        }));
      setCollectionInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
      setActionsDrawerInvoice((oldData) => ({
        ...oldData, reminder: addedReminder,
      }));
    } catch (error) {
      setErrorMessage('Hubo un error al intentar programar el recordatorio. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const onSendCollectionAction = async (actionType, comment, collectionContactIds) => {
    const collectionManagerIds = selectedRows.map((row) => row.id);
    try {
      const { data: { addCollectionAction: {
        addedAction,
      } } } = await addCollectionAction({
        variables: {
          collectionManagerIds,
          actionType,
          comment,
          collectionContactIds,
        },
      });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          if (collectionManagerIds.includes(row.id)) {
            return { ...row, actions: [addedAction, ...row.actions] };
          }
          return row;
        }));
      setCollectionInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
      setActionsDrawerInvoice((oldData) => ({
        ...oldData, actions: [addedAction, ...oldData.actions],
      }));
      setAddActionOpen(false);
    } catch (error) {
      setAddActionOpen(false);
      setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const handleBehaviourChange = async (newBehaviour, masterEntityId) => {
    await setDebtorPaymentBehaviour({
      variables: {
        newBehaviour,
        masterEntityId,
      },
    });
    await collectionInvoicesRefetch();
  };

  const handleBehaviourColor = (debtorPaymentBehaviour) => {
    if (debtorPaymentBehaviour === 'GOOD' || debtorPaymentBehaviour === 'VERY_GOOD') {
      return classes.success;
    } if (debtorPaymentBehaviour === 'IN_PROBLEMS' || debtorPaymentBehaviour === 'IN_SEVERE_PROBLEMS') {
      return classes.red;
    } if (debtorPaymentBehaviour === 'BAD_LATE_PAYER' || debtorPaymentBehaviour === 'NEW') {
      return classes.warningColor;
    }
    return '';
  };

  const transformOrdersToComponent = (_data) => _data.map((row) => {
    const {
      managerId,
      orderId,
      status,
      orderNumber,
      purchaserName,
      purchaserRut,
      companyName,
      companyRut,
      executiveAssigned,
      invoiceIssuedDate,
      amount,
      financedAmount,
      orderingDebt,
      dateOperation,
      timeInStage,
    } = row;
    return ({
      managerId,
      orderId,
      orderNumber: {
        value: orderNumber,
        component: (
          <Typography>{orderNumber}</Typography>
        ),
      },
      company: {
        value: companyName,
        component: (
          <>
            <Typography>{companyName}</Typography>
            <Typography>{formatRut(companyRut)}</Typography>
          </>
        ),
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      purchaser: {
        value: purchaserName,
        component: (
          <>
            <Typography>{purchaserName}</Typography>
            <Typography>{formatRut(purchaserRut)}</Typography>
          </>
        ),
      },
      invoiceIssuedDate: {
        value: invoiceIssuedDate,
        component: (
          <DateWithDaysLeft date={moment(invoiceIssuedDate)} />
        ),
      },
      dateOperation: {
        value: dateOperation,
        component: (
          <Typography>{moment(dateOperation).format('DD-MM-YYYY')}</Typography>
        ),
      },
      amount: {
        value: amount,
        component: (
          <Typography>${formatMoney(amount.amount)}</Typography>
        ),
      },
      financedAmount: {
        value: financedAmount,
        component: (
          <Typography>${formatMoney(financedAmount.amount)}</Typography>
        ),
      },
      debt: {
        value: orderingDebt?.debt,
        component: (
          <Typography>${formatMoney(orderingDebt?.debt.amount)}</Typography>
        ),
      },
      status: {
        value: status,
        component: (
          <Typography>{statusToSpanish[status]}</Typography>
        ),
      },
      timeInStage: {
        value: timeInStage,
        component: (
          <Typography align="center">{getTimePassedTextFromTime(timeInStage)}</Typography>
        ),
      },
    });
  });

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      collector,
      folio,
      isRatificated,
      rutReceiver,
      nameReceiver,
      receiverId,
      paymentDate,
      dateOperation,
      executiveAssigned,
      amount,
      debt,
      isFunding,
      pdf,
      rut,
      name,
      timeInStage,
      objectPurchaseOrder,
      classification,
      actions,
      dataForCollection,
      certificateUrl,
      documents,
      forReintegration,
      debtorPaymentBehaviour,
      siiStatus,
      creditNoteStatus,
      dateIssued,
    } = row;
    const lastAction = actions.length > 0 ? moment(actions.slice(-1)[0].createdAt) : null;

    const getBadgeClass = () => {
      if (!dataForCollection) return null;
      const debtorGivenDateToPay = moment(dataForCollection.dateToPay);
      const clientGivenDateToPay = moment(paymentDate);
      const daysDiff = clientGivenDateToPay.diff(debtorGivenDateToPay, 'days');
      if (daysDiff < -6) return classes.lateBadge;
      if (daysDiff < 0) return classes.littleLateBadge;
      return classes.onTimeBadge;
    };

    const {
      label: siiLabel,
      color: siiColor,
    } = siiStatusToProps(dateIssued, creditNoteStatus)[siiStatus];

    return ({
      id,
      rut,
      collectionManagerId: id,
      name,
      rutReceiver,
      nameReceiver,
      dataForCollection,
      folio: {
        component: (
          <div className={classes.row}>
            <Tooltip title="Ratificada">
              {isRatificated
                ? <CheckCircle className={classes.success} />
                : <Cancel className={classes.failure} />}
            </Tooltip>
            <Typography>{folio}</Typography>
            {isFunding.value && (<FundingTooltip socialReason={isFunding.socialReason} />)}
            {forReintegration && (<ReintegrationTooltip />)}
          </div>
        ),
      },
      client: {
        component: (
          <>
            <Tooltip title={name}>
              <div style={{ maxWidth: 150, overflow: 'hidden' }}>
                <Typography variant="body2" noWrap>
                  {name}
                </Typography>
                <Typography variant="body2">
                  {formatRut(rut)}
                </Typography>
              </div>
            </Tooltip>
          </>
        ),
      },
      debtor: {
        // <Tooltip title={nameReceiver}>
        //   <div style={{ maxWidth: 150, overflow: 'hidden' }}>
        //     <Typography variant="body2" noWrap>
        //       {nameReceiver}
        //     </Typography>
        //     <Typography variant="body2">
        //       {formatRut(rutReceiver)}
        //     </Typography>
        //   </div>
        // </Tooltip>
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
      },
      paymentDate: {
        component: (
          <DateWithDaysLeft date={moment(paymentDate)} />
        ),
      },
      dateOperation: {
        component: (
          <Typography> {moment(dateOperation).format('DD-MM-YYYY')}</Typography>
        ),
      },
      amount: {
        component: (
          <Typography align="right">${formatMoney(amount.amount)}</Typography>
        ),
      },
      debt: {
        component: (
          <Typography align="right">${formatMoney(debt.amount)}</Typography>
        ),
      },
      collector: {
        component: (
          <UserAvatar
            name={collector}
            onAssign={() => onAssignCollector(id)}
            actionName="cobranza"
          />
        ),
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>),
      },
      documents: {
        component: (
          <CustomerDocumentsMenu
            uploadOption={false}
            purchaseOrder={objectPurchaseOrder}
            pdf={pdf}
            certificate={certificateUrl}
            companyRut={rut}
            documents={documents}
          />
        ),
      },
      debtorPaymentBehaviour: {
        value: debtorPaymentBehaviour,
        component: (
          <Select
            id="debtorPaymentBehaviour"
            value={debtorPaymentBehaviour || 'Seleccione'}
            onChange={(event) => handleBehaviourChange(event.target.value, rutReceiver)}
            variant="outlined"
            className={handleBehaviourColor(debtorPaymentBehaviour)}
          >
            <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un tipo</MenuItem>
            {!loadingDebtorPaymentBehaviourEnum && (
              debtorPaymentBehaviourEnum.getDebtorPaymentBehaviourEnum.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              )))}
          </Select>
        ),
      },
      classification: {
        component: (
          <Typography align="right">{classification}</Typography>
        ),
      },

      timeInStage: {
        component: (
          <Typography align="right">{timeInStage ? getTimePassedTextFromTime(timeInStage) : ''}</Typography>
        ),
      },
      actionsData: {
        component: (
          <div className={classes.columnDirection}>
            <IconButton onClick={() => onClickActions(row)}>
              <Badge
                badgeContent={actions.length}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Badge
                  badgeContent=" "
                  classes={{
                    badge: getBadgeClass(),
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Message />
                </Badge>
              </Badge>
            </IconButton>
            {lastAction && (
              <Typography variant="caption">{lastAction.format('DD/MM/YYYY HH:mm')}</Typography>
            )}
          </div>
        ),
      },
      integrationStatus: {
        value: siiLabel,
        component: (
          <div className={classes.center}>
            <Typography style={{ color: siiColor }}>
              {siiLabel}
            </Typography>
          </div>
        ),
      },
    });
  });
  if (permissionDeniedErrorCatcher(collectionInvoicesError)) {
    return <Redirect to="/" />;
  }
  return (
    <>
      {collectionInvoicesLoading && (
        <LinearProgress />
      )}
      <div className={classes.container}>
        <OneButtonAlert
          open={errorMessageOpen}
          onClose={() => setErrorMessageOpen(false)}
          message={errorMessage}
          title="Error"
        />
        <AddCollectionActions
          open={addActionOpen}
          onClose={() => setAddActionOpen(false)}
          nameReceiver={receiver.name}
          rutReceiver={receiver.rut}
          companyRut={company.rut}
          companyName={company.name}
          onAdd={onSendCollectionAction}
        />
        <CollectionActionsDrawer
          open={actionsDrawerOpen}
          onClose={onCloseActionsDrawer}
          invoiceData={actionsDrawerInvoice}
          onSendDataForCollection={onSendDataForCollection}
          onSendCollectionReminder={onSendCollectionReminder}
          collectionInvoicesRefetch={collectionInvoicesRefetch}
        />
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(e, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Facturas en cobranza</Typography>} />
          <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Órdenes de compra pendientes de factura</Typography>} />
        </Tabs>
        {tab === 0 && (
          <>
            <div className={classes.headerContainer}>
              <div className={classes.headerButtonsContainer}>
                <Button
                  className={classes.mainColorButton}
                  onClick={() => setAddActionOpen(true)}
                  color="inherit"
                  variant="contained"
                  disabled={actionButtonDisabled}
                >
                  Añadir gestión
                </Button>
                <Button
                  className={classes.blueButton}
                  onClick={() => setSendMailOpen(true)}
                  color="inherit"
                  variant="contained"
                  disabled={actionButtonDisabled}
                >
                  Enviar correo
                </Button>
              </div>
            </div>
            <Grid container>
              <Grid item xs={6} sm={6} className={classes.searchBar}>
                <TextField
                  name="search"
                  onChange={({ target: { value } }) => debounce(() => {
                    setSelectedRows([]);
                    setSearchValue(value);
                  })}
                  variant="outlined"
                  placeholder="Busqueda por rut de cliente, deudor o folio"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="timeInStage"
                  withSelect
                  handleSelected={handleSelectedRows}
                  setNewDataOrder={setFilteredInvoices}
                  data={filteredInvoices}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          </>
        )}
        {tab === 1 && (
          collectionOrders.length > 0 ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} className={classes.tableContainer}>
                  <Table
                    headers={orderHeaders}
                    initialOrderId="timeInStage"
                    withSelect={false}
                    setNewDataOrder={setCollectionOrders}
                    data={collectionOrders}
                    dataToRows={transformOrdersToComponent}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography align="center" variant="h5">No hay facturas pendientes para cobrar</Typography>
          )
        )}
        { sendMailOpen && (
          <CollectionSendMail
            open={sendMailOpen}
            setOpen={setSendMailOpen}
            selectedInvoices={selectedRows}
            nameReceiver={receiver.name}
            rutReceiver={receiver.rut}
          />
        )}
      </div>
    </>
  );
};

export default Collection;
