import { useMutation } from '@apollo/client';
import ReplayIcon from '@material-ui/icons/Replay';
import { IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Checkbox,
  FormGroup,
  DialogActions,
  Button,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RERUN_EVALUATION_REQUEST } from '../../gql/riskAlgorithm';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  rutsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  title: {
    alignContent: 'center',
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignCheckbox: {
    margintop: '0px',
    paddingtop: '0px',
  },
});

const RerunRiskMenu = ({
  disabled,
  invoiceId,
  refetch,
}) => {
  const [openWindow, setOpenWindow] = useState(false);
  const [clientDicom, setClientDicom] = useState(false);
  const [debtorDicom, setDebtorDicom] = useState(false);
  const [clientBusinessProfile, setClientBusinessProfile] = useState(false);
  const [debtorBusinessProfile, setDebtorBusinessProfile] = useState(false);
  const [clientCurrentBehavior, setClientCurrentBehavior] = useState(false);
  const [debtorCurrentBehavior, setDebtorCurrentBehavior] = useState(false);
  const classes = useStyles();
  const [rerunEvaluationRequest, { loading }] = useMutation(RERUN_EVALUATION_REQUEST,
    { onCompleted: () => {
      setOpenWindow(false);
      refetch();
    } });

  const executeEvaluationRequest = () => {
    rerunEvaluationRequest({
      variables: {
        invoiceId,
        clientDicom,
        debtorDicom,
        clientBusinessProfile,
        debtorBusinessProfile,
        clientCurrentBehavior,
        debtorCurrentBehavior,
      },
    });
  };
  return (
    <>
      <IconButton onClick={() => setOpenWindow(true)} disabled={disabled}>
        <ReplayIcon />
      </IconButton>
      <Dialog open={openWindow} onClose={() => setOpenWindow(false)}>
        <DialogTitle className={classes.title}><b>Reejecutar Evaluación de Riesgo</b></DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={clientDicom} onClick={(e) => setClientDicom(e.target.checked)} />} label="Actualizar Dicom Cliente" />
            <FormControlLabel control={<Checkbox checked={debtorDicom} onClick={(e) => setDebtorDicom(e.target.checked)} />} label="Actualizar Dicom Deudor" />
            <FormControlLabel control={<Checkbox checked={clientBusinessProfile} onClick={(e) => setClientBusinessProfile(e.target.checked)} />} label="Actualizar Perfil Comercial Cliente" />
            <FormControlLabel control={<Checkbox checked={debtorBusinessProfile} onClick={(e) => setDebtorBusinessProfile(e.target.checked)} />} label="Actualizar Perfil Comercial Deudor" />
            <FormControlLabel control={<Checkbox checked={clientCurrentBehavior} onClick={(e) => setClientCurrentBehavior(e.target.checked)} />} label="Actualizar Comportamiento Vigente Cliente" />
            <FormControlLabel control={<Checkbox checked={debtorCurrentBehavior} onClick={(e) => setDebtorCurrentBehavior(e.target.checked)} />} label="Actualizar Comportamiento Vigente Deudor" />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          { loading && <CircularProgress size={16} />}
          <Button color="primary" onClick={() => setOpenWindow(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => executeEvaluationRequest()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RerunRiskMenu.propTypes = {
  disabled: PropTypes.bool.isRequired,
  invoiceId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default RerunRiskMenu;
