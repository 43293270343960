/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { formatMoney } from '../../helpers/money';
import { DefaultDict } from '../../helpers/dataStructures';
import { turnoverKeyToSpanish /* addressTypeToSpanish */ } from '../../helpers/translators';
import { GET_COMPANY_DETAILED_SCORING } from '../../gql/scoring';

const useStyles = makeStyles({
  container: {
    minWidth: 500,
    maxHeight: 500,
    overflowY: 'scroll',
  },
  section: {
    marginBottom: '2rem',
  },
  sectionHeader: {
    marginBottom: '1rem',
  },
  subSection: {
    marginBottom: '1rem',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Scoring = ({ rut }) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_COMPANY_DETAILED_SCORING, { variables: { rut } });

  if (loading) return <LinearProgress />;
  if (error) return <Typography>No tenemos la data de esta empresa</Typography>;
  const {
    getCompanyDetailScoring: {
      // defaults,
      directDebt,
      indirectDebt,
      leasing,
      size,
      billing,
      stability,
      traceability,
    },
  } = data;

  const parseMonthlyTurnover = (_billing) => {
    const parsedBilling = new DefaultDict(Array);
    _billing.forEach((element) => {
      const {
        monthlyAmount,
        month,
        // bankingAssignmentAmount,
        // nonBankingAssignmentAmount,
        // creditNotesAmount,
      } = element;
      parsedBilling.monthlyAmount.push(monthlyAmount);
      parsedBilling.date.push(moment(month, 'YYYYMM'));
      // Object.keys(element).forEach((key) => {
      //   parsedBilling[key].push(element[key]);
      // });
      // const bankingAssignedOverTotal = (
      //   (bankingAssignmentAmount / totalInvoiceAmount) * 100
      // ).toFixed(2);
      // const nonBankingAssignedOverTotal = (
      //   (nonBankingAssignmentAmount / totalInvoiceAmount) * 100
      // ).toFixed(2);
      // const creditOverTotal = ((creditNotesAmount / totalInvoiceAmount) * 100).toFixed(2);
      // parsedBilling.bankingAssignedOverTotal.push(`${bankingAssignedOverTotal}%`);
      // parsedBilling.nonBankingAssignedOverTotal.push(`${nonBankingAssignedOverTotal}%`);
      // parsedBilling.creditOverTotal.push(`${creditOverTotal}%`);
    });
    return parsedBilling;
  };

  const parsedBilling = parseMonthlyTurnover(billing);

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Typography className={classes.sectionHeader} variant="h6">Cumplimiento</Typography>
        {/* <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Impagos</b></Typography>
            <Typography><b>{defaults.total}</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Menores a 6 meses</Typography>
            <Typography>{defaults.lt6Months}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Entre 6 meses y 1 año</Typography>
            <Typography>{defaults.between6MonthsAnd1Year}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Mayores a 1 año</Typography>
            <Typography>{defaults.gt1Year}</Typography>
          </div>
        </div> */}
        <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Deuda directa</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Vigente</Typography>
            <Typography>${formatMoney(directDebt.current)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>De consumo</Typography>
            <Typography>${formatMoney(directDebt.consumption)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Comercial</Typography>
            <Typography>${formatMoney(directDebt.comercial)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Hipoteca</Typography>
            <Typography>${formatMoney(directDebt.mortgage)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Vencida</Typography>
            <Typography>${formatMoney(directDebt.overdue)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Castigada</Typography>
            <Typography>${formatMoney(directDebt.punished)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Morosa</Typography>
            <Typography>${formatMoney(directDebt.badDebt)}</Typography>
          </div>
        </div>
        <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Deuda indirecta</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Vigente</Typography>
            <Typography>${formatMoney(indirectDebt.current)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Vencida</Typography>
            <Typography>${formatMoney(indirectDebt.overdue)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Castigada</Typography>
            <Typography>${formatMoney(indirectDebt.punished)}</Typography>
          </div>
        </div>
        <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Leasing</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Vigente</Typography>
            <Typography>${formatMoney(leasing.current)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Morosa</Typography>
            <Typography>${formatMoney(leasing.badDebt)}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <Typography className={classes.sectionHeader} variant="h6">Respaldo</Typography>
        <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Tamaño</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Ventas último año</Typography>
            <Typography>${formatMoney(size.sales)}</Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography>Tramo</Typography>
            <Typography>{size.salesLeg}</Typography>
          </div>
          {/* <div className={classes.infoContainer}>
            <Typography>Clientes activos último año</Typography>
            <Typography>{size.annualClients}</Typography>
          </div> */}
        </div>
      </div>
      <div className={classes.section}>
        <Typography className={classes.sectionHeader} variant="h6">Facturación</Typography>
        <div className={classes.subSection}>
          <table>
            <thead>
              <tr>
                <th />
                {parsedBilling.date.map((date) => <th key={date}>{date.format('MM/YY')}</th>)}
              </tr>
            </thead>
            <tbody>
              {Object.keys(parsedBilling).filter((key) => key !== 'date').map((key) => (
                <tr key={key}>
                  <td>{turnoverKeyToSpanish[key]}</td>
                  {parsedBilling[key].map((value, i) => <td key={i}>${formatMoney(value)}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.section}>
        <Typography className={classes.sectionHeader} variant="h6">Estabilidad</Typography>
        <div className={classes.subSection}>
          <div className={classes.infoContainer}>
            <Typography><b>Año inicio de actividades</b></Typography>
            <Typography><b>{stability.startYear}</b></Typography>
          </div>
          {/* <div className={classes.infoContainer}>
            <Typography><b>Fecha primera factura</b></Typography>
            <Typography><b>
              {moment(stability.firstInvoiceDate, 'YYYYMMDD').format('DD [de] MMMM [de] YYYY')}
            </b></Typography>
          </div> */}
          <div className={classes.infoContainer}>
            <Typography><b>Avalúo propiedades</b></Typography>
            <Typography><b>${formatMoney(stability.properties)}</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography><b>Deuda hipotecaria</b></Typography>
            <Typography><b>${formatMoney(stability.mortgageDebt)}</b></Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography><b>Propiedades netas</b></Typography>
            <Typography>
              <b>${formatMoney(stability.netProperties)}</b>
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <Typography className={classes.sectionHeader} variant="h6">Trazabilidad</Typography>
        <div className={classes.subSection}>
          {traceability.website && traceability.website.length > 0 && (
            <div className={classes.infoContainer}>
              <Typography><b>Sitio web</b></Typography>
              <Typography><b>{traceability.website[0]}</b></Typography>
            </div>
          )}
          <div className={classes.infoContainer}>
            <Typography><b>Dirección</b></Typography>
            <Typography><b>{traceability.address}</b></Typography>
          </div>
          {/* <div className={classes.infoContainer}>
            <Typography><b>Tipo de dirección</b></Typography>
            <Typography><b>{addressTypeToSpanish[traceability.addressType]}</b></Typography>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Scoring.propTypes = {
  rut: PropTypes.number.isRequired,
};

export default Scoring;
