import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { Table } from '../tables';
import { GET_PENDING_NEGOTIATIONS } from '../../gql/negotiation';
import { formatRut } from '../../helpers/generic';

const headers = [
  { id: 'client', label: 'Cliente', type: 'rut', isSearchable: true },
  { id: 'applicant', label: 'Solicitante', type: 'text', isSearchable: true },
  { id: 'type', label: 'Tipo', type: 'text', isSearchable: false, hideSortIcon: true },
  { id: 'requestDate', label: 'Fecha de solicitud', type: 'date', isSearchable: true },
  { id: 'requestsNumber', label: 'Facturas solicitadas', type: 'number', isSearchable: true, align: 'right' },
  { id: 'respondNegotiationButton', label: '', align: 'center' },
];

const beautyNegotiationType = {
  OFFER: 'Ofertas',
  PREOFFER: 'Pre ofertas',
};

const PendingNegotiations = ({ negotiationType, setLoading }) => {
  const history = useHistory();
  const {
    data: negotiationsData,
    loading: loadingNegotiations,
  } = useQuery(GET_PENDING_NEGOTIATIONS, { variables: { negotiationType }, fetchPolicy: 'network-only' });
  const [filteredNegotiations, setFilteredNegotiations] = useState([]);

  useEffect(() => {
    if (negotiationsData) {
      setFilteredNegotiations(negotiationsData.getPendingNegotiations);
    }
  }, [negotiationsData]);

  useEffect(() => {
    setLoading(loadingNegotiations);
  }, [loadingNegotiations]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      company: { rut: clientRut, name: clientName },
      applicant: { firstName: applicantFirstName, lastName: applicantLastName },
      negotiationType: negType,
      comment,
      createdAt,
      requestsNumber,
      negotiationRequests,
    } = row;
    const respondNegotiation = (
      negotiationId, negotiationType_, negotiationRequests_, comment_, clientName_, clientRut_,
    ) => (
      history.push({
        pathname: `/app/risk/rates-negotiation/${negotiationId}`,
        state: {
          negotiationId,
          negotiationType: negotiationType_,
          negotiationRequests: negotiationRequests_,
          comment: comment_,
          clientName: clientName_,
          clientRut: clientRut_,
        },
      }));
    return ({
      id,
      client: {
        value: clientRut,
        component: (
          <>
            <Typography>{clientName}</Typography>
            <Typography>{formatRut(clientRut)}</Typography>
          </>
        ),
        size: 'small',
      },
      applicant: {
        value: applicantFirstName,
        component: (
          <>
            <Typography>{`${applicantFirstName} ${applicantLastName}`}</Typography>
          </>
        ),
        size: 'small',
      },
      type: {
        value: negType,
        component: (
          <Typography>{beautyNegotiationType[negType] }</Typography>
        ),
        size: 'small',
      },
      requestDate: {
        value: createdAt,
        component: (
          <Typography>{moment(createdAt).format('DD-MM-YYYY HH:mm')}</Typography>
        ),
        size: 'small',
      },
      requestsNumber: {
        value: requestsNumber,
        component: (
          <Typography>{requestsNumber}</Typography>
        ),
        size: 'small',
      },
      respondNegotiationButton: {
        value: 'null',
        component: (
          <Button
            variant="contained"
            onClick={() => respondNegotiation(
              id, negotiationType, negotiationRequests, comment, clientName, clientRut,
            )}
            color="primary"
            size="small"
          >
            Responder
          </Button>
        ),
        size: 'small',
      },
    });
  });
  return (
    <Grid container>
      { (filteredNegotiations.length > 0)
        ? (
          <Table
            headers={headers}
            initialOrderId="requestsNumber"
            setNewDataOrder={setFilteredNegotiations}
            data={filteredNegotiations}
            dataToRows={transformDataToComponents}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            { !loadingNegotiations && <Typography variant="h4" align="center">No hay negociaciones pendientes</Typography>}
          </Grid>
        )}
    </Grid>

  );
};

PendingNegotiations.propTypes = {
  negotiationType: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default PendingNegotiations;
