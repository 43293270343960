/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import useDebounce from '../../helpers/useDebounce';
import { applySimpleSearch } from '../../helpers/filters';

const useStyles = makeStyles({
  textField: {
    backgroundColor: 'white',
    minWidth: 500,
  },
});

const Filters = ({ allData, setFilteredData, searchFields, searchPlaceholder, isControlled }) => {
  const debounce = useDebounce(isControlled.setGlobalFilter, 500);
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (!isControlled.isControlled) {
      if (allData.length > 0) {
        setFilteredData(applySimpleSearch(allData, value, searchFields));
      }
      return;
    }
    debounce(value);
  };

  return (
    <TextField
      name="search"
      onChange={handleSearchChange}
      value={searchValue}
      variant="outlined"
      className={classes.textField}
      placeholder={searchPlaceholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

Filters.propTypes = {
  allData: PropTypes.array.isRequired,
  setFilteredData: PropTypes.func.isRequired,
  searchFields: PropTypes.array.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  isControlled: PropTypes.shape({
    isControlled: PropTypes.bool,
    setGlobalFilter: PropTypes.func,
  }),
};

Filters.defaultProps = {
  isControlled: {
    isControlled: false,
    setGlobalFilter: () => {},
  },
};

export default Filters;
