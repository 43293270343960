import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $sendEmail: Boolean!
    $companyRut: String!
  ) {
    createUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      sendEmail: $sendEmail
      companyRut: $companyRut
    ){
      user {
        email
        companies {
          id
          masterEntity {
            rut
            name
          }
        }
      }
    }
  }
`;

export const REVOKE_REFRESH_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken){
      revoked
    }
  }
`;

export const linte = ''; // TODO: SACAR
