import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
  query getCompaniesRisk {
    getCompaniesRisk {
      rut
      name
      scoring
      electronicTaxFolder
      customer {
        id
        name
      }
      currentInvoices {
        totalSum
        totalNumber
      }
      totalOffered {
        totalSum
        totalNumber
      }
      totalLoaned {
        totalSum
        totalNumber
      }
      otherFactoring {
        name
        total
      }
    }
  }
`;

export const GET_PREOFFERS_REQUESTS = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $inCollection: Boolean = false
    $inRatification: Boolean = false
    $inEvaluation: Boolean = true
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inCollection: $inCollection
      inRatification: $inRatification
      inEvaluation: $inEvaluation
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          rut
          name
          riskBlacklist {
            id
          }
          creditLine {
            id
            creditUsed @client
            limitAmount @client
            lastMovement {
              id
              totalCreditUsed {
                amount
                currency {
                  code
                }
              }
              amount {
                amount
                currency {
                  code
                }
              }
            }
            currentLimit {
              newLimit {
                amount
                currency {
                  code
                }
              }
              id
            }
          }
          company {
            id
            amountOfPendingReintegrations
            amountDebtInvoices
            executiveAssigned {
              id
              firstName
              lastName
            }
            invoiceInEvaluation {
              receiver {
                id
                rut
              }
              amountWithIva {
                amount
                currency {
                  code
                }
              }
              preoffer {
                id
                preofferevaluationrequest {
                  id
                  createdAt
                }
              }
              orderingAssigned {
                purchaseOrder {
                  orderNumber
                }
                amount {
                  amount
                  currency {
                    code
                  }
                }
              }
            }
            orderingInEvaluation {
              id
              purchaser {
                id
                rut
              }
              totalAmount {
                amount
                currency {
                  code
                }
              }
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_EVALUATIONS = gql`
  query Invoices($companyRut: String, $hasEvaluatingPreoffer: Boolean) {
    invoices(company_MasterEntity_Rut: $companyRut, hasEvaluatingPreoffer: $hasEvaluatingPreoffer) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          folio
          company {
            id
            rut
            name
            amountOfPendingReintegrations
            amountDebtInvoices
            masterEntity {
              id
              riskBlacklist {
                id
              }
              creditLine {
                id
                creditUsed @client
                limitAmount @client
                lastMovement {
                  id
                  totalCreditUsed  {
                    amount
                    currency {
                      code
                    }
                  }
                  amount {
                    amount
                    currency {
                      code
                    }
                  }
                  createdAt
                }
                currentLimit {
                  newLimit  {
                    amount
                    currency {
                      code
                    }
                  }
                  id
                  createdAt
                }
              }
              ratificationBlacklist {
                id
                active
              }
            }
            currentIvaObservation {
              observation
              createdAt
            }
            currentDicomObservation {
              observation
              createdAt
            }
            currentCreditLine {
              totalAmount  {
                amount
                currency {
                  code
                }
              }
              approvedOverdraft  {
                amount
                currency {
                  code
                }
              }
              creditUsed  {
                amount
                currency {
                  code
                }
              }
            }
            executiveAssigned {
              firstName
              lastName
            }
            cessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
            companyRiskHistory {
              date
              percentageReceptionAcknowledgmentInvoices
              percentageCreditNotesInvoices
              percentageCessionsOverIssued
              percentageSalesConcentrationBiggestDebtor
              percentageSalesConcentration10PercentBiggestDebtor
              percentageRejectedInvoices
              averageIssuedInvoices
              largestLoanedInvoice3Months
              largestLoanedInvoice12Months
              amountDebtorsCeeded
              amountProviders
              amountDebtors
            }
          }
          receiver {
            id
            rut
            name
            riskBlacklist {
              id
            }
            creditLine {
              id
              creditUsed @client
              limitAmount @client
              lastMovement {
                id
                totalCreditUsed  {
                  amount
                  currency {
                    code
                  }
                }
                amount  {
                  amount
                  currency {
                    code
                  }
                }
                updatedAt
                createdAt
              }
              currentLimit {
                newLimit  {
                  amount
                  currency {
                    code
                  }
                }
                id
                updatedAt
                createdAt
              }
            }
            ratificationBlacklist {
              id
              active
            }
            receiverCessionsHistory {
              id
              cessionsNumber
              cessionsMoneyAmount
              historicCessionsNumber
              historicCessionsMoneyAmount
            }
            receiverRiskHistory {
              date
              averageReceptionAcknowledgmentInvoicesTime
              percentageReceptionAcknowledgmentInvoices
              percentageCreditNotesInvoices
              percentageRejectedInvoices
              averageRejectionInvoiceTime
            }
          }
          companyReceiverRiskHistory {
            date
            averageReceptionAcknowledgmentInvoicesTime
            percentageReceptionAcknowledgmentInvoices
            percentageCreditNotesInvoices
            percentageCessionsOverIssued
            averageRejectionInvoiceTime
            percentageRejectedInvoices
            averageIssuedInvoices
            largestLoanedInvoice3Months
            largestLoanedInvoice12Months
          }
          preoffer {
            id
            monthlyRate
            defaultRate
            retentionRate
            preofferevaluationrequest {
              id
            }
          }
          pdf
          certificateUrl
          integrationStatus
          creditNoteStatus
          siiStatus
          amountWithIva
          dateIssued
          dateExpiration
          lightningPaymentAuthorized @client
          documents {
            id
            url
            type {
              id
              name
              code
            }
          }
          objectPurchaseOrder {
            exists
            url
          }
          documentfinancestateSet {
            id
            estimatedPaymentDate
            paymentDate
            comments
            isAutomatic
            eventTime: date
            status {
              id
              code
              status
            }
            rejectionCode {
              id
              code
              reason
            }
          }
          receiverDocumentRequested {
            hesPercentage
            purchaseOrderPercentage
            invoicesAmount
          }
          riskEvaluationModel {
            treeClientResult
            treeDebtorResult
            treeClientDebtorResult
            rasClientResult
            rasDebtorResult
            rasClientDebtorResult
            ratification
            reason
            ruleResults {
              modelType
              rejects
              threshold
              name
              value
              prize
              logicalType
              labelName
              description
              valueParser
            }
          }
          companyReceiverCessionHistory {
            id
            cessionsNumber
            cessionsMoneyAmount
            historicCessionsNumber
            historicCessionsMoneyAmount
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_ORDERING_EVALUATIONS = gql`
  query purchaseOrders($company_MasterEntity_Rut_In: [String], $inEvaluation: Boolean) {
    purchaseOrders(
      company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In
      inEvaluation: $inEvaluation
    ) {
      edges {
        node {
          id
          orderNumber
          pdfFileUrl
          company {
            id
            rut
            name
            amountDebtInvoices
            companyRiskHistory {
              date
              percentageReceptionAcknowledgmentInvoices
              percentageCreditNotesInvoices
              percentageCessionsOverIssued
              percentageSalesConcentrationBiggestDebtor
              percentageSalesConcentration10PercentBiggestDebtor
              percentageRejectedInvoices
              averageIssuedInvoices
              largestLoanedInvoice3Months
              largestLoanedInvoice12Months
              amountDebtorsCeeded
              amountProviders
              amountDebtors
            }
            masterEntity {
              id
              riskBlacklist {
                id
              }
              creditLine {
                id
                creditUsed @client
                limitAmount @client
                lastMovement {
                  id
                  totalCreditUsed  {
                    amount
                    currency {
                      code
                    }
                  }
                  amount  {
                    amount
                    currency {
                      code
                    }
                  }
                  createdAt
                }
                currentLimit {
                  newLimit  {
                    amount
                    currency {
                      code
                    }
                  }
                  id
                  createdAt
                }
              }
            }
          }
          purchaser {
            rut
            name
            riskBlacklist {
              id
            }
            creditLine {
              id
              creditUsed @client
              limitAmount @client
              lastMovement {
                id
                totalCreditUsed  {
                  amount
                  currency {
                    code
                  }
                }
                amount  {
                  amount
                  currency {
                    code
                  }
                }
                createdAt
              }
              currentLimit {
                newLimit  {
                  amount
                  currency {
                    code
                  }
                }
                id
                createdAt
              }
            }
            ratificationBlacklist {
              id
              active
            }
            receiverRiskHistory {
              date
              averageReceptionAcknowledgmentInvoicesTime
              percentageReceptionAcknowledgmentInvoices
              percentageCreditNotesInvoices
              percentageRejectedInvoices
              averageRejectionInvoiceTime
            }
          }
          companyReceiverRiskHistory {
            date
            averageReceptionAcknowledgmentInvoicesTime
            percentageReceptionAcknowledgmentInvoices
            percentageCreditNotesInvoices
            percentageCessionsOverIssued
            averageRejectionInvoiceTime
            percentageRejectedInvoices
            averageIssuedInvoices
            largestLoanedInvoice3Months
            largestLoanedInvoice12Months
          }
          orderingsimulation {
            id
            invoiceDateToPay
            invoiceIssuedDate
            orderingMonthlyRate
            orderingDefaultRate
            orderingRetentionRate
            factoringMonthlyRate
            factoringDefaultRate
            factoringRetentionRate
          }
          totalAmount  {
            amount
            currency {
              code
            }
          }
          riskEvaluationModel {
            treeClientResult
            treeDebtorResult
            treeClientDebtorResult
            rasClientResult
            rasDebtorResult
            rasClientDebtorResult
            ratification
            reason
            ruleResults {
              modelType
              rejects
              threshold
              name
              value
              prize
              logicalType
              labelName
              description
              valueParser
            }
          }
        }
      }
    }
  }
`;

export const GET_CREDIT_LINE_REQUESTS = gql`
  query GetCreditLineEvaluationRequests($ruts: [String]) {
    getCreditLineEvaluationRequests(ruts: $ruts) {
      id
      company {
        id
        rut
        name
      }
      requestDate
      status
    }
  }
`;

export const GET_CREDIT_LINE = gql`
  query GetCompanyValidationVariables($rut: String!) {
    getCompanyValidationVariables(rut: $rut) {
      creditLine {
        totalAmount
        creditUsed
        approvedOverdraft
      }
    }
  }
`;

export const GET_CREDIT_LINES = gql`
  query GetCompanyCreditLine($ruts: [String]!) {
    getCompanyCreditLine(ruts: $ruts) {
      totalAmount
      creditUsed
      approvedOverdraft
      company {
        id
        rut
      }
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query getCompanyDocuments($rut: String!) {
    getCompanyDocuments(rut: $rut) {
      documentType
      exists
      lastFile
      lastDate
    }
  }
`;

export const GET_COMPANY_FRAMEWORK_CONTRACT = gql`
  query getCompanyFrameworkContract($rut: String!) {
    getCompanyFrameworkContract(companyRut: $rut)
  }
`;

export const CREATE_COMPANY_RESTRICTION = gql`
  mutation createCompanyRestriction($requestRestriction: [CompanyRestrictionInputType]) {
    createCompanyRestriction(requestRestriction: $requestRestriction) {
      success
      message
    }
  }
`;

export const GET_RESTRICTIONS_UNCREATED = gql`
  query getCompanyRestrictionUncreated($companyId: String!) {
    getCompanyRestrictionsUncreated(companyId: $companyId) {
      label
      value
    }
  }
`;

export const GET_PENDING_RESTRICTIONS = gql`
  query getPendingCompanyRestrictions($companyRut: String!) {
    getPendingCompanyRestrictions(companyRut: $companyRut) {
      label
      value
      otherRestriction
      fileAssociated
    }
  }
`;

export const RESOLVE_COMPANY_RESTRICTIONS = gql`
  mutation resolveCompanyRestrictions(
    $restrictionCompanyInput: CompanyRestrictionInputType!
    $associatedDocument: Upload
    $resolutionExplanation: String!
  ) {
    resolveCompanyRestrictions(
      restrictionCompanyInput: $restrictionCompanyInput
      associatedDocument: $associatedDocument
      resolutionExplanation: $resolutionExplanation
    ) {
      success
      message
    }
  }
`;

export const RISE_CREDIT_LINE = gql`
  mutation RiseCreditLine($amountToRise: Int!, $rut: String!) {
    riseCreditLine(amountToRise: $amountToRise, rut: $rut) {
      creditLine {
        totalAmount
        creditUsed
        approvedOverdraft
      }
    }
  }
`;

export const RISE_CREDIT_LINE_OVERDRAFT = gql`
  mutation RiseCreditLineOverdraft($amountToRise: Int!, $rut: String!) {
    riseCreditLineOverdraft(amountToRise: $amountToRise, rut: $rut) {
      creditLine {
        totalAmount
        creditUsed
        approvedOverdraft
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers(
    $searchValue: String
    $offset: Int
    $limit: Int
    $order: String
    $propertyToOrder: String
    $filterMetaValues: [FilterParamsType]
    $filterMetaBool: [FilterParamsBoolType]
  ) {
    getCustomers(
      searchValue: $searchValue
      queryMeta: {
        offset: $offset
        limit: $limit
        order: $order
        propertyToOrder: $propertyToOrder
      }
      filterMetaValues: $filterMetaValues
      filterMetaBool: $filterMetaBool
    ) {
      clients {
        masterEntity {
          rut
          name
        }
        currentCreditLine {
          creditUsed
          totalAmount
        }
        executiveAssigned {
          email
          firstName
          lastName
        }
        totalExposeAmount
        backofficeHasAcceptedTerms
        documents {
          exists
          lastFile
          lastDate
          documentType
        }
        validCredentials {
          id
          updatedAt
          username
          secondUsername
          credentialProvider {
            id
            name
            credentialProviderType {
              id
              name
            }
          }
        }
        hasSiiCredentials @client
        hasDigitalCertificate
        hasErut
      }
      length
    }
  }
`;

export const UPLOAD_COMPANY_FILES = gql`
  mutation uploadCompanyFiles($inputUploadFiles: [UploadFilesDocumentManagerInputType]!) {
    uploadCompanyFiles(inputUploadFiles: $inputUploadFiles) {
      success
      message
    }
  }
`;

export const CREATE_COMPANY_RL = gql`
  mutation createCompanyLegalRepresentative(
    $companyRut: String!
    $representativeName: String!
    $representativeRut: String!
    $phoneNumber: String!
    $email: String!
  ) {
    createCompanyLegalRepresentative(
      companyRut: $companyRut
      representativeName: $representativeName
      representativeRut: $representativeRut
      phoneNumber: $phoneNumber
      email: $email
    ) {
      success
      reason
    }
  }
`;

export const GET_COMPANY_CUSTOMERS_FOR_ASSIGNMENT = gql`
  query getCustomers(
    $searchValue: String
    $offset: Int
    $limit: Int
    $order: String
    $propertyToOrder: String
    $filterMetaValues: [FilterParamsType]
  ) {
    getCustomers(
      searchValue: $searchValue
      queryMeta: {
        offset: $offset
        limit: $limit
        order: $order
        propertyToOrder: $propertyToOrder
      }
      filterMetaValues: $filterMetaValues
    ) {
      clients {
        masterEntity {
          rut
          name
        }
        executiveAssigned {
          firstName
          lastName
          email
        }

        backofficeHasAcceptedTerms
      }
      length
    }
  }
`;

export const GET_COMPANY_COMMISSION_RULES = gql`
  query GetCompanyCommissionRules($rut: String!) {
    getCompanyCommissionRules(rut: $rut) {
      variant
      value
      threshold
      id
      expirationDate
    }
  }
`;

export const REMOVE_COMMISSION_RULE = gql`
  mutation RemoveCommissionRule($commissionRuleId: Int!, $companyRut: String!) {
    removeCommissionRule(commissionRuleId: $commissionRuleId, companyRut: $companyRut) {
      updatedCommissionRules {
        variant
        value
        threshold
        id
        expirationDate
      }
    }
  }
`;

export const ADD_COMMISSION_RULE = gql`
  mutation AddCommissionRule(
    $companyRut: String!
    $threshold: Float!
    $variant: CommisionRuleVariantEnum!
    $expirationDate: DateTime
    $value: Float!
  ) {
    addCommissionRule(
      companyRut: $companyRut
      input: {
        threshold: $threshold
        variant: $variant
        expirationDate: $expirationDate
        value: $value
      }
    ) {
      updatedCommissionRules {
        variant
        value
        threshold
        id
        expirationDate
      }
    }
  }
`;

export const ASSIGN_EXECUTIVE_TO_CLIENT = gql`
  mutation assignExecutive($companyRutList: [Int]!, $executiveMail: String!) {
    assignExecutiveClient(companyRutList: $companyRutList, executiveMail: $executiveMail) {
      success
    }
  }
`;

export const EDIT_COMMISSION_RULE = gql`
  mutation EditCommissionRule(
    $commissionRuleId: Int!
    $threshold: Float!
    $variant: CommisionRuleVariantEnum!
    $expirationDate: DateTime
    $value: Float!
  ) {
    editCommissionRule(
      commissionRuleId: $commissionRuleId
      input: {
        threshold: $threshold
        variant: $variant
        expirationDate: $expirationDate
        value: $value
      }
    ) {
      updatedCommissionRules {
        variant
        value
        threshold
        id
        expirationDate
      }
    }
  }
`;

export const GET_COMMERCIAL_EXECUTIVES = gql`
  query getCommercialExecutives {
    getCommercialExecutives {
      firstName
      lastName
      email
      id
    }
  }
`;

export const GET_RATIFICATION_EXECUTIVES = gql`
  query getRatificationExecutives {
    getRatificationExecutives {
      firstName
      lastName
      email
      id
    }
  }
`;

export const GET_COLLECTION_EXECUTIVES = gql`
  query getCollectionExecutives {
    getCollectionExecutives {
      firstName
      lastName
      email
      id
    }
  }
`;

export const GET_COMPANY_TGR = gql`
  mutation tgr($rut: String!) {
    getCompanyTgr(rut: $rut) {
      requestReceived
    }
  }
`;

export const GET_COMPANY_ERUT = gql`
  mutation erut($rut: String!) {
    getCompanyErut(rut: $rut) {
      message
    }
  }
`;

export const GET_URL_ERUT = gql`
  query getCompanyUrlErut($rut: String!) {
    getCompanyUrlErut(rut: $rut)
  }
`;

export const ADD_IVA_CHECK = gql`
  mutation CreateIvaObservation($companyRut: String!, $observation: String!) {
    createIvaObservation(companyRut: $companyRut, observation: $observation) {
      ivaCheck {
        observation
      }
    }
  }
`;

export const ADD_DICOM_CHECK = gql`
  mutation CreateDicomObservation($companyRut: String!, $observation: String!) {
    createDicomObservation(companyRut: $companyRut, observation: $observation) {
      dicomCheck {
        observation
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($rutWithDv: String!, $name: String!) {
    createCompany(rutWithDv: $rutWithDv, name: $name) {
      success
    }
  }
`;

export const SEND_CTE_SLACK = gql`
  query sendCteSlackMessage($rut: String!) {
    sendCteSlackMessage(rut: $rut)
  }
`;

export const ADD_COMPANY_TO_BLACKLIST = gql`
  mutation addCompanyToBlacklist($ruts: [String]!) {
    addCompanyToBlacklist(ruts: $ruts) {
      success
      reason
    }
  }
`;

export const REMOVE_COMPANY_FROM_BLACKLIST = gql`
  mutation removeCompanyFromBlacklist($ruts: [String]!) {
    removeCompanyFromBlacklist(ruts: $ruts) {
      success
    }
  }
`;

export const GET_COMPANY_BLACKLIST = gql`
  query masterEntities(
    $first: Int
    $offset: Int
    $globalFilter: String
    $inBlacklist: Boolean = true
  ) {
    getMasterEntities(
      first: $first
      offset: $offset
      globalFilter: $globalFilter
      inBlacklist: $inBlacklist
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          rut
          name
          riskBlacklist {
            createdAt
          }
        }
      }
    }
  }
`;

export const UPLOAD_INVOICE_DOCUMENTS = gql`
  mutation uploadInvoiceDocuments($invoiceId: Int!, $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]! ){
    uploadInvoiceDocuments(invoiceId: $invoiceId, inputUploadFiles: $inputUploadFiles){
      invoice {
        id
      }
    }
  }
`;

export const GET_COMMERCIAL_EXECUTIVES_INFORMATION = gql`
  query getExecutiveAssignmentInformation {
    getExecutiveAssignmentInformation {
      user {
        id
        firstName
        lastName
      }
      active
      hubspotId
      tycActiveAssignation
      loginActiveAssignation
      clickActiveAssignation
      clickAssignationProportion
      loginAssignationProportion
      weeklyAssignations
      monthlyAssignations
      hubspotMonthlyAssignations
    }
  }
`;

export const UPDATE_USER_EXECUTIVE = gql`
  mutation UpdateUserExecutive(
    $id: Int!
    $active: Boolean
    $hubspotId: Int
    $tycActiveAssignation: Boolean
    $loginActiveAssignation: Boolean
    $clickActiveAssignation: Boolean
  ) {
    updateUserExecutive(
      id: $id
      input: {
        active: $active
        hubspotId: $hubspotId
        tycActiveAssignation: $tycActiveAssignation
        loginActiveAssignation: $loginActiveAssignation
        clickActiveAssignation: $clickActiveAssignation
      }
    ) {
      updateUserExecutive {
        id
        active
        hubspotId
        tycActiveAssignation
        loginActiveAssignation
        clickActiveAssignation
      }
    }
  }
`;

export const UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION = gql`
  mutation UpdateUserExecutiveAssignationProportion(
    $assignationType: String!
    $input: [UserExecutiveAssignationInputType]!
  ) {
    updateUserExecutiveAssignationProportion(assignationType: $assignationType, input: $input) {
      success
      reason
    }
  }
`;
