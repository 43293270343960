import moment from 'moment';

export const mergeActionsInstallmentsCollectionManager = (invoice) => {
  const { actualAmortizationSchedule: { installments: installmentsInvoice } } = invoice;
  // eslint-disable-next-line max-len
  return installmentsInvoice.reduce((actionsInstallment, _installment) => {
    const {
      installmentManager: {
        collectionManager: { actions: actionsInstallmentCol } } } = _installment;
    return [...actionsInstallment, ...actionsInstallmentCol];
  }, []);
};

export const firstCollectionManager = (invoice) => {
  const { actualAmortizationSchedule: { installments } } = invoice;
  // eslint-disable-next-line max-len
  if (installments) {
    const { installmentManager: { collectionManager } } = installments[0];
    return collectionManager;
  }
  return null;
};

export const nextInstallmentExpirationDate = (_installments) => {
  const filterByActiveorDebt = _installments.filter((inst) => inst.status === 'ACTIVE' || inst.status === 'DEBT');
  const cmpSort = (ins1, ins2) => {
    const date1 = moment(ins1.expirationDate);
    const date2 = moment(ins2.expirationDate);
    if (date1 < date2) {
      return -1;
    }
    return 1;
  };
  const ordered = filterByActiveorDebt.sort(cmpSort);
  return ordered[0] ? ordered[0].expirationDate : 0;
};

export const transformDataFromNetwork = (_data) => {
  const transformedData = _data.map((invoiceData) => {
    const totalActions = mergeActionsInstallmentsCollectionManager(invoiceData);
    const {
      id,
      folio,
      amountWithIva,
      company: {
        name: nameClient,
        rut: clientRut,
        executiveAssigned,
      },
      actualAmortizationSchedule: {
        defaultRate,
        interestRate,
        status,
        createdAt: dateIssued,
        installments: installmentsToTransform,
      },
      receiver,
    } = invoiceData;
    const installments = installmentsToTransform.map((installment) => {
      const {
        installmentAmountToPay,
        expirationDate,
        id: idInstallment,
        installmentManager: {
          status: statusInstallmentManager,
          debtAmount,
          totalAmountToPay,
          collectionManager: {
            paymentDatetime,
            actions,
          },
        } } = installment;
      return {
        id: idInstallment,
        installmentAmountToPay,
        totalAmountToPay,
        expirationDate,
        status: statusInstallmentManager,
        debtAmount,
        paymentDatetime,
        actions,
      };
    });
    return {
      id,
      nameReceiver: receiver.masterEntity?.name ? receiver.masterEntity.name : receiver.name,
      rutReceiver: receiver.masterEntity?.rut ? receiver.masterEntity.rut : receiver.rut,
      dateIssued,
      nameClient,
      clientRut,
      executiveAssigned,
      folio,
      defaultRate,
      interestRate,
      status,
      installments,
      actions: totalActions,
      firstCollectionManager: firstCollectionManager(invoiceData),
      amountWithIva,
      nextInstallmentExpirationDate: nextInstallmentExpirationDate(installments),
    };
  });
  return transformedData;
};

export const initStateDrawerInvoice = {
  name: '',
  folio: '',
  amount: 0,
  nameReceiver: '',
  actions: [],
  rutReceiver: 0,
  companyRut: 0,
  companyName: '',
  managerId: null,
  dataForCollection: {
    dateToPay: null,
    paymentMethod: null,
    collectionContacts: [],
  },
};
