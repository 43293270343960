import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../../../views/styles';

const NumberColumn = ({ number }) => {
  const classes = useStyles();

  return (
    <div style={{ maxWidth: 300 }}>
      <Typography align="center" className={clsx(classes.oneLineClamp)}>{number}</Typography>
    </div>
  );
};

NumberColumn.propTypes = {
  number: PropTypes.number.isRequired,
};

export default NumberColumn;
