import { useLazyQuery } from '@apollo/client';
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { GET_CLIENT_DEBTORS_LIST_EXCEL } from '../../gql/clientDebtorsListExcel';

const ClientSellsExcel = () => {
  const [inputRut, setInputRut] = useState();

  const [getExcelUrl, {
    loading: loadingExcel,
  }] = useLazyQuery(GET_CLIENT_DEBTORS_LIST_EXCEL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getClientDebtorsListExcel) {
        window.open(data.getClientDebtorsListExcel.url);
      }
    },
  });

  const handleRutChange = (event) => {
    setInputRut(event.target.value);
  };

  const handleDownloadExcelByInput = () => {
    getExcelUrl({
      variables: {
        clientRut: inputRut,
      },
    });
  };

  return (
    <Grid container alignItems="flex-end" justifyContent="left">
      <Grid item xs={3} direction="column">
        <Typography>Excel ventas cliente</Typography>
        <TextField
          variant="outlined"
          label="Rut sin puntos ni dígito verificador"
          style={{ width: '90%' }}
          InputProps={{
            style: { height: '3rem' },
          }}
          onChange={(event) => handleRutChange(event)}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleDownloadExcelByInput()}
        >
          Descargar
        </Button>
      </Grid>
      <Grid item xs={3}>
        { loadingExcel
          && (
            <>
              <Typography variant="subtitle2">Descargando Excel <CircularProgress size={20} /></Typography>
            </>
          )}
      </Grid>
    </Grid>
  );
};

export default ClientSellsExcel;
