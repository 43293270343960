import React from 'react';
import { Typography, Tooltip, Button, CircularProgress } from '@material-ui/core';
import { Block, ReportProblem, Error } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { GET_CLIENT_DEBTORS_LIST_EXCEL } from '../../gql/clientDebtorsListExcel';
import { formatRut } from '../../helpers/generic';
import tooltipHandler from '../../helpers/riskTooltip';
import { useStyles } from '../../views/styles';

const CustomerTableComponent = (
  { rut, name, amountOfPendingReintegrations, amountDebtInvoices, riskBlacklist },
) => {
  const classes = useStyles();
  const [getExcelUrl, {
    loading: loadingExcel,
  }] = useLazyQuery(GET_CLIENT_DEBTORS_LIST_EXCEL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getClientDebtorsListExcel) {
        window.open(data.getClientDebtorsListExcel.url);
      }
    },
  });

  const handleDownloadExcel = (clientRut) => {
    getExcelUrl({
      variables: {
        clientRut,
      },
    });
  };
  return (
    <div>
      <Typography className={classes.oneLineClamp}>
        {name}
        {amountOfPendingReintegrations > 0 && (
          <Tooltip
            arrow
            title={tooltipHandler(amountOfPendingReintegrations, 'reintegration')}
          >
            <ReportProblem
              fontSize="small"
              className={classes.warningIcon}
            />
          </Tooltip>
        )}
        {amountDebtInvoices > 0 && (
          <Tooltip
            arrow
            title={tooltipHandler(amountDebtInvoices, 'debt')}
          >
            <Error
              fontSize="small"
              className={classes.alertIcon}
            />
          </Tooltip>
        )}
        {riskBlacklist && <Tooltip title="Blacklist"><Block fontSize="small" className={classes.blacklistIcon} /></Tooltip>}
      </Typography>
      <Typography>
        <Tooltip title="Descargar excel de ventas" arrow>
          <Button
            onClick={() => handleDownloadExcel(rut)}
          >
            {formatRut(rut)}
          </Button>
        </Tooltip>
        {loadingExcel && <Typography variant="subtitle2">Descargando Excel <CircularProgress size={20} /></Typography>}
      </Typography>
    </div>
  );
};

CustomerTableComponent.propTypes = {
  name: PropTypes.string.isRequired,
  rut: PropTypes.number.isRequired,
  amountOfPendingReintegrations: PropTypes.number.isRequired,
  amountDebtInvoices: PropTypes.number.isRequired,
  riskBlacklist: PropTypes.bool,
};

CustomerTableComponent.defaultProps = {
  riskBlacklist: false,
};

export default CustomerTableComponent;
