import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const ErrorComponent = ({ message, fullHeight }) => (
  <Grid container direction="column" justify="center" alignItems="center" style={fullHeight ? { height: '90vh' } : null}>
    <Typography variant="h4">Lo sentimos</Typography>
    <Typography variant="h6">{message}</Typography>
  </Grid>
);

ErrorComponent.propTypes = {
  message: PropTypes.string,
  fullHeight: PropTypes.bool,
};

ErrorComponent.defaultProps = {
  message: 'Algo no salió como esperábamos',
  fullHeight: false,
};

export default ErrorComponent;
