import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { formatMoney } from '../../helpers/money';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  subsection: {
    marginBottom: '1rem',
  },
});

const OperationConfirmation = ({ open, operation, handleClose, handleConfirm, loading }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title"><b>¿Quieres confirmar la operación?</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item xs={8}>
              <Typography>Monto operación ({operation.quantity} facturas)</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="right">${formatMoney(operation.amount)}</Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item xs={8}>
              <Typography>Intereses mensuales operación</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="right">${formatMoney(operation.interest)}</Typography>
            </Grid>
          </Grid>
        </div>
        {/* <div className={classes.section}>
          <Typography gutterBottom>Exposiciones al concretarse la operación</Typography>
          <div className={classes.subsection}>
            <Typography variant="body2"><b>Cliente</b></Typography>
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item xs={8}>
                <Typography variant="body2">{operation.client.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body2">${formatMoney(operation.client.exposure)}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.subsection}>
            <Typography variant="body2"><b>Deudores</b></Typography>
            {operation.debtors.map((debtor) => (
              <Grid container key={debtor.name} justify="space-between" alignItems="flex-end">
                <Grid item xs={8}>
                  <Typography variant="body2">{debtor.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align="right" variant="body2">${formatMoney(debtor.exposure)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </div> */}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={loading} onClick={handleConfirm} color="primary" autoFocus>
          Aceptar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OperationConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  operation: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    interest: PropTypes.number.isRequired,
    client: PropTypes.shape({
      name: PropTypes.string.isRequired,
      exposure: PropTypes.number.isRequired,
    }),
    debtors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        exposure: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

OperationConfirmation.defaultProps = {
  loading: false,
};

export default OperationConfirmation;
