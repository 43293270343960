/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Hidden,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Menu as MenuIcon, Person } from '@material-ui/icons';
import logo from '../../assets/images/logo_fingo_new.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
  },
  toolbar: {
    display: 'flex',
  },
  iconButton: {
    paddingTop: 5,
  },
  menuIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  logoContainer: {
    paddingLeft: '1rem',
  },
  linksContainer: {
    marginLeft: '5rem',
  },
  link: {
    marginRight: '2rem',
    color: theme.palette.text.primary,
  },
  leftcontainer: {
    flex: 0.9,
    display: 'flex',
    alignItems: 'center',
  },
  rightContainer: {
    flex: 0.1,
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '50%',
    boxShadow: `0px 4px 19px -7px ${theme.palette.primary.main}`,
  },
  buttonsContainer: {
    display: 'flex',
  },
}));

const Header = ({ toggleDrawer, user, logout }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftcontainer}>
          <IconButton className={classes.iconButton} onClick={toggleDrawer}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo" style={{ width: 120, marginTop: 4 }} />
          </div>
        </div>
        <div className={classes.rightContainer}>
          {/* {user && (
            <Paper className={classes.paper}>
              <IconButton onClick={openMenu} style={{ padding: 5 }}>
                <Person color="primary" />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
              </Menu>
            </Paper>
          )} */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default Header;
