import React, { useState } from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { CLIENT_RAS, DEBTOR_RAS, CLIENT_DEBTOR_RAS } from '../../gql/riskAlgorithm';
import GenericTree from './TreeGenericView';
import { useStyles } from '../../views/styles';

const Ras = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" color="textPrimary">RAS</Typography>
      </div>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, tab) => setSelectedTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Cliente" />
        <Tab label="Deudor" />
        <Tab label="Relación" />
      </Tabs>
      {selectedTab === 0 && (
        <GenericTree query={CLIENT_RAS} variableType="CLIENT_RAS" />
      )}
      {selectedTab === 1 && (
        <GenericTree query={DEBTOR_RAS} variableType="DEBTOR_RAS" />
      )}
      {selectedTab === 2 && (
        <GenericTree query={CLIENT_DEBTOR_RAS} variableType="CLIENT_DEBTOR_RAS" />
      )}
    </div>
  );
};

export default Ras;
