/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2%',
  },
  section: {
    marginBottom: '2rem',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
  },
  searchContainer: {
    display: 'flex',
  },
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  displayContract: {
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  titleAndUpdate: {
    display: 'flex',
    alignItems: 'center',
    flex: 0.5,
    marginBottom: '2rem',
  },
  updateButton: {
    marginLeft: '2rem',
  },
  headerButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 0.5,
  },
  header: {
    marginBottom: '2rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBar: {
    padding: '0 2rem',
  },
  tableContainer: {
    padding: '0',
  },
  tableContainerEnrollment: {
    padding: '0 3rem 0 3rem',
  },
  twoLineClamp: {
    height: 45,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  oneLineClamp: {
    height: 24,
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
  },
  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: 'red',
  },
  adornedEnd: {
    paddingRight: 0,
  },
  tooltip: {
    maxWidth: 'none',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  blueButton: {
    backgroundColor: '#283593',
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#1a237e',
    },
  },
  mainColorButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  columnDirection: {
    display: 'flex',
    flexDirection: 'column',
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  columnLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  receiptContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    maxHeight: 50,
    width: 200,
    margin: '0 1rem',
    '&&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  cellJustifyRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'right',
  },
  drawerPaper: {
    width: '30%',
    height: '100vh',
    overflow: 'hidden',
  },
  yellowButton: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    marginLeft: '2rem',
    marginRight: '2rem',
  },
  riskButton: {
    marginLeft: '2rem',
    marginRight: '2rem',
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  headerLeft: {
    margin: '2rem 0',
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: theme.palette.error.main,
  },
  green: {
    color: theme.palette.success.main,
  },
  button: {
    marginRight: '2%',
  },
  selectedAmount: {
    paddingLeft: '4%',
    marginBottom: '1rem',
  },
  listEmpty: {
    marginTop: '20vh',
  },
  customHeader: {
    paddingLeft: '4%',
    margin: '2rem 2rem 0 0',
  },
  modalHeader: {
    margin: '1rem 0 0 1rem',
  },
  modalMessage: {
    marginLeft: '1rem',
  },
  dialogButton: {
    margin: '1rem',
  },
  textWithLineBreak: {
    whiteSpace: 'pre-wrap',
  },
  movements: {
    padding: '1rem',
    overflow: 'scroll',
  },
  conciliations: {
    padding: '1rem 1rem 0 2rem',
  },
  tableHeader: {
    marginBottom: '1.5rem',
  },
  movementTable: {
    root: {
      fontSize: '1px',
    },
    table: {
      fontSize: '1px',
    },
  },
  buttonLine: {
    padding: '0 2rem 0 0',
  },
  totalSelected: {
    margin: '1.5rem 0 0 0',
  },
  tableBox: {
    height: '65vh',
    overflowY: 'auto',
  },
  confirmationDifference: {
    fontSize: '20px',
    padding: '20px 50px 0px 50px',
    textAlign: 'right',
  },
  differenceInput: {
    textAlign: 'right',
  },
  inputFieldAndNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
  },
  warningIcon: {
    verticalAlign: 'text-top',
    color: '#FF9900',
  },
  alertIcon: {
    verticalAlign: 'text-top',
    color: '#FC7303',
  },
  noRatificationIcon: {
    color: '#FF9900',
  },
  blacklistIcon: {
    color: 'red',
    verticalAlign: 'bottom',
  },
  rowFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hiddenInput: {
    display: 'none',
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignElement: {
    marginTop: '10px',
    paddingTop: '0px',
  },
  indentedText: {
    paddingLeft: '10px',
    marginTop: '10px',
  },
  cardTitle: {
    marginBottom: '1rem',
  },
  cardContent: {
    marginBottom: '0.5rem',
    paddingInline: '20px',
  },
  card: {
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '800px',
  },
  cardContentDetail: {
    marginLeft: '10px',
  },
  infoCardsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    width: '500px',
  },
  topRightContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
  },
  infoCard: {
    width: '230px',
    height: '180px',
  },
  infoCardContent: {
    width: '200px',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
