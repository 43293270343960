import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Divider,
  Box,
  DialogTitle,
  FormGroup,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import parse from 'html-react-parser';
import moment from 'moment';
import RatificationContactsTable from '../dialogs/RatificationContactsTable';
import { Table } from '../tables';
import {
  SEND_RATIFICATION_MAIL,
  GET_RATIFICATION_MAIL_ENUM,
  GET_RATIFICATION_MAIL_PREVIEW,
} from '../../gql/ratification';
import { OneButtonAlert } from '../dialogs';
import { formatMoney } from '../../helpers/money';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'amount', label: 'Monto', align: 'left' },
  { id: 'issuedDate', label: 'Fecha de emisión', align: 'left' },
  { id: 'paymentDate', label: 'Fecha de pago', align: 'left' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'left' },
];

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: '80%',
    marginInline: 'auto',
  },
  titles: {
    marginTop: '2%',
    marginBottom: '2%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendMailButton: {
    backgroundColor: '#404BD0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1a237e',
    },
  },
});

const RatificationSendMail = ({ open, setOpen, selectedInvoices, nameReceiver, rutReceiver }) => {
  const classes = useStyles();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [mailType, setMailType] = useState();
  const [mailTypeOptions, setMailTypeOptions] = useState([]);
  const [withCessionCertificate, setWithCessionCertificate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successPreviewResponse, setSuccessPreviewResponse] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [mailPreview, setMailPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [openFinalDialog, setOpenFinalDialog] = useState(false);
  const [mailInvoices, setMailInvoices] = useState(
    selectedInvoices.map((inv) => ({
      managerId: inv.ratificationManagerId ? inv.ratificationManagerId : inv.managerId,
      folio: inv.folio.value,
      amount: inv.amount.value,
      dateIssued: inv.dateIssued.value,
      paymentDate: inv.paymentDate.value,
      integrationStatus: inv.integrationStatus,
      certificateUrl: inv.certificateUrl,
    })),
  );
  const { data: mailTypeOptionsData } = useQuery(GET_RATIFICATION_MAIL_ENUM);
  const [SendRatificationMail] = useMutation(SEND_RATIFICATION_MAIL);
  const [GetRatificationMailPreview] = useMutation(GET_RATIFICATION_MAIL_PREVIEW);

  useEffect(() => {
    if (mailTypeOptionsData?.getRatificationMailEnum) {
      setMailTypeOptions(mailTypeOptionsData.getRatificationMailEnum);
    }
  }, [mailTypeOptionsData]);

  const handleMailTypeChange = (e) => {
    if (e.target.value === 'About To Loan Ratification') {
      setWithCessionCertificate(false);
    }
    setMailType(e.target.value);
  };

  const handleSelect = (_selectedRows) => {
    setSelectedContacts(_selectedRows.map((row) => ({
      id: row.id,
      name: row.name.value,
    })));
  };

  const handleCheckbox = (e) => {
    setWithCessionCertificate(e.target.checked);
  };

  const handleSendMailButton = async () => {
    if (selectedContacts.length <= 0) {
      setErrorMessage('Debes seleccionar al menos un contacto');
      return;
    }
    if (mailType == null) {
      setErrorMessage('Debe seleccionar un tipo de correo');
      return;
    }
    setLoading(true);
    const { data: { sendRatificationMail: { success } } } = await SendRatificationMail({
      variables: {
        mailType,
        contactsIds: selectedContacts.map((contact) => contact.id),
        withCessionCertificate,
        managersIds: mailInvoices.map((inv) => inv.managerId),
      },
    });
    setOpenFinalDialog(true);
    setSuccessResponse(success);
    setLoading(false);
  };

  const handleFinalDialogClose = () => {
    setOpen(false);
    setOpenFinalDialog(false);
    window.location.reload();
  };

  const handlePreview = async () => {
    if (mailType == null) {
      setErrorMessage('Debe seleccionar un tipo de correo');
      return;
    }
    setLoading(true);
    const { data: {
      getRatificationMailPreview: { success, preview } } } = await GetRatificationMailPreview({
      variables: {
        mailType,
        managersIds: mailInvoices.map((inv) => inv.managerId),
      },
    });
    setMailPreview(preview);
    setSuccessPreviewResponse(success);
    setOpenPreviewDialog(true);
    setLoading(false);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      managerId,
      folio,
      dateIssued,
      paymentDate,
      amount,
      integrationStatus,
    } = row;

    return ({
      managerId,
      folio: {
        component: <Typography>{folio}</Typography>,
      },
      amount: {
        component: <Typography>${formatMoney(amount)}</Typography>,
      },
      issuedDate: {
        component: <Typography>{moment(dateIssued).format('DD-MM-YYYY')}</Typography>,
      },
      paymentDate: {
        component: <Typography>{moment(paymentDate).format('DD-MM-YYYY')}</Typography>,
      },
      integrationStatus,
    });
  });

  return (
    <>
      <OneButtonAlert
        open={openFinalDialog}
        onClose={handleFinalDialogClose}
        message={(successResponse) ? 'Mails enviados con éxito.' : 'No logramos enviar los mails, contactate con el equipo de desarrollo.'}
        title="Envío de mails"
      />
      <OneButtonAlert
        open={openPreviewDialog}
        message={(successPreviewResponse) ? parse(mailPreview) : 'No logramos cargar el preview, contactate con el equipo de desarrollo.'}
        title="Preview"
        onClose={() => setOpenPreviewDialog(false)}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" align="center">Enviar correo a {nameReceiver}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" align="center" className={classes.titles}>Selecciona el tipo de correo</Typography>
          <RadioGroup
            aria-label="rejection-reason"
            value={mailType}
            onChange={handleMailTypeChange}
            row
          >
            {mailTypeOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {mailType && !(mailType === 'ABOUT_TO_LOAN_RATIFICATION') && (
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={withCessionCertificate}
                    onClick={handleCheckbox}
                    disabled={
                      !(mailInvoices.reduce(
                        (acc, inv) => acc && (inv.certificateUrl !== null), true,
                      ))
                    }
                    name="responded"
                  />
                )}
                label="Adjuntar certificado de cesión"
              />
            </FormGroup>
          )}
          <Divider />
          <Typography variant="h6" align="center" className={classes.titles}>Selecciona los contactos</Typography>
          <RatificationContactsTable
            companyRut={rutReceiver}
            companyName={nameReceiver}
            onSelect={handleSelect}
          />
          <Divider />
          <Typography variant="h6" align="center" className={classes.titles}><></>Información del correo</Typography>
          <Box className={classes.tableContainer}>
            <Table
              withSelect={false}
              pagination={false}
              headers={headers}
              data={mailInvoices}
              dataToRows={transformDataToComponents}
              setNewDataOrder={setMailInvoices}
              initialOrderId="id"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Typography color="error" variant="h6">{errorMessage}</Typography>
          {loading && <CircularProgress />}
          <Button variant="contained" color="success" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handlePreview}>
            Ver correo
          </Button>
          <Button variant="contained" color="inherit" className={classes.sendMailButton} onClick={handleSendMailButton}>
            Enviar correo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RatificationSendMail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedInvoices: PropTypes.objectOf(PropTypes.object).isRequired,
  nameReceiver: PropTypes.string.isRequired,
  rutReceiver: PropTypes.string.isRequired,
};

export default RatificationSendMail;
