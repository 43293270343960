import React from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterHeader from './FilterHeader';

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const Header = (props) => {
  const {
    headCells,
    orderBy,
    order,
    onRequestSort,
    withSelect,
    withoutSelectHeader,
    numSelected,
    onSelectAllClick,
    rowCount,
    collapse,
    sendFilterData,
  } = props;
  const classes = useStyles();

  const createSortHandler = (headCell) => {
    const property = headCell?.filterBy ? headCell?.filterBy : headCell?.id;
    const direction = (orderBy === property && order === 'asc') ? 'desc' : 'asc';
    onRequestSort(property, direction);
  };

  return (
    <TableHead>
      <TableRow>
        {withSelect && (
          <TableCell padding="none">
            {!withoutSelectHeader && (
              <Checkbox
                style={{ paddingBottom: 0 }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all invoices' }}
              />
            )}
          </TableCell>
        )}
        {collapse && <TableCell padding="none" />}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div style={{ display: 'flex' }}>
              <TableSortLabel
                style={{ pointerEvents: headCell.hideSortIcon ? 'none' : null }}
                active={orderBy === headCell.id && !headCell.hideSortIcon}
                hideSortIcon={headCell.hideSortIcon}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => createSortHandler(headCell)}
              >
                {headCell.label}
                {headCell.icon}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              {headCell.filter && (
                <FilterHeader
                  property={headCell.id}
                  titleTooltip={headCell.filter.titleTooltip}
                  dataList={headCell.filter.dataList}
                  label={headCell.label}
                  sendFilterData={sendFilterData}
                  initState={headCell.filter.initState}
                />
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      align: PropTypes.string,
      hideSortIcon: PropTypes.bool,
    }),
  ).isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  withSelect: PropTypes.bool,
  withoutSelectHeader: PropTypes.bool,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  collapse: PropTypes.bool,
  sendFilterData: PropTypes.func,
};

Header.defaultProps = {
  order: 'asc',
  orderBy: '',
  onRequestSort: () => {},
  withSelect: false,
  withoutSelectHeader: true,
  numSelected: 0,
  rowCount: 0,
  onSelectAllClick: () => {},
  collapse: false,
  sendFilterData: () => {},
};

export default Header;
