import React, { useState } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import {
  Typography,
  Tooltip,
  Button,
  LinearProgress,
  IconButton,
  CircularProgress,
  Box,
  Snackbar,
  Grid,
} from '@material-ui/core';
import { Print as PrintIcon, Cancel as CancelIcon, OpenInNew, ArrowBackIos, LiveHelpTwoTone, Block, Cached, Error, FlashOn } from '@material-ui/icons';
import moment from 'moment';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Table } from '../components/tables';
import { CustomerDocumentsMenu, RerunRiskMenu, AlertsMenu } from '../components/customers';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { toPercentage } from '../helpers/generic';
import tooltipHandler from '../helpers/riskTooltip';
import { CREATE_COMPANY_RESTRICTION, ADD_COMPANY_TO_BLACKLIST, ADD_IVA_CHECK, ADD_DICOM_CHECK, GET_COMPANY_EVALUATIONS } from '../gql/customer';
import VisualizeAllDocument from '../components/companyDocumentsPreoffers/VisualizeAllDocuments';
import VisualizeAllRestrictions from '../components/companyRestrictions/VisualizeAllRestrictions';
import ResultDetail from '../components/riskEngine/ResultDetail';
import { RestrictionsToAcceptPreOffers, AddObservationCheck } from '../components/dialogs';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import { changeNumberColor, setResultIcon } from '../helpers/riskEngineMapper';
import VariablesDetail from '../components/riskEngine/VariablesDetail';
import EmailDialog from '../components/riskEngine/EmailDialog';
import { getDialogLikeRiskResult, getPreofferHistoryTooltip } from '../helpers/evaluationsRequests';
import { calculateTreeFinalResult, calculateRasFinalResult } from '../components/risk/Helpers';
import DebtorCell from '../components/shared/DebtorCell';
import { GET_CLIENT_DEBTORS_LIST_EXCEL } from '../gql/clientDebtorsListExcel';

// eslint-disable-next-line
const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'purchaseOrder', label: 'OC', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'emittedDate', label: 'Emisión', align: 'right' },
  { id: 'expirationDate', label: 'Vencimiento', align: 'right' },
  { id: 'amountWithIva', label: 'Monto', align: 'right' },
  { id: 'receiverDocuments', label: 'Historial documentos', align: 'right' },
  { id: 'riskVariables', label: 'V. Riesgo', align: 'center' },
  { id: 'treeResults', label: 'Árbol', align: 'center' },
  { id: 'rasResults', label: 'RAS', align: 'center' },
  { id: 'reExecute', label: 'Re-ejecutar', align: 'center' },
  { id: 'bureausAlert', label: 'Alertas', align: 'center' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
  { id: 'documents', label: 'Documentos', align: 'right' },
];

const CompanyPreOffers = () => {
  const location = useLocation();
  const companyRut = location.pathname.split('/').pop();
  const history = useHistory();
  const classes = useStyles();
  const [createCompanyRestriction,
    { loading: createRestrictionLoading }] = useMutation(CREATE_COMPANY_RESTRICTION);
  const [addIvaCheck] = useMutation(ADD_IVA_CHECK);
  const [addDicomCheck] = useMutation(ADD_DICOM_CHECK);

  // TODO: this should be a CustomHook
  const [getExcelUrl, {
    loading: loadingExcel,
  }] = useLazyQuery(GET_CLIENT_DEBTORS_LIST_EXCEL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.getClientDebtorsListExcel) {
        window.open(data.getClientDebtorsListExcel.url);
      }
    },
  });

  const [riskResults, setRiskResults] = useState({
    type: '',
    open: false,
    data: [],
    header: [],
  });
  const [riskVariables, setRiskVariables] = useState({
    ratification: {},
    open: false,
    invoiceId: null,
    debtor: {},
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [automaticMailModal, setAutomaticMailModal] = useState(false);
  const [modalRestrictions, setModalRestrictions] = useState(false);
  const [restrictionsMutation, setRestrictionsMutation] = useState([]);
  const [controlRefetchDocuments, setControlRefetchDocuments] = useState(false);
  const [ivaCheckModal, setIvaCheckModal] = useState(false);
  const [dicomCheckModal, setDicomCheckModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { refetch, loading, error, data: evaluationsData } = useQuery(GET_COMPANY_EVALUATIONS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      companyRut,
      hasEvaluatingPreoffer: true,
    },
    onCompleted: (data) => {
      setInvoices(data.invoices.edges.map((edge) => edge.node));
    },
  });
  const ivaChecks = evaluationsData?.invoices.edges[0]?.node.company.currentIvaObservation || [];
  const company = evaluationsData?.invoices.edges[0]?.node.company;
  const dicomChecks = evaluationsData?.invoices.edges[0]?.node.company.currentDicomObservation
  || [];
  const [
    addCompanyToBlacklist,
    { loading: blacklistLoading },
  ] = useMutation(ADD_COMPANY_TO_BLACKLIST, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleDownloadExcel = (rut) => {
    getExcelUrl({
      variables: {
        clientRut: rut,
      },
    });
  };

  const handleRestrictions = (_restrictions) => {
    const { restrictions } = _restrictions;
    const restrictionsChecked = restrictions.filter((res) => res.checked);
    const newRestrictionsMutation = [];
    const other = restrictions.find((obj) => obj.name === 'OTHER');
    const otherRestriction = other ? other.value : '';
    restrictionsChecked.map((res) => (res.name === 'OTHER' ? newRestrictionsMutation.push({
      companyRut,
      restriction: res.name,
      otherRestriction,
    })
      : newRestrictionsMutation.push({
        companyId: companyRut,
        restriction: res.name,
        otherRestriction: '',
      })));
    setRestrictionsMutation(newRestrictionsMutation);
  };

  const handleCreateRestrictions = async () => {
    try {
      await createCompanyRestriction({
        variables: {
          requestRestriction: restrictionsMutation,
        },
      });
    } catch (err) {
      // console.log('EL ERROR ES', err);
    }
  };

  const handleDisableAutomaticMail = (inv_) => {
    for (let i = 0; i < inv_.length; i += 1) {
      if (inv_[i].treeResults.value === null) return true;
    }
    return false;
  };

  // function passed to the risk result dialog
  const handleCloseRiskResult = () => {
    setRiskResults({
      type: '',
      open: false,
      data: [],
      header: [],
    });
  };
  // function passed to the risk variable dialog
  const handleCloseRiskVariables = () => {
    setRiskVariables({
      ratification: {},
      open: false,
      invoiceId: null,
      debtor: {},
    });
  };

  const handleGoBack = () => {
    history.push({
      pathname: '/app/risk/evaluations',
      state: {
        tab: 0,
      },
    });
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      company: {
        cessionsHistory,
        companyRiskHistory,
      },
      receiver: {
        id: receiverId,
        receiverCessionsHistory,
        rut,
        name: debtorName,
        riskBlacklist: debtorBlacklist,
        ratificationBlacklist,
        receiverRiskHistory,
        creditLine: receiverCreditLine,
      },
      companyReceiverCessionHistory,
      dateIssued,
      dateExpiration,
      amountWithIva,
      riskEvaluationModel,
      pdf,
      certificateUrl,
      documents,
      objectPurchaseOrder,
      receiverDocumentRequested,
      creditNoteStatus,
      siiStatus,
      documentfinancestateSet,
      companyReceiverRiskHistory,
      lightningPaymentAuthorized,
    } = row;
    const ratificationBlacklistActive = (
      ratificationBlacklist ? ratificationBlacklist.active : false
    );
    const historyTooltip = getPreofferHistoryTooltip(
      cessionsHistory,
      companyReceiverCessionHistory,
      receiverCessionsHistory,
    );
    const treeFinalResult = calculateTreeFinalResult(riskEvaluationModel);
    const rasFinalResult = calculateRasFinalResult(riskEvaluationModel);
    const {
      label: siiLabel,
      color: siiColor,
    } = siiStatusToProps(dateIssued, creditNoteStatus)[siiStatus];

    // function for setting the clicked row RiskResultData
    const handleRiskResultButton = (type) => {
      const dialogLikeRiskResult = getDialogLikeRiskResult(type, riskEvaluationModel);
      setRiskResults(dialogLikeRiskResult);
    };

    // function for setting the clicked row RiskVariablesData
    const handleRiskVariablesButton = () => {
      setRiskVariables({
        company: {
          id: company.id,
          name: company.name,
          rut: company.rut,
        },
        invoiceId: id,
        ratification: {
          ratification: riskEvaluationModel.ratification,
          reason: riskEvaluationModel.reason,
        },
        open: true,
        debtor: {
          id: receiverId,
          debtorName,
          rut,
        },
      });
    };
    return ({
      id,
      folio: {
        value: folio, // value is for table sorting
        component: (
          <div className={classes.alignRight}>
            <Tooltip
              title={(
                <Typography className={classes.textWithLineBreak} variant="body2">
                  {historyTooltip}
                </Typography>
                )}
              placement="right"
              className={classes.tooltip}
            >
              <Typography>{folio}</Typography>
            </Tooltip>
            <Tooltip title="Descargar Factura">
              <a href={pdf} target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </a>
            </Tooltip>
            {lightningPaymentAuthorized && (
              <Tooltip title="Contabilizadas en Inacap">
                <FlashOn color="primary" />
              </Tooltip>
            )}
          </div>
        ),
      },
      debtor: {
        value: rut,
        component: (
          <DebtorCell
            debtorId={receiverId}
            name={debtorName}
            rut={rut}
            creditLine={receiverCreditLine}
            hasRatificationBlacklist={ratificationBlacklistActive}
            hasRiskBlacklist={debtorBlacklist}
          />
        ),
      },
      emittedDate: {
        value: dateIssued,
        component: (
          <>
            <Typography align="right">{moment(dateIssued).format('DD-MM-YYYY')}</Typography>
            {/* <Typography align="right"> {getTimeDiffText(dateIssued)} </Typography> */}
          </>
        ),
      },
      expirationDate: {
        value: dateExpiration,
        component: (
          <>
            <Typography align="right">{moment(dateExpiration).format('DD-MM-YYYY')}</Typography>
            {/* <Typography align="right">{getTimeDiffText(dateExpiration)}</Typography> */}
          </>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography align="right">${formatMoney(amountWithIva)}</Typography>
        ),
      },
      purchaseOrder: {
        value: folio, // value is for table sorting
        component: (
          <div>
            {objectPurchaseOrder && objectPurchaseOrder.exists
              ? (
                <Tooltip title="Descargar orden de compra">
                  <a href={objectPurchaseOrder.url} target="_blank" rel="noopener noreferrer">
                    <IconButton style={{ padding: 0 }}>
                      <PrintIcon />
                    </IconButton>
                  </a>
                </Tooltip>
              ) : (
                <Tooltip title="Sin orden de compra">
                  <CancelIcon color="error" />
                </Tooltip>
              )}
          </div>
        ),
      },
      receiverDocuments: {
        value: receiverDocumentRequested && receiverDocumentRequested.id,
        component: (
          <div className={classes.center}>
            <Tooltip
              title={(
                <Typography className={classes.textWithLineBreak} variant="body2">
                  {
                      receiverDocumentRequested
                        ? (
                          `Poseemos la información de un total de ${receiverDocumentRequested.invoicesAmount} facturas entre este par cliente-deudor:\n  - OC el ${toPercentage(receiverDocumentRequested.purchaseOrderPercentage)} de las veces\n - HES el ${toPercentage(receiverDocumentRequested.hesPercentage)} de las veces`
                        )
                        : 'No se posee información de OC o HES del par cliente deudor'
                    }
                </Typography>
                )}
              className={classes.tooltip}
            >
              <LiveHelpTwoTone />
            </Tooltip>
          </div>
        ),
      },
      riskVariables: {
        value: rasFinalResult,
        component: (
          <Button
            onClick={() => handleRiskVariablesButton()}
            disabled={treeFinalResult == null}
          >
            <OpenInNew />
          </Button>
        ),
      },
      treeResults: {
        value: treeFinalResult,
        component: (
          <Button
            onClick={() => handleRiskResultButton('Tree')}
            disabled={treeFinalResult == null}
          >
            <Box>
              {setResultIcon(treeFinalResult, classes)}
            </Box>
          </Button>
        ),
      },
      rasResults: {
        value: rasFinalResult,
        component: (
          <Button
            onClick={() => handleRiskResultButton('RAS')}
            disabled={rasFinalResult == null}
          >
            <Typography>{changeNumberColor(rasFinalResult === null ? 'n/a' : rasFinalResult.toFixed(2), classes)}</Typography>
          </Button>
        ),
      },
      reExecute: {
        value: null,
        component: (
          <RerunRiskMenu
            disabled={treeFinalResult == null}
            invoiceId={parseInt(id, 10)}
            refetch={refetch}
          />
        ),
      },
      bureausAlert: {
        value: null,
        component: (
          <AlertsMenu
            companyRiskHistory={companyRiskHistory}
            receiverRiskHistory={receiverRiskHistory}
            companyReceiverRiskHistory={companyReceiverRiskHistory}
          />
        ),
      },
      integrationStatus: {
        value: siiLabel,
        component: (
          <>
            <Typography style={{ color: siiColor }}>{siiLabel}</Typography>
            <Tooltip title="Fuente: Portal de provedores" arrow>
              <Grid>
                <Typography style={{ color: siiColor }} variant="caption">
                  {documentfinancestateSet[0]?.status.status}
                </Typography>
                {documentfinancestateSet[0]?.paymentDate
                && (
                <Typography style={{ color: siiColor }} variant="caption">
                  - Fecha de pago: {documentfinancestateSet[0].paymentDate}
                </Typography>
                )}
                {documentfinancestateSet[0]?.rejectionCode
                && (
                <Typography style={{ color: siiColor }} variant="caption">
                  - Razón de rechazo: {documentfinancestateSet[0]?.rejectionCode?.reason}
                </Typography>
                )}
              </Grid>
            </Tooltip>
          </>
        ),
      },
      documents: {
        component: (
          <CustomerDocumentsMenu
            uploadOption={false}
            purchaseOrder={objectPurchaseOrder}
            pdf={pdf}
            certificate={certificateUrl}
            companyRut={company.rut}
            documents={documents}
          />
        ),
      },
    });
  });

  if (
    !company || loading || createRestrictionLoading || blacklistLoading
  ) return <LinearProgress />;
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) return <Typography variant="h2" align="center">Error al cargar las pre ofertas</Typography>;
  return (
    <Grid className={classes.container}>
      <Grid className={classes.headerContainer}>
        <Grid container direction="column" alignItems="left">
          <Grid item style={{ display: 'flex', alignItems: 'left' }}>
            <IconButton onClick={handleGoBack}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4">
              <b>Solicitudes vigentes de {company.name}</b>
              {company.masterEntity.riskBlacklist && (
                <Tooltip title="Blacklist">
                  <Block fontSize="large" className={classes.blacklistIcon} />
                </Tooltip>
              )}
              {company.amountDebtInvoices > 0 && (
                <Tooltip title={tooltipHandler(company.amountDebtInvoices, 'debt')}>
                  <Error fontSize="large" className={classes.alertIcon} />
                </Tooltip>
              )}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-evenly" wrap="nowrap">
            <Button
              variant="contained"
              style={{ backgroundColor: 'green', fontSize: '0.9rem', lineHeight: '0.9rem' }}
              className={classes.yellowButton}
              onClick={() => handleDownloadExcel(company.rut)}
            >
              {loadingExcel ? (
                <Typography variant="body1">Descargando Excel <CircularProgress size={20} /></Typography>
              ) : (
                <>Excel de ventas</>
              )}
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.yellowButton}
              onClick={() => setIvaCheckModal(true)}
            >
              Agregar Iva
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.yellowButton}
              onClick={() => setDicomCheckModal(true)}
            >
              Agregar Dicom
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.yellowButton}
              onClick={() => {
                setModalRestrictions(true);
              }}
            >
              Añadir Restricciones
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.greenButton}
              disabled={(selectedInvoices.length === 0)}
              onClick={() => history.push({
                pathname: `/app/risk/evaluations/preoffer/${companyRut}/to-accept`,
                state: {
                  selectedInvoices: invoices.filter(
                    (el) => (selectedInvoices.reduce((previousValue, value) => {
                      previousValue.push(value.id);
                      return previousValue;
                    }, [])).includes(el.id),
                  ),
                },
              })}
            >
              Aprobar Seleccionadas
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.redButton}
              disabled={(selectedInvoices.length === 0)}
              onClick={() => history.push({
                pathname: `/app/risk/evaluations/preoffer/${companyRut}/to-reject`,
                state: {
                  selectedInvoices: invoices.filter(
                    (el) => (selectedInvoices.reduce((previousValue, value) => {
                      previousValue.push(value.id);
                      return previousValue;
                    }, [])).includes(el.id),
                  ),
                },
              })}
            >
              Rechazar Seleccionadas
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.redButton}
              onClick={() => {
                addCompanyToBlacklist(
                  {
                    variables: { ruts: [company.rut] },
                  },
                );
              }}
              disabled={company.masterEntity.riskBlacklist}
            >
              Agregar cliente a Blacklist
            </Button>
            <Button
              style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}
              variant="contained"
              color="primary"
              className={classes.yellowButton}
              onClick={() => setAutomaticMailModal(true)}
              disabled={selectedInvoices.length === 0
                || handleDisableAutomaticMail(selectedInvoices)}
            >
              Mail Evaluación
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => refetch()}
              endIcon={<Cached />}
            >
              Actualizar
            </Button>
          </Grid>
          <Grid item style={{ marginTop: '2rem', marginLeft: '2rem' }}>
            <Typography variant="h6">Monto total de la evaluación: ${formatMoney(invoices.reduce((acc, invoice) => acc + invoice.amountWithIva, 0))}</Typography>
            <Typography variant="h6">Monto facturas seleccionadas: ${formatMoney(selectedInvoices.reduce((acc, po) => acc + po.amountWithIva.value, 0))}</Typography>
            {ivaChecks.length > 0 && <Typography variant="h6">Último mensaje de chequeo de IVA: {ivaChecks[0].observation}. Con fecha {moment(ivaChecks[0].createdAt).format('DD-MM-YYYY HH:mm')}</Typography>}
            {dicomChecks.length > 0 && <Typography variant="h6">Último mensaje de chequeo de Dicom: {dicomChecks[0].observation}. Con fecha {moment(dicomChecks[0].createdAt).format('DD-MM-YYYY HH:mm')}</Typography>}
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ paddingInline: '2rem', marginTop: '1rem' }}>
        <Typography variant="h6">Línea de crédito:</Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h6"
          >
            {company?.masterEntity?.creditLine ? (
              `Disponible actual: $${formatMoney(company?.masterEntity?.creditLine?.limitAmount
                - company?.masterEntity?.creditLine?.creditUsed)} | 
              Total: $${formatMoney(company?.masterEntity?.creditLine?.limitAmount)}`
            ) : (
              'Sín línea'
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ paddingInline: '2rem' }}>
        <VisualizeAllDocument rut={company.rut} documentTypes={['CTE', 'TGR', 'EERR', 'BALANCE', 'CONTRACT', 'PROVIDER_CREDENTIALS']} controlRefetchDocuments={controlRefetchDocuments} openSnackBar={setOpenSnackBar} />
      </Grid>
      <Grid style={{ marginLeft: '2rem' }}>
        <Typography variant="h6">Ejecutivo asignado: {company.executiveAssigned ? `${company.executiveAssigned.firstName} ${company.executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
      </Grid>
      <VisualizeAllRestrictions
        handleResolveRestriction={() => { setControlRefetchDocuments(!controlRefetchDocuments); }}
        clientRut={company.rut}
        isPreofferPanel
      />
      <Table
        withSelect
        data={invoices}
        dataToRows={transformDataToComponents}
        setNewDataOrder={setInvoices}
        headers={headers}
        initialOrderId="folio"
        handleSelected={setSelectedInvoices}
      />
      <RestrictionsToAcceptPreOffers
        loading={createRestrictionLoading}
        open={modalRestrictions}
        handleClose={() => { setModalRestrictions(false); }}
        handleConfirm={handleCreateRestrictions}
        handleRestrictions={handleRestrictions}
      />
      <AddObservationCheck
        handleClose={() => setIvaCheckModal(false)}
        open={ivaCheckModal}
        addObservationMutation={addIvaCheck}
        whatToCheck="Iva"
      />
      <AddObservationCheck
        handleClose={() => setDicomCheckModal(false)}
        open={dicomCheckModal}
        addObservationMutation={addDicomCheck}
        whatToCheck="Dicom"
      />
      <ResultDetail
        open={riskResults.open}
        onClose={handleCloseRiskResult}
        resultType={riskResults.type}
        riskResults={riskResults.data}
        resultHeader={riskResults.header}
      />
      {riskVariables.invoiceId && (
      <VariablesDetail
        open={riskVariables.open}
        onClose={handleCloseRiskVariables}
        objectId={riskVariables.invoiceId}
        modelName="invoice"
        company={riskVariables.company}
        debtor={riskVariables.debtor}
        ratification={riskVariables.ratification}
      />
      )}
      <EmailDialog
        open={automaticMailModal}
        handleClose={setAutomaticMailModal}
        invoiceIds={selectedInvoices.map((invoice) => invoice.id)}
        clients={[{
          rut: company.rut,
          socialReason: company.name,
        }]}
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => { setOpenSnackBar(false); }}
        message="Documento enviado por Slack al canal #credit_line_requests, brevemente llegará una respuesta"
        disableWindowBlurListener
      />
    </Grid>
  );
};

export default CompanyPreOffers;
