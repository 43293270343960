import {
  Typography,
  Grid,
  Button,
  Box,
  LinearProgress,
  Snackbar,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { useStyles } from './styles';
import { Table } from '../components/tables';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { GET_PENDING_INVOICES_ORDERS } from '../gql/ordering';

const headers = [
  { id: 'orderNumber', label: 'Númer de orden', align: 'left' },
  { id: 'company', label: 'Compañía', align: 'left' },
  { id: 'purchaser', label: 'Comprador', align: 'left' },
  { id: 'totalAmount', label: 'Monto orden', align: 'left' },
  { id: 'publicationDate', label: 'Fecha de publicación', align: 'left' },
  { id: 'orderingFinancedAmount', label: 'Monto financiado', align: 'left' },
  { id: 'invoiceIssuedDate', label: 'Emisión de factura', align: 'left' },
  { id: 'button', label: '', align: 'left' },
];

const OrderingInvoices = () => {
  const { data, refetch } = useQuery(GET_PENDING_INVOICES_ORDERS);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');

  const dateToColor = (date) => {
    const today = new Date();
    const issuedDate = new Date(date);
    if (issuedDate < today) {
      return 'error';
    }
    return '';
  };

  useEffect(() => {
    setLoading(true);
    refetch();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (data) {
      setOrders(data.getPendingInvoicesOrders);
    }
  }, [data]);

  useEffect(() => {
    if (location.state) {
      const { errorMessage } = location.state;
      if (errorMessage) {
        setOpenErrorDialog(true);
        setLocalErrorMessage(errorMessage);
        history.replace('/app/ordering/add-invoices');
      }
    }
  }, [location.state]);
  const transformDataToComponents = (_data) => _data.map((row) => {
    const addOrderInvoices = (order) => {
      history.push({
        pathname: `add-invoices/${order.id}`,
        state: {
          purchaseOrder: order,
        },
      });
    };
    const {
      id,
      orderNumber,
      company: { name: companyName, rut: companyRut },
      purchaser: { name: purchaserName, rut: purchaserRut },
      totalAmount,
      publicationDate,
      orderingoffer: {
        orderingFinancedAmount,
        invoiceIssuedDate,
      },
    } = row;
    return ({
      id,
      orderNumber: {
        value: orderNumber,
        component: (
          <Typography>{orderNumber}</Typography>
        ),
      },
      company: {
        value: companyName,
        component: (
          <Box>
            <Typography>{companyName}</Typography>
            <Typography>{formatRut(companyRut)}</Typography>
          </Box>
        ),
      },
      purchaser: {
        value: purchaserName,
        component: (
          <Box>
            <Typography>{purchaserName}</Typography>
            <Typography>{formatRut(purchaserRut)}</Typography>
          </Box>
        ),
      },
      totalAmount: {
        value: totalAmount,
        component: (
          <Typography>${formatMoney(totalAmount)}</Typography>
        ),
      },
      publicationDate: {
        value: publicationDate,
        component: (
          <Typography>{moment(publicationDate).format('DD-MM-YYYY')}</Typography>
        ),
      },
      orderingFinancedAmount: {
        value: orderingFinancedAmount,
        component: (
          <Typography>${formatMoney(orderingFinancedAmount)}</Typography>
        ),
      },
      invoiceIssuedDate: {
        value: invoiceIssuedDate,
        component: (
          <Typography color={dateToColor(invoiceIssuedDate)}>{moment(invoiceIssuedDate).format('DD-MM-YYYY')}</Typography>
        ),
      },
      button: {
        value: 'id',
        component: (
          <Button variant="contained" color="primary" onClick={() => addOrderInvoices(row)}>Agregar facturas</Button>
        ),
      },
    });
  });

  if (loading) return <LinearProgress />;

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Ordenes de compra pendientes de factura</b></Typography>
      </Box>
      {(orders && orders.length > 0) ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              headers={headers}
              setNewDataOrder={setOrders}
              data={orders}
              dataToRows={transformDataToComponents}
              initialOrderId="invoiceIssuedDate"
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">No hay ordenes de compra pendientes de factura</Typography>
      )}
      <Snackbar
        open={openErrorDialog}
        autoHideDuration={null}
        onClose={() => setOpenErrorDialog(false)}
        message={localErrorMessage}
      />
    </Box>
  );
};

export default OrderingInvoices;
