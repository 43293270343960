import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Tooltip, Grid, LinearProgress, Button } from '@material-ui/core';
import { CheckCircle, Cancel, Assignment, Cached } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { Scoring } from '../components/scoring';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { useStyles } from './styles';
import { GET_CLIENTS } from '../gql/customer';

const headers = [
  { id: 'rut', label: 'Rut', align: 'left' },
  { id: 'name', label: 'Razón social', align: 'left' },
  { id: 'contract', label: 'Contrato', align: 'center' },
  { id: 'scoring', label: 'Ficha Empresa', align: 'center' },
  { id: 'invoicesAmount', label: 'Monto vigente', align: 'right' },
  { id: 'factorizedAmount', label: 'Monto cedido', align: 'right' },
  { id: 'offersAmount', label: 'Monto ofertado', align: 'right' },
  { id: 'invoicesButton', label: '', align: 'center' },
];

const filters = [
  { id: 'rut', label: 'Rut', filterType: 'text' },
  { id: 'name', label: 'Razón social', filterType: 'text' },
  { id: 'contract', label: 'Contrato', filterType: 'boolean' },
  { id: 'invoicesAmount', label: 'Monto vigente', filterType: 'number' },
  { id: 'factorizedAmount', label: 'Monto cedido', filterType: 'number' },
  { id: 'offersAmount', label: 'Monto ofertado', filterType: 'number' },
];

const Clients = () => {
  const history = useHistory();
  const classes = useStyles();
  const { data, refetch } = useQuery(GET_CLIENTS);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      rut,
      name,
      contract,
      currentInvoices,
      totalLoaned,
      totalOffered } = row;
    return ({
      id: rut,
      rut: {
        value: rut,
        component: (
          <Typography>{formatRut(rut)}</Typography>
        ),
      },
      name: {
        value: name,
        component: (
          <Tooltip title={name}>
            <Typography style={{ maxWidth: 200 }} className={classes.twoLineClamp}>
              {name}
            </Typography>
          </Tooltip>
        ),
      },
      contract: {
        value: contract,
        component: (
          <div className={classes.center}>
            {contract ? <CheckCircle className={classes.success} /> : <Cancel color="error" />}
          </div>
        ),
      },
      scoring: {
        value: 0,
        component: (
          <div className={classes.center}>
            <Tooltip
              interactive
              title={<Scoring rut={rut} />}
              classes={{ tooltip: classes.tooltip }}
            >
              <Assignment />
            </Tooltip>
          </div>
        ),
      },
      invoicesAmount: {
        value: currentInvoices.totalSum,
        component: (
          <>
            <Typography align="right">${formatMoney(currentInvoices.totalSum)}</Typography>
            <Typography align="right">{currentInvoices.totalNumber} facturas</Typography>
          </>
        ),
      },
      factorizedAmount: {
        value: totalLoaned.totalSum,
        component: (
          <>
            <Typography align="right">${formatMoney(totalLoaned.totalSum)}</Typography>
            <Typography align="right">{totalLoaned.totalNumber} facturas</Typography>
          </>
        ),
      },
      offersAmount: {
        value: totalOffered.totalSum,
        component: (
          <>
            <Typography align="right">${formatMoney(totalOffered.totalSum)}</Typography>
            <Typography align="right">{totalOffered.totalNumber} facturas</Typography>
          </>
        ),
      },
      invoicesButton: {
        value: 'null',
        component: (
          <>
            {currentInvoices.totalNumber > 0 ? (
              <Button variant="contained" onClick={() => history.push(`clients/${rut}`)} color="primary">
                Ver facturas
              </Button>
            ) : null}
          </>
        ),
      },
    });
  });

  const [stateData, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      const newData = data.getCompaniesRisk.map((item) => ({
        ...item,
        id: item.rut,
        contract: Boolean(item.contract),
        scoring: item.scoring || 0,
        invoicesAmount: item.currentInvoices.totalSum,
        invoicesQuantity: item.currentInvoices.totalNumber,
        factorizedAmount: item.totalLoaned.totalSum,
        factorizedQuantity: item.totalLoaned.totalNumber,
        offersAmount: item.totalOffered.totalSum,
        offersQuantity: item.totalOffered.totalNumber,
      }));
      setData(newData);
      setLoading(false);
    }
  }, [data]);

  const handleRefetch = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  if (loading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Clientes</b></Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRefetch}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </div>
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Filters
            filters={filters}
            data={stateData}
            setFilteredData={setData}
          />
        </Grid>
        <Grid item xs={12} sm={10} className={classes.tableContainer}>
          <Table
            headers={headers}
            setNewDataOrder={setData}
            data={stateData}
            dataToRows={transformDataToComponents}
            initialOrderId="invoicesAmount"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Clients;
