import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';

const AlertOneButton = ({ open, onClose, message, title, closeMessage }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {message}
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
      >
        {closeMessage || 'Entendido'}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertOneButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  title: PropTypes.string.isRequired,
  closeMessage: PropTypes.string,
};

AlertOneButton.defaultProps = {
  message: '',
  closeMessage: '',

};

export default AlertOneButton;
