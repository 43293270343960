import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddNewContact } from '../contacts';
import { GET_COMPANY_COLLECTION_CONTACTS } from '../../gql/collection';

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 20,
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formField: {
    width: '100%',
    marginBottom: '1rem',
  },
  contactField: {
    width: '50%',
    maxHeight: '10rem',
    marginBottom: '1rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  sendButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '2rem',
    marginTop: '1rem',
    width: '100%',
  },
}));

const actionTypeOptions = [
  { label: 'Llamado telefónico al receptor del documento', value: 'PHONE_CALL_TO_RECEIVER', enabled: 'debtor' },
  { label: 'Llamado telefónico al emisor del documento', value: 'PHONE_CALL_TO_ISSUER', enabled: 'client' },
  { label: 'Mail al receptor', value: 'MAIL_TO_RECEIVER', enabled: 'debtor' },
  { label: 'Mail al emisor', value: 'MAIL_TO_ISSUER', enabled: 'client' },
  { label: 'Envío formulario de pago al receptor', value: 'RECEIVER_FORM_MAIL', enabled: 'debtor' },
  { label: 'Agregar gestión genérica', value: 'OTHER', enabled: 'always' },
];
const sourceToSpanish = {
  PROVIDED_BY_CLIENT: 'Facilitado por el cliente',
  OBTAINED_BY_FINGO: 'Obtenido por Fingo',
};

const AddCollectionActions = ({
  open,
  onClose,
  nameReceiver,
  rutReceiver,
  companyRut,
  companyName,
  onAdd,
}) => {
  const classes = useStyles();
  const [selectedActionType, setSelectedActionType] = useState(null);
  const [comment, setComment] = useState('');
  const [debtorContacts, setDebtorContacts] = useState([]);
  const [clientContacts, setClientContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [error, setError] = useState('');
  const availableOptions = actionTypeOptions.filter((option) => {
    if (option.enabled === 'always') return true;
    if (option.enabled === 'debtor' && rutReceiver) return true;
    if (option.enabled === 'client' && companyRut) return true;
    return false;
  });

  const { data: debtorContactsData,
    loading: debtorContactsLoading,
    refetch: debtorContactsRefetch } = useQuery(GET_COMPANY_COLLECTION_CONTACTS,
    { variables: { companyRut: rutReceiver || 0 }, skip: !rutReceiver, fetchPolicy: 'network-only' });
  const { data: clientContactsData,
    loading: clientContactsLoading,
    refetch: clientContactsRefetch } = useQuery(GET_COMPANY_COLLECTION_CONTACTS,
    { variables: { companyRut: companyRut || 0 }, skip: !companyRut, fetchPolicy: 'network-only' });

  const handleActionTypeChange = (event) => {
    setSelectedActionType(event.target.value);
    setSelectedContacts([]);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleContactChange = (event) => {
    setSelectedContacts(event.target.value);
  };

  const handleAddData = async () => {
    if (!selectedActionType) {
      setError('Debes seleccionar el tipo de gestión');
    } else if (selectedActionType !== 'OTHER' && selectedContacts.length === 0) {
      setError('Debes seleccionar un contacto');
    } else {
      setError('');
      setLoadingResponse(true);
      const actionContactIds = selectedActionType === 'OTHER' ? []
        : selectedContacts.map((contact) => contact.id);
      await onAdd(selectedActionType, comment, actionContactIds);
      setLoadingResponse(false);
      setComment('');
      setSelectedActionType(null);
      setSelectedContacts([]);
    }
  };

  useEffect(() => {
    if (debtorContactsData) {
      const listOfContacts = debtorContactsData.getCompanyCollectionContacts.map((contact) => {
        const name = `${contact.firstName} ${contact.lastName}`;
        const info = `Cargo: ${contact.position} | Teléfono: ${contact.phoneNumber} | Correo: ${contact.email} | Fuente: ${sourceToSpanish[contact.source]}`;
        return { name, info, id: contact.id };
      });
      setDebtorContacts(listOfContacts);
    }
  }, [debtorContactsData]);

  useEffect(() => {
    if (clientContactsData) {
      const listOfContacts = clientContactsData.getCompanyCollectionContacts.map((contact) => {
        const name = `${contact.firstName} ${contact.lastName}`;
        const info = `Cargo: ${contact.position} | Teléfono: ${contact.phoneNumber} | Correo: ${contact.email} | Fuente: ${sourceToSpanish[contact.source]}`;
        return { name, info, id: contact.id };
      });
      setClientContacts(listOfContacts);
    }
  }, [clientContactsData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"><b>Añadir gestión</b></DialogTitle>
      <DialogContent>
        <List className={classes.list}>
          <ListItem className={classes.formContainer}>
            <Typography gutterBottom>
              <b>Tipo de gestión</b>
            </Typography>
            <RadioGroup
              aria-label="collection-action-type"
              value={selectedActionType}
              onChange={handleActionTypeChange}
            >
              {availableOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {selectedActionType && ['MAIL_TO_RECEIVER', 'PHONE_CALL_TO_RECEIVER', 'RECEIVER_FORM_MAIL'].includes(selectedActionType)
              && (
              <>
                {debtorContactsLoading ? (<CircularProgress size={16} />) : (
                  <>
                    <Typography gutterBottom>
                      <b>{selectedActionType === 'RECEIVER_FORM_MAIL'
                        ? 'Contacto con el que te comunicaste' : 'Contacto al que le enviarás el formulario'}
                      </b>
                    </Typography>
                    <Select
                      id="data-collection-contact"
                      multiple
                      value={selectedContacts}
                      onChange={handleContactChange}
                      className={classes.contactField}
                      variant="outlined"
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {
                            selected.map((value) => (
                              <Chip key={value.id} label={value.name} className={classes.chip} />
                            ))
                          }
                        </div>
                      )}
                    >
                      {debtorContacts.map((contact) => (
                        <MenuItem key={contact.id} value={contact}>
                          <Grid container direction="column">
                            <Typography>
                              {contact.name}
                            </Typography>
                            <Typography variant="subtitle2">
                              {contact.info}
                            </Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                    <AddNewContact
                      companyName={nameReceiver}
                      companyRut={rutReceiver}
                      type="COLLECTION"
                      contactsRefetch={debtorContactsRefetch}
                    />
                  </>
                )}
              </>
              )}
            {selectedActionType && ['MAIL_TO_ISSUER', 'PHONE_CALL_TO_ISSUER', 'ISSUER_FORM_MAIL'].includes(selectedActionType)
              && (
              <>
                {clientContactsLoading ? (<CircularProgress size={16} />) : (
                  <>
                    <Typography gutterBottom>
                      <b>Contacto con el que te comunicaste</b>
                    </Typography>
                    <Select
                      id="data-collection-contact"
                      multiple
                      value={selectedContacts}
                      onChange={handleContactChange}
                      className={classes.contactField}
                      variant="outlined"
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {
                            selected.map((value) => (
                              <Chip key={value.id} label={value.name} className={classes.chip} />
                            ))
                          }
                        </div>
                      )}
                    >
                      {clientContacts.map((contact) => (
                        <MenuItem key={contact.id} value={contact}>
                          <Grid container direction="column">
                            <Typography>
                              {contact.name}
                            </Typography>
                            <Typography variant="subtitle2">
                              {contact.info}
                            </Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                    <AddNewContact
                      companyName={companyName}
                      companyRut={companyRut}
                      type="COLLECTION"
                      contactsRefetch={clientContactsRefetch}
                    />
                  </>
                )}
              </>
              )}
            <Typography gutterBottom>
              <b>Comentarios sobre la gestión</b>
            </Typography>
            <TextField
              value={comment}
              onChange={handleCommentChange}
              multiline
              rows={3}
              className={classes.formField}
              variant="outlined"
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <div className={classes.sendButtonContainer}>
          <Typography color="error" variant="caption">{error}</Typography>
          <Button
            onClick={handleAddData}
            color="primary"
            variant="outlined"
            disabled={loadingResponse}
            startIcon={loadingResponse
              ? <CircularProgress size={16} /> : null}
          >
            Añadir
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddCollectionActions.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  nameReceiver: PropTypes.string,
  rutReceiver: PropTypes.number,
  companyRut: PropTypes.number,
  companyName: PropTypes.string,
};

AddCollectionActions.defaultProps = {
  nameReceiver: null,
  rutReceiver: null,
  companyRut: null,
  companyName: null,
};

export default AddCollectionActions;
