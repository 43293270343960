import { InMemoryCache } from '@apollo/client';
import moment from 'moment';

const cacheCreator = () => new InMemoryCache({
  typePolicies: {
    InvoiceType: {
      fields: {
        collector: {
          read(_, { readField }) {
            const receiver = readField('receiver');
            if (!receiver) return null;
            const debtor = readField('debtor', receiver);
            if (!debtor) return null;
            return readField('collector', debtor);
          },
        },
        isFunding: {
          read(_, { readField }) {
            const manager = readField('invoiceloanmanager');
            if (!manager) {
              return {
                value: false,
                socialReason: '',
              };
            }
            const roster = readField('invoiceLoanRoster', manager);
            const fund = readField('fund', roster);
            const entity = readField('masterEntity', fund);
            const entityName = readField('name', entity);
            return {
              value: true,
              socialReason: entityName,
            };
          },
        },
        lightningPaymentAuthorized: {
          read(_, { readField }) {
            const dateIssued = readField('dateIssued');
            const states = readField('uniqueDocumentFinanceState');
            if (moment().diff(dateIssued, 'days') > 15) return false;
            if (!states?.length) return false;
            const payedState = states.find((state) => {
              const status = readField('status', state);
              const code = readField('code', status);
              return code === '5';
            });
            return !!payedState;
          },
        },
      },
    },
    FundType: {
      fields: {
        rut: {
          read(_, { readField }) {
            const entity = readField('masterEntity');
            return readField('rut', entity);
          },
        },
        socialReason: {
          read(_, { readField }) {
            const entity = readField('masterEntity');
            return readField('name', entity);
          },
        },
      },
    },
    CompanyType: {
      fields: {
        siiCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === 'government',
            );
          },
        },
        invoiceProviderCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === 'electronic_invoices',
            );
          },
        },
        supplierPortalCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter(
              (cred) => readField(
                'name',
                readField(
                  'credentialProviderType',
                  readField('credentialProvider', cred),
                ),
              ) === 'supplier_platform',
            );
          },
        },
        hasSiiCredentials: {
          read(_, { readField }) {
            const credentials = readField('siiCredentials');
            return credentials.length > 0;
          },
        },
        hasInvoiceProviderCredentials: {
          read(_, { readField }) {
            const credentials = readField('invoiceProviderCredentials');
            return credentials.length > 0;
          },
        },
        hasSupplierPortalCredentials: {
          read(_, { readField }) {
            const credentials = readField('supplierPortalCredentials');
            return credentials.length > 0;
          },
        },
      },
    },
    CreditLineInfoType: {
      fields: {
        limitAmount: {
          read(_, { readField }) {
            const currentLimit = readField('currentLimit');
            if (!currentLimit) return null;
            return readField('newLimit', currentLimit);
          },
        },
        lastUpdate: {
          read(_, { readField }) {
            const currentLimit = readField('currentLimit');
            if (!currentLimit) return null;
            return readField('createdAt', currentLimit);
          },
        },
        creditUsed: {
          read(_, { readField }) {
            const lastMovement = readField('lastMovement');
            if (!lastMovement) return null;
            return readField('totalCreditUsed', lastMovement);
          },
        },
      },
    },
  },
});

export default cacheCreator;
