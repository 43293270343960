import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { esES } from '@material-ui/core/locale';
import { ApolloClient, ApolloProvider /* , HttpLink */ } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import './globalConfig';
import { UserContextProvider, UserContext } from './context/user';
import App from './App';
import * as serviceWorker from './serviceWorker';
import customFetch from './helpers/customFetch';
import cacheCreator from './cache/createNewCache';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF2B68',
    },
    text: {
      primary: '#5A5A5A',
      secondary: '#ABABAB',
    },
  },
  typography: {
    fontFamily: 'Ubuntu',
    h1: {
      fontWeight: 400,
      lineHeight: '56px',
      fontSize: 48,
    },
    h2: {
      fontWeight: 400,
      fontSize: 40,
      lineHeight: '46px',
    },
    h3: {
      fontWeight: 400,
      fontSize: 30,
      lineHeight: '36px',
    },
    h4: {
      fontWeight: 300,
      fontSize: 26,
      lineHeight: '32px',
    },
    h5: {
      fontWeight: 400,
      fontsize: 20,
      lineHeight: '26px',
    },
    h6: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 13,
      lineHeight: '22px',
    },
    subtitle1: {
      lineHeight: '20px',
    },
    caption: {
      fontSize: 12,
      lineHeight: '20px',
      color: '#ABABAB',
    },
    button: {
      fontWeight: 400,
      fontSize: 18,
      textTransform: 'none',
      lineHeight: '22px',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '8px 14px',
      },
    },
    MuiButton: {
      contained: {
        height: 50,
      },
      outlined: {
        height: 50,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiListItemText: {
      inset: {
        paddingLeft: '1rem',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#FF2B68',
        color: '#FFFFFF',
      },
    },

  },
}, esES);

const ApolloRouting = () => {
  const { login, user } = useContext(UserContext);
  const cache = cacheCreator();
  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
    fetch: (uri, options) => customFetch(uri, options, login),
  });
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: user ? `JWT ${user.token}` : '',
    },
  }));

  const apolloClient = new ApolloClient({
    cache,
    link: authLink.concat(uploadLink),
  });

  return (
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <UserContextProvider>
        <ApolloRouting />
      </UserContextProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
