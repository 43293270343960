import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Typography, Paper, Grid, Button, Radio, TextField } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { RISK_RESULTS } from '../../gql/riskAlgorithm';
import TableResults from './TableResults';
import { Loading } from '../loading';
import { Error } from '../error';

const styles = makeStyles(() => ({
  gridContainer: {
    padding: '2rem 20rem',
    justifyContent: 'space-around',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
  },
  centerContent: {
    justifyContent: 'center',
  },
  top: {
    marginTop: '1rem',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  radiosContainer: {
    paddingLeft: '10rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalEv: {
    marginLeft: '1rem',
  },
}));

const GlobalResults = () => {
  const [variables, setVariables] = useState({
    rutClient: '',
    rutDebtor: '',
    rejected: false,
    approved: false,
    statusRejected: false,
  });
  const [checkedVariables, setCheckedVariables] = useState({
    rutClient: '',
    rutDebtor: '',
    rejected: false,
    approved: false,
    statusRejected: false,
  });
  const { data, loading, refetch, client, error } = useQuery(
    RISK_RESULTS, { notifyOnNetworkStatusChange: true, variables },
  );
  const [globalResult, setGlobalResult] = useState();
  const [riskResults, setRiskResults] = useState();
  const classes = styles();
  useEffect(() => {
    if (loading) return;
    if (data) {
      setGlobalResult({ ...data.riskEvaluationGlobal });
      setRiskResults([...data.riskEvaluationResults]);
    }
  }, [loading, data]);
  const handleRadioChange = (e, name) => {
    const newCheckedVariables = { ...checkedVariables, [name]: !checkedVariables[name] };
    setCheckedVariables(newCheckedVariables);
    client.query({
      query: RISK_RESULTS,
      variables: newCheckedVariables,
    });
  };
  const changeVariables = () => {
    setVariables({ ...checkedVariables });
  };
  if (error) return <Error />;
  if (loading || !globalResult || !riskResults) return <Loading />;
  return (
    <>
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => refetch()}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </div>
      <div className={classes.title}>
        <Typography variant="h4">Total Evaluaciones:</Typography>
        <Typography color="primary" variant="h2">{globalResult.totalEvaluations}</Typography>
      </div>
      <div className={classes.title}>
        <Typography variant="h5">Total Cte:</Typography>
        <Typography color="primary" variant="h4">{globalResult.totalCte}</Typography>
      </div>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={5} sm={5} md={5}>
          <Paper elevation={3} variant="elevation" style={{ padding: '3rem' }}>
            <Typography variant="h4" color="textPrimary">Aprobados en el árbol:</Typography>
            <div className={`${classes.row} ${classes.centerContent} ${classes.top}`}>
              <Typography variant="h3" color="primary" style={{ alignItems: 'flex-end' }}>{globalResult.treeApproved}</Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5} sm={5} md={5}>
          <Paper elevation={3} variant="elevation" style={{ padding: '3rem' }}>
            <Typography variant="h4" color="textPrimary">Aprobados en el RAS:</Typography>
            <div className={`${classes.row} ${classes.centerContent} ${classes.top}`}>
              <Typography variant="h3" color="primary" style={{ alignItems: 'flex-end' }}>{globalResult.rasApproved}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={5} sm={5} md={5}>
          <Paper elevation={3} variant="elevation" style={{ padding: '3rem' }}>
            <Typography variant="h4" color="textPrimary">Aprobados en total:</Typography>
            <div className={`${classes.row} ${classes.centerContent} ${classes.top}`}>
              <Typography variant="h2" color="primary" style={{ alignItems: 'flex-end' }}>{globalResult.totalApproved}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} md={6} sm={6} className={classes.radiosContainer}>
          <Grid item className={`${classes.row} ${classes.alignItemsCenter}`} xs={12} md={12} sm={12}>
            <Radio
              checked={checkedVariables.rejected}
              value="rejected"
              onClick={(e) => handleRadioChange(e, 'rejected')}
            />
            <Typography>Mostrar los rechazados por el algoritmo</Typography>
          </Grid>
          <Grid item className={`${classes.row} ${classes.alignItemsCenter}`} xs={12} md={12} sm={12}>
            <Radio
              checked={checkedVariables.approved}
              value="rejected"
              onClick={(e) => handleRadioChange(e, 'approved')}
            />
            <Typography>Mostrar los aprobados por el algoritmo</Typography>
          </Grid>
          <Grid item className={`${classes.row} ${classes.alignItemsCenter}`} xs={12} md={12} sm={12}>
            <Radio
              checked={checkedVariables.statusRejected}
              value="rejected"
              onClick={(e) => handleRadioChange(e, 'statusRejected')}
            />
            <Typography>Mostrar los rechazados por Rocío</Typography>
          </Grid>
          <Grid item className={`${classes.row} ${classes.alignItemsCenter}`} xs={12} md={12} sm={12}>
            <TextField
              variant="outlined"
              label="Rut Cliente"
              type="number"
              value={checkedVariables.rutClient}
              onChange={
                (e) => setCheckedVariables({ ...checkedVariables, rutClient: e.target.value })
              }
            />
          </Grid>
          <Grid item className={`${classes.row} ${classes.alignItemsCenter}`} xs={12} md={12} sm={12}>
            <TextField
              variant="outlined"
              label="Rut Deudor"
              type="number"
              value={checkedVariables.rutDebtor}
              onChange={
                (e) => setCheckedVariables({ ...checkedVariables, rutDebtor: e.target.value })
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={6} md={6} sm={6} className={`${classes.row} ${classes.alignItemsCenter}`}>
          <Button
            color="primary"
            variant="contained"
            onClick={changeVariables}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
      <TableResults data={riskResults} loading={loading} />
    </>
  );
};

export default GlobalResults;
