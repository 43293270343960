import moment from 'moment';
import calculateInvoiceAmounts from './invoiceAmountsCalculation';

const MAX_FINANCING_DAYS_ALLOWED = 180;

const transformInvoice = (invoice) => {
  let defaultRate;
  let monthlyRate;
  let retentionRate;
  let id;
  let status;
  let transferCondition;
  if (invoice.offer) {
    defaultRate = invoice.offer.defaultRate;
    monthlyRate = invoice.offer.monthlyRate;
    retentionRate = invoice.offer.retentionRate || 0;
    id = invoice.offer.id;
    transferCondition = invoice.offer.transferCondition;
  } else if (invoice.preoffer) {
    defaultRate = invoice.preoffer.defaultRate;
    monthlyRate = invoice.preoffer.monthlyRate;
    retentionRate = invoice.preoffer.retentionRate || 0;
    id = invoice.preoffer.id;
    status = invoice.preoffer.preofferevaluationrequest ? invoice.preoffer.preofferevaluationrequest.status : 'CREATED';
  }
  const dateExpiration = moment(invoice.dateExpiration, 'YYYY-MM-DD');
  const startOfDay = moment().startOf('day');
  const daysUntilExpiration = dateExpiration.diff(startOfDay, 'days');
  const dateToPay = daysUntilExpiration > MAX_FINANCING_DAYS_ALLOWED ? startOfDay.clone().add(180, 'days') : dateExpiration.clone();
  const daysBetweenPayAndNow = dateToPay.diff(moment().startOf('day'), 'days');
  const invoiceData = {
    days: daysBetweenPayAndNow,
    retentionRate,
    monthlyRate,
    amountWithIva: invoice.amountWithIva,
  };
  const {
    effectiveRate,
    retentionAmount,
    advanceAmount,
    amountMinusInterests,
    priceDifference,
  } = calculateInvoiceAmounts(invoiceData);
  return ({
    ...invoice,
    invoiceId: invoice.id,
    id,
    status,
    dateExpiration,
    dateToPay,
    effectiveRate,
    amountMinusInterests,
    advanceAmount,
    retentionAmount,
    nonSelectable: status && status !== 'CREATED',
    dateIssued: moment(invoice.dateIssued, 'YYYY-MM-DD'),
    folio: invoice.folio.toString(),
    companyRut: invoice.company.rut,
    companyId: invoice.company.id,
    monthlyRate,
    defaultRate,
    advancePercentage: 100 - retentionRate,
    priceDifference,
    transferCondition,
  });
};

export default transformInvoice;
