import moment from 'moment';
import { filterRuleResults } from '../components/risk/Helpers';
import { formatMoney } from './money';

export const transformPreofferEvaluationRequest = (preofferEvaluationRequest) => {
  const {
    rejectionReasonLabel,
    updatedAt,
    preoffer: {
      monthlyRate,
      defaultRate,
      retentionRate,
      invoice: {
        id,
        folio,
        nameReceiver,
        rutReceiver,
        dateIssued,
        receiver,
        dateExpiration,
        amountWithIva,
        confirming,
        pdf,
      },
    },
  } = preofferEvaluationRequest;
  return {
    id,
    folio,
    nameReceiver,
    rutReceiver,
    dateIssued: moment(dateIssued, 'YYYY-MM-DD'),
    dateExpiration: moment(dateExpiration, 'YYYY-MM-DD'),
    amountWithIva,
    monthlyRate,
    defaultRate,
    advancePercentage: 100 - retentionRate,
    confirming,
    pdf,
    rejectionReason: rejectionReasonLabel,
    updatedAt: moment(updatedAt, 'YYYY-MM-DD'),
    receiver,
  };
};

export const getDialogLikeRiskResult = (type, riskEvaluationModel) => {
  const resultData = filterRuleResults(riskEvaluationModel, type);
  const resultHeader = (type === 'RAS')
    ? [
      riskEvaluationModel.rasClientResult,
      riskEvaluationModel.rasDebtorResult,
      riskEvaluationModel.rasClientDebtorResult,
    ] : [
      riskEvaluationModel.treeClientResult,
      riskEvaluationModel.treeDebtorResult,
      riskEvaluationModel.treeClientDebtorResult,
    ];
  return {
    type,
    open: true,
    data: resultData,
    header: resultHeader,
  };
};

export const getPreofferHistoryTooltip = (clientHistory, clientReceiverHistory, receiverHistory) => `
    Información histórica con Fingo:

    N° de Facturas cliente: ${clientHistory ? clientHistory.cessionsNumber : 0}
    Monto facturas cliente: $${formatMoney(clientHistory ? clientHistory.cessionsMoneyAmount : 0)}
    N° de Facturas historicas cliente: ${clientHistory ? clientHistory.historicCessionsNumber : 0}
    Monto facturas historicas cliente: $${formatMoney(clientHistory ? clientHistory.historicCessionsMoneyAmount : 0)}

    N° de Facturas cliente-deudor: ${clientReceiverHistory ? clientReceiverHistory.cessionsNumber : 0}
    Monto facturas cliente-deudor: $${formatMoney(clientReceiverHistory ? clientReceiverHistory.cessionsMoneyAmount : 0)}
    N° de Facturas historicas cliente-deudor: ${clientReceiverHistory ? clientReceiverHistory.historicCessionsNumber : 0}
    Monto facturas historicas cliente-deudor: $${formatMoney(clientReceiverHistory ? clientReceiverHistory.historicCessionsMoneyAmount : 0)}

    N° de Facturas Deudor: ${receiverHistory ? receiverHistory.cessionsNumber : 0}
    Monto facturas Deudor: $${formatMoney(receiverHistory ? receiverHistory.cessionsMoneyAmount : 0)}
    N° de Facturas historicas Deudor: ${receiverHistory ? receiverHistory.historicCessionsNumber : 0}
    Monto facturas historicas Deudor: $${formatMoney(receiverHistory ? receiverHistory.historicCessionsMoneyAmount : 0)}
  `;
