/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: 'none',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ABABAB',
  },
  uploadStatus: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: 12,
    fontSize: 16,
  },
  acceptButton: {
    color: theme.palette.success.main,
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  warningButton: {
    color: theme.palette.warning.main,
  },
  error: {
    padding: '0 8px',
    marginTop: '1rem',
    color: theme.palette.error.main,
  },
}));
