import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import {
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTimeDiffText, getTermTextBetweenDates } from '../../helpers/date';
import { formatMoney } from '../../helpers/money';
import { formatRut } from '../../helpers/generic';

const useStyles = makeStyles((theme) => ({
  twoLineClamp: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  statusCell: {
    paddingTop: '13px !important',
  },
  factorizedAmount: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const Cell = ({ type, data, extraData, tooltip }) => {
  const classes = useStyles();
  if (type === 'rut') {
    return (
      <TableCell>
        {formatRut(data)}
      </TableCell>
    );
  }
  if (type === 'text') {
    return (
      <TableCell size="small">
        {data}
      </TableCell>
    );
  }
  if (type === 'longText') {
    return (
      <TableCell>
        <Tooltip title={data}>
          <Typography variant="body2" className={classes.twoLineClamp}>{data}</Typography>
        </Tooltip>
      </TableCell>
    );
  }
  if (type === 'date') {
    return (
      <TableCell size="small">
        <div className={classes.dateContainer}>
          <Typography className={classes.date}>{data.format('DD-MM-YYYY')}</Typography>
          <Typography variant="caption">{getTimeDiffText(data)}</Typography>
        </div>
      </TableCell>
    );
  }
  if (type === 'comparedDate') {
    return (
      <TableCell size="small">
        <div className={classes.dateContainer}>
          <Typography className={classes.date}>{data.format('DD-MM-YYYY')}</Typography>
          <Tooltip title={tooltip}>
            <Typography variant="caption">{getTermTextBetweenDates(extraData, data)}</Typography>
          </Tooltip>
        </div>
      </TableCell>
    );
  }
  if (type === 'money') {
    return (
      <TableCell size="small">
        ${formatMoney(data)}
      </TableCell>
    );
  }
  if (type === 'importantMoney') {
    return (
      <TableCell size="small" className={classes.factorizedAmount}>
        ${formatMoney(data)}
      </TableCell>
    );
  }
  if (type === 'rate') {
    return (
      <TableCell size="small">
        {(data * 100).toFixed(2)}%
      </TableCell>
    );
  }
  // if (type === 'status') {
  //   return (
  //     <TableCell size="small" className={classes.statusCell}>
  //       <Status status={data} debt={extraData} />
  //     </TableCell>
  //   );
  // }
  // if (type === 'inputDate') {
  //   return (
  //     <TableCell size="small" align="right">
  //       <div className={classes.datePickerContainer}>
  //         <DatePicker
  //           autoOk
  //           allowKeyboardControl={false}
  //           style={{ width: '90%' }}
  //           disableToolbar
  //           variant="inline"
  //           format="DD-MM-YYYY"
  //           margin="none"
  //           inputVariant="outlined"
  //           disablePast
  //           value={data}
  //           onChange={handleChangeDate}
  //           inputProps={{
  //             style: {
  //               padding: 5,
  //               fontSize: 12,
  //               cursor: 'pointer',
  //             },
  //           }}
  //         />
  //         <Typography variant="caption" align="left">
  //           En {data.diff(moment().startOf('day'), 'days')} días
  //           (tasa {
  //            extraData ? `${(extraData * 100).toFixed(2)}%` : `${(extraData2 * 100).toFixed(2)}%`
  //           })
  //         </Typography>
  //       </div>
  //     </TableCell>
  //   );
  // }
  return null;
};

Cell.propTypes = {
  type: PropTypes.oneOf(['text', 'longText', 'date', 'comparedDate', 'money', 'importantMoney', 'rate', 'status', 'inputDate', 'rut', 'boolean', 'number']).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']),
    momentPropTypes.momentObj,
  ]).isRequired,
  extraData: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']),
    momentPropTypes.momentObj,
  ]),
  tooltip: PropTypes.string,
};

Cell.defaultProps = {
  extraData: '',
  tooltip: '',
};

export default Cell;
