import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import RatificationContactsTable from '../dialogs/RatificationContactsTable';

const ManageRatificationContacts = ({ openDialog, receiverData, setOpenDialog }) => (
  <Dialog
    open={openDialog}
    onClose={() => setOpenDialog(false)}
    maxWidth="md"
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">
      <Typography variant="h4" align="center">Gestionar contactos de {receiverData.companyName}</Typography>
    </DialogTitle>
    <DialogContent>
      <RatificationContactsTable
        companyRut={receiverData.companyRut}
        companyName={receiverData.companyName}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(false)}>Cerrar</Button>
    </DialogActions>
  </Dialog>
);

ManageRatificationContacts.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  receiverData: PropTypes.objectOf(PropTypes.object).isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};

export default ManageRatificationContacts;
