/* eslint-disable import/prefer-default-export */
export const statusToSpanish = {
  PROCESSING: 'Procesando',
  PENDINGTRANSFER: 'Por depositar',
  TRANSFERED: 'Depositada',
  DEBT: 'En mora',
};

export const getRutValidatorDigit = (rut) => {
  let rutWithoutDigit = rut;
  let base = 2;
  let sum = 0;
  for (; rutWithoutDigit; rutWithoutDigit = Math.floor(rutWithoutDigit / 10)) {
    sum += (rutWithoutDigit % 10) * base;
    base += 1;
    if (base > 7) base = 2;
  }
  const vDigit = 11 - (sum % 11);
  if (vDigit === 10) return 'k';
  if (vDigit === 11) return '0';
  return vDigit;
};

const isNumeric = (value) => !Number.isNaN(parseInt(value, 10));

export const formatRut = (rut) => {
  const rutStr = rut?.toString() || '';
  const { length } = rutStr;
  if (!isNumeric(rutStr)) {
    return rutStr;
  }
  return `${rutStr.substr(0, length - 6)}.${rutStr.substr(length - 6, 3)}.${rutStr.substr(length - 3, 3)}-${getRutValidatorDigit(rutStr)}`;
};

export const parseRut = (rut) => `${parseInt(rut.slice(0, -1), 10).toLocaleString('de-DE')}-${rut.charAt(rut.length - 1)}`;

export const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

export const transactionMapper = {
  transfer: 'Transferencia',
  other: 'Otra',
  INVOICE: 'Facturas',
};

export const toPercentage = (floatNumber) => parseFloat(floatNumber).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });

export const percentageVariation = (firstValue, secondValue) => {
  if (firstValue === 0 || firstValue === null || secondValue === null) {
    return null;
  }
  return ((secondValue - firstValue) / firstValue) * 100;
};
