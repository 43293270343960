import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Slider,
} from '@material-ui/core';
import { formatMoney } from '../../helpers/money';
import { formatRut } from '../../helpers/generic';

const useStyles = makeStyles(() => ({
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  choiceTitle: {
    marginBottom: '1rem',
  },
  slider: {
    margin: '1rem 0 0 0.5rem',
  },
  sliderValueLabel: {
    left: '-50%',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
}));

const OverdraftHandler = ({
  overdraft,
  onChoiceChange,
  onAmountChange,
  companyName, companyRut,
}) => {
  const classes = useStyles();
  const [selectedChoice, setSelectedChoice] = useState('OVERDRAFT');
  const [selectedAmount, setSelectedAmount] = useState(0);

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
    onAmountChange(amount);
  };

  const handleChoiceChange = (choice) => {
    setSelectedChoice(choice);
    onChoiceChange(choice);
    // onChoiceChange sets the amount to 0 in the company object of parent component
    setSelectedAmount(0);
  };

  const sliderValueToText = (value) => `$${formatMoney(value)}`;

  return (
    <>
      <Typography gutterBottom>
        <b>{companyName.toUpperCase()}, Rut {formatRut(companyRut)}</b>
      </Typography>
      <Typography gutterBottom>
        {`Supera su línea disponible en ${formatMoney(overdraft)}, ¿Qué quieres hacer?`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant={selectedChoice === 'OVERDRAFT' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleChoiceChange('OVERDRAFT')}
          >
            Sobregirar
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant={selectedChoice === 'RISE_LINE' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleChoiceChange('RISE_LINE')}
          >
            Aumentar línea
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant={selectedChoice === 'NOTHING' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleChoiceChange('NOTHING')}
          >
            Nada
          </Button>
        </Grid>
        <Grid container style={{ padding: '0 0.5rem' }}>
          {selectedChoice === 'OVERDRAFT' && (
            <div className={classes.choiceContainer}>
              <Typography className={classes.choiceTitle}>
                ¿Cuánto quieres sobregirar?
              </Typography>
              <Slider
                defaultValue={0}
                aria-labelledby="discrete-slider-small-steps"
                getAriaValueText={sliderValueToText}
                valueLabelFormat={sliderValueToText}
                step={Math.min(500000, parseInt(overdraft / 20, 10))}
                marks
                value={selectedAmount}
                min={0}
                max={overdraft}
                valueLabelDisplay="on"
                onChange={(_, value) => handleAmountChange(value)}
                className={classes.slider}
                classes={{
                  valueLabel: classes.sliderValueLabel,
                }}
              />
            </div>
          )}
          {selectedChoice === 'RISE_LINE' && (
            <div className={classes.choiceContainer}>
              <Typography className={classes.choiceTitle}>
                ¿Cuánto quieres aumentar la línea?
              </Typography>
              <TextField
                type="number"
                value={selectedAmount}
                onChange={(e) => handleAmountChange(e.target.value)}
                variant="outlined"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

OverdraftHandler.propTypes = {
  overdraft: PropTypes.number.isRequired,
  onChoiceChange: PropTypes.func.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  companyRut: PropTypes.number.isRequired,
};

export default OverdraftHandler;
