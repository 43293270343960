import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DataForCollection from '../DataForCollection';

const useStyles = makeStyles(() => ({
  baseContainer: {
    width: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&&::-webkit-scrollbar': { /* Chrome, Safari, Opera */
      display: 'none',
    },
    scrollbarWidth: 'none', /* Firefox */
    msOverflowStyle: 'none', /* IE */
  },
  collectionDataContainer: {
    height: '25%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '2rem',
    marginTop: '1rem',
  },
}));

const ActionsDrawerCollectionDataInfo = (props) => {
  const { dataForCollection, disableActions, openAddDataForCollection } = props;
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.baseContainer, classes.collectionDataContainer)}>
      <Typography variant="h6">Datos para recaudación</Typography>
      <DataForCollection collectionData={dataForCollection} />
      {!disableActions
        && (
        <div className={classes.buttonContainer}>
          <Button onClick={openAddDataForCollection} color="primary" variant="outlined">
            Editar datos de recaudación
          </Button>
        </div>
        )}
    </ListItem>
  );
};

ActionsDrawerCollectionDataInfo.propTypes = {
  dataForCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  disableActions: PropTypes.bool.isRequired,
  openAddDataForCollection: PropTypes.func.isRequired,
};

export default ActionsDrawerCollectionDataInfo;
