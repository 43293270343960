import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles({
  textField: {
    backgroundColor: 'white',
    minWidth: 500,
  },
});

const SearchFilter = ({ filterValue, setFilterValue, placeholder }) => {
  const classes = useStyles();

  const handleSearchChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <TextField
      name="search"
      onChange={handleSearchChange}
      value={filterValue}
      variant="outlined"
      className={classes.textField}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchFilter.propTypes = {
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchFilter.defaultProps = {
  placeholder: 'Buscar',
};

export default SearchFilter;
