import { gql } from '@apollo/client';

export const GET_MOVEMENTS = gql`
    query getMovements($movementType:String!){
        getMovements(movementType:$movementType){
        id
        senderHolderName
        senderHolderId
        recipientHolderName
        recipientHolderId
        postDate
        amount
        description
        }
    }
`;

export const GET_CONCILIABLE_INVOICES = gql`
  query getConciliableInv {
    getConciliableInvoices {
      id
      folio
      receiver{
        name
        rut
        dv
      }
      dateToPay
      amountWithIva
      collectionManager{
        id
      }
    }
  }
`;

export const GET_CONCILIABLE_ORDERS = gql`
  query getConciliableOrders {
    getConciliableOrders {
      id
      orderNumber
      company {
        id
        name
        rut
      }
      purchaser {
        name
        rut
        dv
      }
      orderingoffer {
        orderingAmount
        orderingFinancedAmount
      }
      orderingDebt {
        debt
      }
      collectionManager {
        id
      }
    }
  }
`;

export const GET_CONCILIABLE_ROSTERS = gql`
  query getConciliableRosters {
    getConciliableRosters {
      id
      status
      recesionDate
      invoicesCount
      invoicesAmount
      transferAmount
      fund {
        rut
        socialReason
      }
      collectionManager {
        id
      }
      cessionFund {
        rut
        socialReason
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query getRequest {
    getRequests {
      id
      bankAccount {
        bankName
        accountRut
        company {
          id
          name
          masterEntity{
            rut
            dv
          }
        }
      }
      transferType
      transferDate
      amount
    }
  }
`;

export const GET_PENDING_COLLECTION_NOTES = gql`
  query GetPendingCollectionNotes {
    getPendingCollectionNotes {
      id
      company {
        id
        name
        masterEntity {
          id
          rut
          dv
        }
      }
      amountOfMoneyToCollect
      collectionManagers {
        id
      }
    }
  }
`;

export const CONCILIATE = gql`
  mutation conciliate($movements: [Int]!, $collectionManagers: [CollectionManagerInputType], $requests: [Int], $conciliationType: String!, $conciliationDate: DateTime!) {
    conciliate(movements: $movements, requests: $requests, conciliationType: $conciliationType, conciliationDate: $conciliationDate, collectionManagers: $collectionManagers) {
      success
    }
  }
`;

export const GET_UNPAYED_DIFFERENCE_REASONS = gql`
  query GetUnpayedDifferenceReasons{
    getUnpayedDifferenceReasons{
      value
      label
    }
  }
`;

export const HIDE_MOVEMENT = gql`
  mutation hide($movement:Int!){
    hideBankMovement(movement:$movement){
      success
    }
  }
`;

export const GET_PAYSHEETS = gql`
  query GetPaysheets {
    getPaysheets {
      id
      amount
      amountOfPayments
    }
  }
`;

export const CONCILIATE_PAYSHEET = gql`
  mutation ConciliatePaysheet($movementsIds: [Int]!, $paysheetsIds: [Int]!) {
    conciliatePaysheet(movementsIds: $movementsIds, paysheetsIds: $paysheetsIds) {
      success
    }
  }
`;
