import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import { formatMoney } from '../../../helpers/money';

const useStyles = makeStyles(() => ({
  invoiceInfoContainer: {
    height: '8%',
  },
}));

const ActionsDrawerInvoiceInfo = ({ nameReceiver, folio, amount }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.invoiceInfoContainer}>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{ whiteSpace: 'pre-line' }}
      >
        <b>Deudor: </b> {`${nameReceiver}\n`}
        <b>Factura: </b> {`${folio}\n`}
        <b>Monto: </b> {`$${formatMoney(amount)}`}
      </Typography>
    </ListItem>
  );
};

ActionsDrawerInvoiceInfo.propTypes = {
  nameReceiver: PropTypes.string.isRequired,
  folio: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default ActionsDrawerInvoiceInfo;
