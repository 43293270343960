import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../../../views/styles';

const useOwnStyles = makeStyles(() => ({
  color: { color: '#14234' },
}));

const TooltipColumn = ({ tooltip, value }) => {
  const classes = { ...useStyles(), ...useOwnStyles() };

  return (
    <Tooltip
      interactive
      classes={{ tooltip: classes.tooltip }}
      title={tooltip}
    >
      <div style={{ maxWidth: 300 }}>
        <Typography className={classes.color}>
          {value}
        </Typography>
      </div>
    </Tooltip>
  );
};

TooltipColumn.propTypes = {
  tooltip: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TooltipColumn;
