import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles, Button } from '@material-ui/core';
import { GET_PENDING_RESTRICTIONS } from '../../gql/customer';
import VisualizeAllRestrictions from './VisualizeAllRestrictions';

const useStyles = makeStyles({
  warning: {
    fontSize: 80,
  },
  margins: {
    marginTop: '10px',
    marginBottom: '10px',
  },
});

const CompanyRestrictions = ({ clientRut,
  companyId,
  clientSocialReason, sethasRestrictions, hasRestrictions }) => {
  const classes = useStyles();
  const [showRestrictionsManager, setshowRestrictionsManager] = useState(false);
  const handleRestrictionsManager = () => {
    setshowRestrictionsManager(!showRestrictionsManager);
  };
  const handleResolveRestriction = () => {};
  const { data,
    loading: loadingRestrictions,
    error: errorRestrictions,
  } = useQuery(GET_PENDING_RESTRICTIONS,
    {
      variables: {
        companyRut: clientRut,
      },
      fetchPolicy: 'cache-and-network',
    });
  useEffect(() => {
    if (data && data.getPendingCompanyRestrictions) {
      if (data.getPendingCompanyRestrictions.length > 0) {
        sethasRestrictions({ ...hasRestrictions, [clientRut]: true });
      } else {
        sethasRestrictions({ ...hasRestrictions, [clientRut]: false });
      }
    }
  }, [data]);

  return (
    <Grid item xs={12} align="center">
      {loadingRestrictions && (<Typography>Cargando Restricciones</Typography>)}
      {errorRestrictions && (
      <Typography>
        Hubo un error al cargar las restricciones. Intenta de nuevo
      </Typography>
      )}
      {data && data.getPendingCompanyRestrictions
      && !(data.getPendingCompanyRestrictions.length === 0)
      && (
        <>
          <Typography> Para el cliente {clientSocialReason}: </Typography>
          {data.getPendingCompanyRestrictions
            && (data.getPendingCompanyRestrictions.map((res) => (
              <Grid>
                <Typography key={res.label}>
                  {res.label}
                </Typography>
              </Grid>
            )))}
          {showRestrictionsManager ? (
            <Grid item xs={6}>
              <VisualizeAllRestrictions
                handleResolveRestriction={handleResolveRestriction}
                clientRut={clientRut}
                companyId={companyId}
                isPreofferPanel={false}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => { handleRestrictionsManager(); }}
              >
                Volver
              </Button>
            </Grid>
          )
            : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => { handleRestrictionsManager(); }}
                className={classes.margins}
              >
                Gestionar Restricciones
              </Button>
            )}
        </>
      )}
    </Grid>
  );
};

CompanyRestrictions.propTypes = {
  clientRut: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  clientSocialReason: PropTypes.string.isRequired,
  sethasRestrictions: PropTypes.func.isRequired,
  hasRestrictions: PropTypes.shape({}).isRequired,
};

export default CompanyRestrictions;
