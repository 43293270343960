import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Icon,
  Button,
  LinearProgress,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { CreateOrEditWhitelist, OneButtonAlert } from '../components/dialogs';
import {
  GET_WHITELISTS, CREATE_WHITELIST, SWITCH_WHITELIST_RULE_STATE, EDIT_WHITELIST,
} from '../gql/whitelist';
import ErrorComponent from '../components/utils/ErrorComponent';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'active', label: 'Activa', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'monthlyRate', label: 'Tasa', align: 'left' },
  { id: 'defaultRate', label: 'Mora', align: 'left' },
  { id: 'retentionRate', label: 'Retención', align: 'left' },
  { id: 'button', label: '', align: 'left' },
];

const filters = [
  { id: 'clientName', label: 'Cliente', filterType: 'text' },
  { id: 'debtorName', label: 'Deudor', filterType: 'text' },
];

const localStyles = makeStyles(() => ({
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  filters: {
    width: '40%',
    marginRight: '1rem',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}));

const Whitelist = () => {
  const classes = useStyles();
  const localClasses = localStyles();
  const [loading, setLoading] = useState(true);
  const [whitelists, setWhitelists] = useState([]);
  const [allWhitelists, setAllWhitelists] = useState([]);
  const [filteredWhitelists, setFilteredWhitelists] = useState(whitelists);
  const [openNewWhitelist, setOpenNewWhitelist] = useState(false);
  const [openEditWhitelist, setOpenEditWhitelist] = useState(false);
  const [editingWhitelist, setEditingWhitelist] = useState(null);
  const [openResultsMessage, setOpenResultsMessage] = useState(false);
  const [resultsMessage, setResultsMessage] = useState('');
  const [selected, setSelected] = useState({
    actives: { label: 'Activas', value: true },
    inactives: { label: 'Inactivas', value: true },
  });
  const { data, error } = useQuery(GET_WHITELISTS);
  const [createWhitelist] = useMutation(CREATE_WHITELIST);
  const [editWhitelist] = useMutation(EDIT_WHITELIST);
  const [switchWhitelistRuleState] = useMutation(SWITCH_WHITELIST_RULE_STATE);

  useEffect(() => {
    if (data) {
      const newData = data.getRateWhitelists.map((item) => ({
        id: item.id,
        clientRut: item.emitter && item.emitter.rut,
        clientName: item.emitter && item.emitter.name,
        debtorRut: item.receiver && item.receiver.rut,
        debtorName: item.receiver && item.receiver.name,
        debtorId: item.receiver && item.receiver.id,
        monthlyRate: item.monthlyRate,
        defaultRate: item.defaultRate,
        retentionRate: item.retentionRate,
        active: item.active,
      }));
      setWhitelists(newData);
      setAllWhitelists(newData);
      setFilteredWhitelists(newData);
      setLoading(false);
    }
    if (error) setLoading(false);
  }, [data, error]);

  const filterWhitelists = (_selected) => {
    if (_selected.actives.value && _selected.inactives.value) {
      setFilteredWhitelists(whitelists);
      return;
    }
    if (_selected.actives.value) {
      const newFilteredWhitelists = whitelists.filter((whitelist) => (whitelist.active));
      setFilteredWhitelists(newFilteredWhitelists);
      return;
    }
    if (_selected.inactives.value) {
      const newFilteredWhitelists = whitelists.filter((whitelist) => (!whitelist.active));
      setFilteredWhitelists(newFilteredWhitelists);
      return;
    }
    setFilteredWhitelists([]);
  };

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newState = { ...selected, [name]: { ...selected[name], value: checked } };
    setSelected(newState);
    filterWhitelists(newState);
  };

  const handleFilteredChange = (filteredData) => {
    setWhitelists(filteredData);
    filterWhitelists(selected);
  };

  const handleSwitchRule = async (id) => {
    try {
      const updatedRule = await switchWhitelistRuleState({
        variables: { identifier: id },
      });
      const newWhitelists = [...whitelists];
      const updatedRuleIndex = whitelists.findIndex((whitelist) => whitelist.id === id);
      newWhitelists[updatedRuleIndex] = updatedRule;
      setWhitelists(newWhitelists);
    } catch {
      setResultsMessage('No pudimos cambiar el estado de la regla, contáctate con el equipo de desarrollo');
      setOpenResultsMessage(true);
    }
  };

  const onOpenEditWhitelist = (whitelist) => {
    setEditingWhitelist(whitelist);
    setOpenEditWhitelist(true);
  };

  const onCloseEditWhitelist = () => {
    setOpenEditWhitelist(false);
    setEditingWhitelist(null);
  };

  const onCreateWhitelist = async (
    clientRut, debtorRut, monthlyRate, defaultRate, retentionRate,
  ) => {
    try {
      const { data: { createRateWhitelist: {
        createdWhitelist, success,
      } } } = await createWhitelist({
        variables: {
          clientRut, debtorRut, monthlyRate, defaultRate, retentionRate,
        },
      });
      if (success) {
        const whitelistAsRow = {
          id: createdWhitelist.id,
          clientRut: createdWhitelist.emitter && createdWhitelist.emitter.rut,
          clientName: createdWhitelist.emitter && createdWhitelist.emitter.name,
          debtorRut: createdWhitelist.receiver && createdWhitelist.receiver.rut,
          debtorName: createdWhitelist.receiver && createdWhitelist.receiver.name,
          monthlyRate: createdWhitelist.monthlyRate,
          defaultRate: createdWhitelist.defaultRate,
          retentionRate: createdWhitelist.retentionRate,
          active: createdWhitelist.active,
        };
        const updatedWhitelist = [...whitelists, whitelistAsRow];
        setWhitelists(updatedWhitelist);
        setFilteredWhitelists(updatedWhitelist);
        setResultsMessage('Tasas creadas exitosamente');
      } else {
        setResultsMessage('Hubo un error al crear las tasas, contáctate con el equipo de desarrollo');
      }
    } catch (err) {
      if (err.message === 'Unique constraint violated') setResultsMessage('Ya existe una regla para el o los ruts especificados');
      else setResultsMessage('Hubo un error al crear las tasas, contáctate con el equipo de desarrollo');
    }
    setOpenResultsMessage(true);
  };

  const onEditWhiteList = async (
    identifier, monthlyRate, defaultRate, retentionRate,
  ) => {
    try {
      const { data: { editRateWhitelist: {
        editedWhitelist, success,
      } } } = await editWhitelist({
        variables: {
          identifier, monthlyRate, defaultRate, retentionRate,
        },
      });
      if (success) {
        const whitelistAsRow = {
          id: editedWhitelist.id,
          clientRut: editedWhitelist.emitter && editedWhitelist.emitter.rut,
          clientName: editedWhitelist.emitter && editedWhitelist.emitter.name,
          debtorRut: editedWhitelist.receiver && editedWhitelist.receiver.rut,
          debtorName: editedWhitelist.receiver && editedWhitelist.receiver.name,
          monthlyRate: editedWhitelist.monthlyRate,
          defaultRate: editedWhitelist.defaultRate,
          retentionRate: editedWhitelist.retentionRate,
          active: editedWhitelist.active,
        };
        const updatedWhitelist = whitelists.map((whitelist) => {
          if (whitelist.id === whitelistAsRow.id) return whitelistAsRow;
          return whitelist;
        });
        setWhitelists(updatedWhitelist);
        setFilteredWhitelists(updatedWhitelist);
        setResultsMessage('Tasas editadas exitosamente');
      } else {
        setResultsMessage('Hubo un error al editar las tasas, contáctate con el equipo de desarrollo');
      }
    } catch (err) {
      setResultsMessage('Hubo un error al editar las tasas, contáctate con el equipo de desarrollo');
    }
    setOpenResultsMessage(true);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      clientRut,
      clientName,
      debtorRut,
      debtorName,
      debtorId,
      monthlyRate,
      defaultRate,
      retentionRate,
      active,
    } = row;
    return ({
      id,
      active: {
        value: active,
        component: (
          <Icon className={active ? classes.green : classes.red}>
            <FiberManualRecord />
          </Icon>
        ),
      },
      client: {
        value: clientName,
        component: (
          <>
            <Typography>{clientName}</Typography>
            <Typography>{clientRut && formatRut(clientRut)}</Typography>
          </>
        ),
      },
      debtor: {
        value: debtorName,
        component: <DebtorCell debtorId={debtorId} name={debtorName} rut={debtorRut} />,
      },
      monthlyRate: {
        value: monthlyRate,
        component: (<Typography>{monthlyRate}%</Typography>),
      },
      defaultRate: {
        value: defaultRate,
        component: (<Typography>{defaultRate}%</Typography>),
      },
      retentionRate: {
        value: retentionRate,
        component: (<Typography>{retentionRate}%</Typography>),
      },
      button: {
        value: active,
        component: (
          <>
            <Button
              className={classes.greenButton}
              onClick={() => onOpenEditWhitelist(row)}
            >
              Editar
            </Button>
            <Button
              className={active ? classes.redButton : classes.greenButton}
              onClick={() => handleSwitchRule(id)}
            >
              {active ? 'Desactivar' : 'Activar'}
            </Button>
          </>
        ),
      },
    });
  });
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) return <ErrorComponent fullHeight />;
  if (loading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <OneButtonAlert
        open={openResultsMessage}
        title={resultsMessage}
        onClose={() => setOpenResultsMessage(false)}
      />
      <CreateOrEditWhitelist
        open={openNewWhitelist}
        handleClose={() => setOpenNewWhitelist(false)}
        onCreateOrEditWhitelist={onCreateWhitelist}
      />
      <CreateOrEditWhitelist
        open={openEditWhitelist}
        handleClose={onCloseEditWhitelist}
        onCreateOrEditWhitelist={onEditWhiteList}
        whitelist={editingWhitelist}
        editing
      />
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Reglas de tasas preferenciales</b></Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            className={classes.greenButton}
            onClick={() => setOpenNewWhitelist(true)}
            color="inherit"
            variant="contained"
          >
            Agregar nueva regla
          </Button>
        </div>
      </div>
      <div className={localClasses.filtersContainer}>
        <div className={localClasses.filters}>
          <Filters
            filters={filters}
            allData={allWhitelists}
            setFilteredData={handleFilteredChange}
            searchFields={['clientName', 'debtorName']}
            searchPlaceholder="Nombre"
          />
        </div>
        <div className={localClasses.checkboxes}>
          {Object.keys(selected).map((key) => {
            const selection = selected[key];
            return (
              <FormControlLabel
                key={selection.label}
                label={selection.label}
                labelPlacement="end"
                control={(
                  <Checkbox
                    name={key}
                    checked={selection.value}
                    onChange={onCheckboxChange}
                  />
                      )}
              />
            );
          })}
        </div>
      </div>
      {whitelists.length > 0
        ? (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="active"
                  setNewDataOrder={setFilteredWhitelists}
                  data={filteredWhitelists}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography align="center" variant="h5">No hay tasas preferenciales</Typography>
        )}
    </div>
  );
};

export default Whitelist;
