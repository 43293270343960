/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const TraceFields = gql`
  fragment TraceFields on TraceType {
    id
    eventCode
    eventTime
  }
`;

const InvoiceFields = gql`
  fragment PurchaseFields on InvoiceType {
    id
    folio
    dateIssued
    dateExpiration
    receptionDate
    status
    loanedTo
    amountWithIva
    integrationStatus
    loanedStatus
    creditNoteStatus
    siiStatus
    unreadMessages
    paymentDate
    preofferSelectable @client
    amounts @client
    dteType {
      id
      code
      name
    }
    binnacleSet{
      message
      username
      id
      mail
      username
    }
    paymentMethod @client 
    invoicedetail {
      id
      purchaseOrderFolio
    }
    references {
      id
      folio
      amountWithIva
      dteType {
        id
        code
        name
      }
    }
    reverseReferences {
      id
      folio
      amountWithIva
      dteType {
        id
        code
        name
      }
    }
    creditNotes @client {
      id
      folio
      amountWithIva
    }
    debitNotes @client {
      id
      folio
      amountWithIva
    }
    cessions {
      id
      cessionDate
      transferor {
        rut
        name
      }
      assignee {
        rut
        name
      }
    }
    traces {
      ... TraceFields
    }
    company {
      id
      rut
      validCredentials {
        id
        updatedAt
        username
        secondUsername
        credentialProvider {
          id
          name
          credentialProviderType {
            id
            name
          }
        }
      }
      hasInvoiceProviderCredentials @client
      currentCommissionRules {
        id
        threshold
        variant
        value
      }
      masterEntity {
        id
        rut
        name
      }
    }
    receiver {
      id
      rut
      name
      company {
        id
        rut
        instructions
        validCredentials {
          id
          updatedAt
          username
          secondUsername
          credentialProvider {
            id
            name
            credentialProviderType {
              id
              name
            }
          }
        }
        hasInvoiceProviderCredentials @client
      }
    }
    preoffer {
      id
      monthlyRate
      defaultRate
      retentionRate
      preofferevaluationrequest {
        status
      }
    }
    offer {
      id
      monthlyRate
      defaultRate
      retentionRate
      transferCondition
      fundingDaysAfterExpiration
    }
    operation {
      id
    }
    documentfinancestateSet{
      id
      estimatedPaymentDate
      paymentDate
      comments
      isAutomatic
      status{
        code
        status
      }
      rejectionCode{
        code
        reason
      }
    }
  }
  ${TraceFields}
`;

export const DOCUMENTS = gql`
  query documents(
    $id_In: [String],
    $receiver_Rut: String,
    $receiver_Rut_In: [String],
    $receiver_Name_Iexact: String,
    $receiver_Name_Icontains: String,
    $receiver_Name_Istartswith: String,
    $company_MasterEntity_Rut: String,
    $company_MasterEntity_Rut_In: [String],
    $company_MasterEntity_Name_Iexact: String,
    $company_MasterEntity_Name_Icontains: String,
    $company_MasterEntity_Name_Istartswith: String,
    $dateIssued: Date,
    $dateIssued_Gte: Date,
    $dateIssued_Lte: Date,
    $dateIssued_Gt: Date,
    $dateIssued_Lt: Date,
    $receptionDate: DateTime,
    $receptionDate_Gte: DateTime,
    $receptionDate_Lte: DateTime,
    $receptionDate_Gt: DateTime,
    $receptionDate_Lt: DateTime,
    $amountWithIva: Int,
    $amountWithIva_Gte: Int,
    $amountWithIva_Lte: Int,
    $amountWithIva_Gt: Int,
    $amountWithIva_Lt: Int,
    $globalFilter: String,
    $orderBy: String,
    $companyId: Int,
    $first: Int,
    $offset: Int,
    $loanedStatus: [String],
    $siiStatus: [String],
    $creditNoteStatus: [String],
    $allReceiverCompany: Boolean,
    $allIssuedCompany: Boolean,
    $dteType_Code: String,
    $dteType_Code_In: [String],
    $folio: String,
    $availableForFinancing: String,
    $hasValidOffer: Boolean,
    $hasBeenCeded: Boolean
  ) {
    invoices(
      id_In: $id_In,
      receiver_Rut: $receiver_Rut,
      receiver_Rut_In: $receiver_Rut_In,
      receiver_Name_Iexact: $receiver_Name_Iexact, 
      receiver_Name_Icontains: $receiver_Name_Icontains, 
      receiver_Name_Istartswith: $receiver_Name_Istartswith, 
      company_MasterEntity_Rut: $company_MasterEntity_Rut,
      company_MasterEntity_Rut_In: $company_MasterEntity_Rut_In,
      company_MasterEntity_Name_Iexact: $company_MasterEntity_Name_Iexact, 
      company_MasterEntity_Name_Icontains: $company_MasterEntity_Name_Icontains, 
      company_MasterEntity_Name_Istartswith: $company_MasterEntity_Name_Istartswith, 
      dateIssued: $dateIssued,
      dateIssued_Gte: $dateIssued_Gte,
      dateIssued_Lte: $dateIssued_Lte,
      dateIssued_Gt: $dateIssued_Gt,
      dateIssued_Lt: $dateIssued_Lt,
      receptionDate: $receptionDate,
      receptionDate_Gte: $receptionDate_Gte,
      receptionDate_Lte: $receptionDate_Lte,
      receptionDate_Gt: $receptionDate_Gt,
      receptionDate_Lt: $receptionDate_Lt,
      amountWithIva: $amountWithIva,
      amountWithIva_Gte: $amountWithIva_Gte,
      amountWithIva_Lte: $amountWithIva_Lte,
      amountWithIva_Gt: $amountWithIva_Gt,
      amountWithIva_Lt: $amountWithIva_Lt,
      globalFilter: $globalFilter,
      orderBy: $orderBy,
      companyId: $companyId,
      first: $first,
      offset: $offset,
      loanedStatus: $loanedStatus,
      siiStatus: $siiStatus,
      creditNoteStatus: $creditNoteStatus,
      allReceiverCompany: $allReceiverCompany,
      allIssuedCompany: $allIssuedCompany,
      dteType_Code_In: $dteType_Code_In,
      dteType_Code: $dteType_Code,
      folio: $folio,
      availableForFinancing: $availableForFinancing,
      hasValidOffer: $hasValidOffer,
      hasBeenCeded: $hasBeenCeded
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ... PurchaseFields
        }
      }
      flatInvoices @client {
        ... PurchaseFields
      }
    }
  }
  ${InvoiceFields}
`;

export const GET_INVOICES_BY_STATUS = gql`
query GetInvoicesByStatus(
  $status: String!,
  $offset: Int,
  $limit: Int,
  $order: String,
  $propertyToOrder: String,
  $searchValue: String) {
  getInvoicesByStatus(
    status:$status, 
    searchValue:$searchValue
    queryMeta: {
        offset: $offset,
        limit: $limit,
        order: $order,
        propertyToOrder: $propertyToOrder,
      }) {
    invoices {
      company {
        id
        name,
        rut,
      },
      receiver {
        id
      }
      folio,
      amountWithIva,
      nameReceiver,
      rutReceiver,
      dateOperation,
      dateToPay
      preoffer {
        preofferevaluationrequest {
          createdAt
        }
      }
    }
    length
  }
}`;

export const GET_INVOICES_COUNT_BY_STATUS = gql`
query GetInvoicesCount {
  getInvoicesCountByStatus {
    evaluatingInvoices,
    acceptedInvoices,
    ratificationInvoices,
    pendingDocumentsInvoices,
    pendingInvoices,
    transferedInvoices,
    debtInvoices
  }
}`;

export const GET_INVOICE_BY_ID = gql`
  query getInvoiceById($invoiceId: Int!){
    getInvoiceById(invoiceId: $invoiceId){
      folio
      dateIssued
      dateExpiration
      amountWithIva
      creditNoteStatus
    }
  }
`;

export const CREATE_FACTORING_SIMULATION_FILE = gql`
mutation CreateSimulationFile($invoiceIds: [Int]!, $invoiceDays: [Int]!, $commission: Int!, $simulationType: String!){
  createSimulationFile(invoiceIds: $invoiceIds, invoiceDays: $invoiceDays, commission: $commission, simulationType: $simulationType) {
    success
    url
  }
}
`;
