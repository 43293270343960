import PropTypes from 'prop-types';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Table } from '../tables';
import { CREATE_FACTORING_SIMULATION_FILE } from '../../gql/invoices';
import calculateInvoiceAmounts from '../../helpers/invoiceAmountsCalculation';
import FactoringSimulationSummary from '../customerMaintainer/FactoringSimulationSummary';
import { formatMoney } from '../../helpers/money';
import DebtorCell from '../shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'nameReceiver', label: 'Deudor', align: 'left' },
  { id: 'amountWithIva', label: 'Monto factura', align: 'left' },
  { id: 'monthlyRate', label: 'Diferencia de precio', align: 'center' },
  { id: 'retentionRate', label: 'Retención', align: 'right' },
  { id: 'defaultRate', label: 'Tasa mora', align: 'center' },
  { id: 'days', label: 'Ingresar plazo', align: 'right' },
];

const CreateFactoringSimulation = ({
  openSimulation,
  onClose,
  selectedRowsData,
  type,

}) => {
  const [CreateSimulationFile, { data }] = useMutation(CREATE_FACTORING_SIMULATION_FILE);
  const [dataToSimulate, setDataToSimulate] = useState([]);
  const [commission, setCommission] = useState();
  const [loadingFile, setLoadingFile] = useState(false);

  useEffect(() => {
    if (selectedRowsData) {
      setDataToSimulate(selectedRowsData.map((inv) => {
        const {
          effectiveRate,
          retentionAmount,
          advanceAmount,
          amountMinusInterests,
          priceDifference,
        } = calculateInvoiceAmounts({
          days: 30,
          retentionRate: inv.retentionRate,
          monthlyRate: inv.monthlyRate,
          amountWithIva: inv.amountWithIva,
        });
        return ({
          ...inv,
          days: 30,
          effectiveRate,
          retentionAmount,
          advanceAmount,
          amountMinusInterests,
          priceDifference,
        });
      }));
    }
  }, [selectedRowsData]);

  useEffect(() => {
    if (data && data.createSimulationFile) {
      window.open(data.createSimulationFile.url);
      setLoadingFile(false);
    }
  }, [data]);

  const updateValuesOnDaysChange = (currentData) => {
    const newData = currentData.map((inv) => {
      const {
        effectiveRate,
        retentionAmount,
        advanceAmount,
        amountMinusInterests,
        priceDifference,
      } = calculateInvoiceAmounts({
        days: inv.days,
        retentionRate: inv.retentionRate,
        monthlyRate: inv.monthlyRate,
        amountWithIva: inv.amountWithIva,
      });
      return ({
        ...inv,
        effectiveRate,
        retentionAmount,
        advanceAmount,
        amountMinusInterests,
        priceDifference,
      });
    });
    return newData;
  };

  const handleCreatePdfFile = () => {
    const mutationData = {
      variables: {
        invoiceIds: dataToSimulate.map((inv) => inv.id),
        invoiceDays: dataToSimulate.map((inv) => inv.days),
        simulationType: type,
        commission,
      },
    };
    setLoadingFile(true);
    CreateSimulationFile(mutationData);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const handleChange = (event, id) => {
      const { name, value } = event.target;
      const currentData = [..._data];
      currentData.find((inv) => inv.id === id)[name] = value;
      const newData = updateValuesOnDaysChange(currentData);
      setDataToSimulate(newData);
    };
    const {
      id,
      folio,
      nameReceiver,
      rutReceiver,
      receiver: {
        id: receiverId,
      },
      retentionAmount,
      priceDifference,
      days,
      retentionRate,
      monthlyRate,
      defaultRate,
      amountWithIva,
    } = row;
    return ({
      id,
      folio: {
        value: folio,
        component: (
          <Typography variant="body2">{folio}</Typography>
        ),
      },
      nameReceiver: {
        value: nameReceiver,
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography variant="body2">${formatMoney(amountWithIva)}</Typography>
        ),
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <>
            <Typography variant="body2" align="center"><b>Tasa:</b> {monthlyRate}%</Typography>
            <TextField
              disabled
              type="text"
              size="small"
              value={formatMoney(priceDifference)}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                size: 'small',
                style: { textAlign: 'right' },
              }}
            />
          </>
        ),
      },
      retentionRate: {
        value: retentionRate,
        component: (
          <>
            <Typography variant="body2"><b>Tasa:</b> {retentionRate}%</Typography>
            <Typography variant="body2"><b>Monto:</b> ${formatMoney(retentionAmount)}</Typography>
          </>
        ),
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <Typography>{defaultRate}%</Typography>
        ),
      },
      days: {
        value: days,
        component: (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              style={{ maxWidth: 80 }}
              type="number"
              step=".01"
              value={days}
              name="days"
              variant="outlined"
              onChange={(event) => handleChange(event, id)}
            />
          </div>
        ),
      },
    });
  });

  return (
    <Dialog
      open={openSimulation}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography align="center" variant="h5">
          Simulación de Factoring
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FactoringSimulationSummary
          dataToSimulate={dataToSimulate}
          setCommission={setCommission}
        />
        <Table
          data={dataToSimulate}
          dataToRows={transformDataToComponents}
          setNewDataOrder={setDataToSimulate}
          headers={headers}
          initialOrderId="id"
          pagination={false}
        />
      </DialogContent>
      <DialogActions>
        { loadingFile && (
          <>
            <Typography variant="body2" color="primary">Generando documento...</Typography>
            <CircularProgress />
          </>
        ) }
        <Button onClick={handleCreatePdfFile} style={{ margin: 'auto' }} color="primary" variant="contained">
          Descargar PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateFactoringSimulation.propTypes = {
  openSimulation: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRowsData: PropTypes.shape([]).isRequired,
  type: PropTypes.string.isRequired,
};

export default CreateFactoringSimulation;
