import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
  CircularProgress,
  Input,
  Chip,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { contactTypeToSpanish } from '../../helpers/translators';
import { getFormFieldError } from '../../helpers/formValidators';
import { CREATE_RATIFICATION_CONTACT } from '../../gql/ratification';
import { CREATE_COLLECTION_CONTACT } from '../../gql/collection';

const sourceTypes = [
  { value: 'PROVIDED_BY_CLIENT', label: 'Entregado por el cliente' },
  { value: 'OBTAINED_BY_FINGO', label: 'Obtenido por Fingo' },
];

const AddNewContact = ({ companyName, companyRut, type, contactsRefetch }) => {
  const INITIAL_FORM_DATA = {
    selectedTypes: {
      value: (type !== '') ? [type] : [],
      error: '',
    },
    names: {
      value: '',
      error: '',
    },
    lastNames: {
      value: '',
      error: '',
    },
    position: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    source: {
      value: '',
      error: '',
    },
  };
  const [createRatificationContact, {
    loading: addRatificationContactLoading,
  }] = useMutation(CREATE_RATIFICATION_CONTACT);
  const [createCollectionContact, {
    loading: addCollectionContactLoading,
  }] = useMutation(CREATE_COLLECTION_CONTACT);
  const [openDialog, setOpenDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: {
        ...oldFormData[name],
        value,
      },
    }));
  };

  const handleSetError = (name, _error) => {
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: {
        ...oldFormData[name],
        error: _error,
      },
    }));
  };

  const handleAddContact = async () => {
    const {
      names,
      lastNames,
      position,
      phoneNumber,
      email,
      selectedTypes,
      source,
    } = formData;
    if (!names.value || !lastNames.value || selectedTypes.value.length === 0 || !source.value) {
      setError('Nombre, appelido, tipo de contacto y fuente del contacto no pueden estar vacíos');
      return;
    }
    if (!phoneNumber.value && !email.value) {
      setError('Debes indicar al menos uno entre email y número de teléfono');
      return;
    }
    const emailError = getFormFieldError(email.value, 'email');
    const errorOnEmail = emailError.isError && email.value.length > 0;
    if (errorOnEmail) handleSetError('email', emailError.message);
    else handleSetError('email', '');
    const phoneNumberError = getFormFieldError(phoneNumber.value, 'phoneNumber');
    const errorOnPhoneNumber = phoneNumberError.isError && phoneNumber.value.length > 0;
    if (errorOnPhoneNumber) handleSetError('phoneNumber', phoneNumberError.message);
    else handleSetError('phoneNumber', '');
    if (errorOnEmail || errorOnPhoneNumber) return;

    if (selectedTypes.value.includes('RATIFICATION')) {
      try {
        await createRatificationContact({
          variables: {
            companyRut,
            firstName: names.value,
            lastName: lastNames.value,
            position: position.value,
            phoneNumber: phoneNumber.value,
            email: email.value,
            source: source.value.value,
          },
        });
        if (contactsRefetch) {
          await contactsRefetch();
        }
      } catch (err) {
        if (err.message === 'Contact with this email already exists for this company') {
          setError('Ya existe un contacto de esta empresa con este mail');
          return;
        }
        setError('No pudimos crear el contacto, por favor comunícate con el equipo de desarrollo');
        return;
      }
    } if (selectedTypes.value.includes('COLLECTION')) {
      try {
        await createCollectionContact({
          variables: {
            companyRut,
            firstName: names.value,
            lastName: lastNames.value,
            position: position.value,
            phoneNumber: phoneNumber.value,
            email: email.value,
            source: source.value.value,
          },
        });
        if (contactsRefetch) {
          contactsRefetch();
        }
      } catch (err) {
        if (err === 'Contact with this email already exists for this company') {
          setError('Ya existe un contacto de esta empresa con este mail');
          return;
        }
        setError('No pudimos crear el contacto, por favor comunícate con el equipo de desarrollo');
        return;
      }
    }
    setSuccess(true);
    setFormData(INITIAL_FORM_DATA);
  };

  const handleAddMore = () => {
    setSuccess(false);
  };

  const {
    names,
    lastNames,
    position,
    phoneNumber,
    email,
    selectedTypes,
    source,
  } = formData;

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpenDialog(true)}
        startIcon={<Add />}
        variant="outlined"
        size="small"
        style={{ marginTop: '2%' }}
      >
        Añadir nuevo contacto
      </Button>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        {success
          ? (
            <>
              <DialogTitle>Contacto añadido con éxito</DialogTitle>
              <DialogContent>
                <Typography>Hemos añadido el contacto</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  color="primary"
                >
                  Cerrar
                </Button>
                <Button
                  onClick={handleAddMore}
                  color="primary"
                >
                  Añadir otro
                </Button>
              </DialogActions>
            </>
          )
          : (
            <>
              <DialogTitle>Añadir nuevo contacto para {companyName}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Nombres</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="names"
                      onChange={handleChange}
                      value={names.value}
                      error={Boolean(names.error)}
                      helperText={names.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Apellidos</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="lastNames"
                      onChange={handleChange}
                      value={lastNames.value}
                      error={Boolean(lastNames.error)}
                      helperText={lastNames.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Cargo</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="position"
                      onChange={handleChange}
                      value={position.value}
                      error={Boolean(position.error)}
                      helperText={position.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Teléfono</Typography>
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+56</InputAdornment>
                        ),
                      }}
                      name="phoneNumber"
                      onChange={handleChange}
                      value={phoneNumber.value}
                      error={Boolean(phoneNumber.error)}
                      helperText={phoneNumber.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Email</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      onChange={handleChange}
                      value={email.value}
                      error={Boolean(email.error)}
                      helperText={email.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Fuente del contacto</Typography>
                    <Select
                      variant="outlined"
                      name="source"
                      value={source.value}
                      fullWidth
                      onChange={handleChange}
                    >
                      {sourceTypes.map((sourceType) => (
                        <MenuItem key={sourceType.value} value={sourceType}>
                          {sourceType.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>Tipo</Typography>
                    <Select
                      multiple
                      variant="outlined"
                      name="selectedTypes"
                      value={selectedTypes.value}
                      fullWidth
                      onChange={handleChange}
                      input={<Input id="multiselect-chip" />}
                      renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {
                            selected.map((value) => (
                              <Chip
                                key={value}
                                label={contactTypeToSpanish[value]}
                                style={{ margin: 2 }}
                              />
                            ))
                          }
                        </div>
                      )}
                    >
                      {['RATIFICATION', 'COLLECTION', 'COMMERCIAL'].map((value) => (
                        <MenuItem key={value} value={value}>
                          {contactTypeToSpanish[value]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Typography style={{ width: '100%' }} variant="caption" color="error" align="right">
                    {error}
                  </Typography>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  color="primary"
                  disabled={addRatificationContactLoading || addCollectionContactLoading}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={(e) => { e.preventDefault(); handleAddContact(); }}
                  color="primary"
                  disabled={addRatificationContactLoading || addCollectionContactLoading}
                  startIcon={addRatificationContactLoading || addCollectionContactLoading
                    ? <CircularProgress size={16} /> : null}
                >
                  Aceptar
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

AddNewContact.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyRut: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['RATIFICATION', 'COLLECTION', 'COMMERCIAL', '']),
  contactsRefetch: PropTypes.func,
};

AddNewContact.defaultProps = {
  type: '',
  contactsRefetch: null,
};

export default AddNewContact;
