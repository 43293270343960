import { Typography, Tab, Tabs, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './styles';
import {
  IncomesCollectionNotes,
  IncomesFactoring,
  IncomesOrdering,
  IncomesPaymentPlan,
  IncomesFunding,
} from '../components/incomes';

const Incomes = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  return (
    <Grid className={classes.container}>
      <Tabs
        variant="fullWidth"
        value={tab}
        onChange={(e, value) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Facturas</Typography>} />
        <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Planes de pago</Typography>} />
        <Tab label={<Typography color={tab === 2 ? 'primary' : 'inherit'}>Órdenes de compra</Typography>} />
        <Tab label={<Typography color={tab === 3 ? 'primary' : 'inherit'}>Notas de cobro</Typography>} />
        <Tab label={<Typography color={tab === 4 ? 'primary' : 'inherit'}>Fondos</Typography>} />
      </Tabs>
      {tab === 0 && (
        <IncomesFactoring />
      )}
      {tab === 1 && (
        <IncomesPaymentPlan />
      )}
      {tab === 2 && (
        <IncomesOrdering />
      )}
      {tab === 3 && (
        <IncomesCollectionNotes />
      )}
      {tab === 4 && (
        <IncomesFunding />
      )}
    </Grid>
  );
};

export default Incomes;
