import { gql } from '@apollo/client';

export const RatificationFields = gql`
  fragment RatificationFields on RatificationManagerType {
    id
    ratificator {
      id
      firstName
    }
    invoice {
      id
      folio
      pdf
      rutReceiver
      nameReceiver
      orderingAssigned {
        purchaseOrder {
          orderNumber
        }
        amount {
          amount
          currency {
            code
          }
        }
      }
      receiver {
        id
        ratificationBlacklist {
          active
        }
        riskBlacklist {
          id
        }
      }
      company {
        id
        rut
        name
        executiveAssigned{
          firstName
          lastName
        }
        masterEntity {
          riskBlacklist {
            id
          }
        }
      }
      dateIssued
      dateToPay
      amountWithIva {
        amount
        currency {
          code
        }
      }
      documents {
        id
        type {
          id
          name
          code
        }
      }
      integrationStatus
      siiStatus
      loanedStatus
      creditNoteStatus
      lastHistoryStatus {
        status
        createdAt
      }
      lightningPaymentAuthorized @client
      documentfinancestateSet{
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime : date
        status{
          id
          code
          status
        }
        rejectionCode{
          id
          code
          reason
        }
      }
      uniqueDocumentFinanceState{
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime : date
        status{
          id
          code
          status
        }
        rejectionCode{
          id
          code
          reason
        }
      }
    }
    actions {
      id
      actionType
      comment
      createdAt
      channel
      responded
      ratificationContacts {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      author {
        id
        firstName
        lastName
      }
      sendgridMessages {
        opensCount
        clicksCount
        events {
          status
        }
      }
      contactsResponse {
        id
      }
    }
  }
`;

export const GET_PRIORITY_RATIFICATION_INVOICES = gql`
  query GetPriorityRatificationInvoices {
    getPriorityRatificationInvoices {
      ... RatificationFields
    }
  }
  ${RatificationFields}
`;

export const GET_NON_PRIORITY_RATIFICATION_INVOICES = gql`
  query GetNonPriorityRatificationInvoices {
    getNonPriorityRatificationInvoices {
      ... RatificationFields
    }
  }
  ${RatificationFields}
`;

export const REJECT_RATIFICATION = gql`
  mutation RejectRatification(
    $ratificationManagerIds: [Int]!,
    $resolutionType: String!,
    $observation: String
  ) {
    resolveRatification(
      ratificationManagerIds: $ratificationManagerIds
      accepted: false
      resolutionType: $resolutionType
      observation: $observation
    ) {
      success
    }
  }
`;

export const GET_COMPANY_RATIFICATION_CONTACTS = gql`
  query GetCompanyRatificationContacts($companyRut: String!) {
    getCompanyRatificationContacts(companyRut: $companyRut) {
      id
      firstName
      lastName
      position
      source
      email
      phoneNumber
      verified
      ratifies
      ratificationContactStats {
        mails
        mailsResponded
        mailsResponseRate
        phoneCalls
        phoneCallsResponded
        phoneCallsResponseRate
      }
    }
  }
`;

export const CREATE_RATIFICATION_ACTION = gql`
  mutation CreateRatificationAction(
    $ratificationManagerIds: [Int]!,
    $ratificationContactsIds: [Int]
    $ratificationActionType: RatificationActionEnum!
    $ratificationComment: String
    $ratificationChannel: RatificationChannelEnum!
    $responded: Boolean
  ) {
    createRatificationAction(
      ratificationActionInput: {
        ratificationManagerIds: $ratificationManagerIds,
        ratificationContactsIds: $ratificationContactsIds,
        ratificationActionType: $ratificationActionType,
        ratificationComment: $ratificationComment,
        ratificationChannel: $ratificationChannel,
        responded: $responded,
      }
    ) {
      success
      addedAction {
        id
        actionType
        comment
        createdAt
        ratificationContacts {
          firstName
          lastName
          email
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ACCEPT_RATIFICATION = gql`
  mutation AcceptRatification(
    $ratificationManagerIds: [Int]!,
    $resolutionType: String!,
    $evidenceFile: Upload,
    $evidenceContactId: Int,
  ) {
    resolveRatification(
      ratificationManagerIds: $ratificationManagerIds
      accepted: true
      resolutionType: $resolutionType
      evidenceFile: $evidenceFile,
      evidenceContactId: $evidenceContactId
    ) {
      success
    }
  }
`;

export const ASSIGN_RATIFICATOR = gql`
  mutation AssignRatificator($ratificationManagerId: Int!) {
    assignRatificator(ratificationManagerId: $ratificationManagerId) {
      success
      ratificator {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_RATIFICATION_CONTACT = gql`
  mutation CreateRatificationContact(
    $companyRut: String!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
    $source: RatificationContactSourceEnum!,
  ) {
    createRatificationContact(
      companyRut: $companyRut,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
      source: $source,
    ) {
      success
    }
  }
`;

export const UPDATE_RATIFICATION_CONTACT = gql`
  mutation UpdateRatificationContact(
    $contactId: String!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
    $ratifies: Boolean!,
    $verified: Boolean!,
  ) {
    updateRatificationContact(
      contactId: $contactId,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
      ratifies: $ratifies,
      verified: $verified,
    ) {
      success
    }
  }
`;

export const GET_RATIFICATION_ACTIONS = gql`
  query GetRatificationActions($invoiceId: String!) {
    getRatificationActions(invoiceId: $invoiceId) {
      actionType
      comment
      createdAt
      responded
      channel
      ratificationContacts {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      author {
        id
        firstName
        lastName
      }
      contactsResponse {
        id
      }
    }
  }
`;

export const CREATE_RATIFICATION_BLACKLIST = gql`
  mutation CreateRatificationBlacklist($masterEntityId: Int!, $activate: Boolean!) {
    createRatificationBlacklist(masterEntityId: $masterEntityId, activate: $activate) {
      success
    }
  }
`;

export const GET_PENDING_DOCUMENTS_REVIEW_INVOICES = gql`
  query GetPendingDocumentsReviewInvoices{
    getPendingDocumentsReviewInvoices{
      id
      folio
      company {
        id
        rut
        name
        executiveAssigned {
          firstName
          lastName
        }
      }
      receiver {
        id
        rut
        name
      }
      documents {
        id
        url
        type {
          id
          name
          code
        }
      }
      offer {
        id
        reviewDocumentsManager {
          id
          requiresOc
          requiresHes
          requiresPortalScreenshot
          ocReviewState
          hesReviewState
          portalScreenshotReviewState
        }
      }
      objectPurchaseOrder {
        exists
        url
      }
      dateIssued
      creditNoteStatus
      siiStatus
      pdf
      certificateUrl
      amountWithIva
      lightningPaymentAuthorized @client
      documentfinancestateSet {
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime : date 
        status{
          id
          code
          status
        }
        rejectionCode{
          id
          code
          reason
        }
      }
    }
  }
`;

export const UPDATE_REVIEW_DOCUMENTS_MANAGER = gql`
  mutation UpdateReviewDocumentsManager($newStates: [UpdateReviewDocumentsManagerInputType]!) {
    updateReviewDocumentsManager(newStates: $newStates) {
      invoices {
        id
        offer {
          id
          reviewDocumentsManager {
            id
            ocReviewState
            hesReviewState
            portalScreenshotReviewState
          }
        }
      }
    }
  }
`;

export const SEND_RATIFICATION_MAIL = gql`
mutation SendRatificationMail(
  $contactsIds: [Int]!
  $mailType: String!
  $withCessionCertificate: Boolean!
  $managersIds: [Int]!
) {
  sendRatificationMail(
    contactsIds: $contactsIds
    mailType: $mailType
    withCessionCertificate: $withCessionCertificate
    managersIds: $managersIds
  ) {
    success
    message
  }
}
`;

export const ASSIGN_CONTACT_RESPONSE = gql`
mutation AssignContactResponse(
  $ratificationContactId: Int!
  $ratificationActionId: Int!
) {
  assignContactResponse(
    ratificationContactId: $ratificationContactId
    ratificationActionId: $ratificationActionId
  ) {
    success
    message
  }
}
`;

export const GET_RATIFICATION_ACTIONS_ENUM = gql`
query GetRatificationActionEnum{
  getRatificationActionEnum{
    value
    label
  }
}
`;

export const GET_RATIFICATION_MAIL_ENUM = gql`
query GetRatificationMailEnum{
  getRatificationMailEnum{
    value
    label
  }
}
`;

export const GET_RATIFICATION_CHANNEL_ENUM = gql`
query GetRatificationChannelEnum{
  getRatificationChannelEnum{
    value
    label
  }
}
`;

export const FETCH_TRACE = gql`
  mutation fetchManyTrace($invoiceId: [Int]!) {
    fetchManyTrace(invoiceId: $invoiceId) {
      invoices {
        id
      }
    }
  }
`;

export const GET_RATIFICATION_MAIL_PREVIEW = gql`
mutation GetRatificationMailPreview(
  $mailType: String!
  $managersIds: [Int]!
) {
  getRatificationMailPreview(
    mailType: $mailType
    managersIds: $managersIds
  ) {
    success
    message
    preview
  }
}
`;
