import { gql } from '@apollo/client';

const CLIENT_TREE = gql`
  query clientTree {
    clientTree {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
    }
  }
`;
const DEBTOR_TREE = gql`
  query debtorTree {
    debtorTree {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
    }
  }
`;

const CLIENT_DEBTOR_TREE = gql`
  query clientDebtorTree {
    clientDebtorTree {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
    }
  }
`;

const CLIENT_RAS = gql`
  query clientRas {
    clientRas {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
      prize
    }
  }
`;
const DEBTOR_RAS = gql`
  query debtorRas {
    debtorRas {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
      prize
    }
  }
`;
const CLIENT_DEBTOR_RAS = gql`
  query clientDebtorRas {
    clientDebtorRas {
      id
      name
      variableOrder
      functionName {
        name
        value
      }
      args
      threshold
      logicalType {
        name
        value
      }
      approved
      rejected
      average
      stdDev
      result
      prize
    }
  }
`;

const VARIABLE_OPTIONS = gql`
  query variableOptions {
    operations {
      name
      value
    }
    logicalOperations {
      name
      value
    }
    variableTypes
    arguments
  }
`;

const UPDATE_VARIABLE = gql`
  mutation updateVar($idVariable: Int!, $Variable: VariableInput!) {
    updateVariable(idVariable: $idVariable, variable: $Variable) {
      variable {
        name
      }
    }
  }
`;

const ADD_VARIABLE = gql`
  mutation createVariable($Variable: VariableInput!) {
    createVariable(variable: $Variable){
      newVariable {
        name
      }
    }
  }
`;

const DEACTIVATE_VARIABLE = gql`
  mutation deactivate ($idVariable: Int!, $variableType: VarType!) {
    deactivateVariable(idVariable: $idVariable, variableType: $variableType){
      variable {
        name
      }
    }
  }
`;

const RISK_RESULTS = gql`
  query riskEvaluationGlobal(
      $rutClient: String,
      $rutDebtor: String,
      $rejected: Boolean,
      $approved: Boolean,
      $statusRejected: Boolean,
      $statusEvaluating: Boolean,
      $offset: Int,
      $limit: Int,
      $order: String,
      $propertyToOrder: String,
  ) {
    riskEvaluationGlobal(
      rutClient: $rutClient, 
      rutDebtor: $rutDebtor, 
      rejected: $rejected, 
      approved: $approved, 
      statusRejected: $statusRejected) {
      totalApproved
      treeApproved
      rasApproved
      totalEvaluations
      totalCte
    }
    length(statusEvaluating: $statusEvaluating)
    riskEvaluationResults(
      rutClient: $rutClient, 
      rutDebtor: $rutDebtor, 
      rejected: $rejected, 
      approved: $approved, 
      statusRejected: $statusRejected
      statusEvaluating: $statusEvaluating
      queryMeta: {
        offset: $offset,
        limit: $limit,
        order: $order,
        propertyToOrder: $propertyToOrder,
      }
    ) {
      createdAt
      id
      clientIvaAmount
      client {
        name
        rut
      }
      debtor {
        name
        rut
      }
      invoice {
        id
        folio
        preoffer {
          preofferevaluationrequest {
            status
          }
        }
      }
      clientTreeResult
      debtorTreeResult
      clientDebtorTreeResult
      clientRasResult
      debtorRasResult
      clientDebtorRasResult
      treeResult
      rasResult
      clientRas {
        name
        value
        result
        prize
      }
      debtorRas {
        name
        value
        result
        prize
      }
      clientDebtorRas {
        name
        value
        result
        prize
      }
      clientTree {
        name
        value
        result
      }
      debtorTree {
        name
        value
        result
      }
      clientDebtorTree {
        name
        value
        result
      }
    }
  }
`;

const PREEVALUATION_EMAIL_SEND = gql`
  mutation GenerateAutomaticPreOfferResponseEmail(
    $invoiceIds: [Int]!,
    $acceptPreoffer: Boolean!,
    $finalDescription: String,
    $emailSending: String!
    $isPrivate: Boolean!) {
    generateAutomaticPreOfferResponseEmail(
      invoiceIds: $invoiceIds,
      acceptPreoffer: $acceptPreoffer,
      finalDescription: $finalDescription,
      emailSending: $emailSending,
      isPrivate: $isPrivate
      ) {
        success
    }
  }
`;

const GENERATE_RISK_PDF_LINK = gql`
query generateRiskPdfLink(
  $invoiceIds: [Int]!) {
    generateRiskPdfLink(
    invoiceIds: $invoiceIds) {
      preview
  }
}
`;

const GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW = gql`
query getAutomaticResponseEmailPreview(
  $invoiceIds: [Int]!) {
    getAutomaticResponseEmailPreview(
    invoiceIds: $invoiceIds) {
      preview
  }
}
`;

const RAS_DISTRIBUTION = gql`
  query rasResults {
    rasResults {
      ras
      tree
    }
  }
`;

const GET_RISK_VARIABLES = gql`
  query getRiskVariables($objectId: Int! $modelName: String!) {
    getRiskVariables(objectId: $objectId, modelName: $modelName) {
      fetchVariables {
        name
        value
        valueType
      }
    }
  }
`;

const GET_RISK_VARIABLES_DESCRIPTION = gql`
  query getRiskEvaluationVariablesDescription {
    getRiskEvaluationVariablesDescription {
      name
      labelName
      description
      variableType
    }
  }
`;

const DICOM_DETAILS = gql`
  query getDicomDetails($id: Int!) {
    getDicomDetails(id: $id) {
      date
      details {
        coinType
        amount
        market
        drawer
        debtType
        doctoNumber
        documentType
        expirationDate
        doctoEntryDate
      }
    }
  }
`;

const RERUN_EVALUATION_REQUEST = gql`
mutation rerunEvaluationRequest(
  $invoiceId: Int!,
  $clientDicom: Boolean!,
  $debtorDicom: Boolean!,
  $clientBusinessProfile: Boolean!,
  $debtorBusinessProfile: Boolean!,
  $clientCurrentBehavior: Boolean!,
  $debtorCurrentBehavior: Boolean!,
  ) {
    rerunEvaluationRequest(
      invoiceId: $invoiceId,
      clientDicom: $clientDicom,
      debtorDicom: $debtorDicom,
      clientBusinessProfile: $clientBusinessProfile,
      debtorBusinessProfile: $debtorBusinessProfile,
      clientCurrentBehavior: $clientCurrentBehavior,
      debtorCurrentBehavior: $debtorCurrentBehavior
      ) {
      success
      }
    }
`;

export {
  CLIENT_TREE,
  DEBTOR_TREE,
  CLIENT_DEBTOR_TREE,
  CLIENT_RAS,
  DEBTOR_RAS,
  CLIENT_DEBTOR_RAS,
  VARIABLE_OPTIONS,
  UPDATE_VARIABLE,
  ADD_VARIABLE,
  DEACTIVATE_VARIABLE,
  RISK_RESULTS,
  RAS_DISTRIBUTION,
  GET_RISK_VARIABLES,
  DICOM_DETAILS,
  PREEVALUATION_EMAIL_SEND,
  GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW,
  GENERATE_RISK_PDF_LINK,
  GET_RISK_VARIABLES_DESCRIPTION,
  RERUN_EVALUATION_REQUEST,
};
