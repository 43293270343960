import { gql } from '@apollo/client';

export const GET_COLLECTION_INVOICES = gql`
  query GetCollectionInvoices($searchValue: String) {
    getCollectionInvoices(searchValue: $searchValue) {
      id
      folio
      pdf
      rutReceiver
      nameReceiver
      receiver {
        id
      }
      dateIssued
      isRatificated
      integrationStatus
      siiStatus
      creditNoteStatus
      forReintegration
      invoiceloanmanager {
        id
        invoiceLoanRoster {
          id
          fund {
            id
            masterEntity {
              rut
              name
              id
            }
          }
        }
      }
      isFunding @client 
      debtorPaymentBehaviour
      classification
      company {
        id
        rut
        name
        executiveAssigned {
          firstName
          lastName
        }
      }
      dateOperation
      dateToPay
      amountWithIva {
        amount
        currency {
          code
        }
      }
      surplusDebt {
        amount
        currency {
          code
        }
      }
      lastHistoryStatus {
        status
        createdAt
      }
      documents {
        id
        type {
          id
          name
          code
        }
      }
      collectionManager {
        id
        collector {
          id
          firstName
        }
        actions {
          id
          actionType
          comment
          createdAt
          collectionContacts {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          author {
            id
            firstName
            lastName
          }
        }
        currentDataForCollection {
          id
          paymentMethod
          dateToPay
          collectionContacts {
            id
            firstName
            lastName
          }
        }
        reminder {
          id
          reminderDate
          reminderNote
        }
      }
    }
  }
`;

export const GET_COLLECTION_ORDERS = gql`
  query GetCollectionOrders {
    getCollectionOrders {
      id
      orderNumber
      totalAmount {
        amount
        currency {
          code
        }
      }
      status
      company {
        id
        rut
        name
        executiveAssigned {
          firstName
          lastName
        }
      }
      purchaser {
        rut
        name
      }
      orderingoffer {
        orderingFinancedAmount
        operationDate
        invoiceIssuedDate
      }
      orderingDebt {
        debt {
          amount
          currency {
            code
          }
        }
        days
      }
      collectionManager {
        id
        createdAt
      }
    }
  }
`;

export const ASSIGN_COLLECTOR = gql`
  mutation AssignCollector($collectionManagerId: Int!) {
    assignCollector(collectionManagerId: $collectionManagerId) {
      success
      collector {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_COMPANY_COLLECTION_CONTACTS = gql`
  query GetCompanyCollectionContacts($companyRut: String!) {
    getCompanyCollectionContacts(companyRut: $companyRut) {
      id
      firstName
      phoneNumber
      email
      source
      lastName
      position
    }
  }
`;

export const ADD_DATA_FOR_COLLECTION = gql`
  mutation AddDataForCollection(
    $collectionManagerId: Int!,
    $dateToPay: String!,
    $paymentMethod: PaymentMethodsEnum!,
    $collectionContactIds: [Int]!
  ){
    addDataForCollection(
      dataForCollection: {
        collectionManagerId: $collectionManagerId
        dateToPay: $dateToPay
        paymentMethod: $paymentMethod
        collectionContactIds: $collectionContactIds
      }
    ){
      success
      addedDataForCollection {
        id
        paymentMethod
        dateToPay
        collectionContacts {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADD_COLLECTION_REMINDER = gql`
  mutation AddCollectionReminder(
    $collectionManagerId: Int!,
    $reminderDatetime: String!,
    $reminderChannel: ReminderChannelEnum!,
    $reminderNote: String!
  ){
    addCollectionReminder(
      collectionReminder: {
        collectionManagerId: $collectionManagerId
        reminderDatetime: $reminderDatetime
        reminderChannel: $reminderChannel
        reminderNote: $reminderNote
      }
    ){
      success
      addedReminder {
        id
        reminderDate
        reminderNote
      }
    }
  }
`;

export const ADD_COLLECTION_ACTION = gql`
  mutation AddCollectionAction(
    $collectionManagerIds: [Int]!
    $actionType: CollectionActionEnum!
    $comment: String
    $collectionContactIds: [Int]!
  ) {
    addCollectionAction(collectionAction: {
      collectionManagerIds: $collectionManagerIds,
      actionType: $actionType,
      comment: $comment,
      collectionContactIds: $collectionContactIds,
    }) {
      success
      addedAction {
        id
        actionType
        comment
        createdAt
        collectionContacts {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const COLLECT_INVOICES = gql`
  mutation CollectInvoices($collectionReceipts: [InvoiceCollectionReceiptType]!) {
    collectInvoices(collectionReceipts: $collectionReceipts) {
      success
    }
  }
`;

export const CREATE_COLLECTION_CONTACT = gql`
  mutation CreateCollectionContact(
    $companyRut: String!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
    $source: CollectionContactSourceEnum!,
  ) {
    createCollectionContact(
      companyRut: $companyRut,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
      source: $source,
    ) {
      success
    }
  }
`;

export const GET_PENDING_COLLECTION_PAYMENT_PLANS = gql`
query getPendingCollectionObjects{
  getPendingCollectionObjects{
    confirmingInvoices{
      id
      amountWithIva
      folio
      company{
        id
        name
        rut
        executiveAssigned {
          firstName
          lastName
        } 
      }
      receiver{
        masterEntity {
          id
          name
          rut
          dv
        }
      }
      dateToPay
      actualAmortizationSchedule{
        id
        createdAt
        interestRate
        defaultRate
        status
        installments {
          id
          expirationDate
          installmentAmountToPay
          installmentManager{
            id
            status
            debtAmount
            totalAmountToPay
            collectionManager{
              id
              status
              actions {
                id
                actionType
                comment
                createdAt
                collectionContacts {
                  id
                  firstName
                  lastName
                  email
                  phoneNumber
                }
                author {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
    invoices{
      id
      nameReceiver
      rutReceiver
      amountWithIva
      folio
      company{
        id
        name
        rut
      }
      receiver{
        id
        name
        rut
        dv
      }
      dateToPay
      actualAmortizationSchedule{
        id
        createdAt
        interestRate
        defaultRate
        status
        installments {
          id
          expirationDate
          installmentAmountToPay
          installmentManager{
            id
            status
            debtAmount
            totalAmountToPay
            collectionManager{
              id
              status
              actions {
                id
                actionType
                comment
                createdAt
                collectionContacts {
                  id
                  firstName
                  lastName
                  email
                  phoneNumber
                }
                author {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const REINTEGRATION_FLAG_ACTIVATION = gql`
  mutation ReintegrationFlagActivation($managerId: Int!, $newStatus: Boolean!) {
    reintegrationFlagActivation(managerId: $managerId, newStatus: $newStatus) {
      success
    }
  }`;

export const DEBTOR_PAYMENT_BEHAVIOUR_ENUM = gql`
  query getDebtorPaymentBehaviourEnum {
    getDebtorPaymentBehaviourEnum{
      value
      label
    }
  }
`;

export const SET_DEBTOR_PAYMENT_BEHAVIOUR = gql`
  mutation setDebtorPaymentBehaviour(
    $masterEntityId: Int!,
    $newBehaviour: String!
  ) {
    setDebtorPaymentBehaviour(
      masterEntityId: $masterEntityId,
      newBehaviour: $newBehaviour
    ) {
      success
    }
  }
`;

export const GET_COLLECTION_MAIL_ENUM = gql`
  query GetCollectionMailEnum{
    getCollectionMailEnum{
      value
      label
    }
  }
`;

export const SEND_COLLECTION_MAIL = gql`
mutation SendCollectionMail(
  $contactsIds: [Int]!
  $mailType: String!
  $managersAndAttachs: [ManagersAndAttachsType]!
) {
  sendCollectionMail(
    contactsIds: $contactsIds
    mailType: $mailType
    managersAndAttachs: $managersAndAttachs
  ) {
    success
    message
  }
}
`;

export const GET_COLLECTION_MAIL_PREVIEW = gql`
mutation GetCollectionMailPreview(
  $mailType: String!
  $managersIds: [Int]!
) {
  getCollectionMailPreview(
    mailType: $mailType
    managersIds: $managersIds
  ) {
    success
    message
    preview
  }
}
`;

export const UPDATE_COLLECTION_CONTACT = gql`
  mutation UpdateCollectionContact(
    $contactId: String!,
    $email: String,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String,
    $position: String,
  ) {
    updateCollectionContact(
      contactId: $contactId,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      position: $position,
    ) {
      success
    }
  }
`;
