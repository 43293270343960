import fetch from 'node-fetch';

const rawRefresh = `mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    __typename
  }
}`;

export default async (uri, options, login) => {
  const response = await fetch(uri, options);
  const text = await response.text();
  const jsonResponse = await JSON.parse(text);
  const expiredMessages = ['Signature has expired', 'La firma ha expirado'];
  if (jsonResponse.errors && expiredMessages.includes(jsonResponse.errors[0].message)) {
    const user = await JSON.parse(localStorage.getItem('user'));
    const body = {
      operationName: 'refreshToken',
      variables: {
        refreshToken: user.refreshToken,
      },
      query: rawRefresh,
    };
    const refreshResponse = await fetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const refreshJSON = await refreshResponse.json();
    user.token = refreshJSON.data.refreshToken.token;
    user.refreshToken = refreshJSON.data.refreshToken.refreshToken;
    login(user);
    const newOptions = {
      ...options,
      headers: { ...options.headers, authorization: `JWT ${user.token}` },
    };
    return fetch(uri, newOptions);
  }
  const result = {};
  result.ok = true;
  result.text = () => Promise.resolve(text);
  return result;
};
