import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context/user';

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest} // eslint-disable-line
      render={(props) => (
        user ? (
          <Component { ...props } /> // eslint-disable-line
        ) : (
          <Redirect to="/" />
        )
      )}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;
