const labelHelper = {
  percentageReceptionAcknowledgmentInvoices: { name: '% Facturas Acuse de recibo', label: '%' },
  percentageCreditNotesInvoices: { name: '% Facturas Nota de credito (Total o Parcial)', label: '%' },
  percentageCessionsOverIssued: { name: '% Facturas cedidas', label: '%' },
  percentageSalesConcentrationBiggestDebtor: { name: '% Ventas deudor mas grande', label: '%' },
  percentageSalesConcentration10PercentBiggestDebtor: { name: '% Ventas 10% deudores mas grandes', label: '%' },
  percentageRejectedInvoices: { name: '% Facturas Rechazadas Sii', label: '%' },
  averageIssuedInvoices: { name: 'Promedio facturas emitidas', label: '$' },
  largestLoanedInvoice3Months: { name: 'Mayor monto cedido ultimos 3 meses', label: '$' },
  largestLoanedInvoice12Months: { name: 'Mayor monto cedido ultimos 12 meses', label: '$' },
  amountDebtorsCeeded: { name: 'Cantidad de deudores cedidos' },
  amountProviders: { name: 'Cantidad de proveedores' },
  amountDebtors: { name: 'Cantidad de deudores' },
  averageReceptionAcknowledgmentInvoicesTime: { name: 'Tiempo promedio entre emision y nota de credito (dias)' },
  averageRejectionInvoiceTime: { name: 'Tiempo promedio entre emision y rechazo (dias)' },
};

export default labelHelper;
