/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Collapse,
  Box,
  Typography,
  TableHead,
  IconButton,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import CustomPagination from './CustomPagination';
import EnhancedTableHead from './Header';
import EnhancedTableToolbar from './Toolbar';
import { stableSort, getComparator } from './TableFunctions';

/* This table filters/sorts  with the frontend  */

const Table = (props) => {
  const {
    headers,
    initialOrderId,
    withSelect,
    initialOrder,
    topLegend,
    handleSelected,
    setNewDataOrder,
    data,
    dataToRows,
    clearSelection,
    selectedObjects,
    withoutSelectHeader,
    pagination,
    rowsPerPageInit,
    sendFilterData,
    collapse,
  } = props;
  const [rows, setRows] = useState(dataToRows(data));
  const [collapseRows, setCollapseRows] = useState([]);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderId);
  const [selected, setSelected] = useState(selectedObjects.map((obj) => obj.id));
  const [selectedRows, setSelectedRows] = useState(selectedObjects);
  const [selectedCollapseTable, setSelectedCollapseTable] = useState([]);
  const [openedRows, setOpenedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(rowsPerPageInit);
  const {
    withSelect: withSelectCollapseTable,
    handleSelected: handleSelectedCollapseTable,
  } = collapse || {};

  useEffect(() => {
    const newOp = rows.reduce((acc, row) => ({ ...acc, [row.id]: false }), {});
    setOpenedRows(newOp);
  }, []);

  const mergeCollapseRows = (_data) => {
    if (collapse) {
      const allCollapseRows = _data.reduce((acc, row) => [...acc, ...row.collapseData.components], []);
      return allCollapseRows;
    }
    return [];
  };

  const getCollapseRowById = (_id) => {
    const collapseRow = collapseRows.find((row) => row.id === _id);
    return collapseRow;
  };

  useEffect(() => {
    setRows(dataToRows(data));
    setCollapseRows(mergeCollapseRows(dataToRows(data)));
    if (clearSelection) {
      setSelected([]);
      setSelectedRows([]);
    }
  }, [data, clearSelection, dataToRows]);

  const handleRequestSort = (property, direction) => {
    setOrder(direction);
    setOrderBy(property);
    setNewDataOrder((oldData) => stableSort(oldData, getComparator(direction, property)));
  };

  useEffect(() => {
    setNewDataOrder((oldData) => stableSort(oldData, getComparator(order, initialOrderId)));
  }, [initialOrderId]);

  const openRow = (id) => {
    setOpenedRows((prevRows) => ({ ...prevRows, [id]: !prevRows[id] }));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedRows(rows);
      handleSelected(rows);
      return;
    }
    setSelected([]);
    setSelectedRows([]);
    handleSelected([]);
  };

  const handleSelectOneCollapseTable = (_eventCollapse, _rowCollapse) => {
    const { id: _idCollapse } = _rowCollapse;
    const { target: { checked } } = _eventCollapse;
    getCollapseRowById(_idCollapse);
    setSelectedCollapseTable((prev) => {
      if (checked) {
        const checkedRows = [...prev, _idCollapse];
        const objectsSelected = checkedRows.map((id) => getCollapseRowById(id));
        handleSelectedCollapseTable([...objectsSelected]);
        return checkedRows;
      }
      const copy = [...prev];
      const indexRemove = copy.indexOf(_idCollapse);
      copy.splice(indexRemove, 1);
      const objectsSelected = copy.map((id) => getCollapseRowById(id));
      handleSelectedCollapseTable([...objectsSelected]);
      return [...copy];
    });
  };

  const handleSelectOne = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    const selectedRow = rows.find((row) => row.id === id);
    let newSelected = [];
    let newSelectedRows = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelectedRows.concat(selectedRows, selectedRow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setSelectedRows(newSelectedRows);
    handleSelected(newSelectedRows);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelectedCollapseTable = (id) => selectedCollapseTable.indexOf(id) !== -1;

  const emptyRows = rowsPerPage
    - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const toRow = (row, index) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <React.Fragment key={`${index}_row_table`}>
        <TableRow
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={`${row.id}`}
          style={row.style}
        >
          {withSelect && (
            <TableCell padding="none" style={{ width: 75 }}>
              <Checkbox
                onChange={(event) => handleSelectOne(event, row.id)}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId, 'aria-label': `${row.disabled && 'disabled'} checkbox` }}
                disabled={row.disabled}
              />
            </TableCell>
          )}
          {row.collapseData
            && (
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={() => openRow(row.id)}>
                {openedRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            )}
          {headers.map((headCell) => (
            <TableCell key={`${headCell.label},${row.id}`}>
              {row[headCell.id].component}
            </TableCell>
          ))}
        </TableRow>
        {row.collapseData
        && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openedRows[row.id]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {row.collapseData.collapseTitle}
                </Typography>
                <MuiTable size="small">
                  <TableHead>
                    <TableRow>
                      {withSelectCollapseTable && (
                        <TableCell padding="none" style={{ width: 75 }}>
                            {' '}
                        </TableCell>
                      )}
                      {row.collapseData.headers.map((header) => (
                        <TableCell key={`${header.id}`} align={`${header.align}`}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.collapseData.components.map((rowCollapse) => (
                      <TableRow key={`${rowCollapse.id}`} selected={rowCollapse.selectedRow}>
                        {withSelectCollapseTable && (
                          <TableCell padding="none" style={{ width: 75 }}>
                            <Checkbox
                              onChange={(eventCollapse) => handleSelectOneCollapseTable(eventCollapse, rowCollapse)}
                              checked={isSelectedCollapseTable(rowCollapse.id) || rowCollapse.checked}
                              inputProps={{ 'aria-labelledby': labelId, 'aria-label': `${rowCollapse.disabled && 'disabled'} checkbox` }}
                              disabled={rowCollapse.disabled}
                            />
                          </TableCell>
                        )}
                        {row.collapseData.headers.map((header) => <TableCell key={`${header.label},${header.id}`}>{rowCollapse[header.id].component}</TableCell>)}
                      </TableRow>
                    ))}
                  </TableBody>
                </MuiTable>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        )}
      </React.Fragment>
    );
  };
  return (
    <>
      <EnhancedTableToolbar
        topLegend={topLegend}
        numSelected={selected.length}
      />
      <TableContainer>
        <MuiTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAll}
            onRequestSort={handleRequestSort}
            headCells={headers}
            rowCount={rows.length}
            withSelect={withSelect}
            withoutSelectHeader={withoutSelectHeader}
            sendFilterData={sendFilterData}
            collapse={collapse}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                toRow(row, index)
              ))}
            {emptyRows > 0 && rows.length > rowsPerPage && (
            <TableRow style={{ height: 53 * emptyRows, backgroundColor: 'transparent' }} />
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {pagination
      && (
      <CustomPagination
        page={page}
        totalPages={Math.ceil(rows.length / rowsPerPage)}
        onChangePage={handleChangePage}
      />
      )}
    </>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setNewDataOrder: PropTypes.func.isRequired,
  dataToRows: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  initialOrderId: PropTypes.string.isRequired,
  initialOrder: PropTypes.string,
  // OPTIONAL PROPS
  withSelect: PropTypes.bool,
  handleSelected: PropTypes.func,
  topLegend: PropTypes.element,
  clearSelection: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedObjects: PropTypes.array,
  withoutSelectHeader: PropTypes.bool,
  pagination: PropTypes.bool,
  rowsPerPageInit: PropTypes.number,
  sendFilterData: PropTypes.func,
  collapse: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(
      {
        withSelect: PropTypes.bool.isRequired,
        handleSelected: PropTypes.func.isRequired,
      },
    )]),
};

Table.defaultProps = {
  withSelect: false,
  handleSelected: () => { },
  topLegend: null,
  initialOrder: 'desc',
  clearSelection: false,
  withoutSelectHeader: false,
  pagination: true,
  selectedObjects: [],
  rowsPerPageInit: 50,
  sendFilterData: () => {},
  collapse: false,
};

export default Table;
