import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Tooltip,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { TablePaginationBackend } from '../components/tables';
import { AssignmentExecutiveClient } from '../components/dialogs';
import { GET_COMPANY_CUSTOMERS_FOR_ASSIGNMENT, GET_COMMERCIAL_EXECUTIVES } from '../gql/customer';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const useOwnStyles = makeStyles(() => ({
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  inputSearch: {
    height: 60,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const headers = [
  { id: 'companyName', label: 'Razón social', align: 'left', hideSortIcon: true },
  { id: 'companyRut', label: 'Rut', align: 'left', hideSortIcon: true },
  {
    id: 'acceptedTerms',
    label: 'TyC aceptado',
    align: 'left',
    hideSortIcon: true,
  },
  {
    id: 'executiveAssigned',
    label: 'Ejecutivo asignado',
    align: 'left',
    filter: {
      titleTooltip: 'Filtrar por ejecutivos',
      dataList: [],
      initState: {},
    },
    hideSortIcon: true,
  },
];

const AssignmentExecutive = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const [clients, setClients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [headersState, setHeadersState] = useState(headers);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [objectOrder, setObjectOrder] = useState({
    property: 'companyName',
    order: 'desc',
  });
  const [lengthData, setLengthData] = useState(0);
  const [modalAssignment, setModalAssignment] = useState(false);
  const [filterMetaValues, setFilterMetaValues] = useState(null);
  // the limit number must match with rowsPerPage of the Table
  const limit = 50;
  const [
    getClients,
    { data, loading, error },
  ] = useLazyQuery(GET_COMPANY_CUSTOMERS_FOR_ASSIGNMENT, {
    fetchPolicy: 'network-only',
  });
  const { data: executivesData,
  } = useQuery(GET_COMMERCIAL_EXECUTIVES,
    { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (filterMetaValues) {
      const executiveObject = headersState.find((obj) => obj.id === 'executiveAssigned');
      // eslint-disable-next-line prefer-destructuring
      executiveObject.filter.initState = filterMetaValues[0];
      const newHeaders = [...headers];
      setHeadersState(newHeaders);
    }
  }, [filterMetaValues]);

  useEffect(() => {
    if (executivesData && executivesData.getCommercialExecutives) {
      const executivesDataList = executivesData.getCommercialExecutives.map((ex) => (
        { label: `${ex.firstName} ${ex.lastName}`, value: ex.email }
      ));
      executivesDataList.unshift({ label: 'Sin asignar', value: 'null' });
      const executiveObject = headers.find((obj) => obj.id === 'executiveAssigned');
      executiveObject.filter.dataList = executivesDataList;
      const newHeaders = [...headers];
      setHeadersState(newHeaders);
    }
  }, [executivesData]);

  useEffect(() => {
    if (data) {
      setClients(data.getCustomers.clients);
      setLengthData(data.getCustomers.length);
    }
  }, [data]);

  useEffect(() => {
    // the first query
    // the limit number must match with rowsPerPage of Table
    getClients({
      variables: {
        offset: 0,
        limit,
        order: objectOrder.order,
        propertyOrder: objectOrder.property,
        filterMetaValues,
      },
    });
  }, []);

  useEffect(() => {
    getClients({
      variables: {
        offset: currentPage * limit,
        limit,
        order: objectOrder.order,
        propertyOrder: objectOrder.property,
        filterMetaValues,
      },
    });
  }, [objectOrder]);

  const handleRefetchClients = async () => {
    try {
      await getClients({
        variables: {
          offset: currentPage * limit,
          limit,
          order: objectOrder.order,
          propertyOrder: objectOrder.property,
          filterMetaValues,
        },
      });
    } catch (_error) {
      // pass
    }
  };

  const receiveFilterData = async (filterData) => {
    await getClients({
      variables: {
        offset: 0,
        limit,
        order: objectOrder.order,
        propertyOrder: objectOrder.property,
        filterMetaValues: [filterData],
      },
    });
    setFilterMetaValues([filterData]);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      masterEntity,
      backofficeHasAcceptedTerms,
      style,
      executiveAssigned,
    } = row;
    return {
      id: masterEntity.rut,
      companyRut: {
        value: masterEntity.rut,
        component: <Typography>{formatRut(masterEntity.rut)}</Typography>,
      },
      companyName: {
        value: masterEntity.name,
        component: (
          <Tooltip
            interactive
            classes={{ tooltip: classes.tooltip }}
            title={masterEntity.name}
          >
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {masterEntity.name}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      acceptedTerms: {
        value: backofficeHasAcceptedTerms ? 'Sí' : 'No',
        component: <Typography>{backofficeHasAcceptedTerms ? 'Sí' : 'No'}</Typography>,
      },
      style,
      executiveAssigned: {
        value: executiveAssigned ? executiveAssigned.email : 'none',
        component: <Typography>{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>,
      },
    };
  });

  const newRequestWithOrder = (property) => {
    // newOrder can be 'asc' or 'desc'
    const orderBool = objectOrder.order === 'asc';
    const order = orderBool ? 'desc' : 'asc';
    setObjectOrder({ property, order });
  };

  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = async () => {
    // the limit number must match with rowsPerPage of Table
    await getClients(
      {
        variables: {
          searchValue,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaValues,
        },
      },
    );
  };

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) {
    return (
      <Typography variant="h3" style={{ margin: '2rem' }}>
        Hubo un error al cargar los clientes. Intenta más tarde
      </Typography>
    );
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4">
          <b>Clientes de Fingo</b>
        </Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalAssignment(true);
            }}
            disabled={selectedRows.length === 0}
          >
            Asignar Ejecutivo
          </Button>
        </div>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.searchContainer}>
          <TextField
            value={searchValue}
            variant="outlined"
            onChange={handleChangeSearchValue}
            helperText="Busca a un cliente por rut o nombre de la empresa"
            className={classes.searchBox}
            inputProps={{
              style: {
                height: 32,
              },
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleSearch}
            className={classes.searchButton}
          >
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12} className={loading ? classes.loading : classes.tableContainer}>
          <TablePaginationBackend
            withSelect
            data={clients}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setClients}
            headers={headersState}
            data_type="customer"
            handleSelected={setSelectedRows}
            initialOrderId="companyName"
            clearSelection={selectedRows.length === 0}
            fetchMore={getClients} // getClients
            lengthData={lengthData}
            argument=""
            setCurrentPageParent={setCurrentPage}
            newRequestWithOrder={newRequestWithOrder}
            objectOrder={objectOrder}
            loading={loading}
            sendFilterData={receiveFilterData}
            filterVariables={{ filterMetaValues }}
          />
        </Grid>
        <AssignmentExecutiveClient
          open={modalAssignment}
          handleClose={() => {
            setModalAssignment(false);
          }}
          handleConfirm={() => {
            handleRefetchClients();
            setSelectedRows([]);
          }}
          selectedRows={selectedRows}
        />
      </Grid>
    </div>
  );
};

export default AssignmentExecutive;
