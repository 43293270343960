import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../tables';
import { GET_REJECTED_INVOICES } from '../../gql/invoicesStatus';
import { formatMoney } from '../../helpers/money';
import { getTimeDiffText } from '../../helpers/date';
import { transformPreofferEvaluationRequest } from '../../helpers/evaluationsRequests';
import { Filters } from '../utils';
import DebtorCell from '../shared/DebtorCell';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  emptyInvoices: {
    marginTop: '2rem',
  },
});

const headers = [
  { id: 'folio', label: 'Folio', isSearchable: true },
  { id: 'rutReceiver', label: 'Deudor', type: 'rut', isSearchable: true },
  { id: 'type', label: 'Tipo', type: 'text', isSearchable: false },
  { id: 'collectorName', label: 'Cobrador', isSearchable: true },
  { id: 'dateIssued', label: 'Fecha de emisión', type: 'date', isSearchable: true },
  { id: 'dateExpiration', label: 'Fecha de vencimiento', type: 'date', isSearchable: true },
  { id: 'amountWithIva', label: 'Monto facturado', type: 'money', isSearchable: true },
  { id: 'monthlyRate', label: 'Tasa mensual', type: 'rate', isSearchable: true },
  { id: 'defaultRate', label: 'Mora mensual', className: 'xs', type: 'rate', isSearchable: false },
  { id: 'advancePercentage', label: 'Porcentaje anticipado', className: 'xs', type: 'rate', isSearchable: true },
  { id: 'rejectionReason', label: 'Motivo de rechazo', type: 'text' },
  { id: 'updatedAt', label: 'Fecha de rechazo', type: 'date' },
];

const RejectedInvoices = ({ setLoading }) => {
  const { clientRut } = useParams();
  const classes = useStyles();
  const [preoffers, setPreoffers] = useState([]);
  const [filteredPreoffers, setFilteredPreoffers] = useState([]);
  const {
    data: invoicesData,
    loading: loadingRejectedInvoices,
  } = useQuery(GET_REJECTED_INVOICES, { variables: { ruts: [clientRut] }, fetchPolicy: 'network-only' });

  useEffect(() => {
    if (invoicesData) {
      setPreoffers(invoicesData.getRejectedInvoices.map(transformPreofferEvaluationRequest));
      setFilteredPreoffers(
        invoicesData.getRejectedInvoices.map(transformPreofferEvaluationRequest),
      );
    }
  }, [invoicesData]);

  useEffect(() => {
    setLoading(loadingRejectedInvoices);
  }, [loadingRejectedInvoices]);

  const openPdf = (pdf) => {
    window.open(pdf);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      nameReceiver,
      rutReceiver,
      receiver: {
        id: receiverId,
      },
      collector,
      dateIssued,
      dateExpiration,
      amountWithIva,
      monthlyRate,
      defaultRate,
      advancePercentage,
      confirming,
      pdf,
      rejectionReason,
      updatedAt,
    } = row;
    return ({
      id,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography className={classes.textCell}>{folio}</Typography>
            <IconButton onClick={() => openPdf(pdf)}>
              <InsertDriveFile />
            </IconButton>
          </div>),
        size: 'small',
      },
      type: {
        value: confirming,
        component: <Typography className={classes.textCell}>{confirming ? 'CONFIRMING' : 'FACTORING'}</Typography>,
        size: 'small',
      },
      rutReceiver: {
        value: rutReceiver,
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      collectorName: {
        value: null,
        component: <Typography className={classes.textCell}>{collector ? `${collector.firstName} ${collector.lastName}` : 'Sin asignar'}</Typography>,
        size: 'small',
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <div>
            <Typography className={classes.date}>{dateIssued.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateIssued)}</Typography>
          </div>
        ),
        size: 'small',
      },
      dateExpiration: {
        value: dateExpiration,
        component: (
          <div>
            <Typography className={classes.date}>{dateExpiration.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateExpiration)}</Typography>
          </div>
        ),
        size: 'small',
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography className={classes.textCell}>${formatMoney(amountWithIva)}</Typography>
        ),
        size: 'small',
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <Typography className={classes.textCell}>{(monthlyRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <Typography className={classes.textCell}>{(defaultRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      advancePercentage: {
        value: advancePercentage,
        component: (
          <Typography className={classes.textCell}>{(advancePercentage).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      rejectionReason: {
        value: rejectionReason,
        component: (
          <Typography>{rejectionReason}</Typography>
        ),
      },
      updatedAt: {
        value: updatedAt,
        component: (
          <Typography className={classes.date}>{updatedAt.format('DD-MM-YYYY')}</Typography>
        ),
      },
    });
  });

  return (
    <Grid container>
      <Grid item xs={6} sm={6} className={classes.searchBar}>
        <Filters
          allData={preoffers}
          setFilteredData={setFilteredPreoffers}
          searchFields={['folio', 'nameReceiver', 'rutReceiver']}
          searchPlaceholder="Búsqueda por folio o rut o nombre de deudor"
        />
      </Grid>
      { (filteredPreoffers.length > 0)
        ? (
          <Table
            headers={headers}
            initialOrderId="monthlyRate"
            setNewDataOrder={setFilteredPreoffers}
            data={filteredPreoffers}
            dataToRows={transformDataToComponents}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            { !loadingRejectedInvoices && <Typography variant="h4" align="center" className={classes.emptyInvoices}>No se encontraron facturas </Typography>}
          </Grid>
        )}
    </Grid>

  );
};

RejectedInvoices.propTypes = {
  setLoading: PropTypes.func,
};

RejectedInvoices.defaultProps = {
  setLoading: () => {},
};

export default RejectedInvoices;
