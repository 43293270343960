import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  inDebt: {
    color: theme.palette.error.main,
  },
  soonInDebt: {
    color: theme.palette.warning.main,
  },
}));

const DateWithDaysLeft = ({ date }) => {
  const classes = useStyles();
  const today = moment().startOf('day');
  const diff = date.diff(today, 'days');

  const getClassName = () => {
    if (diff < 0) return classes.inDebt;
    if (diff <= 10) return classes.soonInDebt;
    return null;
  };

  const getDiffText = () => {
    if (diff < -1) return `Hace ${Math.abs(diff)} días`;
    if (diff === -1) return 'Ayer';
    if (diff === 0) return 'Hoy';
    if (diff === 1) return 'Mañana';
    return `En ${diff} días`;
  };

  return (
    <>
      <Typography>{date.format('DD-MM-YYYY')}</Typography>
      <Typography variant="caption" className={getClassName()}>
        {getDiffText()}
      </Typography>
    </>
  );
};

DateWithDaysLeft.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
};

export default DateWithDaysLeft;
