import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Typography,
  Grid,
  Tooltip,
  TextField,
  Button,

  Tab,
  Tabs,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { parseMoney } from '../helpers/money';
import { parseDate, getTimeDiffText } from '../helpers/date';
import { TablePaginationBackend } from '../components/tables';
import { GET_INVOICES_BY_STATUS, GET_INVOICES_COUNT_BY_STATUS } from '../gql/invoices';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import DebtorCell from '../components/shared/DebtorCell';

const useOwnStyles = makeStyles((theme) => ({
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  inputSearch: {
    height: 60,
  },

  greenColor: {
    background: theme.palette.success.main,
  },
  greenColorIcon: {
    color: theme.palette.success.main,
    marginRight: '0.5rem',
  },
  redColor: {
    background: theme.palette.error.main,
  },
  redColorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem',
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  searchContainer: {
    display: 'flex',
  },
  searchButton: {
    marginBottom: '2rem',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyData: {
    marginTop: '2rem',
  },

}));

const headers = [
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'folios', label: 'Folio', align: 'left', hideSortIcon: true },
  { id: 'amount', label: 'Monto', align: 'left', hideSortIcon: true },
  { id: 'debtor', label: 'Deudor', align: 'left', hideSortIcon: true },
  { id: 'evaluation_date', label: 'Fecha de Evaluación', align: 'left', hideSortIcon: true },
  { id: 'operation_date', label: 'Fecha de Operación', align: 'left', hideSortIcon: true },
  { id: 'date_to_pay', label: 'Fecha de Vencimiento', align: 'left', hideSortIcon: true },
];

const CustomerDetail = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const initHeaders = headers;
  const [invoices, setInvoices] = useState([]);
  const [invoicesCount, setInvoicesCount] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('Evaluating');
  const [lengthData, setLengthData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [objectOrder, setObjectOrder] = useState({ property: 'client', order: 'desc' });
  const [selectedTab, setSelectedTab] = useState(0);
  const limit = 10;
  const {
    data: dataGetInvoicesCount,
    loading: loadingGetInvoicesCount,
    error: errorGetInvoicesCount,
  } = useQuery(GET_INVOICES_COUNT_BY_STATUS);

  const [getInvoices,
    {
      data,
      loading,
      error,
    },
  ] = useLazyQuery(GET_INVOICES_BY_STATUS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (data) {
      setInvoices(data.getInvoicesByStatus.invoices);
      setLengthData(data.getInvoicesByStatus.length);
    }
  }, [data]);

  useEffect(() => {
    if (dataGetInvoicesCount) {
      setInvoicesCount({
        evaluating: dataGetInvoicesCount.getInvoicesCountByStatus.evaluatingInvoices,
        accepted: dataGetInvoicesCount.getInvoicesCountByStatus.acceptedInvoices,
        ratification: dataGetInvoicesCount.getInvoicesCountByStatus.ratificationInvoices,
        pendingDocuments: dataGetInvoicesCount.getInvoicesCountByStatus.pendingDocumentsInvoices,
        pendingTransfer: dataGetInvoicesCount.getInvoicesCountByStatus.pendingInvoices,
        transfered: dataGetInvoicesCount.getInvoicesCountByStatus.transferedInvoices,
        debt: dataGetInvoicesCount.getInvoicesCountByStatus.debtInvoices,
      });
    }
  }, [dataGetInvoicesCount]);

  useEffect(() => {
    getInvoices(
      {
        variables: {
          status: 'Evaluating',
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
        },
      },
    );
    setInvoices([]);
  }, []);

  useEffect(() => {
    getInvoices(
      {
        variables: {
          searchValue,
          status,
          offset: currentPage * limit,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
        },
      },
    );
    setInvoices([]);
  }, [objectOrder]);

  const handleSearch = () => {
    getInvoices(
      {
        variables: {
          status,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          searchValue,
        },
      },
    );
    setInvoices([]);
  };

  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const handleTabChange = (event, value) => {
    let newStatus = '';
    if (value === 0) {
      newStatus = 'Evaluating';
    }
    if (value === 1) {
      newStatus = 'Accepted';
    }
    if (value === 2) {
      newStatus = 'PendingRatification';
    }
    if (value === 3) {
      newStatus = 'PendingDocumentsReview';
    }
    if (value === 4) {
      newStatus = 'PendingTransfer';
    }
    if (value === 5) {
      newStatus = 'Transfered';
    }
    if (value === 6) {
      newStatus = 'Debt';
    }
    getInvoices(
      {
        variables: {
          status: newStatus,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
        },
      },
    );
    setInvoices([]);
    setStatus(newStatus);
    setSelectedTab(value);
  };

  const newRequestWithOrder = (property) => {
    // newOrder can be 'asc' or 'desc'
    const orderBool = objectOrder.order === 'asc';
    const order = orderBool ? 'desc' : 'asc';
    setObjectOrder({ property, order });
  };

  const receiveFilterData = async () => {
    await getInvoices(
      {
        variables: {
          status,
          limit,
          offset: 0,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
        },
      },
    );
    setInvoices([]);
  };

  const parseTabNames = (number) => {
    if (number === 1) {
      return `Evaluación (${invoicesCount.evaluating})`;
    }
    if (number === 2) {
      return `Aceptados (${invoicesCount.accepted})`;
    }
    if (number === 3) {
      return `Ratificación (${invoicesCount.ratification})`;
    }
    if (number === 4) {
      return `Sin Ratificación con OC/HES (${invoicesCount.pendingDocuments})`;
    }
    if (number === 5) {
      return `Giro Pendiente (${invoicesCount.pendingTransfer})`;
    }
    if (number === 6) {
      return `Girado (${invoicesCount.transfered})`;
    }
    return `Morosas (${invoicesCount.debt})`;
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      style,
      company,
      folio,
      amountWithIva,
      nameReceiver,
      rutReceiver,
      dateOperation,
      dateToPay,
      preoffer,
    } = row;
    const preofferevaluationrequestDate = (preoffer_) => {
      if (preoffer_) {
        if (preoffer_.preofferevaluationrequest) {
          return moment(preoffer_.preofferevaluationrequest);
        }
      }
      return null;
    };
    const resolvePreOfferDate = preofferevaluationrequestDate(preoffer);
    return ({
      id: company.rut,
      client: {
        value: company.name,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={company.rut}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {company.name}
              </Typography>
              <Typography>
                {formatRut(company.rut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      folios: {
        value: folio,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={folio}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {folio}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      amount: {
        value: amountWithIva,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={amountWithIva}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {parseMoney(amountWithIva)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      debtor: {
        value: rutReceiver,
        // <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={nameReceiver}>
        //   <div style={{ maxWidth: 300 }}>
        //     <Typography className={classes.oneLineClamp}>
        //       {nameReceiver}
        //     </Typography>
        //     <Typography>
        //       {formatRut(rutReceiver)}
        //     </Typography>
        //   </div>
        // </Tooltip>
        component: <DebtorCell name={nameReceiver} rut={rutReceiver} />,
      },
      evaluation_date: {
        value: resolvePreOfferDate,
        component: (
          <div>
            <Typography className={classes.date}>
              {resolvePreOfferDate
                ? resolvePreOfferDate.format('DD-MM-YYYY HH:mm:ss')
                : 'No existe evaluación'}
            </Typography>
            <Typography variant="caption">
              {resolvePreOfferDate
                ? getTimeDiffText(resolvePreOfferDate)
                : 'No existe evaluación'}
            </Typography>
          </div>
        ),
        size: 'small',
      },
      operation_date: {
        value: dateOperation,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={dateOperation}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {dateOperation ? parseDate(dateOperation) : dateOperation}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      date_to_pay: {
        value: dateToPay,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={dateToPay}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {dateToPay ? parseDate(dateToPay) : dateToPay}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      style,
    });
  });
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error || errorGetInvoicesCount) {
    return (<Typography variant="h3" style={{ margin: '2rem' }}> Hubo un error al cargar los clientes. Intenta de nuevo</Typography>);
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.row}>
          <Typography variant="h4"><b>Estado de clientes</b></Typography>
        </div>
      </div>

      <Grid container className={classes.container}>

        <Grid item xs={12} className={classes.searchContainer}>
          <TextField
            value={searchValue}
            variant="outlined"
            onChange={handleChangeSearchValue}
            helperText="Busca a un cliente por rut o nombre de la empresa"
            className={classes.searchBox}
            inputProps={{
              style: {
                height: 32,
              },
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleSearch}
            className={classes.searchButton}
          >
            Buscar
          </Button>
        </Grid>
        <Grid container justify="center" item xs={12} className={classes.searchContainer}>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => handleTabChange(event, value)}
          >
            <Tab label={parseTabNames(1)} />
            <Tab label={parseTabNames(2)} />
            <Tab label={parseTabNames(3)} />
            <Tab label={parseTabNames(4)} />
            <Tab label={parseTabNames(5)} />
            <Tab label={parseTabNames(6)} />
            <Tab label={parseTabNames(7)} />
          </Tabs>
        </Grid>
        <Grid item xs={12} className={loading ? classes.loading : classes.tableContainer}>
          <TablePaginationBackend
            data={invoices}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setInvoices}
            headers={initHeaders}
            argument={searchValue}
            dataType="invoice"
            initialOrderId="client"
            fetchMore={getInvoices}
            invoiceStatus={status}
            lengthData={lengthData}
            searchValue={searchValue}
            setCurrentPageParent={setCurrentPage}
            newRequestWithOrder={newRequestWithOrder}
            loading={loading || loadingGetInvoicesCount}
            objectOrder={objectOrder}
            sendFilterData={receiveFilterData}
            rowsPerPageInit={10}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerDetail;
