import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  Grid,
  Button,
  IconButton,
  LinearProgress,
  Badge,
  Tooltip,
} from '@material-ui/core';
import { Message, Block, FlashOn } from '@material-ui/icons';
import moment from 'moment';
import { Table } from '../components/tables';
import {
  RejectRatification,
  AcceptRatification,
  OneButtonAlert,
  AddRatificationActions,
} from '../components/dialogs';
import {
  RatificationActionsDrawer,
  ManageRatificationContacts,
  RatificationSendMail,
} from '../components/ratification';
import CustomerDocumentsMenu from '../components/customers/DocumentsMenu';
import { UserAvatar, Filters } from '../components/utils';
import { useStyles } from './styles';
import {
  GET_PRIORITY_RATIFICATION_INVOICES,
  REJECT_RATIFICATION,
  CREATE_RATIFICATION_ACTION,
  ACCEPT_RATIFICATION,
  ASSIGN_RATIFICATOR,
  GET_NON_PRIORITY_RATIFICATION_INVOICES,
  FETCH_TRACE,
} from '../gql/ratification';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { getTimePassedTextFromTime, getTimePassedFromDate, getTermTextBetweenDates } from '../helpers/date';
import { UserContext } from '../context/user';
import { OrderingPaymentTooltip } from '../components/collection/Tooltips';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'dateIssued', label: 'Emisión', align: 'right' },
  { id: 'paymentDate', label: 'Fecha de Pago', align: 'right' },
  { id: 'amount', label: 'Monto', align: 'right' },
  { id: 'documents', label: 'Documentos', align: 'right' },
  { id: 'contacts', label: 'Contactos', align: 'right' },
  { id: 'ratificator', label: 'Ratificador', align: 'center' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
  { id: 'actionsData', label: 'Gestiones', align: 'center' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
];

const PRIORITY_TEXTS = {
  title: 'Facturas prioritarias para ratificar',
  empty: 'No hay facturas prioritarias para ratificar',
};

const NON_PRIORITY_TEXTS = {
  title: 'Facturas sin prioridad para ratificar',
  empty: 'No hay facturas sin prioridad para ratificar',
};

const Ratification = ({ type }) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [ratificationInvoices, setRatificationInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rejectionOpen, setRejectionOpen] = useState(false);
  const [rejectionLoading, setRejectionLoading] = useState(false);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [actionsDrawerInvoice, setActionsDrawerInvoice] = useState({
    name: '',
    folio: 0,
    nameReceiver: '',
    actions: [],
    rutReceiver: 0,
    managerId: null,
    amount: 0,
  });
  const [acceptanceOpen, setAcceptanceOpen] = useState(false);
  const [acceptanceLoading, setAcceptanceLoading] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [sendMailOpen, setSendMailOpen] = useState(false);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(true);
  const [selectedReceiver, setSelectedReceiver] = useState({
    nameReceiver: null, rutReceiver: null, ratificationBlacklistReceiver: null,
  });
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [receiverData, setReceiverData] = useState();
  const [openManageContacts, setOpenManageContacts] = useState(false);
  const queryDocument = type === 'priority'
    ? GET_PRIORITY_RATIFICATION_INVOICES
    : GET_NON_PRIORITY_RATIFICATION_INVOICES;
  const {
    data: ratificationInvoicesData,
    refetch: ratificationInvoicesRefetch,
    error: ratificationInvoicesError,
  } = useQuery(queryDocument);
  const [fetchTrace, { loading: loadingTraces }] = useMutation(
    FETCH_TRACE, { onError: () => {}, refetchQueries: [queryDocument], awaitRefetchQueries: true },
  );
  const [rejectRatification] = useMutation(REJECT_RATIFICATION);
  const [createAction] = useMutation(CREATE_RATIFICATION_ACTION);
  const [acceptRatification] = useMutation(ACCEPT_RATIFICATION);
  const [assignRatificator] = useMutation(ASSIGN_RATIFICATOR);
  const dataKey = type === 'priority'
    ? 'getPriorityRatificationInvoices'
    : 'getNonPriorityRatificationInvoices';
  const texts = type === 'priority'
    ? PRIORITY_TEXTS
    : NON_PRIORITY_TEXTS;
  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
    const notEmpty = rows.length > 0;
    const sameReceiver = notEmpty
      && rows.every((row) => row.nameReceiver === rows[0].nameReceiver);
    const receiverCompany = (rows.length > 0) ? {
      rutReceiver: rows[0].rutReceiver,
      nameReceiver: rows[0].nameReceiver,
      ratificationBlacklistReceiver: rows[0].ratificationBlacklist?.active,
    } : { rutReceiver: null, nameReceiver: null };
    setSelectedReceiver(receiverCompany);
    setActionButtonDisabled(!sameReceiver);
  };

  const transformDataToRowLikeObjects = (_data) => _data.map((row) => {
    const {
      id,
      ratificator,
      invoice: {
        id: invoiceId,
        folio,
        objectPurchaseOrder,
        rutReceiver,
        nameReceiver,
        dateIssued,
        certificateUrl,
        dateToPay,
        amountWithIva,
        pdf,
        documents,
        integrationStatus,
        siiStatus,
        creditNoteStatus,
        orderingAssigned,
        receiver,
        company: {
          rut,
          name,
          executiveAssigned,
          masterEntity: {
            riskBlacklist: companyRiskBlacklist,
          },
        },
        lastHistoryStatus: {
          createdAt,
        },
        lightningPaymentAuthorized,
      },
      actions,
    } = row;
    const ratificationBlacklistActive = (
      receiver.ratificationBlacklist ? receiver.ratificationBlacklist.active : false
    );
    const realIntegrationStatus = (integrationStatus === 'LOANEDOTHER' && type !== 'priority')
      ? 'VALID' : integrationStatus;
    return {
      id,
      invoiceId,
      managerId: id,
      ratificator: ratificator ? ratificator.firstName : null,
      folio,
      orderingAssigned,
      rutReceiver,
      nameReceiver,
      executiveAssigned,
      rut,
      name,
      dateIssued,
      certificateUrl,
      paymentDate: dateToPay,
      amount: amountWithIva.amount,
      pdf,
      documents,
      timeInStage: getTimePassedFromDate(createdAt),
      actions,
      objectPurchaseOrder,
      actionsData: actions.length > 0 ? moment(actions[0].createdAt).valueOf() : 0,
      integrationStatus: realIntegrationStatus,
      ratificationBlacklistActive,
      companyRiskBlacklist,
      siiStatus,
      creditNoteStatus,
      lightningPaymentAuthorized,
    };
  });

  useEffect(() => {
    if (ratificationInvoicesData) {
      setLoading(true);
      const transformedInvoices = transformDataToRowLikeObjects(
        ratificationInvoicesData[dataKey],
      );
      setRatificationInvoices(transformedInvoices);
      setFilteredInvoices(transformedInvoices);
      if (actionsDrawerInvoice.folio) {
        const openedInvoice = ratificationInvoicesData[dataKey].find((element) => (
          element.id === actionsDrawerInvoice.managerId
        ));
        setActionsDrawerInvoice((oldInvoice) => ({
          ...oldInvoice,
          actions: openedInvoice.actions,
        }));
      }
      setLoading(false);
    }
  }, [ratificationInvoicesData]);

  useEffect(() => {
    if (receiverData) {
      setOpenManageContacts(true);
    }
  }, [receiverData]);

  useEffect(() => {
    if (!openManageContacts) {
      setReceiverData(null);
    }
  }, [openManageContacts]);

  const handleContacts = (row) => {
    setReceiverData({
      companyRut: row.rutReceiver,
      companyName: row.nameReceiver,
    });
  };

  const buildCompaniesAndFolios = () => selectedRows.reduce((acc, row) => {
    const { folio, client } = row;
    return { ...acc, [client.value]: [...(acc[client.value] || []), folio.value] };
  }, {});

  const onReject = async (reason, comment) => {
    const observation = (reason === 'other') ? comment : '';
    const ratificationManagerIds = selectedRows.map((row) => parseInt(row.id, 10));
    setRejectionLoading(true);
    try {
      await rejectRatification({
        variables: {
          ratificationManagerIds,
          resolutionType: reason,
          observation,
        },
      });
      setRejectionLoading(false);
      setRejectionOpen(false);

      setSuccessMessage('Rechazo de facturas exitoso.');
      setSuccessMessageOpen(true);

      ratificationInvoicesRefetch();
    } catch (err) {
      setErrorMessage('Hubo un error al intentar rechazar las facturas. Comunícate con el equipo de desarrollo.');
      setRejectionLoading(false);
      setErrorMessageOpen(true);
    }
  };

  const onCloseRejection = () => {
    setRejectionOpen(false);
  };

  const onCloseActionsDrawer = () => {
    setActionsDrawerOpen(false);
    setActionsDrawerInvoice({
      name: '',
      folio: '',
      nameReceiver: '',
      actions: [],
      rutReceiver: 0,
      managerId: null,
      amount: 0,
    });
  };

  const onClickActions = ({
    folio,
    nameReceiver,
    actions,
    rutReceiver,
    managerId,
    amount,
  }) => {
    setActionsDrawerInvoice({
      folio,
      nameReceiver,
      actions,
      managerId,
      rutReceiver,
      amount,
    });
    setActionsDrawerOpen(true);
  };

  const onCreateAction = async (actionType, comment, contactsIds, responded, channel) => {
    const ratificationContactsIds = (actionType === 'INITIATE_RATIFICATION_PROCESS'
      || actionType === 'INSIST_RATIFICATION') ? contactsIds : [];
    const ratificationManagerIds = selectedRows.map((row) => row.id);
    try {
      const { data: { createRatificationAction: {
        addedAction,
      } } } = await createAction({
        variables: {
          ratificationContactsIds,
          ratificationManagerIds,
          ratificationActionType: actionType,
          ratificationComment: comment,
          ratificationChannel: channel,
          responded,
        },
      });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          if (ratificationManagerIds.includes(row.id)) {
            return { ...row, actions: [addedAction, ...row.actions] };
          }
          return row;
        }));
      setRatificationInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
      setActionsDrawerInvoice((oldData) => ({
        ...oldData, actions: [addedAction, ...oldData.actions],
      }));
      setSuccessMessage('Creación de gestión exitosa.');
      setSuccessMessageOpen(true);
      setAddActionOpen(false);
    } catch (error) {
      setAddActionOpen(false);
      setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const onAccept = async (reason, evidenceFile, contactId) => {
    setAcceptanceLoading(true);
    const ratificationManagerIds = selectedRows.map((row) => parseInt(row.id, 10));
    const toSendEvidenceFile = ['exception', 'mail'].includes(reason) ? evidenceFile : null;
    const toSendContactId = (reason === 'phone') ? contactId : null;
    try {
      await acceptRatification({
        variables: {
          ratificationManagerIds,
          resolutionType: reason,
          evidenceFile: toSendEvidenceFile,
          evidenceContactId: toSendContactId,
        },
      });
      setAcceptanceLoading(false);
      setAcceptanceOpen(false);
      setSuccessMessage('Factura aceptada con éxito.');
      setSuccessMessageOpen(true);

      ratificationInvoicesRefetch();
    } catch (error) {
      setErrorMessage('Hubo un error al intentar aceptar las facturas. Comunícate con el equipo de desarrollo.');
      setRejectionLoading(false);
      setErrorMessageOpen(true);
    }
  };

  const onCloseAcceptance = () => {
    setAcceptanceOpen(false);
  };

  const onAssignRatificator = async (ratificationManagerId) => {
    try {
      await assignRatificator({ variables: { ratificationManagerId } });
      const updateData = (_oldData) => (
        _oldData.map((manager) => {
          if (manager.id === ratificationManagerId) {
            return { ...manager, ratificator: user.user.firstName };
          }
          return manager;
        }));
      setRatificationInvoices((oldData) => (updateData(oldData)));
      setFilteredInvoices((oldData) => (updateData(oldData)));
    } catch (err) {
      setErrorMessage('Hubo un error al intentar asignarte como ratificador de esta factura. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      invoiceId,
      managerId,
      ratificator,
      folio,
      rutReceiver,
      nameReceiver,
      receiverId,
      executiveAssigned,
      dateIssued,
      certificateUrl,
      paymentDate,
      amount,
      pdf,
      documents,
      rut,
      orderingAssigned,
      name,
      timeInStage,
      actions,
      objectPurchaseOrder,
      siiStatus,
      creditNoteStatus,
      ratificationBlacklistActive,
      receiverRiskBlacklist,
      companyRiskBlacklist,
      lightningPaymentAuthorized,
    } = row;
    const lastAction = actions.length > 0 ? moment(actions.slice(-1)[0].createdAt) : null;

    const {
      label: siiLabel,
      color: siiColor,
    } = siiStatusToProps(dateIssued, creditNoteStatus)[siiStatus];

    return ({
      id,
      rut,
      managerId,
      invoiceId,
      rutReceiver,
      ratificationBlacklistActive,
      certificateUrl,
      folio: {
        component: (
          <div className={classes.row}>
            <Typography>{folio}</Typography>
            {!!orderingAssigned?.length && (
              <OrderingPaymentTooltip
                purchaseOrders={orderingAssigned?.map((assignation) => assignation.purchaseOrder)}
              />
            )}
            {lightningPaymentAuthorized && (
              <Tooltip title="Contabilizadas en Inacap">
                <FlashOn color="primary" />
              </Tooltip>
            )}
          </div>
        ),
        value: folio,
      },
      client: {
        component: (
          <>
            <Tooltip title={name}>
              <div style={{ maxWidth: 150, overflow: 'hidden' }}>
                <Typography variant="body2" noWrap>
                  {name}
                </Typography>
                <Typography variant="body2">
                  {formatRut(rut)}
                </Typography>
              </div>
            </Tooltip>
            {companyRiskBlacklist && <Tooltip title="Blacklist"><Block fontSize="small" className={classes.blacklistIcon} /></Tooltip>}
          </>
        ),
        value: name,
      },
      debtor: {
        component: (
          <DebtorCell
            debtorId={receiverId}
            name={nameReceiver}
            rut={rutReceiver}
            hasRatificationBlacklist={ratificationBlacklistActive}
            hasRiskBlacklist={receiverRiskBlacklist}
          />
        ),
      },
      nameReceiver,
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      dateIssued: {
        component: (

          <Typography align="right" noWrap variant="body2">
            {`${moment(dateIssued).format('DD-MM-YYYY')}`}
          </Typography>

        ),
        value: dateIssued,
      },
      paymentDate: {
        component: (
          <div className={classes.columnDirection}>
            <Typography align="right" variant="body2">{moment(paymentDate).format('DD-MM-YYYY')}</Typography>
            <Typography align="right" variant="caption">
              {getTermTextBetweenDates(moment(dateIssued), moment(paymentDate))}
            </Typography>

          </div>
        ),
        value: paymentDate,
      },
      amount: {
        component: (
          <Typography align="right">${formatMoney(amount)}</Typography>
        ),
        value: amount,
      },
      documents: {
        component: (
          <CustomerDocumentsMenu
            uploadOption={false}
            documents={documents}
            purchaseOrder={objectPurchaseOrder}
            pdf={pdf}
            certificate={certificateUrl}
            companyRut={rut}
          />
        ),
        value: amount,
      },
      contacts: {
        component: (
          <Button variant="contained" color="primary" onClick={() => handleContacts(row)}>
            <Typography variant="body2">Gestionar contactos</Typography>
          </Button>
        ),
      },
      ratificator: {
        component: (
          <UserAvatar
            name={ratificator}
            onAssign={() => onAssignRatificator(id)}
            actionName="ratificación"
          />
        ),
      },
      timeInStage: {
        // eslint-disable-next-line no-underscore-dangle
        component: (
          <Typography align="right">{getTimePassedTextFromTime(timeInStage)}</Typography>
        ),
      },
      actionsData: {
        component: (
          <div className={classes.columnDirection}>
            <IconButton onClick={() => onClickActions(row)}>
              <Badge badgeContent={actions.length} color="primary">
                <Message />
              </Badge>
            </IconButton>
            {lastAction && (
              <Typography variant="caption">{lastAction.format('DD/MM/YYYY HH:mm')}</Typography>
            )}
          </div>
        ),
      },
      integrationStatus: {
        value: siiLabel,
        component: (
          <div className={classes.center}>
            <Typography style={{ color: siiColor }}>{siiLabel}</Typography>
          </div>
        ),
      },
    });
  });
  if (permissionDeniedErrorCatcher(ratificationInvoicesError)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;
  return (
    <div className={classes.container}>
      <OneButtonAlert
        open={errorMessageOpen}
        onClose={() => setErrorMessageOpen(false)}
        message={errorMessage}
        title="Error"
      />
      <OneButtonAlert
        open={successMessageOpen}
        onClose={() => window.location.reload()}
        message={successMessage}
        title="Éxito"
      />
      <RatificationActionsDrawer
        open={actionsDrawerOpen}
        onClose={onCloseActionsDrawer}
        invoiceData={actionsDrawerInvoice}
      />
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}>
          <b>{texts.title}</b>
        </Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            color="primary"
            onClick={() => fetchTrace(
              { variables: { invoiceId: selectedRows.map((row) => row.invoiceId) } },
            )}
            variant="contained"
            disabled={selectedRows.length < 1 || loadingTraces}
          >
            Buscar trazas
          </Button>
          <Button
            className={classes.blueButton}
            onClick={() => setSendMailOpen(true)}
            color="inherit"
            variant="contained"
            disabled={actionButtonDisabled}
          >
            Enviar correo
          </Button>
          <Button
            className={classes.mainColorButton}
            onClick={() => setAddActionOpen(true)}
            color="inherit"
            variant="contained"
            disabled={actionButtonDisabled}
          >
            Gestión manual
          </Button>
          <Button
            className={classes.greenButton}
            onClick={() => setAcceptanceOpen(true)}
            color="inherit"
            variant="contained"
            disabled={selectedRows.length < 1}
          >
            Aceptar
          </Button>
          <Button
            className={classes.redButton}
            onClick={() => setRejectionOpen(true)}
            color="inherit"
            variant="contained"
            disabled={selectedRows.length < 1}
          >
            Rechazar
          </Button>
        </div>
      </div>
      {ratificationInvoices.length > 0
        ? (
          <Grid container>
            <Grid item xs={6} sm={6} className={classes.searchBar}>
              <Filters
                allData={ratificationInvoices}
                setFilteredData={setFilteredInvoices}
                searchFields={['folio', 'nameReceiver', 'rutReceiver', 'name', 'rut']}
                searchPlaceholder="Búsqueda por folio o rut o nombre de cliente o deudor"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.tableContainer}>
              <Table
                headers={headers}
                initialOrderId="timeInStage"
                withSelect
                handleSelected={handleSelectedRows}
                setNewDataOrder={setFilteredInvoices}
                data={filteredInvoices}
                dataToRows={transformDataToComponents}
                clearSelection={acceptanceLoading || rejectionLoading}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography align="center" variant="h5">{texts.empty}</Typography>
        )}
      {addActionOpen && (
        <AddRatificationActions
          open={addActionOpen}
          onClose={() => setAddActionOpen(false)}
          nameReceiver={selectedReceiver.nameReceiver}
          rutReceiver={selectedReceiver.rutReceiver}
          onAccept={onCreateAction}
          ratificationInvoicesRefetch={ratificationInvoicesRefetch}
          ratificationBlacklistReceiver={selectedReceiver.ratificationBlacklistReceiver}
        />
      )}
      {selectedRows.length > 0 && (
        <RejectRatification
          open={rejectionOpen}
          onClose={onCloseRejection}
          onAccept={onReject}
          companiesAndFolios={buildCompaniesAndFolios()}
          loading={rejectionLoading}
        />
      )}
      {selectedRows.length > 0 && (
        <AcceptRatification
          open={acceptanceOpen}
          debtorProfile={false}
          onClose={onCloseAcceptance}
          onAccept={onAccept}
          companiesAndFolios={buildCompaniesAndFolios()}
          loading={acceptanceLoading}
          rutReceiver={selectedReceiver.rutReceiver}
          nameReceiver={selectedReceiver.nameReceiver}
          clients={selectedRows.map((row) => ({
            rut: row.rut,
            socialReason: row.client.value,
          }))}
        />
      )}
      { receiverData && (
        <ManageRatificationContacts
          openDialog={openManageContacts}
          receiverData={receiverData}
          setOpenDialog={setOpenManageContacts}
        />
      )}
      { sendMailOpen && (
        <RatificationSendMail
          open={sendMailOpen}
          setOpen={setSendMailOpen}
          selectedInvoices={selectedRows}
          nameReceiver={selectedReceiver.nameReceiver}
          rutReceiver={selectedReceiver.rutReceiver}
        />
      )}

    </div>
  );
};

Ratification.propTypes = {
  type: PropTypes.oneOf(['priority', 'nonPriority']).isRequired,
};

export default Ratification;
