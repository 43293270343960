import React, { useState } from 'react';
import { Button, Grid, IconButton, LinearProgress, Snackbar, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useStyles } from './styles';
import { GET_LOAN_MANAGERS, DELETE_INVOICE_FROM_ROSTER, ADD_INVOICE_TO_ROSTER } from '../gql/funding';
import { formatRut } from '../helpers/generic';
import { AddNewInvoice } from '../components/funding';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { formatMoney } from '../helpers/money';
import { TwoButtonsAlert } from '../components/dialogs';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'company', label: 'Compañía', align: 'left' },
  { id: 'receiver', label: 'Deudor', align: 'left' },
  { id: 'dates', label: 'Fechas', align: 'left' },
  { id: 'amountWithIva', label: 'Monto factura', align: 'left' },
  { id: 'financedAmount', label: 'Monto financiado', align: 'left' },
  { id: 'priceDifference', label: 'Diferencia de precio', align: 'left' },
  { id: 'transferAmount', label: 'Monto a recibir', align: 'left' },
  { id: 'assignmentStatus', label: 'Estado cesión', align: 'left' },
];

const typeToColor = {
  Invoice: '#FF2B68',
  Confirming: '#2C69FF',
};

const transformDataToRowLikeObjects = (_data) => _data.map((row) => {
  const {
    id,
    document: {
      company: {
        name: companyName,
        rut: companyRut,
      },
      receiver: {
        name: receiverName,
        rut: receiverRut,
      },
      type,
      folio,
      dateIssued,
      dateToPay,
      amountWithIva,
    },
    assignment,
    monthlyRate,
    retentionRate,
    priceDifference,
    transferAmount,
    financedAmount,
    expirationDate: fundExpirationDate,
  } = row;
  return {
    id,
    type,
    companyName,
    companyRut,
    receiverName,
    receiverRut,
    folio,
    dateIssued,
    dateToPay,
    amountWithIva,
    monthlyRate,
    priceDifference,
    retentionRate,
    transferAmount,
    fundExpirationDate,
    financedAmount,
    assignment,
  };
});

const RosterDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { roster } = location.state;
  // useStates
  const [documentId, setDocumentId] = useState();
  const [openAddNewInvoice, setOpenAddNewInvoice] = useState(false);
  const [loanManagers, setLoanManagers] = useState([]);
  const [filteredLoanManagers, setFilteredLoanManagers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [newExpirationDate, setNewExpirationDate] = useState(false);
  const [newRetentionRate, setNewRetentionRate] = useState(false);
  const [newRate, setNewRate] = useState(false);
  const [newType, setNewType] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // Queries
  useQuery(GET_LOAN_MANAGERS, {
    variables: {
      rosterId: roster.id,
    },
    onCompleted: (data) => {
      const transformedData = transformDataToRowLikeObjects(data.getLoanManagers);
      setLoanManagers(transformedData);
      setFilteredLoanManagers(transformedData);
    },
  });
  // Mutations
  const [deleteInvoiceFromRoster] = useMutation(DELETE_INVOICE_FROM_ROSTER, {
    refetchQueries: [
      { query: GET_LOAN_MANAGERS, variables: { rosterId: roster.id } },
    ],
    onCompleted: () => {
      setLoading(false);
      setOpenDeleteDialog(false);
      setSelectedRows([]);
    },
  });
  const [addInvoiceToRoster] = useMutation(ADD_INVOICE_TO_ROSTER, {
    refetchQueries: [
      { query: GET_LOAN_MANAGERS, variables: { rosterId: roster.id } },
    ],
    onCompleted: () => {
      setLoading(false);
      setOpenAddNewInvoice(false);
    },
    onError: (error) => {
      setOpenAddNewInvoice(false);
      setLoading(false);
      setLocalErrorMessage(error.message);
      setOpenErrorDialog(true);
    },
  });
  // Functions
  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };
  const handleDelete = () => {
    setLoading(true);
    const mutationVariables = {
      rosterId: roster.id,
      loanManagerId: selectedRows.map((row) => row.id),
    };
    deleteInvoiceFromRoster({
      variables: mutationVariables,
    });
  };
  const handleAcceptNewInvoice = () => {
    setLoading(true);
    const mutationVariables = {
      documentId,
      rate: newRate,
      retentionRate: newRetentionRate,
      type: newType,
      expirationDate: moment(newExpirationDate).format('YYYY-MM-DD'),
      rosterId: roster.id,
    };
    addInvoiceToRoster({
      variables: mutationVariables,
    });
  };
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      type,
      companyName,
      companyRut,
      receiverName,
      receiverRut,
      folio,
      dateIssued,
      dateToPay,
      amountWithIva,
      monthlyRate,
      priceDifference,
      retentionRate,
      transferAmount,
      fundExpirationDate,
      financedAmount,
      assignment,
    } = row;
    return ({
      id,
      folio: {
        value: folio,
        component: (
          <div style={{ textAlign: 'center' }}>
            <Typography>{folio}</Typography>
            <Typography variant="caption" style={{ color: typeToColor[type] }}>{type}</Typography>
          </div>
        ),
      },
      company: {
        value: companyRut,
        component: (
          <div>
            <Typography>{companyName}</Typography>
            <Typography variant="caption">{formatRut(companyRut)}</Typography>
          </div>
        ),
      },
      receiver: {
        value: receiverRut,
        component: <DebtorCell name={receiverName} rut={receiverRut} />,
      },
      priceDifference: {
        value: priceDifference,
        component: (
          <div>
            <Typography>${formatMoney(priceDifference)}</Typography>
            <Typography variant="caption">Tasa: {monthlyRate}%</Typography>
          </div>
        ),
      },
      transferAmount: {
        value: transferAmount,
        component: (
          <Typography>
            ${formatMoney(transferAmount)}
          </Typography>
        ),
      },
      financedAmount: {
        value: financedAmount,
        component: (
          <div>
            <Typography>${formatMoney(financedAmount)}</Typography>
            <Typography variant="caption">Retención: {retentionRate}%</Typography>
          </div>
        ),
      },
      dates: {
        value: fundExpirationDate,
        component: (
          <Grid container direction="column" style={{ minWidth: 170, color: '#3F3F3F' }}>
            <Grid item>
              <Typography variant="caption" color="inherit">Emisión: {moment(dateIssued).format('DD-MM-YYYY')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="inherit">Pago cliente: {dateToPay ? moment(dateToPay).format('DD-MM-YYYY') : 'No disponible'}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="inherit">Expiración fondo: {moment(fundExpirationDate).format('DD-MM-YYYY')}</Typography>
            </Grid>
          </Grid>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      assignmentStatus: {
        value: assignment ? assignment.status : '',
        component: (
          <Typography>{assignment ? assignment.status : 'No creada'}</Typography>
        ),
      },
    });
  });
  return (
    <Grid className={classes.container}>
      <Grid className={classes.titleAndUpdate}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4"><b>Detalle de Nómina a {roster.fund.socialReason}</b></Typography>
      </Grid>
      <Grid className={classes.headerButtonsContainer}>
        <Button
          className={classes.mainColorButton}
          onClick={() => setOpenAddNewInvoice(true)}
          color="inherit"
          disabled={roster.recesionDate}
          variant="contained"
        >
          Agregar factura
        </Button>
        {selectedRows.length > 0 && (
          <Button
            className={classes.redButton}
            onClick={() => setOpenDeleteDialog(true)}
            color="inherit"
            variant="contained"
          >
            Eliminar de nómina
          </Button>
        )}
      </Grid>
      <Grid container className={classes.container}>
        <Filters
          allData={loanManagers}
          setFilteredData={setFilteredLoanManagers}
          searchFields={['folio', 'receiverRut', 'receiverName', 'companyName', 'companyRut']}
          searchPlaceholder="Búsqueda por folio o rut o nombre de cliente o deudor"
        />
        <Grid item xs={12} sm={12} className={classes.tableContainer}>
          {loanManagers ? (
            <Table
              data={filteredLoanManagers}
              dataToRows={transformDataToComponents}
              setNewDataOrder={setFilteredLoanManagers}
              headers={headers}
              withSelect={!roster.recesionDate}
              handleSelected={handleSelectedRows}
              initialOrderId="folio"
            />
          ) : (
            <LinearProgress />
          )}
        </Grid>
      </Grid>
      <TwoButtonsAlert
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Eliminar de nómina"
        message={`¿Estás seguro de eliminar estas facturas de la nómina a ${roster.fund.socialReason}?`}
        onAccept={handleDelete}
        loading={loading}
      />
      <AddNewInvoice
        open={openAddNewInvoice}
        onClose={() => setOpenAddNewInvoice(false)}
        setOpen={setOpenAddNewInvoice}
        onAccept={handleAcceptNewInvoice}
        setDocumentId={setDocumentId}
        setRate={setNewRate}
        setRetentionRate={setNewRetentionRate}
        setType={setNewType}
        type={newType}
        setExpirationDate={setNewExpirationDate}
        expirationDate={newExpirationDate}
        loading={loading}
      />
      <Snackbar
        open={openErrorDialog}
        autoHideDuration={null}
        onClose={() => setOpenErrorDialog(false)}
        message={localErrorMessage}
      />
    </Grid>
  );
};

export default RosterDetail;
