import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  Grid,
  Button,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useStyles } from './styles';
import { RuleCard } from '../components/commissions';
import { CopyCommission, AddOrEditCommissionRule } from '../components/dialogs';
import { formatRut } from '../helpers/generic';
import {
  GET_COMPANY_COMMISSION_RULES,
  REMOVE_COMMISSION_RULE,
  ADD_COMMISSION_RULE,
  EDIT_COMMISSION_RULE,
} from '../gql/customer';

const ManageClientCommissions = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { name, rut } = state || {};
  const { clientRut } = useParams();
  const { data, loading, error } = useQuery(GET_COMPANY_COMMISSION_RULES, {
    variables: {
      rut: parseInt(clientRut, 10),
    },
  });
  const [removeCommissionRule, {
    loading: removeLoading,
    error: removeError,
  }] = useMutation(REMOVE_COMMISSION_RULE);
  const [addCommissionRule, {
    loading: addLoading,
    error: addError,
  }] = useMutation(ADD_COMMISSION_RULE);
  const [editCommissionRule, {
    loading: editLoading,
    error: editError,
  }] = useMutation(EDIT_COMMISSION_RULE);
  const [rules, setRules] = useState([]);
  const [selectedRule, setSelectedRule] = useState(null);
  const [copyCommission, setCopyCommission] = useState(false);
  const [addOrEditCommissionRuleModal, setAddOrEditCommissionRuleModal] = useState(false);

  useEffect(() => {
    if (data) {
      setRules(data.getCompanyCommissionRules);
    }
  }, [data]);

  const handleEditOpen = (rule) => {
    setSelectedRule(rule);
    setAddOrEditCommissionRuleModal(true);
  };

  const handleEdit = async (ruleData) => {
    const { data: { editCommissionRule: { updatedCommissionRules } } } = await editCommissionRule({
      variables: {
        commissionRuleId: selectedRule.id,
        ...ruleData,
      },
    });
    setRules(updatedCommissionRules);
    setAddOrEditCommissionRuleModal(false);
    setSelectedRule(null);
  };

  const handleDelete = async (id) => {
    const {
      data: {
        removeCommissionRule: {
          updatedCommissionRules,
        },
      },
    } = await removeCommissionRule({
      variables: {
        commissionRuleId: id,
        companyRut: parseInt(clientRut, 10),
      },
    });
    setRules(updatedCommissionRules);
  };

  const handleAdd = async (ruleData) => {
    const { data: { addCommissionRule: { updatedCommissionRules } } } = await addCommissionRule({
      variables: {
        companyRut: parseInt(clientRut, 10),
        ...ruleData,
      },
    });
    setRules(updatedCommissionRules);
    setAddOrEditCommissionRuleModal(false);
  };

  if (loading || removeLoading || addLoading || editLoading) return <LinearProgress />;
  if (error || removeError || addError || editError) return <div>Error</div>;

  return (
    <div className={classes.container}>
      <div className={classes.titleAndUpdate}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4"><b>{state ? `Gestionar comisiones de ${name} ${formatRut(rut)}` : `Gestionar comisiones de ${formatRut(clientRut)}`}</b></Typography>
      </div>
      <Typography className={classes.section} variant="h6">Reglas actuales de comisión</Typography>
      {rules.length > 0
        ? (
          <Grid container spacing={2}>
            {rules.map((rule) => (
              <Grid item xs={12} sm={6} md={3} lg={2} key={rule.id}>
                <RuleCard
                  rule={rule}
                  onDelete={() => handleDelete(rule.id)}
                  onEdit={() => handleEditOpen(rule)}
                />
              </Grid>
            ))}
          </Grid>
        )
        : (
          <>
            <Typography variant="h2" align="center" color="error">Este cliente no tiene reglas de comisión</Typography>
            <Typography variant="h4" align="center">Esto significa que su comisión será $0</Typography>
          </>
        )}
      <Grid container justify="flex-end" spacing={4} style={{ marginTop: '2rem' }}>
        {/* <Grid item xs={12} sm={6} md={3} lg={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setCopyCommission(true)}
          >
            Copiar reglas de otro cliente
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setAddOrEditCommissionRuleModal(true)}
          >
            Agregar nueva regla
          </Button>
        </Grid>
      </Grid>
      <CopyCommission
        open={copyCommission}
        onClose={() => setCopyCommission(false)}
      />
      <AddOrEditCommissionRule
        open={addOrEditCommissionRuleModal}
        onClose={() => setAddOrEditCommissionRuleModal(false)}
        onAccept={selectedRule ? handleEdit : handleAdd}
        ruleToEdit={selectedRule}
      />
    </div>
  );
};

export default ManageClientCommissions;
