import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
  Grid,
  IconButton,
  Badge,
  Button,
  Tooltip,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import {
  Check,
  Close,
  Timelapse as TimelapseIcon,
  ReportProblem as ReportProblemIcon,
  Message,
  Block,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../../views/styles';
import { Table } from '../tables';
import { OneButtonAlert, AddCollectionActions } from '../dialogs';
import { CollectionActionsDrawer } from '../collection';
import { ADD_COLLECTION_ACTION } from '../../gql/collection';
import { transformDataFromNetwork, initStateDrawerInvoice } from '../../helpers/paymentPlans';
import { formatRut } from '../../helpers/generic';
import { formatMoney } from '../../helpers/money';
import { DateWithDaysLeft, Filters } from '../utils';
import DebtorCell from '../shared/DebtorCell';

const useOwnStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  emptyInvoices: {
    marginTop: '2rem',
  },
  greenColorIcon: {
    color: theme.palette.success.main,
    marginRight: '0.5rem',
  },
  redColorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem',
  },
  addActionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  installmentAmount: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const headers = [
  { id: 'client', label: 'Emisor', type: 'rut' },
  { id: 'debtor', label: 'Deudor', type: 'rut' },
  { id: 'executive', label: 'Ejecutivo del emisor', align: 'left' },
  { id: 'folio', label: 'Folio' },
  { id: 'amount', label: 'Monto factura', align: 'center' },
  { id: 'dateIssued', label: 'Fecha de creación' },
  { id: 'nextInstallmentExpirationDate', label: 'Fecha de expiración más cercana', align: 'center' },
  { id: 'defaultRate', label: 'Tasa de mora' },
  { id: 'interestRate', label: 'Tasa de interés' },
  { id: 'status', label: 'Estado' },
  { id: 'actions', label: 'Gestiones', align: 'center' },
];

const TablePaymentPlan = ({ paymentPlanInvoices }) => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const [filteredPaymentPlans, setFilteredPaymentPlans] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [actionsDrawerInvoice, setActionsDrawerInvoice] = useState(initStateDrawerInvoice);
  const [receiver, setReceiver] = useState({ name: null, rut: null });
  const [company, setCompany] = useState({ name: null, rut: null });
  const [actionButtonDisabled, setActionButtonDisabled] = useState(true);
  const [addCollectionAction] = useMutation(ADD_COLLECTION_ACTION);

  const mapperIconStatus = {
    DEBT: <Tooltip title="En mora"><ReportProblemIcon className={classes.redColorIcon} /></Tooltip>,
    ACTIVE: <Tooltip title="Activo"><TimelapseIcon className={classes.greenColorIcon} /></Tooltip>,
  };

  useEffect(() => {
    if (paymentPlanInvoices) {
      const transformed = transformDataFromNetwork(paymentPlanInvoices);
      setFilteredPaymentPlans(transformed);
      setPaymentPlans(transformed);
    }
  }, [paymentPlanInvoices]);

  const onCloseActionsDrawer = () => {
    setActionsDrawerOpen(false);
    setActionsDrawerInvoice(initStateDrawerInvoice);
  };

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const onClickActions = ({
    id,
    folio,
    nameClient,
    actions,
    rutReceiver,
    nameReceiver,
    clientRut,
    firstCollectionManager: {
      dataForCollection,
      id: managerId,
      reminder,
    },
    amountWithIva,
  }) => {
    setActionsDrawerInvoice({
      id,
      folio,
      amount: amountWithIva,
      nameReceiver,
      actions,
      rutReceiver,
      companyRut: clientRut,
      companyName: nameClient,
      dataForCollection,
      reminder,
      managerId,
    });
    setActionsDrawerOpen(true);
  };

  const onSendCollectionAction = async (actionType, comment, collectionContactIds) => {
    const collectionManagerIds = selectedRows.map((row) => row.collectionManager.id);
    try {
      const { data: { addCollectionAction: {
        addedAction,
      } } } = await addCollectionAction({
        variables: {
          collectionManagerIds,
          actionType,
          comment,
          collectionContactIds,
        },
      });
      const updateData = (_oldData) => (
        _oldData.map((row) => {
          const { firstCollectionManager: { id: firstCollectionManagerId } } = row;
          if (collectionManagerIds.includes(firstCollectionManagerId)) {
            const newRow = { ...row, actions: [addedAction, ...row.actions] };
            return newRow;
          }
          return row;
        }));
      setFilteredPaymentPlans((oldData) => updateData(oldData));
      setPaymentPlans((oldData) => updateData(oldData));
      setActionsDrawerInvoice((oldData) => ({
        ...oldData, actions: [addedAction, ...oldData.actions],
      }));
      setAddActionOpen(false);
    } catch (error) {
      setAddActionOpen(false);
      setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const updateAddActionState = (rows) => {
    const notEmpty = rows.length > 0;
    const sameReceiver = notEmpty && rows.every((row) => row.rutReceiver === rows[0].rutReceiver);
    const sameCompany = notEmpty && rows.every((row) => row.clientRut === rows[0].clientRut);
    setActionButtonDisabled(!sameReceiver && !sameCompany);
    if (sameReceiver) setReceiver({ rut: rows[0].rutReceiver, name: rows[0].nameReceiver });
    else setReceiver({ rut: null, name: null });
    if (sameCompany) setCompany({ rut: rows[0].clientRut, name: rows[0].nameClient });
    else setCompany({ rut: null, name: null });
  };

  useEffect(() => {
    updateAddActionState(selectedRows);
  }, [selectedRows]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      clientRut,
      nameClient,
      nameReceiver,
      rutReceiver,
      executiveAssigned,
      folio,
      defaultRate,
      interestRate,
      status,
      dateIssued,
      installments: collapseData,
      actions,
      firstCollectionManager,
      nextInstallmentExpirationDate,
      amountWithIva,
    } = row;
    const actionsNumber = actions ? actions.length : 0;
    return ({
      id,
      nameReceiver,
      rutReceiver,
      clientRut,
      nameClient,
      collectionManager: firstCollectionManager,
      collapseData: {
        collapseTitle: 'Cuotas',
        headers: [
          { id: 'installmentAmountToPay', label: 'Monto cuota' },
          { id: 'expirationDate', label: 'Fecha de vencimiento' },
          { id: 'paid', label: 'Pagado' },
        ],
        components: collapseData.map((rowCollapse) => {
          const {
            installmentAmountToPay,
            expirationDate,
            id: idCollapse,
            debtAmount,
            paymentDate,
            status: statusInstallment,
            totalAmountToPay,
          } = rowCollapse;
          const debt = Boolean(debtAmount);
          const paid = statusInstallment === 'FINISHED';
          return ({
            id: idCollapse,
            selectedRow: debt,
            installmentAmountToPay: {
              component: (
                <div className={classes.installmentAmount}>
                  <Typography>${formatMoney(totalAmountToPay)}</Typography>
                  <Typography variant="caption">Monto cuota original: ${formatMoney(installmentAmountToPay)}</Typography>
                  <Typography variant="caption">Monto mora: ${formatMoney(debtAmount)}</Typography>
                </div>
              ),
              value: installmentAmountToPay,
            },
            expirationDate: {
              component: (<DateWithDaysLeft date={moment(expirationDate)} />),
              value: expirationDate,
            },
            paid: {
              value: paid,
              component: (
                paid ? <Tooltip title={`Fecha de pago: ${moment(paymentDate)}`}><Check className={classes.greenColorIcon} /></Tooltip>
                  : <Close className={classes.redColorIcon} />),
            },
          });
        }),
      },
      amount: {
        value: amountWithIva,
        component: (<Typography>${formatMoney(amountWithIva)}</Typography>),
      },
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography className={classes.textCell}>{folio}</Typography>
          </div>),
        size: 'small',
      },
      client: {
        value: clientRut,
        component: (
          <>
            <Typography>{nameClient}</Typography>
            <Typography>{formatRut(clientRut)}</Typography>
          </>
        ),
        size: 'small',
      },
      debtor: {
        value: rutReceiver,
        component: <DebtorCell name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <div>
            <Typography className={classes.date}>{(moment(dateIssued)).format('DD-MM-YYYY')}</Typography>
          </div>
        ),
        size: 'small',
      },
      interestRate: {
        value: interestRate,
        component: (
          <Typography className={classes.textCell}>{(interestRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <Typography className={classes.textCell}>{(defaultRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      status: {
        value: status,
        component: (
          <Typography className={classes.row}>{mapperIconStatus[status]}</Typography>
        ),
      },
      nextInstallmentExpirationDate: {
        value: moment(nextInstallmentExpirationDate).format('DD-MM-YYYY'),
        component: (<DateWithDaysLeft date={moment(nextInstallmentExpirationDate)} />),
      },
      actions: {
        value: actionsNumber,
        component: (
          <IconButton
            disabled={!actionsNumber}
            onClick={() => onClickActions(row)}
          >
            <Badge badgeContent={actionsNumber} color="primary">
              {actionsNumber ? <Message /> : <Block />}
            </Badge>
          </IconButton>
        ),
      },
    });
  });

  return (
    <>
      <div className={classes.addActionButton}>
        <Button
          className={classes.mainColorButton}
          onClick={() => setAddActionOpen(true)}
          color="inherit"
          variant="contained"
          disabled={actionButtonDisabled}
        >
          Añadir gestión
        </Button>
      </div>
      <Grid container>
        <Grid item xs={6} sm={6} className={classes.searchBar}>
          <Filters
            allData={paymentPlans}
            setFilteredData={setFilteredPaymentPlans}
            searchFields={['folio', 'nameReceiver', 'rutReceiver', 'nameClient', 'clientRut']}
            searchPlaceholder="Búsqueda por folio o rut o nombre de cliente o deudor"
          />
        </Grid>
        { (filteredPaymentPlans.length > 0)
          ? (
            <Grid item xs={12} sm={12} className={classes.tableContainer}>
              <Table
                withSelect
                headers={headers}
                initialOrderId="dateIssued"
                setNewDataOrder={setFilteredPaymentPlans}
                data={filteredPaymentPlans}
                dataToRows={transformDataToComponents}
                handleSelected={handleSelectedRows}
                collapse
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12}>
              <Typography variant="h4" align="center" className={classes.emptyInvoices}>No se encontraron planes de pago </Typography>
            </Grid>
          )}

        {paymentPlanInvoices && (
          <div className={classes.container}>
            <OneButtonAlert
              open={errorMessageOpen}
              onClose={() => setErrorMessageOpen(false)}
              message={errorMessage}
              title="Error"
            />
            <AddCollectionActions
              open={addActionOpen}
              onClose={() => setAddActionOpen(false)}
              nameReceiver={receiver.name}
              rutReceiver={receiver.rut}
              companyRut={company.rut}
              companyName={company.name}
              onAdd={onSendCollectionAction}
            />
            <CollectionActionsDrawer
              open={actionsDrawerOpen}
              onClose={onCloseActionsDrawer}
              invoiceData={actionsDrawerInvoice}
              hideRatificationDrawer
              disableActions
              disableShowData
            />
          </div>
        )}
      </Grid>
    </>
  );
};

TablePaymentPlan.propTypes = {
  paymentPlanInvoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TablePaymentPlan;
