import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActionsDrawerInfo from './ActionsDrawerInfo';
import AddDataForCollection from './AddDataForCollection';
import AddCollectionReminder from './AddCollectionReminder';
import { RatificationHistory } from '../ratification';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '60%',
    height: '100vh',
    overflow: 'hidden',
  },
}));

const CollectionActionsDrawer = ({
  open,
  onClose,
  invoiceData,
  generalActions,
  allActions,
  onSendDataForCollection,
  onSendCollectionReminder,
  disableActions,
  disableShowData,
  hideRatificationDrawer,
  collectionInvoicesRefetch,
}) => {
  const classes = useStyles();
  const [componentToShow, setComponentToShow] = useState('info');
  const [selectedTab, setSelectedTab] = useState(0);

  const openAddDataForCollection = () => {
    setComponentToShow('addDataForCollection');
  };

  const backToInfo = () => {
    setComponentToShow('info');
  };

  const ratificationHistoryActions = generalActions
    ? allActions.ratificationActions : invoiceData.ratificationActions;

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={onClose}
        variant="temporary"
      >
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, value) => setSelectedTab(value)}
        >
          <Tab label="Gestiones de cobranza" />
          { !hideRatificationDrawer && <Tab label="Gestiones de ratificación" /> }
        </Tabs>
        {selectedTab === 0 && (
          <>
            {componentToShow === 'info' && (
              <ActionsDrawerInfo
                invoiceData={invoiceData}
                generalActions={generalActions}
                allActions={allActions}
                openAddDataForCollection={openAddDataForCollection}
                disableActions={disableActions}
                disableShowData={disableShowData}
              />
            )}
            {componentToShow === 'addDataForCollection' && !disableActions && (
              <AddDataForCollection
                backToInfo={backToInfo}
                nameReceiver={invoiceData.nameReceiver}
                rutReceiver={invoiceData.rutReceiver}
                onSendData={onSendDataForCollection}
                managerId={invoiceData.managerId}
                collectionInvoicesRefetch={collectionInvoicesRefetch}
                forReintegration={invoiceData.forReintegration}
              />
            )}
            {componentToShow === 'addCollectionReminder' && !disableActions && (
              <AddCollectionReminder
                backToInfo={backToInfo}
                onSendData={onSendCollectionReminder}
              />
            )}
          </>
        )}
        {selectedTab === 1 && (
          <RatificationHistory
            invoiceId={invoiceData.id}
            actions={ratificationHistoryActions}
            generalActions={generalActions}
          />
        )}
      </Drawer>
    </>
  );
};

CollectionActionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  invoiceData: PropTypes.shape({
    id: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })).isRequired,
      author: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string,
      }).isRequired,
    })).isRequired,
    folio: PropTypes.number,
    nameReceiver: PropTypes.string,
    rutReceiver: PropTypes.number,
    companyName: PropTypes.string,
    companyRut: PropTypes.number,
    managerId: PropTypes.number,
    forReintegration: PropTypes.bool,
    ratificationActions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      ratificationContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })),
    })),
    dataForCollection: PropTypes.shape({
      dateToPay: PropTypes.instanceOf(Date),
      paymentMethod: PropTypes.string,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })).isRequired,
    }).isRequired,
    reminder: PropTypes.shape({
      reminderDate: PropTypes.instanceOf(Date),
      reminderNote: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onSendDataForCollection: PropTypes.func,
  onSendCollectionReminder: PropTypes.func,
  disableActions: PropTypes.bool,
  generalActions: PropTypes.bool,
  allActions: PropTypes.arrayOf(PropTypes.shape({
    ratificationActions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      ratificationContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })),
    })),
    collectionActions: PropTypes.arrayOf(PropTypes.shape({
      actionType: PropTypes.string.isRequired,
      comment: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      collectionContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.lastName,
      })).isRequired,
      author: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string,
      }).isRequired,
    })).isRequired,
  })),
  hideRatificationDrawer: PropTypes.bool,
  disableShowData: PropTypes.bool,
  collectionInvoicesRefetch: PropTypes.func,
};

CollectionActionsDrawer.defaultProps = {
  open: false,
  disableActions: false,
  generalActions: false,
  hideRatificationDrawer: false,
  allActions: {},
  onSendCollectionReminder: () => {},
  onSendDataForCollection: () => {},
  disableShowData: false,
  collectionInvoicesRefetch: () => {},
};

export default CollectionActionsDrawer;
