/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog, DialogTitle, Typography, DialogContent, List, ListItem, DialogActions, Button, TextField, Tooltip, Grid, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Table } from '../tables';
import { formatMoney } from '../../helpers/money';
import { useStyles } from '../../views/styles';
import { GET_UNPAYED_DIFFERENCE_REASONS } from '../../gql/conciliations';

const headerDifferences = [
  { id: 'difference', label: 'Diferencia', align: 'left' },
  { id: 'reason', label: 'Diferencia pagada con', align: 'left' },
];

const ConciliationConfirmation = (props) => {
  const {
    openDialog,
    setOpenDialog,
    warnings,
    conciliationAction,
    invoices,
    setSelectedInvoices,
    totalInvoiceSelected,
    totalMoveSelected,
    differences,
    setDifferences,
    reasons,
    setReasons,
    loadingConciliation,
    setLoadingConciliation,
    dates,
    setDates,
    objectsHeaders,
  } = props;

  const classes = {
    ...useStyles(),
  };
  const [headers, setHeaders] = useState(objectsHeaders);
  const [hasDifference, setHasDifference] = useState(false);

  const {
    data: reasonOptions,
  } = useQuery(GET_UNPAYED_DIFFERENCE_REASONS);

  const [differenceAmount, setDifferenceAmount] = useState(
    totalInvoiceSelected - totalMoveSelected,
  );

  useEffect(() => {
    setDifferenceAmount(totalInvoiceSelected - totalMoveSelected);
    const newHasDiff = Math.abs(totalInvoiceSelected - totalMoveSelected) > 0;
    setHasDifference(newHasDiff);
    if (newHasDiff) {
      setHeaders([...objectsHeaders, ...headerDifferences]);
    } else {
      setHeaders(objectsHeaders);
    }
  }, [totalInvoiceSelected, totalMoveSelected]);

  useEffect(() => {
    setDifferenceAmount(
      totalInvoiceSelected - totalMoveSelected - Object.values(differences).reduce(
        (t, value) => t + value, 0,
      ),
    );
  }, [differences]);

  useEffect(() => {
    if (!openDialog) {
      setDifferences({});
      setReasons({});
    }
  }, [openDialog]);

  const dataToRows = (_data) => _data.map((row) => {
    const handleChangeDifferences = (_e) => {
      setDifferences({
        ...differences,
        [row.collectionManagerId]: Number(_e.target.value),
      });
    };
    const handleChangeReasons = (_e) => {
      setReasons({
        ...reasons,
        [row.collectionManagerId]: _e.target.value,
      });
    };

    const difference = {
      difference: {
        value: differences[row.collectionManagerId],
        component: (
          <TextField
            inputProps={{ style: { textAlign: 'right' } }}
            value={differences[row.collectionManagerId] || ''}
            placeholder="0"
            variant="outlined"
            onChange={handleChangeDifferences}
            type="number"
          />
        ),
      },
    };
    const reasonComponent = {
      reason: {
        value: reasons[row.collectionManagerId],
        component: (
          <Select
            value={reasons[row.collectionManagerId]}
            variant="outlined"
            onChange={handleChangeReasons}
            style={{ minWidth: 165 }}
          >
            <MenuItem value="" disabled>Seleccionar</MenuItem>
            {reasonOptions.getUnpayedDifferenceReasons.map((res) => (
              <MenuItem key={res.label} value={res.value}>{res.label}</MenuItem>
            ))}
          </Select>
        ),
      },
    };
    const { value: dateToPay } = row.dateToPay || {};
    const getValueTimePicker = (collectionManagerId, _dateToPay) => {
      if (!dates[collectionManagerId]) {
        setDates((prevDates) => ({ ...prevDates, [collectionManagerId]: moment(new Date(`${_dateToPay}T00:00:00`)) }));
      }
    };
    return { ...row,
      actualDate: {
        value: dates[row.collectionManagerId] || dateToPay,
        component: (
          <KeyboardDateTimePicker
            variant="dialog"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            name="actualDate"
            inputVariant="outlined"
            ampm={false}
            style={{ width: '15vw' }}
            value={
              dates[row.collectionManagerId] || getValueTimePicker(
                row.collectionManagerId, dateToPay,
              )
            }
            onChange={(date) => {
              setDates({
                ...dates,
                [row.collectionManagerId]: date,
              });
            }}
          />
        ),
      },
      ...difference,
      ...reasonComponent };
  });
  return (
    <Dialog
      open={openDialog}
      aria-labelledby="confirmation-dialog-title"
      maxWidth="md"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">
          {warnings.length ? 'Advertencia' : 'Confirmación de fecha'}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {warnings.length
            && (
            <>
              <Typography>
                Está tratando de conciliar movimientos con documentos que no calzan
                por la(s) siguiente(s) razón(es)
              </Typography>
              <List>
                {warnings.map((warning) => (
                  <ListItem key={warning}>
                    - {warning}
                  </ListItem>
                ))}
              </List>
            </>
            )}

        {invoices.length > 0 && (
        <Table
          data={invoices}
          setNewDataOrder={setSelectedInvoices}
          dataToRows={dataToRows}
          headers={headers}
          pagination={false}
          initialOrderId="folio"
          rowsPerPageInit={invoices.length}
        />
        )}
        {hasDifference && (
          <>
            <Grid container justify="space-between">
              <Grid item>
                <Typography className={classes.confirmationDifference}>
                  Diferencia: ${formatMoney(totalInvoiceSelected - totalMoveSelected)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.confirmationDifference}>
                  {differenceAmount ? `Faltan $${formatMoney(differenceAmount)}` : 'Diferencias saldadas'}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Tooltip title="Ajuste las diferencias" disableHoverListener={differenceAmount <= 0} arrow>
          <span>
            <Button
              variant="contained"
              onClick={() => {
                setLoadingConciliation(true);
                conciliationAction();
              }}
              disabled={differenceAmount > 0 || loadingConciliation}
            >
              {hasDifference ? 'Forzar conciliación' : 'Conciliar'}
              {loadingConciliation && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
            </Button>
          </span>
        </Tooltip>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(!openDialog)}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConciliationConfirmation.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  conciliationAction: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object),
  totalInvoiceSelected: PropTypes.number,
  totalMoveSelected: PropTypes.number,
  setSelectedInvoices: PropTypes.func,
  differences: PropTypes.objectOf(PropTypes.number),
  setDifferences: PropTypes.func,
  reasons: PropTypes.string,
  setReasons: PropTypes.func,
  loadingConciliation: PropTypes.bool,
  setLoadingConciliation: PropTypes.func,
  dates: PropTypes.objectOf(PropTypes.object),
  setDates: PropTypes.func,
  objectsHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ConciliationConfirmation.defaultProps = {
  invoices: [],
  totalInvoiceSelected: 0,
  totalMoveSelected: 0,
  setSelectedInvoices: () => {},
  differences: {},
  setDifferences: () => {},
  reasons: '',
  setReasons: () => {},
  loadingConciliation: false,
  setLoadingConciliation: () => {},
  dates: {},
  setDates: () => {},
};

export default ConciliationConfirmation;
