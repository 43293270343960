import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Avatar, Typography } from '@material-ui/core';
import { ReportProblem } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fundingIcon: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '1.4rem',
    height: '1.4rem',
    marginLeft: '0.5rem',
  },
  reintegrationIcon: {
    verticalAlign: 'text-top',
    color: '#FF9900',
  },
  orderingPaymentIcon: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: '1.4rem',
    height: '1.4rem',
    marginLeft: '0.5rem',
  },
}));

const ReintegrationTooltip = () => {
  const classes = useStyles();
  return (
    <Tooltip arrow title="Factura debe ser reintegrada">
      <ReportProblem className={classes.reintegrationIcon} />
    </Tooltip>
  );
};

const FundingTooltip = ({ socialReason }) => {
  const classes = useStyles();
  return (
    <Tooltip arrow title={`Factura cedida a ${socialReason}`}>
      <Avatar className={classes.fundingIcon}>
        <Typography fontSize="0.7rem">{socialReason[0]}</Typography>
      </Avatar>
    </Tooltip>
  );
};

const OrderingPaymentTooltip = ({ purchaseOrders }) => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      title={`Factura paga la OCs ${purchaseOrders.map((oc) => oc.orderNumber).join(', ')}`}
    >
      <Avatar className={classes.fundingIcon}>
        <Typography style={{ fontSize: '0.7rem' }}>OCs</Typography>
      </Avatar>
    </Tooltip>
  );
};

FundingTooltip.propTypes = {
  socialReason: PropTypes.string.isRequired,
};

OrderingPaymentTooltip.propTypes = {
  purchaseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string,
    }),
  ).isRequired,
};

export { ReintegrationTooltip, FundingTooltip, OrderingPaymentTooltip };
