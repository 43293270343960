import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  Tooltip,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useStyles } from './styles';
import { Table } from '../components/tables';
import { GET_MARKETING_CAMPAIGNS, CANCEL_MARKETING_CAMPAIGN } from '../gql/marketingCampaign';
import { OneButtonAlert } from '../components/dialogs';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const ownStyles = makeStyles(() => ({
  hoverMails: {
    paddingLeft: '4%',
    margin: '2rem 2rem 2rem 0',
  },
}));

const headers = [
  { id: 'sendgridID', label: 'ID Sendgrid', align: 'left' },
  { id: 'creationDate', label: 'Fecha de creación', align: 'left' },
  { id: 'sendingDate', label: 'Fecha de envío', align: 'left' },
  { id: 'state', label: 'Estado', align: 'left' },
  { id: 'fromMail', label: 'Mail origen', align: 'left' },
  { id: 'toMails', label: 'Destinatarios', align: 'left' },
  { id: 'action', label: '', align: 'right' },
];

const MassiveMails = () => {
  const classes = { ...ownStyles(), ...useStyles() };
  const history = useHistory();
  const [mails, setMails] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageCopy, setMessageCopy] = useState('Copiar al portapapeles');
  const { data, loading, error, refetch } = useQuery(GET_MARKETING_CAMPAIGNS);
  const [cancelMarketingCampaign,
    {
      loading: loadingCancel,
      error: errorCancel,
    },
  ] = useMutation(CANCEL_MARKETING_CAMPAIGN);

  useEffect(() => {
    if (data) {
      setMails(data.getMarketingCampaigns);
    }
  }, [data]);

  const handleCancelMarketingCampaign = async (id) => {
    try {
      await cancelMarketingCampaign({ variables: { idMarketingCampaign: id } });
      setOpenAlert(true);
    } catch (_error) {
      // pass
    }
  };
  const transformDataToComponents = (_data) => {
    const outCopyButton = () => {
      setMessageCopy('Copiar al portapapeles');
    };
    const onClickCopy = (toMails) => {
      navigator.clipboard.writeText(toMails.join(', '));
      setMessageCopy('Copiado al portapapeles');
    };

    return _data.map((row) => {
      const {
        id,
        sendgridTemplateId,
        createdAt,
        scheduledMailingDate,
        mailsList,
        style,
        campaignCanceled,
        campaignSent,
        fromMail,
      } = row;
      let state = '';
      if (campaignCanceled) {
        state = 'Cancelado';
      } else if (campaignSent) {
        state = 'Enviado';
      } else {
        state = 'Por enviar';
      }
      return {
        id,
        sendgridID: {
          value: sendgridTemplateId,
          component: <div className={classes.alignRight}>{sendgridTemplateId}</div>,
        },
        creationDate: {
          value: createdAt,
          component: <div className={classes.alignRight}>{moment(createdAt).format('DD-MM-YYYY')}</div>,
        },
        sendingDate: {
          value: scheduledMailingDate,
          component: <div className={classes.alignRight}>{moment(scheduledMailingDate).format('DD-MM-YYYY HH:mm')}</div>,
        },
        state: {
          value: state,
          component: state && <div className={classes.alignRight}>{state}</div>,
        },
        fromMail: {
          value: fromMail,
          component: (
            <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={fromMail}>
              <Typography align="left" noWrap style={{ maxWidth: '10vw' }}>
                {fromMail}
              </Typography>
            </Tooltip>
          ),
        },
        toMails: {
          value: mailsList.length,
          component: (
            <Tooltip
              interactive
              arrow
              placement="bottom-start"
              title={(
                <div>
                  <Typography variant="body1">{mailsList.join(', ')}</Typography>
                  {/* eslint-disable-next-line */}
                  <Tooltip title={messageCopy} onMouseOut={outCopyButton}>
                    <IconButton
                      style={{
                        color: '#FFFFFF',
                        padding: 0,
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }}
                      onClick={() => {
                        onClickCopy(mailsList);
                      }}
                    >
                      <FileCopyIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            >
              <Typography align="left" noWrap style={{ maxWidth: '20vw' }}>
                {mailsList.join(', ')}
              </Typography>
            </Tooltip>
          ),
        },
        action: {
          value: null,
          component: (
            <div className={classes.alignRight}>
              <Button
                onClick={() => { handleCancelMarketingCampaign(id); }}
                variant="contained"
                color="primary"
                className={classes.redButton}
                disabled={state === 'Enviado' || state === 'Cancelado' || loadingCancel}
              >
                Cancelar
              </Button>
            </div>
          ),
        },
        style,
      };
    });
  };
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) return (<Typography variant="h3" style={{ margin: '2rem' }}> Hubo un error para cargar las campañas de marketing. Contáctate con el equipo de desarrollo.</Typography>);
  return (
    <div className={classes.container}>
      {(loading || loadingCancel) && <LinearProgress />}
      <div className={classes.header}>
        <Typography variant="h4">
          <b>Enviar mails masivos</b>
        </Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { history.push('/app/risk/new-mass-email'); }}
          >
            Crear nuevo mail
          </Button>
        </div>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.tableContainer}>
          <Table
            data={mails}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setMails}
            headers={headers}
            initialOrderId="sendingDate"
          />
        </Grid>
      </Grid>
      <OneButtonAlert
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
          refetch();
        }}
        title={errorCancel ? 'Hubo un error al cancelar la campaña de marketing. Contáctate con el equipo de desarrollo.' : 'Campaña de marketing cancelada con éxito'}
      />
    </div>
  );
};

export default MassiveMails;
