import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { GET_DEBTORS } from '../features/debtors/gql/debtors';
import SearchFilter from '../components/inputs/SearchFilter';
import DebtorsTable from '../features/debtors/components/DebtorsTable';
import { ROWS_PER_PAGE } from '../features/debtors/constants/debtors-panel';

const DebtorsPanel = () => {
  const classes = useStyles();
  const [debtors, setDebtors] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('debtorRut');
  const orderByProp = orderDirection === 'asc' ? orderBy : `-${orderBy}`;
  const {
    loading,
    error,
  } = useQuery(
    GET_DEBTORS, {
      variables: {
        first: ROWS_PER_PAGE,
        offset: page * ROWS_PER_PAGE,
        globalFilter: filterValue,
        orderBy: orderByProp,
      },
      onCompleted: (data) => {
        setDebtors(data?.getDebtors?.edges?.map((debtor) => debtor?.node));
        setTotalCount(data?.getDebtors?.totalCount || 0);
      },
    },
  );

  if (error) return `Error!: ${error}`;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4">
          <b>Maestro deudores</b>
        </Typography>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.searchContainer}>
          <Grid item xs={6} sm={6} className={classes.searchBar}>
            <SearchFilter
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              placeholder="Búsqeuda por rut o por nombre del deudor"
            />
          </Grid>
        </Grid>
        {debtors?.length === 0 ? 'No hay deudores disponibles' : (
          <Grid item xs={12} className={classes.tableContainer}>
            <DebtorsTable
              debtors={debtors}
              totalCount={totalCount}
              loading={loading}
              page={page}
              setPage={setPage}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              orderDirection={orderDirection}
              setOrderDirection={setOrderDirection}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default DebtorsPanel;
