import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { getFormFieldError } from '../../helpers/formValidators';
import { GET_CLIENT_RISK } from '../../gql/clientRisk';
import { riskErrorToSpanish } from '../../helpers/translators';

const useStyles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
  },
  buttonContainer: {
    marginTop: '5%',
  },
  button: {
    marginInline: '1%',
  },
}));

const GenericRiskForm = () => {
  const classes = useStyles();
  const [rut, setRut] = useState('');
  const [inputError, setInputError] = useState('');
  const [url, setUrl] = useState('');

  const [getClientRisk, {
    data,
    loading,
    error: queryError,
    refetch,
  }] = useLazyQuery(GET_CLIENT_RISK);

  useEffect(() => {
    if (data) {
      setUrl(data.getCompanyRiskVariables.url);
    }
    if (queryError) {
      setInputError(riskErrorToSpanish(queryError.message));
    }
  }, [data, queryError]);

  const handleSubmit = () => {
    const { isError } = getFormFieldError(rut, 'rut');
    if (isError) {
      setInputError('Formato del rut incorrecto, debe ser 12345678-9');
      return;
    }
    setInputError(false);
    if (data) {
      refetch({ rut });
    } else {
      getClientRisk({ variables: { rut } });
    }
  };

  const onRutChange = (e) => {
    const { value } = e.target;
    setRut(value);
  };

  return (
    <div className={classes.inputContainer}>
      <TextField
        error={Boolean(inputError)}
        multiline
        variant="outlined"
        label="RUT"
        helperText={inputError || 'Ingrese el rut de la empresa con guión'}
        value={rut}
        onChange={onRutChange}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={handleSubmit}
        className={classes.button}
        disabled={loading}
        endIcon={loading ? <CircularProgress size={16} /> : null}
      >
        Consultar
      </Button>
      {url
        ? (
          <Button
            href={url}
            download
            color="primary"
            variant="contained"
          >
            Descargar
          </Button>
        )
        : <></>}
    </div>
  );
};

export default GenericRiskForm;
