import React, { useState } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Publish } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useMutation } from '@apollo/client';
import { useStyles } from './styles';
import CustomInputField from '../components/utils/CustomInputField';
import { CREATE_MARKETING_CAMPAIGN } from '../gql/marketingCampaign';
import ConfirmSendMarketingCampaign from '../components/dialogs/ConfirmSendMarketingCampaign';
import { OneButtonAlert } from '../components/dialogs';
import { getFormFieldError, validName } from '../helpers/formValidators';

const ownStyles = makeStyles(() => ({
  formContainer: {
    paddingLeft: '4%',
    margin: '2rem 2rem 2rem 0',
  },

  input: {
    display: 'none',
  },

  buttonCreateCampaign: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
}));

const MassiveMailsForm = () => {
  const classes = {
    ...useStyles(),
    ...ownStyles(),
  };

  const [idTemplateSendGrid, setIdTemplateSendGrid] = useState('');
  const [fromMail, setFromMail] = useState('');
  const [fromName, setFromName] = useState('');
  const [fileCsv, setFileCsv] = useState({ file: '', name: '' });
  const [newData, setNewData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [mailsCustomInputField, setMailsCustomInputField] = useState([]);
  const [disableButtonSendCampaign, setDisableButtonSendCampaign] = useState(
    true,
  );
  const [openConfirm, setOpenConfirm] = useState(false);
  const [objectErrorFromMail, setObjectErrorFromMail] = useState({
    isError: false,
    message: '',
  });
  const [errorNameFrom, setErrorNameFrom] = useState('');
  // maybe will be useful
  // const [controlRenderCustomInput, setControlRenderCustomInput] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMutation, setErrorMutation] = useState({
    isError: false,
    message:
      'Hubo un error al crear la campaña. Revisa que la hora de envío de la campaña sea posterior a la hora actual. Contáctate con el equipo de desarrollo.',
  });

  const [
    createMarketingCampaign,
    { loading: loadingCreationCampaign },
  ] = useMutation(CREATE_MARKETING_CAMPAIGN, {
    variables: {
      mailList: mailsCustomInputField,
      sendgridTemplateId: idTemplateSendGrid,
      sendingDate: selectedDate,
      fromMail: `${fromName} de Fingo <${fromMail}>`,
    },
  });
  const handleMutationCreateCampaign = async () => {
    try {
      await createMarketingCampaign();
      setOpenConfirm(false);
      /*  the next code, maybe it's useful if we don't want to reload
     the page every time the operation is successful, but first we need to
     solve the problem of the csv file state,
     the input file component does not update its own state */
      /* setIdTemplateSendGrid('');
      setSelectedDate(null);
      setMailsCustomInputField([]);
      setControlRenderCustomInput((prev) => !prev); // to set the state in CustomInputField
      setOpenAlert(true);
      setFileCsv({ file: '', name: '' }); */
      setOpenAlert(true); // the alert has a button that triggers window.location.reload();
    } catch (e) {
      setErrorMutation((prev) => ({ ...prev, isError: true }));
    }
  };
  const handleDateChange = (date) => {
    if (
      date && idTemplateSendGrid !== ''
      && mailsCustomInputField.length !== 0
      && !objectErrorFromMail.isError
      && errorNameFrom === ''
    ) {
      setDisableButtonSendCampaign(false);
    } else {
      setDisableButtonSendCampaign(true);
    }
    setSelectedDate(date);
  };

  const handleDateError = (error) => {
    if (error) {
      setDisableButtonSendCampaign(true);
    }
  };

  const updateMailsCustomInputField = (newMails) => {
    setMailsCustomInputField(newMails);
    if (
      newMails.length !== 0
      && idTemplateSendGrid !== ''
      && selectedDate
      && !objectErrorFromMail.isError
      && errorNameFrom === ''
    ) {
      setDisableButtonSendCampaign(false);
    } else {
      setDisableButtonSendCampaign(true);
    }
  };

  const handleChangeTemplateId = (_event) => {
    _event.persist();
    if (
      _event.target.value !== ''
      && selectedDate
      && mailsCustomInputField.length !== 0
      && !objectErrorFromMail.isError
      && errorNameFrom === ''
    ) {
      setDisableButtonSendCampaign(false);
    } else {
      setDisableButtonSendCampaign(true);
    }
    setIdTemplateSendGrid(_event.target.value);
  };

  const handleChangeFromMail = (_event) => {
    _event.persist();
    const objError = getFormFieldError(_event.target.value, 'email');
    const { isError } = objError;
    setObjectErrorFromMail(objError);
    if (
      selectedDate
      && mailsCustomInputField.length !== 0
      && !isError
      && idTemplateSendGrid !== ''
      && errorNameFrom === ''
    ) {
      setDisableButtonSendCampaign(false);
    } else {
      setDisableButtonSendCampaign(true);
    }
    setFromMail(_event.target.value);
  };

  const handleChangeFromName = (_event) => {
    _event.persist();
    setFromName(_event.target.value);
    if (!validName(_event.target.value)) {
      setErrorNameFrom('Debes ingresar un nombre válido. Con mayúscula al principio y con espacio solo si es nombre compuesto.');
    } else {
      setErrorNameFrom('');
    }
    if (
      selectedDate
      && mailsCustomInputField.length !== 0
      && !objectErrorFromMail.isError
      && idTemplateSendGrid !== ''
      && errorNameFrom === ''
    ) {
      setDisableButtonSendCampaign(false);
    } else {
      setDisableButtonSendCampaign(true);
    }
  };

  const handleFileCsv = async (_event) => {
    // _event.persist();
    setFileCsv({ file: _event.target.files[0], name: _event.target.name });
    const reader = new FileReader();

    function read(input) {
      const csv = input.target.files[0];
      try {
        reader.readAsText(csv);
      } catch (error) {
        // eslint-disable-next-line
      }
    }

    reader.onload = async (e) => {
      // WARNING: if you have to split by \n, do it with (\r\n or \n),
      // and that will works in Mac and Windows.
      // const newArrayCRLF = e.target.result.split('\r\n'); // only works in windows
      // const newArrayLF = e.target.result.split('\n'); // only works in mac
      const newArray = e.target.result.split(/\r\n|\n/); // this works in both
      newArray.pop();
      setNewData([...new Set(newArray)]);
    };
    read(_event);
  };

  return (
    <div className={classes.container}>
      <div style={{ width: '50vw' }}>
        <div className={classes.header}>
          <Typography variant="h4">
            <b>Enviar mails masivos</b>
          </Typography>
          <Typography variant="body2">
            Ingresa el id del template que creaste en Sendgrid, la fecha y hora en
            la cual quieres que se envíe y los mails de los destinatarios. Para
            esto último puedes subir un csv.
          </Typography>
        </div>

        <div className={classes.formContainer}>
          <Typography variant="body2" gutterBottom>
            ID template SendGrid
          </Typography>
          <TextField
            variant="outlined"
            value={idTemplateSendGrid}
            onChange={handleChangeTemplateId}
            style={{ marginBottom: '1rem', width: '100%' }}
          />

          <Typography variant="body2" gutterBottom>
            Fecha y hora de envío
          </Typography>
          <KeyboardDateTimePicker
            variant="dialog"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            inputVariant="outlined"
            disablePast
            ampm={false}
            style={{ width: '100%', marginBottom: '1rem' }}
            value={selectedDate}
            onChange={handleDateChange}
            invalidDateMessage="Fecha inválida"
            minDateMessage="Debes elegir una fecha mayor a la actual"
            maxDateMessage="Debes elegir una fecha menor"
            onError={handleDateError}
          />
          <Typography variant="body2" gutterBottom>
            Nombre de origen
          </Typography>
          {errorNameFrom !== '' && (
            <Typography color="error">{errorNameFrom}</Typography>
          )}
          <TextField
            variant="outlined"
            value={fromName}
            onChange={handleChangeFromName}
            style={{ marginBottom: '1rem', width: '100%' }}
          />
          <Typography variant="body2" gutterBottom>
            Mail de origen
          </Typography>
          {objectErrorFromMail.isError && (
            <Typography color="error">{objectErrorFromMail.message}</Typography>
          )}
          <TextField
            variant="outlined"
            value={fromMail}
            onChange={handleChangeFromMail}
            style={{ marginBottom: '1rem', width: '100%' }}
          />
          <Typography variant="body2" gutterBottom style={{ marginTop: '1rem' }}>
            Listado destinatarios
          </Typography>

          <Typography variant="caption" gutterBottom style={{ display: 'block' }}>
            De: {fromName === '' ? 'Nombre' : fromName} de Fingo {fromMail === '' ? '<mail@fingo.cl>' : `<${fromMail}>`}
          </Typography>

          <CustomInputField
            type="email"
            newData={newData}
            sendStateToParent={updateMailsCustomInputField}
          // controlRender={controlRenderCustomInput}
          />

          <label htmlFor="contained-button-file-csv">
            <input
              name="contained-button-file-csv"
              accept=".csv"
              className={classes.input}
              id="contained-button-file-csv"
              type="file"
              onChange={handleFileCsv}
            />
            <Button
              component="span"
              variant="contained"
              color="primary"
              startIcon={<Publish />}
            >
              Subir CSV
            </Button>
            <Typography variant="caption" style={{ marginLeft: '0.5rem' }}>
              {fileCsv.file && fileCsv.file.name}
            </Typography>
          </label>
          <div className={classes.buttonCreateCampaign}>
            <Button
              component="span"
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenConfirm(true);
              }}
              disabled={disableButtonSendCampaign}
            >
              Crear campaña
            </Button>
          </div>
        </div>
        <ConfirmSendMarketingCampaign
          open={openConfirm}
          handleConfirm={handleMutationCreateCampaign}
          handleClose={() => {
            setOpenConfirm(false);
            setErrorMutation((prev) => ({ ...prev, isError: false }));
          }}
          loading={loadingCreationCampaign}
          error={errorMutation}
          campaign={{
            templateId: idTemplateSendGrid,
            date: selectedDate,
            mails: mailsCustomInputField,
          }}
        />
        <OneButtonAlert
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            window.location.reload();
          }}
          title="Campaña de marketing creada con éxito"
        />
      </div>
    </div>
  );
};

export default MassiveMailsForm;
