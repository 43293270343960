import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { Button, CircularProgress, Grid, IconButton, TextField } from '@material-ui/core';
import { getFormFieldError } from '../../helpers/formValidators';
import { GET_CLIENT_RISK, GET_CLIENT_AND_DEBTORS_RISK } from '../../gql/clientRisk';
import { riskErrorToSpanish } from '../../helpers/translators';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    marginTop: '1rem',
  },
}));

const ClientRiskForm = () => {
  const classes = useStyles();
  const [clientRut, setClientRut] = useState('');
  const [debtorRuts, setDebtorRuts] = useState([]);
  const [inputError, setInputError] = useState('');
  const [url, setUrl] = useState('');

  const [getClientRisk, {
    data: clientRiskData,
    loading: clientRiskLoading,
    error: clientRiskError,
  }] = useLazyQuery(GET_CLIENT_RISK);

  const [getClientAndDebtorsRisk, {
    data: clientAndDebtorsRiskData,
    loading: clientAndDebtorsRiskLoading,
    error: clientAndDebtorsRiskError,
  }] = useLazyQuery(GET_CLIENT_AND_DEBTORS_RISK);

  useEffect(() => {
    if (clientRiskData) {
      setUrl(clientRiskData.getCompanyRiskVariables.url);
    }
    if (clientRiskError) {
      setInputError(riskErrorToSpanish(clientRiskError.message));
    }
  }, [clientRiskData, clientRiskError]);

  useEffect(() => {
    if (clientAndDebtorsRiskData) {
      setUrl(clientAndDebtorsRiskData.getCompanyAndDebtorsRiskVariables.url);
    }
    if (clientAndDebtorsRiskError) {
      setInputError(riskErrorToSpanish(clientAndDebtorsRiskError.message));
    }
  }, [clientAndDebtorsRiskData, clientAndDebtorsRiskError]);

  const cleanInputErrors = () => {
    setInputError(false);
    const newDebtorRuts = [...debtorRuts].map((rutData) => ({
      ...rutData,
      error: '',
    }));
    setDebtorRuts(newDebtorRuts);
  };

  const atLeastOneDebtorRut = () => {
    for (let i = 0; i < debtorRuts.length; i += 1) {
      const debtorRut = debtorRuts[i];
      if (debtorRut.value !== '') return true;
    }
    return false;
  };

  const handleSubmit = () => {
    cleanInputErrors();
    setUrl('');

    if (atLeastOneDebtorRut()) {
      const newDebtorRuts = [...debtorRuts];
      let debtorRutsWithErrors = false;
      debtorRuts.forEach((rutData, i) => {
        const { isError: rutError, message: rutMessage } = getFormFieldError(rutData.value, 'rut');
        if (rutError) {
          newDebtorRuts[i] = { ...newDebtorRuts[i], error: rutMessage };
          debtorRutsWithErrors = true;
        }
      });
      if (debtorRutsWithErrors) {
        setDebtorRuts(newDebtorRuts);
        return;
      }
      getClientAndDebtorsRisk({
        variables: {
          clientRut,
          debtorRuts: debtorRuts.map((rutData) => rutData.value),
        },
      });
    } else {
      const { isError, message } = getFormFieldError(clientRut, 'rut');
      if (isError) {
        setInputError(message);
        return;
      }
      getClientRisk({ variables: { rut: clientRut, access: 'client' } });
    }
  };

  const onRutChange = (e) => {
    const { value } = e.target;
    setClientRut(value);
  };

  const onDebtorRutChange = (e, i) => {
    const { value } = e.target;
    const newDebtorRuts = [...debtorRuts];
    newDebtorRuts[i].value = value;
    setDebtorRuts(newDebtorRuts);
  };

  const handleAddDebtor = () => {
    const newDebtorRuts = [...debtorRuts];
    newDebtorRuts.push({
      value: '',
      error: '',
    });
    setDebtorRuts(newDebtorRuts);
  };

  const handleRemoveDebtor = (i) => {
    const newDebtorRuts = debtorRuts.slice(0, i).concat(debtorRuts.slice(i + 1, debtorRuts.length));
    setDebtorRuts(newDebtorRuts);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          error={Boolean(inputError)}
          multiline
          variant="outlined"
          label="RUT"
          helperText={inputError || 'Ingrese el rut de la empresa con guión'}
          value={clientRut}
          onChange={onRutChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="flex-end" spacing={2}>
          {debtorRuts.map((rutData, i) => {
            const {
              error,
              value,
            } = rutData;
            return (
              <Grid key={value} item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  error={Boolean(error)}
                  multiline
                  variant="outlined"
                  label={`Rut deudor ${i + 1}`}
                  helperText={error || 'Ingrese el rut de la empresa con guión'}
                  value={value}
                  onChange={(e) => onDebtorRutChange(e, i)}
                />
                <IconButton color="error" onClick={() => handleRemoveDebtor(i)}>
                  <Delete />
                </IconButton>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              color="primary"
              variant="text"
              startIcon={<Add />}
              onClick={handleAddDebtor}
            >
              Agregar deudor
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className={classes.button}
          disabled={clientRiskLoading || clientAndDebtorsRiskLoading}
          endIcon={
            clientRiskLoading || clientAndDebtorsRiskLoading
              ? <CircularProgress size={16} />
              : null
          }
        >
          Consultar
        </Button>
      </Grid>
      {url
        ? (
          <Button
            href={url}
            download
            color="primary"
            variant="contained"
          >
            Descargar
          </Button>
        )
        : <></>}
    </Grid>
  );
};

export default ClientRiskForm;
