import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Drawer, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { History } from '@material-ui/icons';
import moment from 'moment';
import { GET_AVAILABLE_FOR_TRANSFER_INVOICES } from '../gql/moneyTransfer';
import { formatRut } from '../helpers/generic';
import GenericAvailableForTransfer from '../components/moneyTransfers';
import CustomerDocumentsMenu from '../components/customers/DocumentsMenu';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { getTimePassedTextFromTime, getTimePassedFromDate } from '../helpers/date';
import { RatificationHistory } from '../components/ratification';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import { OrderingPaymentTooltip, ReintegrationTooltip } from '../components/collection/Tooltips';
import DebtorCell from '../components/shared/DebtorCell';
import BFStatus from '../components/status';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'executiveAssigned', label: 'Ejecutivo asignado', align: 'left' },
  { id: 'amount', label: 'Monto Factura', align: 'right' },
  { id: 'dateToPay', label: 'Fecha de pago', align: 'right' },
  { id: 'amountMinusRetention', label: 'Monto anticipo', align: 'right' },
  { id: 'amountToTransfer', label: 'Monto a girar', align: 'right' },
  { id: 'documents', label: 'Documentos', align: 'right' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
  { id: 'history', label: 'Historial', align: 'right' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
  { id: 'BFstatus', label: 'Estado de BF', align: 'right' },
];

const filter = ['folio', 'nameReceiver', 'rutReceiver', 'nameClient', 'rutClient'];

const INITIAL_DRAWER_DATA = {
  open: false,
  component: null,
};

const AvailableForTransfer = () => {
  const classes = useStyles();
  const { data, refetch, error } = useQuery(GET_AVAILABLE_FOR_TRANSFER_INVOICES);
  const [moneyTransfers, setMoneyTransfers] = useState([]);
  const [filteredMoneyTransfers, setFilteredMoneyTransfers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingReintegrationInvoices, setPendingReintegrationInvoices] = useState([]);
  const [filteredPendingReintegrationInvoices, setFilteredPendingReintegrationInvoices] = useState(
    [],
  );
  const [payingReintegrationInvoices, setPayingReintegrationInvoices] = useState([]);
  const [historyDrawer, setHistoryDrawer] = useState(INITIAL_DRAWER_DATA);
  const title = 'Disponibles para giro';

  const rejectMessage = (
    <Grid container>
      <Typography style={{ marginBottom: '1rem' }}>
        <b>
          Realizando esta acción se dará por rechazado el financiamiento de las siguientes facturas
        </b>
      </Typography>
      <Grid item xs={4}>
        <Typography variant="body2">
          <b>Cliente</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          <b>Folio</b>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          <b>Giro</b>
        </Typography>
      </Grid>
      {selectedRows.map((row) => (
        <Grid container key={row.folio.value}>
          <Grid item xs={4}>
            <Typography variant="body2">{row.client.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">{row.folio.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">${formatMoney(row.amountToTransfer.value)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id: invoiceId,
      moneyTransferId,
      folio,
      company: { amountOfPendingReintegrations },
      pdf,
      client,
      debtor,
      executiveAssigned,
      orderingAssigned,
      dateToPay,
      amounts,
      documents,
      objectPurchaseOrder,
      certificateUrl,
      timeInStage,
      retentionRate,
      siiStatus,
      dateIssued,
      creditNoteStatus,
      companycompliance,
      isFirstOperation,
    } = row;
    const handleHistoryDrawer = () => setHistoryDrawer({
      open: true,
      component: <RatificationHistory invoiceId={invoiceId} />,
    });

    const { label: siiLabel, color: siiColor } = siiStatusToProps(dateIssued, creditNoteStatus)[
      siiStatus
    ];
    return {
      id: moneyTransferId,
      invoiceId,
      clientRut: client.rut,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            {Boolean(amountOfPendingReintegrations) && <ReintegrationTooltip />}
            <Typography>{folio}</Typography>
            {!!orderingAssigned?.length && (
              <OrderingPaymentTooltip
                purchaseOrders={orderingAssigned?.map((assignation) => assignation.purchaseOrder)}
              />
            )}
          </div>
        ),
      },
      client: {
        value: client.name,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={client.name}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp} variant="body2">
                {client.name}
              </Typography>
              <Typography variant="body2">{formatRut(client.rut)}</Typography>
            </div>
          </Tooltip>
        ),
      },
      debtor: {
        value: debtor.name,
        component: <DebtorCell name={debtor.name} rut={debtor.rut} />,
      },
      executiveAssigned: {
        value: null,
        component: (
          <Typography align="left">
            {executiveAssigned
              ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}`
              : 'Sin asignar'}
          </Typography>
        ),
      },
      amount: {
        value: amounts.total,
        component: <Typography align="right">${formatMoney(amounts.total)}</Typography>,
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <Typography align="right">{moment(dateToPay).format('DD-MM-YYYY')}</Typography>
        ),
      },
      amountMinusRetention: {
        value: amounts.minusRetention,
        component: (
          <div className={classes.columnDirection}>
            <Typography align="right">${formatMoney(amounts.minusRetention)}</Typography>
            <Typography align="right" variant="caption">
              Retención {retentionRate.toFixed(2)}%
            </Typography>
          </div>
        ),
      },
      amountToTransfer: {
        value: amounts.toTransfer,
        component: (
          <div className={classes.columnDirection}>
            <Typography align="right">${formatMoney(amounts.toTransfer)}</Typography>
            <Typography align="right" variant="caption">
              Com: ${formatMoney(amounts.commission)}
            </Typography>
            <Typography align="right" variant="caption">
              Exc: ${formatMoney(amounts.negativeSurplus)}
            </Typography>
            <Typography align="right" variant="caption">
              Dif: ${formatMoney(amounts.priceDifference)}
            </Typography>
          </div>
        ),
      },
      documents: {
        component: (
          <CustomerDocumentsMenu
            uploadOption={false}
            purchaseOrder={objectPurchaseOrder}
            pdf={pdf}
            certificate={certificateUrl}
            companyRut={client.rut}
            documents={documents}
          />
        ),
      },
      timeInStage: {
        value: timeInStage,
        component: (
          <Typography align="right">{getTimePassedTextFromTime(timeInStage)}</Typography>
        ),
      },
      history: {
        value: 0,
        component: (
          <div className={classes.receiptContainer}>
            <IconButton onClick={handleHistoryDrawer}>
              <History />
            </IconButton>
          </div>
        ),
      },
      integrationStatus: {
        value: siiLabel,
        component: (
          <div className={classes.center}>
            <Typography style={{ color: siiColor }}>{siiLabel}</Typography>
          </div>
        ),
      },
      BFstatus: {
        component: (
          <div className={classes.center}>
            <BFStatus compliance={companycompliance} isFirstOperation={isFirstOperation} />
          </div>
        ),
      },
    };
  });

  useEffect(() => {
    if (data) {
      const cleanData = data.getAvailableForTransferInvoices
        .filter(
          (item) => item.status === 'WAITING'
          && item.invoice.paymentDiscounts
          && item.invoice.paymentDiscounts.paymentAmountAfterNegativeSurplus !== undefined
          && item.invoice.paymentDiscounts.paymentAmountAfterNegativeSurplus !== null,
        )
        .map((item) => {
          const {
            company,
            nameReceiver,
            rutReceiver,
            orderingAssigned,
            paymentDiscounts: {
              paymentAmountAfterNegativeSurplus,
              negativeSurplusAsociated,
              commissionAsociated,
              interestAsociated,
            },
            amountWithIva,
            offer: { retentionRate },
            lastHistoryStatus: { createdAt },
            integrationStatus,
            objectPurchaseOrder,
            certificateUrl,
          } = item.invoice;
          return {
            ...item.invoice,
            moneyTransferId: item.id,
            nameClient: company.rut,
            rutClient: company.name,
            objectPurchaseOrder,
            orderingAssigned,
            certificateUrl,
            client: company,
            executiveAssigned: company.executiveAssigned,
            debtor: {
              name: nameReceiver,
              rut: rutReceiver,
            },
            companycompliance: company.companycompliance,
            isFirstOperation: item.invoice.isFirstOperation,
            retentionRate,
            amounts: {
              total: amountWithIva,
              minusRetention: ((100 - retentionRate) / 100) * amountWithIva,
              toTransfer: paymentAmountAfterNegativeSurplus,
              commission: commissionAsociated,
              negativeSurplus: negativeSurplusAsociated,
              priceDifference: interestAsociated,
            },
            timeInStage: getTimePassedFromDate(createdAt),
            integrationStatus,
          };
        });
      setMoneyTransfers(cleanData);
      setFilteredMoneyTransfers(cleanData);
      setPendingReintegrationInvoices(data.getPendingReintegrationInvoices);
    }
  }, [data]);

  const handleSelectedRows = (_selectedRows) => {
    const selectedCompanies = _selectedRows.reduce((acc, item) => {
      if (acc.includes(item.clientRut)) {
        return [...acc];
      }
      return [...acc, item.clientRut];
    }, []);
    const filteredPendingInvoices = pendingReintegrationInvoices.filter(
      (invoice) => selectedCompanies.includes(invoice.company.rut),
    );
    setFilteredPendingReintegrationInvoices(filteredPendingInvoices);
    setPayingReintegrationInvoices(selectedCompanies.length === 1 ? _selectedRows : []);
    setSelectedRows(_selectedRows);
  };

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <GenericAvailableForTransfer
        title={title}
        refetch={refetch}
        headers={headers}
        transformDataToComponents={transformDataToComponents}
        setFilteredMoneyTransfers={setFilteredMoneyTransfers}
        filteredMoneyTransfers={filteredMoneyTransfers}
        moneyTransfers={moneyTransfers}
        rejectMessage={rejectMessage}
        selectedRows={selectedRows}
        setSelectedRows={handleSelectedRows}
        filter={filter}
        pendingReintegrationInvoices={filteredPendingReintegrationInvoices}
        payingReintegrationInvoices={payingReintegrationInvoices}
      />
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={historyDrawer.open}
        onClose={() => setHistoryDrawer(INITIAL_DRAWER_DATA)}
        variant="temporary"
      >
        <div>{historyDrawer.component}</div>
      </Drawer>
    </div>
  );
};

export default AvailableForTransfer;
