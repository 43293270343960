import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import { Filters } from '../components/utils';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import {
  GET_PROMPT_PAYMENT_DEAL,
} from '../gql/confirming';
import {
  CREATE_WHITELIST, EDIT_WHITELIST,
} from '../gql/whitelist';

const headers = [
  { id: 'id', label: 'Id', align: 'left' },
  { id: 'promptPaymentCompanyName', label: 'Empresa Pronto Pago', align: 'left' },
  { id: 'promptPaymentCompanyRut', label: 'Rut Empresa Pronto Pago', align: 'left' },
  { id: 'providerName', label: 'Proveedor', align: 'right' },
  { id: 'providerRut', label: 'Rut proveedor', align: 'right' },
  { id: 'monthlyRate', label: 'Tasa mensual', align: 'right' },
  { id: 'defaultRate', label: 'Tasa mora', align: 'right' },
  { id: 'retentionRate', label: 'Tasa retención', align: 'right' },
  { id: 'goButton', label: '', align: 'right' },
];

const PromptPaymentDeal = () => {
  const classes = useStyles();
  const [createWhitelist] = useMutation(CREATE_WHITELIST);
  const [editWhitelist] = useMutation(EDIT_WHITELIST);
  const [deals, setDeals] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [rates, setRates] = useState([]);

  const { data, refetch } = useQuery(
    GET_PROMPT_PAYMENT_DEAL, { notifyOnNetworkStatusChange: true },
  );

  const transformDataToComponents = (_data) => _data.map((row) => {
    const sendNewWhitelist = async (identifier) => {
      const rate = rates[identifier];
      if (!rate.identifier) {
        await createWhitelist({ variables: {
          ...rate,
        } });
      } else {
        await editWhitelist({
          variables: {
            ...rate,
          },
        });
      }
    };
    const {
      id,
      promptPaymentCompany: {
        masterEntity: {
          name: promptPaymentCompanyName,
          rut: promptPaymentCompanyRut,
        },
      },
      providerCompany: {
        masterEntity: {
          name: providerName,
          rut: providerRut,
        },
      },
      whitelist,
    } = row;
    const changeRates = (identifier, event, key) => {
      const newRates = { ...rates };
      newRates[identifier][key] = event.target.value;
      setRates(newRates);
    };
    return ({
      id: {
        value: id,
        component: (
          <Typography className={classes.oneLineClamp}>
            {id}
          </Typography>
        ),
      },
      promptPaymentCompanyName: {
        value: promptPaymentCompanyName,
        component: (
          <Typography className={classes.oneLineClamp}>
            {promptPaymentCompanyName}
          </Typography>
        ),
      },
      promptPaymentCompanyRut: {
        value: promptPaymentCompanyRut,
        component: (
          <Typography>
            {formatRut(promptPaymentCompanyRut)}
          </Typography>
        ),
      },
      providerName: {
        value: providerName,
        component: (
          <Typography className={classes.oneLineClamp}>
            {providerName}
          </Typography>
        ),
      },
      providerRut: {
        value: providerRut,
        component: (
          <Typography>
            {formatRut(providerRut)}
          </Typography>
        ),
      },
      monthlyRate: {
        value: rates.monthlyRate,
        component: (
          <TextField
            style={{ maxWidth: 80 }}
            type="number"
            step=".01"
            value={rates[id].monthlyRate}
            name="fundingDays"
            variant="outlined"
            onChange={(event) => { changeRates(id, event, 'monthlyRate'); }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        ),
      },
      defaultRate: {
        value: rates.defaultRate,
        component: (
          <TextField
            style={{ maxWidth: 80 }}
            type="number"
            step=".01"
            value={rates[id].defaultRate}
            name="fundingDays"
            variant="outlined"
            onChange={(event) => { changeRates(id, event, 'defaultRate'); }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        ),
      },
      retentionRate: {
        value: rates.retentionRate,
        component: (
          <TextField
            style={{ maxWidth: 80 }}
            type="number"
            step=".01"
            value={rates[id].retentionRate}
            name="fundingDays"
            variant="outlined"
            onChange={(event) => { changeRates(id, event, 'retentionRate'); }}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        ),
      },
      goButton: {
        value: whitelist && whitelist.retentionRate,
        component: (
          <Button
            variant="contained"
            color="primary"
            onClick={() => { sendNewWhitelist(id); }}
          >
            Cambiar tasa
          </Button>
        ),
      },
    });
  });

  useEffect(() => {
    if (data) {
      const newDeals = data.getPromptPaymentDeal;
      setDeals(newDeals);
      setFilteredDeals(newDeals);
      const newRates = {};
      newDeals.forEach((deal) => {
        const { whitelist } = deal;
        newRates[deal.id] = {
          identifier: whitelist ? whitelist.id : false,
          monthlyRate: deal.whitelist ? deal.whitelist.monthlyRate : '',
          defaultRate: deal.whitelist ? deal.whitelist.defaultRate : '',
          retentionRate: deal.whitelist ? deal.whitelist.retentionRate : '',
          clientRut: deal.providerCompany.masterEntity.rut,
          debtorRut: deal.promptPaymentCompany.masterEntity.rut,
        };
      });
      setRates(newRates);
    }
  }, [data]);
  return (
    <Grid className={classes.container}>
      <Grid className={classes.headerContainer}>
        <Grid className={classes.titleAndUpdate}>
          <Typography variant="h4"><b>Empresas de Pronto Pago</b></Typography>
          <Button
            className={classes.updateButton}
            color="primary"
            variant="contained"
            onClick={() => refetch()}
            endIcon={<Cached />}
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} className={classes.searchBar}>
          <Filters
            allData={deals}
            setFilteredData={setFilteredDeals}
            searchFields={[
              'promptPaymentCompany.masterEntity.name',
              'promptPaymentCompany.masterEntity.rut',
              'providerCompany.masterEntity.name',
              'providerCompany.masterEntity.rut',
            ]}
            searchPlaceholder="Búsqueda por folio o rut o nombre de cliente o deudor"
          />
        </Grid>
        <Grid className={classes.tableContainer}>
          <Table
            headers={headers}
            initialOrderId="id"
            setNewDataOrder={setFilteredDeals}
            data={filteredDeals}
            dataToRows={transformDataToComponents}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromptPaymentDeal;
