import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

const selectOptions = [
  { value: 'bad emission', label: 'Factura mal emitida' },
  { value: 'invoice payed', label: 'Servicios o productos pagados por el deudor' },
  { value: 'not requested', label: 'Servicios o productos no solicitados' },
  { value: 'partially delivered', label: 'Servicios o productos entregados parcialmente' },
  { value: 'not delivered', label: 'Ningún servicio o producto entregado' },
  { value: 'no authorization', label: 'No existe autorización por parte del deudor para factorizar' },
  { value: 'other', label: 'Otro' },
];

const RejectRatification = ({ open, onClose, onAccept, companiesAndFolios, loading }) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [comment, setComment] = useState('');

  const handleSelectChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleTextChange = (e) => {
    setComment(e.target.value);
  };

  const getInvoicesMessage = () => Object.keys(companiesAndFolios).reduce((acc, company) => {
    const folios = companiesAndFolios[company];
    const foliosString = folios.join(', ');
    const companyString = `Folio ${foliosString} del cliente ${company}\n`;
    return `${acc}${companyString}`;
  }, '');

  const getFullMessage = () => {
    const invoicesMessage = getInvoicesMessage();
    return `${invoicesMessage}Por favor indica el motivo del rechazo:`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"><b>¿Rechazar facturas?</b></DialogTitle>
      <DialogContent>
        <Typography gutterBottom style={{ whiteSpace: 'pre-line' }}>{getFullMessage()}</Typography>
        <RadioGroup
          aria-label="rejection-reason"
          value={selectedReason}
          onChange={handleSelectChange}
        >
          {selectOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        {(selectedReason === 'other') && (
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="Por favor, indica el motivo"
            onChange={handleTextChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button
            onClick={onClose}
            color="primary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => onAccept(selectedReason, comment)}
            color="primary"
            disabled={loading}
            startIcon={loading ? (<CircularProgress size={16} />) : null}
          >
            Confirmar
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

RejectRatification.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  companiesAndFolios: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RejectRatification;
