import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RatificationAction from './RatificationAction';
import { formatMoney } from '../../helpers/money';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: '60%',
    height: '100vh',
    overflow: 'hidden',
  },
  list: {
    paddingTop: 20,
    height: '100%',
  },
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    '&&::-webkit-scrollbar': { /* Chrome, Safari, Opera */
      display: 'none',
    },
    scrollbarWidth: 'none', /* Firefox */
    msOverflowStyle: 'none', /* IE */
  },
  actionsContainer: {
    height: '100%',
    alignItems: 'stretch',
  },
  finalItem: {
    minHeight: 100,
  },
}));

const RatificationActionsDrawer = ({
  open,
  onClose,
  invoiceData: {
    actions,
    folio,
    nameReceiver,
    amount,
  },
}) => {
  const classes = useStyles();
  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={onClose}
        variant="temporary"
      >
        <List className={classes.list}>
          <ListItem>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              <b>Deudor: </b> {`${nameReceiver}\n`}
              <b>Factura: </b> {`${folio}\n`}
              <b>Monto: </b> {`$${formatMoney(amount)}`}
            </Typography>
          </ListItem>
          <Divider />
          <ListItem className={clsx(classes.baseContainer, classes.actionsContainer)}>
            {actions.length > 0
              ? actions.map((action) => (
                <RatificationAction
                  key={`${action.createdAt}-${action.comment}-${action.actionType}`}
                  action={action}
                />
              ))
              : (
                <Typography>Aún no se realizan gestiones para esta factura</Typography>
              )}
            <Box className={classes.finalItem} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

RatificationActionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  invoiceData: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    actions: PropTypes.arrayOf(PropTypes.object),
    folio: PropTypes.string,
    nameReceiver: PropTypes.string,
    rutReceiver: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
};

RatificationActionsDrawer.defaultProps = {
  open: false,
};

export default RatificationActionsDrawer;
