import {
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { WarningRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import CompanyRestrictions from './CompanyRestrictions';

const useStyles = makeStyles({
  warning: {
    fontSize: 80,
  },
  mt: {
    marginTop: '10px',
  },
});

const AllRestrictionsWarning = ({ clients, align }) => {
  const classes = useStyles();
  const [hasRestrictions, sethasRestrictions] = useState({});
  const [showWarning, setShowWarning] = useState('');
  useEffect(() => {
    if (Object.keys(hasRestrictions).length > 0) {
      setShowWarning(false);
      clients.forEach((client) => {
        if (hasRestrictions[client.rut] === true) {
          setShowWarning(true);
        }
      });
    }
  }, [hasRestrictions]);

  return (
    <Grid item xs={12} align={align || 'center'}>
      {((showWarning === true)
      && (
        <>
          <WarningRounded color="primary" className={classes.warning} />
          <Typography gutterBottom>
            <b>Hay restricciones pendientes</b>
          </Typography>
        </>
      )
      ) || ((showWarning === false) && (
      <Typography gutterBottom>
        <b>No hay restricciones pendientes</b>
      </Typography>
      ))}
      {clients.map((client) => (
        <CompanyRestrictions
          clientRut={client.rut}
          companyId={client.id}
          clientSocialReason={client.socialReason}
          sethasRestrictions={sethasRestrictions}
          hasRestrictions={hasRestrictions}
        />
      ))}
    </Grid>
  );
};

AllRestrictionsWarning.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  align: PropTypes.string,
  clients: PropTypes.arrayOf(PropTypes.shape({
    rut: PropTypes.number,
    socialReason: PropTypes.string,
  })).isRequired,
};

AllRestrictionsWarning.defaultProps = {
  align: null,
};

export default AllRestrictionsWarning;
