import { gql } from '@apollo/client';

export const GET_WHITELISTS = gql`
  query GetWhitelists {
    getRateWhitelists {
      id
      emitter {
        rut
        name
      }
      receiver {
        id
        rut
        name
      }
      monthlyRate
      defaultRate
      retentionRate
      active
      status
    }
  }
`;

// ! Creo que no se usa
export const CREATE_WHITELIST = gql`
  mutation CreateOrEditWhitelist(
    $clientRut: String,
    $debtorRut: String,
    $monthlyRate: Float!,
    $defaultRate: Float!,
    $retentionRate: Float!,
  ) {
    createRateWhitelist(
      clientRut: $clientRut,
      debtorRut: $debtorRut,
      monthlyRate: $monthlyRate,
      defaultRate: $defaultRate,
      retentionRate: $retentionRate,
    ) {
      success
      createdWhitelist {
        id
        emitter {
          rut
          name
        }
        receiver {
          id
          rut
          name
        }
        monthlyRate
        defaultRate
        retentionRate
        active
      }
    }
  }
`;

export const SWITCH_WHITELIST_RULE_STATE = gql`
  mutation SwitchWhitelistRuleState($identifier: Int!) {
    switchWhitelistRuleState(identifier: $identifier) {
      updatedWhitelist {
        id
        emitter {
          rut
          name
        }
        receiver {
          id
          rut
          name
        }
        monthlyRate
        defaultRate
        retentionRate
        active
      }
    }
  }
`;

export const EDIT_WHITELIST = gql`
  mutation EditWhitelist(
    $identifier: Int!,
    $monthlyRate: Float!,
    $defaultRate: Float!,
    $retentionRate: Float!,
  ) {
    editRateWhitelist(
      identifier: $identifier,
      monthlyRate: $monthlyRate,
      defaultRate: $defaultRate,
      retentionRate: $retentionRate,
    ) {
      success
      editedWhitelist {
        id
        emitter {
          rut
          name
        }
        receiver {
          id
          rut
          name
        }
        monthlyRate
        defaultRate
        retentionRate
        active
        status
      }
    }
  }
`;
