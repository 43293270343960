import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AttachFile } from '@material-ui/icons';
import { Table } from '../tables';
import { CREATE_NEGOTIATION } from '../../gql/negotiation';
import DebtorCell from '../shared/DebtorCell';

const useStyles = makeStyles({
  sendButtonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '1rem',
    width: '100%',
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignCheckbox: {
    margintop: '0px',
    paddingtop: '0px',
  },
});

const headers = [
  { id: 'folio', label: 'Folio', isSearchable: false, hideSortIcon: true },
  { id: 'nameReceiver', label: 'Deudor', type: 'rut', isSearchable: false, hideSortIcon: true },
  {
    id: 'monthlyRate',
    label: 'Tasa mensual',
    type: 'text',
    isSearchable: false,
    hideSortIcon: true,
  },
  {
    id: 'defaultRate',
    label: 'Mora mensual',
    type: 'text',
    isSearchable: false,
    hideSortIcon: true,
  },
  {
    id: 'retentionRate',
    label: 'Retención',
    type: 'text',
    isSearchable: false,
    hideSortIcon: true,
  },
  {
    id: 'competitorRate',
    label: 'Tasa competencia',
    type: 'text',
    isSearchable: false,
    hideSortIcon: true,
  },
  {
    id: 'competitorName',
    label: 'Nombre competencia',
    type: 'text',
    isSearchable: false,
    hideSortIcon: true,
  },
  {
    id: 'simulationFile',
    label: 'Subir simulación',
    type: 'file',
    isSearchable: false,
    hideSortIcon: true,
  },
];

const CreateNegotiation = ({
  open,
  onClose,
  onSubmit,
  negotiationData,
  companyId,
  negotiationType,
}) => {
  const classes = useStyles();
  const [negotiationComment, setNegotiationComment] = useState('');
  const [negotiationCheckboxes, setNegotiationCheckboxes] = useState({});
  const [validationMessage, setValidationMessage] = useState('');

  const [createNegotiationMutation, { loading }] = useMutation(CREATE_NEGOTIATION, {
    onError: (e) => {
      setValidationMessage(e.message);
    },
    onCompleted: () => {
      setNegotiationComment('');
      setValidationMessage('');
      onSubmit();
    },
  });

  useEffect(() => {
    if (negotiationData.length > 0) {
      setNegotiationCheckboxes(
        negotiationData.reduce(
          (acc, val) => ({
            ...acc,
            [val.id]: {
              monthlyRate: false,
              defaultRate: false,
              retentionRate: false,
              competitorRate: '',
              competitorName: '',
              simulationFile: null,
            },
          }),
          {},
        ),
      );
    }
  }, [negotiationData]);

  const abstractHandleCheck = (id, rate) => () => {
    const checks = { ...negotiationCheckboxes };
    checks[id][rate] = !negotiationCheckboxes[id][rate];
    setNegotiationCheckboxes(checks);
  };

  const handleRequestNegotiation = () => {
    setValidationMessage('');
    const checkValidation = Object.entries(negotiationCheckboxes).reduce(
      (acc, [id, checkbox]) => ({
        ...acc,
        [id]: checkbox.monthlyRate || checkbox.defaultRate || checkbox.retentionRate,
      }),
      {},
    );
    if (Object.values(checkValidation).includes(false)) {
      setValidationMessage('Debe seleccionar al menos una casilla en cada solicitud');
      return;
    }
    const competitorValidation = Object.entries(negotiationCheckboxes).reduce(
      (acc, [id, checkbox]) => ({
        ...acc,
        [id]: checkbox.competitorRate && checkbox.competitorName,
      }),
      {},
    );
    if (Object.values(competitorValidation).includes('')) {
      setValidationMessage('Tasa y nombre de competencia son obligatorios');
      return;
    }
    if (negotiationComment.length < 1) {
      setValidationMessage('Debe ingresar un comentario');
      return;
    }
    const negotiationRequests = Object.entries(negotiationCheckboxes).map(([id, checkboxes]) => ({
      ...checkboxes,
      negotiationObjectId: id,
    }));
    createNegotiationMutation({
      variables: {
        companyId,
        negotiationType,
        negotiationRequests,
        negotiationComment,
      },
    });
  };
  const setNegotiationAttribute = (id, columnName, value) => {
    setNegotiationCheckboxes({
      ...negotiationCheckboxes,
      [id]: { ...negotiationCheckboxes[id], [columnName]: value },
    });
  };
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      rutReceiver,
      nameReceiver,
      receiver: { id: receiverId },
      monthlyRate,
      defaultRate,
      retentionRate,
    } = row;
    const { competitorRate, competitorName, simulationFile } = negotiationCheckboxes[id];
    return {
      id,
      folio: {
        value: folio,
        component: <Typography>{folio}</Typography>,
        size: 'small',
      },
      nameReceiver: {
        value: nameReceiver,
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{monthlyRate.toFixed(2)}%</Typography>
            <Checkbox
              checked={negotiationCheckboxes[row.id].monthlyRate || false}
              onChange={abstractHandleCheck(row.id, 'monthlyRate')}
              className={classes.alignCheckbox}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{defaultRate.toFixed(2)}%</Typography>
            <Checkbox
              checked={negotiationCheckboxes[row.id].defaultRate || false}
              onChange={abstractHandleCheck(row.id, 'defaultRate')}
              className={classes.alignCheckbox}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        ),
        size: 'small',
      },
      retentionRate: {
        value: retentionRate,
        component: (
          <div className={classes.centerDiv}>
            <Typography>{retentionRate.toFixed(2)}%</Typography>
            <Checkbox
              checked={negotiationCheckboxes[row.id].retentionRate || false}
              onChange={abstractHandleCheck(row.id, 'retentionRate')}
              className={classes.alignCheckbox}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        ),
        size: 'small',
      },
      competitorRate: {
        value: competitorRate,
        component: (
          <div className={classes.centerDiv}>
            <TextField
              type="number"
              size="small"
              onChange={(e) => setNegotiationAttribute(row.id, 'competitorRate', e.target.value)}
              value={competitorRate}
            />
            <Typography>%</Typography>
          </div>
        ),
        size: 'small',
      },
      competitorName: {
        value: competitorName,
        component: (
          <div className={classes.centerDiv}>
            <TextField
              margin="normal"
              variant="outlined"
              onChange={(e) => setNegotiationAttribute(row.id, 'competitorName', e.target.value)}
              value={competitorName}
            />
          </div>
        ),
        size: 'small',
      },
      simulationFile: {
        value: simulationFile,
        component: (
          <Button component="label" size="small">
            <AttachFile />
            {simulationFile?.name || 'Adjuntar archivo'}
            <input
              type="file"
              onChange={(e) => {
                e.preventDefault();
                setNegotiationAttribute(row.id, 'simulationFile', e.target.files[0]);
              }}
              hidden
            />
          </Button>
        ),
        size: 'small',
      },
    };
  });
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <b>Solicitud de negociación</b>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Marque las casillas respectivas para solicitar la negociación de tasa
        </DialogContentText>
        <Grid container direction="column" spacing={2}>
          <Table
            headers={headers}
            initialOrderId="folio"
            setNewDataOrder={() => {}}
            data={negotiationData}
            dataToRows={transformDataToComponents}
            pagination={false}
          />
          <Grid item>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Se solicita modificar estas tasas por las siguientes razones..."
              label="Comentarios"
              multiline
              value={negotiationComment}
              onChange={(e) => {
                setNegotiationComment(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.sendButtonContainer}>
          <Button
            onClick={() => {
              setNegotiationComment('');
              setValidationMessage('');
              onClose();
            }}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Typography color="error">{validationMessage}</Typography>
          <Button
            onClick={handleRequestNegotiation}
            color="primary"
            variant="outlined"
            startIcon={loading ? <CircularProgress size={16} /> : null}
          >
            Solicitar negociación
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CreateNegotiation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  negotiationData: PropTypes.shape([]).isRequired,
  companyId: PropTypes.string.isRequired,
  negotiationType: PropTypes.string.isRequired,
};

export default CreateNegotiation;
