import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  Grid,
  Select,
  Tooltip,
  Badge,
  LinearProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArrowBackIos, Print as PrintIcon, Message } from '@material-ui/icons';
import { useStyles } from './styles';
import { Filters, DateWithDaysLeft } from '../components/utils';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { getTimePassedFromDate, getTimePassedTextFromTime } from '../helpers/date';
import { TablePaginationBackend } from '../components/tables';
import {
  DEBTOR_PAYMENT_BEHAVIOUR_ENUM,
  SET_DEBTOR_PAYMENT_BEHAVIOUR,
  ADD_DATA_FOR_COLLECTION,
  ADD_COLLECTION_ACTION,
} from '../gql/collection';
import { GET_DEBTOR_INVOICES, GET_DEBTOR, GET_INVOICES_MANAGERS } from '../features/debtors/gql/debtors';
import {
  REJECT_RATIFICATION,
  CREATE_RATIFICATION_ACTION,
  ACCEPT_RATIFICATION,
} from '../gql/ratification';
import useFilteredQuery from '../helpers/useFilteredQuery';
import { CollectionActionsDrawer, CollectionSendMail } from '../components/collection';
import { RatificationSendMail } from '../components/ratification';
import siiStatusToProps from '../components/constants/integrationStatusInvalidOptions';
import ContactsTable from '../components/dialogs/ContactsTable';
import {
  OneButtonAlert,
  AddRatificationActions,
  AddCollectionActions,
  RejectRatification,
  AcceptRatification,
} from '../components/dialogs';

const ROW_NUMBERS = 50;

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'status', label: 'Estado', align: 'left' },
  { id: 'purchaseOrder', label: 'OC', align: 'left' },
  { id: 'client', label: 'Emisor', align: 'left' },
  { id: 'dateIssued', label: 'Fecha emisión', align: 'left' },
  { id: 'dateExpiration', label: 'Fecha vencimiento', align: 'left' },
  { id: 'amount', label: 'Monto', align: 'right' },
  { id: 'debt', label: 'Monto mora', align: 'left' },
  { id: 'integrationStatus', label: 'Estado en SII', align: 'right' },
  { id: 'actionsData', label: 'Gestiones', align: 'center' },
];

const initialObjectOrderState = {
  property: 'folio',
  order: 'desc',
};

const initialActionsDrawerInvoiceState = {
  id: '',
  name: '',
  folio: 0,
  amount: 0,
  nameReceiver: '',
  actions: [],
  rutReceiver: 0,
  companyRut: 0,
  companyName: '',
  managerId: null,
  dataForCollection: {
    dateToPay: null,
    paymentMethod: null,
    collectionContacts: [],
  },
  reminder: {
    reminderDate: null,
    reminderNote: null,
  },
};

const compareByDate = (act1, act2) => {
  if (moment(act1?.createdAt) > moment(act2?.createdAt)) {
    return -1;
  }
  if (moment(act1?.createdAt) < moment(act2?.createdAt)) {
    return 1;
  }
  return 0;
};

const DebtorProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { debtorId, rut, name, siiSalesSection, ratificator, collector } = location.state;
  const [contactsOpen, setContactsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [collectionActionButtonDisabled, setCollectionActionButtonDisabled] = useState(true);
  const [ratificationActionButtonDisabled, setRatificationActionButtonDisabled] = useState(true);
  const [allActions, setAllActions] = useState({});
  const [generalActions, setGeneralActions] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [ratificationBlacklistDebtor, setRatificationBlacklistDebtor] = useState(false);
  const [debtorInfo, setDebtorInfo] = useState({});
  const [isControlled, setIsControlled] = useState(true);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [addRatificationActionOpen, setAddRatificationActionOpen] = useState(false);
  const [addCollectionActionOpen, setAddCollectionActionOpen] = useState(false);
  const [sendCollectionMailOpen, setSendCollectionMailOpen] = useState(false);
  const [sendRatificationMailOpen, setSendRatificationMailOpen] = useState(false);
  const [rejectionOpen, setRejectionOpen] = useState(false);
  const [acceptanceOpen, setAcceptanceOpen] = useState(false);
  const [rejectionLoading, setRejectionLoading] = useState(false);
  const [acceptanceLoading, setAcceptanceLoading] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [anchorElementMail, setAnchorElementMail] = useState(null);
  const [objectOrder, setObjectOrder] = useState(initialObjectOrderState);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [actionsDrawerInvoice, setActionsDrawerInvoice] = useState(
    initialActionsDrawerInvoiceState,
  );

  const {
    data: debtorData,
    loading: loadingDebtorData,
    refetch: refetchDebtorData,
  } = useQuery(
    GET_DEBTOR, {
      variables: {
        debtorRut: rut,
      },
    },
  );
  const {
    data: debtorPaymentBehaviourEnum,
    loading: loadingDebtorPaymentBehaviourEnum,
  } = useQuery(DEBTOR_PAYMENT_BEHAVIOUR_ENUM);
  const {
    usableData: invoicesData,
    loading: loadingInvoicesData,
    refetch: invoicesDataRefetch,
  } = useFilteredQuery(
    GET_DEBTOR_INVOICES, {
      variables: {
        debtorRut: rut,
        first: ROW_NUMBERS,
        offset: page * ROW_NUMBERS,
        globalFilter,
        orderBy,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const debtorEdges = data?.getDebtorInvoices?.edges || [];
        const newInvoices = [];
        const newInvoicesInfo = {};
        debtorEdges.forEach((invoice) => {
          newInvoicesInfo[invoice.node.id] = invoice.node;
          newInvoices.push(invoice.node);
        });
        setDebtorInfo({ ...debtorInfo, ...newInvoicesInfo });
        setFilteredInvoices(newInvoices);
      },
    },
  );
  const { loading: loadingInvoicesManagersData } = useFilteredQuery(
    GET_INVOICES_MANAGERS, {
      variables: {
        debtorRut: rut,
      },
      onCompleted: (data) => {
        const collectionActions = [];
        const ratificationActions = [];

        data.getActiveInvoicesByReceiver.forEach((invoice) => {
          if (invoice.collectionManager) {
            collectionActions.push(...invoice.collectionManager.actions);
          }
          if (invoice.ratificationmanager) {
            ratificationActions.push(...invoice.ratificationmanager.actions);
          }
        });
        collectionActions.sort(compareByDate);
        ratificationActions.sort(compareByDate);
        setAllActions({ collectionActions, ratificationActions });
      },
    },
  );

  const [createAction] = useMutation(CREATE_RATIFICATION_ACTION);
  const [rejectRatification] = useMutation(REJECT_RATIFICATION);
  const [acceptRatification] = useMutation(ACCEPT_RATIFICATION);
  const [addDataForCollection] = useMutation(ADD_DATA_FOR_COLLECTION);
  const [addCollectionAction] = useMutation(ADD_COLLECTION_ACTION);
  const [setDebtorPaymentBehaviour] = useMutation(SET_DEBTOR_PAYMENT_BEHAVIOUR);

  const newRequestWithOrder = (property) => {
    const order = objectOrder.order === 'asc' ? 'desc' : 'asc';
    setObjectOrder({ property, order });
  };

  const onClickActions = ({
    id,
    folio,
    nameReceiver,
    collectionManager,
    ratificationmanager,
    company,
    rutReceiver,
    amountWithIva,
  }) => {
    setActionsDrawerInvoice({
      id,
      folio,
      amount: amountWithIva,
      nameReceiver,
      actions: collectionManager ? [...collectionManager.actions].sort(compareByDate) : [],
      rutReceiver,
      companyRut: company.rut,
      companyName: company.name,
      managerId: collectionManager ? collectionManager.id : null,
      dataForCollection: collectionManager ? collectionManager.dataforcollection
        : {
          dateToPay: null,
          paymentMethod: null,
          collectionContacts: [],
        },
      reminder: collectionManager ? collectionManager.reminder
        : {
          reminderDate: null,
          reminderNote: null,
        },
      ratificationActions: ratificationmanager ? ratificationmanager.actions : [],
    });
    setActionsDrawerOpen(true);
  };

  const onClickAllActions = () => {
    setGeneralActions(true);
    setActionsDrawerOpen(true);
  };

  const onCloseActionsDrawer = () => {
    setActionsDrawerOpen(false);
    setGeneralActions(false);
    setActionsDrawerInvoice(initialActionsDrawerInvoiceState);
  };

  const onSendDataForCollection = async (dateToPay, paymentMethod, collectionContactIds) => {
    const collectionManagerId = actionsDrawerInvoice.managerId;
    try {
      const data = await addDataForCollection({
        variables: {
          collectionManagerId,
          dateToPay,
          paymentMethod,
          collectionContactIds,
        },
      });
      const addedDataForCollection = data?.addDataForCollection?.addedDataForCollection;
      setFilteredInvoices((prevState) => {
        const newState = prevState;
        const selectedInvoice = prevState.findIndex((row) => row.id === collectionManagerId);
        newState[selectedInvoice] = {
          ...newState[selectedInvoice],
          dataForCollection: addedDataForCollection,
        };
        return newState;
      });
      setActionsDrawerInvoice((prevState) => ({
        ...prevState, dataForCollection: addedDataForCollection,
      }));
    } catch (_) {
      setErrorMessage('Hubo un error al intentar enviar la información. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
    const notEmpty = rows.length > 0;
    const sameCompany = notEmpty && rows.every((row) => row.company.rut === rows[0].company.rut);
    const allRatification = notEmpty && rows.every((row) => row.status.value === 'PendingRatification');
    const allCollection = notEmpty && rows.every((row) => row.status.value === 'Pending collection');
    if (notEmpty) {
      setRatificationBlacklistDebtor(rows[0].receiver.ratificationBlacklist?.active);
    }
    if (sameCompany) {
      setSelectedCompany(rows[0].company);
    }
    setRatificationActionButtonDisabled(!allRatification);
    setCollectionActionButtonDisabled(!allCollection);
  };

  const handleOpenActionMenu = (e) => {
    setAnchorElement(e.currentTarget);
  };

  const handleOpenMailMenu = (e) => {
    setAnchorElementMail(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleCloseMail = () => {
    setAnchorElementMail(null);
  };

  const handleRatificationAction = () => {
    setAddRatificationActionOpen(true);
  };

  const handleCollectionAction = () => {
    setAddCollectionActionOpen(true);
  };

  const onCloseRejection = () => {
    setRejectionOpen(false);
  };

  const onCloseAcceptance = () => {
    setAcceptanceOpen(false);
  };

  const onReject = async (reason, comment) => {
    const observation = (reason === 'other') ? comment : '';
    const ratificationManagerIds = selectedRows.map((row) => (
      parseInt(row.ratificationmanager.id, 10)
    ));
    setRejectionLoading(true);
    try {
      await rejectRatification({
        variables: {
          ratificationManagerIds,
          resolutionType: reason,
          observation,
        },
      });
      setRejectionLoading(false);
      setRejectionOpen(false);
      setSuccessMessage('Rechazo de facturas exitoso.');
      setSuccessMessageOpen(true);
      invoicesDataRefetch();
    } catch (err) {
      setErrorMessage('Hubo un error al intentar rechazar las facturas. Comunícate con el equipo de desarrollo.');
      setRejectionLoading(false);
      setErrorMessageOpen(true);
    }
  };

  const onAccept = async (reason, evidenceFile, contactId) => {
    setAcceptanceLoading(true);
    const ratificationManagerIds = selectedRows.map((row) => (
      parseInt(row.ratificationmanager.id, 10)
    ));
    const toSendEvidenceFile = ['exception', 'mail'].includes(reason) ? evidenceFile : null;
    const toSendContactId = (reason === 'phone') ? contactId : null;
    try {
      await acceptRatification({
        variables: {
          ratificationManagerIds,
          resolutionType: reason,
          evidenceFile: toSendEvidenceFile,
          evidenceContactId: toSendContactId,
        },
      });
      setAcceptanceLoading(false);
      setAcceptanceOpen(false);
      setSuccessMessage('Factura aceptada con éxito.');
      setSuccessMessageOpen(true);
      invoicesDataRefetch();
    } catch (error) {
      setErrorMessage('Hubo un error al intentar aceptar las facturas. Comunícate con el equipo de desarrollo.');
      setRejectionLoading(false);
      setErrorMessageOpen(true);
    }
  };

  const buildCompaniesAndFolios = () => selectedRows.reduce((acc, row) => {
    const { folio, client } = row;
    return { ...acc, [client.value]: [...(acc[client.value] || []), folio.value] };
  }, {});

  const onCreateRatificationAction = async (
    actionType, comment, contactsIds, responded, channel,
  ) => {
    const ratificationContactsIds = ['INITIATE_RATIFICATION_PROCESS', 'INSIST_RATIFICATION'].includes(actionType)
      ? contactsIds : [];
    const ratificationManagerIds = selectedRows.map((row) => row.ratificationmanager.id);
    try {
      const data = await createAction({
        variables: {
          ratificationContactsIds,
          ratificationManagerIds,
          ratificationActionType: actionType,
          ratificationComment: comment,
          ratificationChannel: channel,
          responded,
        },
      });
      const addedAction = data?.createRatificationAction?.addedAction;
      setActionsDrawerInvoice((prevState) => ({
        ...prevState, actions: [addedAction, ...prevState.actions].sort(compareByDate),
      }));
      setSuccessMessage('Creación de gestión exitosa.');
      setSuccessMessageOpen(true);
      setAddRatificationActionOpen(false);
    } catch (error) {
      setAddRatificationActionOpen(false);
      setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const onCreateCollectionAction = async (actionType, comment, collectionContactIds) => {
    const collectionManagerIds = selectedRows.map((row) => row.collectionManager.id);
    try {
      const data = await addCollectionAction({
        variables: {
          collectionManagerIds,
          actionType,
          comment,
          collectionContactIds,
        },
      });
      const addedAction = data?.addCollectionAction?.addedAction;
      setActionsDrawerInvoice((prevState) => ({
        ...prevState, actions: [addedAction, ...prevState.actions].sort(compareByDate),
      }));
      setSuccessMessage('Creación de gestión exitosa.');
      setSuccessMessageOpen(true);
      setAddCollectionActionOpen(false);
    } catch (error) {
      setAddCollectionActionOpen(false);
      setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
      setErrorMessageOpen(true);
    }
  };

  const handleBehaviourChange = async (newBehaviour) => {
    await setDebtorPaymentBehaviour({
      variables: {
        newBehaviour,
        masterEntityId: debtorId,
      },
    });
    await refetchDebtorData();
  };

  const handleBehaviourColor = (paymentBehavior) => {
    switch (paymentBehavior) {
      case 'GOOD': case 'VERY_GOOD': return classes.success;
      case 'IN_PROBLEMS': case 'IN_SEVERE_PROBLEMS': return classes.red;
      case 'BAD_LATE_PAYER': case 'NEW': return classes.warningColor;
      default: return '';
    }
  };

  const handleStatusName = (status) => {
    switch (status) {
      case 'Evaluation': return 'Evaluación';
      case 'PendingRatification': return 'Ratificación';
      case 'Pending collection': return 'Cobranza';
      case 'Finished': return 'Finalizado';
      case 'Processing': return 'Procesando Cesión';
      case 'PendingTransfer': return 'Pendiente de Giro';
      case 'Receded': return 'Recedida';
      case 'SurplusWithdrawn': return 'Excedente Retirado';
      case 'RejectedRatification': return 'Ratificación Rechazada';
      default: return '';
    }
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      dateIssued,
      dateExpiration,
      amountWithIva,
      stage,
      company,
      creditNoteStatus,
      siiStatus,
      pdf,
      ratificationmanager,
      collectionManager,
      surplusDebt,
      paymentDate,
      lastHistoryStatus,
      receiver,
    } = row;

    const {
      label: siiLabel,
      color: siiColor,
    } = siiStatusToProps(dateIssued, creditNoteStatus)[siiStatus];

    return ({
      id,
      collectionManagerId: collectionManager ? collectionManager.id : null,
      ratificationManagerId: ratificationmanager ? ratificationmanager.id : null,
      receiver,
      ratificationmanager,
      collectionManager,
      company,
      folio: {
        value: folio, // value is for table sorting
        component: (
          <div className={classes.alignRight}>
            <Typography>  {folio}</Typography>
            <Tooltip title="Descargar Factura">
              <a href={pdf} target="_blank" rel="noopener noreferrer">
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </a>
            </Tooltip>
          </div>
        ),
      },
      dateIssued: {
        value: dateIssued,
        component: <Typography align="right">{moment(dateIssued).format('DD-MM-YYYY')}</Typography>,
      },
      dateExpiration: {
        value: dateExpiration,
        component: <Typography align="right">{moment(dateExpiration).format('DD-MM-YYYY')}</Typography>,
      },
      status: {
        value: stage,
        component: <Typography align="right">{handleStatusName(stage)}</Typography>,
      },
      amount: {
        value: amountWithIva,
        component: <Typography align="right">${formatMoney(amountWithIva)}</Typography>,
      },
      paymentDate: {
        value: paymentDate,
        component: <DateWithDaysLeft date={moment(paymentDate)} />,
      },
      debt: {
        value: surplusDebt,
        component: <Typography align="right">${formatMoney(surplusDebt)}</Typography>,
      },
      client: {
        value: company.rut,
        component: (
          <>
            <Typography>{company.name}</Typography>
            <Typography>{formatRut(company.rut)}</Typography>
          </>
        ),
        size: 'small',
      },
      timeInStage: {
        value: getTimePassedFromDate(lastHistoryStatus?.createdAt),
        component: (
          <Typography align="center">
            {getTimePassedTextFromTime(getTimePassedFromDate(lastHistoryStatus?.createdAt))}
          </Typography>
        ),
      },
      purchaseOrder: {
        value: folio,
        component: (
          <div />
        ),
      },
      actionsData: {
        component: (
          <div className={classes.columnDirection}>
            <IconButton onClick={() => onClickActions(row)}>
              <Badge
                color="primary"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Badge anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                  <Message />
                </Badge>
              </Badge>
            </IconButton>
          </div>
        ),
      },
      integrationStatus: {
        value: siiStatus,
        component: (
          <div className={classes.center}>
            <Typography style={{ color: siiColor }}>
              {siiLabel}
            </Typography>
          </div>
        ),
      },
    });
  });

  return (
    <div className={classes.container}>
      <OneButtonAlert
        open={successMessageOpen}
        onClose={() => window.location.reload()}
        message={successMessage}
        title="Éxito"
      />
      <OneButtonAlert
        open={errorMessageOpen}
        onClose={() => setErrorMessageOpen(false)}
        message={errorMessage}
        title="Error"
      />
      <div className={classes.headerContainer}>
        <div>
          <div className={classes.titleAndUpdate}>
            <IconButton onClick={history.goBack}>
              <ArrowBackIos />
            </IconButton>
            <div>
              <Typography variant="h3">
                <b>{name}</b>
              </Typography>
              <Typography variant="h5">
                <b>{formatRut(rut)}</b>
              </Typography>
            </div>
          </div>
          <Typography variant="h6">
            <Button onClick={() => setContactsOpen(true)}> Ver Contactos </Button>
          </Typography>
          <Card className={classes.card}>
            <CardContent>
              <Grid container direction="column">
                <Grid item className={classes.cardTitle}>
                  <Typography variant="h5">
                    <b>Resumen con Fingo</b>
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Tramo SII</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.cardContentDetail}>
                          {siiSalesSection}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Ratificador asignado</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.cardContentDetail}>
                          {ratificator ? (`${ratificator.firstName} ${ratificator.lastName}`) : 'No asignado' }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Cobrador asignado</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.cardContentDetail}>
                          {collector ? (`${collector.firstName} ${collector.lastName}`) : 'No asignado' }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Gestiones históricas</Typography>
                      </Grid>
                      {!loadingInvoicesManagersData && (
                      <IconButton onClick={onClickAllActions}>
                        <Badge
                          color="primary"
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          <Badge
                            badgeContent=" "
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          >
                            <Message />
                          </Badge>
                        </Badge>
                      </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  {!loadingDebtorData && (
                  <Grid item>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Facturas pagadas</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.cardContentDetail}>
                          {debtorData?.getDebtor?.masterEntity.payedInvoicesCount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Grid item>
                        <Typography variant="h6">Facturas en mora</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.cardContentDetail}>
                          {debtorData?.getDebtor?.masterEntity.invoicesInDebtCount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.cardContent}>
                      <Select
                        id="debtorPaymentBehaviour"
                        value={debtorData?.getDebtor?.debtorType || 'Seleccione'}
                        onChange={(event) => handleBehaviourChange(event.target.value)}
                        variant="outlined"
                        className={handleBehaviourColor(debtorData?.getDebtor?.debtorType)}
                      >
                        <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un tipo</MenuItem>
                        {!loadingDebtorPaymentBehaviourEnum && (
                          debtorPaymentBehaviourEnum.getDebtorPaymentBehaviourEnum.map(
                            (option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ),
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Typography variant="h5"> <b> Detalle de facturas vigentes </b></Typography>
          <br />
          <Filters
            allData={filteredInvoices}
            setFilteredData={setFilteredInvoices}
            searchFields={['folio', 'name', 'rut']}
            searchPlaceholder="Búsqueda por folio o emisor"
            isControlled={{ isControlled, setGlobalFilter }}
          />
        </div>
        <Grid container direction="column" className={classes.topRightContainer}>
          <Grid item>
            <div className={classes.headerButtonsContainer}>
              <Button
                className={classes.blueButton}
                onClick={handleOpenMailMenu}
                color="inherit"
                variant="contained"
                disabled={collectionActionButtonDisabled && ratificationActionButtonDisabled}
              >
                Enviar correo
              </Button>
              <Menu
                open={!!anchorElementMail}
                anchorEl={anchorElementMail}
                onClose={handleCloseMail}
              >
                <MenuItem
                  onClick={() => setSendCollectionMailOpen(true)}
                  disabled={collectionActionButtonDisabled}
                >
                  Mail de Cobranza
                </MenuItem>
                <MenuItem
                  onClick={() => setSendRatificationMailOpen(true)}

                >
                  Mail de Ratificación
                </MenuItem>
              </Menu>
              <Button
                onClick={handleOpenActionMenu}
                variant="contained"
                color="primary"
                className={classes.yellowButton}
                disabled={collectionActionButtonDisabled && ratificationActionButtonDisabled}
              >
                Añadir gestión
              </Button>
              <Menu
                open={!!anchorElement}
                anchorEl={anchorElement}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => handleCollectionAction()}
                  disabled={collectionActionButtonDisabled}
                >
                  Gestión de Cobranza
                </MenuItem>
                <MenuItem
                  onClick={() => handleRatificationAction()}
                  disabled={ratificationActionButtonDisabled}
                >
                  Gestión de Ratificación
                </MenuItem>
              </Menu>
              <Button
                variant="contained"
                color="primary"
                className={classes.greenButton}
                disabled={ratificationActionButtonDisabled}
                onClick={() => setAcceptanceOpen(true)}
              >
                Aceptar
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.redButton}
                disabled={ratificationActionButtonDisabled}
                onClick={() => setRejectionOpen(true)}
              >
                Rechazar
              </Button>
            </div>
          </Grid>
          <Grid item>
            {!loadingDebtorData && (
            <div className={classes.infoCardsContainer}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Card className={classes.infoCard}>
                    <CardContent className={classes.infoCardContent}>
                      <Typography variant="h6"> Monto por pagar</Typography>
                      <Typography variant="h3">
                        <b>
                          ${formatMoney(
                          debtorData?.getDebtor?.masterEntity.pendingInvoicesTotalAmount,
                        )}
                        </b>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className={classes.infoCard}>
                    <CardContent className={classes.infoCardContent}>
                      <Typography variant="h6"> Documentos por pagar</Typography>
                      <Typography variant="h3">
                        <b>{debtorData?.getDebtor?.masterEntity.pendingInvoicesCount}</b>
                      </Typography>
                      <Typography variant="h6"> Facturas</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className={classes.infoCard}>
                    <CardContent className={classes.infoCardContent}>
                      <Typography variant="h6"> Plazo de pago promedio acordado</Typography>
                      <Typography variant="h3">
                        <b>
                          {debtorData?.getDebtor?.masterEntity.invoicesAgreedPaymentTermAverage}
                        </b>
                      </Typography>
                      <Typography variant="h6"> días</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card className={classes.infoCard}>
                    <CardContent className={classes.infoCardContent}>
                      <Typography variant="h6"> Plazo de pago promedio real</Typography>
                      <Typography variant="h3">
                        <b>{debtorData?.getDebtor?.masterEntity.invoicesActualPaymentTerm}</b>
                      </Typography>
                      <Typography variant="h6"> días</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            )}
          </Grid>
        </Grid>
      </div>
      <CollectionActionsDrawer
        open={actionsDrawerOpen}
        onClose={onCloseActionsDrawer}
        generalActions={generalActions}
        allActions={allActions}
        invoiceData={actionsDrawerInvoice}
        collectionInvoicesRefetch={invoicesDataRefetch}
        onSendDataForCollection={onSendDataForCollection}
      />
      <Grid item xs={12} className={loadingInvoicesData ? classes.loading : classes.tableContainer}>
        {!loadingInvoicesData ? (
          <TablePaginationBackend
            withSelect
            data={filteredInvoices}
            dataToRows={transformDataToComponents}
            handleSelected={handleSelectedRows}
            setNewDataOrder={setFilteredInvoices}
            headers={[...headers]}
            initialOrderId="companyRut"
            lengthData={invoicesData.getDebtorInvoices.totalCount}
            newRequestWithOrder={newRequestWithOrder}
            objectOrder={objectOrder}
            loading={loadingInvoicesData}
            controlled={{
              isControlled,
              setIsControlled,
              setOrderBy,
              totalPages: invoicesData.getDebtorInvoices.totalPages,
              setPage,
              page,
            }}
          />
        ) : (
          <LinearProgress />
        )}
      </Grid>
      <OneButtonAlert
        open={contactsOpen}
        message={(
          <ContactsTable
            companyRut={rut.toString()}
            companyName={name}
            onSelect={false}
            masterEntityId={debtorId}
          />
        )}
        title="Contactos"
        closeMessage="Cerrar"
        onClose={() => setContactsOpen(false)}
      />
      {addRatificationActionOpen && (
        <AddRatificationActions
          open={addRatificationActionOpen}
          onClose={() => setAddRatificationActionOpen(false)}
          nameReceiver={name}
          rutReceiver={rut}
          onAccept={onCreateRatificationAction}
          ratificationInvoicesRefetch={invoicesDataRefetch}
          ratificationBlacklistReceiver={ratificationBlacklistDebtor}
        />
      )}
      {addCollectionActionOpen && (
        <AddCollectionActions
          open={addCollectionActionOpen}
          onClose={() => setAddCollectionActionOpen(false)}
          nameReceiver={name}
          rutReceiver={rut}
          companyRut={selectedCompany.rut}
          companyName={selectedCompany.name}
          onAdd={onCreateCollectionAction}
        />
      )}
      {sendCollectionMailOpen && (
      <CollectionSendMail
        open={sendCollectionMailOpen}
        setOpen={setSendCollectionMailOpen}
        selectedInvoices={selectedRows}
        nameReceiver={name}
        rutReceiver={rut}
        receiverId={debtorId}
      />
      )}
      {sendRatificationMailOpen && (
      <RatificationSendMail
        open={sendRatificationMailOpen}
        setOpen={setSendRatificationMailOpen}
        selectedInvoices={selectedRows}
        nameReceiver={name}
        rutReceiver={rut}
        receiverId={debtorId}
      />
      )}
      {selectedRows.length > 0 && (
        <>
          <RejectRatification
            open={rejectionOpen}
            onClose={onCloseRejection}
            onAccept={onReject}
            companiesAndFolios={buildCompaniesAndFolios()}
            loading={rejectionLoading}
          />
          <AcceptRatification
            open={acceptanceOpen}
            debtorProfile
            onClose={onCloseAcceptance}
            onAccept={onAccept}
            companiesAndFolios={buildCompaniesAndFolios()}
            loading={acceptanceLoading}
            rutReceiver={rut}
            nameReceiver={name}
            clients={selectedRows.map((row) => ({
              rut: row.company.rut,
              socialReason: row.company.name,
            }))}
          />
        </>
      )}
    </div>
  );
};

export default DebtorProfile;
