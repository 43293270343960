/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_CLIENT_DEBTORS_LIST_EXCEL = gql`
query getClientDebtorsListExcel($clientRut: String!){
  getClientDebtorsListExcel(clientRut: $clientRut){
    url
  }
}
`;
