import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConciliaitonSuccessful = ({ open, setOpen }) => (
  <Dialog open={open}>
    <DialogTitle>
      <Typography variant="h5">
        Conciliación hecha con exito
      </Typography>
    </DialogTitle>
    <DialogActions>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpen(false)}
      >
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

ConciliaitonSuccessful.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConciliaitonSuccessful;
