import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  List,
  ListItem,
  Divider,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AddNewContact } from '../contacts';
import { GET_COMPANY_COLLECTION_CONTACTS, REINTEGRATION_FLAG_ACTIVATION } from '../../gql/collection';

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 20,
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formField: {
    width: '50%',
    marginBottom: '1rem',
  },
  sendButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '2rem',
    marginTop: '1rem',
    width: '100%',
  },
  sendButtonButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  warningButton: {
    backgroundColor: '#BC211D',
    color: 'white',
    '&:hover': {
      color: '#BC211D',
    },
    borderColor: '#BC211D',
  },
}));

const paymentMethodOptions = [
  { label: 'Transferencia', value: 'TRANSFER' },
  { label: 'Cheque', value: 'CHECK' },
  { label: 'Vale Vista', value: 'BANK_TICKET' },
];

const AddDataForCollection = ({
  backToInfo,
  nameReceiver,
  rutReceiver,
  onSendData,
  managerId,
  collectionInvoicesRefetch,
  forReintegration,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [error, setError] = useState('');

  const { data: contactsData,
    loading: contactsLoading,
    refetch: contactsRefetch } = useQuery(GET_COMPANY_COLLECTION_CONTACTS,
    { variables: { companyRut: rutReceiver || 0 }, skip: !rutReceiver, fetchPolicy: 'network-only' });

  const [reintegrationFlagActivation] = useMutation(REINTEGRATION_FLAG_ACTIVATION);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleContactChange = (event) => {
    setContactId(event.target.value);
  };

  const handleAddData = async () => {
    if (!selectedDate || !selectedPaymentMethod || !contactId) {
      setError('Debes completar todos los campos');
    } else {
      setError('');
      const dateToPay = moment(selectedDate).format('YYYY-MM-DD');
      await onSendData(dateToPay, selectedPaymentMethod, [contactId]);
      backToInfo();
    }
  };

  const handleReintegration = async (event) => {
    event.preventDefault();
    await reintegrationFlagActivation({
      variables: {
        newStatus: !forReintegration,
        managerId,
      },
    });
    await collectionInvoicesRefetch();
  };

  useEffect(() => {
    if (contactsData) {
      const listOfContacts = contactsData.getCompanyCollectionContacts.map((contact) => {
        const name = `${contact.firstName} ${contact.lastName} - Cargo: ${contact.position}`;
        return { name, id: contact.id };
      });
      setCompanyContacts(listOfContacts);
    }
  }, [contactsData]);

  return (
    <>
      <List className={classes.list}>
        <ListItem>
          <Button onClick={backToInfo}>
            <ChevronLeftIcon />
          </Button>
          <Typography
            variant="h6"
            gutterBottom
          >
            Editar datos de recaudación
          </Typography>
        </ListItem>
        <Divider />
        <ListItem className={classes.formContainer}>
          <Typography gutterBottom>
            <b>Fecha de pago</b>
          </Typography>
          <KeyboardDatePicker
            variant="inline"
            format="DD/MM/yyyy"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            inputVariant="outlined"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.formField}
          />
          <Typography gutterBottom>
            <b>Método de pago</b>
          </Typography>
          <Select
            id="data-collection-payment-method"
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
            className={classes.formField}
            variant="outlined"
          >
            {paymentMethodOptions.map((method) => (
              <MenuItem key={method.value} value={method.value}>{method.label}</MenuItem>
            ))}
          </Select>
          <Typography gutterBottom>
            <b>Contacto que entregó los datos</b>
          </Typography>
          {contactsLoading ? (<CircularProgress size={16} />) : (
            <>
              <Select
                id="data-collection-contact"
                value={contactId}
                onChange={handleContactChange}
                className={classes.formField}
                variant="outlined"
              >
                {companyContacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                ))}
              </Select>
              <AddNewContact
                companyName={nameReceiver}
                companyRut={rutReceiver}
                type="COLLECTION"
                contactsRefetch={contactsRefetch}
              />
            </>
          )}
          <div className={classes.sendButtonContainer}>
            <Typography color="error" variant="caption">{error}</Typography>
            <div className={classes.sendButtonButtons}>
              <Button onClick={(e) => handleAddData(e)} color="primary" variant="outlined" value>
                Enviar datos de recaudación
              </Button>
              <Button onClick={handleReintegration} className={classes.warningButton} variant="outlined">
                Factura {forReintegration && 'no '}debe ser reintegrada
              </Button>
            </div>
          </div>
        </ListItem>
      </List>
    </>
  );
};

AddDataForCollection.propTypes = {
  backToInfo: PropTypes.func.isRequired,
  nameReceiver: PropTypes.string.isRequired,
  rutReceiver: PropTypes.number.isRequired,
  onSendData: PropTypes.func.isRequired,
  managerId: PropTypes.number.isRequired,
  collectionInvoicesRefetch: PropTypes.func,
  forReintegration: PropTypes.bool,
};

AddDataForCollection.defaultProps = {
  collectionInvoicesRefetch: () => {},
  forReintegration: false,
};

export default AddDataForCollection;
