import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { TreeRisk, Ras, RiskResult } from '../components/riskAlgorithm';

const RiskAlgorithm = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, tab) => setSelectedTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Árbol" />
        <Tab label="Ras" />
        <Tab label="Resultados" />
      </Tabs>
      {selectedTab === 0 && (
        <TreeRisk />
      )}
      {selectedTab === 1 && (
        <Ras />
      )}
      {selectedTab === 2 && (
        <RiskResult />
      )}
    </>
  );
};

export default RiskAlgorithm;
