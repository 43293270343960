import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import TableHeader from './Header';
import CustomPagination from './CustomPagination';

const PaginationTable = (props) => {
  const {
    headers,
    data,
    dataToRows,
    rowsCount,
    page,
    setPage,
    rowsPerPage,
    orderBy,
    orderDirection,
    setOrderBy,
    setOrderDirection,
    withSelect,
    selectedRows,
    setSelectedRows,
  } = props;

  const pageCount = Math.ceil(rowsCount / rowsPerPage);
  const rows = dataToRows(data);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const isSelected = (row) => selectedRows?.some((r) => r.id === row.id);

  const requestSortHandler = (property, direction) => {
    setOrderBy(property);
    setOrderDirection(direction);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectOne = (selectedRow) => {
    if (isSelected(selectedRow)) {
      setSelectedRows(selectedRows.filter((row) => row.id !== selectedRow.id));
    } else {
      setSelectedRows([...selectedRows, selectedRow]);
    }
  };

  const tableBody = rows?.map((row, idx) => (
    <TableRow
      aria-checked={isSelected(row)}
      tabIndex={-1}
      key={row.id}
      style={row.style}
    >
      {withSelect && (
        <TableCell padding="none" style={{ width: 75 }}>
          <Checkbox
            onChange={() => handleSelectOne(row)}
            checked={isSelected(row)}
            inputProps={{
              'aria-labelledby': `enhanced-table-checkbox-${idx}`,
              'aria-label': `${row.disabled && 'disabled'} checkbox`,
            }}
            disabled={row.disabled}
          />
        </TableCell>
      )}
      {headers?.map((headCell) => (
        <TableCell key={`${headCell.label},${row.id}`}>
          {row[headCell.id]?.component}
        </TableCell>
      ))}
    </TableRow>
  ));

  return (
    <>
      <TableContainer>
        <MuiTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHeader
            headCells={headers}
            orderBy={orderBy}
            order={orderDirection}
            onRequestSort={requestSortHandler}
            withSelect={withSelect}
            withoutSelectHeader={false}
            onSelectAllClick={handleSelectAll}
            numSelected={selectedRows?.length}
            rowCount={rows?.length}
          />
          <TableBody>
            {tableBody}
            {emptyRows > 0 && rows?.length > rowsPerPage && (
              <TableRow style={{ height: 53 * emptyRows, backgroundColor: 'transparent' }} />
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <CustomPagination
        page={page}
        totalPages={pageCount}
        onChangePage={setPage}
      />
    </>
  );
};

PaginationTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dataToRows: PropTypes.func.isRequired,
  data: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  rowsCount: PropTypes.number,
  orderDirection: PropTypes.string.isRequired,
  setOrderDirection: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  withSelect: PropTypes.bool,
  selectedRows: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  setSelectedRows: PropTypes.func,
};

PaginationTable.defaultProps = {
  data: [],
  rowsCount: 0,
  withSelect: false,
  selectedRows: [],
  setSelectedRows: () => {},
};

export default PaginationTable;
