import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Typography, Snackbar } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_PENDING_RESTRICTIONS } from '../../gql/customer';
import VisualizeRestriction from './VisualizeRestriction';

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',

  },

}));
const VisualizeAllRestrictions = ({
  // both props are from the parent view CompanyPreOffers
  // controlRefetch: from the view CompanyPreOffers, now we are not using this controlRefetch
  handleResolveRestriction, // this prop is used in VisualizeRestriction, child component
  clientRut,
  companyId,
  isPreofferPanel,
}) => {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [getPendingRestrictions, { data: pendingRestrictions,
    loading: loadingPendingRestrictions,
    error: errorPendingRestrictions,
  }] = useLazyQuery(GET_PENDING_RESTRICTIONS, { variables: {
    companyRut: clientRut,
  },
  fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    getPendingRestrictions();
    // we call the query at the first render, and then only if a restriction is resolved
  }, []);
  const handleOpenSnackbar = ({ controlSnackbar, messageSnackbarInput }) => {
    setOpenSnackbar(controlSnackbar);
    setMessageSnackbar(messageSnackbarInput);
  };
  const handleRefetchRestrictions = (controlRefetchFromVisualizeRestriction) => {
    // this function is called in the VisualizeRestriction component to notify
    // that a restriction has just been resolved
    if (controlRefetchFromVisualizeRestriction) {
      getPendingRestrictions();
    }
  };
  if (errorPendingRestrictions) return <Typography align="center">Error al cargar las restricciones {errorPendingRestrictions.message}</Typography>;
  if (loadingPendingRestrictions) return <Typography align="center">Cargando las restricciones pendientes  </Typography>;
  return (
    <div>
      <Snackbar
        open={openSnackBar}
        onClose={() => { setOpenSnackbar(false); }}
        message={messageSnackbar}
        key="snackbar"
        disableWindowBlurListener
      />
      {(isPreofferPanel && pendingRestrictions
      && !(pendingRestrictions.getPendingCompanyRestrictions.length === 0))
      && (<Typography variant="h6" style={{ marginBottom: '2rem', marginTop: '2rem' }}><b>Restricciones:</b></Typography>)}
      <div className={classes.container}>
        {pendingRestrictions && pendingRestrictions.getPendingCompanyRestrictions.map((res) => (

          <VisualizeRestriction
            restrictionName={res.label}
            restrictionValue={res.value}
            otherRestriction={res.otherRestriction}
            fileAssociated={res.fileAssociated}
            handleRefetchRestrictions={handleRefetchRestrictions}
            handleOpenSnackbar={handleOpenSnackbar}
            key={res.label}
              // from the parent view CompanyPreOffers the view must
              // trigger an update of the state of
              // the documents, when a restriction is resolved
              // for this reason we use the handleResolveRestriction
            handleResolveRestriction={handleResolveRestriction}
            companyId={companyId}
          />

        ))}
      </div>

    </div>
  );
};

VisualizeAllRestrictions.propTypes = {
  handleResolveRestriction: PropTypes.func.isRequired,
  clientRut: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isPreofferPanel: PropTypes.bool.isRequired,
};
export default VisualizeAllRestrictions;
