import { useState } from 'react';

// Always use with use callback in the react component
const useDebounce = (func, delay) => {
  const [, setTimer] = useState();
  return (args) => {
    setTimer((_prevTimer) => {
      clearTimeout(_prevTimer);
      return setTimeout(() => func(args), delay);
    });
  };
};

export default useDebounce;
