import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import { AddNewContact, EditContact } from '../contacts';
import { GET_COMPANY_COLLECTION_CONTACTS } from '../../gql/collection';
import { GET_COMPANY_RATIFICATION_CONTACTS } from '../../gql/ratification';

const headers = [
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'contact', label: 'Contacto', align: 'left' },
  { id: 'type', label: 'Tipo', align: 'left' },
  { id: 'actions', label: 'Acciones', align: 'left' },
];
const useStyles = makeStyles({
  addContactContainer: {
    textAlign: 'center',
  },
  container: {
    margin: '2%',
  },
});

const ContactsTable = ({ companyRut, companyName, onSelect }) => {
  const {
    data: collectionContactsData,
    refetch: collectionContactsRefetch,
  } = useQuery(
    GET_COMPANY_COLLECTION_CONTACTS,
    { variables: { companyRut }, skip: !companyRut, fetchPolicy: 'network-only' },
  );
  const {
    data: ratificationContactsData,
    refetch: ratificationContactsRefetch,
  } = useQuery(
    GET_COMPANY_RATIFICATION_CONTACTS,
    { variables: { companyRut }, fetchPolicy: 'network-only' },
  );

  const contactsRefetch = () => {
    collectionContactsRefetch();
    ratificationContactsRefetch();
  };

  const [contacts, setContacts] = useState();
  const [openEditContact, setOpenEditContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState();
  const classes = useStyles();

  const handleEditContact = (row) => {
    setContactToEdit(row);
    setOpenEditContact(true);
  };

  useEffect(() => {
    if (collectionContactsData?.getCompanyCollectionContacts
        && ratificationContactsData?.getCompanyRatificationContacts) {
      // eslint-disable-next-line prefer-const
      let allContacts = {};
      ratificationContactsData.getCompanyRatificationContacts.forEach((contact) => {
        allContacts[contact.email] = contact;
        allContacts[contact.email].type = ['ratificación'];
        allContacts[contact.email].ratificationId = contact.id;
      });
      collectionContactsData.getCompanyCollectionContacts.forEach((contact) => {
        if (contact.email in allContacts) {
          allContacts[contact.email].type.push('cobranza');
        } else {
          allContacts[contact.email] = contact;
          allContacts[contact.email].type = ['cobranza'];
        }
        allContacts[contact.email].collectionId = contact.id;
      });

      setContacts(Object.values(allContacts));
    }
  }, [collectionContactsData, ratificationContactsData]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
      type,
    } = row;
    return ({
      id,
      name: {
        component: (
          <>
            <Typography variant="body2">{firstName}</Typography>
            <Typography variant="body2">{lastName}</Typography>
            <Typography variant="caption"><b>{position || 'Sin cargo'}</b></Typography>
          </>
        ),
        value: firstName,
      },
      contact: {
        component: (
          <>
            <Typography variant="body2">{email || 'Sin email'}</Typography>
            <Typography variant="body2">{phoneNumber || 'Sin telefono'}</Typography>
          </>
        ),
        value: position,
      },
      type: {
        component: <Typography variant="body2">{type.join(' | ')}</Typography>,
      },
      actions: {
        component: <Button color="primary" onClick={() => handleEditContact(row)}>Editar</Button>,
      },
    });
  });

  return (
    <Box className={classes.container}>
      {contacts ? (
        <Table
          data={contacts}
          dataToRows={transformDataToComponents}
          headers={headers}
          withSelect={Boolean(onSelect)}
          pagination={false}
          setNewDataOrder={setContacts}
          handleSelected={onSelect}
        />
      ) : (
        <CircularProgress />
      )}
      <Grid className={classes.addContactContainer}>
        <AddNewContact
          companyName={companyName}
          companyRut={companyRut}
          contactsRefetch={contactsRefetch}
        />
      </Grid>
      <EditContact
        companyName={companyName}
        companyRut={companyRut}
        openForm={openEditContact}
        setOpenForm={setOpenEditContact}
        contact={contactToEdit}
        contactsRefetch={contactsRefetch}
        type={contactToEdit ? contactToEdit.type : ''}
      />
    </Box>
  );
};

ContactsTable.propTypes = {
  companyRut: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ContactsTable;
