import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import fingoLogo from '../../assets/images/logo_fingo_primary_color.png';

const styles = makeStyles(() => ({
  container: {
    padding: '3% 5%',
  },
  paper: {
    padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '10rem',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
}));
const Error = () => {
  const classes = styles();
  return (
    <Grid sm={12} md={12} lg={12} className={classes.container}>
      <Paper className={classes.paper} variant="outlined">
        <div className={classes.errorMessage}>
          <Typography>Ha ocurrido un error con la comunicación !</Typography>
          <Typography>
            Refresque la página nuevamente o contáctese con un desarrollador para ver el problema
          </Typography>
        </div>
        <img src={fingoLogo} alt="logo" style={{ width: 70 }} />
      </Paper>
    </Grid>
  );
};

export default Error;
