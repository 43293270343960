import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import GenericAvailableForTransfer from '../components/moneyTransfers';
import { formatRut } from '../helpers/generic';
import { useStyles } from './styles';
import { AVAILABLE_FOR_TRANSFER_CONFIRMING } from '../gql/confirming';
import { formatMoney } from '../helpers/money';
import { getTimePassedTextFromTime, getTimePassedFromDate } from '../helpers/date';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import DebtorCell from '../components/shared/DebtorCell';
import BFStatus from '../components/status';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'amount', label: 'Monto Factura', align: 'right' },
  { id: 'dateToPay', label: 'Fecha de Pago', align: 'right' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
  { id: 'BFstatus', label: 'Estado de BF', align: 'right' },
];

const filter = ['folio', 'nameReceiver', 'rutReceiver', 'nameClient', 'rutClient'];

const AvailableForTransferConfirming = () => {
  const { data, refetch, error, loading } = useQuery(
    AVAILABLE_FOR_TRANSFER_CONFIRMING, { notifyOnNetworkStatusChange: true },
  );

  const classes = useStyles();
  const [moneyTransfers, setMoneyTransfers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredMoneyTransfers, setFilteredMoneyTransfers] = useState([]);
  const title = 'Confirming disponible para giro';

  const rejectMessage = (
    <Grid container>
      <Typography style={{ marginBottom: '1rem' }}>
        <b>
          Realizando esta acción se dará por rechazado
          el financiamiento de las siguientes facturas
        </b>
      </Typography>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Cliente</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Folio</b></Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2"><b>Monto factura</b></Typography>
      </Grid>
      {selectedRows.map((row) => (
        <Grid container key={row.folio.value}>
          <Grid item xs={4}>
            <Typography variant="body2">{row.client.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">{row.folio.value}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">${formatMoney(row.amount.value)}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id: moneyTransferId,
      confirming: {
        amountWithIva,
        company: {
          masterEntity: {
            name: companyName,
            rut: companyRut,
          },
          companycompliance,
        },
        receiver: {
          masterEntity: {
            id: receiverId,
            name: nameReceiver,
            rut: rutReceiver,
          } },
        dateToPay,
        folio,
      },
      createdAt,
    } = row;

    return ({
      id: moneyTransferId,
      clientRut: companyRut,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography>{folio}</Typography>
          </div>
        ),
      },
      client: {
        value: companyName,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={companyName}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {companyName}
              </Typography>
              <Typography>
                {formatRut(companyRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      debtor: {
        value: nameReceiver,
        // <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={nameReceiver}>
        //   <div style={{ maxWidth: 200 }}>
        //     <Typography className={classes.oneLineClamp}>
        //       {nameReceiver}
        //     </Typography>
        //     <Typography>
        //       {formatRut(rutReceiver)}
        //     </Typography>
        //   </div>
        // </Tooltip>
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
      },
      amount: {
        value: amountWithIva,
        component: (
          <Typography align="right">${formatMoney(amountWithIva.amount)}</Typography>
        ),
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <Typography align="right">{moment(dateToPay).format('DD-MM-YYYY')}</Typography>
        ),
      },
      timeInStage: {
        value: getTimePassedFromDate(createdAt),
        component: (
          <Typography align="right">{getTimePassedTextFromTime(getTimePassedFromDate(createdAt))}</Typography>
        ),
      },
      BFstatus: {
        component: (
          <div className={classes.center}>
            <BFStatus compliance={companycompliance} />
          </div>
        ),
      },
    });
  });

  useEffect(() => {
    if (data) {
      setMoneyTransfers(data.getAvailableForTransferConfirming);
      setFilteredMoneyTransfers(data.getAvailableForTransferConfirming);
    }
  }, [data]);

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;

  return (
    <GenericAvailableForTransfer
      title={title}
      refetch={refetch}
      headers={headers}
      transformDataToComponents={transformDataToComponents}
      setFilteredMoneyTransfers={setFilteredMoneyTransfers}
      filteredMoneyTransfers={filteredMoneyTransfers}
      moneyTransfers={moneyTransfers}
      rejectMessage={rejectMessage}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      filter={filter}
    />
  );
};

export default AvailableForTransferConfirming;
