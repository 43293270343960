export const totalAmountFromObject = (list) => {
  const totalAmount = list.reduce((prev, curr) => prev + curr.amountWithIva, 0);
  return totalAmount;
};

export const countInvoices = (list) => {
  const count = list.reduce((prev) => prev + 1, 0);
  return count;
};

export const totalPriceDifferenceFromObject = (list) => {
  const totalPriceDifference = list.reduce((prev, curr) => prev + curr.priceDifference, 0);
  return totalPriceDifference;
};

export const totalRetentionFromObject = (list) => {
  const totalRetention = list.reduce((prev, curr) => prev + curr.retentionAmount, 0);
  return totalRetention;
};

export const totalAdvanceAmountFromObject = (list) => {
  const totalAdvanceAmount = list.reduce((prev, curr) => prev + curr.amountMinusInterests, 0);
  return totalAdvanceAmount;
};

export const calculateCommission = (commissionRules, accumulatedAmountWithIva) => {
  if (accumulatedAmountWithIva <= 0) return 0;
  const fixed = commissionRules.filter((rule) => rule.variant === 'FIXED');
  if (fixed.length > 0) {
    return fixed[0].value * 1.19; // Consider VAT
  }
  const percentage = commissionRules.filter((rule) => rule.variant === 'PERCENTAGE');
  let commission = 0;
  if (percentage.length > 0) {
    commission = percentage[0].value * accumulatedAmountWithIva;
  }
  const mins = commissionRules.filter((rule) => rule.variant === 'MIN').sort((a, b) => b.threshold - a.threshold);
  for (let i = 0; i < mins.length; i += 1) {
    const min = mins[i];
    if (accumulatedAmountWithIva >= min.threshold) {
      commission = Math.max(commission, min.value);
      break;
    }
  }
  const maxes = commissionRules.filter((rule) => rule.variant === 'MAX').sort((a, b) => a.threshold - b.threshold);
  for (let i = 0; i < maxes.length; i += 1) {
    const max = maxes[i];
    if (accumulatedAmountWithIva <= max.threshold) {
      commission = Math.min(commission, max.value);
      break;
    }
  }
  return commission * 1.19;
};
