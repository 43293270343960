import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Offers, InvoicesInOperation, RejectedInvoices, Preoffers } from '../components/clientInvoiceStatus';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';

const ClientInvoiceStatus = () => {
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { state } = location || {};
  const { rut, name } = state || {};
  const { clientRut } = useParams();
  const [loading, setLoading] = useState(true);

  return (
    <>
      { loading && <LinearProgress />}
      <Grid container>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.titleAndUpdate}>
            <IconButton onClick={history.goBack}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h4"><b>{state ? `Facturas cliente ${name} ${formatRut(rut)}` : `Facturas cliente ${formatRut(clientRut)}`} </b></Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '2rem', backgroundColor: 'white' }}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(e, value) => setTab(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Pre ofertas</Typography>} />
              <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Pre ofertas en Evaluación</Typography>} />
              <Tab label={<Typography color={tab === 2 ? 'primary' : 'inherit'}>Ofertas</Typography>} />
              <Tab label={<Typography color={tab === 3 ? 'primary' : 'inherit'}>Facturas en operación</Typography>} />
              <Tab label={<Typography color={tab === 4 ? 'primary' : 'inherit'}>Facturas rechazadas por riesgo</Typography>} />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: '0 2rem' }}>
          {tab === 0 && <Preoffers setLoading={setLoading} evaluation={false} />}
          {tab === 1 && <Preoffers setLoading={setLoading} evaluation />}
          {tab === 2 && <Offers setLoading={setLoading} />}
          {tab === 3 && <InvoicesInOperation setLoading={setLoading} />}
          {tab === 4 && <RejectedInvoices setLoading={setLoading} />}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientInvoiceStatus;
