import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  Typography,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { formatRut } from '../../helpers/generic';
import { Table } from '../tables';

const AddCompanyEnrollmentContract = ({
  classes,
  open,
  setUploadFile,
  onClose,
  rows,
}) => {
  const columns = [
    { id: 'id', label: 'ID', align: 'center' },
    { id: 'name', label: 'Nombre', align: 'center' },
    { id: 'uploadButton', label: 'Archivo', align: 'center' },
  ];

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      name,
      rut,
    } = row;

    return ({
      id: {
        component: (
          <div>
            <Typography>{id}</Typography>
          </div>
        ),
        value: id,
      },
      name: {
        value: name,
        component: (
          <Tooltip interactivetitle={name}>
            <div style={{ maxWidth: 200 }}>
              <Typography>
                {name}
              </Typography>
              {formatRut(rut)}
            </div>
          </Tooltip>
        ),
      },
      uploadButton: {
        value: 0,
        component: (
          <div>
            <label htmlFor="upload">
              <input
                style={{ display: 'none' }}
                id="upload"
                name="upload"
                type="file"
                onChange={(event) => (
                  setUploadFile((prevArray) => [...prevArray, event.target.files]))}
                accept="application/pdf, application/vnd.ms-excel"
              />
              <IconButton aria-label="delete" component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
          </div>
        ),
      },
    });
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h3">
          Contrato
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography className={classes.displayContract}>
          Ingrese el contrato de las siguientes empresas:
        </Typography>
        <Grid item xs={12} sm={12} className={classes.tableContainerEnrollment}>
          <Table
            headers={columns}
            data={rows}
            dataToRows={transformDataToComponents}
            setNewDataOrder={() => ([])}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddCompanyEnrollmentContract.propTypes = {
  open: PropTypes.bool.isRequired,
  setUploadFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddCompanyEnrollmentContract;
