import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '1rem',
  },
  pagesContainer: {
    maxWidth: 300,
    display: 'flex',
    overflow: 'scroll',
    paddingBottom: 2,
    '&::-webkit-scrollbar': {
      height: 2,
    },
  },
  activePage: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    color: 'white',
    padding: '2px 8px',
    width: 20,
    margin: '0 5px',
  },
  page: {
    padding: '2px 8px',
    width: 20,
    margin: '0 5px',
    cursor: 'pointer',
  },
}));

const CustomPagination = ({ page, onChangePage, totalPages }) => {
  const classes = useStyles();
  const previousPage = () => {
    if (page <= 0) return;
    onChangePage(page - 1);
  };

  const nextPage = () => {
    if (page >= totalPages - 1) return;
    onChangePage(page + 1);
  };

  const setPage = (newPage) => onChangePage(newPage);

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={previousPage} disabled={page <= 0}>
          <ChevronLeft />
        </IconButton>
        <div className={classes.pagesContainer}>
          {[...Array(totalPages)].map((_, i) => (
            <Typography
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={() => setPage(i)}
              align="center"
              variant="body2"
              className={page === i ? classes.activePage : classes.page}
            >
              {i + 1}
            </Typography>
          ))}
        </div>
        <IconButton onClick={nextPage} disabled={page >= totalPages - 1}>
          <ChevronRight />
        </IconButton>
      </div>
    </Grid>
  );
};

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default CustomPagination;
