import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, Redirect } from 'react-router-dom';
import {
  Typography,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { useStyles } from './styles';
import {
  GET_ACTIVE_ADVANCE_PAYMENTS,
} from '../gql/advancePayment';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const headers = [
  { id: 'requestId', label: 'ID', align: 'left', isSearchable: true },
  { id: 'company', label: 'Empresa', align: 'left', isSearchable: true },
  { id: 'employee', label: 'Colaborador', align: 'left', isSearchable: true },
  { id: 'amount', label: 'Monto', align: 'left', isSearchable: false },
  { id: 'requestStatus', label: 'Estado', align: 'left', isSearchable: false },
];

const useOwnStyles = makeStyles((theme) => ({
  onTimeBadge: {
    backgroundColor: theme.palette.success.main,
  },
  littleLateBadge: {
    backgroundColor: theme.palette.warning.main,
  },
  lateBadge: {
    backgroundColor: theme.palette.error.main,
  },
}));

const STATUS_MAP = {
  CREATED: 'Evaluando',
  APPROVED: 'Pendiente de giro',
  TRANSFERED: 'Girada',
};

const AdvancePaymentRequest = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const location = useLocation();
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const {
    data: advancePaymentRequestsData,
    loading: advancePaymentRequestsLoading,
    refetch: advancePaymentRequestsRefetch,
    error: advancePaymentRequestsError,
  } = useQuery(GET_ACTIVE_ADVANCE_PAYMENTS);

  useEffect(() => {
    if (location.state && location.state.refetch) advancePaymentRequestsRefetch();
  }, [location.state]);

  useEffect(() => {
    if (advancePaymentRequestsData) {
      setRequests(advancePaymentRequestsData.getActiveAdvancePaymentsRequests);
      setFilteredRequests(advancePaymentRequestsData.getActiveAdvancePaymentsRequests);
    }
  }, [advancePaymentRequestsData]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      company: {
        rut,
        name,
      },
      naturalPersonName,
      amount,
      requestStatus,
    } = row;

    return ({
      rut,
      id,
      requestId: {
        component: (
          <div className={classes.row}>
            <Typography>{id}</Typography>
          </div>
        ),
        value: id,
      },
      company: {
        component: (
          <div style={{ minWidth: '6rem' }}>
            <Typography>{name}</Typography>
            <Typography>{formatRut(rut)}</Typography>
          </div>
        ),
        value: name,
      },
      employee: {
        component: (
          <div style={{ minWidth: '6rem' }}>
            <Typography>{naturalPersonName}</Typography>
          </div>
        ),
        value: naturalPersonName,
      },
      amount: {
        component: (
          <Typography>{`$${formatMoney(amount)}`}</Typography>
        ),
        value: amount,
      },
      requestStatus: {
        component: (
          <Typography>{STATUS_MAP[requestStatus]}</Typography>
        ),
        value: requestStatus,
      },
    });
  });

  if (permissionDeniedErrorCatcher(advancePaymentRequestsError)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {advancePaymentRequestsLoading && (
        <LinearProgress />
      )}
      {!advancePaymentRequestsLoading && (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography variant="h4" className={classes.header}><b>Solicitudes de anticipo vigentes</b></Typography>
          </div>
          {requests.length > 0 ? (
            <Grid container>
              <Grid item xs={6} sm={6} className={classes.searchBar}>
                <Filters
                  allData={requests}
                  setFilteredData={setFilteredRequests}
                  searchFields={['name', 'rut']}
                  searchPlaceholder="Búsqueda por rut o nombre de cliente"
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="id"
                  setNewDataOrder={setFilteredRequests}
                  data={filteredRequests}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography align="center" variant="h5">No hay solicitudes vigentes</Typography>
          )}
        </div>
      )}
    </>
  );
};

export default AdvancePaymentRequest;
