const HEADERS = [
  {
    id: 'debtorRut',
    label: 'Rut',
    align: 'center',
    hideSortIcon: false,
  },
  {
    id: 'debtorName',
    label: 'Deudor',
    align: 'center',
    hideSortIcon: false,
  },
  {
    id: 'invoicesInEvaluationCount',
    label: 'Facturas en evaluación',
    align: 'center',
    hideSortIcon: true,
  },
  {
    id: 'invoicesInRatificationCount',
    label: 'Facturas en ratficación',
    align: 'center',
    hideSortIcon: true,
  },
  {
    id: 'invoicesInCollectionCount',
    label: 'Facturas en cobranza',
    align: 'center',
    hideSortIcon: true,
  },
  {
    id: 'debtorsProfileButton',
    label: 'Acciones',
    align: 'center',
    hideSortIcon: true,
  },
];

const ROWS_PER_PAGE = 15;

export { HEADERS, ROWS_PER_PAGE };
