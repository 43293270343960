import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Typography, Button } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import { useStyles } from '../../views/styles';
import AddVariable from './AddVariable';
import { DEACTIVATE_VARIABLE } from '../../gql/riskAlgorithm';
import { Loading } from '../loading';
import { Error } from '../error';

const headers = [
  { id: 'variableOrder', label: 'Orden', align: 'center' },
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'functionName', label: 'Función', align: 'left' },
  { id: 'args', label: 'Argumentos', align: 'left' },
  { id: 'logicalType', label: 'Función Lógica', align: 'left' },
  { id: 'threshold', label: 'Threshold', align: 'left' },
  { id: 'prize', label: 'Premio', align: 'left' },
  { id: 'approved', label: 'Aprobados', align: 'left' },
  { id: 'rejected', label: 'Rechazados', align: 'left' },
  { id: 'average', label: 'Promedio', align: 'left' },
  { id: 'stdDev', label: 'Desviación', align: 'left' },
  { id: 'result', label: 'Resultado Árbol', align: 'left' },
  { id: 'edit', label: '', align: 'right' },
  { id: 'deactivate', label: '', align: 'right' },
];

const GenericTree = ({ query, variableType }) => {
  const { data, loading, refetch, error } = useQuery(query, { notifyOnNetworkStatusChange: true });
  const [treeVariables, setTreeVariables] = useState();
  const [addVariableOpen, setAddVariableOpen] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState();
  const [newAllowedHeaders, setNewAllowedHeaders] = useState();
  const [deactivateVariable] = useMutation(DEACTIVATE_VARIABLE);
  const classes = useStyles();
  useEffect(() => {
    if (!data) return;
    const newVars = data[Object.keys(data)[0]];
    if (newVars && newVars[0].prize !== undefined) {
      setNewAllowedHeaders([...headers]);
    } else {
      setNewAllowedHeaders(headers.filter((header) => header.id !== 'prize'));
    }
    setTreeVariables([...newVars]);
  }, [data]);
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      name,
      functionName,
      args,
      threshold,
      logicalType,
      approved,
      rejected,
      average,
      stdDev,
      result,
      variableOrder,
      prize,
    } = row;
    return ({
      id,
      variableOrder: {
        value: variableOrder,
        component: (
          <Typography>{variableOrder}</Typography>
        ),
      },
      name: {
        value: name,
        component: (
          <Typography>{name}</Typography>
        ),
      },
      functionName: {
        value: functionName.name,
        component: (
          <Typography>{functionName.name}</Typography>
        ),
      },
      args: {
        value: args,
        component: (
          args.map((arg) => (
            <Typography key={`${arg}${id}`}>- {arg}</Typography>
          ))
        ),
      },
      threshold: {
        value: threshold,
        component: (
          <Typography>{threshold}</Typography>
        ),
      },
      logicalType: {
        value: logicalType.name,
        component: (
          <Typography>{logicalType.name}</Typography>
        ),
      },
      approved: {
        value: approved,
        component: (
          <Typography>{approved}</Typography>
        ),
      },
      rejected: {
        value: rejected,
        component: (
          <Typography>{rejected}</Typography>
        ),
      },
      average: {
        value: average,
        component: (
          <Typography>{average.toFixed(2)}</Typography>
        ),
      },
      stdDev: {
        value: stdDev,
        component: (
          <Typography>{stdDev.toFixed(2)}</Typography>
        ),
      },
      result: {
        value: result,
        component: (
          <Typography>{result}</Typography>
        ),
      },
      edit: {
        value: id,
        component: (
          <Button
            color="primary"
            onClick={() => {
              setAddVariableOpen(true); setSelectedVariable({
                id,
                name,
                functionName,
                variableOrder,
                threshold,
                logicalType,
                args,
              });
            }}
          >
            Editar Variable
          </Button>
        ),
      },
      deactivate: {
        value: id,
        component: (
          <Button
            color="primary"
            onClick={async () => {
              await deactivateVariable({ variables: { idVariable: id, variableType } });
              refetch();
            }}
          >
            Desactivar Variable
          </Button>
        ),
      },
      prize: {
        value: prize,
        component: (
          <Typography>{prize}</Typography>
        ),
      },
    });
  });
  const handleModalClose = () => {
    setAddVariableOpen(false);
    setSelectedVariable(undefined);
  };
  if (error) return <Error />;
  if (loading) return <Loading />;
  if (!treeVariables) return null;
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setAddVariableOpen(true)}
        >
          Agregar Variable
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => refetch()}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </div>
      <AddVariable
        open={addVariableOpen}
        onClose={handleModalClose}
        variable={selectedVariable}
        variableType={variableType}
        onSuccessSubmit={refetch}
      />
      <Table
        headers={newAllowedHeaders}
        initialOrderId="variableOrder"
        data={treeVariables}
        dataToRows={transformDataToComponents}
        setNewDataOrder={setTreeVariables}
      />
    </div>
  );
};

GenericTree.propTypes = {
  // eslint-disable-next-line
  query: PropTypes.object.isRequired,
  variableType: PropTypes.string.isRequired,
};

export default GenericTree;
