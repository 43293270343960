import React, { useState } from 'react';
import {
  Typography,
  Grid,
  CircularProgress,
  Snackbar,
  Button,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client';
import { useStyles } from './styles';
import { Table } from '../components/tables';
import { GET_COMMERCIAL_EXECUTIVES_INFORMATION, UPDATE_USER_EXECUTIVE, UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION } from '../gql/customer';
import { toPercentage } from '../helpers/generic';
import { EditExecutive, EditExecutiveProportions } from '../components/dialogs';

const useOwnStyles = makeStyles(() => ({
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  inputSearch: {
    height: 60,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const headers = [
  { id: 'executiveName', label: 'Nombre Ejecutivo', align: 'left', hideSortIcon: true },
  { id: 'active', label: 'Ejecutivo Activo', align: 'left', hideSortIcon: true },
  { id: 'tycActiveAssignation', label: 'Asignación en TyC', align: 'left', hideSortIcon: true },
  { id: 'loginActiveAssignation', label: 'Asignación en Login', align: 'left', hideSortIcon: true },
  { id: 'clickActiveAssignation', label: 'Asignación en Click', align: 'left', hideSortIcon: true },
  { id: 'monthlyAssignations', label: 'Asignaciones Mensuales', align: 'left', hideSortIcon: true },
  { id: 'hubspotMonthlyAssignations', label: 'Delta Asignaciones Mensuales (Hubspot)', align: 'left', hideSortIcon: true },
  { id: 'weeklyAssignations', label: 'Asignaciones Semanales', align: 'left', hideSortIcon: true },
  { id: 'hubspotId', label: 'ID de Hubspot', align: 'left', hideSortIcon: true },
  { id: 'clickAssignationProportion', label: 'Proporcion de Asignación (Click)', align: 'left', hideSortIcon: true },
  { id: 'loginAssignationProportion', label: 'Proporcion de Asignación (Login)', align: 'left', hideSortIcon: true },
  { id: 'edit', label: 'Editar', align: 'left', hideSortIcon: true },
];

const ExecutiveManager = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const [executives, setExecutives] = useState([]);
  const [selectedExecutive, setSelectedExecutive] = useState({
    id: null,
    firstName: null,
    lastName: null,
    active: null,
    tycActiveAssignation: null,
    loginActiveAssignation: null,
    clickActiveAssignation: null,
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditProportionsDialog, setOpenEditProportionsDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  // the limit number must match with rowsPerPage of the Table
  const { error: errorExecutivesData, loading: loadingExecutivesData, refetch: refetchExecutiveData,
  } = useQuery(GET_COMMERCIAL_EXECUTIVES_INFORMATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (_data) => { setExecutives(_data.getExecutiveAssignmentInformation); },
    });
  const [UpdateUserExecutive] = useMutation(UPDATE_USER_EXECUTIVE, {
    onCompleted: () => { refetchExecutiveData(); },
    onError: () => { setOpenSnackBar(true); },
  });
  const [UpdateUserExecutiveProportions] = useMutation(
    UPDATE_USER_EXECUTIVE_ASSIGNATION_PROPORTION, {
      onCompleted: () => { refetchExecutiveData(); },
      onError: () => { setOpenSnackBar(true); },
    },
  );

  const handleUpdateExecutive = (
    id,
    active,
    tycActiveAssignation,
    loginActiveAssignation,
    clickActiveAssignation,
  ) => {
    UpdateUserExecutive({
      variables: {
        id,
        active,
        tycActiveAssignation,
        loginActiveAssignation,
        clickActiveAssignation,
      },
    });
  };
  const handleUpdateExecutiveProportions = (
    assignationType,
    selectedExecutives,
  ) => {
    const selectedExecutivesVariable = [];
    selectedExecutives.forEach(
      (executive) => {
        if (assignationType === 'Click') {
          selectedExecutivesVariable.push({
            id: executive.user.id,
            assignationProportion: executive.clickAssignationProportion,
          });
        } else {
          selectedExecutivesVariable.push({
            id: executive.user.id,
            assignationProportion: executive.loginAssignationProportion,
          });
        }
      },
    );
    UpdateUserExecutiveProportions({
      variables: {
        assignationType,
        input: selectedExecutivesVariable,
      },
    });
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      user: {
        id,
        firstName,
        lastName,
      },
      active,
      hubspotId,
      tycActiveAssignation,
      loginActiveAssignation,
      clickActiveAssignation,
      clickAssignationProportion,
      loginAssignationProportion,
      weeklyAssignations,
      monthlyAssignations,
      hubspotMonthlyAssignations,
    } = row;
    return {
      id: null,
      executiveName: {
        value: id,
        component: <Typography>{firstName} {lastName}</Typography>,
      },
      weeklyAssignations: {
        value: weeklyAssignations,
        component: <Typography>{weeklyAssignations}</Typography>,
      },
      hubspotMonthlyAssignations: {
        value: hubspotMonthlyAssignations,
        component: <Typography>{hubspotMonthlyAssignations - monthlyAssignations}</Typography>,
      },
      monthlyAssignations: {
        value: monthlyAssignations,
        component: <Typography>{monthlyAssignations}</Typography>,
      },
      hubspotId: {
        value: hubspotId,
        component: <Typography>{hubspotId || 'No tiene ID'}</Typography>,
      },
      active: {
        value: active,
        component:
  <Typography>
    {active
      ? <CheckCircle className={classes.success} />
      : <Cancel className={classes.failure} />}
  </Typography>,
      },
      tycActiveAssignation: {
        value: tycActiveAssignation,
        component:
  <Typography>
    {tycActiveAssignation
      ? <CheckCircle className={classes.success} />
      : <Cancel className={classes.failure} />}
  </Typography>,
      },
      loginActiveAssignation: {
        value: loginActiveAssignation,
        component:
  <Typography>
    {loginActiveAssignation
      ? <CheckCircle className={classes.success} />
      : <Cancel className={classes.failure} />}
  </Typography>,
      },
      clickActiveAssignation: {
        value: clickActiveAssignation,
        component:
  <Typography>
    {clickActiveAssignation
      ? <CheckCircle className={classes.success} />
      : <Cancel className={classes.failure} />}
  </Typography>,
      },
      clickAssignationProportion: {
        value: clickAssignationProportion,
        component: <Typography>{clickAssignationProportion ? toPercentage(clickAssignationProportion) : 'n/a'}</Typography>,
      },
      loginAssignationProportion: {
        value: loginAssignationProportion,
        component: <Typography>{loginAssignationProportion ? toPercentage(loginAssignationProportion) : 'n/a'}</Typography>,
      },
      edit: {
        value: 0,
        component: (
          <>
            <IconButton
              onClick={() => {
                setSelectedExecutive({
                  id,
                  firstName,
                  lastName,
                  active,
                  tycActiveAssignation,
                  loginActiveAssignation,
                  clickActiveAssignation,
                });
                setOpenEditDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </>),
      },
    };
  });

  if (errorExecutivesData) {
    return (
      <Typography variant="h3" style={{ margin: '2rem' }}>
        Hubo un error al cargar los ejecutivos. Intenta más tarde
      </Typography>
    );
  }
  if (loadingExecutivesData) {
    return <CircularProgress size={16} />;
  }
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4">
          <b>Gestor de Ejecutivos</b>
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenEditProportionsDialog(true)}
        >
          Editar Prop. de Asignaciones
        </Button>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Table
            data={executives}
            setNewDataOrder={setExecutives}
            dataToRows={transformDataToComponents}
            headers={headers}
          />
        </Grid>
      </Grid>
      <EditExecutive
        open={openEditDialog}
        selectedExecutive={selectedExecutive}
        handleUpdateExecutive={handleUpdateExecutive}
        handleClose={() => {
          setSelectedExecutive({
            id: null,
            firstName: null,
            lastName: null,
            active: null,
            tycActiveAssignation: null,
            loginActiveAssignation: null,
            clickActiveAssignation: null,
          });
          setOpenEditDialog(false);
        }}
      />
      <EditExecutiveProportions
        open={openEditProportionsDialog}
        executives={executives}
        handleUpdateExecutive={handleUpdateExecutiveProportions}
        handleClose={() => {
          setOpenEditProportionsDialog(false);
        }}
      />
      <Snackbar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message="Hubo un error al actualizar el ejecutivo. Contactate con desarrollo."
        disableWindowBlurListener
        autoHideDuration={5000}
      />
    </div>
  );
};

export default ExecutiveManager;
