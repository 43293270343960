import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { TablePaginationBackend } from '../components/tables';
import { Filters } from '../components/utils';

import useFilteredQuery from '../helpers/useFilteredQuery';
import { GET_RATIFICATION_EXECUTIVES, GET_COLLECTION_EXECUTIVES } from '../gql/customer';
import { ASSIGN_EXECUTIVE_TO_DEBTOR, GET_DEBTORS } from '../features/debtors/gql/debtors';

const useOwnStyles = makeStyles(() => ({
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  inputSearch: {
    height: 60,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const headers = [
  { id: 'companyName', label: 'Razón social', align: 'left', hideSortIcon: false },
  { id: 'companyRut', label: 'Rut', align: 'left', hideSortIcon: false },
  {
    id: 'debtorRatificationExecutive',
    label: 'Ejecutivo Ratificador asignado',
    align: 'left',
    hideSortIcon: true,
  },
  {
    id: 'debtorCollectorExecutive',
    label: 'Ejecutivo Cobrador asignado',
    align: 'left',
    hideSortIcon: true,
  },
];

const DebtorAdmin = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const [isControlled, setIsControlled] = useState(true);
  const [numberRows] = useState(50);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [ratificatorExecList, setRatificatorExecList] = useState([]);
  const [collectorExecList, setCollectorExecList] = useState([]);
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [debtorInfo, setDebtorInfo] = useState({});
  const [changedDebtorsId, setChangedDebtorsId] = useState([]);
  const [errorOperation, setErrorOperation] = useState(false);

  const [objectOrder, setObjectOrder] = useState({
    property: 'companyName',
    order: 'desc',
  });

  const { usableData: debtorsData,
    refetch: refetchDebtors,
    loading: loadingDebtors,
  } = useFilteredQuery(
    GET_DEBTORS, {
      variables: {
        first: numberRows,
        offset: page * numberRows,
        globalFilter,
        orderBy,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: ratExecData,
  } = useQuery(GET_RATIFICATION_EXECUTIVES,
    { fetchPolicy: 'network-only' });

  const { data: colExecData,
  } = useQuery(GET_COLLECTION_EXECUTIVES,
    { fetchPolicy: 'network-only' });

  const [assignExecutiveToDebtor] = useMutation(ASSIGN_EXECUTIVE_TO_DEBTOR);

  useEffect(() => {
    if (ratExecData && ratExecData.getRatificationExecutives) {
      const executives = ratExecData.getRatificationExecutives.map((ex) => (
        { label: ex.firstName, value: ex.id }));
      setRatificatorExecList([...executives, { label: 'Seleccionar ejecutivo', value: null }]);
    }
  }, [ratExecData]);

  useEffect(() => {
    if (colExecData && colExecData.getCollectionExecutives) {
      const executives = colExecData.getCollectionExecutives.map((ex) => (
        { label: ex.firstName, value: ex.id }));
      setCollectorExecList([...executives, { label: 'Seleccionar ejecutivo', value: null }]);
    }
  }, [colExecData]);

  useEffect(() => {
    if (debtorsData) {
      const debtorsEdges = debtorsData.getDebtors.edges;
      const newDebtors = [];
      const newDebtorsInfo = {};
      debtorsEdges.map((debtor) => {
        newDebtorsInfo[debtor.node.masterEntity.rut] = debtor.node;
        newDebtors.push(debtor.node);
        return null;
      });
      setDebtorInfo({ ...debtorInfo, ...newDebtorsInfo });
      setFilteredDebtors(newDebtors);
    }
  }, [debtorsData]);

  // eslint-disable-next-line consistent-return
  const handleConfirmModal = async () => {
    let debtorsRatificatorCollector = [];
    changedDebtorsId.forEach((debtorId) => {
      debtorsRatificatorCollector = [...debtorsRatificatorCollector, { debtorId,
        // eslint-disable-next-line max-len
        ratificatorId: debtorInfo[debtorId].ratificator ? debtorInfo[debtorId].ratificator.id : null,
        collectorId: debtorInfo[debtorId].collector ? debtorInfo[debtorId].collector.id : null }];
    });

    try {
      await assignExecutiveToDebtor(
        {
          variables: {
            companyRatificatorCollector: debtorsRatificatorCollector,
          },
        },
      );
      refetchDebtors();
    } catch (_error) {
      setErrorOperation(true);
      if (errorOperation) {
        return (
          <Typography variant="h3" style={{ margin: '2rem' }}>
            Hubo un error al guardar los cambios. Intenta más tarde
          </Typography>
        );
      }
      refetchDebtors();
    }
  };

  const newRequestWithOrder = (property) => {
  // newOrder can be 'asc' or 'desc'
    const orderBool = objectOrder.order === 'asc';
    const order = orderBool ? 'desc' : 'asc';
    setObjectOrder({ property, order });
  };

  const handleChange = async (e, masterEntityId) => {
    const { target: { value, name } } = e;
    setChangedDebtorsId([...changedDebtorsId, masterEntityId]);
    setDebtorInfo({
      ...debtorInfo,
      [masterEntityId]: {
        ...debtorInfo[masterEntityId],
        [name]: { id: value },
      },
    });
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      masterEntity,
    } = row;
    return {
      id: masterEntity.id,
      companyRut: {
        value: masterEntity.rut,
        component: <Typography>{formatRut(masterEntity.rut)}</Typography>,
      },
      companyName: {
        value: masterEntity.name,
        component: (
          <Tooltip
            interactive
            classes={{ tooltip: classes.tooltip }}
            title={masterEntity.name}
          >
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {masterEntity.name}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      debtorRatificationExecutive: {
        component: (
          <Select
            id="debtorRatificationExecutive"
            name="ratificator"
            value={debtorInfo[masterEntity.rut].ratificator
              ? debtorInfo[masterEntity.rut].ratificator.id : 'Seleccione'}
            onChange={(e) => handleChange(e, masterEntity.id, 'ratificator')}
            variant="outlined"
            className={classes.select}
          >
            <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un ratificador</MenuItem>
            {ratificatorExecList.map((_executive) => (
              <MenuItem value={_executive.value} key={_executive.value}>
                {_executive.label}
              </MenuItem>
            ))}
          </Select>

        ),
      },
      debtorCollectorExecutive: {
        component: (
          <Select
            id="debtorCollectorExecutive"
            name="collector"
            value={debtorInfo[masterEntity.rut].collector
              ? debtorInfo[masterEntity.rut].collector.id : 'Seleccione'}
            onChange={(e) => handleChange(e, masterEntity.id, 'collector')}
            variant="outlined"
            className={classes.select}
          >
            <MenuItem key="Seleccione" value="Seleccione" disabled>Seleccione un cobrador</MenuItem>
            {collectorExecList.map((_executive) => (
              <MenuItem value={_executive.value} key={_executive.value}>
                {_executive.label}
              </MenuItem>
            ))}
          </Select>

        ),
      },
    };
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4">
          <b>Asignación de ejecutivos a deudores</b>
        </Typography>
        <div className={classes.headerButtonsContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmModal}
            disabled={false}
          >
            Guardar
          </Button>
        </div>
      </div>
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.searchContainer}>
          <Grid item xs={6} sm={6} className={classes.searchBar}>
            <Filters
              allData={filteredDebtors}
              setFilteredData={setFilteredDebtors}
              searchFields={['companyName', 'companyRut']}
              searchPlaceholder="Búsqueda por rut o deudor"
              isControlled={{ isControlled, setGlobalFilter }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={loadingDebtors ? classes.loading : classes.tableContainer}>
          {!loadingDebtors ? (
            <TablePaginationBackend
              withSelect
              data={filteredDebtors}
              dataToRows={transformDataToComponents}
              setNewDataOrder={setFilteredDebtors}
              headers={[...headers]}
              initialOrderId="companyName"
              lengthData={debtorsData.getDebtors.totalCount}
              newRequestWithOrder={newRequestWithOrder}
              objectOrder={objectOrder}
              loading={loadingDebtors}
              controlled={{
                isControlled,
                setIsControlled,
                setOrderBy,
                totalPages: debtorsData.getDebtors.totalPages,
                setPage,
                page,
              }}
            />
          ) : (
            <LinearProgress />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DebtorAdmin;
