import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Grid, CircularProgress } from '@material-ui/core';
import { useMutation } from '@apollo/client';
// import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Table } from '../tables';
import { formatMoney } from '../../helpers/money';
import { useStyles } from '../../views/styles';
import { CREATE_REINTEGRATION } from '../../gql/moneyTransfer';
import DebtorCell from '../shared/DebtorCell';

const pendingReintegrationHeaders = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'receiver', label: 'Deudor', align: 'left' },
  { id: 'amount', label: 'Monto', align: 'right' },
  { id: 'dateToPay', label: 'Fecha de pago', align: 'left' },
];

const ReintegrateInvoices = (props) => {
  const {
    openDialog,
    setOpenDialog,
    pendingReintegrationInvoices,
    amountAvailableToReintegrate,
    payingInvoicesIds,
  } = props;

  const classes = {
    ...useStyles(),
  };
  const [availableAmount, setAvailableAmount] = useState(amountAvailableToReintegrate);
  const [
    selectedPendingReintegrationInvoices, setSelectedPendingReintegrationInvoices,
  ] = useState([]);
  const [createReintegration] = useMutation(CREATE_REINTEGRATION);
  const [loadingCreation, setLoadingCreation] = useState(false);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      company,
      rutReceiver,
      nameReceiver,
      amountWithIva,
      dateToPay,
    } = row;

    return ({
      id,
      companyRut: company.rut,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography>{folio}</Typography>
          </div>
        ),
      },
      receiver: {
        value: rutReceiver,
        // <div style={{ maxWidth: 200 }}>
        //   <Typography className={classes.oneLineClamp} variant="body2">
        //     {nameReceiver}
        //   </Typography>
        //   <Typography variant="body2">
        //     {formatRut(rutReceiver)}
        //   </Typography>
        // </div>
        component: <DebtorCell name={nameReceiver} rut={rutReceiver} />,
      },
      amount: {
        value: amountWithIva,
        component: (
          <Typography align="right">${formatMoney(amountWithIva)}</Typography>
        ),
      },
      dateToPay: {
        value: dateToPay,
        component: (
          <Typography align="right">{moment(dateToPay).format('DD-MM-YYYY')}</Typography>
        ),
      },
    });
  });

  const handleSelectedInvoice = (_selectedRows) => {
    const totalAmountToBeReintegrated = _selectedRows.reduce(
      (acc, row) => acc + row.amount.value, 0,
    );
    setAvailableAmount(amountAvailableToReintegrate - totalAmountToBeReintegrated);
    setSelectedPendingReintegrationInvoices(_selectedRows);
  };

  const handleCreateReintegrations = async () => {
    const owedInvoicesIds = selectedPendingReintegrationInvoices.map(({ id }) => id);
    try {
      setLoadingCreation(true);
      await createReintegration({
        variables: {
          owedInvoicesIds,
          payingInvoicesIds,
        },
      });
      setLoadingCreation(false);
      setOpenDialog(false);
    } catch (e) {
      // pass
    }
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="confirmation-dialog-title"
      maxWidth="md"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">
          Reintegro de facturas
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Table
          withSelect
          handleSelected={handleSelectedInvoice}
          data={pendingReintegrationInvoices}
          setNewDataOrder={() => {}}
          dataToRows={transformDataToComponents}
          headers={pendingReintegrationHeaders}
          pagination={false}
          initialOrderId="folio"
        />
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.confirmationDifference}>
              Monto disponible para reintegro: ${formatMoney(availableAmount)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCreateReintegrations}
          disabled={loadingCreation}
          endIcon={loadingCreation ? <CircularProgress size={16} /> : null}
        >
          Crear reintegros
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenDialog(!openDialog)}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReintegrateInvoices.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  pendingReintegrationInvoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amountAvailableToReintegrate: PropTypes.number.isRequired,
  payingInvoicesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ReintegrateInvoices;
