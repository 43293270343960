import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { SmallUserAvatarWithName } from '../utils';

const useStyles = makeStyles({
  actionContainer: {
    padding: '1rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#ABABAB',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    overflowWrap: 'break-word',
  },
  actionInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const actionTypeTitle = {
  PHONE_CALL_TO_RECEIVER: 'Llamado telefónico a receptor del documento:',
  PHONE_CALL_TO_ISSUER: 'Llamado telefónico a emisor del documento:',
  MAIL_TO_ISSUER: 'Mail al emisor, enviado a:',
  MAIL_TO_RECEIVER: 'Mail al receptor, enviado a:',
  RECEIVER_FORM_MAIL: 'Formulario de pago enviado a:',
  ISSUER_FORM_MAIL: 'Formulario de pago enviado a:',
  AUTOMATIC_MAIL_TO_RECEIVER: 'Mail automático al receptor del documento:',
};

const actionTypeContact = {
  PHONE_CALL_TO_RECEIVER: 'phoneNumber',
  PHONE_CALL_TO_ISSUER: 'phoneNumber',
  MAIL_TO_ISSUER: 'email',
  MAIL_TO_RECEIVER: 'email',
  RECEIVER_FORM_MAIL: 'email',
  ISSUER_FORM_MAIL: 'email',
  AUTOMATIC_MAIL_TO_RECEIVER: 'email',
};

const CollectionAction = ({ action }) => {
  const classes = useStyles();
  const { actionType, createdAt, collectionContacts, comment, author } = action;
  const authorName = author ? `${author.firstName} ${author.lastName}` : '';
  const creationDate = moment(createdAt).format('DD/MM/YYYY HH:mm');

  const getActionContent = () => {
    if (actionType === 'OTHER') {
      return <Typography>{comment}</Typography>;
    }
    const actionTitle = actionTypeTitle[actionType];
    return (
      <>
        <Typography>{actionTitle}</Typography>
        <ul>
          { collectionContacts && collectionContacts.map((contact) => (
            <li key={contact.id}>
              <Typography>
                {`${contact.firstName} ${contact.lastName} - ${contact[actionTypeContact[actionType]]}`}
              </Typography>
            </li>
          ))}
        </ul>
        {comment && <Typography variant="subtitle2">{comment}</Typography>}
      </>
    );
  };

  return (
    <div className={classes.actionContainer}>
      <div className={classes.actionInfoContainer}>
        <SmallUserAvatarWithName name={authorName} />
        <Typography variant="caption">{creationDate}</Typography>
      </div>
      {getActionContent()}
    </div>
  );
};

CollectionAction.propTypes = {
  action: PropTypes.shape({
    actionType: PropTypes.string.isRequired,
    comment: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    collectionContacts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })).isRequired,
    author: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CollectionAction;
