import React from 'react';
import { Typography } from '@material-ui/core';
import { Build } from '@material-ui/icons';

const UnderConstruction = () => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
    <Build style={{ fontSize: 50 }} />
    <Typography variant="h3">Componente en construcción</Typography>
  </div>
);

export default UnderConstruction;
