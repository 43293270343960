import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { CheckSharp, Star } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import { AddNewContact, EditContact } from '../contacts';
import { GET_COMPANY_RATIFICATION_CONTACTS } from '../../gql/ratification';

const headers = [
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'info', label: 'Información', align: 'left' },
  { id: 'contact', label: 'Contacto', align: 'left' },
  { id: 'rates', label: 'Tasas', align: 'left' },
  { id: 'actions', label: 'Acciones', align: 'left' },
];
const useStyles = makeStyles({
  addContactContainer: {
    textAlign: 'center',
  },
  container: {
    margin: '2%',
  },
});

const RatificationContactsTable = ({ companyRut, companyName, onSelect }) => {
  const { data: contactsData, refetch: contactsRefetch } = useQuery(
    GET_COMPANY_RATIFICATION_CONTACTS,
    { variables: { companyRut }, fetchPolicy: 'network-only' },
  );
  const [contacts, setContacts] = useState();
  const [openEditContact, setOpenEditContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState();
  const classes = useStyles();
  const handleEditContact = (row) => {
    setContactToEdit(row);
    setOpenEditContact(true);
  };
  useEffect(() => {
    if (contactsData && contactsData.getCompanyRatificationContacts) {
      setContacts(contactsData.getCompanyRatificationContacts);
    }
  }, [contactsData]);
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      firstName,
      lastName,
      position,
      email,
      phoneNumber,
      ratifies,
      verified,
      ratificationContactStats: {
        mails,
        mailsResponded,
        mailsResponseRate,
        phoneCalls,
        phoneCallsResponded,
        phoneCallsResponseRate,
      },
    } = row;
    const ratesTooltipLabel = `
    N° de mails enviados: ${mails}
    N° de mails respondidos: ${mailsResponded}

    N° de llamados telefónicos: ${phoneCalls}
    N° de llamados respondidos: ${phoneCallsResponded}
    `;
    return ({
      id,
      name: {
        component: (
          <>
            <Typography variant="body2">{firstName}</Typography>
            <Typography variant="body2">{lastName}</Typography>
            <Typography variant="caption"><b>{position || 'Sin cargo'}</b></Typography>
          </>
        ),
        value: firstName,
      },
      info: {
        component: (
          <>
            { verified ? (
              <Typography variant="body2" align="center"><CheckSharp fontSize="small" style={{ color: 'lightGreen', top: 10 }} />Contacto verificado</Typography>
            ) : (
              <Typography variant="body2" align="center">No verificado</Typography>
            )}
            { ratifies ? (
              <Typography variant="body2" align="center"><Star fontSize="small" style={{ color: 'orange' }} />Contacto ratifica</Typography>
            ) : (
              <Typography variant="body2" align="center">No ratifica</Typography>
            )}
          </>
        ),
      },
      contact: {
        component: (
          <>
            <Typography variant="body2">{email || 'Sin email'}</Typography>
            <Typography variant="body2">{phoneNumber || 'Sin telefono'}</Typography>
          </>
        ),
        value: position,
      },
      rates: {
        component: (
          <Tooltip title={(
            <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body2">
              {ratesTooltipLabel}
            </Typography>
          )}
          >
            <Box>
              <Typography variant="body2"><b>Tasa mails: {mailsResponseRate.toFixed(2) * 100}%</b></Typography>
              <Typography variant="body2"><b>Tasa teléfono: {phoneCallsResponseRate.toFixed(2) * 100}%</b></Typography>
            </Box>
          </Tooltip>
        ),
      },
      actions: {
        component: (
          <>
            <Button color="primary" onClick={() => handleEditContact(row)}>Editar</Button>
          </>
        ),
      },
    });
  });
  return (
    <Box className={classes.container}>
      { contacts ? (
        <Table
          data={contacts}
          dataToRows={transformDataToComponents}
          headers={headers}
          withSelect={Boolean(onSelect)}
          pagination={false}
          setNewDataOrder={setContacts}
          handleSelected={onSelect}
        />
      ) : (
        <CircularProgress />
      )}
      <Grid className={classes.addContactContainer}>
        <AddNewContact
          companyName={companyName}
          companyRut={companyRut}
          type="RATIFICATION"
          contactsRefetch={contactsRefetch}
        />
      </Grid>
      <EditContact
        companyName={companyName}
        companyRut={companyRut}
        openForm={openEditContact}
        setOpenForm={setOpenEditContact}
        contact={contactToEdit}
        type="RATIFICATION"
      />
    </Box>
  );
};

RatificationContactsTable.propTypes = {
  companyRut: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default RatificationContactsTable;
