import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Grid, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { GET_PENDING_DOCUMENT_ORDERS, SEND_ASSIGNMENT_DOCUMENT } from '../gql/ordering';
import { useStyles } from './styles';
import { parseDate } from '../helpers/date';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import { Table } from '../components/tables';
import SendContractDialog from '../components/ordering/sendContractDialog';

const headers = [
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'purchaser', label: 'Comprador', align: 'left' },
  { id: 'orderNumber', label: 'Número órden', align: 'left', hideSortIcon: true },
  { id: 'totalAmount', label: 'Monto Orden', align: 'left', hideSortIcon: true },
  { id: 'operationDate', label: 'Día de operación', align: 'left' },
  { id: 'uploadButton', label: '', align: 'right', hideSortIcon: true },
];

const OrderingPendingDocuments = () => {
  const classes = useStyles();
  const { loading: loadingData, error, data } = useQuery(GET_PENDING_DOCUMENT_ORDERS);
  const [sendAssignmentDocument] = useMutation(SEND_ASSIGNMENT_DOCUMENT, {
    refetchQueries: [
      { query: GET_PENDING_DOCUMENT_ORDERS },
    ] });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(loadingData);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [clickedOrder, setClickedOrder] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [representativeInputs, setRepresentativeInputs] = useState({
    name: {
      value: '',
    },
    rut: {
      value: '',
    },
    email: {
      value: '',
    },
  });

  const handleSendDocument = () => {
    const variablesToSend = {
      variables: {
        orderId: clickedOrder.id,
        representativeName: representativeInputs.name.value,
        representativeRut: representativeInputs.rut.value,
        representativeEmail: representativeInputs.email.value,
      },
    };
    sendAssignmentDocument(variablesToSend);
    setRepresentativeInputs({
      name: {
        value: '',
      },
      rut: {
        value: '',
      },
      email: {
        value: '',
      },
    });
    setLoadingDocument(true);
  };
  const handleClick = (row) => {
    setClickedOrder(row);
    setOpenDialog(true);
  };

  const handleInputChange = (e) => {
    const { name: nameTarget, value } = e.target;
    setRepresentativeInputs((oldState) => ({
      ...oldState,
      [nameTarget]: { ...oldState[nameTarget], error: '', value },
    }));
  };
  const handleClose = () => {
    setRepresentativeInputs({
      name: {
        value: '',
      },
      rut: {
        value: '',
      },
      email: {
        value: '',
      },
    });
    setOpenDialog(false);
  };

  useEffect(() => {
    if (data) {
      setOpenDialog(false);
      setOrders(data.getPendingDocumentOrders);
      setLoading(false);
      setLoadingDocument(false);
    }
  }, [data]);

  const transformDataToComponents = (dataset) => dataset.map((row) => {
    const {
      id,
      orderNumber,
      totalAmount,
      company: {
        rut: companyRut,
        name: companyName,
      },
      purchaser: {
        rut: purchaserRut,
        name: purchaserName,
      },
      operationDate,
    } = row;

    return ({
      id,
      client: {
        value: companyName,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={companyName}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {companyName}
              </Typography>
              <Typography>
                {formatRut(companyRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      purchaser: {
        value: purchaserRut,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={purchaserRut}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {purchaserName}
              </Typography>
              <Typography>
                {formatRut(purchaserRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      orderNumber: {
        value: orderNumber,
        component: (
          <Typography align="left">{orderNumber}</Typography>
        ),
      },
      totalAmount: {
        value: totalAmount,
        component: (
          <Typography align="left">${formatMoney(totalAmount)}</Typography>
        ),
      },
      operationDate: {
        value: operationDate,
        component: (
          <Typography align="left">{parseDate(operationDate)}</Typography>
        ),
      },
      uploadButton: {
        value: null,
        component: (
          <Button
            name="CONFIRMDOCUMENT"
            size="small"
            style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
            component="span"
            color="primary"
            variant="contained"
            onClick={() => handleClick(row)}
          >
            Enviar contrato
          </Button>
        ),
      },
    });
  });

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;
  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.titleAndUpdate}>
            <Typography variant="h4"><b>Ordering con documentos de cesión pendientes</b></Typography>
          </div>
        </div>
        {orders.length > 0
          ? (
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="id"
                  setNewDataOrder={setOrders}
                  data={orders}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography align="center" variant="h5">No hay órdenes pendientes de documento</Typography>
          )}
      </div>
      <SendContractDialog
        order={clickedOrder}
        openDialog={openDialog}
        loading={loadingDocument}
        handleClose={handleClose}
        handleChange={handleInputChange}
        handleClick={handleSendDocument}
        representativeInputs={representativeInputs}
      />
    </>
  );
};

export default OrderingPendingDocuments;
