import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  RadioGroup,
  Radio,
  DialogContent,
  DialogTitle,
  Typography,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Checkbox,
  Grid,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_RISK_PDF_LINK,
  PREEVALUATION_EMAIL_SEND,
  GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW,
} from '../../gql/riskAlgorithm';
import AllRestrictionsWarning from '../companyRestrictions/AllRestrictionsWarning';

const useStyles = makeStyles({
  'wrapper-class': {
    padding: '1rem',
    border: '1px solid #ccc',
  },
  'editor-class': {
    'background-color': 'white',
    padding: '1rem',
    border: '1px solid #ccc',
  },
  'toolbar-class': {
    border: '1px solid #ccc',
  },
});

const EmailDialog = ({ open, handleClose, invoiceIds, clients }) => {
  const classes = useStyles();
  const [acceptPreoffer, setAcceptPreoffer] = useState(null);
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [emailSending, setEmailSending] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const html = '';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  const [description, setDescription] = useState(editorState);
  const [automaticEmailPreview, setAutomaticEmailPreview] = useState('');
  const handleCloseDialog = () => {
    setAcceptPreoffer(null);
    setEmailSending('');
    setDescription(EditorState.createWithContent(contentState));
    handleClose(false);
  };
  const [sendEvaluationEmail, { error: errorMailSend, loading: loadingMailSend }] = useMutation(
    PREEVALUATION_EMAIL_SEND,
    {
      onCompleted: () => {
        handleCloseDialog();
      },
    },
  );
  const [getEmailPreview, { error: errorPreview, loading: loadingPreview }] = useLazyQuery(
    GENERATE_RISK_PDF_LINK,
    {
      variables: { invoiceIds },
      onCompleted: (data) => {
        window.open(data.generateRiskPdfLink.preview);
      },
    },
  );
  const { error: errorEmailPreview, loading: loadingEmailPrevew } = useQuery(
    GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW,
    {
      skip: !invoiceIds.length || !open,
      variables: { invoiceIds },
      onCompleted: (data) => {
        setAutomaticEmailPreview(data.getAutomaticResponseEmailPreview.preview);
      },
    },
  );

  const handleAcceptPreoffer = (e) => {
    setAcceptPreoffer(e.target.value === 'true');
  };

  const handleCheckbox = (e) => {
    setIsPrivate(e.target.checked);
  };

  useEffect(() => {
    if (acceptPreoffer === false && emailSending !== '') {
      setDisableConfirmButton(false);
    } else if (emailSending === '') {
      setDisableConfirmButton(true);
    } else {
      setDisableConfirmButton(false);
    }
  }, [acceptPreoffer]);

  const handleConfirmPreoffer = () => {
    let finalHtml = draftToHtml(convertToRaw(description.getCurrentContent()));
    finalHtml = `<div>${finalHtml}</div>`;
    sendEvaluationEmail({
      variables: {
        invoiceIds,
        acceptPreoffer,
        finalDescription: finalHtml,
        emailSending,
        isPrivate,
      },
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="s"
      fullWidth
      onClose={() => { handleCloseDialog(); }}
    >
      <DialogTitle style={{ margin: '1.5rem' }}>
        <Typography variant="h3">Envío de email de preevaluación</Typography>
        <AllRestrictionsWarning clients={clients} align="left" />
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ padding: '1.5rem' }} spacing={4}>
          <Grid item>
            {automaticEmailPreview && (
              <div>
                {parse(automaticEmailPreview)}
                <br />
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                getEmailPreview();
              }}
              disabled={loadingPreview}
            >
              Generar PDF
            </Button>
            {(loadingPreview || loadingEmailPrevew) && <CircularProgress size={18} />}
            {errorEmailPreview && (
              <Typography>No se pudo generar el mensaje. Contactate con soporte</Typography>
            )}
            {errorPreview && (
              <Typography>No se pudo generar el PDF. Contactate con soporte</Typography>
            )}
          </Grid>
          <Grid item>
            <Typography>Comentarios</Typography>
            <Editor
              editorState={description}
              toolbarClassName={classes['toolbar-class']}
              wrapperClassName={classes['wrapper-class']}
              editorClassName={classes['editor-class']}
              onEditorStateChange={setDescription}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
          style={{ padding: '1.5rem' }}
        >
          <Grid item style={{ backgroundColor: '#D3D3D3', padding: '2rem', borderRadius: '1rem' }}>
            <Typography variant="h6">¿A aquién enviar?</Typography>
            <RadioGroup
              value={emailSending}
              onChange={(e) => {
                setEmailSending(e.target.value);
              }}
            >
              <FormControlLabel key="jmg" value="jmg" control={<Radio />} label="Gerente" />
              <FormControlLabel
                key="rocio"
                value="rocio"
                control={<Radio />}
                label="Jefe de riesgo"
              />
              <FormControlLabel
                key="natalia"
                value="natalia"
                control={<Radio />}
                label="Analista de riesgo"
              />
            </RadioGroup>
          </Grid>
          <Grid item style={{ backgroundColor: '#D3D3D3', padding: '2rem', borderRadius: '1rem' }}>
            <Typography variant="h6">Aceptar</Typography>
            <RadioGroup
              value={acceptPreoffer}
              onChange={(e) => { handleAcceptPreoffer(e); }}
            >
              <FormControlLabel key="true" value control={<Radio />} label="Si" />
              <FormControlLabel key="false" value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item style={{ backgroundColor: '#D3D3D3', padding: '2rem', borderRadius: '1rem' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isPrivate} onClick={handleCheckbox} name="responded" />}
                label="¿Mail privado?"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loadingMailSend && <CircularProgress size={18} />}
        {errorMailSend && (
          <Typography>No se pudo generar el PDF. Conatctate con soporte</Typography>
        )}
        <Button
          onClick={() => {
            handleConfirmPreoffer();
          }}
          disabled={disableConfirmButton || loadingMailSend}
          color="primary"
          variant="contained"
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      rut: PropTypes.number,
      socialReason: PropTypes.string,
    }),
  ).isRequired,
};

export default EmailDialog;
