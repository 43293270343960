import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, InputLabel, MenuItem, Select, FormControl } from '@material-ui/core/';

const useStyles = makeStyles(() => createStyles({
  margin: {
    margin: 0,
  },
  select: {
    maxWidth: 400,
    height: 40,
  },
  container: {
    display: 'flex',
    alignItems: 'center',

  },

}));

export default function SelectForm({ reasons, handleSelectOther, inputText, onChange, name }) {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [otherField, setOtherField] = useState(false);
  const [otherReason, setOtherReason] = useState('Otro');
  const handleChange = (event) => {
    setReason(event.target.value);
    onChange(event);
  };
  const handleChangeOther = (event) => {
    setOtherReason(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    if (reason === 'OTHER') {
      handleSelectOther(true);
      setOtherField(true);
    } else {
      setOtherField(false);
    }
  }, [reason]);
  return (
    <div className={classes.container}>
      <FormControl className={classes.margin}>
        <InputLabel id="reason"> </InputLabel>
        <Select
          labelId="reason"
          id="id-select"
          value={reason}
          name={name}
          onChange={handleChange}
          className={classes.select}
          variant="outlined"
          displayEmpty
        >
          <MenuItem value="" disabled>
            {inputText}
          </MenuItem>
          {reasons.map((_reason) => (
            <MenuItem value={_reason.value} key={_reason.value}>
              {_reason.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {otherField && (
      <TextField
        required
        label="Obligatorio"
        className={classes.select}
        value={otherReason}
        name="otherRejectionReason"
        variant="outlined"
        multiline
        rowsMax={2}
        onChange={handleChangeOther}
        size="small"
        style={{ marginLeft: 20 }}
      />
      )}
    </div>
  );
}

SelectForm.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
  handleSelectOther: PropTypes.func,
  inputText: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

SelectForm.defaultProps = {
  handleSelectOther: () => {},
  inputText: '',
  onChange: () => {},
  name: '',
};
