import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Announcement, Block } from '@material-ui/icons';
import { formatRut } from '../../helpers/generic';
import { useStyles } from '../../views/styles';
import { formatMoney } from '../../helpers/money';

const DebtorCell = ({
  name,
  variant,
  debtorId,
  rut,
  hasRiskBlacklist,
  hasRatificationBlacklist,
  creditLine,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const goToDebtorsProfile = () => history.push({
    pathname: `/app/risk/debtor-panel/${rut}`,
    state: { rut, name, debtorId },
  });
  const tooltipContent = (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
    >
      <Typography variant={variant}>
        {name}
      </Typography>
      {creditLine && (
        <Grid
          container
          item
          direction="column"
        >
          <Typography variant={variant}>
            Línea de crédito disponible:{' '}
            ${formatMoney(creditLine?.limitAmount - creditLine?.creditUsed)}
          </Typography>
          <Typography variant={variant}>
            Línea de crédito total:{' '}
            ${formatMoney(creditLine?.limitAmount)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
  return (
    <Tooltip
      interactive
      classes={{ tooltip: classes.tooltip }}
      title={tooltipContent}
      onClick={goToDebtorsProfile}
    >
      <div style={{ minWidth: 200 }}>
        <Typography
          className={classes.oneLineClamp}
          variant={variant}
        >
          {name}
        </Typography>
        {hasRatificationBlacklist && (
          <Tooltip title="Este deudor no ratifica">
            <Announcement fontSize="small" className={classes.noRatificationIcon} />
          </Tooltip>
        )}
        <Typography variant={variant}>
          {formatRut(rut)} {hasRiskBlacklist && (
            <Tooltip title="Blacklist">
              <Block fontSize="small" className={classes.blacklistIcon} />
            </Tooltip>
          )}
        </Typography>
      </div>
    </Tooltip>
  );
};

DebtorCell.propTypes = {
  name: PropTypes.string.isRequired,
  rut: PropTypes.number.isRequired,
  variant: PropTypes.string,
  hasRiskBlacklist: PropTypes.bool,
  hasRatificationBlacklist: PropTypes.bool,
  creditLine: PropTypes.shape({
    creditUsed: PropTypes.number,
    limitAmount: PropTypes.number,
  }),
  debtorId: PropTypes.string,
};

DebtorCell.defaultProps = {
  variant: 'body2',
  hasRiskBlacklist: false,
  hasRatificationBlacklist: false,
  creditLine: null,
  debtorId: null,
};

export default DebtorCell;
