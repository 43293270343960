import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useMutation } from '@apollo/client';
import { UPLOAD_INVOICE_DOCUMENTS } from '../../gql/customer';

const useStyles = makeStyles({
  contentContainer: {
    Width: 400,
  },
  rowFile: {
    marginRight: '2rem',
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 10,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  responseText: {
    height: 24,
    fontSize: 11,
    maxWidth: 300,
    lineHeight: '0.8rem !important',
    marginLeft: '2rem',
    marginBottom: '1rem',
  },
  loadingButton: {
    height: '2em',
    borderRadius: '0.5em',
    padding: '0 30px',
  },
  exitButton: {
    height: '2em',
    borderRadius: '0.5em',
    padding: '0 30px',
    marginRight: '16em',
  },
});

const documentOptions = [
  { label: 'PDF Factura', value: 'PDF' },
  { label: 'Factura Timbrada/firmada', value: 'SIGNED_INVOICE' },
  { label: 'Guía de Despacho', value: 'SHIPPING_GUIDEBOOK' },
  { label: 'OC', value: 'PURCHASE_ORDER' },
  { label: 'HES', value: 'HES' },
  { label: 'Estado de Pago', value: 'PAYMENT_STATUS' },
  { label: 'Pantallazo Portal', value: 'PORTAL_SCREENSHOT' },
  { label: 'Otros', value: 'OTHER' },
];

const UploadDocumentsManager = ({
  refetchPage,
  open,
  handleClose,
  invoiceId,

}) => {
  const classes = useStyles();
  const [documents, setDocuments] = useState([{
    name: '',
    documentType: '',
    file: '',
  }]);
  const [uploadFilesMessage, setUploadFilesMessage] = useState('');
  const [uploadFiles,
    {
      loading: loadingUploadFiles,
    },
  ] = useMutation(UPLOAD_INVOICE_DOCUMENTS, {
    onCompleted: () => {
      setUploadFilesMessage('Documentos cargados correctamente');
      refetchPage();
    },
  });

  const setDocumentType = (e, i) => {
    const { value } = e.target;
    const newDocuments = [...documents];
    newDocuments[i].documentType = value;
    setDocuments(newDocuments);
  };

  const handleAddDocumentInput = () => {
    const newDocuments = [...documents];
    newDocuments.push({
      name: '',
      documentType: '',
      file: '',
    });
    setDocuments(newDocuments);
  };

  const handleRemoveDocument = (i) => {
    const newDocuments = documents.slice(0, i).concat(documents.slice(i + 1, documents.length));
    setDocuments(newDocuments);
  };

  const handleAddDocument = (file, i) => {
    const newDocuments = [...documents];
    newDocuments[i].name = file.name;
    newDocuments[i].file = file;
    setDocuments(newDocuments);
  };

  const handleCloseButton = () => {
    handleClose();
    setUploadFilesMessage('');
    setDocuments([{
      name: '',
      documentType: '',
      file: '',
    }]);
  };

  const handleUploadDocuments = () => {
    let arrayFilesMutation = [];
    Object.entries(documents).forEach(([...v]) => {
      arrayFilesMutation = [...arrayFilesMutation,
        { documentType: v[1].documentType, file: v[1].file }];
    });
    uploadFiles({
      variables: {
        invoiceId,
        inputUploadFiles: arrayFilesMutation,
      },
    });
  };
  return (
    <Dialog open={open} onClose={() => { handleCloseButton(); }}>
      <DialogTitle>Carga de Documentos</DialogTitle>
      <DialogContent className={classes.contentContainer}>
        {documents.map((document, i) => {
          const {
            name,
            documentType,
          } = document;
          return (
            <div>
              <div className={classes.rowFile}>
                <div style={{ minWidth: 150, maxWidth: 150 }}>
                  <Typography className={classes.oneLineClamp}>Documento</Typography>
                  <Typography className={classes.textCell}>{name}</Typography>
                </div>
                <Select
                  id="ratification-contact-select"
                  value={documentType}
                  onChange={(e) => setDocumentType(e, i)}
                  style={{ minWidth: '12rem', maxWidth: '12rem' }}
                >
                  {documentOptions.map((method) => (
                    <MenuItem key={method.value} value={method.value}>{method.label}</MenuItem>
                  ))}
                </Select>
                <div>
                  <label htmlFor={i}>
                    <input
                      style={{ display: 'none' }}
                      id={i}
                      type="file"
                      onChange={(e) => handleAddDocument(e.target.files[0], i)}
                    />
                    <IconButton aria-label="delete" component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </div>
                <IconButton color="error" onClick={() => handleRemoveDocument(i)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          );
        })}
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="text"
            startIcon={<Add />}
            onClick={handleAddDocumentInput}
          >
            <small>Agregar documento</small>
          </Button>
        </Grid>
      </DialogContent>
      <Typography className={classes.responseText}><b>{uploadFilesMessage}</b></Typography>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.exitButton}
          disabled={loadingUploadFiles}
          onClick={() => { handleCloseButton(); }}
          color="primary"
        >
          Salir
        </Button>
        <Button
          variant="contained"
          className={classes.loadingButton}
          onClick={handleUploadDocuments}
          color="primary"
          autoFocus
          disabled={
          documents.length === 0
          || documents.some((document) => document.name === ''
            || document.documentType === '')
          }
        >
          Cargar
          {loadingUploadFiles && <CircularProgress style={{ marginLeft: '0.5rem', color: 'white' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadDocumentsManager.defaultProps = {
  refetchPage: () => {},
};

UploadDocumentsManager.propTypes = {
  refetchPage: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoiceId: PropTypes.number.isRequired,
};

export default UploadDocumentsManager;
