import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';

import { Publish } from '@material-ui/icons';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  rowFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    display: 'none',
  },

});

const DocumentManager = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  client,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState({
    CONTRACT: null,
    BALANCE: null,
    EERR: null,
    CTE: null,
    TGR: null });
  const [disableButton, setDisableButton] = useState(true);
  const handleUploadFile = (event) => {
    event.persist();
    const filesEvent = event.target.files;
    setDisableButton(false);
    setFiles((prevState) => ({ ...prevState, [event.target.name]: filesEvent[0] }));
  };

  useEffect(() => {
    if (!open) {
      setFiles({ CONTRACT: null,
        BALANCE: null,
        EERR: null,
        CTE: null,
        TGR: null });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>Gestionar documentos {client?.name}</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <div className={classes.rowFile}>
            <Typography><b>Contrato</b></Typography>
            {files.CONTRACT && <Typography variant="caption" style={{ marginLeft: '0.5rem' }}> {files.CONTRACT.name}</Typography>}
            <label htmlFor="contained-button-file-contract-name">
              <input
                name="CONTRACT"
                accept=".pdf"
                className={classes.input}
                id="contained-button-file-contract-name"
                type="file"
                onChange={handleUploadFile}
              />

              <Button
                name="CONTRACT"
                size="small"
                style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                component="span"
                color="primary"
                variant="contained"
                startIcon={<Publish />}
              >
                Seleccionar Contrato
              </Button>

            </label>
          </div>
          <div className={classes.rowFile}>
            <Typography><b>Balance</b></Typography>
            {files.BALANCE && <Typography variant="caption" style={{ marginLeft: '0.5rem' }}> {files.BALANCE.name}</Typography>}
            <label htmlFor="contained-button-file-balance-name">
              <input
                name="BALANCE"
                accept=".pdf, .jpg, .jpeg, .png"
                className={classes.input}
                id="contained-button-file-balance-name"
                type="file"
                onChange={handleUploadFile}
              />
              <Button
                name="BALANCE"
                size="small"
                style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                component="span"
                color="primary"
                variant="contained"
                startIcon={<Publish />}
              >
                Seleccionar Balance
              </Button>

            </label>
          </div>
          <div className={classes.rowFile}>
            <Typography><b>EERR</b></Typography>
            {files.EERR && <Typography variant="caption" style={{ marginLeft: '0.5rem' }}> {files.EERR.name}</Typography>}
            <label htmlFor="contained-button-file-EERR-name">
              <input
                name="EERR"
                accept=".pdf, .jpg, .jpeg, .png"
                className={classes.input}
                id="contained-button-file-EERR-name"
                type="file"
                onChange={handleUploadFile}
              />
              <Button
                name="EERR"
                size="small"
                style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                component="span"
                color="primary"
                variant="contained"
                startIcon={<Publish />}
              >
                Seleccionar EERR
              </Button>

            </label>
          </div>
          { !client?.hasSiiCredentials
          && (
          <>
            <div className={classes.rowFile}>
              <Typography><b>TGR</b></Typography>
              {files.TGR && <Typography variant="caption" style={{ marginLeft: '0.5rem' }}> {files.TGR.name}</Typography>}
              <label htmlFor="contained-button-file-tgr-name">
                <input
                  name="TGR"
                  accept=".pdf, .jpg, .jpeg, .png"
                  className={classes.input}
                  id="contained-button-file-tgr-name"
                  type="file"
                  onChange={handleUploadFile}
                />
                <Button
                  name="TGR"
                  size="small"
                  style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                  component="span"
                  color="primary"
                  variant="contained"
                  startIcon={<Publish />}
                >
                  Seleccionar TGR
                </Button>

              </label>
            </div>
            <div className={classes.rowFile}>
              <Typography><b>CTE</b></Typography>
              {files.CTE && <Typography variant="caption" style={{ marginLeft: '0.5rem' }}> {files.CTE.name}</Typography>}
              <label htmlFor="contained-button-file-cte-name">
                <input
                  name="CTE"
                  accept=".pdf"
                  className={classes.input}
                  id="contained-button-file-cte-name"
                  type="file"
                  onChange={handleUploadFile}
                />
                <Button
                  name="CTE"
                  size="small"
                  style={{ marginLeft: '0.5rem', marginBottom: '1rem', width: '11rem' }}
                  component="span"
                  color="primary"
                  variant="contained"
                  startIcon={<Publish />}
                >
                  Seleccionar CTE
                </Button>

              </label>
            </div>
          </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleClose}
          color="primary"
        >
          Cancelar
        </Button>
        <Button disabled={loading || disableButton} onClick={() => handleConfirm(files)} color="primary" autoFocus>
          Subir archivos
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DocumentManager.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  client: PropTypes.shape(
    { rut: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      hasSiiCredentials: PropTypes.bool,
    },
  ).isRequired,
};
DocumentManager.defaultProps = {
  loading: false,
};

export default DocumentManager;
