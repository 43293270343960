import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  List,
  ListItem,
  Divider,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: 20,
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formField: {
    width: '50%',
    marginBottom: '1rem',
  },
  textField: {
    width: '100%',
    marginBottom: '1rem',
  },
  sendButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '2rem',
    marginTop: '1rem',
    width: '100%',
  },
}));

const notificationMethodOptions = [
  { label: 'Email', value: 'Email' },
  { label: 'Whatsapp', value: 'Whatsapp' },
  { label: 'Slack', value: 'Slack' },
];

const AddCollectionReminder = ({ backToInfo, onSendData }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedNotificationMethod, setSelectedNotificationMethod] = useState(null);
  const [note, setNote] = useState('');
  const [error, setError] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNotificationMethodChange = (event) => {
    setSelectedNotificationMethod(event.target.value);
  };

  const handleTextChange = (event) => {
    setNote(event.target.value);
  };

  const handleAddData = async () => {
    if (!selectedDate || !selectedNotificationMethod) {
      setError('Debes rellenar la fecha y forma de notificación');
    } else {
      setError('');
      const reminderDate = moment(selectedDate).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');
      await onSendData(reminderDate, selectedNotificationMethod, note);
      backToInfo();
    }
  };

  return (
    <>
      <List className={classes.list}>
        <ListItem>
          <Button onClick={backToInfo}>
            <ChevronLeftIcon />
          </Button>
          <Typography
            variant="h6"
            gutterBottom
          >
            Programar recordatorio
          </Typography>
        </ListItem>
        <Divider />
        <ListItem className={classes.formContainer}>
          <Typography gutterBottom>
            <b>Fecha</b>
          </Typography>
          <KeyboardDateTimePicker
            variant="dialog"
            format="DD/MM/YYYY HH:mm"
            margin="normal"
            inputVariant="outlined"
            disablePast
            ampm={false}
            className={classes.formField}
            value={selectedDate}
            onChange={handleDateChange}
          />
          <Typography gutterBottom>
            <b>Nota sobre el recordatorio</b>
          </Typography>
          <TextField
            value={note}
            onChange={handleTextChange}
            className={classes.textField}
            multiline
            rows={3}
            variant="outlined"
          />
          <Typography gutterBottom>
            <b>Forma de notificación</b>
          </Typography>
          <Select
            id="data-collection-payment-method"
            value={selectedNotificationMethod}
            onChange={handleNotificationMethodChange}
            className={classes.formField}
            variant="outlined"
          >
            {notificationMethodOptions.map((method) => (
              <MenuItem key={method.value} value={method.value}>{method.label}</MenuItem>
            ))}
          </Select>
          <div className={classes.sendButtonContainer}>
            <Typography color="error" variant="caption">{error}</Typography>
            <Button onClick={handleAddData} color="primary" variant="outlined">
              Guardar recordatorio
            </Button>
          </div>
        </ListItem>
      </List>
    </>
  );
};

AddCollectionReminder.propTypes = {
  backToInfo: PropTypes.func.isRequired,
  onSendData: PropTypes.func.isRequired,
};

export default AddCollectionReminder;
