import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Error, Check } from '@material-ui/icons';

const styles = makeStyles(() => ({
  checkColor: {
    color: green[500],
  },
}));

const ListRisk = ({ history, name }) => {
  const classes = styles();
  return (
    <>
      <ListItem>
        <ListItemText primary={name} />
      </ListItem>
      {history.map((result) => {
        let isTrue = false;
        if ((result.prize === undefined)) {
          isTrue = result.result;
        } else if (result.prize > 0 && result.result) {
          isTrue = true;
        } else if (result.prize < 0 && !result.result) {
          isTrue = true;
        }
        return (
          <ListItem key={result.name}>
            <ListItemIcon>
              {isTrue ? <Check className={classes.checkColor} /> : <Error color="error" />}
            </ListItemIcon>
            <ListItemText
              primary={result.name}
              secondary={result.prize === undefined ? result.value : `Con un valor de ${result.value} tuvo un premio ${result.prize * result.result}`}
            />
          </ListItem>
        );
      })}
    </>
  );
};
ListRisk.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
};
const RiskDetails = ({ row, open, onClose }) => {
  const classes = styles();
  if (!row) {
    return (
      <>
      </>
    );
  }
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <List
        component="nav"
      >
        <ListItem>
          <ListItem>
            <ListItemIcon>
              {row.clientIvaAmount !== null ? <Check className={classes.checkColor} /> : <Error color="error" />}
            </ListItemIcon>
            <ListItemText
              primary="CTE"
            />
          </ListItem>
        </ListItem>
        <ListRisk history={row.clientTree} name="Árbol Cliente" />
        <Divider />
        <ListRisk history={row.debtorTree} name="Árbol Deudor" />
        <Divider />
        <ListRisk history={row.clientDebtorTree} name="Árbol Relación" />
        <Divider />
        <ListRisk history={row.clientRas} name="RAS Cliente" />
        <Divider />
        <ListRisk history={row.debtorRas} name="RAS Deudor" />
        <Divider />
        <ListRisk history={row.clientDebtorRas} name="RAS Relación" />
        <Divider />
      </List>
    </Dialog>
  );
};

RiskDetails.propTypes = {
  /* eslint-disable-next-line */
  row: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

RiskDetails.defaultProps = {
  row: undefined,
};

export default RiskDetails;
