import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  mails: {
    maxHeight: '30vh',
    overflowY: 'auto',
    marginBottom: '1rem',

    '&&::-webkit-scrollbar': {
      display: 'none',
    },

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
});

const ConfirmSendMarketingCampaign = ({
  open,
  error,
  campaign,
  handleClose,
  handleConfirm,
  loading }) => {
  const classes = useStyles();
  const { templateId, date, mails } = campaign;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>¿Quieres enviar la siguiente campaña?</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.section}>
          <Typography><b>ID Template Sendgrid:</b> {templateId}</Typography>
          <Typography><b>Fecha de envío:</b> {date && date.format('DD/MM/YYYY HH:mm')} </Typography>
          <Typography style={{ marginBottom: '1rem', marginTop: '1rem' }}><b>Destinatarios: </b></Typography>
          <div className={classes.mails}>
            {mails && mails.map((mail, index) => (
              <Typography key={mail}>
                {index + 1}.{mail}
              </Typography>
            ))}
          </div>
          {error.isError && <Typography color="error">{error.message}</Typography> }
        </div>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button disabled={loading || error.isError} onClick={handleConfirm} color="primary" autoFocus>
          Aceptar
          {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmSendMarketingCampaign.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    isError: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  campaign: PropTypes.shape({
    mails: PropTypes.arrayOf(PropTypes.string).isRequired,
    date: PropTypes.string,
    templateId: PropTypes.string.isRequired,

  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ConfirmSendMarketingCampaign.defaultProps = {
  loading: false,
};

export default ConfirmSendMarketingCampaign;
