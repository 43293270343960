import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles({
  collectionDataContainer: {
    padding: '0.5rem',
  },
});

const DataForCollection = ({ collectionData }) => {
  const classes = useStyles();
  const dateToPay = collectionData ? collectionData.dateToPay : null;
  const dateToPayMoment = dateToPay
    ? moment(collectionData.dateToPay).format('DD-MM-YYYY') : 'sin datos';

  const getPaymentMethod = () => {
    const paymentMethod = collectionData ? collectionData.paymentMethod : null;
    if (!paymentMethod) return 'sin datos';
    if (paymentMethod === 'TRANSFER') return 'Transferencia';
    if (paymentMethod === 'CHECK') return 'Cheque';
    if (paymentMethod === 'BANKTICKET') return 'Vale Vista';
    if (paymentMethod === 'OTHERINVOICE') return 'Otra factura';
    return 'sin datos';
  };

  const getCollectionContactsNames = () => {
    const collectionContacts = collectionData ? collectionData.collectionContacts : [];
    if (collectionContacts.length === 0) return 'sin datos';
    const nameList = collectionContacts.reduce((acc, contact) => {
      const name = `${contact.firstName} ${contact.lastName}`;
      return [...acc, name];
    }, []);
    return nameList.join(', ');
  };

  return (
    <div className={classes.collectionDataContainer}>
      <Typography>{`Fecha de pago: ${dateToPayMoment}`}</Typography>
      <Typography>{`Método de pago: ${getPaymentMethod()}`}</Typography>
      <Typography>{`Contacto que entregó los datos: ${getCollectionContactsNames()}`}</Typography>
    </div>
  );
};

DataForCollection.propTypes = {
  collectionData: PropTypes.shape({
    dateToPay: PropTypes.instanceOf(Date).isRequired,
    paymentMethod: PropTypes.string,
    collectionContacts: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.lastName,
    })).isRequired,
  }).isRequired,
};

export default DataForCollection;
