import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';

const DeleteCommissionAlert = ({ open, onClose, onAccept }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">¿Seguro que quieres eliminar esta regla?</DialogTitle>
    <DialogActions>
      <Button
        onClick={onClose}
      >
        Cancelar
      </Button>
      <Button
        onClick={onAccept}
        color="primary"
      >
        Sí, Eliminar
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteCommissionAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DeleteCommissionAlert;
