export const handleMoveSelected = (
  rows,
  selectedMoveRows,
  setSelectedMoveRows,
  selectedRut,
  setSelectedRut,
  movements,
  disableds,
  setDisableds,
  setSelectedCompany,
  setTotalMoveAmount,
  totalMoveAmount,
  holderId,
  refresh,
  setRefresh,
) => {
  const newElement = rows.filter((x) => !selectedMoveRows.includes(x))[0];
  const oldElement = selectedMoveRows.filter((x) => !rows.includes(x))[0];
  const disabledsAux = { ...disableds };
  if (!selectedRut) {
    setSelectedRut(newElement[holderId].value);
    movements.forEach((movement) => {
      if (movement[holderId].slice(0, -1) !== newElement[holderId].value) {
        disabledsAux[movement.id] = true;
      } else {
        setSelectedCompany(movement);
      }
    });
    setDisableds({ ...disabledsAux });
  } else if (!rows.length) {
    setSelectedRut(false);
    movements.forEach((movement) => {
      disabledsAux[movement.id] = false;
    });
    setDisableds({ ...disabledsAux });
  }
  if (newElement) {
    setTotalMoveAmount(totalMoveAmount + newElement.amount.value);
  } else {
    setTotalMoveAmount(totalMoveAmount - oldElement.amount.value);
  }
  if (refresh) {
    setRefresh(false);
    setSelectedMoveRows([newElement]);
  } else {
    setSelectedMoveRows(rows);
  }
};

export const getIds = (selectedRows, column) => {
  const ids = [];
  selectedRows.forEach((row) => {
    if (column) {
      ids.push(row[column]);
    } else {
      ids.push(row.id);
    }
  });
  return ids;
};

export const getCollectionManagers = (selectedRows, differences, reasons, dates) => {
  const managers = [];
  selectedRows.forEach((row) => {
    const unpayedDifference = (
      differences[row.collectionManagerId] ? differences[row.collectionManagerId] : 0
    );
    const unpayedDifferenceReason = (
      reasons[row.collectionManagerId] ? reasons[row.collectionManagerId] : null
    );
    const paymentDatetime = (
      dates && dates[row.collectionManagerId] ? dates[row.collectionManagerId] : null
    );
    managers.push({
      managerId: row.collectionManagerId,
      unpayedDifference,
      unpayedDifferenceReason,
      paymentDatetime,
    });
  });
  return managers;
};

export const getCollectionManagersFromCollectionNote = (
  selectedRows, differences, reasons, dates,
) => {
  const managers = [];
  selectedRows.forEach((row) => {
    const unpayedDifference = (
      differences[row.collectionManagerId] ? differences[row.collectionManagerId] : 0
    );
    const unpayedDifferenceReason = (
      reasons[row.collectionManagerId] ? reasons[row.collectionManagerId] : null
    );
    const paymentDatetime = (
      dates && dates[row.collectionManagerId] ? dates[row.collectionManagerId] : null
    );
    row.collectionManagersIds.forEach((id) => {
      managers.push({
        managerId: id,
        unpayedDifference: id === row.collectionManagerId ? unpayedDifference : 0,
        unpayedDifferenceReason: id === row.collectionManagerId ? unpayedDifferenceReason : null,
        paymentDatetime,
      });
    });
  });
  return managers;
};

export const filterInvoices = (invoices, filter) => (
  invoices.filter((invoice) => {
    const name = invoice.debtor.name || invoice.debtor.masterEntity.name;
    const folioCondition = invoice.folio.toString().includes(filter);
    const nameCondition = name.toLowerCase().includes(filter.toLowerCase());
    const amountCondition = invoice.amountWithIva.toString().includes(filter);
    return folioCondition || nameCondition || amountCondition;
  })
);

export const filterCollectionNotes = (notes, filter) => (
  notes.filter((note) => {
    const nameCondition = note.debtor.name.toLowerCase().includes(filter.toLowerCase());
    const amountCondition = note.amountOfMoneyToCollect.toString().includes(filter);
    return nameCondition || amountCondition;
  })
);

export const filterOrders = (orders, filter) => (
  orders.filter((order) => {
    const purchaserCondition = order.purchaser.name.toLowerCase().includes(filter.toLowerCase());
    const companyNameCondition = order.company.name.toLowerCase().includes(filter.toLowerCase());
    const amountCondition = order.orderingoffer.orderingFinancedAmount.toString().includes(filter);
    return amountCondition || companyNameCondition || purchaserCondition;
  })
);

export const filterRosters = (rosters, filter) => (
  rosters.filter((roster) => {
    const fundingCondition = roster.fund.socialReason.toLowerCase().includes(filter.toLowerCase());
    const cessionFundingCondition = roster.cessionFund.socialReason.toLowerCase().includes(
      filter.toLowerCase(),
    );
    const amountCondition = roster.transferAmount.toString().includes(filter);
    return amountCondition || cessionFundingCondition || fundingCondition;
  })
);
