import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, LinearProgress, Button, TextField } from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { formatRut } from '../helpers/generic';
import { useStyles } from './styles';
import { ADD_COMPANY_TO_BLACKLIST, GET_COMPANY_BLACKLIST, REMOVE_COMPANY_FROM_BLACKLIST } from '../gql/customer';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const headers = [
  { id: 'rut', label: 'Rut', align: 'left' },
  { id: 'name', label: 'Razón social', align: 'left' },
  { id: 'createdAt', label: 'Fecha Incorporación', align: 'left' },
];

const Blacklist = () => {
  const history = useHistory();
  const classes = useStyles();
  const [stateData, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [inputsRut, setInputsRut] = useState();
  const [getBlacklist, { loading, error }] = useLazyQuery(GET_COMPANY_BLACKLIST, {
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      setData(_data.getMasterEntities.edges.map((item) => ({
        id: item.node.rut,
        rut: item.node.rut,
        name: item.node.name,
        createdAt: item.node.riskBlacklist.createdAt,
      })));
    },
  });
  useEffect(() => {
    getBlacklist();
  }, []);

  const [removeCompanyFromBlacklist,
    { loading: loadingRemoveCompanyFromBlacklist,
      error: errorRemoveCompanyFromBlacklist },
  ] = useMutation(REMOVE_COMPANY_FROM_BLACKLIST,
    {
      refetchQueries: [{ query: GET_COMPANY_BLACKLIST }],
    });

  const [addCompanyToBlacklist,
    { loading: loadingAddCompaniesToBlacklist,
      error: errorAddCompaniesToBlacklist },
  ] = useMutation(ADD_COMPANY_TO_BLACKLIST,
    {
      refetchQueries: [{ query: GET_COMPANY_BLACKLIST }],
    });
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleRemoveCompaniesFromBlacklist = async () => {
    const rutsCompanies = selectedCompanies.reduce(
      (previousValue, currentValue) => previousValue.concat(currentValue.id),
      [],
    );
    await removeCompanyFromBlacklist({
      variables: {
        ruts: rutsCompanies,
      },
    });
    setSelectedCompanies([]);
  };

  const handleAddCompaniesBlacklist = async () => {
    const rutsCompanies = inputsRut.split(' ');
    await addCompanyToBlacklist({
      variables: {
        ruts: rutsCompanies,
      },
    });
  };
  const handleSearch = () => {
    getBlacklist({
      variables: {
        globalFilter: searchValue,
      },
    });
  };
  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      rut,
      name,
      createdAt,
    } = row;
    return ({
      id,
      rut: {
        value: rut,
        component: (
          <Typography variant="body2">{formatRut(rut)}</Typography>
        ),
      },
      name: {
        value: name,
        component: (
          <Typography variant="body2">{name}</Typography>
        ),
      },
      createdAt: {
        value: createdAt,
        component: (
          <Typography variant="body2">{new Date(createdAt).toLocaleString()}</Typography>
        ),
      },
    });
  });

  if (permissionDeniedErrorCatcher(error)) {
    history.replace('/');
  }
  if (
    loading
    || loadingRemoveCompanyFromBlacklist
    || loadingAddCompaniesToBlacklist
  ) return <LinearProgress />;
  if (
    errorRemoveCompanyFromBlacklist
    || errorAddCompaniesToBlacklist
  ) return <Typography align="center">Error al cargar las empresas</Typography>;
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Empresas en Blacklist</b></Typography>
        <TextField
          valiant="contained"
          label="Rut/s sin DV"
          helperText="Si es mas de un rut, separar con espacio"
          onChange={(event) => setInputsRut(event.target.value)}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddCompaniesBlacklist}
        >
          Agregar empesas

        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRemoveCompaniesFromBlacklist}
          disabled={selectedCompanies.length === 0}
        >
          Quitar Empresas de la Blacklist
        </Button>
      </div>
      <Grid item xs={12} className={classes.searchContainer}>
        <TextField
          value={searchValue}
          variant="outlined"
          onChange={(event) => setSearchValue(event.target.value)}
          helperText="Busca a un cliente por rut o nombre de la empresa"
          className={classes.searchBox}
          inputProps={{
            style: {
              height: 32,
            },
          }}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSearch}
          className={classes.searchButton}
        >
          Buscar
        </Button>
      </Grid>
      {stateData.length > 0 ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              withSelect
              headers={headers}
              setNewDataOrder={setData}
              data={stateData}
              initialOrderId="name"
              dataToRows={transformDataToComponents}
              handleSelected={setSelectedCompanies}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">No hay empresas en la Blacklist</Typography>
      )}
    </div>
  );
};

export default Blacklist;
