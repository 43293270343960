import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Cached } from '@material-ui/icons';
import { Typography, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from '../../views/styles';
import { Filters } from '../utils';
import { Table } from '../tables';
import { TwoButtonsAlert, OneButtonAlert, AcceptMoneyTransfers, ReintegrateInvoices } from '../dialogs';
import { REJECT_MONEY_TRANSFERS } from '../../gql/moneyTransfer';

const GenericAvailableForTransfer = ({
  title,
  refetch,
  headers,
  transformDataToComponents,
  setFilteredMoneyTransfers,
  filteredMoneyTransfers,
  moneyTransfers,
  rejectMessage,
  selectedRows,
  setSelectedRows,
  filter,
  pendingReintegrationInvoices,
  payingReintegrationInvoices,
}) => {
  const [rejectMoneyTransfers, {
    loading: rejectLoading,
    error: rejectError,
  }] = useMutation(REJECT_MONEY_TRANSFERS);
  const classes = useStyles();
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [reintegrationOpen, setReintegrationOpen] = useState(false);
  const [successDialog, setSuccessDialog] = useState({
    open: false,
    title: '',
  });
  const handleRefetch = async () => {
    await refetch();
  };

  const handleAcceptClose = (accepted) => {
    if (accepted) {
      setSuccessDialog({ open: true, title: 'Giros aceptados y mail enviado' });
      refetch();
      setSelectedRows([]);
    }
    setAcceptOpen(false);
  };

  const handleRejectTransfers = async () => {
    // error handling is made in the mutation
    const response = await rejectMoneyTransfers({
      variables: {
        moneyTransfers: selectedRows.map((row) => ({
          moneyTransferId: row.id,
          moneyTransferType: 'INVOICE',
        })),
      },
    });
    setSelectedRows([]);
    if (response.data.rejectMoneyTransfers.success) {
      setRejectOpen(false);
      setSuccessDialog({ open: true, title: 'Giros rechazados con éxito' });
      refetch();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.titleAndUpdate}>
          <Typography variant="h4"><b>{title}</b></Typography>
          <Button
            className={classes.updateButton}
            color="primary"
            variant="contained"
            onClick={handleRefetch}
            endIcon={<Cached />}
          >
            Actualizar
          </Button>
        </div>
        <div className={classes.headerButtonsContainer}>
          {pendingReintegrationInvoices && (
            <Button
              className={classes.redButton}
              color="inherit"
              variant="contained"
              onClick={() => setReintegrationOpen(true)}
              disabled={
                pendingReintegrationInvoices.length < 1 || payingReintegrationInvoices.length < 1
              }
            >
              Reintegrar
            </Button>
          )}
          <Button
            className={classes.redButton}
            color="inherit"
            variant="contained"
            onClick={() => setRejectOpen(true)}
            disabled={selectedRows.length < 1}
          >
            Rechazar giros
          </Button>
          <Button
            className={classes.greenButton}
            color="inherit"
            variant="contained"
            onClick={() => setAcceptOpen(true)}
            disabled={selectedRows.length < 1}
          >
            Solicitar giros a tesorería
          </Button>
        </div>
      </div>
      {moneyTransfers.length > 0
        ? (
          <Grid container>
            <Grid item xs={6} sm={6} className={classes.searchBar}>
              <Filters
                allData={moneyTransfers}
                setFilteredData={setFilteredMoneyTransfers}
                searchFields={filter}
                searchPlaceholder="Búsqueda por número de documento o rut o nombre de cliente o deudor"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.tableContainer}>
              <Table
                headers={headers}
                initialOrderId="timeInStage"
                withSelect
                handleSelected={setSelectedRows}
                setNewDataOrder={setFilteredMoneyTransfers}
                data={filteredMoneyTransfers}
                dataToRows={transformDataToComponents}
                clearSelection={successDialog.open}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography align="center" variant="h5">No hay facturas listas para giro</Typography>
        )}
      <TwoButtonsAlert
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onAccept={handleRejectTransfers}
        loading={rejectLoading}
        error={rejectError ? 'No pudimos rechazar los giros' : ''}
        message={rejectMessage}
        title="¿Quieres rechazar estos giros?"
      />
      <OneButtonAlert
        open={successDialog.open}
        title={successDialog.title}
        onClose={() => setSuccessDialog({ open: false })}
      />
      <AcceptMoneyTransfers
        open={acceptOpen}
        onClose={handleAcceptClose}
        clients={selectedRows.map((row) => ({
          rut: row.clientRut,
          socialReason: row.client.value,
        }))}
        moneyTransfers={selectedRows.map((row) => ({
          moneyTransferId: row.id,
          moneyTransferType: 'INVOICE',
        }))}
      />
      {pendingReintegrationInvoices && payingReintegrationInvoices && (
        <ReintegrateInvoices
          openDialog={reintegrationOpen}
          setOpenDialog={setReintegrationOpen}
          pendingReintegrationInvoices={pendingReintegrationInvoices}
          amountAvailableToReintegrate={payingReintegrationInvoices.reduce(
            (acc, inv) => acc + inv.amount.value, 0,
          )}
          payingInvoicesIds={payingReintegrationInvoices.map((invoice) => invoice.invoiceId)}
        />
      )}
    </div>
  );
};
GenericAvailableForTransfer.propTypes = {
  title: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.objectOf(PropTypes.array).isRequired,
  transformDataToComponents: PropTypes.node.isRequired,
  setFilteredMoneyTransfers: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filteredMoneyTransfers: PropTypes.objectOf(PropTypes.array).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  moneyTransfers: PropTypes.objectOf(PropTypes.array).isRequired,
  rejectMessage: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedRows: PropTypes.objectOf(PropTypes.array).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.objectOf(PropTypes.array).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pendingReintegrationInvoices: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  payingReintegrationInvoices: PropTypes.arrayOf(PropTypes.object),
};

GenericAvailableForTransfer.defaultProps = {
  pendingReintegrationInvoices: null,
  payingReintegrationInvoices: null,
};

export default GenericAvailableForTransfer;
