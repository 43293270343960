/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Grid, LinearProgress, Button } from '@material-ui/core';
import { PublishRounded, Clear, Cached } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { formatRut } from '../helpers/generic';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { GET_WAITING_APPROVAL_CONFIRMING } from '../gql/confirming';
import { CONFIRM_MONEY_TRANSFERS, REJECT_MONEY_TRANSFERS } from '../gql/moneyTransfer';
import { ApprovalDialog, OneButtonAlert } from '../components/dialogs';
import { DefaultDict } from '../helpers/dataStructures';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const headers = [
  { id: 'client', label: 'Client', align: 'left' },
  { id: 'bankName', label: 'Banco', align: 'left' },
  { id: 'accountNumber', label: 'Cuenta', align: 'left' },
  { id: 'type', label: 'Tipo', align: 'left' },
  { id: 'total', label: 'Monto a girar', align: 'right' },
  { id: 'date', label: 'Fecha de giro', align: 'left' },
  { id: 'receipt', label: 'Comprobante de giro', align: 'right' },
];

const typeChange = {
  INVOICE: 'Facturas',
  SURPLUS: 'Excedentes',
};

const WaitingApprovalConfirming = () => {
  const classes = useStyles();
  const { data, refetch, error } = useQuery(GET_WAITING_APPROVAL_CONFIRMING);
  const [confirmMoneyTransfers] = useMutation(CONFIRM_MONEY_TRANSFERS);
  const [rejectMoneyTransfers] = useMutation(REJECT_MONEY_TRANSFERS);
  const [stateData, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState({});
  const [selectedFiles, setSelectedFiles] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState('');

  useEffect(() => {
    if (data) {
      const newData = data.getWaitingForApprovalConfirming;
      const transformedData = newData.map((transfer) => ({
        ...transfer,
        rutClient: transfer.client.rut,
        nameClient: transfer.client.name,
        spanishTransferType: typeChange[transfer.moneyTransferType],
      }));
      setData(transformedData);
      setFilteredData(transformedData);
      setLoading(false);
    }
  }, [data]);

  const handleDateChange = (id, newDate) => {
    const newDates = { ...selectedDates, [id]: newDate };
    setSelectedDates(newDates);
  };

  const handleFileChange = (id, files) => {
    const newSelectedFiles = { ...selectedFiles, [id]: Array.from(files) };
    setSelectedFiles(newSelectedFiles);
  };

  const handleClearVoucher = (id) => {
    const { [id]: deleted, ...newSelectedFiles } = selectedFiles;
    setSelectedFiles(newSelectedFiles);
  };

  const checkInputs = () => (
    selected
      .map((item) => selectedDates[item.id] && selectedFiles[item.id])
      .every(Boolean)
  );

  const handleReject = () => {
    setRejectOpen(true);
  };

  const handleConfirm = () => {
    if (!checkInputs()) return;
    setConfirmOpen(true);
  };

  const handleConfirmMoneyTransfers = async () => {
    if (!checkInputs()) return;
    const dataByReceipt = new DefaultDict(Array);
    selected.forEach((manager) => {
      selectedFiles[manager.id].forEach((receipt) => {
        dataByReceipt[`${receipt.name}.${receipt.lastModified}.${receipt.size}`].push({
          receipt,
          transferDatetime: selectedDates[manager.id].format('YYYY-MM-DD HH:mm:ss'),
          transfersConfirmed: manager.moneyTransfersId.map((moneyTransferId) => (
            {
              moneyTransferId,
              moneyTransferType: manager.type.value,
            }
          )),
        });
      });
    });
    const confirmationReceipts = [];
    Object.keys(dataByReceipt).forEach((receiptKey) => {
      const receiptData = dataByReceipt[receiptKey];
      confirmationReceipts.push({
        receipt: receiptData[0].receipt,
        transferDatetime: receiptData[0].transferDatetime,
        transfersConfirmed: receiptData
          .reduce((acc, item) => [...acc, ...item.transfersConfirmed], []),
      });
    });
    setModalLoading(true);
    try {
      await confirmMoneyTransfers({ variables: { confirmationReceipts } });
      setModalLoading(false);
      setSelected([]);
      setSelectedDates({});
      setSelectedFiles({});
      setConfirmOpen(false);
      setAlertTitle('Confirmado');
      setErrorMessage('Se han confirmado correctamente las solicitudes');
      await refetch();
    } catch {
      setModalLoading(false);
      setRejectOpen(false);
      setAlertTitle('Error');
      setErrorMessage('Hubo un error con la solicutd, intente nuevamente');
    }
  };

  const handleRejectMoneyTransfers = async () => {
    if (!selected.length) return;
    const moneyTransfers = (
      selected.map((item) => item.moneyTransfersId.map((moneyTransferId) => (
        { moneyTransferId, moneyTransferType: item.type.value }
      )))
        .reduce((pre, cur) => pre.concat(cur))
    );
    setModalLoading(true);
    try {
      await rejectMoneyTransfers({ variables: { moneyTransfers } });
      setSelected([]);
      setSelectedDates({});
      setSelectedFiles({});
      setModalLoading(false);
      setRejectOpen(false);
      setAlertTitle('Rechazado');
      setErrorMessage('Se han rechazado correctamente las solicitudes');
      await refetch();
    } catch {
      setModalLoading(false);
      setRejectOpen(false);
      setAlertTitle('Error');
      setErrorMessage('Hubo un error con la solicutd, intente nuevamente');
    }
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      bank,
      moneyTransfersId,
      moneyTransferType,
      total,
      client,
      id,
      folios,
    } = row;
    return ({
      id,
      client: {
        value: client,
        component: (
          <div className={classes.columnLeft}>
            <Typography variant="body2">{client.name}</Typography>
            <Typography variant="body2">{formatRut(client.rut)}</Typography>
          </div>
        ),
      },
      moneyTransfersId,
      folios,
      bankName: {
        value: bank.bankName,
        component: (
          <Typography variant="body2">{bank.bankName}</Typography>
        ),
      },
      accountNumber: {
        value: bank.accountNumber,
        component: (
          <Typography variant="body2">{bank.accountNumber}</Typography>
        ),
      },
      type: {
        value: moneyTransferType,
        component: (
          <>
            <Typography variant="body2">{typeChange[moneyTransferType]}</Typography>
          </>
        ),
      },
      total: {
        value: total,
        component: (
          <>
            <Typography variant="body2" align="right">{`$${formatMoney(total)}`}</Typography>
          </>
        ),
      },
      date: {
        value: null,
        component: (
          <KeyboardDateTimePicker
            variant="dialog"
            format="DD/MM/YYYY HH:mm"
            maxDate={moment.now()}
            margin="normal"
            ampm={false}
            disableFuture
            style={{ width: 200 }}
            inputVariant="outlined"
            value={selectedDates[id] || null}
            onChange={(newDate) => handleDateChange(id, newDate)}
            inputProps={{
              style: {
                fontSize: 12,
              },
            }}
          />
        ),
      },
      receipt: {
        value: null,
        component: (
          // <div style={classes.cellJustifyRight}>
          //   {selectedFiles[id] && <Clear onClick={() => { handleClearVoucher(id); }} />}
          //   {selectedFiles[id] && selectedFiles[id].name}
          //   <label htmlFor={id} className={classes.hoverPointer}>
          //     <PublishRounded fontSize="small" />
          //     <Input
          //       id={id}
          //       type="file"
          //       style={{ display: 'none' }}
          //       onChange={(event) => handleFileChange(id, event.target.files)}
          //     />
          //   </label>
          // </div>
          <div className={classes.receiptContainer}>
            {selectedFiles[id] && <Clear onClick={() => handleClearVoucher(id)} />}
            <div className={classes.fileNameContainer}>
              {selectedFiles[id] && selectedFiles[id].map((file, i) => (
                <Typography key={file.name} variant="caption">
                  {i + 1}. {file.name}
                </Typography>
              ))}
            </div>
            <label htmlFor={id} className={classes.hoverPointer}>
              <PublishRounded fontSize="small" />
              <input
                id={id}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                multiple
                style={{ display: 'none' }}
                onChange={(event) => handleFileChange(id, event.target.files)}
              />
            </label>
          </div>
        ),
      },
    });
  });

  const handleRefetch = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;

  return (
    <div className={classes.container}>
      <OneButtonAlert
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        message={errorMessage}
        title={alertTitle}
      />
      <div className={classes.headerContainer}>
        <div className={classes.titleAndUpdate}>
          <Typography variant="h4"><b>Giros de confirming en espera de aprobación</b></Typography>
          <Button
            className={classes.updateButton}
            color="primary"
            variant="contained"
            onClick={handleRefetch}
            endIcon={<Cached />}
          >
            Actualizar
          </Button>
        </div>
        <div className={classes.headerButtonsContainer}>
          <Button
            variant="contained"
            className={classes.redButton}
            color="inherit"
            disabled={!selected.length}
            onClick={handleReject}
          >
            Rechazar giros
          </Button>
          <Button
            variant="contained"
            className={classes.greenButton}
            color="inherit"
            disabled={!selected.length}
            onClick={handleConfirm}
          >
            Aceptar giros
          </Button>
        </div>
      </div>
      {stateData.length > 0
        ? (
          <Grid container>
            <Grid item xs={6} sm={6} className={classes.searchBar}>
              <Filters
                allData={stateData}
                setFilteredData={setFilteredData}
                searchFields={['spanishTransferType', 'nameClient', 'rutClient']}
                searchPlaceholder="Búsqueda por rut o nombre de cliente o tipo de transferencia"
              />
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <Table
                headers={headers}
                setNewDataOrder={setFilteredData}
                data={filteredData}
                dataToRows={transformDataToComponents}
                initialOrderId="total"
                handleSelected={setSelected}
                withSelect
                clearSelection={loading}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography align="center" variant="h5">No hay transferencias en espera para ser aprobadas</Typography>
        )}
      {confirmOpen && selected.length
        && (
          <ApprovalDialog
            open={confirmOpen}
            title="Estás seguro que deseas CONFIRMAR estas transferencias?"
            onAccept={handleConfirmMoneyTransfers}
            onCancel={() => setConfirmOpen(false)}
            selected={selected}
            loading={modalLoading}
          />
        )}
      {rejectOpen && selected.length
        && (
          <ApprovalDialog
            open={rejectOpen}
            title="Estás seguro que deseas RECHAZAR estas transferencias?"
            onAccept={handleRejectMoneyTransfers}
            onCancel={() => setRejectOpen(false)}
            selected={selected}
            loading={modalLoading}
          />
        )}
    </div>
  );
};

export default WaitingApprovalConfirming;
