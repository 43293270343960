import { Card, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  totalAdvanceAmountFromObject,
  totalPriceDifferenceFromObject,
  totalAmountFromObject,
  totalRetentionFromObject,
  countInvoices,
  calculateCommission,
} from '../../helpers/factoringSimulation';
import { formatMoney } from '../../helpers/money';

const useStyles = makeStyles({
  card: {
    padding: '1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '20%',
    marginTop: 10,
    marginBottom: 10,
  },
  summaryRow: {
    padding: 5,
  },
  primarySummaryRow: {
    padding: 10,
  },
  caption: {
    marginLeft: '22%',
  },
});

const FactoringSimulationSummary = ({ dataToSimulate, setCommission }) => {
  const classes = useStyles();
  const [dataSummary, setDataSummary] = useState({});
  useEffect(() => {
    if (dataToSimulate && dataToSimulate[0]) {
      const summaryData = {
        countInvoices: countInvoices(dataToSimulate),
        totalAmount: totalAmountFromObject(dataToSimulate),
        totalPriceDifference: totalPriceDifferenceFromObject(dataToSimulate),
        totalRetention: totalRetentionFromObject(dataToSimulate),
        totalCommission: 0,
      };
      const commission = calculateCommission(
        dataToSimulate[0].companyCommissionRules,
        summaryData.totalAmount,
      );
      summaryData.totalCommission = commission;
      summaryData.totalAdvanceAmount = totalAdvanceAmountFromObject(dataToSimulate) - commission;
      setCommission(commission);
      setDataSummary(summaryData);
    }
  }, [dataToSimulate]);
  return (
    <>
      <Card className={classes.card}>
        <Grid container direction="column">
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6">Cantidad de facturas:</Typography>
            <Typography variant="subtitle1">{dataSummary.countInvoices}</Typography>
          </Grid>
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6">Monto total de las facturas:</Typography>
            <Typography variant="subtitle1">${formatMoney(dataSummary.totalAmount)}</Typography>
          </Grid>
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6">Retención total</Typography>
            <Typography variant="subtitle1">${formatMoney(dataSummary.totalRetention)}</Typography>
          </Grid>
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6">Comisión total</Typography>
            <Typography variant="subtitle1">${formatMoney(dataSummary.totalCommission)}</Typography>
          </Grid>
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6">Diferencia de precio total:</Typography>
            <TextField
              disabled
              style={{ maxWidth: '30%' }}
              type="text"
              value={formatMoney(dataSummary.totalPriceDifference)}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                style: { textAlign: 'right' },
              }}
            />
          </Grid>
          <Divider style={{ margin: 5 }} />
          <Grid className={classes.summaryRow} container direction="row" justify="space-between">
            <Typography variant="h6"><b>Total a girar:</b></Typography>
            <TextField
              disabled
              style={{ maxWidth: '30%' }}
              type="text"
              value={formatMoney(dataSummary.totalAdvanceAmount)}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                style: { textAlign: 'right', color: '#ED2064' },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

FactoringSimulationSummary.propTypes = {
  setCommission: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataToSimulate: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default FactoringSimulationSummary;
