import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Table } from '../tables';
import { parseMoney } from '../../helpers/money';

const DicomReport = ({ report }) => {
  const headers = [
    { id: 'amount', label: 'Monto', align: 'center' },
    { id: 'debtType', label: 'Tipo de deuda', align: 'center' },
    { id: 'doctoEntryDate', label: 'Fecha Docto', align: 'center' },
    { id: 'doctoNumber', label: 'Número Docto', align: 'center' },
    { id: 'documentType', label: 'Tipo de documento', align: 'center' },
    { id: 'drawer', label: 'Librador', align: 'center' },
    { id: 'expirationDate', label: 'Fecha de expiración', align: 'center' },
    { id: 'market', label: 'Mercado', align: 'center' },
  ];
  const [dataContent, setDataContent] = useState([]);

  useEffect(() => {
    if (report) setDataContent(report);
  }, [report]);

  const displayAmount = (amount, coinType) => {
    if (coinType === 'UF') return `UF ${amount}`;
    if (coinType === 'US') return `US ${parseMoney(amount)}`;
    return parseMoney(amount);
  };

  const dataToRows = (_data) => _data.map((row) => {
    const {
      createdAt,
      coinType,
      amount,
      debtType,
      doctoEntryDate,
      doctoNumber,
      documentType,
      drawer,
      expirationDate,
      market,
    } = row;
    return ({
      createdAt,
      doctoEntryDate: {
        value: doctoEntryDate,
        component: (
          <Typography>{doctoEntryDate}</Typography>
        ),
      },
      expirationDate: {
        value: expirationDate,
        component: (
          <Typography>{expirationDate}</Typography>
        ),
      },
      amount: {
        value: amount,
        component: (
          <Typography>{displayAmount(amount, coinType)}</Typography>
        ),
      },
      debtType: {
        value: debtType,
        component: (
          <Typography>{debtType}</Typography>
        ),
      },
      doctoNumber: {
        value: doctoNumber,
        component: (
          <Typography>{doctoNumber}</Typography>
        ),
      },
      documentType: {
        value: documentType,
        component: (
          <Typography>{documentType}</Typography>
        ),
      },
      drawer: {
        value: drawer,
        component: (
          <Typography>{drawer}</Typography>
        ),
      },
      market: {
        value: market,
        component: (
          <Typography>{market}</Typography>
        ),
      },
    });
  });
  return (
    <>
      {dataContent.length > 0 && (
        <Table
          headers={headers}
          data={dataContent}
          dataToRows={dataToRows}
          setNewDataOrder={setDataContent}
          rowsPerPageInit={7}
          initialOrderId="date"
        />
      )}
    </>
  );
};

DicomReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DicomReport;
