import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Button, InputAdornment, OutlinedInput, CircularProgress } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/client';
import { SearchOutlined } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import { Table } from '../tables';
import { useStyles } from '../../views/styles';
import MovementsTable from '../../views/MovementsTable';
import { CONCILIATE, GET_MOVEMENTS } from '../../gql/conciliations';
import { formatMoney } from '../../helpers/money';
import { getIds } from '../../helpers/conciliation';
import { permissionDeniedErrorCatcher } from '../../helpers/permissionDeniedError';
import ConciliaitonSuccessful from '../dialogs/ConciliationSuccessful';
import ConciliationConfirmation from '../dialogs/ConciliationConfirmation';

const IncomesTable = ({
  objectsToCollectHeaders,
  objectsToCollectConfirmationHeaders,
  objectsToCollectData,
  transformObjectsToComponents,
  objectsToCollectType,
  getObjectsCollectionManagers,
  refetchObjects,
  objectsFilterFunction,
}) => {
  const classes = {
    ...useStyles(),
  };

  const [objects, setObjects] = useState(objectsToCollectData);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [totalObjectSelected, setTotalObjectSelected] = useState(0);
  const [selectedMoves, setSelectedMoves] = useState([]);
  const [totalMoveSelected, setTotalMoveSelected] = useState(0);
  const [reset, setReset] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [filter, setFilter] = useState('');
  const [differences, setDifferences] = useState({});
  const [reasons, setReasons] = useState({});
  const [loadingConciliation, setLoadingConciliation] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [dates, setDates] = useState({});

  const {
    data: movementsData,
    loading: movementsLoading,
    refetch: refetchMovements,
    error: movementsErrors,
  } = useQuery(GET_MOVEMENTS, { variables: { movementType: 'incomes' } });

  const [conciliate] = useMutation(CONCILIATE);

  useEffect(() => {
    setObjects(objectsToCollectData);
  }, [objectsToCollectData]);

  useEffect(() => {
    setTotalObjectSelected(selectedObjects.reduce(
      (total, row) => total + row.amountWithIva.value, 0,
    ));
  }, [selectedObjects]);

  useEffect(() => {
    setTotalMoveSelected(selectedMoves.reduce((total, row) => total + row.amount.value, 0));
  }, [selectedMoves]);

  useEffect(() => {
    if (objectsToCollectData) {
      setObjects(objectsFilterFunction(objectsToCollectData, filter));
    }
  }, [filter]);

  const conciliationAction = async () => {
    setLoadingConciliation(true);
    await conciliate({ variables: {
      movements: getIds(selectedMoves),
      collectionManagers: getObjectsCollectionManagers(
        selectedObjects, differences, reasons, dates,
      ),
      conciliationType: 'Manual',
      conciliationDate: new Date(),
    } });
    setReset(true);
    refetchMovements();
    refetchObjects();
    setSelectedObjects([]);
    setSelectedMoves([]);
    setReset(false);
    setOpenDialog(false);
    setWarnings([]);
    setDifferences({});
    setReasons({});
    setLoadingConciliation(false);
    setSuccessDialog(true);
  };

  const match = (movements, invoices_) => {
    const diffs = [];
    const moveRut = movements[0].senderHolderId;
    const invoiceRut = invoices_[0].fullRut;
    if (moveRut !== invoiceRut) {
      diffs.push('El RUT del pagador y receptor no calzan');
    }
    if (movements.filter((move) => move.senderHolderId !== moveRut).length > 0) {
      diffs.push('Los movimientos pertenecen a más de un pagador');
    }
    if (invoices_.filter((invoice) => invoice.fullRut !== invoiceRut).length > 0) {
      diffs.push('Los documentos financiados pertenecen a más de un emisor');
    }
    if (totalObjectSelected !== totalMoveSelected) {
      diffs.push('Los montos totales no calzan');
    }
    setWarnings([...diffs]);
    return diffs;
  };

  const handleSelectedMoves = (moves) => {
    setSelectedMoves(moves);
    if (moves.length > 0) {
      const newObjects = [...objects].sort((a) => {
        if (moves[0].senderHolderId === a.debtor.rut.toString()) {
          return -1;
        }
        return 1;
      });
      setObjects(newObjects);
    }
  };

  const handleSelectedRowsCollapse = (_collapseRows) => {
    setSelectedObjects(_collapseRows);
  };

  if (permissionDeniedErrorCatcher(movementsErrors)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Box>
        <Grid container spacing={1} className={classes.conciliations}>
          <Grid item xs={6}>
            <MovementsTable
              movementType="incomes"
              setSelectedMoves={handleSelectedMoves}
              movementsData={movementsData ? movementsData.getMovements : []}
              movementsLoading={movementsLoading}
              reset={reset}
              totalSelected={totalMoveSelected}
              refetchMovements={refetchMovements}
              resetSelectedMoves={() => setSelectedMoves([])}
            />
          </Grid>
          <Grid item xs={6}>
            <Box component="div" className={classes.movements}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h4" className={classes.tableHeader}>
                    <b>{objectsToCollectType === 'PURCHASEORDERS' ? 'Órdenes de compra' : 'Facturas'}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    id="input-with-icon-adornment"
                    startAdornment={(
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                      )}
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </Grid>
              </Grid>
              <>
                {objects.length > 0 ? (
                  <>
                    <Box border={0.5} borderColor="grey.500" className={classes.tableBox}>
                      {objectsToCollectType === 'INSTALLMENTS' ? (
                        <Table
                          data={objects}
                          dataToRows={transformObjectsToComponents}
                          setNewDataOrder={setObjects}
                          headers={objectsToCollectHeaders}
                          withoutSelectHeader
                          clearSelection={reset}
                          pagination={false}
                          initialOrderId="receiverName"
                          collapse={{
                            withSelect: true,
                            handleSelected: handleSelectedRowsCollapse,
                          }}
                        />
                      ) : (
                        <Table
                          data={objects}
                          dataToRows={transformObjectsToComponents}
                          setNewDataOrder={setObjects}
                          headers={objectsToCollectHeaders}
                          handleSelected={setSelectedObjects}
                          withSelect
                          withoutSelectHeader
                          clearSelection={reset}
                          pagination={false}
                          initialOrderId="debtorName"
                        />
                      )}
                    </Box>
                    <Grid container justify="space-between" className={classes.totalSelected}>
                      <Grid item>
                        <Typography variant="h5">Total seleccionado:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5">${formatMoney(totalObjectSelected)}</Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Typography variant="h5" align="center" className={classes.listEmpty}>
                    {filter ? 'No hay coincidencias' : 'No hay facturas sin conciliar'}
                  </Typography>
                )}

              </>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" className={classes.buttonLine}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={
                selectedMoves.length === 0 || selectedObjects.length === 0 || loadingConciliation
              }
              onClick={() => {
                if (match(selectedMoves, selectedObjects).length === 0) {
                  conciliationAction();
                } else {
                  setOpenDialog(!openDialog);
                }
              }}
            >
              Conciliar
              {loadingConciliation && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConciliationConfirmation
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        warnings={warnings}
        conciliationAction={conciliationAction}
        invoices={selectedObjects}
        setSelectedInvoices={setSelectedObjects}
        totalInvoiceSelected={totalObjectSelected}
        totalMoveSelected={totalMoveSelected}
        differences={differences}
        setDifferences={setDifferences}
        reasons={reasons}
        setReasons={setReasons}
        loadingConciliation={loadingConciliation}
        setLoadingConciliation={setLoadingConciliation}
        dates={dates}
        setDates={setDates}
        header
        objectsHeaders={objectsToCollectConfirmationHeaders}
      />
      <ConciliaitonSuccessful
        open={successDialog}
        setOpen={setSuccessDialog}
      />
    </>
  );
};

IncomesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  objectsToCollectHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  objectsToCollectConfirmationHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  objectsToCollectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  transformObjectsToComponents: PropTypes.func.isRequired,
  objectsToCollectType: PropTypes.string.isRequired,
  getObjectsCollectionManagers: PropTypes.func.isRequired,
  refetchObjects: PropTypes.func.isRequired,
  objectsFilterFunction: PropTypes.func.isRequired,
};

export default IncomesTable;
