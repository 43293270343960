export default {
  'Evaluaciones de Riesgo': '/app/risk/evaluations',
  Blacklist: '/app/risk/blacklist',
  'Ratificación con prioridad': '/app/ratification/priority',
  'Ratificación sin prioridad': '/app/ratification/non-priority',
  'Revisión de OC y HES': '/app/without-ratification/oc-hes-review',
  'Disponible para giro': '/app/payment/available',
  Contactabilidad: '/app/risk/contactability',
  'Riesgo Cliente': '/app/risk/risk',
  'Estado de clientes': '/app/risk/customer-detail',
  'Gestor de clientes': '/app/risk/customer-maintainer',
  'Asignación de ejecutivos': '/app/risk/assignment-executive',
  'Gestor de ejecutivos': '/app/risk/executive-manager',
  'Administración de deudores': '/app/risk/debtor-administration',
  'Maestro de deudores': '/app/risk/debtors-panel',
  'Cobranza simple': '/app/collect/simple',
  'Cobranza judicial': '/app/collect/judiciary',
  'Planes de Pago': '/app/collect/payment-plans',
  'Egresos Anticipos': '/app/conciliate/paysheet-expenses',
  Whitelist: '/app/risk/whitelist',
  'Mails Masivos': '/app/risk/mass-email',
  Egresos: '/app/conciliate/expenses',
  Ingresos: '/app/conciliate/incomes',
  Algoritmo: '/app/risk/risk-algorithm',
  Motor: '/app/risk/risk-engine',
  'Ingresar facturas': '/app/confirming/upload-invoices',
  'Confirming en proceso': '/app/confirming/processing',
  'Confirming disponible para giro': '/app/confirming/available-for-transfer',
  'Empresas de Pronto Pago': '/app/confirming/prompt-payment-deal',
  'Crear Empresas': '/app/utils/create-company',
  'Crear Usuarios': '/app/utils/create-user',
  'Ingreso de facturas': '/app/ordering/add-invoices',
  'Documentos de cesión pendientes': '/app/ordering/pending-documents',
  'Órdenes disponibles para giro': '/app/ordering/available-for-transfer',
  'Solicitudes de activación': '/app/advance-payment/pending-activation',
  'Solicitudes de anticipo': '/app/advance-payment/active-requests',
  'Negociación de tasas': '/app/risk/rates-negotiation',
  'Descargas en fondos': '/app/funding/funds-download',
  'Ordenes externas': '/app/ordering/external-orders',
};
