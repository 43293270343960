import React, { useState, useEffect, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Tooltip,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Lock,
  AccountBalance,
  ListAlt,
  Assignment,
  TrendingUp,
  InsertDriveFile,
  Description,
  Check,
  Close,
  MoreHoriz,
  Create,
  CardMembership,
} from '@material-ui/icons';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import moment from 'moment';
import { UserContext } from '../context/user';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { TablePaginationBackend } from '../components/tables';
import { DocumentManager, CompanyInfoManager, OneButtonAlert } from '../components/dialogs';
import { GET_CUSTOMERS, UPLOAD_COMPANY_FILES, GET_COMMERCIAL_EXECUTIVES, GET_COMPANY_TGR, GET_COMPANY_ERUT, GET_URL_ERUT, SEND_CTE_SLACK, CREATE_COMPANY_RL } from '../gql/customer';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

import { IconFilters, ButtonFilters } from '../components/customerMaintainer';

const useOwnStyles = makeStyles((theme) => ({
  searchBox: {
    paddingLeft: '2rem',
    marginBottom: '2rem',
    marginRight: '1rem',
  },
  inputSearch: {
    height: 60,
  },

  greenColor: {
    background: theme.palette.success.main,
  },
  greenColorIcon: {
    color: theme.palette.success.main,
    marginRight: '0.5rem',
  },
  redColor: {
    background: theme.palette.error.main,
  },
  redColorIcon: {
    color: theme.palette.error.main,
    marginRight: '0.5rem',
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: '1rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  searchContainer: {
    display: 'flex',
  },
  searchButton: {
    marginBottom: '2rem',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyData: {
    marginTop: '2rem',
  },

}));

const marginRight = '0.5rem';

const headers = [
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'line', label: 'Línea', align: 'left', hideSortIcon: true },
  { id: 'totalExposure', label: 'Exposición total', align: 'left', hideSortIcon: true },
  {
    id: 'files',
    label: '',
    hideSortIcon: true,
    icon: (
      <>
        <Tooltip title="Términos y condiciones"><Create style={{ marginRight }} /></Tooltip>
        <Tooltip title="Contrato"><Assignment style={{ marginRight }} /></Tooltip>
        <Tooltip title="Balance"><AccountBalance style={{ marginRight }} /></Tooltip>
        <Tooltip title="EERR"><TrendingUp style={{ marginRight }} /></Tooltip>
        <Tooltip title="Equifax"><ListAlt style={{ marginRight }} /></Tooltip>
        <Tooltip title="TGR"><InsertDriveFile style={{ marginRight }} /></Tooltip>
        <Tooltip title="CTE"><Description style={{ marginRight }} /></Tooltip>
        <Tooltip title="Certificado digital"><CardMembership style={{ marginRight }} /></Tooltip>
        <Tooltip title="Clave de SII"><Lock style={{ marginRight }} /></Tooltip>
        <Tooltip title="E Rut"><FingerprintIcon style={{ marginRight }} /></Tooltip>
      </>),
  },
  {
    id: 'executiveAssigned',
    label: 'Ejecutivo asignado',
    align: 'left',
    filter: {
      titleTooltip: 'Filtrar por ejecutivos',
      dataList: [],
    },
    hideSortIcon: true,
  },
  {
    id: 'actions',
    label: 'Acciones',
    align: 'left',
    hideSortIcon: true,
  },
];

const headersForCommercialExecutives = [
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'line', label: 'Línea', align: 'left', hideSortIcon: true },
  { id: 'totalExposure', label: 'Exposición total', align: 'left', hideSortIcon: true },
  {
    id: 'files',
    label: '',
    hideSortIcon: true,
    icon: (
      <>
        <Tooltip title="Términos y condiciones"><Create style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="Contrato"><Assignment style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="Balance"><AccountBalance style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="EERR"><TrendingUp style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="Equifax"><ListAlt style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="TGR"><InsertDriveFile style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="CTE"><Description style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="Certificado digital"><CardMembership style={{ marginRight: '0.5rem' }} /></Tooltip>
        <Tooltip title="Clave de SII"><Lock style={{ marginRight: '0.5rem' }} /></Tooltip>
      </>),
  },
  {
    id: 'actions',
    label: 'Acciones',
    align: 'left',
    hideSortIcon: true,
  },
];

const CustomerMaintainer = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const history = useHistory();
  const { user: { user: { userGroups } } } = useContext(UserContext);
  const isCommercialExecutive = userGroups.indexOf('commercial_executive') !== -1;
  const initHeaders = isCommercialExecutive ? headersForCommercialExecutives : headers;
  const [clients, setClients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [lengthData, setLengthData] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialogDocumentManager, setOpenDialogDocumentManager] = useState(false);
  const [openDialogCompanyInfoManager, setOpenDialogCompanyInfoManager] = useState(false);
  const [headersState, setHeadersState] = useState(initHeaders);
  const [anchorElActionsMenu, setAnchorElActionsMenu] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [actualClient, setActualClient] = useState(null);
  const [objectOrder, setObjectOrder] = useState({ property: 'client', order: 'desc' });
  const [iconFilters, setIconFilters] = useState([]);
  const [buttonFilters, setButtonFilters] = useState([]);
  const [filterMetaValues, setFilterMetaValues] = useState(null);
  const [openWorkingSnack, setOpenWorkingSnack] = useState(false);
  const [openFinishedSnack, setOpenFinishedSnack] = useState(false);
  const [openSentCte, setOpenSentCte] = useState(false);
  const [cteSuccess, setCteSuccess] = useState(false);
  const [erutSuccess, setErutSuccess] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [openSentTgr, setOpenSentTgr] = useState(false);
  // the limit number must match with rowsPerPage of the Table
  const limit = 10;
  const [getClients,
    {
      data,
      loading,
      error,
    },
  ] = useLazyQuery(GET_CUSTOMERS, { fetchPolicy: 'network-only' });
  const { data: executivesData,
  } = useQuery(GET_COMMERCIAL_EXECUTIVES,
    { fetchPolicy: 'network-only' });
  const [getErutUrl, { data: urlData }] = useLazyQuery(GET_URL_ERUT, { fetchPolicy: 'network-only' });
  const [sendCte, { data: sentCte }] = useLazyQuery(SEND_CTE_SLACK, { fetchPolicy: 'network-only' });
  const [uploadFiles,
    {
      loading: loadingUploadFiles,
    },
  ] = useMutation(UPLOAD_COMPANY_FILES);
  const [uploadRL] = useMutation(CREATE_COMPANY_RL);
  const [errorUploadFiles, setErrorUploadFiles] = useState(false);
  const [
    getTgr,
    { data: sentTgr },
  ] = useMutation(GET_COMPANY_TGR);
  const [getERut] = useMutation(GET_COMPANY_ERUT);

  useEffect(() => {
    if (filterMetaValues && !isCommercialExecutive) {
      const executiveObject = headersState.find((obj) => obj.id === 'executiveAssigned');
      // eslint-disable-next-line prefer-destructuring
      executiveObject.filter.initState = filterMetaValues[0];
      const newHeaders = [...headers];
      setHeadersState(newHeaders);
    }
  }, [filterMetaValues]);

  useEffect(() => {
    if (executivesData && executivesData.getCommercialExecutives && !isCommercialExecutive) {
      const executivesDataList = executivesData.getCommercialExecutives.map((ex) => (
        { label: `${ex.firstName} ${ex.lastName}`, value: ex.email }
      ));
      executivesDataList.unshift({ label: 'Sin asignar', value: 'null' });
      const executiveObject = initHeaders.find((obj) => obj.id === 'executiveAssigned');
      executiveObject.filter.dataList = executivesDataList;
      const newHeaders = [...initHeaders];
      setHeadersState(newHeaders);
    }
  }, [executivesData]);

  useEffect(() => {
    if (data) {
      setClients(data.getCustomers.clients);
      setLengthData(data.getCustomers.length);
    }
  }, [data]);

  useEffect(() => {
    // the first query
    // the limit number must match with rowsPerPage of Table
    getClients(
      {
        variables: {
          searchValue,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaBool: [...iconFilters, ...buttonFilters],
        },
      },
    );
  }, []);

  useEffect(() => {
    if (objectOrder.property !== 'files') {
      getClients(
        {
          variables: {
            searchValue,
            offset: currentPage * limit,
            limit,
            order: objectOrder.order,
            propertyToOrder: objectOrder.property,
            filterMetaBool: [...iconFilters, ...buttonFilters],
          },
        },
      );
    }
  }, [objectOrder]);

  useEffect(() => {
    const newClients = clients.map(
      (client) => (client.masterEntity.rut === actualClient.rut ? { ...client, hasErut: erutSuccess === 'Success' } : client),
    );
    setClients(newClients);
  }, [erutSuccess]);

  const handleIconFilters = (_iconFilters) => {
    getClients(
      {
        variables: {
          searchValue,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaBool: [..._iconFilters, ...buttonFilters],
        },
      },
    );
    setIconFilters(_iconFilters);
  };

  const handleButtonFilters = (_buttonFilters) => {
    getClients(
      {
        variables: {
          searchValue,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaBool: [...iconFilters, ..._buttonFilters],
        },
      },
    );
    setButtonFilters(_buttonFilters);
  };

  const handleMutationCompanyInfo = async (phone, email, representativeName, representativeRut) => {
    const phoneNumber = `+56${phone}`;
    setOpenDialogCompanyInfoManager(false);
    try {
      await uploadRL({
        variables:
          {
            companyRut: actualClient.rut,
            representativeName,
            representativeRut,
            phoneNumber,
            email,
          },
      });
    } catch (e) {
      setErrorUploadFiles(true);
    }
  };

  const handleMutationUploadFiles = async (files) => {
    let arrayFilesMutation = [];
    Object.entries(files).forEach(([k, v]) => {
      if (v) {
        arrayFilesMutation = [...arrayFilesMutation,
          { documentType: k, fileCompany: v, rut: actualClient.rut }];
      }
    });
    try {
      await uploadFiles({ variables: { inputUploadFiles: arrayFilesMutation } });
    } catch (e) {
      setErrorUploadFiles(true);
    }
    setOpenAlert(true);
    getClients(
      {
        variables: {
          searchValue,
          offset: currentPage * limit,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaBool: [...iconFilters, ...buttonFilters],
        },
      },
    );
    setOpenDialogDocumentManager(false);
  };

  useEffect(() => {
    if (actualClient) {
      getErutUrl({ variables: { rut: actualClient.rut } });
    }
  }, [actualClient]);

  useEffect(() => {
    if (urlData) {
      if (urlData.getCompanyUrlErut) {
        setValidUrl(true);
      }
    }
  }, [urlData]);

  const handleSearch = () => {
    // the limit number must match with rowsPerPage of Table
    getClients(
      {
        variables: {
          searchValue,
          offset: 0,
          limit,
          order: objectOrder.order,
          propertyToOrder: objectOrder.property,
          filterMetaBool: [...iconFilters, ...buttonFilters],
        },
      },
    );
  };

  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const getDocumentName = (documentType) => {
    if (documentType === 'CONTRACT') {
      return 'Contrato';
    }
    if (documentType === 'EQUIFAX') {
      return 'Equifax';
    }
    if (documentType === 'BALANCE') {
      return 'Balance';
    }

    return documentType;
  };

  const handleOpenActionsMenu = (event, masterEntity, hasSiiCredentials) => {
    setAnchorElActionsMenu(event.currentTarget);
    const newMasterEntity = { ...masterEntity, hasSiiCredentials };
    setActualClient(newMasterEntity);
  };

  const closeMenuActions = () => {
    setAnchorElActionsMenu(null);
    setValidUrl(false);
  };

  const handleDocumentManager = () => {
    setOpenDialogDocumentManager(actualClient);
    closeMenuActions();
  };

  const handleCompanyInfoManager = () => {
    setOpenDialogCompanyInfoManager(true);
    closeMenuActions();
  };

  const handleInvoiceStatus = () => {
    const toRoute = `/app/client/${actualClient.rut}/invoice-status`;
    history.push({ pathname: toRoute, state: { name: actualClient.name, rut: actualClient.rut } });
  };

  const handleClientComissions = () => {
    const toRoute = `/app/client/${actualClient.rut}/manage-commissions`;
    history.push({ pathname: toRoute, state: { name: actualClient.name, rut: actualClient.rut } });
  };

  const handleTgr = async () => {
    await getTgr({ variables: { rut: actualClient.rut } });
  };

  const handleERut = async () => {
    closeMenuActions();
    setOpenWorkingSnack(true);
    const response = await getERut({ variables: { rut: actualClient.rut } });
    setOpenWorkingSnack(false);
    setErutSuccess(response.data.getCompanyErut.message);
    setOpenFinishedSnack(true);
  };

  const handleSendCte = async () => {
    closeMenuActions();
    await sendCte({ variables: { rut: actualClient.rut } });
  };

  useEffect(() => {
    if (sentCte) {
      setCteSuccess(sentCte.sendCteSlackMessage);
      setOpenSentCte(true);
    }
  }, [sentCte]);

  useEffect(() => {
    if (sentTgr) {
      setOpenSentTgr(true);
    }
  }, [sentTgr]);

  const newRequestWithOrder = (property) => {
    // newOrder can be 'asc' or 'desc'
    const orderBool = objectOrder.order === 'asc';
    const order = orderBool ? 'desc' : 'asc';
    setObjectOrder({ property, order });
  };

  const receiveFilterData = async (filterData) => {
    await getClients({
      variables: {
        offset: 0,
        limit,
        order: objectOrder.order,
        propertyOrder: objectOrder.property,
        filterMetaValues: [filterData],
      },
    });
    setFilterMetaValues([filterData]);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      masterEntity,
      totalExposeAmount,
      currentCreditLine,
      documents,
      hasSiiCredentials,
      backofficeHasAcceptedTerms,
      hasDigitalCertificate,
      executiveAssigned,
      style,
      hasErut,
    } = row;
    const percentageLine = (currentCreditLine?.creditUsed / currentCreditLine?.totalAmount) * 100;
    return ({
      id: masterEntity.rut,
      client: {
        value: masterEntity.name,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={masterEntity.name}>
            <div style={{ maxWidth: 300 }}>
              <Typography className={classes.oneLineClamp}>
                {masterEntity.name}
              </Typography>
              <Typography>
                {formatRut(masterEntity.rut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      line: {
        value: currentCreditLine ? currentCreditLine.totalAmount : 0,
        component: (
          <>
            {currentCreditLine && currentCreditLine.totalAmount > 0
              ? (
                <Tooltip
                  title={(
                    <div>
                      <Typography variant="body2">Utilizado: ${formatMoney(currentCreditLine.creditUsed)}</Typography>
                      <Typography variant="body2">Aprobado: ${formatMoney(currentCreditLine.totalAmount)}</Typography>
                    </div>
                  )}
                  placement="top-start"
                  arrow
                >
                  <div style={{ display: 'flex', width: '10vw', height: 20, margin: '2px 0 1rem' }}>
                    {(percentageLine !== 0) && <span className={classes.redColor} style={{ width: `${percentageLine}%` }} />}
                    <div style={{ width: `${100 - percentageLine}%`, display: 'flex' }}>
                      {(percentageLine !== 0) && <span className={classes.redColor} style={{ width: `${percentageLine}%` }} />}
                      {((100 - percentageLine) !== 0) && <span className={classes.greenColor} style={{ width: `${100 - percentageLine}%` }} />}
                    </div>
                  </div>
                </Tooltip>
              ) : <Typography>Sin línea de crédito</Typography>}
          </>
        ),
      },
      totalExposure: {
        value: totalExposeAmount,
        component: (
          <Typography align="left">${formatMoney(totalExposeAmount)}</Typography>
        ),
      },
      files: {
        value: null,
        component: (
          <>
            {backofficeHasAcceptedTerms ? (
              <Tooltip title="Tyc aceptados">
                <Check key={`tyc${masterEntity.rut}`} className={classes.greenColorIcon} />
              </Tooltip>
            )
              : (
                <Tooltip title="Tyc no aceptados">
                  <Close key={`not-tyc${masterEntity.rut}`} className={classes.redColorIcon} />
                </Tooltip>
              )}
            {documents.map(
              (document) => (document.exists ? (
                <Tooltip key={`tooltip-check${document.documentType}`} title={`Descargar ${getDocumentName(document.documentType)} ${moment(document.lastDate).format('DD-MM-YYYY')}`}>
                  <a href={document.lastFile} target="_blank" rel="noopener noreferrer">

                    <Check key={`icon-check${document.documentType}${masterEntity.rut}`} className={classes.greenColorIcon} />

                  </a>

                </Tooltip>
              )
                : (
                  <Tooltip key={`tooltip-close${document.documentType}${masterEntity.rut}`} title={`Sin ${getDocumentName(document.documentType)}`}>
                    <Close key={`icon-close${document.documentType}${masterEntity.rut}`} className={classes.redColorIcon} />
                  </Tooltip>
                )),
            )}
            {hasDigitalCertificate ? (
              <Tooltip key={`tooltip-check-digital-certificate${masterEntity.rut}`} title="Con certificado digital">
                <Check key={`icon-check-digital-certificate${masterEntity.rut}`} className={classes.greenColorIcon} />
              </Tooltip>
            )
              : (
                <Tooltip key={`tooltip-close-digital-certificate${masterEntity.rut}`} title="Sin certificado digital">
                  <Close key={`icon-close-digital-certificate${masterEntity.rut}`} className={classes.redColorIcon} />
                </Tooltip>
              )}
            {hasSiiCredentials ? (
              <Tooltip title="Con clave del SII">
                <Check key={`sii-credetentials${document.documentType}${masterEntity.rut}`} className={classes.greenColorIcon} />
              </Tooltip>
            )
              : (
                <Tooltip title="Sin clave del SII">
                  <Close key={`icon-close${document.documentType}${masterEntity.rut}`} className={classes.redColorIcon} />
                </Tooltip>
              )}
            {hasErut ? (
              <Tooltip title="Con E-Rut">
                <Check key={`sii-credetentials${document.documentType}${masterEntity.rut}`} className={classes.greenColorIcon} />
              </Tooltip>
            )
              : (
                <Tooltip title="Sin E-Rut">
                  <Close key={`icon-close${document.documentType}${masterEntity.rut}`} className={classes.redColorIcon} />
                </Tooltip>
              )}
          </>),
      },
      executiveAssigned: {
        value: executiveAssigned ? executiveAssigned.email : 'none',
        component: <Typography>{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>,
      },
      actions: {
        value: null,
        component: (
          <>
            <IconButton
              onClick={(e) => {
                handleOpenActionsMenu(e, masterEntity, hasSiiCredentials);
              }}
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              id={`menu-actions-${masterEntity.rut}`}
              anchorEl={anchorElActionsMenu}
              keepMounted
              open={Boolean(anchorElActionsMenu)}
              onClose={closeMenuActions}
              key={`menu-actions-${masterEntity.rut}`}
            >
              <MenuItem key={`menu-actions-invoice-status${masterEntity.rut}`} onClick={() => handleInvoiceStatus()}>
                Ver estado de facturas
              </MenuItem>
              <MenuItem key={`menu-actions-document-manager${masterEntity.rut}`} onClick={() => handleDocumentManager()}>
                Gestionar documentos
              </MenuItem>
              <MenuItem key={`menu-actions-tgr${masterEntity.rut}`} onClick={() => handleTgr()} disabled={openSentTgr}>
                Obtener cartola fiscal
              </MenuItem>
              <MenuItem key={`menu-actions-email-phone${masterEntity.rut}`} onClick={() => handleCompanyInfoManager()}>
                Agregar representante legal
              </MenuItem>
              {!isCommercialExecutive
                && (<MenuItem key={`menu-actions-comissions-manager${masterEntity.rut}`} onClick={() => handleClientComissions()}>Gestionar comisiones</MenuItem>)}
              <MenuItem key={`menu-actions-erut${masterEntity.rut}`} onClick={() => handleERut()}>
                Obtener E-Rut
              </MenuItem>
              <a
                href={urlData && urlData.getCompanyUrlErut}
                target="_blank"
                rel="noreferrer"
              >
                <MenuItem key={`menu-actions-download-erut${masterEntity.rut}`} disabled={!validUrl}>
                  Descargar E-Rut
                </MenuItem>
              </a>
              <MenuItem key={`menu-actions-send-cte${masterEntity.rut}`} onClick={() => handleSendCte()}>
                Enviar CTE por Slack
              </MenuItem>
            </Menu>
          </>

        ),
      },
      style,
    });
  });
  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (error) {
    return (<Typography variant="h3" style={{ margin: '2rem' }}> Hubo un error al cargar los clientes. Intenta de nuevo</Typography>);
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.row}>
          <Typography variant="h4"><b> Gestor de clientes</b></Typography>
        </div>
      </div>

      <Grid container className={classes.container}>

        <Grid item xs={12} className={classes.searchContainer}>
          <TextField
            value={searchValue}
            variant="outlined"
            onChange={handleChangeSearchValue}
            helperText="Busca a un cliente por rut o nombre de la empresa"
            className={classes.searchBox}
            inputProps={{
              style: {
                height: 32,
              },
            }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleSearch}
            className={classes.searchButton}
          >
            Buscar
          </Button>
          <IconFilters handleIconFilters={handleIconFilters} />
        </Grid>
        <Grid item xs={12} className={classes.searchContainer}>
          <ButtonFilters className={classes.searchBox} handleButtonFilters={handleButtonFilters} />
        </Grid>
        <Grid item xs={12} className={loading ? classes.loading : classes.tableContainer}>
          <TablePaginationBackend
            data={clients}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setClients}
            headers={headersState}
            data_type="customer"
            initialOrderId="client"
            fetchMore={getClients}
            filterVariables={{
              filterMetaBool: [...iconFilters, ...buttonFilters],
              filterMetaValues,
            }}
            lengthData={lengthData}
            searchValue={searchValue}
            setCurrentPageParent={setCurrentPage}
            newRequestWithOrder={newRequestWithOrder}
            loading={loading}
            objectOrder={objectOrder}
            sendFilterData={receiveFilterData}
            rowsPerPageInit={10}
          />
        </Grid>
      </Grid>

      <DocumentManager
        open={openDialogDocumentManager}
        handleClose={() => { setOpenDialogDocumentManager(false); }}
        handleConfirm={handleMutationUploadFiles}
        client={actualClient}
        loading={loadingUploadFiles}
      />
      <CompanyInfoManager
        open={openDialogCompanyInfoManager}
        handleClose={() => { setOpenDialogCompanyInfoManager(false); }}
        handleConfirm={handleMutationCompanyInfo}
      />
      <OneButtonAlert
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        message={errorUploadFiles ? 'Contáctate con el equipo de desarrollo' : ''}
        title={errorUploadFiles ? 'Error en la operación' : 'Archivos subidos con éxito'}
      />
      <Snackbar
        open={openWorkingSnack}
        TransitionComponent={Slide}
        autoHideDuration={12000}
        onClose={() => setOpenWorkingSnack(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert severity="info">Obteniendo E-Rut, puede tomar varios segundos...</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openFinishedSnack}
        TransitionComponent={Slide}
        autoHideDuration={6000}
        onClose={() => setOpenFinishedSnack(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {erutSuccess === 'Success' ? (
          <MuiAlert severity="success">E-Rut obtenido con exito!</MuiAlert>
        ) : (
          <MuiAlert severity="error">
            No se pudo obtener el E-Rut,
            {erutSuccess === 'No current sii credentials' && ' el cliente no ha ingresado credenciales válidas del SII'}
            {erutSuccess === 'Login error' && ' el cliente no tiene credenciales válidas del SII'}
            {erutSuccess === 'Error' && ' hubo un error en el intento'}
          </MuiAlert>
        )}
      </Snackbar>
      <Snackbar
        open={openSentCte}
        TransitionComponent={Slide}
        autoHideDuration={6000}
        onClose={() => setOpenSentCte(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert severity={cteSuccess ? 'info' : 'error'}>{cteSuccess ? 'CTE enviada por Slack' : 'No se pudo enviar la CTE'}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openSentTgr}
        TransitionComponent={Slide}
        autoHideDuration={10000}
        onClose={() => setOpenSentTgr(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert severity="info">
          Cartola fiscal enviada por Slack al canal
          <em>#credit_line_requests</em>, brevemente llegará una respuesta
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CustomerMaintainer;
