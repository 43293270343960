/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import {
  AccessTime,
  ErrorOutline,
  RadioButtonUnchecked,
  CheckCircleOutline,
  HelpOutline,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { statusToActiveStep, statusToStepLabels } from '../../helpers/invoiceStatus';

export const statusToIcon = {
  PROCESSING: <AccessTime style={{ color: '#ff9800' }} />,
  REJECTED: <ErrorOutline style={{ color: '#f44336' }} />,
  PENDINGRATIFICATION: <AccessTime style={{ color: '#ff9800' }} />,
  REJECTEDRATIFICATION: <ErrorOutline style={{ color: '#f44336' }} />,
  PENDINGTRANSFER: <AccessTime style={{ color: '#ff9800' }} />,
  TRANSFERED: <AccessTime style={{ color: '#ff9800' }} />,
  DEBT: <ErrorOutline style={{ color: '#f44336' }} />,
  FINISHED: <CheckCircleOutline style={{ color: '#4caf50' }} />,
  INFORMATION: <HelpOutline style={{ color: '#808080' }} />,
};

const useStyles = makeStyles({
  stepper: {
    padding: 0,
  },
  stepLabel: {
    marginTop: '4px !important',
    fontSize: 12,
  },
});

const getStatusIcon = (status, i) => {
  if (i < statusToActiveStep[status]) return <CheckCircleOutline style={{ color: '#4caf50' }} />;
  if (statusToActiveStep[status] === i) return statusToIcon[status];
  return <RadioButtonUnchecked />;
};

const Status = ({ status, paymentDate }) => {
  const classes = useStyles();
  return (
    <Stepper className={classes.stepper} activeStep={statusToActiveStep[status]} alternativeLabel>
      {statusToStepLabels(status).map((label, i) => (
        <Step key={label}>
          <StepLabel
            icon={getStatusIcon(status, i)}
            classes={{
              label: classes.stepLabel,
            }}
          >
            {label} <br /> {status === 'FINISHED' && statusToActiveStep[status] === i && paymentDate && moment(paymentDate).format('DD-MM-YYYY')}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf(['CREATED', 'PROCESSING', 'REJECTED', 'PENDINGRATIFICATION', 'REJECTEDRATIFICATION', 'PENDINGTRANSFER', 'TRANSFERED', 'DEBT', 'FINISHED', 'BADDEBT']).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paymentDate: PropTypes.object,
};

Status.defaultProps = {
  paymentDate: null,
};

export default Status;
