/* eslint-disable no-shadow */
import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { REVOKE_REFRESH_TOKEN } from '../gql/user';
import customMutation from '../helpers/graphql/customMutation';

const INITIAL_STATE = {
  user: null,
};

export const UserContext = createContext(INITIAL_STATE);

export class UserContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
    };
  }

  render() {
    const { children } = this.props;
    const { user } = this.state;
    return (
      <UserContext.Provider
        value={{
          user,
          login: (user) => {
            this.setState((oldState) => ({ ...oldState, user }));
            localStorage.setItem('user', JSON.stringify(user));
          },
          logout: () => {
            customMutation(REVOKE_REFRESH_TOKEN, { refreshToken: user.refreshToken });
            this.setState((oldState) => ({ ...oldState, user: null }));
            localStorage.setItem('user', null);
          },
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

UserContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const withUserContext = (ChildComponent) => (props) => (
  <UserContext.Consumer>
    {
      // eslint-disable-next-line react/jsx-props-no-spreading
      (userContext) => <ChildComponent {...props} userContext={userContext} />
    }
  </UserContext.Consumer>
);
