import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const DebtorProfileButtonColumn = ({ masterEntity, ratificator, collector }) => {
  const history = useHistory();

  const { id: debtorId, rut, name, siiSalesSection } = masterEntity;

  const debtorsProfileRedirect = () => history.push({
    pathname: `debtor-panel/${rut}`,
    state: {
      debtorId,
      rut,
      name,
      siiSalesSection,
      ratificator,
      collector,
    },
  });

  return (
    <Button
      variant="contained"
      onClick={debtorsProfileRedirect}
      color="primary"
    >
      Ver Deudor
    </Button>
  );
};

DebtorProfileButtonColumn.propTypes = {
  masterEntity: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ratificator: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  collector: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

DebtorProfileButtonColumn.defaultProps = {
  ratificator: null,
  collector: null,
};

export default DebtorProfileButtonColumn;
