import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core';

const useOwnStyles = makeStyles(() => ({
  button: {
    marginRight: '1rem',
  },
}));
const ButtonFilters = ({ className, handleButtonFilters }) => {
  const classes = useOwnStyles();
  // eslint-disable-next-line
  const [activeFilters, setActiveFilters] = useState({
    creditUsed: { activeFilter: false, propertyFilter: 'creditUsed' },
    exposedAmount: { activeFilter: false, propertyFilter: 'exposedAmount' },
  });
  // eslint-disable-next-line
  const handleChange = (name) => {
    setActiveFilters((prev) => (
      { ...prev,
        [name]: { ...prev[name], activeFilter: !prev[name].activeFilter },
      }));
  };

  useEffect(() => {
    handleButtonFilters(Object.values(activeFilters));
  }, [activeFilters]);
  const { creditUsed, exposedAmount } = activeFilters;
  return (
    <div className={className}>
      <Button size="small" onClick={() => handleChange('creditUsed')} className={classes.button} variant="outlined" color="primary">
        {!creditUsed.activeFilter ? 'Mostrar solo líneas utilizadas' : 'Mostrar todas las líneas'}
      </Button>
      <Button size="small" onClick={() => handleChange('exposedAmount')} className={classes.button} variant="outlined" color="primary">
        {!exposedAmount.activeFilter ? 'Mostrar solo exposiciones positivas' : 'Mostrar todas las exposiciones'}
      </Button>
    </div>
  );
};
ButtonFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.object.isRequired,
  handleButtonFilters: PropTypes.func.isRequired,
};

export default ButtonFilters;
