import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatRut } from '../../helpers/generic';
import { formatMoney } from '../../helpers/money';

const ConfirmRecesionDialog = ({
  open,
  onClose,
  onConfim,
  handleSeeRoster,
  roster,
  loading,
}) => {
  const internalOnClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={internalOnClose}
    >
      <DialogTitle>
        <Typography variant="h6">
          Confirmar recesión de nómina a {roster.fund.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 10 }}>
          <b>Nombre del cesionario:</b> {roster.cessionFund.socialReason}
        </Typography>
        <Typography style={{ marginBottom: 10 }}>
          <b>Rut del cesionario:</b> {formatRut(roster.cessionFund.rut)}
        </Typography>
        <Typography style={{ marginBottom: 10 }}>
          <b>Nombre del fondo:</b> {roster.fund.socialReason}
        </Typography>
        <Typography style={{ marginBottom: 10 }}>
          <b>Cantidad de facturas:</b> {roster.invoicesCount}
        </Typography>
        <Typography style={{ marginBottom: 10 }}>
          <b>Monto total:</b> ${formatMoney(roster.invoicesAmount)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSeeRoster(roster)}
        >
          Ver nómina
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onConfim}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={14} /> : null}
        >
          Receder nómina
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmRecesionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfim: PropTypes.func.isRequired,
  handleSeeRoster: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roster: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ConfirmRecesionDialog;
