const tooltipHandler = (amount, tooltipType) => {
  let tooltip = `Este cliente tiene ${amount}`;
  if (tooltipType === 'debt') {
    tooltip += ` ${amount === 1 ? 'factura en mora' : 'facturas en mora'}`;
    return tooltip;
  }

  tooltip += ` ${amount === 1 ? 'reintegración pendiente' : 'reintegraciones pendientes'}`;
  return tooltip;
};

export default tooltipHandler;
