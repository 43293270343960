import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const AddNewInvoice = ({
  open,
  onClose,
  onAccept,
  setDocumentId,
  setRate,
  setType,
  setRetentionRate,
  type,
  setExpirationDate,
  expirationDate,
  loading,
}) => {
  const minDate = moment().add(1, 'days');
  const handleIdChange = (event) => {
    setDocumentId(event.target.value);
  };
  const handleRateChange = (event) => {
    setRate(event.target.value);
  };
  const handleRetentionChange = (event) => {
    setRetentionRate(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const handleChangeDate = (date) => {
    setExpirationDate(date);
  };
  const internalOnClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={internalOnClose}
    >
      <DialogTitle>
        <Typography variant="h6">
          Agregar nueva factura a nómina
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography>
              Ingresa el ID del documento:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              type="text"
              onChange={(event) => handleIdChange(event)}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid>
            <Select
              value={type}
              onChange={(event) => handleTypeChange(event)}
            >
              <MenuItem value="invoice">Factura</MenuItem>
              <MenuItem value="confirming">Confirming</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Typography>
              Ingresa la tasa:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              onChange={(event) => handleRateChange(event)}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item>
            <Typography>
              Ingresa la tasa de Retención:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              onChange={(event) => handleRetentionChange(event)}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item>
            <Typography>
              Ingresa la fecha de expiración:
            </Typography>
            <DatePicker
              autoOk
              allowKeyboardControl={false}
              style={{ width: '90%' }}
              disableToolbar
              variant="inline"
              format="DD-MM-YYYY"
              margin="none"
              inputVariant="outlined"
              minDate={minDate}
              value={expirationDate || null}
              onChange={(date) => handleChangeDate(date)}
              inputProps={{
                style: {
                  padding: 5,
                  fontSize: 15,
                  cursor: 'pointer',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAccept}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={14} /> : null}
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNewInvoice.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  setDocumentId: PropTypes.func.isRequired,
  setRate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setRetentionRate: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  expirationDate: PropTypes.instanceOf(Date).isRequired,
  setExpirationDate: PropTypes.func.isRequired,
};

export default AddNewInvoice;
