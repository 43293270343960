import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useStyles } from './styles';
import { CREATE_COMPANY } from '../gql/customer';

const CreateCompany = () => {
  const classes = useStyles();
  const [createNewCompany] = useMutation(CREATE_COMPANY);
  const [companyRut, setCompanyRut] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const onSuccessCreate = () => {
    setCompanyRut('');
    setCompanyName('');
    setErrorMsg(null);
    setSuccessMsg('Empresa cargada con exíto');
  };

  const createCompany = () => {
    setErrorMsg(null);
    (async () => {
      try {
        await createNewCompany({
          variables: {
            rutWithDv: companyRut,
            name: companyName,
          },
        });
        onSuccessCreate();
      } catch (_error) {
        setErrorMsg(_error.message);
      }
    })();
  };

  const handleChangCompanyRut = (event) => {
    setCompanyRut(event.target.value);
  };

  const handleChangCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.headerContainer}>
        <Grid className={classes.titleAndUpdate}>
          <Typography variant="h4"><b>Creador de Empresas</b></Typography>
        </Grid>
      </Grid>
      <Grid container>
        <TextField
          value={companyRut}
          variant="outlined"
          onChange={handleChangCompanyRut}
          helperText="Ingresa Rut empresa Proovedor (con dv, sin '.')"
          inputProps={{
            style: {
              height: 20,
            },
          }}
        />
        <TextField
          value={companyName}
          variant="outlined"
          onChange={handleChangCompanyName}
          helperText="Razon Social"
          inputProps={{
            style: {
              height: 20,
            },
          }}
        />
        <Button
          className={classes.updateButton}
          color="primary"
          variant="contained"
          onClick={createCompany}
          disabled={companyName === '' || companyRut === ''}
        >
          Ingresar
        </Button>
        <Typography color="error">{errorMsg}</Typography>
        <Typography color="primary">{successMsg}</Typography>
      </Grid>
    </Grid>
  );
};

export default CreateCompany;
