import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Typography,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Table } from '../tables';
import { GET_PRE_OFFERS } from '../../gql/invoicesStatus';
import { formatMoney } from '../../helpers/money';
import { getTimeDiffText } from '../../helpers/date';
import transformInvoice from '../../helpers/invoiceTransform';
import { Filters } from '../utils';
import CustomerDocumentsMenu from '../customers/DocumentsMenu';
import { CreateFactoringSimulation, CreateNegotiation, UploadDocumentsManager } from '../dialogs';
import DebtorCell from '../shared/DebtorCell';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  textCell: {
    height: 24,
    fontSize: 12,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    lineHeight: '0.8rem !important',
  },
  date: {
    fontSize: 12,
    lineHeight: '1',
  },
  emptyInvoices: {
    marginTop: '2rem',
  },
  negotiationButton: {
    margin: 1,
    borderRadius: '5em',
  },
});

const preOfferStatusToText = {
  CREATED: 'Disponible',
  EVALUATING: 'En evaluación',
};

const headers = [
  { id: 'folio', label: 'Folio', isSearchable: true },
  { id: 'rutReceiver', label: 'Deudor', type: 'rut', isSearchable: true },
  { id: 'type', label: 'Tipo', type: 'text', isSearchable: false },
  { id: 'collectorName', label: 'Cobrador', type: 'text', isSearchable: true },
  { id: 'dateIssued', label: 'Fecha de emisión', type: 'date', isSearchable: true },
  { id: 'dateEvaluation', label: 'Fecha de evaluación', type: 'date', isSearchable: true },
  { id: 'dateExpiration', label: 'Fecha de vencimiento', type: 'date', isSearchable: true },
  { id: 'amountWithIva', label: 'Monto facturado', type: 'money', isSearchable: true },
  { id: 'monthlyRate', label: 'Tasa mensual', type: 'rate', isSearchable: true },
  { id: 'monthlyWLRate', label: 'Tasa Whitelist mensual', type: 'rate', isSearchable: true },
  { id: 'defaultRate', label: 'Mora mensual', className: 'xs', type: 'rate', isSearchable: false },
  { id: 'advancePercentage', label: 'Porcentaje anticipado', className: 'xs', type: 'rate', isSearchable: true },
  { id: 'status', label: 'Estado', className: 'xs', type: 'preOfferStatus', isSearchable: true },
  { id: 'negotiation', label: 'Negociar tasas', className: 'xs', type: 'negotiateRates', isSearchable: false, hideSortIcon: true },
  { id: 'simulation', label: 'Crear simulación', className: 'xs' },
  { id: 'documents', label: 'Gestor de Documentos', align: 'center', hideSortIcon: true },
];

const Preoffers = ({ setLoading, evaluation }) => {
  const { clientRut } = useParams();
  const classes = useStyles();
  const [preoffers, setPreoffers] = useState([]);
  const [filteredPreoffers, setFilteredPreoffers] = useState([]);
  const [buttonStates, setButtonStates] = useState({});
  const {
    loading: loadingPreoffers,
    refetch: preoffersRefetch,
  } = useQuery(
    GET_PRE_OFFERS,
    {
      variables: { ruts: [clientRut], evaluation },
      fetchPolicy: 'network-only',
      onCompleted: (_data) => {
        setPreoffers(_data.getPreoffersNp.map(transformInvoice));
        setFilteredPreoffers(_data.getPreoffersNp.map(transformInvoice));
        if (_data.getPreoffersNp.length > 0) {
          setButtonStates(
            _data.getPreoffersNp.reduce((acc, row) => ({ ...acc, [row.id]: true }), {}),
          );
        }
      },
    },
  );
  const [selectedForButtons, setSelectedForButtons] = useState([]);
  const [openNegotiation, setOpenNegotiation] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openSimulation, setOpenSimulation] = useState(false);
  const [simulationData, setSimulationData] = useState([]);
  const [negotiationData, setNegotiationData] = useState([]);
  const [clearSelection, setClearSelection] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  useEffect(() => {
    setLoading(loadingPreoffers);
  }, [loadingPreoffers]);

  const openPdf = (pdf) => {
    window.open(pdf);
  };

  useEffect(() => {
    if (selectedForButtons) {
      const baseButtonStates = preoffers.reduce((acc, row) => ({ ...acc, [row.id]: true }), {});
      setButtonStates(selectedForButtons.reduce((acc, row) => (
        { ...acc, [row.id]: false }), baseButtonStates));
    }
  }, [selectedForButtons]);

  const handleNegotiationClick = () => {
    if (selectedForButtons.length > 0) {
      setNegotiationData(selectedForButtons.map((row) => ({
        id: row.id,
        folio: row.folio.value,
        rutReceiver: row.rutReceiver.value,
        nameReceiver: row.nameReceiver,
        monthlyRate: row.monthlyRate.value,
        receiver: row.receiver,
        defaultRate: row.defaultRate.value,
        retentionRate: 100 - row.advancePercentage.value,
      })));
      setOpenNegotiation(true);
    }
  };

  const handleSimulationClick = () => {
    if (selectedForButtons.length > 0) {
      setSimulationData(selectedForButtons.map((row) => ({
        id: row.invoiceId,
        folio: row.folio.value,
        nameReceiver: row.nameReceiver,
        rutReceiver: row.rutReceiver.value,
        receiver: row.receiver,
        companyCommissionRules: row.currentCommissionRules,
        monthlyRate: row.monthlyRate.value,
        defaultRate: row.defaultRate.value,
        retentionRate: 100 - row.advancePercentage.value,
        amountWithIva: row.amountWithIva.value,
      })));
      setOpenSimulation(true);
    }
  };

  const onSubmitNegotiation = () => {
    setSelectedForButtons([]);
    setNegotiationData([]);
    setClearSelection(true);
    preoffersRefetch();
    setClearSelection(false);
    setOpenNegotiation(false);
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      invoiceId,
      nonSelectable,
      folio,
      nameReceiver,
      rutReceiver,
      collector,
      dateIssued,
      dateExpiration,
      amountWithIva,
      receiver,
      preoffer: { negotiationRequestStatus, preofferevaluationrequest,
        invoice: {
          objectPurchaseOrder,
          documents,
          certificateUrl,
        },
      },
      monthlyRate,
      defaultRate,
      advancePercentage,
      status,
      confirming,
      pdf,
      company,
    } = row;
    const createdAtpreOfferEvaluationRequest = (preoffer) => {
      if (preoffer) {
        return moment(preoffer.createdAt);
      }

      return null;
    };
    const resolvedDate = createdAtpreOfferEvaluationRequest(preofferevaluationrequest);
    const monthlyWLRates = company.masterEntity.rateWhitelistEmitter;
    let monthlyWLRate = null;
    if (monthlyWLRates) {
      monthlyWLRate = monthlyWLRates.find((x) => !x.receiver || x.receiver.rut === rutReceiver);
    }
    let negotiationDisabled = false;
    let negotiationMessage = 'Negociar';
    if (negotiationRequestStatus !== null) {
      switch (negotiationRequestStatus) {
        case 'PendingResponse':
          negotiationMessage = 'Negociando';
          negotiationDisabled = true;
          break;
        case 'Rejected':
          negotiationMessage = 'Renegociar';
          break;
        case 'Responded':
          negotiationMessage = 'Renegociar';
          break;
        default:
          break;
      }
    }
    return ({
      id,
      invoiceId,
      disabled: negotiationDisabled,
      nameReceiver,
      nonSelectable,
      receiver,
      currentCommissionRules: company.currentCommissionRules,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography className={classes.textCell}>{folio}</Typography>
            <IconButton onClick={() => openPdf(pdf)}>
              <InsertDriveFile />
            </IconButton>
          </div>),
        size: 'small',
      },
      type: {
        value: confirming,
        component: <Typography className={classes.textCell}>{confirming ? 'CONFIRMING' : 'FACTORING'}</Typography>,
        size: 'small',
      },
      rutReceiver: {
        value: rutReceiver,
        component: <DebtorCell debtorId={receiver.id} name={nameReceiver} rut={rutReceiver} />,
        size: 'small',
      },
      collectorName: {
        value: null,
        component: <Typography className={classes.textCell}>{collector ? `${collector.firstName} ${collector.lastName}` : 'Sin asignar'}</Typography>,
        size: 'small',
      },
      dateIssued: {
        value: dateIssued,
        component: (
          <div>
            <Typography className={classes.date}>{dateIssued.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateIssued)}</Typography>
          </div>
        ),
        size: 'small',
      },
      dateEvaluation: {
        value: preofferevaluationrequest,
        component: (
          <div>
            <Typography className={classes.date}>
              {resolvedDate
                ? resolvedDate.format('DD-MM-YYYY HH:mm:ss')
                : 'No existe evaluación'}
            </Typography>
            <Typography variant="caption">
              {resolvedDate
                ? getTimeDiffText(resolvedDate)
                : 'No existe evaluación'}
            </Typography>
          </div>
        ),
        size: 'small',
      },
      dateExpiration: {
        value: dateExpiration,
        component: (
          <div>
            <Typography className={classes.date}>{dateExpiration.format('DD-MM-YYYY')}</Typography>
            <Typography variant="caption">{getTimeDiffText(dateExpiration)}</Typography>
          </div>
        ),
        size: 'small',
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography className={classes.textCell}>${formatMoney(amountWithIva)}</Typography>
        ),
        size: 'small',
      },
      monthlyRate: {
        value: monthlyRate,
        component: (
          <Typography className={classes.textCell}>{(monthlyRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      monthlyWLRate: {
        value: monthlyWLRate,
        component: (
          <Typography className={classes.textCell}>{monthlyWLRate ? `${(monthlyWLRate.monthlyRate).toFixed(2)}%` : '-'}</Typography>
        ),
        size: 'small',
      },
      defaultRate: {
        value: defaultRate,
        component: (
          <Typography className={classes.textCell}>{(defaultRate).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      advancePercentage: {
        value: advancePercentage,
        component: (
          <Typography className={classes.textCell}>{(advancePercentage).toFixed(2)}%</Typography>
        ),
        size: 'small',
      },
      status: {
        value: status,
        component: (
          <Typography className={classes.textCell}>{preOfferStatusToText[status]}</Typography>
        ),
        size: 'small',
      },
      negotiation: {
        value: negotiationDisabled,
        component: (
          <Button
            className={classes.negotiationButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleNegotiationClick}
            disabled={(buttonStates && buttonStates[id])
              || negotiationDisabled}
          >
            {negotiationMessage}
          </Button>
        ),
        size: 'small',
      },
      simulation: {
        component: (
          <Button
            className={classes.negotiationButton}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSimulationClick}
            disabled={(buttonStates && buttonStates[id])}
          >
            Crear simulación
          </Button>
        ),
        size: 'small',
      },
      documents: {
        value: null,
        component: (
          <>
            <CustomerDocumentsMenu
              uploadOption
              onUploadClick={() => {
                setOpenDocuments(true);
                setSelectedInvoice(invoiceId);
              }}
              purchaseOrder={objectPurchaseOrder}
              pdf={pdf}
              certificate={certificateUrl}
              companyRut={clientRut}
              documents={documents}
            />
          </>
        ),
      },
    });
  });
  return (
    <Grid container>
      <Grid item xs={6} sm={6} className={classes.searchBar}>
        <Filters
          allData={preoffers}
          setFilteredData={setFilteredPreoffers}
          searchFields={['folio', 'nameReceiver', 'rutReceiver']}
          searchPlaceholder="Búsqueda por folio o rut o nombre de deudor"
        />
      </Grid>
      {(filteredPreoffers.length > 0)
        ? (
          <Table
            headers={headers}
            initialOrderId="monthlyRate"
            setNewDataOrder={setFilteredPreoffers}
            data={filteredPreoffers}
            dataToRows={transformDataToComponents}
            withSelect
            withoutSelectHeader
            handleSelected={setSelectedForButtons}
            clearSelection={clearSelection}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            {!loadingPreoffers && <Typography variant="h4" align="center" className={classes.emptyInvoices}>No se encontraron facturas </Typography>}
          </Grid>
        )}
      <CreateNegotiation
        open={openNegotiation}
        onClose={() => { setOpenNegotiation(false); }}
        onSubmit={onSubmitNegotiation}
        negotiationData={negotiationData}
        companyId={filteredPreoffers?.[0]?.companyId}
        negotiationType="PREOFFER"
      />
      <CreateFactoringSimulation
        openSimulation={openSimulation}
        onClose={() => { setOpenSimulation(false); }}
        onSubmit={handleSimulationClick}
        selectedRowsData={simulationData}
        type="PREOFFER"
      />
      <UploadDocumentsManager
        open={openDocuments}
        handleClose={() => { setOpenDocuments(false); }}
        invoiceId={selectedInvoice}
        refetchPage={() => preoffersRefetch()}
      />
    </Grid>

  );
};

Preoffers.propTypes = {
  setLoading: PropTypes.func,
  evaluation: PropTypes.bool.isRequired,
};

Preoffers.defaultProps = {
  setLoading: () => { },
};

export default Preoffers;
