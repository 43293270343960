import { Typography } from '@material-ui/core';
import { Cancel, CheckCircle, HelpOutline } from '@material-ui/icons';
import React from 'react';

export const changeNumberColor = (variable, classes) => (
  <Typography
    className={
      variable > 0.5 ? classes.success : classes.failure
    }
  >
    <b>{variable}</b>
  </Typography>
);

export const setResultIcon = (variable, classes) => {
  if (variable === null) { return <HelpOutline />; }
  if (variable) { return <CheckCircle className={classes.success} />; }
  return <Cancel className={classes.failure} />;
};

export const operatorMapper = {
  GT: '>',
  LT: '<',
  EQ: '=',
  BO: '=',
};

export const tabMapper = {
  0: 'client',
  1: 'debtor',
  2: 'clientDebtor',
};

export const titleMapper = {
  debtor: 'deudor',
  client: 'cliente',
  clientDebtor: 'relación',
  TREE: 'Árbol',
  RAS: 'RAS',
};
