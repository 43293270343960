import {
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Table } from '../components/tables';
import { useStyles } from './styles';
import { formatRut } from '../helpers/generic';
import { GET_EVALUATION_SIMULATIONS, REJECT_ORDERING_SIMULATION } from '../gql/ordering';
import { formatMoney } from '../helpers/money';
import SelectForm from '../components/utils/SelectForm';
import { daysBetween } from '../helpers/date';

const headers = [
  { id: 'orderNumber', label: 'Númer de orden', align: 'left' },
  { id: 'name', label: 'Comprador', align: 'left' },
  { id: 'orderingAmount', label: 'Monto orden', align: 'left' },
  { id: 'publicationDate', label: 'Fecha de publicación', align: 'left' },
  { id: 'invoiceIssuedDate', label: 'Emisión de Factura', align: 'left' },
  { id: 'invoiceDateToPay', label: 'Fecha de pago', align: 'left' },
];

const rejectionReasons = [
  { label: 'Se supera el nivel de endeudamiento objetivo de Fingo', value: 'OVER_INDEBTEDNESS_FINGO' },
  { label: 'Cliente con nivel de endeudamiento excesivo', value: 'OVER_INDEBTEDNESS_CLIENT' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a ventas', value: 'OVER_INDEBTEDNESS_CLIENT_SALES' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a patrimonio', value: 'OVER_INDEBTEDNESS_CLIENT_EQUITY' },
  { label: 'Existencia de procedimientos judiciales en curso', value: 'JUDICIAL_PROCEDEMENT' },
  { label: 'Existencia de procedimientos administrativos en curso', value: 'ADMINISTRATIVE_PROCEDURES' },
  { label: 'Antecedentes inexactos o insuficientes', value: 'INACCURATE_BACKGROUND' },
  { label: 'Cliente con morosidades o protestos según Ley 19.268', value: 'CLIENT_OVERDUE' },
  { label: 'Deudor con morosidades o protestos según Ley 19.268', value: 'DEBTOR_OVERDUE' },
  { label: 'Incumplimiento previo con Fingo o con una de sus filiales', value: 'PREVIOUS_NON_COMPLIANCE' },
  { label: 'Conflicto de interés en virtud de una causa legal', value: 'INTEREST_CONFLICT' },
  { label: 'No cumple con antigüedad mínima en la actividad ', value: 'SENIORITY' },
  { label: 'Presenta inestabilidad en sus ingresos', value: 'UNSTABLE_INCOME' },
  { label: 'Otro', value: 'OTHER' },
];

const SimulationToReject = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const history = useHistory();

  const [simulations, setSimulations] = useState(state?.selectedSimulations);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [otherRejectionReason, setOtherRejectionReason] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [RejectOrderingSimulation] = useMutation(
    REJECT_ORDERING_SIMULATION,
    {
      refetchQueries: [
        { query: GET_EVALUATION_SIMULATIONS },
      ],
    },
  );

  const handleReject = async () => {
    const resolvedPreOffers = [];
    simulations.forEach((preOffer) => {
      const {
        orderingsimulation,
      } = preOffer;
      resolvedPreOffers.push({
        requestId: orderingsimulation?.id,
        rejectionReason,
        otherRejectionReason,
      });
    });
    await RejectOrderingSimulation({
      variables:
        {
          requestResolutions: resolvedPreOffers,
          analystDescription: '',
          informClients: false,
          sendEmail: true,
        },
    });
    history.push({ pathname: '/app/risk/evaluations', state: { tab: 1 } });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'otherRejectionReason') {
      setOtherRejectionReason(value);
    } else {
      setRejectionReason(value);
    }
  };

  const transformDataToComponents = (data) => data?.map((row) => {
    const {
      id,
      orderNumber,
      publicationDate,
      purchaser: { rut, name },
      totalAmount,
      orderingsimulation: {
        invoiceIssuedDate,
        invoiceDateToPay,
      },
    } = row;
    return ({
      id,
      orderNumber: {
        value: orderNumber,
        component: <Typography align="left">{orderNumber}</Typography>,
      },
      name: {
        value: name,
        component: (
          <Grid>
            <Typography align="left">{name}</Typography>
            <Typography align="left">{formatRut(rut)}</Typography>
          </Grid>
        ),
      },
      orderingAmount: {
        value: totalAmount,
        component: <Typography align="left">${formatMoney(totalAmount)}</Typography>,
      },
      publicationDate: {
        value: publicationDate,
        component: (
          <Grid style={{ minWidth: 90 }}>
            <Typography align="left">{moment(publicationDate).format('DD-MM-YYYY')}</Typography>
          </Grid>
        ),
      },
      invoiceIssuedDate: {
        value: invoiceIssuedDate,
        component: (
          <Grid style={{ minWidth: 90 }}>
            <Typography align="left">{moment(invoiceIssuedDate).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceIssuedDate))} días</Typography>
          </Grid>
        ),
      },
      invoiceDateToPay: {
        value: invoiceDateToPay,
        component: (
          <Grid style={{ minWidth: 90 }}>
            <Typography align="left">{moment(invoiceDateToPay).format('DD-MM-YYYY')}</Typography>
            <Typography align="left">En {daysBetween(new Date(), new Date(invoiceDateToPay))} días</Typography>
          </Grid>
        ),
      },
    });
  });

  return (
    <Grid className={classes.container}>
      <Grid className={classes.titleAndUpdate}>
        <IconButton onClick={history.goBack}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h4"><b>Rechazar simulación de {simulations[0].company.name}</b></Typography>
        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start' }}>
          <SelectForm
            reasons={rejectionReasons}
            inputText="Selecciona el motivo de rechazo"
            name="rejectionReason"
            onChange={(event) => handleChange(event)}
          />
        </div>
      </Grid>
      <Grid container className={classes.container} alignItems="center">
        <Grid item xs={12} sm={12} className={classes.tableContainer}>
          <Table
            data={simulations}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setSimulations}
            headers={headers}
            initialOrderId="id"
          />
        </Grid>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Estás seguro que deseas rechazar esta orden de compra?
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleReject}>Rechazar</Button>
          </DialogActions>
        </Dialog>
        <Button
          variant="contained"
          color="primary"
          disabled={!rejectionReason}
          className={classes.redButton}
          onClick={() => setOpenDialog(true)}
        >
          Rechazar
        </Button>
      </Grid>
    </Grid>
  );
};

export default SimulationToReject;
