import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CREATE_RATIFICATION_BLACKLIST, GET_RATIFICATION_ACTIONS_ENUM, GET_RATIFICATION_CHANNEL_ENUM } from '../../gql/ratification';
import RatificationContactsTable from './RatificationContactsTable';
import ConfirmRatificationBlacklist from './ConfirmRatificationBlacklist';

const useStyles = makeStyles({
  title: {
    padding: '0.5rem',
  },
  subTitles: {
    paddingTop: '1.5rem',
  },
  sendButtonContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '1rem',
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  dialog: {
    width: '150%',
  },
  warningButton: {
    backgroundColor: '#BC211D',
    color: 'white',
    '&:hover': {
      color: '#BC211D',
    },
    borderColor: '#BC211D',
  },
  marginTop: {
    marginTop: '1rem',
  },
});

const AddRatificationActions = ({
  open,
  onClose,
  nameReceiver,
  rutReceiver,
  onAccept,
  ratificationInvoicesRefetch,
  ratificationBlacklistReceiver,
}) => {
  const classes = useStyles();
  const [actionTypeOptions, setActionTypeOptions] = useState([]);
  const [actionChannelOptions, setActionChannelOptions] = useState([]);
  const [selectedActionType, setSelectedActionType] = useState('');
  const [selectedActionChannel, setSelectedActionChannel] = useState('');
  const [comment, setComment] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [responded, setResponded] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [error, setError] = useState('');
  const [blacklist, setBlacklist] = useState(ratificationBlacklistReceiver);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [createRatificationBlacklist] = useMutation(CREATE_RATIFICATION_BLACKLIST);
  const { data: ratificationActionEnumData } = useQuery(GET_RATIFICATION_ACTIONS_ENUM);
  const { data: ratificationChannelEnumData } = useQuery(GET_RATIFICATION_CHANNEL_ENUM);

  useEffect(() => {
    if (ratificationActionEnumData && ratificationActionEnumData.getRatificationActionEnum) {
      setActionTypeOptions(ratificationActionEnumData.getRatificationActionEnum);
    }
  }, [ratificationActionEnumData, ratificationChannelEnumData]);

  useEffect(() => {
    if (ratificationChannelEnumData && ratificationChannelEnumData.getRatificationChannelEnum) {
      setActionChannelOptions(ratificationChannelEnumData.getRatificationChannelEnum);
    }
  }, [ratificationChannelEnumData]);

  const handleSelectChange = (e) => {
    setSelectedActionType(e.target.value);
  };

  const handleChannelChange = (e) => {
    if (e.target.value === 'MANUAL_MAIL') {
      setResponded(false);
    }
    setSelectedActionChannel(e.target.value);
  };
  const handleTextChange = (e) => {
    setComment(e.target.value);
  };

  const handleContactSelect = (_selectedRows) => {
    setSelectedContacts(_selectedRows.map((row) => ({
      id: row.id,
      name: row.name.value,
    })));
  };

  const handleRespondedCheckbox = (e) => {
    setResponded(e.target.checked);
  };

  const hasButtonDisabled = () => {
    if (!selectedActionType
    || !selectedActionChannel
    || (selectedContacts.length === 0 && selectedActionType !== 'OTHER')
    || (selectedActionType === 'OTHER' && !comment)) {
      return true;
    }
    return false;
  };

  const handleAccept = async () => {
    if (selectedContacts.length <= 0 && selectedActionType !== 'OTHER') {
      setError('Debes seleccionar al menos 1 contacto');
      return;
    }
    if (selectedActionType === 'OTHER' && !comment) {
      setError('El campo de texto no puede estar vacío');
      return;
    }
    setError('');
    setLoadingResponse(true);
    await onAccept(
      selectedActionType,
      comment,
      selectedContacts.map((contact) => parseInt(contact.id, 10)),
      responded,
      selectedActionChannel,
    );
    setLoadingResponse(false);
    setSelectedActionType('');
    setComment('');
    setSelectedContacts([]);
  };

  const handleRatificationBlacklist = async () => {
    await createRatificationBlacklist({
      variables: {
        masterEntityId: rutReceiver,
        activate: !blacklist,
      },
    });
    await ratificationInvoicesRefetch();
    setBlacklist(!blacklist);
    setConfirmOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <ConfirmRatificationBlacklist
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleRatificationBlacklist={handleRatificationBlacklist}
      />
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" align="center">Añadir gestión</Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Typography align="center" className={classes.subTitles}><b>Selecciona el tipo de gestión</b></Typography>
        <RadioGroup
          aria-label="rejection-reason"
          value={selectedActionType}
          onChange={handleSelectChange}
          row
        >
          {actionTypeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        <Typography align="center" className={classes.subTitles}><b>Selecciona el canal de la gestión</b></Typography>
        <RadioGroup
          aria-label="rejection-reason"
          value={selectedActionChannel}
          onChange={handleChannelChange}
          row
        >
          {actionChannelOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        {(selectedActionChannel === 'PHONE_CALL') && (
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={responded} onClick={handleRespondedCheckbox} name="responded" />} label="¿Contestó la llamada?" />
          </FormGroup>
        )}
        <Divider />
        <Typography align="center" className={classes.subTitles}>
          <b>Selecciona los contactos de la gestión</b>
        </Typography>
        <RatificationContactsTable
          companyName={nameReceiver}
          companyRut={rutReceiver}
          onSelect={handleContactSelect}
        />
        <Divider />
        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder={selectedActionType === 'OTHER' ? 'Por favor, indica la gestión' : 'Ingresa un comentario'}
          onChange={handleTextChange}
          variant="outlined"
          value={comment}
          className={classes.marginTop}
        />
      </DialogContent>
      <DialogActions>
        <div className={classes.sendButtonContainer}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.warningButton}
            onClick={() => setConfirmOpen(true)}
          >
            Deudor {!blacklist && 'no '}ratifica
          </Button>
          <Typography color="error" variant="caption">{error}</Typography>
          <Button
            onClick={() => { handleAccept(); }}
            color="primary"
            variant="outlined"
            endIcon={loadingResponse}
            disabled={hasButtonDisabled()}
            startIcon={loadingResponse
              ? <CircularProgress size={16} /> : null}
          >
            Añadir gestión
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddRatificationActions.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  nameReceiver: PropTypes.string.isRequired,
  rutReceiver: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  ratificationInvoicesRefetch: PropTypes.func,
  ratificationBlacklistReceiver: PropTypes.bool,
};

AddRatificationActions.defaultProps = {
  ratificationInvoicesRefetch: () => {},
  ratificationBlacklistReceiver: null,
};

export default AddRatificationActions;
