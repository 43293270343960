import React, { useState, useEffect } from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useStyles } from '../../views/styles';
import { GET_PENDING_COLLECTION_NOTES } from '../../gql/conciliations';
import { formatMoney } from '../../helpers/money';
import { getCollectionManagersFromCollectionNote, filterCollectionNotes } from '../../helpers/conciliation';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import IncomesTable from './incomesTable';

const IncomesCollectionNotes = () => {
  const classes = {
    ...useStyles(),
  };

  const [collectionNotes, setCollectionNotes] = useState([]);

  const {
    data: notesData,
    loading: notesLoading,
    refetch: refetchNotes,
  } = useQuery(GET_PENDING_COLLECTION_NOTES);

  useEffect(() => {
    if (notesData) {
      setCollectionNotes(notesData.getPendingCollectionNotes.map((note) => (
        { ...note, debtor: note.company }
      )));
    }
  }, [notesData]);

  const headersNotes = [
    { id: 'debtorName', label: 'Razón Social Deudor', align: 'left' },
    { id: 'amountWithIva', label: 'Monto', align: 'left' },
  ];

  const confirmationHeadersNotes = [
    { id: 'amountWithIva', label: 'Monto', align: 'left' },
    { id: 'actualDate', label: 'Fecha de Pago', align: 'left' },
  ];

  const transformCollectionNotesToComponents = (_data) => _data.map((row) => {
    const {
      id,
      debtor: {
        name: debtorName,
        masterEntity: {
          rut: debtorRut,
          dv: debtorDv,
        },
      },
      amountOfMoneyToCollect: amountWithIva,
    } = row;
    const collectionManagersIds = row.collectionManagers.map((collectionManager) => (
      collectionManager.id
    ));
    const collectionManagerId = collectionManagersIds[0];
    const fullRut = debtorRut.toString() + debtorDv;
    return ({
      id,
      collectionManagersIds,
      collectionManagerId,
      debtorName: {
        value: debtorName,
        component: (
          <Typography className={classes.twoLineClamp}>{debtorName}</Typography>
        ),
      },
      amountWithIva: {
        value: amountWithIva,
        component: (
          <Typography>${formatMoney(amountWithIva)}</Typography>
        ),
      },
      fullRut,
    });
  });

  if (notesLoading) {
    return (<LinearProgress />);
  }

  return (
    <IncomesTable
      objectsToCollectHeaders={headersNotes}
      objectsToCollectConfirmationHeaders={confirmationHeadersNotes}
      objectsToCollectData={collectionNotes}
      transformObjectsToComponents={transformCollectionNotesToComponents}
      objectsToCollectType="NOTES"
      getObjectsCollectionManagers={getCollectionManagersFromCollectionNote}
      refetchObjects={refetchNotes}
      objectsFilterFunction={filterCollectionNotes}
    />
  );
};

export default IncomesCollectionNotes;
