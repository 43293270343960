/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_AVAILABLE_FOR_TRANSFER_INVOICES = gql`
  query GetAvailableForTransferInvoices {
    getAvailableForTransferInvoices {
      id
      status
      invoice {
        id
        folio
        isFirstOperation
        company {
          id
          rut
          name
          amountOfPendingReintegrations
          executiveAssigned {
            firstName
            lastName
          }
          companycompliance {
            id
            readyForTransfer
            status
            requirementsClean
            information
          }
        }
        objectPurchaseOrder {
          exists
          url
        }
        certificateUrl
        documents {
          id
          url
          type {
            id
            name
            code
          }
        }
        orderingAssigned {
          purchaseOrder {
            orderNumber
          }
          amount
        }
        dateIssued
        integrationStatus
        siiStatus
        creditNoteStatus
        rutReceiver
        nameReceiver
        dateToPay
        amountWithIva
        offer {
          retentionRate
        }
        pdf
        paymentDiscounts {
          interestAsociated
          commissionAsociated
          negativeSurplusAsociated
          paymentAmountAfterNegativeSurplus
        }
        lastHistoryStatus {
          status
          createdAt
        }
      }
    }
    getPendingReintegrationInvoices {
      id
      folio
      company {
        id
        rut
        name
      }
      rutReceiver
      nameReceiver
      dateToPay
      status
      amountWithIva
    }
  }
`;

export const CREATE_REINTEGRATION = gql`
  mutation CreateReintegration($owedInvoicesIds: [Int]!, $payingInvoicesIds: [Int]!) {
    createReintegration(owedInvoicesIds: $owedInvoicesIds, payingInvoicesIds: $payingInvoicesIds) {
      success
    }
  }
`;

export const REJECT_MONEY_TRANSFERS = gql`
  mutation RejectMoneyTransfers($moneyTransfers: [RejectedMoneyTransferType]!) {
    rejectMoneyTransfers(rejectedMoneyTransfers: $moneyTransfers) {
      success
    }
  }
`;

export const GENERATE_TREASURY_MESSAGE = gql`
  mutation GenerateTreasuryMessage($moneyTransfers: [RequestedMoneyTransferType]!) {
    generateTreasuryMessage(moneyTransfers: $moneyTransfers) {
      createdObject {
        id
        message
      }
    }
  }
`;

export const REQUEST_MONEY_TRANSFERS = gql`
  mutation RequestMoneyTransfers(
    $requestedMoneyTransfers: [RequestedMoneyTransferType]!,
    $treasuryMessageId: String!
  ) {
    requestMoneyTransfers(
      requestedMoneyTransfers: $requestedMoneyTransfers,
      treasuryMessageId: $treasuryMessageId
    ) {
      success
    }
  }
`;

export const DELETE_TREASURY_MESSAGE = gql`
  mutation DeleteTreasuryMessage($id: String!) {
    deleteTreasuryMessage(id: $id) {
      success
    }
  }
`;

export const CONFIRM_MONEY_TRANSFERS = gql`
  mutation confirmMoneyTransfers ($confirmationReceipts: [MoneyTransferConfirmationReceipt]!) {
    confirmMoneyTransfers(confirmationReceipts: $confirmationReceipts) {
      success
    }
  }
`;
