import React, { useState } from 'react';
import { CircularProgress, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import OrderingSimulationEvaluationRequests from './OrderingSimulationEvaluationRequests';
import PreofferEvaluationRequests from './PreofferEvaluationRequests';
import { GET_PREOFFERS_REQUESTS } from '../gql/customer';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import { formatMoney } from '../helpers/money';

const EvaluationsRequests = () => {
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useState(location.state?.tab || 0);
  const [sortedSimulations, setSortedSimulations] = useState([]);
  const [sortedPreoffers, setSortedPreoffers] = useState([]);

  const groupFactoringPreoffers = (preoffers) => preoffers.filter(
    (e) => e.node.company.invoiceInEvaluation.length !== 0,
  ).map(
    (row) => {
      // eslint-disable-next-line no-underscore-dangle
      const row_ = row.node;
      const {
        rut,
        name,
        riskBlacklist,
        creditLine,
        company: {
          executiveAssigned,
          invoiceInEvaluation,
          amountOfPendingReintegrations,
          amountDebtInvoices,
        },
      } = row_;
      const debtorsQuantity = Array.from(
        new Set(invoiceInEvaluation.map((item) => item.receiver.rut)),
      ).length;
      const preOffersQuantity = invoiceInEvaluation.length;
      const preOffersAmount = invoiceInEvaluation.reduce(
        (previousValue, currentValue) => previousValue + currentValue.amountWithIva.amount,
        0,
      );
      const firstRequestDate = invoiceInEvaluation.reduce(
        (previousValue, currentValue) => (
          previousValue < currentValue.preoffer.preofferevaluationrequest.createdAt
            ? previousValue : currentValue.preoffer.preofferevaluationrequest.createdAt
        ),
        invoiceInEvaluation[0].preoffer.preofferevaluationrequest.createdAt,
      );
      const requestDate = invoiceInEvaluation.reduce(
        (previousValue, currentValue) => (
          previousValue > currentValue.preoffer.preofferevaluationrequest.createdAt
            ? previousValue : currentValue.preoffer.preofferevaluationrequest.createdAt
        ),
        invoiceInEvaluation[0].preoffer.preofferevaluationrequest.createdAt,
      );
      const orderingAssignations = [...new Set(
        invoiceInEvaluation
          .flatMap((invoice) => invoice.orderingAssigned)
          .map((assignation) => assignation?.purchaseOrder?.orderNumber),
      )];
      // if undefined use 0
      const creditLineLeft = (
        creditLine ? `$${formatMoney((creditLine?.limitAmount?.amount || 0) - (creditLine?.creditUsed?.amount || 0))}` : 'Sin línea'
      );

      return {
        rut,
        name,
        amountOfPendingReintegrations,
        amountDebtInvoices,
        riskBlacklist,
        executiveAssigned,
        preOffersQuantity,
        debtorsQuantity,
        preOffersAmount,
        firstRequestDate,
        requestDate,
        orderingAssignations,
        creditLineLeft,
      };
    },
  );

  const groupOrderingPreoffers = (preoffers) => preoffers.filter(
    (e) => e.node.company.orderingInEvaluation.length !== 0,
  ).map(
    (row) => {
      // eslint-disable-next-line no-underscore-dangle
      const row_ = row.node;
      const {
        rut,
        name,
        riskBlacklist,
        creditLine,
        company: {
          orderingInEvaluation,
          executiveAssigned,
          amountOfPendingReintegrations,
          amountDebtInvoices,
        },
      } = row_;
      const simulationsQuantity = orderingInEvaluation.length;
      const simulationsAmount = orderingInEvaluation.reduce(
        (previousValue, currentValue) => previousValue + currentValue.totalAmount.amount,
        0,
      );
      const purchasersQuantity = Array.from(
        new Set(orderingInEvaluation.map((item) => item.purchaser.rut)),
      ).length;
      const requestDate = orderingInEvaluation.reduce(
        (previousValue, currentValue) => (
          previousValue > currentValue.createdAt ? previousValue : currentValue.createdAt
        ),
        orderingInEvaluation[0].createdAt,
      );
      const firstRequestDate = orderingInEvaluation.reduce(
        (previousValue, currentValue) => (
          previousValue < currentValue.createdAt ? previousValue : currentValue.createdAt
        ),
        orderingInEvaluation[0].createdAt,
      );
      const creditLineLeft = (
        creditLine ? `$${formatMoney((creditLine?.limitAmount?.amount || 0) - (creditLine?.creditUsed?.amount || 0))}` : 'Sin línea'
      );
      return {
        rut,
        name,
        amountOfPendingReintegrations,
        amountDebtInvoices,
        executiveAssigned,
        riskBlacklist,
        simulationsQuantity,
        simulationsAmount,
        purchasersQuantity,
        requestDate,
        firstRequestDate,
        creditLineLeft,
      };
    },
  );

  const {
    refetch,
    loading,
    error,
  } = useQuery(GET_PREOFFERS_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSortedPreoffers(groupFactoringPreoffers(data.getMasterEntities.edges));
      setSortedSimulations(groupOrderingPreoffers(data.getMasterEntities.edges));
    },
  });

  if (
    permissionDeniedErrorCatcher(error)
  ) {
    history.replace('/');
  }

  const handleRefetch = () => {
    setSortedPreoffers([]);
    setSortedSimulations([]);
    refetch();
  };

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Grid>
      <br />
      <Tabs
        variant="fullWidth"
        value={tab}
        onChange={(e, value) => setTab(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Factoring ({sortedPreoffers.length})</Typography>} />
        <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Ordering ({sortedSimulations.length})</Typography>} />
      </Tabs>
      {tab === 0 && (
        <PreofferEvaluationRequests
          sortedPreoffers={sortedPreoffers}
          setSortedPreoffers={setSortedPreoffers}
          refetch={handleRefetch}
        />
      )}
      {tab === 1 && (
        <OrderingSimulationEvaluationRequests
          sortedSimulations={sortedSimulations}
          setSortedSimulations={setSortedSimulations}
          refetch={handleRefetch}
        />
      )}
    </Grid>
  );
};

export default EvaluationsRequests;
