import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  TextField,
} from '@material-ui/core';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 400,
  },
  section: {
    marginBottom: '2rem',
  },
  textSection: {
    marginBottom: '1rem',
  },
});

const EditExecutiveProportions = ({
  open,
  executives,
  handleClose,
  handleUpdateExecutive,
}) => {
  const classes = useStyles();
  const [assignationType, setAssignationType] = useState('Login');
  const [selectedExecutives, setSelectedExecutives] = useState([]);

  useEffect(() => {
    if (executives) {
      if (assignationType === 'Login') {
        setSelectedExecutives(
          executives.filter(
            (executive) => (executive.active === true && executive.loginActiveAssignation === true),
          ),
        );
      } else {
        setSelectedExecutives(
          executives.filter(
            (executive) => (executive.active === true && executive.clickActiveAssignation === true),
          ),
        );
      }
    }
  }, [executives, assignationType]);

  const handleDisableSaveButton = () => {
    if (assignationType === 'Login') {
      return selectedExecutives.reduce(
        (total, row) => total + row.loginAssignationProportion, 0,
      ) !== 1.0;
    }

    return selectedExecutives.reduce(
      (total, row) => total + row.clickAssignationProportion, 0,
    ) !== 1.0;
  };
  const setProportionType = (e, i) => {
    const { value } = e.target;
    const newSelectedExecutives = [...selectedExecutives];
    if (assignationType === 'Login') {
      newSelectedExecutives[i]
        .loginAssignationProportion = parseFloat(value) ? parseFloat(value) : value;
    } else {
      newSelectedExecutives[i]
        .clickAssignationProportion = parseFloat(value) ? parseFloat(value) : value;
    }
    setSelectedExecutives(newSelectedExecutives);
  };

  const handleUpdateExecutiveonConfirm = () => {
    handleClose();
    handleUpdateExecutive(assignationType, selectedExecutives);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"><b>Editar Proporciones de Asignacion de Ejecutivos</b></DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <Box className={classes.section}>
          <FormControl fullWidth>
            <InputLabel id="active-executive">Tipo de Asignacion</InputLabel>
            <Select
              labelId="active-executive"
              id="demo-simple-select"
              label="Ejecutivo Activo"
              value={assignationType}
              onChange={(e) => setAssignationType(e.target.value)}
            >
              <MenuItem value="Login">Login</MenuItem>
              <MenuItem value="Click">Click</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {selectedExecutives.map((executive, i) => {
          const {
            user: {
              firstName,
              lastName,
            },
            loginAssignationProportion,
            clickAssignationProportion,
          } = executive;
          return (
            <div className={classes.textSection}>
              <Typography>{firstName} {lastName}</Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e) => setProportionType(e, i)}
                value={assignationType === 'Click' ? clickAssignationProportion : loginAssignationProportion}
              />
            </div>
          );
        })}
        <Button
          variant="contained"
          onClick={() => handleUpdateExecutiveonConfirm()}
          color="primary"
          disabled={handleDisableSaveButton()}
        >
          Guardar
        </Button>
      </DialogContent>
    </Dialog>
  );
};

EditExecutiveProportions.propTypes = {
  open: PropTypes.bool.isRequired,
  executives: PropTypes.func.isRequired,
  handleUpdateExecutive: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditExecutiveProportions;
