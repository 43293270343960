import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, Grid, Button } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { Table } from '../components/tables';
import { CustomerTableComponent } from '../components/customers';
import { formatMoney } from '../helpers/money';
import { useStyles } from './styles';
import ClientSellsExcel from '../components/risk/ClientSellsExcel';
import OrderingAssignationTooltip from '../components/ordering/OrderingAssignationTooltip';

const headers = [
  { id: 'rut', label: 'Empresa', align: 'left' },
  { id: 'executive', label: 'Ejecutivo Asignado', align: 'left' },
  { id: 'preOffersQuantity', label: 'Cantidad de facturas', align: 'right' },
  { id: 'debtorsQuantity', label: 'Cantidad de deudores', align: 'right' },
  { id: 'preOffersAmount', label: 'Monto total', align: 'right' },
  { id: 'firstRequestDate', label: 'Fecha primera solicitud', align: 'right' },
  { id: 'requestDate', label: 'Fecha última solicitud', align: 'right' },
  { id: 'creditLineStatus', label: 'Línea disponible', align: 'right' },
  { id: 'orderingPayment', label: 'Para pago de OCs', align: 'right' },
  { id: 'invoicesButton', label: '', align: 'center' },
];

const PreofferEvaluationRequests = ({ sortedPreoffers, setSortedPreoffers, refetch }) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const handleRefetch = async () => {
    await refetch();
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const goToCompanyPreOffers = (
      rut,
    ) => history.push({
      pathname: `${location.pathname}/preoffer/${rut}`,
    });
    const {
      rut,
      name,
      amountOfPendingReintegrations,
      amountDebtInvoices,
      riskBlacklist,
      executiveAssigned,
      preOffersQuantity,
      debtorsQuantity,
      preOffersAmount,
      firstRequestDate,
      requestDate,
      orderingAssignations,
      creditLineLeft,
    } = row;
    return ({
      rut: {
        value: rut,
        component: (
          <CustomerTableComponent
            rut={rut}
            name={name}
            amountOfPendingReintegrations={amountOfPendingReintegrations}
            amountDebtInvoices={amountDebtInvoices}
            riskBlacklist={Boolean(riskBlacklist)}
          />
        ),
      },
      executive: {
        value: null,
        component: (
          <>
            <Typography align="left">{executiveAssigned ? `${executiveAssigned.firstName} ${executiveAssigned.lastName}` : 'Sin asignar'}</Typography>
          </>
        ),
      },
      preOffersAmount: {
        value: preOffersAmount,
        component: (
          <>
            <Typography align="left">${formatMoney(preOffersAmount)}</Typography>
          </>
        ),
      },
      preOffersQuantity: {
        value: preOffersQuantity,
        component: (
          <>
            <Typography align="left">{preOffersQuantity} facturas</Typography>
          </>
        ),
      },
      debtorsQuantity: {
        value: debtorsQuantity,
        component: (
          <>
            <Typography align="left">{debtorsQuantity} deudores</Typography>
          </>
        ),
      },
      firstRequestDate: {
        value: firstRequestDate,
        component: (
          <>
            <Typography align="left">{moment(firstRequestDate).format('DD-MM-YYYY HH:mm')}</Typography>
          </>
        ),
      },
      requestDate: {
        value: requestDate,
        component: (
          <>
            <Typography align="left">{moment(requestDate).format('DD-MM-YYYY HH:mm')}</Typography>
          </>
        ),
      },
      creditLineStatus: {
        value: creditLineLeft,
        component: (
          <>
            <Typography align="left">{creditLineLeft}</Typography>
          </>
        ),
      },
      orderingPayment: {
        value: orderingAssignations,
        component: <OrderingAssignationTooltip orderNumbers={orderingAssignations} />,
      },
      invoicesButton: {
        value: 'null',
        component: (
          <>
            {preOffersQuantity > 0 ? (
              <Button
                variant="contained"
                onClick={() => goToCompanyPreOffers(rut)}
                color="primary"
              >
                Ver solicitudes
              </Button>
            ) : null}
          </>
        ),
      },
    });
  });

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}><b>Clientes que solicitaron evaluación de pre ofertas</b></Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRefetch}
          endIcon={<Cached />}
        >
          Actualizar
        </Button>
      </div>
      <ClientSellsExcel />
      {sortedPreoffers.length > 0 ? (
        <Grid container>
          <Grid item xs={12} className={classes.tableContainer}>
            <Table
              headers={headers}
              setNewDataOrder={setSortedPreoffers}
              data={sortedPreoffers}
              dataToRows={transformDataToComponents}
              initialOrderId="firstRequestDate"
              initialOrder="asc"
            />
          </Grid>
        </Grid>
      ) : (
        <Typography align="center" variant="h5">No hay solicitudes pendientes</Typography>
      )}
    </div>
  );
};

PreofferEvaluationRequests.propTypes = {
  sortedPreoffers: PropTypes.objectOf(PropTypes.objects).isRequired,
  setSortedPreoffers: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PreofferEvaluationRequests;
