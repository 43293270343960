import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Grid, LinearProgress, Tooltip, Typography, Snackbar } from '@material-ui/core';
import { Cached, DoneAll } from '@material-ui/icons';
import { PROCESSING_CONFIRMING, CHECK_PROCESSING_CONFIRMING } from '../gql/confirming';
import { formatRut } from '../helpers/generic';
import { useStyles } from './styles';
import { formatMoney } from '../helpers/money';
import { getTimePassedTextFromTime, getTimePassedFromDate } from '../helpers/date';
import { Table } from '../components/tables';
import { Filters } from '../components/utils';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import DebtorCell from '../components/shared/DebtorCell';

const headers = [
  { id: 'folio', label: 'Folio', align: 'left' },
  { id: 'client', label: 'Cliente', align: 'left' },
  { id: 'debtor', label: 'Deudor', align: 'left' },
  { id: 'amount', label: 'Monto Factura', align: 'right' },
  { id: 'timeInStage', label: 'Tiempo en esta Etapa', align: 'right' },
];

const ConfirmingProcessing = () => {
  const classes = useStyles();
  const { data, refetch, error, loading } = useQuery(
    PROCESSING_CONFIRMING, { notifyOnNetworkStatusChange: true },
  );
  const [checkAllConfirming] = useMutation(CHECK_PROCESSING_CONFIRMING);
  const [invoices, setInvoices] = useState([]);
  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      folio,
      company: {
        masterEntity: {
          name: companyName,
          rut: companyRut,
        },
      },
      receiver: {
        id: receiverId,
        rut: rutReceiver,
        name: nameReceiver,
      },
      amountWithIva,
      createdAt,
    } = row;

    return ({
      id,
      folio: {
        value: folio,
        component: (
          <div className={classes.row}>
            <Typography>{folio}</Typography>
          </div>
        ),
      },
      client: {
        value: companyName,
        component: (
          <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={companyName}>
            <div style={{ maxWidth: 200 }}>
              <Typography className={classes.oneLineClamp}>
                {companyName}
              </Typography>
              <Typography>
                {formatRut(companyRut)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      debtor: {
        value: nameReceiver,
        // <Tooltip interactive classes={{ tooltip: classes.tooltip }} title={nameReceiver}>
        //   <div style={{ maxWidth: 200 }}>
        //     <Typography className={classes.oneLineClamp}>
        //       {nameReceiver}
        //     </Typography>
        //     <Typography>
        //       {formatRut(rutReceiver)}
        //     </Typography>
        //   </div>
        // </Tooltip>
        component: <DebtorCell debtorId={receiverId} name={nameReceiver} rut={rutReceiver} />,
      },
      amount: {
        value: amountWithIva,
        component: (
          <Typography align="right">${formatMoney(amountWithIva.amount)}</Typography>
        ),
      },
      timeInStage: {
        value: getTimePassedFromDate(createdAt),
        component: (
          <Typography align="right">{getTimePassedTextFromTime(getTimePassedFromDate(createdAt))}</Typography>
        ),
      },
    });
  });

  useEffect(() => {
    if (data) {
      setInvoices(data.processingConfirming);
      setFilteredInvoices(data.processingConfirming);
    }
  }, [data]);

  if (permissionDeniedErrorCatcher(error)) {
    return <Redirect to="/" />;
  }
  if (loading) return <LinearProgress />;
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackBarOpen(false)}
        message="Las facturas de confirming se procesarán. Esto puede demorar un tiempo"
        disableWindowBlurListener
        autoHideDuration={5000}
      />
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.titleAndUpdate}>
            <Typography variant="h4"><b>Confirming en proceso de ser cedidas</b></Typography>
            <Button
              className={classes.updateButton}
              color="primary"
              variant="contained"
              onClick={() => refetch()}
              endIcon={<Cached />}
            >
              Actualizar
            </Button>
            <Button
              className={classes.greenButton}
              color="primary"
              variant="contained"
              onClick={() => { checkAllConfirming(); setSnackBarOpen(true); }}
              endIcon={<DoneAll />}
            >
              Procesar confirmings
            </Button>
          </div>
        </div>
        {invoices.length > 0
          ? (
            <Grid container>
              <Grid item xs={6} sm={6} className={classes.searchBar}>
                <Filters
                  allData={invoices}
                  setFilteredData={setFilteredInvoices}
                  searchFields={['folio', 'nameReceiver', 'rutReceiver', 'nameClient', 'rutClient']}
                  searchPlaceholder="Búsqueda por folio o rut o nombre de cliente o deudor"
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="timeInStage"
                  setNewDataOrder={setFilteredInvoices}
                  data={filteredInvoices}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography align="center" variant="h5">No hay operaciones de confirming en espera de ser cedidas</Typography>
          )}
      </div>
    </>
  );
};

export default ConfirmingProcessing;
