import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useStyles } from './styles';
import { CREATE_USER } from '../gql/user';

const CreateUser = () => {
  const classes = useStyles();
  const [createNewUser] = useMutation(CREATE_USER);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyRut, setCompanyRut] = useState('');
  const [sendEmail, setSendMail] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleChangeEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setUserPassword(event.target.value);
  };

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeCompanyRut = (event) => {
    setCompanyRut(event.target.value);
  };

  const handleChangeSendEmail = (event) => {
    const { checked } = event.target;
    setSendMail(checked);
  };

  const enableButton = () => (
    companyRut !== '' && userEmail !== '' && userPassword !== ''
  );

  const onSuccessCreate = () => {
    setUserEmail('');
    setUserPassword('');
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setCompanyRut('');
    setSendMail(false);
    setErrorMsg(null);
    setSuccessMsg('Usuario creado con exíto');
  };

  const autoGeneratePassword = () => {
    const length = 20;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setUserPassword(result);
  };

  const createUser = () => {
    (async () => {
      try {
        await createNewUser({
          variables: {
            email: userEmail,
            password: userPassword,
            firstName: firstName !== '' ? firstName : null,
            lastName: lastName !== '' ? lastName : null,
            phoneNumber: phoneNumber !== '' ? phoneNumber : null,
            companyRut,
            sendEmail,
          },
        });
        onSuccessCreate();
      } catch (_error) {
        setErrorMsg(_error.message);
      }
    })();
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.headerContainer}>
        <Grid className={classes.titleAndUpdate}>
          <Typography variant="h4"><b>Crear Usuarios</b></Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.columnDirection}>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Email</Typography>
          <TextField
            value={userEmail}
            variant="outlined"
            onChange={handleChangeEmail}
            helperText="(Requerido)"
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Password</Typography>
          <TextField
            value={userPassword}
            variant="outlined"
            onChange={handleChangePassword}
            helperText="(Requerido)"
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
          <Button
            className={classes.updateButton}
            color="primary"
            variant="contained"
            onClick={autoGeneratePassword}
          >autogenerar
          </Button>
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Nombre</Typography>
          <TextField
            value={firstName}
            variant="outlined"
            onChange={handleChangeFirstName}
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Apellido</Typography>
          <TextField
            value={lastName}
            variant="outlined"
            onChange={handleChangeLastName}
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Telefono</Typography>
          <TextField
            value={phoneNumber}
            variant="outlined"
            onChange={handleChangePhoneNumber}
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Rut Empresa (sin dv)</Typography>
          <TextField
            value={companyRut}
            variant="outlined"
            onChange={handleChangeCompanyRut}
            helperText="(Requerido)"
            inputProps={{
              style: {
                height: 20,
              },
            }}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Typography style={{ marginRight: '20px' }}>Enviar credenciales por Email?</Typography>
          <Checkbox
            checked={sendEmail}
            name="sendmail"
            onChange={handleChangeSendEmail}
          />
        </Grid>
        <Grid className={classes.inputFieldAndNameContainer}>
          <Button
            className={classes.updateButton}
            color="primary"
            variant="contained"
            onClick={createUser}
            disabled={!enableButton()}
          >
            Crear
          </Button>
          <Typography color="error">{errorMsg}</Typography>
          <Typography color="primary">{successMsg}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateUser;
