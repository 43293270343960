import React, { useState } from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import GlobalResults from './GlobalResults';
import RasDistribution from './RasDistribution';
import { useStyles } from '../../views/styles';

const Results = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" color="textPrimary">Resultados</Typography>
      </div>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, tab) => setSelectedTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Resultados Globales" />
        <Tab label="Distribución RAS de aprobados en el árbol" />
      </Tabs>
      {selectedTab === 0 && (
        <GlobalResults />
      )}
      {selectedTab === 1 && (
        <RasDistribution />
      )}
    </div>
  );
};

export default Results;
