// This component is deprecated and will be removed in the next major release.
// Use the PaginationTable component instead.
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import CustomPagination from './CustomPagination';
import EnhancedTableHead from './Header';
import EnhancedTableToolbar from './Toolbar';
import { stableSort, getComparator } from './TableFunctions';

const TablePaginationBackend = (props) => {
  const {
    headers,
    initialOrderId,
    withSelect,
    topLegend,
    handleSelected,
    setNewDataOrder,
    data,
    dataToRows,
    clearSelection,
    dataType,
    invoiceStatus,
    fetchMore, // the function of apollo. The variables of this query should be 'offset' and 'limit,
    // to fetch only the data of the current page
    lengthData, // the total length of the data.
    // For example, if we call a query with a limit of 10 items,
    // but the query has 200 items, the dataLength will be 200.
    // lengthData will be useful to display all pages in the CustomPagination component
    argument, // the argument of the query that we want to fetch (with the function 'fetchMore'),
    filterVariables,
    // this argument is for the search box
    setCurrentPageParent,
    newRequestWithOrder, // set the objectOrder state from the parent
    rowsPerPageInit,
    objectOrder, // { order: 'asc or desc', property: 'propertyName' }
    loading, // to return <CircularProgress />, if the query is loading
    sendFilterData,
    controlled,
    parentPage,
  } = props;

  const [rows, setRows] = useState(dataToRows(data));
  const [order, setOrder] = useState(objectOrder.order);
  const [orderBy, setOrderBy] = useState(objectOrder.property);
  const [selected, setSelected] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(controlled.isControlled ? controlled.page : parentPage);
  const [rowsPerPage] = useState(rowsPerPageInit);

  useEffect(() => {
    // when the search button is pressed, a new query is called and lengthData changes
    // because of this we need to set the current page to 0,
    // to sort the data with the correct offset
    if (!controlled.isControlled) { if (!parentPage) setPage(0); }
  },
  [lengthData]);

  useEffect(() => {
    setRows(dataToRows(data));
    if (clearSelection) {
      setSelected([]);
      setSelectedRows([]);
    }
  }, [data, clearSelection, dataToRows]);

  const handleRequestSort = (property, direction) => {
    setOrder(direction);
    setOrderBy(property);
    if (controlled.isControlled) {
      controlled.setOrderBy(direction === 'asc' ? property : `-${property}`);
      return;
    }
    newRequestWithOrder(property);
  };

  useEffect(() => {
    setNewDataOrder((oldData) => stableSort(
      oldData, getComparator(objectOrder.order, initialOrderId),
    ));
  }, [initialOrderId]);

  useEffect(() => {
    // to update the state of the current page of the parent
    setCurrentPageParent(page);
  }, [page]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      setSelectedRows(rows);
      handleSelected(rows);
      return;
    }
    setSelected([]);
    setSelectedRows([]);
    handleSelected([]);
  };

  const handleSelectOne = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    const selectedRow = rows.find((row) => row.id === id);
    let newSelected = [];
    let newSelectedRows = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedRows = newSelectedRows.concat(selectedRows, selectedRow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setSelectedRows(newSelectedRows);
    handleSelected(newSelectedRows);
  };

  const handleChangePageWithFetch = async (newPage) => {
    const variables = {
      variables: {
        ...filterVariables,
        searchValue: argument,
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
        order: objectOrder.order,
        propertyToOrder: objectOrder.property,
      },
    };
    if (controlled.isControlled) {
      controlled.setPage(newPage);
      setPage(newPage);
    } else {
      if (dataType === 'invoice') {
        variables.variables.status = invoiceStatus;
      }
      fetchMore(variables);
      if (dataType === 'invoice') {
        setNewDataOrder([]);
      }
      setPage(newPage);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage
    - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <EnhancedTableToolbar
        topLegend={topLegend}
        numSelected={selected.length}
      />
      <TableContainer>
        <MuiTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAll}
            onRequestSort={handleRequestSort}
            headCells={headers}
            rowCount={rows.length}
            withSelect={withSelect}
            sendFilterData={sendFilterData}
          />
          {!loading ? (
            <TableBody>
              {rows
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      style={row.style}
                    >
                      {withSelect && (
                      <TableCell padding="none" style={{ width: 75 }}>
                        <Checkbox
                          onChange={(event) => handleSelectOne(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId, 'aria-label': `${row.disabled && 'disabled'} checkbox` }}
                          disabled={row.disabled}
                        />
                      </TableCell>
                      )}
                      {headers.map((headCell) => (
                        <TableCell key={`${headCell.label},${row.id}`}>
                          {row[headCell.id].component}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && rows.length > rowsPerPage && (
              <TableRow style={{ height: 53 * emptyRows, backgroundColor: 'transparent' }} />
              )}
            </TableBody>
          ) : (<LinearProgress />)}
        </MuiTable>
      </TableContainer>
      <CustomPagination
        page={page}
        totalPages={
          controlled.isControlled
            ? Math.ceil(lengthData / rowsPerPage) : Math.ceil(lengthData / rowsPerPage)
        }
        onChangePage={handleChangePageWithFetch}
      />
    </>
  );
};

TablePaginationBackend.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setNewDataOrder: PropTypes.func.isRequired,
  dataToRows: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  initialOrderId: PropTypes.string.isRequired,
  fetchMore: PropTypes.func.isRequired,
  lengthData: PropTypes.number.isRequired,
  newRequestWithOrder: PropTypes.func.isRequired,
  objectOrder: PropTypes.shape({ order: PropTypes.string, property: PropTypes.string }).isRequired,
  loading: PropTypes.bool.isRequired,
  // OPTIONAL PROPS
  setCurrentPageParent: PropTypes.func,
  rowsPerPageInit: PropTypes.number,
  argument: PropTypes.string,
  dataType: PropTypes.string,
  invoiceStatus: PropTypes.string,
  parentPage: PropTypes.number,
  controlled: PropTypes.shape({
    isControlled: PropTypes.bool,
    setIsControlled: PropTypes.func,
    setOrderBy: PropTypes.func,
    page: PropTypes.number,
    setPage: PropTypes.func,
    totalPages: PropTypes.number,
  }),
  // eslint-disable-next-line react/no-typos
  filterVariables: PropTypes.obj,
  withSelect: PropTypes.bool,
  handleSelected: PropTypes.func,
  topLegend: PropTypes.element,
  clearSelection: PropTypes.bool,
  sendFilterData: PropTypes.func,
};

TablePaginationBackend.defaultProps = {
  withSelect: false,
  handleSelected: () => { },
  topLegend: null,
  clearSelection: false,
  dataType: 'clients',
  invoiceStatus: '',
  argument: '',
  rowsPerPageInit: 50,
  sendFilterData: () => { },
  setCurrentPageParent: () => { },
  filterVariables: () => {},
  parentPage: 0,
  controlled: { isControlled: false,
    setIsControlled: () => {},
    setOrderBy: () => {},
    totalPages: 0 },
};

export default TablePaginationBackend;
