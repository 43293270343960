import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { GET_PENDING_COLLECTION_PAYMENT_PLANS } from '../gql/collection';
import { useStyles } from './styles';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';
import TablePaymentPlan from '../components/paymentPlans/collectionPaymentPlans';

const CollectionPaymentPlans = () => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  const [paymentPlanInvoices, setPaymentPlanInvoices] = useState([]);
  const [paymentPlanConfirmingInvoices, setPaymentPlanConfirmingInvoices] = useState([]);
  const {
    loading: loadingGetPendingCollectionObjects,
    error: errorGetPendingCollectionObjects,
  } = useQuery(GET_PENDING_COLLECTION_PAYMENT_PLANS, { onCompleted: (data) => {
    const {
      getPendingCollectionObjects: { confirmingInvoices, invoices },
    } = data;
    setPaymentPlanInvoices(invoices);
    setPaymentPlanConfirmingInvoices(confirmingInvoices);
  } });

  if (loadingGetPendingCollectionObjects) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={40} />
      </div>
    );
  }
  if (permissionDeniedErrorCatcher(errorGetPendingCollectionObjects)) {
    return <Redirect to="/" />;
  }
  if (errorGetPendingCollectionObjects) {
    return (
      <div className={classes.loading}>
        <Typography> Hubo un error para cargar las facturas de los planes de pago.
          Contáctate con el equipo de desarrollo
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.titleAndUpdate}>
            <Typography variant="h4"><b>Cobranza de Planes de Pago</b></Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '2rem', backgroundColor: 'white' }}>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(e, value) => setTab(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={<Typography color={tab === 0 ? 'primary' : 'inherit'}>Confirming</Typography>} />
              <Tab label={<Typography color={tab === 1 ? 'primary' : 'inherit'}>Factoring</Typography>} />
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: '0 2rem' }}>
          {tab === 0 && <TablePaymentPlan paymentPlanInvoices={paymentPlanConfirmingInvoices} />}
          {tab === 1 && <TablePaymentPlan paymentPlanInvoices={paymentPlanInvoices} />}
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionPaymentPlans;
