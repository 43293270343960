import React, { useState, useEffect, useCallback } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  DialogActions,
  Button,
  Tab,
  Tabs,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import { ReportProblem } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Table } from '../tables';
import labelHelper from '../../helpers/riskHistoryHelper';
import { percentageVariation, toPercentage } from '../../helpers/generic';
import { formatMoney } from '../../helpers/money';

const useStyles = makeStyles({
  contentContainer: {
    minWidth: 1000,
  },
  rutsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  title: {
    align: 'center',
  },
  centerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignCheckbox: {
    margintop: '0px',
    paddingtop: '0px',
  },
});

const headers = (firstDate, secondDate) => [
  { id: 'label', label: 'Nombre variable', align: 'center' },
  { id: 'penultimateData', label: secondDate, align: 'center' },
  { id: 'lastData', label: firstDate, align: 'center' },
  { id: 'variation', label: 'Variación %', align: 'center' },
];

const AlertsMenu = ({
  companyRiskHistory,
  receiverRiskHistory,
  companyReceiverRiskHistory,
}) => {
  const [openWindow, setOpenWindow] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [penultimateDate, setPenultimateDate] = useState(null);
  const [warning, setWarning] = useState(false);
  const [labelDisplay, setLabelDisplay] = useState('');
  const [lastDate, setLastDate] = useState(null);
  const [checked, setChecked] = useState(true);
  const classes = useStyles();

  const handleValue = useCallback((value, label) => {
    if (labelHelper[label].label === '%') {
      return value !== null ? `${toPercentage(value)}` : 'n/a';
    }
    if (labelHelper[label].label === '$') {
      return value !== null ? `$${formatMoney(value)}` : 'n/a';
    }
    return value !== null ? value.toFixed(0) : 'n/a';
  });

  const handleData = (dataSet, label) => {
    if (dataSet && dataSet.length === 2) {
      const items = [];
      setLastDate(dataSet[0].date);
      setPenultimateDate(dataSet[1].date);
      Object.keys(dataSet[0]).filter((e) => !['date', '__typename']
        .includes(e)).forEach((key) => {
        const variation = percentageVariation(dataSet[1][key], dataSet[0][key]);
        const critical = variation > 20 || variation < -20;
        if (critical) {
          setWarning(true);
        }
        items.push(
          {
            labelDisplay: label,
            label: key,
            penultimateData: handleValue(dataSet[1][key], key),
            lastData: handleValue(dataSet[0][key], key),
            critical,
            variation,
          },
        );
      });
      setItemsToDisplay((current) => current.concat(items));
    }
  };

  useEffect(() => {
    handleData(companyRiskHistory, 'companyRiskHistory');
    handleData(receiverRiskHistory, 'receiverRiskHistory');
    handleData(companyReceiverRiskHistory, 'companyReceiverRiskHistory');
  }, []);

  useEffect(() => {
    if (selectedTab === 0) {
      setLabelDisplay('companyRiskHistory');
    }
    if (selectedTab === 1) {
      setLabelDisplay('receiverRiskHistory');
    }
    if (selectedTab === 2) {
      setLabelDisplay('companyReceiverRiskHistory');
    }
  }, [selectedTab]);

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      label,
      penultimateData,
      lastData,
      variation,
    } = row;
    return ({
      label: {
        value: label,
        component: (<Typography align="right">{labelHelper[label].name}</Typography>),
      },
      penultimateData: {
        value: penultimateData,
        component: (<Typography align="right">{penultimateData}</Typography>),
      },
      lastData: {
        value: lastData,
        component: (<Typography align="right">{lastData}</Typography>),
      },
      variation: {
        value: variation,
        component: (<Typography align="right">{variation !== null ? `${(variation).toFixed(2)}%` : 'n/a'}</Typography>),
      },
    });
  });
  return (
    <>
      <IconButton onClick={() => setOpenWindow(true)} color={warning ? 'primary' : 'default'}>
        <ReportProblem />
      </IconButton>
      <Dialog open={openWindow} onClose={() => setOpenWindow(false)} maxWidth="xl">
        <DialogTitle className={classes.title}><b>Variables Bureau Interno por mes</b></DialogTitle>
        <Box ml={3}>
          <FormControlLabel control={<Checkbox checked={checked} onClick={(e) => setChecked(e.target.checked)} />} label="Variaciones Importantes (20% o más)" />
        </Box>
        <DialogContent className={classes.contentContainer}>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
          >
            <Tab
              label="Cliente"
            />
            <Tab
              label="Deudor"
            />
            <Tab
              label="Relación"
            />
          </Tabs>
          <Table
            data={checked ? itemsToDisplay.filter(
              (el) => el.critical && el.labelDisplay === labelDisplay,
            ) : itemsToDisplay.filter((el) => el.labelDisplay === labelDisplay)}
            dataToRows={transformDataToComponents}
            setNewDataOrder={setItemsToDisplay}
            headers={headers(lastDate, penultimateDate)}
            initialOrderId="label"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenWindow(false)}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const propTypes = {
  amountDebtors: PropTypes.number,
  amountDebtorsCeeded: PropTypes.number,
  amountProviders: PropTypes.number,
  averageIssuedInvoices: PropTypes.number,
  averageReceptionAcknowledgmentInvoicesTime: PropTypes.number,
  averageRejectionInvoiceTime: PropTypes.number,
  date: PropTypes.string,
  largestLoanedInvoice3Months: PropTypes.number,
  largestLoanedInvoice12Months: PropTypes.number,
  percentageCessionsOverIssued: PropTypes.number,
  percentageCreditNotesInvoices: PropTypes.number,
  percentageReceptionAcknowledgmentInvoices: PropTypes.number,
  percentageRejectedInvoices: PropTypes.number,
  percentageSalesConcentration10PercentBiggestDebtor: PropTypes.number,
  percentageSalesConcentrationBiggestDebtor: PropTypes.number,
};

AlertsMenu.propTypes = {
  companyRiskHistory: PropTypes.arrayOf(PropTypes.shape(propTypes)),
  receiverRiskHistory: PropTypes.arrayOf(PropTypes.shape(propTypes)),
  companyReceiverRiskHistory: PropTypes.arrayOf(PropTypes.shape(propTypes)),
};

AlertsMenu.defaultProps = {
  companyRiskHistory: [],
  receiverRiskHistory: [],
  companyReceiverRiskHistory: [],
};

export default AlertsMenu;
