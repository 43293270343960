import { ApolloClient, InMemoryCache /* , HttpLink */ } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache();
// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
//   fetch: (uri, options) => customFetch(uri, options, login),
// });
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: '',
  },
}));

const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(uploadLink),
});

export default apolloClient;
