import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Typography,
  TextField,
  Paper,
  Container,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LOGIN } from '../gql/auth';
import { UserContext } from '../context/user';
import textToRoute from '../helpers/textToRoute';

const useStyles = makeStyles({
  container: {
    paddingTop: '20vh',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: '1rem',
  },
  buttonContainer: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
});

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { login, user, logout } = useContext(UserContext);
  const [loginGql] = useMutation(LOGIN);
  const [formData, setFormData] = useState({
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const defaultRoute = 'Evaluaciones de Riesgo';

  const redirectToValidRoute = () => {
    const { user: { backofficeSubmenuPermissions } } = user;
    if (backofficeSubmenuPermissions && backofficeSubmenuPermissions.includes(defaultRoute)) {
      return history.replace(textToRoute[defaultRoute]);
    } if (backofficeSubmenuPermissions && backofficeSubmenuPermissions.length) {
      const firstSubMenuFromPermissionsIndex = 0;
      return history
        .replace(textToRoute[backofficeSubmenuPermissions[firstSubMenuFromPermissionsIndex]]);
    }
    logout();
    return history.replace('/');
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((oldFormData) => ({
      ...oldFormData,
      [name]: { ...oldFormData[name], value },
    }));
  };

  const onFailedLogin = (_error) => {
    setError('Login inválido');
    setLoading(false);
    // eslint-disable-next-line no-console
    console.error(_error);
  };

  const onUserDoesNotHavePermitions = () => {
    setError('Usuario sin permisos para acceder');
    setLoading(false);
  };

  const onSuccessLogin = async (userData) => {
    await login(userData);
  };

  const doLogin = async (email, password) => {
    const graphqlPayload = { variables: { email, password } };
    const loginResponse = await loginGql(graphqlPayload);
    const userData = loginResponse.data.tokenAuth;
    if (userData.user.isStaff) return onSuccessLogin(userData);
    return onUserDoesNotHavePermitions();
  };

  const handleLogin = async () => {
    const { email, password } = formData;
    setLoading(true);
    try {
      await doLogin(email.value, password.value);
    } catch (err) {
      onFailedLogin(err);
    }
  };

  const { email, password } = formData;

  useEffect(() => {
    if (user) {
      redirectToValidRoute();
    }
  }, [user]);

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center">Inicia Sesión</Typography>
        <div style={{ marginTop: '2rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <Typography>Email</Typography>
            <TextField
              name="email"
              value={email.value}
              error={Boolean(email.error)}
              helperText={email.error}
              onChange={onChange}
              type="email"
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Typography>Contraseña</Typography>
            <TextField
              name="password"
              value={password.value}
              error={Boolean(password.error)}
              helperText={password.error}
              onChange={onChange}
              type="password"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          {Boolean(error) && (
            <Typography
              variant="caption"
              color="error"
              align="center"
              className={classes.errorMessage}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            fullWidth
            disabled={loading}
          >
            Ingresar
            {loading && <CircularProgress style={{ marginLeft: '0.5rem' }} size={16} />}
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default Login;
