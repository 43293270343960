const calculateInvoiceAmounts = (invoiceData) => {
  const { days, retentionRate, monthlyRate, amountWithIva } = invoiceData;
  const effectiveRate = (monthlyRate / 100) * (days / 30);
  const retentionAmount = parseInt((retentionRate / 100) * amountWithIva, 10);
  const advanceAmount = amountWithIva - retentionAmount;
  const amountMinusInterests = parseInt(advanceAmount * (1 - effectiveRate), 10);
  const priceDifference = advanceAmount - amountMinusInterests;
  return { effectiveRate, retentionAmount, advanceAmount, amountMinusInterests, priceDifference };
};

export default calculateInvoiceAmounts;
