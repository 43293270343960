import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import { Table } from '../tables';
import { GET_RISK_VARIABLES, GET_RISK_VARIABLES_DESCRIPTION } from '../../gql/riskAlgorithm';
import DicomDetail from './DicomDetail';
import { parseMoney } from '../../helpers/money';
import { toPercentage } from '../../helpers/generic';

const useStyles = makeStyles({
  titleContainer: {
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1rem',
  },
});

const VariablesDetail = ({
  company,
  debtor,
  open,
  onClose,
  objectId,
  modelName,
  ratification,
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataContent, setDataContent] = useState();
  const [dataVariables, setDataVariables] = useState({});
  const [createdAt, setCreatedAt] = useState('');
  const [clientDicomResponse, setClientDicomResponse] = useState(null);
  const [debtorDicomResponse, setDebtorDicomResponse] = useState(null);
  const [clientCurrentBehaviorResponse, setClientCurrentBehaviorResponse] = useState(null);
  const [debtorCurrentBehaviorResponse, setDebtorCurrentBehaviorResponse] = useState(null);
  const [clientBusinessProfileResponse, setClientBusinessProfileResponse] = useState(null);
  const [debtorBusinessProfileResponse, setDebtorBusinessProfileResponse] = useState(null);
  const [dataDisplay, setDataDisplay] = useState();
  const headers = [
    { id: 'variable', label: 'Variable', align: 'center' },
    { id: 'value', label: 'Valor', align: 'center' },
  ];
  useQuery(
    GET_RISK_VARIABLES,
    {
      variables: {
        objectId,
        modelName,
      },
      onCompleted: (data) => {
        const { fetchVariables } = data.getRiskVariables;
        setCreatedAt(fetchVariables.find((e) => e.name === 'created_at').value);
        setDataContent(fetchVariables);
        setClientDicomResponse(fetchVariables.find((e) => e.name === 'client_dicom_response').value === 'true');
        setDebtorDicomResponse(fetchVariables.find((e) => e.name === 'debtor_dicom_response').value === 'true');
        setClientCurrentBehaviorResponse(fetchVariables.find((e) => e.name === 'client_current_behavior_response').value === 'true');
        setDebtorCurrentBehaviorResponse(fetchVariables.find((e) => e.name === 'debtor_current_behavior_response').value === 'true');
        setClientBusinessProfileResponse(fetchVariables.find((e) => e.name === 'client_business_profile_response').value === 'true');
        setDebtorBusinessProfileResponse(fetchVariables.find((e) => e.name === 'debtor_business_profile_response').value === 'true');
      },
      skip: !objectId,
    },
  );
  useQuery(
    GET_RISK_VARIABLES_DESCRIPTION,
    {
      onCompleted: (data) => {
        setDataVariables(
          Object.assign(
            {}, ...data.getRiskEvaluationVariablesDescription.map(
              (x) => ({ [x.name]: x }),
            ),
          ),
        );
      },
    },
  );

  useEffect(() => {
    if (dataContent) {
      if (selectedTab === 0) {
        setDataDisplay(
          dataContent.filter(
            (rule) => rule.name.startsWith('client') && !rule.name.startsWith('client_debtor'),
          ),
        );
      }
      if (selectedTab === 1) {
        setDataDisplay(
          dataContent.filter(
            (rule) => rule.name.startsWith('debtor'),
          ),
        );
      }
      if (selectedTab === 2) {
        setDataDisplay(
          dataContent.filter(
            (rule) => rule.name.startsWith('client_debtor'),
          ),
        );
      }
    }
  }, [selectedTab, dataContent]);

  const valueHandler = (name, value, valueType) => {
    if (valueType === 'NoneType') {
      return 'n/a';
    }
    if (valueType === 'bool') {
      return value === 'true' ? 'Si' : 'No';
    }
    if (valueType === 'int' || valueType === 'float') {
      const floatValue = parseFloat(value);
      if (dataVariables[name] === undefined) {
        return floatValue;
      }
      if (dataVariables[name].variableType === '$') {
        const moneySign = floatValue >= 0 ? '' : '-';
        return moneySign.concat(parseMoney(floatValue));
      }
      if (dataVariables[name].variableType === '%') {
        return toPercentage(floatValue);
      }
      return floatValue;
    }
    return value;
  };

  const tooltipHandler = (name) => {
    if (dataVariables[name]) {
      return dataVariables[name].description;
    }
    return name;
  };

  const labelHandler = (name) => {
    if (dataVariables[name]) {
      return dataVariables[name].labelName;
    }
    return name;
  };

  const dataToRows = (_data) => _data.map((row) => {
    const {
      name,
      value,
      valueType,
    } = row;
    return ({
      variable: {
        value: name,
        component: (
          <Tooltip title={tooltipHandler(name)} arrow>
            <Typography>{labelHandler(name)}</Typography>
          </Tooltip>
        ),
      },
      value: {
        value,
        component: (
          <Typography>{valueHandler(name, value, valueType)}</Typography>
        ),
      },
    });
  });
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography variant="h3">
          Variables de Riesgo
        </Typography>
        {createdAt && (
        <Typography variant="h5">
          Fecha Evaluación: {new Date(createdAt).toLocaleString()}
        </Typography>
        )}
      </DialogTitle>
      {ratification
        && (!ratification.ratification
          ? <MuiAlert severity="success">Sin ratificación según motor</MuiAlert>
          : <MuiAlert severity="error">Con ratificacion segun motor. Razón: {ratification.reason}</MuiAlert>
        )}
      {clientDicomResponse === false && <MuiAlert severity="error">Respuesta Dicom Cliente no válida</MuiAlert>}
      {debtorDicomResponse === false && <MuiAlert severity="error">Respuesta Dicom Deudor no válida</MuiAlert>}
      {clientCurrentBehaviorResponse === false && <MuiAlert severity="error">Respuesta Comportamiento Vigente Cliente no válida</MuiAlert>}
      {debtorCurrentBehaviorResponse === false && <MuiAlert severity="error">Respuesta Comportamiento Vigente Deudor no válida</MuiAlert>}
      {clientBusinessProfileResponse === false && <MuiAlert severity="error">Respuesta Perfil Comercial Cliente no válida</MuiAlert>}
      {debtorBusinessProfileResponse === false && <MuiAlert severity="error">Respuesta Perfil Comercial Deudor no válida</MuiAlert>}
      <Grid className={classes.buttonContainer}>
        {company.id && (
        <DicomDetail
          company={company}
          label="Cliente"
        />
        )}
        {debtor.id && (
        <DicomDetail
          company={debtor}
          label="Deudor"
        />
        )}
      </Grid>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <Tab
          label="Cliente"
        />
        <Tab
          label="Deudor"
        />
        <Tab
          label="Relación"
        />
      </Tabs>
      {dataDisplay
        && (
        <DialogContent>
          <Table
            headers={headers}
            initialOrderId="name"
            data={dataDisplay}
            dataToRows={dataToRows}
            setNewDataOrder={setDataContent}
          />
        </DialogContent>
        )}
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VariablesDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  objectId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    rut: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  debtor: PropTypes.shape({
    id: PropTypes.string,
    rut: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  ratification: PropTypes.shape({
    ratification: PropTypes.bool,
    reason: PropTypes.string,
  }).isRequired,
};

export default VariablesDetail;
