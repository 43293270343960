import moment from 'moment';

const siiStatusToProps = (dateIssued, creditNoteStatus) => {
  const meritDays = 9 - (moment().diff(moment(dateIssued), 'days'));
  const hasCreditNote = ['HAS_CREDIT_NOTE', 'PARTIAL_CREDIT_NOTE'].includes(creditNoteStatus);
  const hasPartialCreditNote = creditNoteStatus === 'PARTIAL_CREDIT_NOTE';
  const creditNoteStatusLabel = hasCreditNote ? `, con NC ${hasPartialCreditNote ? 'parcial' : 'total'}` : '';
  return {
    PROCESSING: {
      label: 'En espera de aceptación SII',
      color: 'grey',
    },
    REJECTED_BY_SII: {
      label: `Rechazado${creditNoteStatusLabel}`,
      color: 'red',
    },
    REJECTED_RECEIVER: {
      label: `Rechazado${creditNoteStatusLabel}`,
      color: 'red',
    },
    REJECTED_CONTENT: {
      label: `Rechazado${creditNoteStatusLabel}`,
      color: 'red',
    },
    REJECTED_TOTAL: {
      label: `Rechazado${creditNoteStatusLabel}`,
      color: 'red',
    },
    REJECTED_PARTIAL: {
      label: `Rechazado${creditNoteStatusLabel}`,
      color: 'red',
    },
    VALID: {
      label: meritDays <= 0 ? `Mérito ejecutivo${creditNoteStatusLabel}` : `Quedan ${meritDays} días${creditNoteStatusLabel}`,
      color: (hasPartialCreditNote && 'gold') || (hasCreditNote && 'red') || 'grey',
    },
    RECEPTION_ACKNOWLEDGED: {
      label: `Acuse de recibo${creditNoteStatusLabel}`,
      color: (hasPartialCreditNote && 'gold') || (hasCreditNote && 'red') || 'green',
    },
    ALREADY_PAYED: {
      label: 'Pago al contado',
      color: 'orange',
    },
  };
};

export default siiStatusToProps;
