import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, MenuItem, Select, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { DICOM_DETAILS } from '../../gql/riskAlgorithm';
import DicomReport from './DicomReport';
import { parseMoney } from '../../helpers/money';

const useStyles = makeStyles({
  grid: {
    textAlign: 'center',
  },
  summary: {
    borderRight: 'solid',
  },
});

const DicomDetail = ({ company, label }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dataContent, setDataContent] = useState([]);
  const [selectedReport, setSelectedReport] = useState({ date: '', details: [] });
  const [dicomAmount, setDicomAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const { loading, error } = useQuery(
    DICOM_DETAILS,
    {
      variables: {
        id: company.id,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (_data) => {
        setDataContent(_data.getDicomDetails);
      },
    },
  );

  useEffect(() => {
    if (dataContent.length > 0) {
      setDisabled(false);
      setSelectedReport(dataContent.at(-1));
    }
  }, [dataContent]);

  useEffect(() => {
    setDicomAmount(selectedReport.details.reduce((partialSum, a) => {
      if (a.coinType === 'US') return partialSum + a.amount * 900;
      if (a.coinType === 'UF') return partialSum + a.amount * 34000;
      return partialSum + a.amount;
    }, 0));
  }, [selectedReport.details]);
  if (loading) return <CircularProgress size={16} style={{ marginLeft: '5em', marginRight: '5em' }} />;
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled || error}
      >
        <Typography>Dicom {label}</Typography>
      </Button>
      <Dialog
        open={open}
        maxWidth="lg"
        className={classes.dialog}
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h3">Detalles Dicom: <b>{company.name}</b></Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={4}
            className={classes.grid}
          >
            <Grid item xs={4} className={classes.summary}>
              <Typography><b>Monto Total Impagos</b></Typography>
              <Typography>{parseMoney(dicomAmount)}</Typography>
            </Grid>
            <Grid item xs={4} className={classes.summary}>
              <Typography><b>Total Documentos impagos</b></Typography>
              <Typography>{selectedReport.details.length}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography><b>Fecha de reporte</b></Typography>
              <Select
                id="report-selector"
                value={selectedReport}
                onChange={(e) => setSelectedReport(e.target.value)}
                variant="outlined"
                placeholder="Seleccione un reporte"
              >
                {dataContent.map((report) => (
                  <MenuItem
                    key={report.date}
                    value={report}
                  >
                    {report.date}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          {selectedReport.details.length > 0 && (
            <DicomReport report={selectedReport.details} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpen(false); }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DicomDetail.propTypes = {
  label: PropTypes.string.isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    rut: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default DicomDetail;
