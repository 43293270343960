import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  assignButton: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#ABABAB',
  },
});

const UserAvatar = ({ name, onAssign, actionName }) => {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (e) => {
    setAnchorElement(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      {(name == null) ? (
        <>
          <Button onClick={handleClick}>
            <Avatar>
              <Person />
            </Avatar>
          </Button>
          <Menu
            anchorOrigin={{ vertical: 'bottom' }}
            elevation={0}
            getContentAnchorEl={null}
            id="menu"
            anchorEl={anchorElement}
            open={Boolean(anchorElement)}
            onClose={handleClose}
          >
            <MenuItem className={classes.assignButton} onClick={onAssign}>
              {`Asignarme la ${actionName} de este deudor`}
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Button disabled>
          <Avatar>{name[0]}</Avatar>
        </Button>
      )}
    </>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string,
  onAssign: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
};

UserAvatar.defaultProps = {
  name: null,
};

export default UserAvatar;
