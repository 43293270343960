import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation, Redirect } from 'react-router-dom';
import {
  Typography,
  Grid,
  Button,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Table } from '../components/tables';
import { OneButtonAlert, AddCompanyEnrollmentContract } from '../components/dialogs';
import { Filters } from '../components/utils';
import { useStyles } from './styles';
import {
  GET_PENDING_COMPANY_ENROLLMENT_REQUESTS,
  RESOLVE_COMPANY_ENROLLMENT_REQUEST,
} from '../gql/advancePayment';
import { formatRut } from '../helpers/generic';
import { formatMoney } from '../helpers/money';
import { permissionDeniedErrorCatcher } from '../helpers/permissionDeniedError';

const headers = [
  { id: 'requestId', label: 'ID', align: 'left', isSearchable: true },
  { id: 'company', label: 'Empresa', align: 'left', isSearchable: true },
  { id: 'representative', label: 'Representante', align: 'left', isSearchable: true },
  { id: 'rexStartDate', label: 'Fecha de inicio en Rex+', align: 'left', isSearchable: false },
  { id: 'monthlyPayroll', label: 'Nómina mensual', align: 'left', isSearchable: false },
];

const useOwnStyles = makeStyles((theme) => ({
  onTimeBadge: {
    backgroundColor: theme.palette.success.main,
  },
  littleLateBadge: {
    backgroundColor: theme.palette.warning.main,
  },
  lateBadge: {
    backgroundColor: theme.palette.error.main,
  },
}));

const AdvancePaymentCompanyEnrollment = () => {
  const classes = { ...useStyles(), ...useOwnStyles() };
  const location = useLocation();
  const [requests, setRequests] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsIds, setRowsIds] = useState([]);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [enrollmentContractOpen, setEnrollmentContractOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data: enrollmentRequestsData,
    loading: enrollmentRequestsLoading,
    refetch: enrollmentRequestsRefetch,
    error: enrollmentRequestsError,
  } = useQuery(GET_PENDING_COMPANY_ENROLLMENT_REQUESTS);
  const [resolveEnrollmentRequest] = useMutation(RESOLVE_COMPANY_ENROLLMENT_REQUEST);

  const handleSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const transformDataToRowLikeObjects = (_data) => _data.map((row) => {
    const {
      id,
      company: {
        rut,
        name,
        rexInfo,
      },
    } = row;
    const {
      rexStartActivities,
      monthlyPayroll,
      representativeName,
      representativePhoneNumber,
      representativeEmail,
    } = rexInfo[0];
    const monthlyPayrollJson = JSON.parse(monthlyPayroll);
    const transformedStartActivities = moment(rexStartActivities, 'YYYYMM').format('MM-YYYY');
    const transformedMonthlyPayroll = {};
    Object.entries(monthlyPayrollJson).forEach(([key, val]) => {
      const transformedDate = moment(key, 'YYYY-MM').format('MM-YYYY');
      transformedMonthlyPayroll[transformedDate] = val;
    });
    const monthlyPayrollTooltip = (
      <div>
        {Object.entries(transformedMonthlyPayroll).map(([key, val]) => (
          <Typography>{key}: {`$${formatMoney(val)}`}</Typography>
        ))}
      </div>
    );
    const lastPayrollKey = Object.keys(monthlyPayrollJson).sort((a, b) => (
      (a).localeCompare(b)
    )).slice(-1)[0];
    const lastPayroll = monthlyPayrollJson[lastPayrollKey];
    return {
      id,
      rut,
      name,
      rexStartActivities: transformedStartActivities,
      monthlyPayrollTooltip,
      lastPayroll,
      representativeName,
      representativePhoneNumber,
      representativeEmail,
    };
  });

  useEffect(() => {
    if (location.state && location.state.refetch) enrollmentRequestsRefetch();
  }, [location.state]);

  useEffect(() => {
    if (enrollmentRequestsData) {
      const transformedRequests = transformDataToRowLikeObjects(
        enrollmentRequestsData.getPendingCompanyEnrollmentRequests,
      );
      setRequests(transformedRequests);
      setFilteredRequests(transformedRequests);
    }
  }, [enrollmentRequestsData]);

  const onResolveRequests = async () => {
    const requestsIds = selectedRows.map((row) => (
      { id: row.id, name: row.company.value, rut: row.rut }));
    setRowsIds(requestsIds);
    setEnrollmentContractOpen(true);
  };

  const onEnrollmentContractClose = async (requestApproved) => {
    setEnrollmentContractOpen(false);
    if (uploadFile.length !== selectedRows.length) {
      setErrorMessage('Se deben cargar la misma cantidad de documentos que las empresas seleccionadas.');
      setErrorMessageOpen(true);
    } else {
      const requestsIds = selectedRows.map((row) => row.id);
      try {
        await resolveEnrollmentRequest({ variables:
          { requestIds: requestsIds, requestApproved, files: uploadFile } });
        const updateData = (_oldData) => (
          _oldData.filter((row) => (!requestsIds.includes(row.id)))
        );
        setRequests((oldData) => (updateData(oldData)));
        setFilteredRequests((oldData) => (updateData(oldData)));
        setUploadFile([]);
      } catch (error) {
        setErrorMessage('Hubo un error al intentar crear la gestión. Comunícate con el equipo de desarrollo.');
        setErrorMessageOpen(true);
      }
    }
  };

  const transformDataToComponents = (_data) => _data.map((row) => {
    const {
      id,
      rut,
      name,
      rexStartActivities,
      monthlyPayrollTooltip,
      lastPayroll,
      representativeName,
      representativePhoneNumber,
      representativeEmail,
    } = row;

    return ({
      rut,
      id,
      requestId: {
        component: (
          <div className={classes.row}>
            <Typography>{id}</Typography>
          </div>
        ),
        value: id,
      },
      company: {
        component: (
          <div style={{ minWidth: '6rem' }}>
            <Typography>{name}</Typography>
            <Typography>{formatRut(rut)}</Typography>
          </div>
        ),
        value: name,
      },
      representative: {
        component: (
          <div style={{ minWidth: '6rem' }}>
            <Typography>{representativeName}</Typography>
            <Typography>{representativeEmail}</Typography>
            <Typography>{representativePhoneNumber}</Typography>
          </div>
        ),
        value: representativeName,
      },
      rexStartDate: {
        component: (
          <Typography>{rexStartActivities}</Typography>
        ),
        value: rexStartActivities,
      },
      monthlyPayroll: {
        component: (
          <Tooltip title={monthlyPayrollTooltip}>
            <Typography>{`$${formatMoney(lastPayroll)}`}</Typography>
          </Tooltip>
        ),
        value: rexStartActivities,
      },
    });
  });

  if (permissionDeniedErrorCatcher(enrollmentRequestsError)) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {enrollmentRequestsLoading && (
        <LinearProgress />
      )}
      {!enrollmentRequestsLoading && (
        <div className={classes.container}>
          <OneButtonAlert
            open={errorMessageOpen}
            onClose={() => setErrorMessageOpen(false)}
            message={errorMessage}
            title="Error"
          />
          <AddCompanyEnrollmentContract
            classes={classes}
            open={enrollmentContractOpen}
            setUploadFile={setUploadFile}
            onClose={() => onEnrollmentContractClose(true)}
            rows={rowsIds}
          />
          <div className={classes.headerContainer}>
            <Typography variant="h4" className={classes.header}><b>Solicitudes de activación pendientes</b></Typography>
            <div className={classes.headerButtonsContainer}>
              <Button
                className={classes.mainColorButton}
                onClick={() => onResolveRequests(true)}
                color="inherit"
                variant="contained"
                disabled={selectedRows.length <= 0}
              >
                Activar
              </Button>
              <Button
                className={classes.redButton}
                onClick={() => onResolveRequests(false)}
                color="inherit"
                variant="contained"
                disabled={selectedRows.length <= 0}
              >
                Rechazar
              </Button>
            </div>
          </div>
          {requests.length > 0 ? (
            <Grid container>
              <Grid item xs={6} sm={6} className={classes.searchBar}>
                <Filters
                  allData={requests}
                  setFilteredData={setFilteredRequests}
                  searchFields={['name', 'rut']}
                  searchPlaceholder="Búsqueda por rut o nombre de cliente"
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.tableContainer}>
                <Table
                  headers={headers}
                  initialOrderId="id"
                  withSelect
                  handleSelected={handleSelectedRows}
                  setNewDataOrder={setFilteredRequests}
                  data={filteredRequests}
                  dataToRows={transformDataToComponents}
                />
              </Grid>
            </Grid>
          ) : (
            <Typography align="center" variant="h5">No hay solicitudes pendientes</Typography>
          )}
        </div>
      )}
    </>
  );
};

export default AdvancePaymentCompanyEnrollment;
