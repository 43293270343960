import { gql } from '@apollo/client';

export const GET_PRE_OFFERS = gql`
  query GetPreOffers($ruts: [String]!, $evaluation: Boolean!, $pageOffset: Int, $quantity: Int) {
    getPreoffersNp(ruts: $ruts, evaluation: $evaluation, pageOffset: $pageOffset, quantity: $quantity) {
      id
      company {
        id
        rut
        currentCommissionRules {
          id
          threshold
          variant
          value
        }
        masterEntity{
          rateWhitelistEmitter {
            monthlyRate
            receiver {
              id
              rut
            }
          }
        }
      }
      folio
      confirming
      receiver {
        id
        debtor {
          id
          collector {
            id
            firstName
            lastName
          }
        }
      }
      collector @client {
        id
        firstName
        lastName
      }
      nameReceiver
      rutReceiver
      dateIssued
      dateOperation
      dateExpiration
      dateToPay
      amountWithIva
      pdf
      preoffer {
        id
        monthlyRate
        defaultRate
        retentionRate
        preofferevaluationrequest {
          status
          createdAt
        }
        invoice {
          objectPurchaseOrder {
            exists
            url
          }
          certificateUrl
          documents {
            id
            url
            type {
              id
              name
              code
            }
          }
        }
        negotiationRequestStatus
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers($rut: String!) {
    getOffers(rut: $rut) {
      offers {
        id
        company {
          id
          rut
          currentCommissionRules {
            id
            threshold
            variant
            value
          }
          masterEntity{
            id
            rateWhitelistEmitter {
              monthlyRate
              receiver {
                id
                rut
              }
            }
          }
        }
        id
        folio
        pdf
        confirming
        nameReceiver
        rutReceiver
        receiver {
          id
          debtor {
            id
            collector {
              id
              firstName
              lastName
            }
          }
        }
        collector @client {
          id
          firstName
          lastName
        }
        dateIssued
        dateOperation
        dateExpiration
        objectPurchaseOrder {
          exists
          url
        }
        certificateUrl
        documents {
          id
          url
          type {
            id
            name
            code
          }
        }
        dateToPay
        amountWithIva
        xml
        offer {
          id
          monthlyRate
          defaultRate
          retentionRate
          transferCondition
          negotiationRequestStatus
        }
      }
      debtorCreditLines {
        id
        totalAmount
        creditUsed
        approvedOverdraft
        company {
          id
          rut
          name
        }
      }
    }
  }
`;

export const GET_CESIONS = gql`
  query GetCessions($rut: String!) {
    getCessions(rut: $rut) {
      id
      company {
        id
        rut
      }
      folio
      lastHistoryStatus {
        status
        createdAt
      }
      pdf
      nameReceiver
      rutReceiver
      receiver {
        id
        rut
        debtor {
          id
          collector {
            id
            firstName
            lastName
          }
        }
      }
      collector @client {
        id
        firstName
        lastName
      }
      dateIssued
      dateOperation
      dateExpiration
      dateToPay
      collectionDate
      paymentDate
      amountWithIva
      status
      surplusDebt
      surplusRetention
      surplusSurplus
      offer {
        monthlyRate
        defaultRate
        retentionRate
      }
      operation {
        id
        commission
      }
      ratificationmanager{
        id
        actions {
        id
        actionType
        comment
        createdAt
        ratificationContacts {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        author {
          id
          firstName
          lastName
        }
      }
      }
      collectionManager{
        id
        actions {
        id
        actionType
        comment
        createdAt
        collectionContacts {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        author {
          id
          firstName
          lastName
        }
      }
      currentDataForCollection {
        id
        paymentMethod
        dateToPay
        collectionContacts {
          id
          firstName
          lastName
        }
      }
      reminder {
        id
        reminderDate
        reminderNote
      }
    }
    
    }
  }
`;

export const GET_REJECTED_INVOICES = gql`
query getRejectedInvoices($ruts:[Int]!){
  getRejectedInvoices(ruts:$ruts){
    rejectionReasonLabel
    updatedAt
    preoffer{
      id
      monthlyRate
      defaultRate
      retentionRate
      invoice{
        id
        folio
        confirming
        nameReceiver
        rutReceiver
        receiver {
          id
          debtor {
            id
            collector {
              id
              firstName
              lastName
            }
          }
        }
        collector @client {
          id
          firstName
          lastName
        }
        dateIssued
        dateOperation
        dateExpiration
        dateToPay
        amountWithIva
        pdf    
      }
    }
  }
}
`;
