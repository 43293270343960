import React, { useState } from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { CLIENT_TREE, DEBTOR_TREE, CLIENT_DEBTOR_TREE } from '../../gql/riskAlgorithm';
import GenericTree from './TreeGenericView';
import { useStyles } from '../../views/styles';

const TreeRisk = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" color="textPrimary">Árbol de decisión</Typography>
      </div>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(_, tab) => setSelectedTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Cliente" />
        <Tab label="Deudor" />
        <Tab label="Relación" />
      </Tabs>
      {selectedTab === 0 && (
        <GenericTree query={CLIENT_TREE} variableType="CLIENT_TREE" />
      )}
      {selectedTab === 1 && (
        <GenericTree query={DEBTOR_TREE} variableType="DEBTOR_TREE" />
      )}
      {selectedTab === 2 && (
        <GenericTree query={CLIENT_DEBTOR_TREE} variableType="CLIENT_DEBTOR_TREE" />
      )}
    </div>
  );
};

export default TreeRisk;
