import { gql } from '@apollo/client';

export default gql`
  query getCompanyContactability (
    $ruts: [String]!
  ) {
    getCompanyContactability(ruts: $ruts){
      success
    }
  }
`;
